import {useEffect} from "react";

export function useSseEvent(sseEndpoint: string, onMessage: Function) {

  useEffect(() => {
    const eventSource = new EventSource(sseEndpoint);
    eventSource.onmessage = ({data}) => {
      const json = JSON.parse(data);
      onMessage(json);
      // console.log('New message', JSON.parse(data));
    };

    eventSource.onerror = (e) => {
      // error log here
      console.log('error sse', e);
      eventSource.close();
    }
    return () => {
      eventSource.close();
    };
  }, []);

}
