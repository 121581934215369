import React from "react";
import {NavBar} from "../components/ui/nav-bar.component";
import {FootBar} from "../components/ui/foot-bar.component";
import {PublicNavBar} from "../components/ui/public-nav-bar.component";
import {useIsAuth} from "../hooks/useIsAuth";
import AppFooter from "../components/ui/app-footer.component";
import {TanjiIcon} from "../components/ui/tanji-icon";

const PublicRoute: React.FC<any> = ({hideAppFooter = false, children}) => {
  const {isAuth, isAuthLoading} = useIsAuth();

  if (isAuthLoading) {
    return <div className={"bg-orange w-full h-screen flex justify-center items-center"}>
      <div className={"animate-pulse"}><TanjiIcon h={60}/></div>
    </div>
  } else {
    // console.log('isAuth : ' + JSON.stringify(isAuth));
    return isAuth ? <><NavBar/>{children}<FootBar/>{hideAppFooter ? <></> : <AppFooter/>}</> : <>
      <PublicNavBar/>{children}{hideAppFooter ? <></> :
      <AppFooter/>}</>;
  }
}
export {PublicRoute};