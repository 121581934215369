import React, {useEffect, useState} from 'react';
import Layout from "../Layout";
import {SecondaryNavigation} from "../components/ui/secondary-navigation.component";
import {useGetNextTournamentsQuery} from "../apis/tournaments.api";
import {classNames, formatGainPercent, hasElements} from "../utils/Helper";
import {useGetSquadAnalyticsQuery, useGetSquadPerfPercentageQuery} from "../apis/squad.api";
import {SquadScorePayload} from "../dto/squad/squad.dto";
import {CoinIcon} from "../components/ui/coin-icon";
import {SquadAggrPositions} from "../components/squad/squad-aggr-tx.component";
import {ActionButtons} from "../components/ui/action-buttons";
import {AreaChart} from "../components/profile/area-chart.component";
import * as _ from "lodash";
import {PriceRange} from "../models/PriceRange";
import {DateTime} from "luxon";
import {compareDates, getDuration} from "../utils/DateUtils";
import {useLocation} from "react-router";
import {BackButton} from "../components/ui/back-button";
import {useNavigate} from "react-router-dom";
import {formatGain} from "../utils/usePerfFormatter";

function getTotalValue(scorePayload?: SquadScorePayload) {
  return scorePayload ? scorePayload.positions.reduce((prev, cur) => prev + cur.amount, 0) : 0;
}

const getEmptyTimeSeries = () => {
  return [{x: 0, y: 1}, {x: 1, y: 1}]
}

const PortfolioPage: React.FC = () => {
  const [seriesData, setSeriesData] = useState<Array<{ x: number, y: number }>>([]);
  const [range, setRange] = useState<PriceRange>('1W');
  const [hiddenButton, setHiddenButtons] = useState<string[]>(["Buy", "Sell", "Swap", "Skills"]);

  const {
    data: portfolio,
    isLoading: areNextLoading
  } = useGetNextTournamentsQuery({count: 1, type: 'squad'});
  const {state} = useLocation();

  const {data: scorePayload, isLoading: areAnalyticsLoading} = useGetSquadAnalyticsQuery({
    tournamentId: portfolio && hasElements(portfolio) ? portfolio[0].id : '', pseudo: state?.pseudo
  }, {skip: !portfolio || !portfolio[0], refetchOnFocus: true, refetchOnMountOrArgChange: 120});

  const {data: portfolioPerf, isLoading: isPerformanceLoading} =
    useGetSquadPerfPercentageQuery({
      tournamentId: portfolio && hasElements(portfolio) ? portfolio[0].id : '',
      pseudo: state?.pseudo,
      range: range
    }, {skip: !portfolio || !portfolio[0], refetchOnFocus: true, refetchOnMountOrArgChange: 120});

  useEffect(() => {
    const now = DateTime.now();
    const dur = getDuration(range);
    const min = now.minus(dur);
    if (scorePayload && hasElements(scorePayload.historizedTotalValues)) {
      let sd = []
      for (const p of scorePayload.historizedTotalValues) {
        const valueDate = new Date(p.date);
        if (compareDates(valueDate, min.toJSDate()) >= 0) {
          sd.push({x: valueDate.getTime(), y: Number(p.value)});
        }
      }

      //add fake data to display graph
      if (sd.length === 0) {
        const d = {x: 0, y: 1}
        sd.push(d);
      }

      if (sd.length === 1) {
        const d = _.cloneDeep(sd[0]);
        d.x += 1000 * 60 * 60;
        sd.push(d);
      }
      setSeriesData(sd);
    }

  }, [scorePayload, range]);

  useEffect(() => {
    if (!portfolio || !portfolio[0] || portfolio[0].status !== 2) {
      setHiddenButtons(["Buy", "Sell", "Swap"]);
    } else if (!scorePayload) {
      setHiddenButtons(["Sell", "Swap"]);
    } else {
      setHiddenButtons([]);
    }

  }, [scorePayload, portfolio])

  const navigate = useNavigate();
  return (
    <Layout classes={"min-h-screen justify-end"}>
      {portfolio && portfolio[0] && portfolio[0].type === 'squad' &&
        <>
          <div
            className={classNames("w-screen h-screen fixed top-12 pt-10 p-4 pb-16 items-center flex flex-col flex flex-col bg-gradient-to-b from-orange-700 to-orange gap-8", (portfolio[0].status === 1 || portfolio[0].status === 2) ? '' : 'hidden')}>
            <div className="w-full max-w-md center flex flex-col">

              {/*======================== Nav Menu ========================*/}
              <div className={"relative flex w-full justify-center"}>
                {state?.pseudo &&
                  <span className={"absolute left-0"}><BackButton label={""} callback={() => navigate(-1)}/></span>
                }
                <div className={""}>
                  <SecondaryNavigation navItems={[
                    {path: '/portfolio', name: 'Portfolio'},
                    {path: `/tournament-ranking?_sa_t_id=${portfolio[0].id}`, name: 'Players'},
                  ]}/>
                </div>
              </div>

              {/*======================== Portfolio Title/Balance ========================*/}
              <div className="flex w-full flex-col gap-2 center mt-5">
                <h2
                  className="text-white text-opacity-80 text-xs font-bold text-center">{state?.pseudo ? state.pseudo : ''} Portfolio
                  Value</h2>

                <div className="text-white text-[32px] font-bold font-['Satoshi'] leading-none center gap-2">
                  <CoinIcon h={24}/>{getTotalValue(scorePayload).toFixed(1)}
                </div>
                <div className="justify-center items-center gap-2 inline-flex">
                  <div className="grow shrink basis-0 justify-end items-center gap-0.5 flex">
                    <div className="w-2 h-2 pt-0.5 flex-col justify-start items-center inline-flex"/>
                    <div
                      className="text-white text-opacity-80 text-sm font-bold font-['Satoshi']">{formatGainPercent(portfolioPerf?.performance || 0)}
                    </div>
                  </div>
                  <div className="justify-center items-center gap-0.5 flex">
                    <div className="text-white text-opacity-30 text-sm font-bold font-['Satoshi']">|</div>
                  </div>
                  <div
                    className="text-white text-opacity-80 text-sm font-bold font-['Satoshi']">{formatGain(portfolioPerf?.gain || 0)}</div>
                </div>
              </div>

              {/*======================== Chart ========================*/}
              <div className="flex flex-col gap-0">
                <div className={"w-screen max-w-3xl"}>
                  <AreaChart series={[{
                    name: 'Prices',
                    data: seriesData && hasElements(seriesData) ? seriesData : getEmptyTimeSeries(),
                  }]}/>
                </div>

                <div className={"flex w-full gap-x-10 cursor-pointer justify-center mb-5"}>
                  <span className={classNames("time-range-selector", range === '1H' ? 'selected' : '')}
                        onClick={() => setRange('1H')}>1H</span>
                  <span className={classNames("time-range-selector", range === '8H' ? 'selected' : '')}
                        onClick={() => setRange('8H')}>8H</span>
                  <span className={classNames("time-range-selector", range === '1D' ? 'selected' : '')}
                        onClick={() => setRange('1D')}>1D</span>
                  <span className={classNames("time-range-selector", range === '1W' ? 'selected' : '')}
                        onClick={() => setRange('1W')}>ALL</span>
                </div>
              </div>

              {!state?.pseudo &&
                <ActionButtons tournamentId={portfolio[0].id} hideButtons={hiddenButton}/>
              }
            </div>
          </div>

          <div
            className="relative mt-[23rem] w-screen bg-white p-6 rounded-tl-2xl rounded-tr-2xl pb-24 justify-start max-w-md min-h-[30rem]">
            <SquadAggrPositions showMarketPriceButton={true} tournament={portfolio[0]} pseudo={state?.pseudo}/>
          </div>
        </>
      }

      <div
        className={classNames(!portfolio || !portfolio[0] || (portfolio[0].status !== 1 && portfolio[0].status !== 2) ? '' : 'hidden', 'mb-56')}>
        Trading game will start soon
      </div>

    </Layout>
  );
};

export {
  PortfolioPage
}