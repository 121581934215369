import React, {useEffect, useState} from 'react';
import TemporaryDrawer from "../ui/temp-drawer.component";
import {disableBodyScroll, enableBodyScroll} from "../../utils/HtmlHelper";
import {classNames} from "../../utils/Helper";
import {GemsCostButton} from '../ui/secondary-gems-button.component';
import {useBuyProductWithGemsMutation} from '../../apis/shop.api';
import {ShopProduct} from "../../models/shop/Product";
import {toastSuccess} from "../../utils/ToastUtils";


interface GemsBuyableDrawerProductProps {
  open: boolean,
  onClose: Function,
  product: ShopProduct,
  icon: JSX.Element
}

const GemsBuyableDrawerProduct: React.FC<GemsBuyableDrawerProductProps> = ({
                                                                             open,
                                                                             onClose = () => {
                                                                             },
                                                                             product, icon
                                                                           }) => {

  const [buyProductWithGems] = useBuyProductWithGemsMutation();

  const [isBuying, setIsBuying] = useState(false);

  useEffect(() => {
    return () => enableBodyScroll();
  }, []);


  return (
    <>
      {/* { collectible && !userCollectibleLoading && */}
      {product &&
        <React.Fragment>
          <TemporaryDrawer
            title={product.label}
            closeWhenClickingOutside={true}
            anchor={'bottom'}
            open={open}
            lineIcon={false}
            onOpen={() => {
              disableBodyScroll()
            }}
            onClose={() => {
              enableBodyScroll()
              onClose();
            }}
            size="auto"
          >
          <div className={"w-full h-full flex flex-col justify-center items-center py-2 overflow-y-auto pb-9"}>


            <div className={"relative flat-card !p-4 mb-10"}>{icon}</div>

            <div className='w-full max-w-80'>

              {isBuying &&
                <GemsCostButton
                  label={"Buying..."}
                  quantity={product.displayedPrice}
                  css={classNames("disabled")}
                  onClick={() => {
                  }}
                />
              }

              {!isBuying &&

                <GemsCostButton
                  label={"Buy"}
                  quantity={product.displayedPrice}
                  css={classNames()}
                  onClick={async (e) => {

                    e.stopPropagation();
                    setIsBuying(true);

                    await buyProductWithGems({productCode: product.code}).then((data: any) => {

                      if (data.error) {
                        return;
                      }

                      toastSuccess('Item bought with success');

                      gtag('event', 'buyShopItemWithGems', {
                        inventory_item_code: product.code,
                        inventory_item_price: product.displayedPrice
                      });

                    }).finally(() => {
                      setIsBuying(false);
                      onClose();
                    });

                  }}
                />
              }

            </div>

          </div>
        </TemporaryDrawer>
        </React.Fragment>
      }
</>
)
;
}

export default GemsBuyableDrawerProduct;