import React from 'react'
import {QuantityCoinComponent} from "../ui/quantity-coin.component";
import {TrophyIcon} from "../ui/trophy-icon";
import {PlayerItem} from "../../models/User";
import {formatGainPercent} from "../../utils/Helper";

export interface WeekResultProps {
  player: PlayerItem,
  playersCount?: number
}

const WeekResultComponent: React.FC<WeekResultProps> = ({player, playersCount = 0}) => {
  return <div id={"week-result"}
              className={`relative flex flex-col flat-card items-center gap-y-2 w-full z-10 overflow-hidden !pb-0 !pr-0 !pl-0`}>

    <div className={"w-[90%] !rounded-lg flex flat-card !py-2"}>

      <div
        className={"w-24 h-24 p-[4px] bg-white rounded-lg fat-grey-shadow-2 border border-neutral-200 justify-start items-start gap-4 inline-flex overflow-hidden"}>
        <img crossOrigin={"anonymous"} className={"object-cover w-full h-full"}
             src={player.avatarUrl + '?' + new Date().getTime()}/>
      </div>

      <div className={"flex flex-col gap-y-2 justify-start flex-grow items-end"}>
        <div
          className={"px-0.5 py-0.5 bg-white rounded-lg border border-4  border-black border-opacity-10 justify-center items-center gap-x-2 flex text-xs"}>
          <TrophyIcon h={10}/>
          <span>
            <span className={"mr-1"}>RANK</span>
            <span>{player.previousWeekRank}</span>
            <span className={"text-opacity-40 text-black"}>|</span>
              <span>{playersCount}</span>
            </span>
        </div>


        <div className={"flex flex-col items-end "}>
          <div className={"text-[2.5rem] leading-8 md:text-5xl flex items-center gap-x-1"}>
            {player.previousWeekBeneficeAmount && player.previousWeekFrozenFinalMoneyAmount &&
              <>
                {formatGainPercent(100 * player.previousWeekBeneficeAmount / (player.previousWeekFrozenFinalMoneyAmount - player.previousWeekBeneficeAmount))}
              </>
            }
          </div>
          <div className={"text-opacity-30 text-xxxs"}>WEEKLY RETURN</div>
        </div>

      </div>
    </div>

    <div className={"flex justify-center"}>
      <span className={"p-2 px-4 bg-black rounded-xl text-white text-3xl"}>
        <QuantityCoinComponent size={30} quantity={player.previousWeekFrozenFinalMoneyAmount || 0}/>
      </span>
    </div>

    <img className={"z-10 object-cover relative"} crossOrigin={"anonymous"}
         src={`${process.env.REACT_APP_STATIC_ASSETS}/images/safe-full-of-tanji.png`}/>

  </div>
};
export {WeekResultComponent};