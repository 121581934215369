import React, {useState} from "react";
import {classNames} from "../../utils/Helper";
import {TrophyIcon} from "../ui/trophy-icon";
import TemporaryDrawer from "../ui/temp-drawer.component";
import {useNavigate} from "react-router-dom";

const CollectibleRewardComponent: React.FC<{
 widthCss?: string,
 displayWithTrophyIcon?: boolean
 displayInfoButton?: boolean

}> = ({widthCss = 'w-8', displayWithTrophyIcon = false, displayInfoButton = false}) => {

  const nav = useNavigate();
  const [showDrawer, setShowDrawer] = useState(false);

  return (
    <React.Fragment>

      <div className={classNames("relative", widthCss)} onClick={(e) => {
        setShowDrawer(true);
        e.stopPropagation();
      }}>

        { displayInfoButton ?
          <div className="nav-bar-button  w-8 h-[30px] center" > i </div>
          :
          <>
            <img className="object-cover" src={`${process.env.REACT_APP_STATIC_ASSETS}/images/collectible-card.png`}/>

            <div className={classNames("absolute -bottom-1 -right-1 z-10", displayWithTrophyIcon ? '' : 'hidden')}><TrophyIcon
              h={14}/>
            </div>
          </>
        }
      </div>

      <TemporaryDrawer title={`Prizes`} closeWhenClickingOutside={true} anchor={'bottom'}
                      open={showDrawer}
                      lineIcon={false}
                      onClose={() => {
                        setShowDrawer(false);
                      }}>
        <div className={"w-full h-full flex flex-col justify-between items-center py-2 overflow-y-auto"}>

          <div>
            <div className={"flex flex-col gap-y-2 items-center mb-8"}>
              <img className="w-16" src={`${process.env.REACT_APP_STATIC_ASSETS}/images/collectible-card.png`}/>
            </div>

            <div className={"flex flex-col gap-y-2"}>
              <p className={"font-normal text-center"}>
                Top players earn for free a random crypto card collectible.
              </p>
              <p className={"font-normal text-center"}>To know more about crypto card collectibles, you can check yours on
                profile page.
              </p>
            </div>
          </div>

          <div className={"mb-2"}>
            <button className={"primary"} onClick={()=>nav('/collectibles')}>Check Profile</button>
          </div>
        </div>
      </TemporaryDrawer>

  </React.Fragment>
)
}

export {CollectibleRewardComponent};