import React, {useEffect} from 'react';
import {Field, Form, Formik} from "formik";
import axios from "axios";
import {useUrlParameter} from "../utils/useUrlParameter";
import {useNavigate} from "react-router-dom";
import Layout from "../Layout";
import {PublicNavBar} from "../components/ui/public-nav-bar.component";
import {TanjiLogo} from "../components/ui/seein-logo.component";
interface MyFormValues {
  newPassword: string;
  newPasswordAgain: string;
}

const DoresetpwdPage: React.FC = () => {

  const initialValues: MyFormValues = {newPassword: '', newPasswordAgain: ''};
  const token: string | null = useUrlParameter('_sa_reset_pwd_token');
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate('/link_expired');
    }
  }, []);

  return (
    <Layout>
      <PublicNavBar/>
      <div className={"w-full rounded-2xl bg-orange p-4 max-w-xl justify-center flex"}><TanjiLogo/></div>
      {token &&
        <div className={"min-w-[300px] flex flex-col justify-center"}>
          <h2 className="text-gray-900 font-sans text-center">Choose a new password</h2>
          <Formik
            initialValues={initialValues}
            onSubmit={(values, actions) => {
              if (values.newPassword != values.newPasswordAgain) {
                alert('Password are not the same');
              } else {
                gtag('event', 'postDoResetPwdForm', {});
                axios.post('/users/doresetpwd', {
                  newPassword: values.newPassword,
                  newPasswordAgain: values.newPasswordAgain,
                  token: token,
                }).then(function (data) {
                  console.log('Response do reset : ' + JSON.stringify(data));
                  console.log('reset sent');
                  gtag('event', 'doResetPwdSuccess', {});
                  navigate('/login');
                }).catch(function (error) {
                  alert(error.response.data.message);
                  navigate('/link_expired');
                });
              }
            }}
          >
            <Form>
              <div id="form-content" className="form-card">
                <div>
                  <label htmlFor="newPassword">Enter your new password</label>
                  <Field type="password" id="newPassword" name="newPassword" placeholder="Password"
                         className={"input"}/>
                </div>
                <div>
                  <label htmlFor="newPasswordAgain">Confirm your new password</label>
                  <Field type="password" id="newPasswordAgain" name="newPasswordAgain" placeholder="Password"
                         className={"input"}/>
                </div>

                <button type="submit" className={"primary"}>Submit</button>
              </div>
            </Form>

          </Formik>
        </div>
      }
    </Layout>
  );
};

export {DoresetpwdPage}