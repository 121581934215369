import React from 'react'
import {FullScreenDialog} from "../ui/modal-confirmation";
import {StarIcon} from "../ui/star-icon";
import {UserNotification} from "../../models/User";
import {useGetCollectibleQuery} from "../../apis/collectibles.api";
import {CollectibleCardComponent} from "../collectibles/collectible-card.component";
import {StarDeactivatedIcon} from "../ui/star-deactivated-icon";


const BigTradeNotifComponent: React.FC<{
  notification: UserNotification,
  timer: number,
  visible: boolean, onClose: Function
}> = ({timer, visible, notification, onClose}) => {

  const {data: collectible} = useGetCollectibleQuery(notification.payload.cryptoId);

  return <FullScreenDialog openProp={visible} timer={timer}
                           onClose={() => onClose()}>
    <div className={"h-full flex flex-col gap-y-12 justify-center items-center"}>

      <div className={"w-full flex-col flex gap-3 justify-center items-center"}>
        <div
          className={"w-full  flex flex-col gap-y-2 rounded-lg bg-black bg-opacity-20 text-white font-bold px-4 py-2 text-lg text-center"}>
          {notification.payload.action === 'CRYPTO_COLLECTIBLE_ACTION_GOOD_TRADE_1' && `Nice Trade !`}
          {notification.payload.action === 'CRYPTO_COLLECTIBLE_ACTION_GOOD_TRADE_2' && `Big Trade ! `}
          {notification.payload.action === 'CRYPTO_COLLECTIBLE_ACTION_GOOD_TRADE_3' && `Amazing Trade ! `}

          <div className={"flex justify-center w-full"}>
            {notification.payload.action === 'CRYPTO_COLLECTIBLE_ACTION_GOOD_TRADE_1' &&
              <><StarIcon h={32}></StarIcon><StarDeactivatedIcon h={32}></StarDeactivatedIcon><StarDeactivatedIcon
                h={32}></StarDeactivatedIcon></>}
            {notification.payload.action === 'CRYPTO_COLLECTIBLE_ACTION_GOOD_TRADE_2' && <>
              <StarIcon h={32}></StarIcon><StarIcon h={32}></StarIcon><StarDeactivatedIcon
              h={32}></StarDeactivatedIcon></>}
            {notification.payload.action === 'CRYPTO_COLLECTIBLE_ACTION_GOOD_TRADE_3' && <>
              <StarIcon h={32}></StarIcon><StarIcon h={32}></StarIcon><StarIcon h={32}></StarIcon></>}
          </div>
        </div>
      </div>
      <div className={"text-white text-center flex flex-col justify-center items-center gap-y-4"}>
        {collectible && <CollectibleCardComponent collectible={collectible} animateProgressBar={true}/>}
        {/*{`You just won ${notification?.payload?.xp || ''} xp for your ${notification?.payload.crypto} collectible (${notification.payload.action})!`}*/}
        <div
          className={" glass-dark p-4 rounded-2xl"}><span
          className={"text-2xl text-green-500"}>+{notification?.payload?.xp || ''} </span>Trading
          XP
        </div>
      </div>
    </div>
  </FullScreenDialog>
}

export {BigTradeNotifComponent};