import React, { useEffect } from "react";

import TemporaryDrawer from "../ui/temp-drawer.component";
import {disableBodyScroll, enableBodyScroll} from "../../utils/HtmlHelper";

interface ReferralCountInfoProps {

}

interface AccordionIconProps {
  id:number,
  open:number,
}

const AccordionIcon: React.FC<AccordionIconProps> = ({id, open}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform fill-black`}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
    </svg>
  );
}

const ReferralCountInfo: React.FC<ReferralCountInfoProps> = () => {

  const [open, setOpen] = React.useState(false);

  const openDrawer = () => setOpen(true);
  const closeDrawer = () => setOpen(false);

  useEffect(() => {
    return () => enableBodyScroll();
  }, []);

  return (
    <React.Fragment>

      <div onClick={openDrawer}
        className="cursor-pointer w-8 h-[30px] bg-orange rounded-lg fat-orange-shadow-2 border-1 border-orange-700 center gap-2 inline-flex text-white"
      >
        i
      </div>

      <TemporaryDrawer title={`Confirmed Referred Friends`} anchor={'bottom'} open={open}
        closeWhenClickingOutside={true}
        lineIcon={false}
        onOpen={() => {
          disableBodyScroll()
        }}
        onClose={() => {
          enableBodyScroll()
          closeDrawer();
        }}
        size="auto"
      >

        <div className="h-full content-center overflow-y-auto pb-8">

            <div className={"w-full flex justify-center"}>
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.52 1.24127C15.7494 0.740598 14.7174 0.959932 14.2147 1.73193L6.95735 12.9179L3.60468 9.81793C2.92868 9.1926 1.87402 9.2346 1.24935 9.90993C0.624684 10.5859 0.666018 11.6406 1.34202 12.2653L6.14802 16.7093C6.46802 17.0059 6.87468 17.1526 7.27868 17.1526C7.72735 17.1526 8.30135 16.9646 8.67802 16.3933C8.89935 16.0559 17.0114 3.5466 17.0114 3.5466C17.512 2.77393 17.292 1.74193 16.52 1.24127Z" fill="#EA5C27"/>
              </svg>
            </div>

            <div className={"flex flex-col mt-4 items-center"}>
              <p className="text-center">
                Once invited in the app,<br/>
                your friends receive instantly 2000 Tanjicoins.
              </p>
            </div>

            <div className={"w-full flex justify-center mt-4"}>
              <p className="text-center">
                To be considered as confirmed, <br/>
                they have to earn 5 Gems.
              </p>
            </div>

          <div className={"w-full flex justify-center mt-4"}>
            <p className="text-center">
              If they play 3 days in a row, they will receive <br/>
              8 gems for free.
            </p>
          </div>


        </div>

      </TemporaryDrawer>
    </React.Fragment>
  );

}

export default ReferralCountInfo;