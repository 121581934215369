import React, { useEffect } from "react";
import { Button } from "@material-tailwind/react";

import TemporaryDrawer from "../ui/temp-drawer.component";
import {disableBodyScroll, enableBodyScroll} from "../../utils/HtmlHelper";

import { GemsIcon } from "./gems-icon";
import RightPointingIcon from "../svg/RightPointingIcon";

interface CollectibleInfoProps {

}

interface AccordionIconProps {
  id:number,
  open:number,
}

const AccordionIcon: React.FC<AccordionIconProps> = ({id, open}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform fill-black`}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
    </svg>
  );
}

const CollectibleInfo: React.FC<CollectibleInfoProps> = () => {

  const [open, setOpen] = React.useState(false);

  const openDrawer = () => setOpen(true);
  const closeDrawer = () => setOpen(false);

  useEffect(() => {
    return () => enableBodyScroll();
  }, []);

  return (
    <React.Fragment>

      <div className="nav-bar-button w-8 h-[30px] center" onClick={openDrawer}> i </div>

      <TemporaryDrawer title={`Collectibles`} anchor={'bottom'} open={open}
        closeWhenClickingOutside={true}
        lineIcon={false}
        onOpen={() => {
          disableBodyScroll()
        }}
        onClose={() => {
          enableBodyScroll()
          closeDrawer();
        }}
        size="auto"
      >


        <div className="h-full content-center overflow-y-auto pb-8 font-normal">

            <div className={"flex mb-2 center"}>

              <img className="w-16"
             src={`${process.env.REACT_APP_STATIC_ASSETS}/images/collectible-card.png`}/>

            </div>

            <div className={"text-center text-inherit"}>
              <p className={"text-inherit"}>Collectibles give you lifetime advantages!</p>
            </div>

            <div className={"flex mt-4 center text-inherit"}>
              <p className={"text-inherit"}>Invest and bet in-app on cryptos to <span className={"font-semibold"}>gain</span>
                <div className="w-min h-[21px] ml-1 px-1.5 py-0.5 bg-black bg-opacity-30 rounded border border-black border-opacity-10 justify-start items-center gap-1 inline-flex">
                    <div className="justify-center items-center gap-1 flex">
                      <div className="opacity-50 text-white text-sm font-bold leading-[17px]">XP</div>
                    </div>
                </div>
              </p>
            </div>

            <div className={"flex mt-2 center text-inherit"}>
              <p className={"text-inherit"}><span className={"font-semibold"}>Level-up</span> your collectibles with</p>
              <div className="w-min h-[21px] ml-1 px-1.5 py-0.5 bg-black bg-opacity-30 rounded border border-black border-opacity-10 justify-start items-center gap-1 inline-flex">
                  <div className="justify-center items-center gap-1 flex">
                    <div className="opacity-50 text-white text-sm font-bold leading-[17px]">XP</div>
                  </div>
              </div>
            </div>

            <div className="flex mt-2 center text-inherit">
              <p className={"text-inherit"}>Use</p>
              <div className={"max-w-fit mx-1 bordered border-2"}>
                <GemsIcon h={12}/>
              </div>
              <p className={"text-inherit"}>to<span className={"font-semibold"}> unlock </span>your collectible bonus</p>
            </div>

            <div className={"flex mt-4 center text-inherit"}>
                <div className={"max-w-fit flex content-center"}>
                  <div className={"w-[20px]"}>
                    <RightPointingIcon/>
                  </div>
                </div>
                <div className={"ml-1 text-inherit"}>
                  <p className={"text-inherit"}>Crypto Cards Collectibles can become <span className={"font-semibold"}>NFTs</span></p>
                </div>
            </div>

        </div>

      {/* </Drawer> */}
      </TemporaryDrawer>
    </React.Fragment>
  );

}

export default CollectibleInfo;