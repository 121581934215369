import React, {useMemo} from 'react';
import {LoginForm} from "../components/auth/login-form/login-form.component";
import {AuthNav} from "../components/auth/auth-navigation.component";
import {BkTanjiIcon} from "../components/ui/bk-tanji-icon";
import UnauthenticatedLayout from "../UnauthenticatedLayout";

const LoginPage: React.FC = () => {
  const triggerTwitter = useMemo<boolean>(() => !!new URLSearchParams(window.location.search).get('triggerTwitterLogin') || false, []);
  const triggerDiscord = useMemo<boolean>(() => !!new URLSearchParams(window.location.search).get('triggerDiscordLogin') || false, []);
  const triggerGoogle = useMemo<boolean>(() => !!new URLSearchParams(window.location.search).get('triggerGoogleLogin') || false, []);

  return (<>
      <div className={"fixed w-screen h-screen bg-orange z-0 flex justify-end items-center"}><BkTanjiIcon factor={1.3}/>
      </div>
      <UnauthenticatedLayout>
        <AuthNav/>
        <div
          className={"flex w-full justify-start items-center flex-col"}>
          {triggerTwitter && <>
            <img className={"img-scaling"} width={100} height={100}
                 src={process.env.REACT_APP_STATIC_ASSETS + "/images/twitter.webp"}/>
            <h1 className={""}>Redirecting to Twitter...</h1>
          </>}
          {triggerDiscord && <>
            <img className={"img-scaling"} width={100} height={100}
                 src={process.env.REACT_APP_STATIC_ASSETS + "/images/discord.webp"}/>
            <h1 className={""}>Redirecting to Discord...</h1>
          </>}
          {triggerGoogle && <>
            <img className={"img-scaling"} width={100} height={100}
                 src={process.env.REACT_APP_STATIC_ASSETS + "/images/google.webp"}/>
            <h1 className={""}>Redirecting to Google...</h1>
          </>}

          <div
            className={"flex w-full center" + ((triggerTwitter === true || triggerDiscord === true || triggerGoogle === true) ? 'hidden' : '')}>
            <LoginForm triggerTwitterLogin={triggerTwitter} triggerDiscordLogin={triggerDiscord}
                       triggerGoogleLogin={triggerGoogle}/>
          </div>
        </div>
      </UnauthenticatedLayout>
    </>
  );
};

export {LoginPage};