import React from "react";


const Podium2ndIcon: React.FC<{ h?: number }> = ({h = 24}) => 

<svg width="106" height="84" viewBox="0 0 106 84" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.678 0L0 8H106V0H11.678Z" fill="white" fillOpacity="0.8"/>
<path d="M106 76H0V84H106V76Z" fill="white"/>
<path d="M106 80H0V8H106V80Z" fill="white"/>
<path d="M62.5578 70.92L41.4378 71V66.2L51.1978 58.08C54.3578 55.48 55.6778 53.64 55.6778 51.2C55.6778 48.2 54.1978 46.6 51.5578 46.6C48.7978 46.6 47.1978 48.48 47.1978 51.8H40.7578C40.7578 45.16 44.9978 40.84 51.5178 40.84C58.2778 40.84 62.3178 44.48 62.3178 50.8C62.3178 54.84 59.8778 58.12 56.1978 61.2L51.7578 64.92H62.5578V70.92Z" fill="#EA5C28"/>
</svg>


export {Podium2ndIcon};