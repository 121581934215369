import React, {useEffect, useState} from 'react'
import {FullScreenDialog} from "../ui/modal-confirmation";
import {UserNotification} from "../../models/User";
import {useGetProductsQuery} from "../../apis/shop.api";
import {ShopProduct} from "../../models/shop/Product";
import {ShushingIcon} from "../ui/shushing-icon";
import ProductDisplay from '../shop/product-display.component';

const GemsDiscountNotifComponent: React.FC<{
  notification: UserNotification,
  timer: number,
  visible: boolean, onClose: Function
}> = ({timer, visible, notification, onClose}) => {

  const {
    data: products,
    isLoading: areProductsLoading
  } = useGetProductsQuery({family: 'simple'});

  const [prod, setProd] = useState<ShopProduct | undefined>(undefined);

  useEffect(() => {
    if (products) {
      setProd(products.find(p => p.code === `gems_pack_${notification.payload.gemsPackDiscounted}`))
    }
  }, [products]);

  useEffect(() => {
    if (prod) {
      console.log(JSON.stringify(prod));
    }
  }, [prod])

  return <FullScreenDialog openProp={visible} timer={timer}
                           onClose={onClose}>
    <div className={"h-full flex flex-col gap-y-16 justify-center items-center"}>
      <div className={"flex flex-col gap-y-2"}>
        <ShushingIcon h={50}/>
      </div>

      <div className={"flex-col flex gap-3 justify-center items-center"}>
        <div
          className={"rounded-lg bg-black bg-opacity-20 text-white font-bold px-2 py-2 text-lg text-center"}>
          Psst! Keep this a secret
        </div>

        <div className={"text-white text-center"}>
          {`Take over the leaderboard with an additional GEM boost !`}
        </div>

        {prod &&
          <div className={"flat-card !p-0"}>
            <ProductDisplay externalId={prod?.externalId} image={prod?.image1} code={prod?.code}
                            price={prod?.displayedPrice} discountPrice={prod?.displayedDiscountPrice}/>
          </div>
        }
      </div>
    </div>
  </FullScreenDialog>;
}

export {GemsDiscountNotifComponent};