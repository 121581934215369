import React from "react";


const BkTanjiIcon: React.FC<{ factor?: number }> = ({factor = 1}) => <svg width={390 * factor} height={735 * factor}
                                                                          viewBox="0 0 390 735" fill="none"
                                                                          xmlns="http://www.w3.org/2000/svg">
  <path
    d="M419.842 508.749L322.077 565.242L224.311 621.735L224.311 395.689L322.077 452.182L419.842 508.675V395.674L324.378 340.475L224.311 282.614V56.493L126.531 0V226.091L28.7655 169.598L-69 226.091L30.0569 283.372L126.546 339.137V452.212V678.288L224.326 734.781L322.092 678.288L419.857 621.795L419.842 508.749Z"
    fill="url(#paint0_linear_1694_8221)" fillOpacity="0.1"/>
  <path
    d="M129.531 226.091V5.19797L221.311 58.2245V282.614V284.345L222.81 285.211L322.876 343.072L416.842 397.405V503.477L323.578 449.585L225.812 393.091L221.311 390.491V395.689L221.311 621.735V626.934L225.812 624.333L323.578 567.84L416.843 513.947L416.857 620.064L320.591 675.69L224.326 731.316L129.546 676.556V452.212V339.137V337.405L128.047 336.539L31.5587 280.775L31.5581 280.775L-63.0055 226.092L28.7655 173.063L125.03 228.689L129.531 231.289V226.091Z"
    stroke="url(#paint1_linear_1694_8221)" strokeOpacity="0.2" strokeWidth="6"/>
  <defs>
    <linearGradient id="paint0_linear_1694_8221" x1="175.429" y1="0" x2="175.429" y2="734.781"
                    gradientUnits="userSpaceOnUse">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="white" stopOpacity="0"/>
    </linearGradient>
    <linearGradient id="paint1_linear_1694_8221" x1="175.429" y1="0" x2="175.429" y2="734.781"
                    gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stopOpacity="0"/>
      <stop offset="1" stopColor="white"/>
    </linearGradient>
  </defs>
</svg>


export {BkTanjiIcon};