import React from 'react';

const HandShakeIcon = () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.0004 18C17.0004 18 10.9694 23.274 9.26045 24.832C7.93745 26.035 10.1404 28.33 11.3954 27.284C14.7284 24.499 17.8884 22.434 17.8884 22.434L18.2554 22.96C16.9464 23.75 13.5624 25.818 11.8064 27.398C10.4824 28.588 12.5734 30.951 13.8994 29.763C15.7214 28.129 19.8944 25.198 19.8944 25.198L20.2624 25.582C19.1824 26.261 17.4904 27.522 15.2774 29.54C13.9454 30.755 15.8964 32.025 17.0414 31.074C20.1574 28.488 20.2734 26.691 23.7024 25.224C28.9354 22.986 17.0004 18 17.0004 18Z" fill="#EF9645"/>
<path d="M29.9786 8.83598C30.8596 8.39798 31.6316 8.97998 32.0846 9.88898C32.5326 10.8 34.6166 14.904 35.0376 15.758C35.4626 16.61 35.2286 17.861 34.3186 18.311C33.4076 18.764 32.0846 19.365 31.2266 20.823C30.7616 21.617 28.8306 23.71 23.5976 25.952C20.1706 27.414 17.4326 29.89 16.3366 30.752C15.4506 31.45 13.6636 30.115 14.9936 28.902C17.2076 26.882 18.8996 25.621 19.9786 24.944L19.6106 24.558C19.6106 24.558 15.2296 27.614 13.3726 29.208C12.1116 30.293 10.4346 28.255 11.7596 27.066C13.5166 25.486 16.6616 23.112 17.9706 22.322L17.6046 21.793C17.6046 21.793 14.1746 24.024 10.8396 26.813C9.72657 27.743 7.99257 25.654 9.31457 24.449C11.0246 22.894 14.3576 20.106 16.4076 18.95L16.2116 18.341C16.2116 18.341 12.9936 20.146 10.2156 22.835C9.22157 23.796 7.24157 22.017 8.69057 20.542C12.1526 17.02 16.6486 14.933 17.8446 14.129C19.4506 13.049 20.2956 12.375 19.4506 11.996C18.1766 11.423 16.0546 9.41398 15.2406 7.78598C14.1876 5.67998 15.6806 4.01598 17.3456 5.67998C18.3986 6.73298 20.5046 8.83798 22.6086 8.83798C25.4376 8.83798 25.8706 9.89098 27.8726 9.89098C28.9266 9.88898 29.9786 8.83598 29.9786 8.83598Z" fill="#FFCC4D"/>
<path d="M25.0471 15.167C24.7131 15.168 23.6081 15.017 22.3631 14.442C21.2901 13.948 20.4581 13.052 19.7661 12.3C19.8051 12.461 19.7011 12.654 19.4831 12.88C20.1641 13.672 21.2061 14.576 22.1371 15.026C23.2191 15.551 24.4521 15.816 25.0081 15.793C25.5801 15.769 25.3931 15.171 25.0471 15.167ZM27.2141 23.747C24.9501 22.693 23.1521 21.813 26.2401 23.087C27.5171 23.612 29.4341 21.027 27.5551 20.17C23.7731 18.444 11.9991 12 11.9991 12C11.9991 12 5.6311 19.437 6.4191 19.914C7.2081 20.388 7.5371 23.763 12.0841 26.732C16.3821 29.54 19.0591 30.747 20.0651 31.25C22.1721 32.303 23.5421 29.572 21.8981 28.838C19.0651 27.572 19.1641 27.495 23.3161 29.22C24.8531 29.86 26.1451 26.97 24.5211 26.239C22.0991 25.15 21.8811 25.018 25.7601 26.766C27.1951 27.409 28.8331 24.502 27.2141 23.747Z" fill="#EF9645"/>
<path d="M7.08961 9.278C6.24761 8.769 5.12961 9.022 4.60461 9.893C4.07961 10.764 1.77661 14.576 1.28561 15.393C0.793613 16.209 0.921613 17.472 1.79161 17.997C2.66161 18.523 4.23861 19.474 5.02761 19.949C5.81661 20.426 9.03861 24.426 13.6896 27.228C18.3376 30.036 20.0556 30.514 21.1016 30.925C22.1516 31.335 23.5386 29.242 21.8946 28.509C19.0576 27.241 17.0586 26.071 16.0356 25.304L16.3536 24.719C16.3536 24.719 19.1906 26.839 23.3406 28.567C24.8776 29.207 26.1306 26.813 24.5076 26.08C22.0846 24.991 19.0956 23.186 17.8566 22.29L18.2026 21.719C18.2026 21.719 22.1486 24.213 26.0276 25.961C27.4586 26.606 28.6596 24.175 27.0406 23.419C24.7806 22.365 21.5926 20.464 19.6466 19.142L19.9846 18.642C19.9846 18.642 23.5996 21.193 26.6896 22.465C27.9666 22.993 29.4696 20.604 27.5886 19.744C23.8096 18.019 19.5246 15 18.4006 14.1C17.4826 13.363 17.2196 11.687 19.4526 10.942C22.6106 9.889 23.6636 7.784 23.6636 6.731C23.6636 5.242 22.2876 4.585 21.5596 5.678C19.4526 8.836 18.3936 7.76 16.2966 8.836C13.7826 10.127 10.8306 11.498 9.11761 10.464C8.38461 10.023 7.08961 9.278 7.08961 9.278Z" fill="#FFDC5D"/>
<path d="M18.9471 11.721L24.3731 9.00901C23.8961 8.90701 23.333 8.83701 22.6101 8.83701C20.5061 8.83701 18.4 6.73201 17.347 5.67901C15.682 4.01501 14.1891 5.67901 15.242 7.78501C15.957 9.21301 17.6751 10.933 18.9471 11.721Z" fill="#FFCC4D"/>
</svg>
);

export default HandShakeIcon;
