import { getAllAchievementByTypeDto } from "../dto/achievement/get-all-achievement-by-type-response.dto";
import { getAllAchievementOtherByTypeDto } from "../dto/achievement/get-all-achievement-other-by-type-response.dto";
import { getAllAchievementTypeOtherDto } from "../dto/achievement/get-all-achievement-other-type.dto";
import { getAllAchievementTypeDto } from "../dto/achievement/get-all-achievement-type.dto";
import { emptySplitApi } from "./empty.api";


export const achievementsApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    getAchievements: build.query<getAllAchievementTypeDto[], void>({
      query: () => ({ url: "/api/achievements", method: "GET" }),
    }),

    getAchievementsByType: build.query<getAllAchievementByTypeDto,
      string
    >({
      query: (type) => ({
        url: `/api/achievements/${type}`,
        method: "GET",
      }),
    }),

    getAchievementsOther: build.query<
      getAllAchievementTypeOtherDto,
      string
    >({
      query: (username) => ({
        url: `/api/achievements/other/${username}`,
        method: "GET",
      }),
    }),

    getAchievementsOtherByType: build.query<
      getAllAchievementOtherByTypeDto,
      [string, string]
    >({
      query: (args: [string, string]) => ({
        url: `/api/achievements/other/${args[0]}/${args[1]}`,
        method: "GET",
      }),
    }),


    achievementAsNotified: build.mutation<void, string>({
      query: (id) => ({
        url: `/api/achievements/${id}/notified`,
        method: "POST",
        body: {},
      })
    }),

  }),
});

export const { useGetAchievementsQuery, useGetAchievementsByTypeQuery, useGetAchievementsOtherQuery, useGetAchievementsOtherByTypeQuery, useAchievementAsNotifiedMutation } = achievementsApi;
