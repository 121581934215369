import React from "react";


const StarIcon: React.FC<{ h?: number }> = ({h = 16}) => <svg width={h} height={h} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.56429 0.385703L10.3502 4.81742C10.3934 4.92497 10.464 5.01819 10.5544 5.08703C10.6448 5.15588 10.7515 5.19773 10.863 5.20807L15.4342 5.61954C15.5555 5.62969 15.6712 5.67708 15.7666 5.75571C15.862 5.83433 15.9329 5.94066 15.9703 6.06124C16.0076 6.18183 16.0098 6.31124 15.9765 6.43311C15.9432 6.55498 15.876 6.66383 15.7833 6.74588L12.3152 9.89595C12.2309 9.97306 12.1683 10.0725 12.1339 10.1837C12.0995 10.2949 12.0947 10.4137 12.12 10.5276L13.1594 15.2139C13.1868 15.3362 13.1791 15.4644 13.1372 15.5822C13.0952 15.7001 13.0208 15.8025 12.9234 15.8766C12.8259 15.9508 12.7097 15.9935 12.5891 15.9993C12.4685 16.0052 12.3489 15.9739 12.2453 15.9095L8.31674 13.4279C8.22129 13.3674 8.11172 13.3355 8 13.3355C7.88828 13.3355 7.77871 13.3674 7.68326 13.4279L3.75472 15.9095C3.65106 15.9739 3.53149 16.0052 3.41091 15.9993C3.29033 15.9935 3.17407 15.9508 3.07661 15.8766C2.97915 15.8025 2.90481 15.7001 2.86285 15.5822C2.82088 15.4644 2.81315 15.3362 2.84062 15.2139L3.88003 10.5276C3.9053 10.4137 3.90048 10.2949 3.8661 10.1837C3.83172 10.0725 3.76905 9.97306 3.68476 9.89595L0.216727 6.74588C0.12401 6.66383 0.0567522 6.55498 0.0234632 6.43311C-0.00982575 6.31124 -0.00764944 6.18183 0.0297166 6.06124C0.0670827 5.94066 0.137959 5.83433 0.233378 5.75571C0.328797 5.67708 0.44447 5.62969 0.56576 5.61954L5.13701 5.20807C5.24854 5.19773 5.35524 5.15588 5.4456 5.08703C5.53597 5.01819 5.60657 4.92497 5.6498 4.81742L7.43571 0.385703C7.48341 0.271125 7.56224 0.173569 7.6625 0.10504C7.76276 0.036511 7.88007 0 8 0C8.11993 0 8.23724 0.036511 8.3375 0.10504C8.43776 0.173569 8.51659 0.271125 8.56429 0.385703Z" fill="url(#paint0_linear_1615_15359)"/>
    <defs>
      <linearGradient id="paint0_linear_1615_15359" x1="8" y1="0" x2="8" y2="16" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FEC706"/>
        <stop offset="1" stopColor="#FDC605"/>
      </linearGradient>
    </defs>
  </svg>;

export {StarIcon};