import React from "react";
import {CoinIcon} from "./coin-icon";
import {classNames, formatBigNumber} from "../../utils/Helper";

type Props = {
  gain: number;
  size?: number;
  noColor?: boolean,
  big?:  boolean
};


function formatBig(gain: number): string {
  if (gain >= 100000) {
    return formatBigNumber(gain)
  } else {
    return String(gain);
  }
}

const GainCoinComponent: React.FC<Props> = ({gain = 0, size, noColor = false, big}) => {
  return (
    <div className={classNames("flex justify-center items-center text-inherit" , (gain === 0 || noColor) ? '' : (gain >= 0 ? "!text-green-500" : "!text-red-500"))}>
      <CoinIcon h={size}/>
      <span className={classNames("text-inherit mx-2 font-bold", big ? "text-xl" : "")}>
        {(gain > 0 ? '+' : '') + formatBig(Math.round(gain))}
      </span>
    </div>
  )
}

export {GainCoinComponent};

