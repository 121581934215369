import React, {useState} from "react";
import {classNames} from "../../utils/Helper";
import {LockIcon} from "./lock-icon";
import {AlertTitle, Snackbar} from "@mui/material";
import Alert from "@mui/material/Alert";
import {getBottomAlertSx} from "./MuiStyle";

type Props = {
  size?: number,
  css?: string | string[],
  label: string,
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined,
  lockMessage?:string,
};

const LockedButton: React.FC<Props> = ({lockMessage,label, size = 12, css = '', onClick}) => {
  const [showAlert, setShowAlert] = useState(false);
  return (
    <><button
      className={classNames("w-full secondary flex justify-center cursor-pointer", css)}
      onClick={onClick ? onClick : ()=>{
        setShowAlert(true);
      }}>

      <span className="uppercase">{label}</span>

      <div
        className={classNames("absolute right-0 mr-2 h-8 max-w-[56px] p-0.5 bg-black bg-opacity-20 rounded-md border border-black border-opacity-5 justify-start items-start gap-1 inline-flex")}>
        <div
          className="self-stretch w-full px-2 py-0.5 bg-transparent rounded border border-black border-opacity-20 justify-center items-center gap-1 flex">
          <div className="text-white text-sm font-bold font-['Satoshi'] leading-[17px]">
            <LockIcon h={size}/>
          </div>
        </div>
      </div>
    </button>

      {showAlert &&
        <Snackbar sx={{bottom: '75px!important', left:"20px",right:"20px"}} anchorOrigin={{vertical: 'bottom', horizontal: 'center'}} open={true}
                  autoHideDuration={6000} onClose={() => {
          setShowAlert(false)
        }}>
          <Alert className={"flat-card"} sx={getBottomAlertSx()} severity="error">
            <AlertTitle>Oops</AlertTitle>
            <span className={"text-gray-500"}>{lockMessage}</span>
          </Alert>
        </Snackbar>
      }
    </>
  )
}

export {LockedButton};

