import React from "react";


const InvestIcon: React.FC<{ h?: number,color?:string }> = ({h = 17,color='black'}) => <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_2535_14705)">
<path d="M17.5 16.25H2.5V3.75" stroke={`${color}`} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M15.625 5.625L10 11.25L7.5 8.75L2.5 13.75" stroke={`${color}`} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M15.625 8.75V5.625H12.5" stroke={`${color}`} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
</g>
<defs>
<clipPath id="clip0_2535_14705">
<rect width="20" height="20" fill="white"/>
</clipPath>
</defs>
</svg>
;

export {InvestIcon};