import React from "react";
import {Navigate} from "react-router-dom";
import {useGetUserQuery} from "../apis/users.api";
import {NavBar} from "../components/ui/nav-bar.component";
import {FootBar} from "../components/ui/foot-bar.component";
import usePushNotifications from "../hooks/usePushSubscription";
import {ServerSentEventNotif} from "../components/ServerSentEventNotif";

const ProtectedRoute: React.FC<any> = ({hideAppFooter = false, children}) => {
  const {data: user, isLoading} = useGetUserQuery(undefined);
  usePushNotifications();

  if (isLoading) {
    return <></>;
  } else {
    return user ? <><NavBar/>{children}<FootBar/><ServerSentEventNotif/></> : <Navigate to="/signup"/>;
  }
}
export {ProtectedRoute};