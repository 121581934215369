import {useCallback, useEffect, useState} from "react";

export function useIsDocumentVisible(visibilityChangeCallback?: Function) {
  const [isVisible, setIsVisible] = useState(true);

  const handleActivity = useCallback((data: any) => {
    // console.log(`visibility change : ${document.visibilityState}`);
    setIsVisible(document.visibilityState === 'visible');
    if (visibilityChangeCallback) visibilityChangeCallback(document.visibilityState === 'visible');
  }, []);

  // useEffect(() => {
  //   console.log(`isVisible:${isVisible}, document.visibilityState=${document.visibilityState}`);
  // }, [isVisible]);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleActivity)
    // document.addEventListener('blur', handleActivity)
    // window.addEventListener('blur', handleActivity)
    // window.addEventListener('focus', handleActivity)
    // document.addEventListener('focus', handleActivity)

    return () => {
      // window.removeEventListener('blur', handleActivity)
      // document.removeEventListener('blur', handleActivity)
      // window.removeEventListener('focus', handleActivity)
      // document.removeEventListener('focus', handleActivity)
      document.removeEventListener('visibilitychange', handleActivity)
    }
  }, []);

  return {isVisible};
}
