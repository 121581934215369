import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const logoutApi = createApi({
  reducerPath: 'logoutApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/auth'
  }),
  endpoints: (build) => ({
    logout: build.query<undefined, undefined>({
      query: () => ({
        url: "/logout",
        method: "POST",
        body: {},
      })
    })
  })
})

export const { useLogoutQuery } = logoutApi;