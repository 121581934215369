import { CollectibleAmountComponent } from "../ui/collectible-amount.component";
import { GemsAmountComponent } from "../ui/gems-amount.component";
import { QuantityGemsComponent } from "../ui/quantity-gems.component";

export enum TreasureSizeTypes {
  SMALL = 'SMALL',
  LARGE = 'LARGE',
}

export enum TreasureItemTypes {
  GEMS = 'GEMS',
  COLLECTIBLES = 'COLLECTIBLES'
}

export interface TreasureItem {
  type:TreasureItemTypes,
  amount:number,
  level: number
}

export interface TreasureInterface {
  code: string,
  size: TreasureSizeTypes,
  title: string,
  subtitle: string,
  description: string,
  displayedPrice: number,
  displayedDiscountPrice: number,
  items:TreasureItem[],
  image1:string,
}

function treasureItem(item:TreasureItem) {
  switch(item.type) {
    case TreasureItemTypes.COLLECTIBLES:
      return <CollectibleAmountComponent quantity={item.amount}/>;
    case TreasureItemTypes.GEMS:
      return <GemsAmountComponent quantity={item.amount}/>;
    default:
      return;
  }
}

interface TreasureItemsProps {
  items: TreasureItem[]
}

const TreasureItems: React.FC<TreasureItemsProps> = ({items}) => {
  return <>
    { items.map((item, index) => {
      return <div key={index}> {treasureItem(item)} </div>
      })
    }
  </>;
}

interface LargeTreasureComponentProps {
  treasure: TreasureInterface
  onClick:Function
}

const LargeTreasureComponent: React.FC<LargeTreasureComponentProps> = ({treasure, onClick}) => {

  const treasurePrice = treasure.displayedDiscountPrice ? treasure.displayedDiscountPrice : treasure.displayedPrice;

  return (

    <div className="w-full flat-card flex flex-col items-center gap-y-2 !pb-1 !pt-2 !px-2 max-w-[308px]"
      onClick={()=>onClick()}
    >

      <div className="w-full flex bg-grey-100 rounded-lg p-2 relative">

        <div className="absolute top-2 right-2 flex flex-row-reverse gap-2">
          <TreasureItems items={treasure.items}/>
        </div>

        <div className="flex-1 center">
          <img className="w-32" src={`${process.env.REACT_APP_STATIC_ASSETS}/images/${treasure.image1}`} alt={treasure.title}/>
        </div>

        <div className="flex-1 flex-col content-center">

          <p className="font-BioRhyme font-bold text-base">{treasure.title}</p>
          <p className="font-medium text-xs">{treasure.subtitle}</p>

        </div>

      </div>

      <QuantityGemsComponent quantity={treasurePrice} size={18}/>

    </div>

    );
}

interface TreasureProps {

  treasure: TreasureInterface
  onClick:Function

}

const Treasure: React.FC<TreasureProps> = ({treasure, onClick}) => {
  return (
    <>
      { treasure.size === TreasureSizeTypes.LARGE ?

        <LargeTreasureComponent treasure={treasure} onClick={onClick}/>
        :
        <>small</>

      }

    </>
  );
}

export default Treasure;

interface SmallTreasureComponentProps {
  treasure: TreasureInterface,
  onClick:Function,
  displayPrice:boolean,
}

export const SmallTreasureComponent: React.FC<SmallTreasureComponentProps> = ({treasure, onClick, displayPrice}) => {

  const treasurePrice = treasure.displayedDiscountPrice ? treasure.displayedDiscountPrice : treasure.displayedPrice;

  return (

    <div className="flat-card flex flex-col items-center gap-y-2 !pb-1 !pt-2 !px-2 w-[150px]"
      onClick={()=>onClick()}
    >

      <div className="w-full flex flex-col bg-grey-100 rounded-lg p-2 relative gap-2">

        <div className="top-2 right-2 flex flex-row-reverse gap-2">
          <TreasureItems items={treasure.items}/>
        </div>

        <div className="flex w-full center">
          <img className="w-28" src={`${process.env.REACT_APP_STATIC_ASSETS}/images/${treasure.image1}`} alt={treasure.title}/>
        </div>

        <div className="w-full flex justify-center">
          <p className="font-medium text-xs">{treasure.title}</p>
        </div>

      </div>

      {
        displayPrice && <QuantityGemsComponent quantity={treasurePrice} size={18}/>
      }

    </div>

    );
}