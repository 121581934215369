import React, {useEffect, useRef, useState} from 'react';
import Layout from "../Layout";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {useGetUserQuery, useLogoutUserMutation, useUpdateUserInfosMutation} from "../apis/users.api";
import {User} from "../models/User";
import {Link} from "react-router-dom";
import 'react-confirm-alert/src/react-confirm-alert.css';
import {UserPushConfig} from "../components/users/user-push-config.component";
import {InnerHeader} from "../components/ui/app-inner-header.component";
import {
  useConsumeGemsMutation,
  useCreditGemsMutation,
  useGenerateClaimRemainderMutation,
  useGenerateGoodTradesMutation,
  useGetClaimCohortQuery,
  useGetPurchaseCohortQuery,
  useGetQuantityOfGemsWorthQuery,
  useIsAdminActionAuthorizedQuery,
  useLazySendNewFriendNotifQuery,
  useRandomizeDailyCollectiblesMutation,
  useRandomizeMyCollectibleMutation,
  useRandomizeMyReferredFriendsMutation,
  useRandomizeQuestMutation,
  useRegenerateEndOfWeekNotificationMutation,
  useRegenerateFreeClaimNotificationMutation,
  useResetBTCCollectibleToZeroMutation,
  useResetDailyGiftMutation,
  useResetETHCollectibleToStep1Mutation,
  useResetOnboardingMutation,
  useRewardRankingWithCollectibleMutation,
  useUnVerifyMyEmailMutation,
  useVerifyMyEmailMutation
} from "../apis/dev.api";
import {GemsIcon} from "../components/ui/gems-icon";
import RocketIcon from "../components/svg/RocketIcon";
import {toastSuccess} from "../utils/ToastUtils";
import {classNames, cssHidden} from "../utils/Helper";
import {FiatProductPromoComponent} from "../components/users/admin/fiat-product-promo.component";
import toast from "react-hot-toast";
import {DiscordFollow} from "../components/ui/app-footer.component";

function useCsvExport(data: string[] | undefined, name: string, linkRef: React.RefObject<HTMLAnchorElement>) {
  useEffect(() => {
    if (data) {

      const txt = data.join('\n');
      const myFile = new Blob([txt], {type: 'text/csv'});

      window.URL = window.URL || window.webkitURL;

      linkRef.current?.setAttribute("href", window.URL.createObjectURL(myFile));
      linkRef.current?.setAttribute("download", name);
    }
  }, [data]);
}

const Account: React.FC = () => {
  const {data: user} = useGetUserQuery();
  const [updateUser] = useUpdateUserInfosMutation();
  const [initialValue, setInitialValues] = useState<User | null>(null);
  const [logout] = useLogoutUserMutation();

  //dev action
  const [regenEndOfWeekNotif] = useRegenerateEndOfWeekNotificationMutation();
  const [regenFreeClaimNotif] = useRegenerateFreeClaimNotificationMutation();
  const [resetOnboarding] = useResetOnboardingMutation();
  const [creditGems] = useCreditGemsMutation();
  const [consumeGems] = useConsumeGemsMutation();
  const {data: isAdmin} = useIsAdminActionAuthorizedQuery();
  const [generateClaimRemainderNotification] = useGenerateClaimRemainderMutation();
  const [resetBTCCollectible] = useResetBTCCollectibleToZeroMutation();
  const [randomizeCollectibles] = useRandomizeMyCollectibleMutation();
  const [randomizeDailyCollectibles] = useRandomizeDailyCollectiblesMutation();
  const [resetETHCollectibleToStep1] = useResetETHCollectibleToStep1Mutation();
  const [generateGoodTrades] = useGenerateGoodTradesMutation();
  const [randomizeMyReferredFriends] = useRandomizeMyReferredFriendsMutation();
  const [generateExport, setGenerateExport] = useState<boolean>(false);
  const {data: cohortClaim} = useGetClaimCohortQuery({}, {skip: !generateExport});
  const {data: cohortShop} = useGetPurchaseCohortQuery({}, {skip: !generateExport});
  const [sendNewFriendNotif] = useLazySendNewFriendNotifQuery();
  const [rewardCollectibleForRank] = useRewardRankingWithCollectibleMutation();
  const [randomizeQuestMutation] = useRandomizeQuestMutation();
  const [resetDailyGift] = useResetDailyGiftMutation();
  const inputDollarAmountRef = useRef<HTMLInputElement>(null);
  const [dollarAmount, setDollarAmount] = useState('10');
  const {data: quantityOfGemsWorth} = useGetQuantityOfGemsWorthQuery(dollarAmount);
  const [verifyEmail] = useVerifyMyEmailMutation();
  const [unVerifyEmail] = useUnVerifyMyEmailMutation();

  useEffect(() => {
    if (user) {
      setInitialValues(user);
    }
  }, [user]);


  const btnClaimCohort = useRef<HTMLAnchorElement>(null);
  const btnShopCohort = useRef<HTMLAnchorElement>(null);
  useCsvExport(cohortClaim, 'coh_claim.csv', btnClaimCohort);
  useCsvExport(cohortShop, 'coh_shop.csv', btnShopCohort);

  return (
    <Layout>

      <div className={"-mt-12 bg-gradient-to-b from-orange-700 to-orange h-40 w-screen"}></div>

      <div className={"relative -top-28 w-full max-w-4xl mx-auto flex flex-col gap-4 md:gap-8 pb-24"}>
        <InnerHeader pageTitle={`Settings`}><></>
        </InnerHeader>
        {user && initialValue &&
          <>
            <div className="flex flex-col gap-4 w-full">
              <div className="flex flex-col md:flex-row gap-4">

                <div className={"flex w-full flex-col flat-card justify-between"}>
                  <div
                    className="flex flex-col w-full gap-2 pb-4 border-b-white border-b-solid border-b-[1px] border-opacity-20">
                    <h2
                      className={"text-left text-2xl w-full"}>
                      Information
                    </h2>
                  </div>
                  {user.discordRole && (
                    <>
                      <div className="flex justify-center items-center gap-3 mt-4">
                        <span className="w-4 h-4 rounded-full"
                              style={{background: `${user.discordRole?.color ? user.discordRole.color : "white"}`}}></span>
                        <p>{user.discordRole?.name}</p>
                      </div>

                    </>
                  )}

                  {/*  formulaire infos*/}
                  <Formik validateOnBlur={true} validateOnChange={true} enableReinitialize
                          initialValues={initialValue}
                          validate={values => {
                            const errors: any = {};
                            return errors;
                          }}
                          onSubmit={async (values, {setSubmitting}) => {
                            updateUser(values).unwrap().then(() => {
                              toastSuccess("Saved");
                              gtag('event', 'userInfosUpdate', {email: user.email});
                            })
                          }}
                  >
                    {({isSubmitting, values, handleChange, handleBlur, touched, errors, isValid, dirty}) => (
                      <Form className={"flex flex-col justify-between xl:h-full"}>
                        <div id="contentForm" className="mt-4 flex flex-col">
                          <label htmlFor="email">Email</label>
                          <Field type="text" className="seein-input mb-4"
                                 value={values.email}
                                 onChange={handleChange}
                                 name="email"
                                 required
                                 variant="outlined" disabled={true}/>
                          <ErrorMessage name="email">{msg =>
                            <div>{msg}</div>}</ErrorMessage>
                          <label htmlFor="firstname">Firstname</label>
                          <Field type="text" className="seein-input mb-4"
                                 value={values.firstname}
                                 onChange={handleChange}
                                 name="firstname"
                                 variant="outlined" disabled={false}/>
                          <ErrorMessage name="firstname">{msg =>
                            <div>{msg}</div>}</ErrorMessage>

                          <label htmlFor="lastname">Lastname</label>
                          <Field type="text" className="seein-input mb-4"
                                 value={values.lastname}
                                 onChange={handleChange}
                                 name="lastname"
                                 variant="outlined" disabled={false}/>
                          <ErrorMessage name="lastname">{msg =>
                            <div>{msg}</div>}</ErrorMessage>

                          <label htmlFor="pseudo">Pseudo</label>
                          <Field type="text" className="seein-input mb-4"
                                 value={values.pseudo}
                                 onChange={handleChange}
                                 name="pseudo"
                                 required
                                 variant="outlined" disabled={false}/>
                          <ErrorMessage name="pseudo">{msg =>
                            <div>{msg}</div>}</ErrorMessage>


                          {/*<label htmlFor="discordPseudo">Discord Name</label>*/}
                          {/*<Field type="text" className="seein-input"*/}
                          {/*       value={values.discordPseudo}*/}
                          {/*       onChange={handleChange}*/}
                          {/*       name="discordPseudo"*/}
                          {/*       variant="outlined" disabled={true}/>*/}
                          {/*<ErrorMessage name="discordPseudo">{msg =>*/}
                          {/*  <div>{msg}</div>}</ErrorMessage>*/}
                        </div>


                        <div className="flex flex-col mt-8 gap-4 w-full">
                          <button className={"primary w-full"} disabled={!dirty || isSubmitting}
                                  type="submit">
                            Update infos
                          </button>
                          <Link to="/askresetpwd" className="flex center">
                            <button className="secondary w-full">
                              Change Password
                            </button>
                          </Link>

                        </div>

                      </Form>
                    )}
                  </Formik>
                </div>

                <div className={"flex flex-col w-full gap-y-2"}>
                  <UserPushConfig/>
                  <div className={"text-sm flat-card flex-row flex-wrap cursor-pointer"}>
                    <span>Contact us : </span>
                    {/*<a className={"!text-orange !underline"}*/}
                    {/*   href="mailto:contact@tanjiblocks.com">contact@tanjiblocks.com</a>*/}

                    <span className={"text-orange underline"} onClick={(e) => {
                      navigator.clipboard.writeText('contact@tanjiblocks.com').then(() => {
                        toast.success('Email copied !');
                      })
                      e.stopPropagation();
                    }}>contact@tanjiblocks.com</span>

                  </div>

                  <div className={"text-sm flat-card flex-row flex-wrap cursor-pointer"}>
                    Join the community : <DiscordFollow/>
                  </div>
                </div>

              </div>


              <div className={"w-full justify-center flex"}>
                <button className={"primary"} onClick={() => {
                  logout()
                }}>Logout
                </button>
              </div>


              {/*Admin/dev actions*/}
              {isAdmin === true &&
                <div className={"border-2 border-red-500 p-4 flex flex-col gap-y-6"}>
                  <button className={"tertiary"} onClick={() => regenEndOfWeekNotif()}>Generate end of Week Notif
                  </button>
                  <button className={"tertiary"} onClick={() => regenFreeClaimNotif()}>Generate free claim Notif
                  </button>
                  <button className={"tertiary"} onClick={() => creditGems()}>Credit me Gems +50
                    <GemsIcon/>
                  </button>
                  <button className={"tertiary"} onClick={() => consumeGems()}>Consume Gems -50
                    <GemsIcon/>
                  </button>
                  <button className={"tertiary"} onClick={async () => {
                    await resetOnboarding();
                    localStorage.removeItem('tanji_initial_onboarding');
                  }}>Redo Onboarding
                    <RocketIcon h={20}/>
                  </button>

                  <button className={"tertiary"} onClick={async () => {
                    await generateClaimRemainderNotification();
                  }}>Generate claim remainder notification
                  </button>
                  <button className={"tertiary"} onClick={async () => {
                    await resetBTCCollectible();
                  }}>BTC collectible to upgrade to Step 1
                  </button>

                  <button className={"tertiary"} onClick={async () => {
                    await resetETHCollectibleToStep1();
                  }}>Eth collectible to Upgrade to Step 2
                  </button>

                  <button className={"tertiary"} onClick={async () => {
                    await randomizeCollectibles();
                  }}>Randomize my collectibles
                  </button>

                  <button className={"tertiary"} onClick={async () => {
                    await randomizeDailyCollectibles();
                  }}>Randomize daily collectibles
                  </button>

                  <button className={"tertiary"} onClick={async () => {
                    await rewardCollectibleForRank();
                  }}>Win Collectible from my Rank
                  </button>


                  <button className={"tertiary"} onClick={async () => {
                    await generateGoodTrades();
                  }}>Generate Good Trades
                  </button>


                  <button className={"tertiary"} onClick={async () => {
                    await randomizeMyReferredFriends();
                  }}>Randomize my referred Friends
                  </button>

                  <button className={"tertiary"} onClick={async () => {
                    sendNewFriendNotif();
                  }}>NOTIF : Get new friend
                  </button>

                  <button className={"tertiary"} onClick={async () => {
                    await randomizeQuestMutation();
                  }}>Randomize my Quest
                  </button>

                  <button className={"tertiary"} onClick={async () => {
                    await resetDailyGift();
                  }}>Reset my Daily Gift
                  </button>

                  <button className={"tertiary"} onClick={async () => {
                    await verifyEmail();
                  }}>Verify my email
                  </button>

                  <button className={"tertiary"} onClick={async () => {
                    await unVerifyEmail();
                  }}>Un-verify my email
                  </button>


                  <div className={"flex flex-col"}>
                    <div className={"flex items-center justify-between"}>
                      <span className={"flex items-center"}>
                      <input ref={inputDollarAmountRef} type={"text"} placeholder={'10'} className={"!w-[100px]"}/>
                      <label className={"text-xl"}>$</label>
                      </span>
                      <button className={"tertiary"} onClick={() => {
                        setDollarAmount(inputDollarAmountRef?.current?.value || '10');
                      }}>
                        Gems quantity
                      </button>
                    </div>
                    <div
                      className={classNames(cssHidden(!quantityOfGemsWorth), "italic text-green-500 text-end")}>
                      {dollarAmount}$ is worth {quantityOfGemsWorth} Gems
                    </div>
                  </div>

                  <FiatProductPromoComponent/>

                  <div className={"flat flex flex-col justify-center p-4 gap-y-4"}>
                    <button className={"secondary"} onClick={async () => {
                      setGenerateExport(true);
                    }}>Generate Exports
                    </button>

                    <a ref={btnClaimCohort} id={"dl_claim_cohort"}
                       className={classNames(!!cohortClaim ? 'underline text-blue-700' : 'hidden')}
                       onClick={async () => {
                       }}>Download Claim Cohort
                    </a>
                    <a ref={btnShopCohort} id={"dl_shop_cohort"}
                       className={classNames(!!cohortShop ? 'underline text-blue-700' : 'hidden')}
                       onClick={async () => {
                       }}>Download Purchase Cohort
                    </a>

                  </div>


                </div>
              }
            </div>
          </>
        }
      </div>
    </Layout>
  );
};

export {Account};