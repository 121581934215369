import {CryptoCurrency} from "./Wallet";
import {SquadTradeDto} from "../dto/squad/squad.dto";
import {isAfterOrEqual} from "../utils/DateUtils";
import {DateTime} from "luxon";
import {UserItem} from "./User";

export interface Tournament {
  id: string;
  title: string;
  code: string;
  type: string;
  shouldStartAt: Date;
  shouldStopAt: Date;
  subscriptionsCount: number,
  status: number,
  // currentUserSubscription?: { inputPayload: any, shouldStopAt: Date, status: number, },
  currentUserSubscription?: SubPayload,
  currentUserResult?: GameResult,
  tournamentInputPayload?: any | HeadToHeadInputPayload,
  canParticipate: boolean,
  winner: string,
  whitelist: boolean;
  nftAccessible: boolean
  hasNeededNft: boolean;
  subscriptionAfterStartAuthorized: boolean,
  cryptoBoosts: { [key: string]: number },
}


export interface HeadToHeadInputPayload {
  cryptoA: CryptoCurrency;
  cryptoB: CryptoCurrency;
}

export interface SubPayload {
  userId: string,
  tournamentId: string,

  squadInputPayload: { cryptos: string[], cryptoValues: number[], squadTrades: SquadTradeDto[] },
  squadScorePayload: { totalValue: number, totalGain: number, },

  headToHeadInputPayload: {
    chosen: CryptoCurrency,
    amount: number,
    gemsBoost: number,
    durationBoost?: number,
    durationInHours?: number,
    itemsApplied?: UserItem[],
  },
  headToHeadScorePayload: HeadToHeadScore,

  pumpOrDumpInputPayload: {
    choice: 'up' | 'down',
    amount: number,
    gemsBoost: number,
    durationBoost?: number,
    durationInHours?: number,
    itemsApplied?: UserItem[],
  },
  pumpOrDumpScorePayload: PumpOrDumpScore,

  status: number;
  shouldStopAt: Date;
  collectibleXpReward?: number;
}

export const getSubScoreValue = (sub: SubPayload): number => {
  let res = 0;
  if (sub.squadScorePayload) res += sub.squadScorePayload.totalValue;
  if (sub.pumpOrDumpScorePayload) res += sub.pumpOrDumpScorePayload.totalValue;
  if (sub.headToHeadScorePayload) res += sub.headToHeadScorePayload.totalValue;
  return res;
}

export interface PumpOrDumpInputPayload {
  crypto: CryptoCurrency;
}


export interface PumpOrDumpScore {
  totalValue: number;
  totalGain: number;
  openPrice?: number;
  price?: number;
  variationRate: number;
  winning: boolean;
  streakMultiplier?: number;
  streakCount?: number;
}


export interface HeadToHeadScore {
  totalValue: number;
  totalGain: number;
  openPriceA?: number;
  openPriceB?: number;
  priceA?: number;
  priceB?: number;
  variationRateA: number;
  variationRateB: number;
  winning: boolean;
  streakMultiplier?: number;
  streakCount?: number;
}

export interface GameResult {
  date: Date;
  weekDay: number;
  weekNumber: number;
  year: number;
  month: number;
  tournamentId: string;
  moneyGain: number;
  xpGain: number;
  investment: number;
  rank: number;
  totalPlayers: number;
}

export function isTournamentRunning(tournament: Tournament, sub?: { status: number, shouldStopAt: Date, }) {
  return (tournament.status === 2 || tournament.status === 3) && sub?.status !== 5;
}

export function isTournamentActive(tournament: Tournament) {
  return tournament.status === 1;
}

export function hasTournamentEnded(tournament: Tournament, sub?: { status: number, shouldStopAt: Date, }) {
  return tournament.status === 4 || tournament.status === 6 || sub?.status === 5;
}

export function canSubscribeToTournament(tournament: Tournament) {
  return (tournament.status === 0 || tournament.status === 1) || (tournament.subscriptionAfterStartAuthorized === true && !tournament.currentUserSubscription);
}

export function canParticipate(tournament: Tournament) {
  return canSubscribeToTournament(tournament) && tournament.canParticipate === true;
}

export const isAfterTournamentEnd = (durationHour: number, tournament: Tournament) => {
  return isAfterOrEqual(DateTime.now().plus({'hour': durationHour}).toJSDate(), new Date(tournament.shouldStopAt));
}


export function getTournamentStatus(tournament: Tournament): string {
  let res = 'NEW';
  if (tournament.status === 2 || tournament.status === 3) {
    res = 'RUNNING';
  } else if (tournament.status == 4) {
    res = 'FINISHED';
  }
  return res;
}