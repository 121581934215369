import React from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {AnchorLink} from "react-anchor-navigation";
import {cssHidden} from "../../utils/Helper";
import {isIOS} from "react-device-detect";
import SecondaryNavigationItems from './secondary-navigation-icons.component';
import {classNames} from "../../utils/Helper";

type NavItem = {
  matchPath?: string,
  path: string;
  hash?: string;
  state?: any;
  name: string;
  hidden?: boolean;
};

type SecondaryNavigationProps = {
  navItems: NavItem[];
  css?: string | string[];
};

function matchPath(item: NavItem, location: string, hash?: string) {
  const match = new RegExp(`${encodeURI(item.matchPath ? item.matchPath:item.path)}([\\/.*]+|$)`).exec(location);
  return !!match && ((!hash && !item.hash) || (hash && item.hash === hash));
}

const SecondaryNavigation: React.FC<SecondaryNavigationProps> = ({navItems, css = ''}) => {
  const location = useLocation();
  const nav = useNavigate();
  return (
    <div className={classNames("w-full max-w-min h-8 justify-between items-center inline-flex p-0.5 bg-black bg-opacity-10 rounded-lg border-[1px] border-solid border-black border-opacity-5 ")}>
      <div className="w-full h-full flex flex-row justify-center">


        <div className="flex items-center flex-row gap-2 hover:cursor w-full">
          { navItems.map((item, i) => {
            const match = matchPath(item, location.pathname, location.hash);

            return item.hash ? <AnchorLink onClick={() => {

              // IOS anchor link is broken : I was forced to add this line for IOS for the anchor to be added in the url on mobile, otherwise
              //the user need to click the link twice.
              if (item.hash && isIOS) {
                // console.log(`scrolling to ${item.hash}`);
                nav(item.hash)
              }
            }}
                                           className={classNames("h-full flex items-center px-4 md:px-8 py-1.5 rounded-md border border-transparent grow justify-center text-white opacity-40 text-sm", css, cssHidden(item.hidden || false))}
                                           href={item.hash}
                                           key={item.hash}
                                           activeClassName="bg-orange border-[1px] border-solid border-black border-opacity-10 text-white !opacity-100">
              {/* {item.name} */}
              <SecondaryNavigationItems name={item.name}/>
            </AnchorLink> : (
              <div
                key={item.path + (item.hash || '')}
                className={classNames(
                  "h-full flex items-center px-4 py-1.5 rounded-md border border-transparent grow justify-center",
                  !!match ? 'bg-orange border-[1px] border-solid border-black border-opacity-10 text-white' : '')}>

                {!item.hash &&
                  <Link to={item.path + (item.hash ? `${item.hash}` : '')} state={item.state ? {...item.state} : {}}
                        className={classNames("w-full flex flex-row center gap-2", item.hidden === true ? 'hidden' : '')}>
                    <p
                      className={classNames("text-white text-sm font-bold font-['Satoshi'] whitespace-nowrap", !!match ? 'text-white' : 'text-white opacity-40')}>
                      {item.name}
                      {/* <SecondaryNavigationItems name={item.name}/> */}
                    </p>
                  </Link>
                }
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export {SecondaryNavigation};