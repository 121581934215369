import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {
  canParticipate,
  canSubscribeToTournament,
  hasTournamentEnded,
  HeadToHeadInputPayload,
  isAfterTournamentEnd,
  isTournamentRunning,
  SubPayload,
  Tournament
} from "../../models/Tournament";
import {classNames, cssHidden, cssShow, hasElements} from "../../utils/Helper";
import {useGetBaseBetOddQuery, useRegisterUserToTournamentMutation} from "../../apis/tournaments.api";
import {useNavigate} from "react-router-dom";
import {QuantityCoinComponent} from "../ui/quantity-coin.component";
import {useGetMoneyAmountQuery} from "../../apis/game-income.api";
import {toastMessage} from "../../utils/ToastUtils";
import {CryptoCurrency} from "../../models/Wallet";
import {GemsIcon} from "../ui/gems-icon";
import Switch from "@mui/material/Switch";
import {getBottomAlertSx, getSwitchSx} from "../ui/MuiStyle";
import {useGetInventoryQuery, useGetUserQuery} from "../../apis/users.api";
import Alert from '@mui/material/Alert';
import {AlertTitle, Snackbar} from "@mui/material";
import {DateTime} from "luxon";
import BoostUpIcon from '../svg/BoostUpIcon';
import PotentialEarningsAccordion from './subcomponents/potential-earnings-accordion.component';
import BetSummary from './subcomponents/bet-summary.component';
import BetTokenItem from './subcomponents/bet-token-item.component';
import {getChoosenCollectibleInfo} from '../tournament/tournaments-list.component';
import {find} from 'lodash';
import {useGetBetDurationOddBonusQuery, useGetMaxBetAmountQuery} from "../../apis/game.api";
import {RapidDecrementButtonV2, RapidIncrementButtonV2} from "../ui/numeric-button-v2";
import RocketIcon from "../svg/RocketIcon";
import {MaxBetAmountReachedSnackbar} from "../game/max-bet-amount-snackbar.component";
import {BoostItemIcon, ShieldItemIcon} from "../shop/gems-buyable-items.component";
import {UserItem} from "../../models/User";
import {PowerUpAlertSnackbar} from "../game/gems-alert-snackbar.component";
import {ItemsSelectionDrawer} from "./items-selection-drawer.component";
import {Spa} from "@mui/icons-material";
import {SparkIcon} from "../ui/spark-icon";

const getTournamentCryptoBoost = (tournament: Tournament, cryptoId?: string) => {
  return tournament.cryptoBoosts ? (cryptoId ? tournament.cryptoBoosts[cryptoId] : undefined) : undefined
}

const HeadToHeadItemComponent: React.FC<{ tournament: Tournament, userScore?: SubPayload }> = ({
                                                                                                 tournament,
                                                                                                 userScore,
                                                                                               }) => {
  const {data: duration_boosts} = useGetBetDurationOddBonusQuery();
  const navigate = useNavigate();
  const [register] = useRegisterUserToTournamentMutation();
  const [payload, setPayload] = useState<HeadToHeadInputPayload | null>(tournament.tournamentInputPayload);
  const [chosen, setChosen] = useState<CryptoCurrency | undefined>(userScore?.headToHeadInputPayload?.chosen);
  const [amount, setAmount] = useState<number>(userScore?.headToHeadInputPayload?.amount || 0);
  const [winning, setWinning] = useState<boolean>(!!userScore?.headToHeadScorePayload?.winning);
  const {data: money} = useGetMoneyAmountQuery();
  const [choiceDone, setChoiceDone] = useState(!!userScore?.headToHeadScorePayload);
  const [gemsBoostActivated, setGemsBoostActivated] = useState(!!userScore?.headToHeadInputPayload?.gemsBoost && userScore?.headToHeadInputPayload?.gemsBoost > 0);
  const [showGemsAlert, setShowGemsAlert] = useState(false);
  const [a_rate, setA_rate] = useState(userScore?.headToHeadScorePayload.variationRateA || 0);
  const [b_rate, setB_rate] = useState(userScore?.headToHeadScorePayload.variationRateB || 0);
  const [a_price, setA_price] = useState(Number(payload?.cryptoA?.lastQuote?.price || 0));
  const [b_price, setB_price] = useState(Number(payload?.cryptoB?.lastQuote?.price || 0));
  const [a_openPrice, setA_openPice] = useState(userScore?.headToHeadScorePayload?.openPriceA || 0);
  const [b_openPrice, setB_openPrice] = useState(userScore?.headToHeadScorePayload?.openPriceB || 0);
  const [duration, setDuration] = useState<number>(userScore?.headToHeadInputPayload?.durationInHours || 1);
  const [subscribing, setSubscribing] = useState(false);
  const {data: user} = useGetUserQuery();
  const {data: maxBetAmount} = useGetMaxBetAmountQuery(chosen?._id);
  const [showMaxBetAmountReached, setShowMaxBetAmountReached] = useState(false);
  const [maxBetThresholdCount, setMaxBetThresholdCount] = useState(0);
  const {data: inventory} = useGetInventoryQuery({});
  const [showShieldBoostDrawer, setShowShieldBoostDrawer] = useState(false);
  const [shield, setShield] = useState<UserItem | undefined>(undefined);
  const [boost, setBoost] = useState<UserItem | undefined>(undefined);
  useEffect(() => {
    if (chosen && maxBetAmount && amount > maxBetAmount) {
      setAmount(maxBetAmount);
    }
  }, [chosen, maxBetAmount, amount]);

  useEffect(() => {
    if (userScore && userScore.headToHeadInputPayload) {
      setChosen(userScore.headToHeadInputPayload.chosen);
      setAmount(userScore.headToHeadInputPayload.amount);
      setGemsBoostActivated(userScore.headToHeadInputPayload.gemsBoost > 0);
      setDuration(userScore.headToHeadInputPayload.durationInHours || 1);
      setBoost(userScore.headToHeadInputPayload.itemsApplied?.find((i) => i.subFamily === 'bet_boost'));
      let headToHeadScore = userScore.headToHeadScorePayload;
      setWinning(headToHeadScore.winning);
      setA_rate(headToHeadScore.variationRateA);
      setB_rate(headToHeadScore.variationRateB);
      if (headToHeadScore.openPriceA && headToHeadScore.openPriceB) {
        setA_openPice(headToHeadScore.openPriceA);
        setB_openPrice(headToHeadScore.openPriceB);
        setA_price(headToHeadScore.openPriceA + headToHeadScore.openPriceA * headToHeadScore.variationRateA);
        setB_price(headToHeadScore.openPriceB + headToHeadScore.openPriceB * headToHeadScore.variationRateB);
      }
    } else if (payload && payload.cryptoA.lastQuote && payload.cryptoB.lastQuote) {
      setA_price(Number(payload.cryptoA.lastQuote.price));
      setB_price(Number(payload.cryptoB.lastQuote.price));
    }
  }, [userScore, payload]);

  const userHasEnoughGems = useCallback(() => {
    return !!(user && user.gemsAmount >= 2);
  }, [user]);

  const choose = useCallback(() => {
    //optimistic
    setSubscribing(true);
    setChoiceDone(true);
    const _boostActivated = userHasEnoughGems() && gemsBoostActivated;
    const itemsApplied = [];
    if (shield) itemsApplied.push(shield);
    if (boost) itemsApplied.push(boost);
    register({
      inputPayload: {
        chosen,
        amount,
        gemsBoost: _boostActivated ? 2 : 0,
        durationInHours: duration || 1,
        itemsApplied,
      },
      tournamentType: tournament.type,
      tournamentId: tournament.id,
      shouldStopAt: DateTime.now().plus({hour: duration || 1}).toJSDate(),
    }).then((data: any) => {
      setSubscribing(false);
      if (data.error) {
        gtag('event', 'postTournamentSubscriptionFailure', {
          tournament_type: tournament.type,
          code: tournament?.code,
          gemsBoost: _boostActivated
        });
        navigate('/tournament');
      } else {
        gtag('event', 'postTournamentSubscriptionSuccess', {
          code: tournament?.code,
          tournament_type: tournament.type,
          content_type: 'tournament',
          content_id: tournament.id,
          gemsBoost: _boostActivated
        });
      }
    });
  }, [payload, chosen, amount, gemsBoostActivated, shield, boost]);

  const {data: marketOdds} = useGetBaseBetOddQuery();

  const getOdds = useCallback((cryptoBoost: number = 0, durationBoost: number = 0) => {
    return (marketOdds || 1) + Number(boost?.metadata?.value || 0) + cryptoBoost + durationBoost;
  }, [boost, marketOdds]);

  const getCryptoBoost = useCallback((cryptoId?: string) => {
    return getTournamentCryptoBoost(tournament, cryptoId);
  }, [tournament]);

  const getDurationBoost = useCallback((duration: number) => {
    const durationItem = find(duration_boosts, ['hours', duration]);
    return durationItem?.bonus || 0;
  }, [duration_boosts]);

  const isDisplayed = !!chosen;

  const isSame = useMemo(() => {
    let res = true;
    if (user && userScore && userScore.userId !== user._id) {
      res = false;
    }
    return res;
  }, [user, userScore]);

  return (
    <div className={classNames("relative flex flex-col gap-3 justify-center items-center")}>

      {payload &&
        <>

          {!subscribing &&
            <>
              <div
                className={classNames(cssShow(choiceDone && winning && hasTournamentEnded(tournament, userScore)), "rounded-full bg-victory-green text-white uppercase text-xs p-2")}>
                {isSame ? 'You won!' : 'Won'}
              </div>
              <div
                className={classNames(cssShow(choiceDone && !winning && hasTournamentEnded(tournament, userScore)), "rounded-full bg-red-500 text-white uppercase text-xs p-2")}>
                {isSame ? 'You lost!' : 'Lost'}
              </div>
              <div
                className={classNames(cssShow(!choiceDone && hasTournamentEnded(tournament, userScore)), "rounded-full bg-gray-600 text-white uppercase text-xs p-2")}>
                Not Played
              </div>

              <div
                className={classNames(cssShow(choiceDone && !winning && !hasTournamentEnded(tournament, userScore)), "rounded-full bg-red-500 text-white uppercase text-xs p-2")}>
                {isSame ? 'You are losing' : 'Losing'}
              </div>

              <div
                className={classNames(cssShow(choiceDone && winning && !hasTournamentEnded(tournament, userScore)), "rounded-full bg-victory-green text-white uppercase text-xs p-2")}>
                {isSame ? 'You are winning' : 'Winning'}
              </div>
            </>
          }


          <div className={"relative w-full flex gap-2 justify-center pb-2"}>

            <BetTokenItem price={a_price} chosen={chosen} crypto={payload.cryptoA} tournament={tournament}
                          isGoodChoice={() => chosen && a_rate >= b_rate}
                          choseCallback={(crypto: CryptoCurrency) => setChosen(crypto)} choiceDone={choiceDone}
                          variationRate={a_rate} openPrice={a_openPrice}
                          odd={getOdds(getCryptoBoost(payload.cryptoA._id), getDurationBoost(duration))}
                          collectibleBoosted={getCryptoBoost(payload.cryptoA._id)}/>

            <BetTokenItem price={b_price} chosen={chosen} crypto={payload.cryptoB} tournament={tournament}
                          isGoodChoice={() => chosen && b_rate >= a_rate}
                          choseCallback={(crypto: CryptoCurrency) => setChosen(crypto)} choiceDone={choiceDone}
                          variationRate={b_rate} openPrice={b_openPrice}
                          odd={getOdds(getCryptoBoost(payload.cryptoB._id), getDurationBoost(duration))}
                          collectibleBoosted={getCryptoBoost(payload.cryptoB._id)}/>
          </div>

          {!subscribing && !choiceDone && canSubscribeToTournament(tournament) && <div
            className={classNames("w-full flex flex-col items-center justify-center gap-5 px-2", isDisplayed ? 'py-2' : '')}>

            {/* Tournament Duration */}
            <div className={classNames("flex gap-x-4", cssHidden(!isDisplayed || !duration_boosts))}>
              {duration_boosts?.map((durationChoice, index) => {
                return <div key={index}
                            className={classNames("flex flex-col items-center gap-y-2", cssHidden(isAfterTournamentEnd(durationChoice.hours, tournament)))}>
                  {/* <button className={classNames(duration === durationChoice ? 'primary' : 'secondary')} */}
                  <button
                    className={classNames('px-3.5 py-0.5 !rounded text-sm', duration === durationChoice.hours ? 'bg-orange text-white' : 'bg-grey-100 !text-grey-700')}
                    onClick={() => setDuration(durationChoice.hours)}>{durationChoice.hours}h
                  </button>
                  <span
                    className={"text-opacity-70 text-xs"}>{(getOdds(getCryptoBoost(chosen?._id), getDurationBoost(durationChoice.hours))).toFixed(2)}</span>
                </div>
              })}
            </div>

            {/* Stake */}
            <div className="flex gap-2 w-full">

              <div className={classNames("flex flex-col items-end w-full gap-2", isDisplayed ? '' : 'hidden')}>

                <div className={classNames("w-full flex gap-4")}>

                  <div className='pb-[4px]'>
                    <RapidDecrementButtonV2 count={amount} callback={(a) => {
                      setAmount(a)
                    }}/>
                  </div>

                  <div className={"flex center flex-col grow h-full"}>
                    <QuantityCoinComponent quantity={amount} size={16} big={true}/>
                    <p className={"font-bold leading-3"}>Stake</p>
                  </div>

                  {money &&
                    <div className='pb-[4px]'>
                      <RapidIncrementButtonV2 max={maxBetAmount} count={amount} callback={(a) => {
                        if (amount === maxBetAmount) {
                          if (maxBetThresholdCount >= 1) {
                            setShowMaxBetAmountReached(true);
                          } else {
                            setMaxBetThresholdCount((prev) => ++prev);
                          }
                        }

                        if (a > money.amount) {
                          setAmount(money.amount);
                        } else {
                          setAmount(a);
                        }
                      }}/>
                    </div>
                  }

                </div>
              </div>

            </div>

            {isDisplayed &&
              <BetSummary
                items={userScore?.headToHeadInputPayload.itemsApplied}
                collectible={getChoosenCollectibleInfo(tournament, chosen)}
                duration={duration_boosts?.find((durationChoice) => {
                  return durationChoice.hours === duration
                })}
              />
            }

            <hr className={`w-full border-t border-grey-100 ${isDisplayed ? '' : 'hidden'}`}/>

            {/*  Shield*/}
            <div className={classNames(isDisplayed ? 'w-full flex justify-between' : 'hidden')}>

              <div className={classNames('flex gap-x-2 items-center bg-gray-200 rounded-xl p-1', cssShow(!!shield))}
                   onClick={() => setShield(undefined)}>
                {shield && <><ShieldItemIcon h={24} item={shield}/><span
                  className={"text-xs"}>{shield.metadata?.value}%</span></>}
              </div>

              <div className={classNames('flex gap-x-2 items-center  bg-gray-200 rounded-xl p-1', cssShow(!!boost))} onClick={() => setBoost(undefined)}>
                {boost && <><BoostItemIcon h={24} item={boost}/><span
                  className={"text-xs"}>+ odd {Number(boost.metadata?.value).toFixed(1)}</span></>}
              </div>

              <button className={classNames("secondary !text-xs")} onClick={() => {
                setShowShieldBoostDrawer(true);
              }}><SparkIcon h={16}/>{" Power ups"}
              </button>
            </div>
          </div>
          }

          {subscribing && <div
            className={classNames("w-full flex items-center justify-center gap-5")}>
            <div>Registering your Bet...</div>
            <div className={"animate-bounce"}>
              <RocketIcon h={65}/>
            </div>
          </div>
          }
        </>
      }

      {!subscribing && (choiceDone && chosen) && (isTournamentRunning(tournament, tournament.currentUserSubscription) || hasTournamentEnded(tournament, tournament.currentUserSubscription)) &&
        <div className='w-full'>
          <hr className={`w-full border-t border-grey-100`}/>
          <PotentialEarningsAccordion
            items={userScore?.headToHeadInputPayload.itemsApplied}
            collectibleXpReward={userScore?.collectibleXpReward}
            streakCount={userScore?.headToHeadScorePayload.streakCount}
            streakMultiplier={userScore?.headToHeadScorePayload.streakMultiplier}
            running={isTournamentRunning(tournament, tournament.currentUserSubscription)}
            stake={amount}
            xp={winning ? 100 : 0}
            gain={winning ? amount * getOdds(getCryptoBoost(chosen?._id), getDurationBoost(duration)) * (userScore?.headToHeadScorePayload.streakMultiplier || 1) : 0} // 0 instead of - amount for "potential earnings"
            collectible={getChoosenCollectibleInfo(tournament, chosen)}
            duration={duration_boosts?.find((durationChoice) => {
              return durationChoice.hours === duration
            })}
            gemBoost={gemsBoostActivated}
            crypoCode={chosen?.code}
            total={getOdds(getCryptoBoost(chosen?._id), getDurationBoost(duration))}
          />
        </div>
      }

      {(!choiceDone && chosen) && canParticipate(tournament) &&
        <div className='w-full px-2'>
          <button className={"primary w-full mb-4"}
                  onClick={() => {
                    if (chosen) {
                      gtag('event', 'tournamentSubscribe', {
                        update: !!tournament.currentUserSubscription,
                        code: tournament.code,
                        type: tournament.type
                      });
                      choose();
                    } else {
                      toastMessage('Select your bet')
                    }
                  }}
          >
            PLACE BET
          </button>
        </div>
      }

      {!choiceDone && chosen &&
        <div className='flex flex-col w-full gap-4'>

          <hr className={`w-full border-t border-grey-100`}/>

          <div className='flex w-full justify-between'>

            <p className='pl-2'>
              Potential earnings
            </p>

            <QuantityCoinComponent size={20} big={true}
                                   quantity={amount * getOdds(getCryptoBoost(chosen?._id), getDurationBoost(duration))}/>

          </div>

        </div>
      }

      {showGemsAlert &&
        <Snackbar sx={{bottom: '75px!important'}} anchorOrigin={{vertical: 'bottom', horizontal: 'center'}} open={true}
                  autoHideDuration={6000} onClose={() => {
          setShowGemsAlert(false)
        }}>
          <Alert className={"flat-card"} sx={getBottomAlertSx()} severity="error" action={
            <button className={"primary"} onClick={() => navigate('/store')}>
              SHOP
            </button>
          }>
            <AlertTitle>Oops</AlertTitle>
            <span className={""}>You don't have enough gems to boost your bet.</span>
          </Alert>
        </Snackbar>
      }

      {showShieldBoostDrawer && inventory && hasElements(inventory) &&
        <ItemsSelectionDrawer inventory={inventory} open={showShieldBoostDrawer}
                              onClose={() => setShowShieldBoostDrawer(false)}
                              onItemChosen={(item: UserItem) => {
                                if (item.subFamily === 'shield') setShield(item);
                                if (item.subFamily === 'bet_boost') setBoost(item);
                              }}/>
      }

      {showShieldBoostDrawer && !hasElements(inventory) &&
        <PowerUpAlertSnackbar onClose={() => setShowShieldBoostDrawer(false)}/>
      }

      {showMaxBetAmountReached && chosen && maxBetAmount &&
        <MaxBetAmountReachedSnackbar crypto={chosen} maxAmount={maxBetAmount} onClose={() => {
          setShowMaxBetAmountReached(false);
          setMaxBetThresholdCount(0);
        }}/>
      }
    </div>
  );
}

export {HeadToHeadItemComponent};