import * as React from 'react';
import {useEffect, useState} from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {styled} from '@mui/material/styles';
import {GainCoinComponent} from '../../ui/gain-coin.component';
import {CoinIcon} from '../../ui/coin-icon';
import BetSummary from './bet-summary.component';
import {UserItem} from "../../../models/User";
import {ShieldItemIcon} from "../../shop/gems-buyable-items.component";

interface PotentialEarningsAccordionProps {
  running: boolean,
  stake: number,
  xp: number,
  gain: number,
  collectible: any,
  duration: any,
  gemBoost: boolean,
  crypoCode: string,
  total?: number,
  streakMultiplier?: number,
  streakCount?: number,
  collectibleXpReward?: number,
  items?: UserItem[],
}

const CustomAccordion = styled(Accordion)(({theme}) => {
  return {
    boxShadow: 'none',
    border: 'none',
    borderRadius: '0 !important',
    '.MuiAccordionSummary-root': {
      paddingRight: 0,
      paddingLeft: '0.5rem',
    },
    '.MuiAccordionDetails-root': {
      paddingRight: 0,
      paddingLeft: 0,
    },
    '.MuiAccordionSummary-content': {
      marginTop: 0,
      marginBottom: 0,
    },
  };
});

const PotentialEarningsAccordion: React.FC<PotentialEarningsAccordionProps> = ({
                                                                                 items = [],
                                                                                 running,
                                                                                 stake,
                                                                                 xp,
                                                                                 gain,
                                                                                 collectible,
                                                                                 duration,
                                                                                 gemBoost,
                                                                                 crypoCode,
                                                                                 total,
                                                                                 streakMultiplier = 1,
                                                                                 streakCount = 0,
                                                                                 collectibleXpReward = 0
                                                                               }) => {

  const [shield, setShield] = useState<UserItem | undefined>(undefined);
  useEffect(() => {
    setShield(items?.find((i) => i.subFamily === 'shield'));
  }, [items]);
  return (
    <CustomAccordion>
      <AccordionSummary
        id="potentialEarningsAccordion"
        expandIcon={<ExpandMoreIcon className='text-black-input'/>}
        aria-controls="panel1-content"
      >
        <div className='w-full flex justify-between pr-2 center gap-x-4'>

          {running &&
            <p>Potential earnings</p>
          }

          {!running &&
            <div>

              <p className={"text-gray-600 text-sm"}>{crypoCode} Collectible</p>
              <p className={"flex gap-x-1"}><img className="w-5"
                                                 src={`${process.env.REACT_APP_STATIC_ASSETS}/images/collectible-card.png`}/>+{collectibleXpReward} XP
              </p>
            </div>
          }

          {(() => {
            if (gain === 0 && shield && stake > 0) {
              return <div className={"relative"}>
                <GainCoinComponent size={20} gain={stake * Number(shield.metadata?.value) / 100} big={true}/>
                <div className={"absolute -right-2 -top-2"}><ShieldItemIcon h={20} item={shield}/></div>
              </div>
            } else {
              return <GainCoinComponent size={20} gain={gain} big={true}/>
            }
          })()}


        </div>
      </AccordionSummary>

      <hr className="w-full border-t border-grey-100"/>

      <AccordionDetails className='w-full flex flex-col gap-2'>

        <div className={"flex w-full justify-between items-center text-inherit pr-0.5"}>
          <div className='flex center gap-2 pl-1'>
            <CoinIcon h={18}/>
            <p className='text-xs'>Stake</p>
          </div>
          <p>{stake}</p>
        </div>

        <hr className="w-full border-t border-grey-100"/>

        <BetSummary
          items={items}
          collectible={collectible}
          duration={duration}
          gemBoost={gemBoost}
          total={total}
          streakMultiplier={streakMultiplier}
          streakCount={streakCount}
        />

      </AccordionDetails>

    </CustomAccordion>
  )
    ;
}

export default PotentialEarningsAccordion;