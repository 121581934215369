import React from "react";


const DownRedIcon: React.FC<{ h?: number }> = ({h = 10}) =><svg width={h} height={h} viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 2L6.79289 2C7.23835 2 7.46143 2.53857 7.14645 2.85355L4.35355 5.64645C4.15829 5.84171 3.84171 5.84171 3.64645 5.64645L0.853553 2.85355C0.538571 2.53857 0.761654 2 1.20711 2L4 2Z" fill="#EE0004"/>
  </svg>;

export {DownRedIcon};

const DownWhiteIcon: React.FC<{ h?: number }> = ({h = 10}) =><svg width={h} height={h} viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 2L6.79289 2C7.23835 2 7.46143 2.53857 7.14645 2.85355L4.35355 5.64645C4.15829 5.84171 3.84171 5.84171 3.64645 5.64645L0.853553 2.85355C0.538571 2.53857 0.761654 2 1.20711 2L4 2Z" fill="white"/>
  </svg>;

export {DownWhiteIcon};