import React from "react";


const ShieldIcon: React.FC<{ h?: number, color1?: string, color2?: string, color3?: string }> = ({
                                                                                                   h = 17,
                                                                                                   color1 = '#CCD6DD',
                                                                                                   color2 = '#55ACEE',
                                                                                                   color3 = '#226699'
                                                                                                 }) => <svg width={h}
                                                                                                            height={h}
                                                                                                            viewBox="0 0 36 36"
                                                                                                            fill="none"
                                                                                                            xmlns="http://www.w3.org/2000/svg">
    <path d="M33 3C26 0 18 0 18 0C18 0 10 0 3 3C-2.5034e-06 18 3 31 18 36C33 31 36 18 33 3Z" fill={color1}/>
    <path
      d="M18 33.884C6.412 29.729 1.961 19.831 4.76 4.444C11.063 2.029 17.928 2 18 2C18.071 2 24.958 2.04 31.24 4.444C34.039 19.831 29.588 29.729 18 33.884Z"
      fill={color2}/>
    <path d="M31.24 4.444C24.958 2.04 18.071 2 18 2V33.884C29.588 29.729 34.039 19.831 31.24 4.444Z" fill={color3}/>
  </svg>

;


const DoubleShield: React.FC<{ h?: number, color?: string }> = ({h = 17, color = 'black'}) => <ShieldIcon h={h}
                                                                                                          color1={"#9599ad"}
                                                                                                          color2={"#cccfd7"}
                                                                                                          color3={"#e9e9e9"}/>
;

const TripleShield: React.FC<{ h?: number, color?: string }> = ({h = 17, color = 'black'}) => <ShieldIcon h={h}
                                                                                                          color1={"#f19900"}
                                                                                                          color2={"#E3800A"}
                                                                                                          color3={"#E3B273"}/>;


export {ShieldIcon, DoubleShield, TripleShield};