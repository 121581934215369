import React, {useEffect, useState} from 'react'
import {useGetSquadAnalyticsQuery} from "../../apis/squad.api";
import {Tournament} from "../../models/Tournament";
import {classNames, formatBalanceNumber, hasElements} from "../../utils/Helper";
import {useGetCryptosFromIdsQuery} from "../../apis/cryptos.api";
import {CryptoTrade} from "../../dto/squad/squad.dto";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {CryptoCurrency} from "../../models/Wallet";
import {QuantityCoinComponent} from "../ui/quantity-coin.component";


const SquadSimplifiedPositions: React.FC<{
  tournament: Tournament,
  pseudo?: string,
  cryptoId?: string,
  title?: string
}> = ({tournament, pseudo, cryptoId, title = 'Open/Closed positions'}) => {

  const [trades, setTrades] = useState<CryptoTrade[]>([]);
  const {
    data: cryptos,
    isLoading: areCryptosLoading
  } = useGetCryptosFromIdsQuery({ids: trades.map(t => t.cryptoSourceId)});

  const {data: scorePayload, isLoading: areAnalyticsLoading} = useGetSquadAnalyticsQuery({
    tournamentId: tournament.id,
    pseudo
  });

  const {
    data: _crypto,
    isLoading: isCryptoLoading
  } = useGetCryptosFromIdsQuery({ids: cryptoId ? [cryptoId] : []}, {skip: !cryptoId});

  const [crypto, setCrypto] = useState<CryptoCurrency>();

  useEffect(() => {
    if (_crypto && _crypto.length) {
      setCrypto(_crypto[0]);
    }
  }, [_crypto]);

  useEffect(() => {
    if (scorePayload && scorePayload.positions) {
      setTrades(scorePayload.positions.filter(p => cryptoId ? p.cryptoSourceId === cryptoId : true));
    }
  }, [tournament, scorePayload]);

  return (<div className={"flex flex-col gap-3 text-sm md:text-base"}>
      <h3 className={"w-full text-center"}>{title}</h3>

      {!areCryptosLoading && pseudo && (!trades || !hasElements(trades)) &&
        <div className={"m-2 flex flex-col items-center"}>
          <div>{`${pseudo} does not share his Squad or you did not choose the right trading action to see it.`}</div>
          <VisibilityOffIcon fontSize={"large"}></VisibilityOffIcon>
        </div>
      }
      {!areCryptosLoading && cryptos && trades.map((t: CryptoTrade, idx) => {
        const c = cryptos.find(c => c._id === t.cryptoSourceId);
        return <div key={t.cryptoSourceId + idx}
                    className={classNames("text-xs md:text-base flex gap-x-2 pt-3 px-3 rounded border-t-grey-100 border-t")}>

          {t.open &&
            <div className={"flex grow flex-row gap-4"}>
              <div className={"bg-turquoise bg-opacity-30 text-turquoise font-bold rounded-md px-2 py-1"}>OPEN</div>
              <div
                className={"flex items-center text-black text-sm font-bold font-['Satoshi']"}>
                {formatBalanceNumber(t.amountCrypto) + ' ' + crypto?.code}
              </div>
            </div>
          }

          {!t.open &&
            <div className={"flex grow flex-row gap-4"}>
              <div className={"bg-orange-100 text-orange font-bold rounded-md px-2 py-1"}>CLOSED</div>
              <div
                className={"flex justify-end items-center text-black text-sm font-bold font-['Satoshi']"}>
                {formatBalanceNumber(t.amountCrypto) + ' ' + crypto?.code}
              </div>
            </div>
          }

          <div className="flex gap-1 justify-end items-center text-black text-sm">
            <QuantityCoinComponent quantity={t.amountCrypto * t.price} size={12}/>
          </div>
        </div>
      })}

    </div>
  );
}

export {SquadSimplifiedPositions};