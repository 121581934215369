import React, {useEffect, useState} from 'react';
import Layout from "../../Layout";
import toast from "react-hot-toast";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {RootState} from "../../app/store";
import {Field, FieldArray, Form, Formik, FormikErrors} from "formik";
import {useNavigate} from "react-router-dom";
import {useGetUserScoreQuery} from "../../apis/tournaments.api";
import {CryptoTeammate} from "../../models/squad/CryptoTeammate";
import {updateOrAddToSquad} from "./squadSlice";
import {useGetMoneyAmountQuery} from "../../apis/game-income.api";
import {classNames} from "../../utils/Helper";
import {CoinIcon} from "../../components/ui/coin-icon";
import {SubPayload} from "../../models/Tournament";
import {SecondaryNavigation} from "../../components/ui/secondary-navigation.component";
import {InnerHeader} from "../../components/ui/app-inner-header.component";

export function useGetPreviouslyRegisteredCryptos(subPayload?: SubPayload): { data: string[], isLoading: boolean } {
  const [previouslyRegisteredCrypto, setPreviouslyRegisteredCrypto] = useState<string[]>([]);
  useEffect(() => {
    if (subPayload && subPayload.squadInputPayload) {
      const crypto = [...subPayload.squadInputPayload.cryptos];
      setPreviouslyRegisteredCrypto(crypto);
    }
  }, [subPayload]);

  return {data: previouslyRegisteredCrypto, isLoading: false};
}

export function useGetPreviouslyRegisteredCryptosFromTournament(subPayload?: SubPayload): string[] {
  const [previouslyRegisteredCrypto, setPreviouslyRegisteredCrypto] = useState<string[]>([]);
  useEffect(() => {
    if (subPayload && subPayload.squadInputPayload) {
      const crypto = [...subPayload.squadInputPayload.cryptos];
      setPreviouslyRegisteredCrypto(crypto);
    }
  }, [subPayload]);

  return previouslyRegisteredCrypto;
}

const SquadAllocationPage: React.FC = () => {
  const squad = useAppSelector((state: RootState) => state.squad.value);
  const tournamentId = useAppSelector((state: RootState) => state.subscribingTournament.id);
  const {
    data: subscription
  } = useGetUserScoreQuery({tournamentId: tournamentId}, {skip: !tournamentId});
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [currentAffectations, setCurrentAffectations] = useState<number[]>(squad.map(s => s.value));
  const [betAmount, setBetAmount] = useState<number>(0);
  const [initialBetAmount, setInitialBetAmount] = useState<number>(0);
  const {data: money} = useGetMoneyAmountQuery();
  const initialValues = {
    squad: [
      ...squad
    ]
  };

  const previouslyRegisteredCrypto = useGetPreviouslyRegisteredCryptos(subscription);

  useEffect(() => {
    if (!tournamentId) {
      navigate('/tournament');
    }
  }, [])

  //init bet amount.
  useEffect(() => {
    const tot = initialValues.squad.map(s => s.value).filter(a => !isNaN(Number(a))).map(a => Number(a)).reduce((c, p) => c + p, 0);
    setBetAmount(tot);
    setInitialBetAmount(tot);
  }, []);

  function validateField(value: string, prev: number) {
    let error;
    if (isNaN(Number(value))) {
      error = 'Number Required';
    } else if (Number(value) <= 0) {
      error = `Cannot allocate 0$`;
    } else if (prev && Number(value) < prev) {
      error = `Cannot allocate less than previous allocation of ${prev}$`;
    }
    return error;
  }

  return (
    <Layout>
      <InnerHeader pageTitle={"Squad Tournament"} backPath={"/tournament"}>
      </InnerHeader>

      <div className={"flex flex-col gap-y-4 max-w-3xl items-center w-full"}>
        <SecondaryNavigation navItems={[
          {path: '/squad-registration', name: 'Squad', state: {tournamentId}},
          {path: '/squad-distribution', name: 'Allocation', state: {tournamentId}},
          {path: '/squad-trades', name: 'Action', state: {tournamentId}},
        ]}/>
        <div className={"flex flex-col justify-center items-center gap-y-4 w-full"}>

          <div
            className={classNames("flex justify-center items-center font-serif text-lg font-bold")}>
            <span className={"mx-1"}>Total amount : {betAmount}</span><CoinIcon/>
          </div>


          <Formik
            validate={(formValues) => {
              const allocated = formValues.squad.map(s => s.value).filter(a => !isNaN(Number(a))).map(a => Number(a)).reduce((c, p) => c + p, 0);
              // console.log('new affectations : ' + JSON.stringify(formValues.squad.map(s => s.value)) + ` total:${allocated}`);
              setCurrentAffectations(formValues.squad.map(s => s.value));
              setBetAmount(allocated);
              // console.log('setting to assign : ' + (fictiveAmount - allocated));
              // setToAssign(fictiveAmount - allocated);
            }}
            initialValues={initialValues}
            onSubmit={(values) => {
              const allocated = values.squad.map(s => s.value).map(a => Number(a)).reduce((c, p) => c + p, 0);

              if (money && (allocated - initialBetAmount > money.amount)) {
                toast(`You don't have enough to allocate ${allocated - initialBetAmount} coins.`);
                return;
              }

              if (allocated < initialBetAmount) {
                toast('You cannot remove trading amount from your previous allocation.');
                return;
              }

              values.squad.forEach((t) => {
                dispatch(updateOrAddToSquad(t));
              });

              gtag('event', 'validateSquadAllocation');
              navigate('/squad-trades', {state: {tournamentId}})
            }}
          >

            {({values, errors, touched}) => (
              <Form className={"flex flex-col justify-center gap-y-4 max-w-2xl w-full"}>
                <FieldArray name="squad">
                  {({insert, remove, push}) => {
                    return (
                      <div className={"flex flex-col gap-y-2"}>
                        {values.squad.length > 0 &&
                          values.squad.map((crypto, index) => (
                            <div
                              className="flex row py-3 border-b-solid border-b-2 border-b-whitesmoke-300 items-center"
                              key={index}>
                              <div className={"grow"}>
                                <img className={"float-left mr-2"} src={crypto.crypto.logo} alt={crypto.crypto.code}
                                     width={20} height={20}
                                     title={crypto.crypto.slug}/>
                                <label htmlFor={`squad.${index}.code`}>{crypto.crypto.code}</label>
                              </div>
                              <div className={"flex flex-col"}>
                                <div className={"flex justify-center items-center gap-x-2"}>
                                  <Field
                                    className={classNames("input", "seein-input", previouslyRegisteredCrypto.data.indexOf(crypto.crypto.code) !== -1 ? "disabled" : "")}
                                    // disabled={previouslyRegisteredCrypto.indexOf(crypto.crypto.code) !== -1 ? true : false}
                                    validate={(v: any) => {
                                      return validateField(v, initialValues.squad[index]?.value);
                                    }}
                                    name={`squad.${index}.value`}
                                    placeholder={`${crypto.crypto.slug} allocation`}
                                    type="number" pattern="[0-9]*" inputmode="numeric"/>
                                  <span className={"mr-2 text-xl"}> <CoinIcon/> </span>
                                </div>
                                {errors.squad && errors.squad[index] && touched.squad &&
                                  <div
                                    className={"text-red-500 text-xs"}>{(errors.squad[index] as FormikErrors<CryptoTeammate>).value}
                                  </div>
                                }
                              </div>
                            </div>
                          ))
                        }
                      </div>
                    );
                  }}
                </FieldArray>
                <div className={"flex gap-x-4 justify-center"}>
                  <button className={"primary order-2"} disabled={betAmount === 0} type="submit">Next</button>
                  <button className={"secondary order-1"}
                          onClick={() => {
                            navigate("/squad-registration?", {state: {tournamentId}})
                          }}>BACK
                  </button>
                </div>
              </Form>
            )}
          </Formik>
          <img className="w-20 h-20 xl:w-40 xl:h-40" src={process.env.REACT_APP_STATIC_ASSETS + "/images/65-min.png"}
               alt="Crypto Squad"/>
        </div>

      </div>
    </Layout>
  );
};

export {SquadAllocationPage}
