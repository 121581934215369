import React from "react";


const BicepIcon: React.FC<{ h?: number }> = ({h = 17}) => <svg width={h} height={h} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M28.4043 16.64H35.1403C35.343 16.3004 35.4016 15.8596 35.2434 15.4436L28.4061 7.07912V16.64H28.4043Z" fill="#EF9645"/>
    <path d="M22.7636 39.6035C22.6622 38.9831 22.5431 38.3964 22.3973 37.8738C21.2951 33.9218 20.3707 32.2489 20.7653 28.1067C21.2809 25.2907 21.2507 19.6391 28.7351 20.5191C30.712 20.752 33.7893 22.1671 35.5511 22.1369C36.5591 22.1191 37.2933 20.08 37.3591 19.4009C37.4498 18.4658 35.8213 16.8764 35.1405 16.6382C34.4596 16.4 31.5636 15.5164 30.3742 15.1733C28.5271 14.6382 28.6231 12.1422 28.5058 11.2533C28.4507 10.8356 28.6551 10.4231 29.0285 10.2027C29.4018 9.98222 29.6951 10.1884 30.0578 10.4249L31.6311 11.5769C32.5147 12.3342 30.0773 13.7813 30.7369 14.0231C30.7369 14.0231 33.8569 15.1947 35.1938 15.4382C35.9262 15.5716 38.4542 12.72 38.6018 11.0471C38.7173 9.73155 32.5609 3.78844 28.1716 1.48088C26.6285 0.670217 25.7236 0.199106 24.7067 0.247106C23.4925 0.303995 23.1102 0.783995 21.5991 2.07822C14.76 7.92888 8.12179 20.5813 7.14757 23.3813C3.15468 34.8675 2.53068 40.9191 2.38846 44.3378C2.30134 45.3867 2.25334 46.4302 2.25334 47.4631C2.3689 47.4631 0.475567 56.352 2.25334 58.1298C4.03112 59.9076 11.7218 59.9076 11.7218 59.9076C29.4996 66.9991 62.5129 65.1484 62.5129 47.0489C62.5129 25.4098 29.0676 28.2809 22.7636 39.6035Z" fill="#FFDC5D"/>
    <path d="M36.6765 57.0276C31.0587 56.4373 26.6711 53.6836 26.424 53.5253C25.5973 52.9973 25.3556 51.9022 25.8818 51.0738C26.408 50.2453 27.5049 50.0018 28.3351 50.5262C28.4062 50.5707 35.4516 54.9564 42.2836 53.1342C46.0613 52.128 49.1582 49.3742 51.4871 44.9547C51.9458 44.0836 53.0267 43.7529 53.8889 44.2098C54.7565 44.6667 55.0907 45.7422 54.6338 46.6116C51.816 51.9556 47.96 55.3102 43.1725 56.5778C40.9378 57.168 38.7245 57.2427 36.6765 57.0276Z" fill="#EF9645"/>
  </svg>
;

export {BicepIcon};