import React from "react";


const BackIcon: React.FC<{ h?: number, color?: string }> = ({h = 24, color = 'white'}) =>
  <svg width="8" height="14" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M6.60691 0L8 1.37054L2.78617 6.5L8 11.6295L6.60691 13L0 6.5L6.60691 0Z" fill={color}/>
  </svg>


;

export {BackIcon};