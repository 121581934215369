import React, {useEffect, useState} from "react";
import TemporaryDrawer from "../ui/temp-drawer.component";
import {useNavigate} from "react-router-dom";
import {enableBodyScroll} from "../../utils/HtmlHelper";
import {useGetPlayerQuery} from "../../apis/users.api";
import {getFirstPlayerItemsComp} from "../users/podium.component";
import {GemsCostButton} from "../ui/secondary-gems-button.component";
import {useActivateHideMutation, useActivateSpyMutation, useGetCyclePlayerQuery} from "../../apis/game.api";
import toast from "react-hot-toast";
import {toastSuccess} from "../../utils/ToastUtils";
import {useCanSpy, useIsSpyable} from "../../hooks/useCanSpy";
import {AlertTitle, Snackbar} from "@mui/material";
import Alert from "@mui/material/Alert";
import {getBottomAlertSx} from "../ui/MuiStyle";
import {ShieldIcon} from "../ui/shield-icon";

const PlayerActionDrawerComponent: React.FC<{
  open: boolean,
  pseudo: string,
  onClose: Function
}> = ({pseudo, open, onClose}) => {

  const nav = useNavigate();
  const [showDrawer, setShowDrawer] = useState(open);
  const [activateSpyMutation] = useActivateSpyMutation();
  const [activateHideMutation] = useActivateHideMutation();
  const {data: targetPlayer} = useGetPlayerQuery({pseudo: pseudo}, {skip: !pseudo || pseudo == ''});
  const {data: cyclePlayer} = useGetCyclePlayerQuery();
  const isTargetSpyable = useIsSpyable(targetPlayer?.userId);
  const canSpy = useCanSpy(targetPlayer?.userId);

  const [showSnack, setShowSnack] = useState(false);
  useEffect(() => {
    if (pseudo) {
      setShowDrawer(true);
    } else setShowDrawer(false);
  }, [pseudo]);

  return (
    <>
      <TemporaryDrawer title={`View`} closeWhenClickingOutside={true} anchor={'bottom'} size={'auto'}
                       open={showDrawer}
                       lineIcon={false}
                       onClose={() => {
                         setShowDrawer(false);
                         setShowSnack(false)
                         onClose();
                       }}>
        <div className={"w-full h-full flex flex-col justify-between items-center gap-y-2 mb-5"}>

          {targetPlayer &&
            <div className={"text-black p-4"}>
              {getFirstPlayerItemsComp(targetPlayer, 0, 20)}
            </div>
          }


          {targetPlayer && cyclePlayer && targetPlayer.userId !== cyclePlayer.userId &&
            <>
              <p className={"text-grey-700 font-medium py-1 text-center"}>
                Use <span className={"font-bold"}>View Profile</span> to watch the Crypto Card Collection of a player
                and
                its
                statistics.
              </p>

              <p className={"text-grey-700 font-medium py-1 text-center"}>
                Use <span className={"font-bold"}>Spy positions</span> to analyze the player's Invest and Bet positions
                taken
                during the current cycle.
              </p>

              <p className={"text-grey-700 font-medium py-1 text-center"}>
                What do you want to do ?
              </p>
            </>
          }

          {targetPlayer && cyclePlayer && targetPlayer.userId === cyclePlayer.userId &&
            <p className={"text-grey-700 font-medium py-1 text-center"}>
              {cyclePlayer.forbidSpy !== true && <>Use <span className={"font-bold"}>Hide positions</span> to forbid
                other players to spy on your positions.</>}
              {cyclePlayer.forbidSpy === true && <span className={"flex items-center gap-x-2"}>Your are protected from Spies <ShieldIcon h={32}/></span>}
            </p>
          }


          <div className={"w-full flex flex-col gap-y-3"}>

            {targetPlayer && cyclePlayer &&
              <button className={"primary"} onClick={() => {
                enableBodyScroll();
                nav(`/collectibles/${pseudo}`);
              }}>View profile
              </button>
            }


            {/*View*/}
            {targetPlayer && canSpy && targetPlayer.userId !== cyclePlayer?.userId &&
              <button className={"secondary"} onClick={() => {
                enableBodyScroll();
                nav('/spy/bets', {state: {pseudo}})
              }}>View positions
              </button>
            }

            {/*SPY*/}
            {targetPlayer && !canSpy &&
              <GemsCostButton quantity={3} label={'Spy positions'} onClick={async () => {
                if (isTargetSpyable) {
                  toast.loading('Activating spy...');
                  activateSpyMutation({targetUserId: targetPlayer.userId}).then((res) => {
                    //@ts-ignore
                    if (!res.error) {
                      toastSuccess('Spy activated');
                      gtag('event', 'activatePlayerSpy');
                    }
                  })
                } else {
                  setShowSnack(true);
                }
              }}/>
            }


            {/*  HIDE */}
            {targetPlayer && cyclePlayer && targetPlayer.userId === cyclePlayer.userId && cyclePlayer.forbidSpy !== true &&
              <GemsCostButton quantity={3} label={'Hide positions'} onClick={async () => {
                if (isTargetSpyable) {
                  toast.loading('Activating hiding...');
                  activateHideMutation().then((res) => {
                    //@ts-ignore
                    if (!res.error) {
                      toastSuccess('Hide mode activated, nobody can spy you anymore.');
                      gtag('event', 'activatePlayerHide');
                    }
                  })
                } else {
                  setShowSnack(true);
                }
              }}/>
            }
          </div>
        </div>

        <Snackbar sx={{zIndex: 100000, bottom: '16px!important', left: "20px", right: "20px"}}
                  anchorOrigin={{vertical: 'bottom', horizontal: 'center'}} open={showSnack}
                  autoHideDuration={5000} onClose={() => {
          setShowSnack(false)
        }}>
          <Alert className={"flat-card"} sx={getBottomAlertSx()} severity="error">
            <AlertTitle>Oops</AlertTitle>
            <span className={"text-gray-500"}>{`Player ${pseudo} hide his positions you cannot spy them`}</span>
          </Alert>
        </Snackbar>
      </TemporaryDrawer>
    </>

  )
}

export {PlayerActionDrawerComponent};