import React from 'react'
import {FullScreenDialog} from "../ui/modal-confirmation";
import {UserNotification} from "../../models/User";
import {useGetCollectibleQuery} from "../../apis/collectibles.api";
import {CollectibleCardComponent} from "../collectibles/collectible-card.component";


const CollectibleXpRewardNotifComponent: React.FC<{
  notification: UserNotification,
  timer: number,
  visible: boolean, onClose: Function
}> = ({timer, visible, notification, onClose}) => {

  const {data: collectible} = useGetCollectibleQuery(notification.payload.cryptoId);

  return <FullScreenDialog onClose={onClose} timer={timer} openProp={visible}>
    <div className={"h-full flex flex-col gap-y-16 justify-center items-center"}>
      <div className={"flex-col flex gap-3 justify-center items-center"}>
        <div
          className={"rounded-lg bg-black bg-opacity-20 text-white font-bold px-2 py-2 text-lg text-center"}>
          {notification.name}
        </div>
        {collectible && <CollectibleCardComponent shineEffect={true} collectible={collectible} animateProgressBar={true}/>}
        <div className={"text-white text-center"}>
          {notification.text}
        </div>
      </div>
    </div>
  </FullScreenDialog>;
}

export {CollectibleXpRewardNotifComponent};