import React, {useEffect, useState} from 'react';
import {ShopProduct} from "../../models/shop/Product";
import {BetsIcon} from "../svg/bets-icon";
import {DoubleShield, ShieldIcon, TripleShield} from "../ui/shield-icon";
import {QuantityGemsComponent} from "../ui/quantity-gems.component";
import * as _ from "lodash";
import GemsBuyableDrawerProduct from "./buy-gems-product-drawer.component";
import {UserItem} from "../../models/User";
import
{BoostIcon, DoubleBoostIcon, TripleBoostIcon} from "../svg/BoostIcon";


interface BuyShieldDrawerProps {
  gemsBuyableItems: ShopProduct[],
}

function getSubFamilyTitle(subFamilyCode: string) {
  let res = ''
  if (subFamilyCode === 'shield') {
    res = 'Use Shields to protect you from a bet loss !'
  }
  else if (subFamilyCode === 'bet_boost') {
    res = 'Use boost to get better odds !'
  }
  return res;
}

function getShieldCItemComponent(prod: ShopProduct): JSX.Element {
  let res = <></>;
  if (prod.subFamily === 'shield') {
    res = <ShieldItem item={prod}></ShieldItem>;
  } else if (prod.subFamily === 'bet_boost') {

    res = <BoostItem item={prod}></BoostItem>;
  }
  return res;
}

export const ShieldItemIcon: React.FC<{ item: UserItem, h?: number }> = ({
                                                                           h = 48,
                                                                           item,
                                                                         }) => {
  return <>
    {item.code === 'shield_x_1' &&
      <ShieldIcon h={h}></ShieldIcon>
    }
    {item.code === 'shield_x_2' &&
      <DoubleShield h={h}></DoubleShield>
    }
    {item.code === 'shield_x_3' &&
      <TripleShield h={h}></TripleShield>
    }
  </>
}

const getShieldName = (item: UserItem,) =>{
  if (item.code === 'shield_x_1') {
    return'Blue Shield'
  }
  else if (item.code === 'shield_x_1') {
    return'Silver Shield'
  }
  else if (item.code === 'shield_x_1') {
    return 'Gold Shield';
  }
}

export const ShieldItem: React.FC<{ item: UserItem, iconElement?: JSX.Element, h?: number,  showDetailedDescription?: boolean }> = ({
                                                                                                  showDetailedDescription = false,
                                                                                                  h,
                                                                                                  item,
                                                                                                  iconElement =
                                                                                                    <></>
                                                                                                }) => {
  return <div className={"flex flex-col bg-grey-100 rounded-lg p-3 relative items-center shadow-inner min-w-28"}>
    {iconElement && <span className={"absolute right-1 top-1"}>{iconElement}</span>}
    <ShieldItemIcon item={item} h={h}/>
    <div className={"text-sm text-opacity-70"}>{`Pay back ${item?.metadata?.value}%`}</div>
    {showDetailedDescription && <div className={"text-xxs"}>{getShieldName(item)} prevent you from loosing your stake</div>}
  </div>
}


export const BoostItemIcon: React.FC<{ item: UserItem, h?: number }> = ({
                                                                          h = 48,
                                                                          item,
                                                                        }) => {
  return <>
    {item.code === 'bet_boost_x_1' &&
      <BoostIcon h={h}></BoostIcon>
    }
    {item.code === 'bet_boost_x_2' &&
      <DoubleBoostIcon h={h}></DoubleBoostIcon>
    }
    {item.code === 'bet_boost_x_3' &&
      <TripleBoostIcon h={h}></TripleBoostIcon>
    }
  </>
}

export const BoostItem: React.FC<{
  item: UserItem,
  iconElement?: JSX.Element,
  h?: number,
  showDetailedDescription?: boolean
}> = ({
        h, showDetailedDescription = false,
        item,
        iconElement = <></>
      }) => {
  return <div className={"flex flex-col bg-grey-100 rounded-lg p-3 relative items-center shadow-inner min-w-28"}>
    {iconElement && <span className={"absolute right-1 top-1"}>{iconElement}</span>}
    <BoostItemIcon item={item} h={h}/>
    <div className={"text-sm text-opacity-70"}>{`Odd Boost +${Number(item?.metadata?.value).toFixed(1)} `}</div>
    {showDetailedDescription && <div className={"text-xs"}>Boost bet odds</div>}
  </div>
}


const GemsBuyableItems: React.FC<BuyShieldDrawerProps> = ({
                                                            gemsBuyableItems,
                                                          }) => {

  const [grouped, setGrouped] = useState<_.Dictionary<ShopProduct[]>>({});
  useEffect(() => {
    setGrouped(_.groupBy(gemsBuyableItems, t => t.subFamily));
  }, [gemsBuyableItems]);

  const [selected, setSelected] = useState<ShopProduct | null>(null);

  return <div className={"shadow-inner rounded"}>{Object.keys(grouped).map((k) => <div
    className={"rounded-b-2xl py-4"}>
    <div className={"w-full text-center pb-2"}>{getSubFamilyTitle(k)}</div>

    <div className={"w-full flex flex-wrap justify-center gap-y-4 gap-x-2 pt-3"}>

      {k === 'shield' && <>
        {grouped[k].map((i) => <div key={`prod_${i.code}`} className={"relative flat-card !p-4"} onClick={() => {
          setSelected(i);
        }}>
          <ShieldItem item={i} showDetailedDescription={false}/>
          <div className={"w-full flex justify-center"}>
            <QuantityGemsComponent quantity={i.displayedPrice} size={18}/>
          </div>
        </div>)}
      </>
      }

      {k === 'bet_boost' && <>
        {grouped[k].map((i) => <div key={`prod_${i.code}`} className={"relative flat-card !p-4"} onClick={() => {
          setSelected(i);
        }}>
          <BoostItem item={i} showDetailedDescription={false}/>
          <div className={"w-full flex justify-center"}>
            <QuantityGemsComponent quantity={i.displayedPrice} size={18}/>
          </div>
        </div>)}
      </>
      }

    </div>
  </div>)}

    {selected && <GemsBuyableDrawerProduct icon={getShieldCItemComponent(selected)} product={selected} open={!!selected}
                                           onClose={() => setSelected(null)}/>}
  </div>
}

export default GemsBuyableItems;