import React from 'react'
import {SocialSharingComponent, SocialSharingProps} from './sharing.component'

export const SharingCardComponent: React.FC<SocialSharingProps> = ((sharingOptions) => {

  return <div className={"w-full max-w-4xl"}>

        {sharingOptions.player && <>
          <div className={"flat-card gap-y-2 w-full"}>

            <div className={"grid gap-4 w-full gap-y-2 overflow-hidden text-ellipsis whitespace-nowrap pb-4"}>

              {/* Text */}
              <div className={"text-wrap text-black font-normal text-sm leading-4"}>
                Invite friends to earn rewards. Make sure they use your link to sign up and begin their journey.
              </div>                  

            </div>
            
            <div className={"flex flex-col gap-y-1 py-1"}>

              <div className={"w-full text-center opacity-70"}>Share Invite link</div>

              <SocialSharingComponent
                player={sharingOptions.player}
                sharingText={sharingOptions.sharingText} 
              />

            </div>            

          </div>

        </>}

      </div>

})