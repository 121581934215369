import {GameResult} from "../models/User";
import {emptySplitApi} from "./empty.api";
import {Player} from "../models/game/Player";

export interface GameStats {
  totalPlayed: number,
  investment: number,
  moneyGain: number,
  type: string,
  biggestGain: number,
  totalPlayedWithGain: number,
  total1st: number,
  total2nd: number,
  total3rd: number
}

export const gameApi = emptySplitApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({

    getTradingWeekResults: build.query<GameResult[], { type: 'weekly' | 'daily' }>({
      query: (params) => ({
        url: "/game/getTradingWeekResults",
        method: "GET",
        params
      }),
      providesTags: ['getTradingWeekResults'],
    }),

    getUserBestRank: build.query<number, { pseudo?: string }>({
      query: (params) => ({
        url: "/game/getUserBestRank",
        method: "GET",
        params
      }),
      providesTags: (result, error, id) => [{type: 'getUserBestRank', id: id?.pseudo || ''}],
    }),

    getGamingStats: build.query<Array<GameStats>, { pseudo?: string }>({
      query: (params) => ({
        url: "/game/getUserGamingStats",
        method: "GET",
        params
      }),
      providesTags: (result, error, id) => [{type: 'getGamingStats', id: id?.pseudo || ''}],
    }),
    getLastWeekParticipationCount: build.query<number, void>({
      query: () => ({
        url: "/game/getLastWeekParticipationCount",
        method: "GET",
      }),
      providesTags: (result, error, id) => [{type: 'getLastWeekParticipationCount'}],
    }),
    getGeneralCollectibleRewardRank: build.query<number, void>({
      query: () => ({
        url: "/game/getGeneralCollectibleRewardRank",
        method: "GET",
      }),
      providesTags: (result, error, id) => [{type: 'getGeneralCollectibleRewardRank'}],
    }),
    getPortfolioCollectibleRewardRank: build.query<number, void>({
      query: () => ({
        url: "/game/getPortfolioCollectibleRewardRank",
        method: "GET",
      }),
      providesTags: (result, error, id) => [{type: 'getPortfolioCollectibleRewardRank'}],
    }),
    getFriendsCumulatedGemsAmount: build.query<{ [key: string]: number }, void>({
      query: () => ({
        url: "/game/getFriendsCumulatedGemsAmount",
        method: "GET",
      }),
      providesTags: (result, error, id) => [{type: 'getFriendsCumulatedGemsAmount'}],
    }),

    getBetDurationOddBonus: build.query<Array<{ hours: number, label: string, bonus: number }>, void>({
      query: () => ({
        url: "/game/getBetDurationOddBonus",
        method: "GET",
      }),
      providesTags: (result, error, id) => [{type: 'getFriendsCumulatedGemsAmount'}],
    }),

    getCyclePlayer: build.query<Player, string | void>({
      query: (userId) => ({
        url: "/game/getPlayer",
        params: {userId},
        method: "GET",
      }),
      providesTags: (result, error, id) => [{type: 'getCyclePlayer'}],
    }),

    activateSpy: build.mutation<void, { targetUserId: string }>({
      query: (dto) => ({
        url: "/game/activateSpy",
        method: "POST",
        body: dto,
      }),
      invalidatesTags: (a, b, o) => ['getCyclePlayer', 'User'],
    }),

    activateHide: build.mutation<void, void>({
      query: () => ({
        url: "/game/activateHide",
        method: "POST",
      }),
      invalidatesTags: (a, b, o) => ['getCyclePlayer', 'User'],
    }),

    getMaxBetAmount: build.query<number, string | void>({
      query: (cryptoId) => ({
        url: "/game/getMaxBetAmount",
        params: {cryptoId},
        method: "GET",
      }),
      providesTags: (result, error, id) => [{type: 'getMaxBetAmount'}],
    }),
  }),
});

export const {
  useGetMaxBetAmountQuery,
  useActivateHideMutation,
  useGetCyclePlayerQuery,
  useActivateSpyMutation,
  useGetBetDurationOddBonusQuery,
  useGetFriendsCumulatedGemsAmountQuery,
  useGetTradingWeekResultsQuery,
  useGetGamingStatsQuery,
  useGetUserBestRankQuery,
  useGetLastWeekParticipationCountQuery,
  useGetGeneralCollectibleRewardRankQuery,
  useGetPortfolioCollectibleRewardRankQuery
} = gameApi;