import React from 'react';
import {SignupForm} from '../components/auth/signup-form/signup-form.component';
import HeightLayout from "../HeightLayout";

const ClaimSignupPage: React.FC = () => {
  return (
    <HeightLayout>
      <div className="flex flex-col justify-center items-center w-full">
        <div className={"flex flex-col w-full max-w-xl gap-y-5 "}>
          <SignupForm onlyForm={true} noRedirect={true} hideCoinbase={true} hideGoogle={false} hideTwitter={false}
                      hideTikTok={true} hideSignin={true} signupCtaLabel={"CLAIM YOUR CITY"}/>
        </div>
      </div>
    </HeightLayout>
  );
};

export {ClaimSignupPage};