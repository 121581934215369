import React from 'react';
import {Link} from "react-router-dom";
import Layout from "../Layout";
import {PublicNavBar} from "../components/ui/public-nav-bar.component";


const LinkExpiredPage: React.FC = () => {
  return (
    <Layout>
      <PublicNavBar/>
      <div className={"flex justify-center items-center flex flex-col"}>
        <h1 className={"h1"}>This link has expired!</h1>
        <Link className={'block px-4 py-2 text-sm text-gray-700'} to="/login"><button className={"primary"}>Home</button></Link>
      </div>
    </Layout>
  );
};

export {LinkExpiredPage}