import React from "react";


const FireIcon: React.FC<{ h?: number }> = ({h = 24}) =><svg width={h} height={h} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2653_1176)">
      <path d="M35 19C35 16.938 34.633 14.961 33.96 13.132C33.5 18.521 30.627 21.289 27.625 20C24.813 18.792 26.708 14.083 26.848 11.836C27.084 8.02699 26.836 3.66699 19.917 0.0419922C22.792 5.54199 20.25 8.95899 17.584 9.16699C14.626 9.39799 11.917 6.62499 12.917 2.12499C9.679 4.51099 9.585 8.52699 10.584 11.125C11.626 13.833 10.542 16.083 8.001 16.333C5.161 16.613 3.583 13.292 5.038 7.99999C2.52 10.965 1 14.805 1 19C1 28.389 8.611 36 18 36C27.389 36 35 28.389 35 19Z" fill="#F4900C"/>
      <path d="M28.394 23.999C28.542 27.083 25.833 28.292 24.375 27.708C22.269 26.865 22.834 25.417 22.292 22.417C21.75 19.417 19.667 17.334 16.584 16.417C18.834 22.75 15.337 25.084 13.504 25.501C11.632 25.927 9.751 25.5 9.536 21.494C7.352 23.668 6 26.676 6 30C6 30.368 6.023 30.73 6.055 31.09C9.125 34.124 13.342 36 18 36C22.658 36 26.875 34.124 29.945 31.09C29.977 30.73 30 30.368 30 30C30 27.813 29.416 25.764 28.394 23.999Z" fill="#FFCC4D"/>
    </g>
    <defs>
      <clipPath id="clip0_2653_1176">
        <rect width="36" height="36" fill="white"/>
      </clipPath>
    </defs>
  </svg>;

export {FireIcon};