import React, {useEffect, useState} from 'react';
import Layout from "../../Layout";
import {useGetUserQuery} from "../../apis/users.api";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {RootState} from "../../app/store";
import {addToSquad, clearSquad, removeFromSquad, removeFromSquadWithCode} from "./squadSlice";
import {SquadComponent} from "../../components/squad/squad.component";
import {CryptoCurrency} from "../../models/Wallet";
import {useGetTournamentQuery, useGetUserScoreQuery} from "../../apis/tournaments.api";
import toast from "react-hot-toast";
import {CryptoTeammate} from "../../models/squad/CryptoTeammate";
import {useLocation} from "react-router";
import {startRegistration} from "../../slices/tournamentSlice";
import {toastMessage} from "../../utils/ToastUtils";
import {useGetCryptosFromIdsQuery} from "../../apis/cryptos.api";
import {useGetPreviouslyRegisteredCryptosFromTournament} from "./squad-allocation.page";
import {CryptoFilterSelectionElement} from "../../components/crypto-filtered-selection-element.component";
import {SecondaryNavigation} from "../../components/ui/secondary-navigation.component";
import {hasElements} from "../../utils/Helper";
import {InnerHeader} from "../../components/ui/app-inner-header.component";

const MAX_CRYPTO = 5;
const SquadRegisterPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {data: user, isLoading: isUserLoading} = useGetUserQuery()
  const squad = useAppSelector((state: RootState) => state.squad.value);
  const cachedId = useAppSelector((state: RootState) => state.subscribingTournament.id);
  const {state} = useLocation();
  const {tournamentId} = state;
  //tournament
  const {
    data: tournament,
    isLoading: isTournamentLoading
  } = useGetTournamentQuery(tournamentId || undefined, {skip: !tournamentId});

  //user subscription
  const {
    data: subscription, isLoading: isSubscriptionLoading
  } = useGetUserScoreQuery({tournamentId: tournamentId}, {skip: !tournamentId});

  const [minSquadSize, setMinSquadSize] = useState(Number(1));
  const previouslyRegisteredCrypto =
    useGetPreviouslyRegisteredCryptosFromTournament(subscription);

  const {
    data: prevCrypto,
    isLoading: areUserCryptoLoading
  } = useGetCryptosFromIdsQuery({ids: previouslyRegisteredCrypto}, {skip: !previouslyRegisteredCrypto || previouslyRegisteredCrypto.length === 0});

  useEffect(() => {
    if (!tournamentId) {
      toast.error('Missing tournament');
      navigate("/tournament");
    }
  }, []);

  useEffect(() => {
    if (cachedId != tournamentId) {
      dispatch(clearSquad());
      if (tournament) {
        if (!areUserCryptoLoading && !isSubscriptionLoading && (!subscription || prevCrypto)) {
          dispatch(startRegistration(tournament.id));
          if (subscription) {
            const cryptos: string[] = subscription.squadInputPayload.cryptos;
            const values: number[] = subscription.squadInputPayload.cryptoValues;
            cryptos.forEach((c, idx) => {
              const crypto = prevCrypto?.find(crypto => crypto._id === c);
              if (crypto) {
                // const alloc = (values[idx] || 0) * tournament.tournamentInputPayload.amount / 100;
                dispatch(addToSquad({crypto, value: values[idx]}));
              }
            });
          }
        }
      }
    }
  }, [tournament, cachedId, prevCrypto, areUserCryptoLoading, isTournamentLoading]);

  return (
    <Layout>
      <InnerHeader pageTitle={"Squad Tournament"} backPath={"/tournament"}>
      </InnerHeader>
      <div className={"flex flex-col gap-x-4 gap-y-4 max-w-3xl justify-center items-center mb-10"}>
        <SecondaryNavigation navItems={[
          {path: '/squad-registration', name: 'Squad', state: {tournamentId}},
          {path: '/squad-distribution', name: 'Allocation', state: {tournamentId}, hidden: !hasElements(squad)},
          {path: '/squad-trades', name: 'Action', state: {tournamentId}, hidden: !hasElements(squad)},
        ]}/>
        <div className={"flex flex-col items-center mt-4"}>
          <span className={"text-xs xl:text-sm mb-1"}>Your Crypto Squad</span>
          <div className={"w-full flex flex-wrap justify-center mb-10 gap-x-2"}>

            <SquadComponent squad={squad} maxSlot={MAX_CRYPTO}
                            mandatorySlot={minSquadSize}
                            onClick={(crypto: CryptoTeammate) => {
                              if (previouslyRegisteredCrypto.indexOf(crypto.crypto._id) === -1) {
                                dispatch(removeFromSquad(crypto))
                              } else {
                                toastMessage('Cannot remove previously registered crypto')
                              }
                            }}/>
          </div>
        </div>


        <CryptoFilterSelectionElement
          selectedCryptos={squad.map(o => o.crypto._id) || []}
          onSelect={(crypto: CryptoCurrency) => {
            if (squad.length < MAX_CRYPTO) {
              dispatch(addToSquad({crypto, value: 0}));
            }
          }}
          onDeselect={(crypto: CryptoCurrency) => {
            if (previouslyRegisteredCrypto.indexOf(crypto._id) === -1) {
              dispatch(removeFromSquadWithCode(crypto.code));
            } else {
              toastMessage('Cannot remove previously registered crypto')
            }
          }}/>

        <div className={"flex gap-x-4 sticky bottom-20  w-full glass-light p-2 rounded-2xl justify-center"}>
          <button className={"primary " + (squad.length < 1 ? 'disabled' : '')}
                  disabled={squad.length < minSquadSize}
                  onClick={() => {
                    gtag('event', 'validateSquadChoice');
                    navigate('/squad-distribution');
                  }}> Validate
          </button>
        </div>

        <img className="w-20 h-20 xl:w-40 xl:h-40" src={process.env.REACT_APP_STATIC_ASSETS + "/images/65-min.png"}
             alt="Crypto Squad"/>
      </div>
    </Layout>
  );
};

export {SquadRegisterPage};