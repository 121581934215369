import React from "react";


const AddIcon: React.FC<{ h?: number }> = ({h = 24}) =><svg width={h} height={h} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2424_7328)">
      <path d="M1 7H13" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M7 1V13" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_2424_7328">
        <rect width="14" height="14" fill="white"/>
      </clipPath>
    </defs>
  </svg>;

export {AddIcon};