import {CreateUserRequest} from "../dto/create-user-request.dto";
import {LoginRequest} from "../dto/login-request.dto";
import {
  CityLink,
  CityLinkResponse,
  DailyQuest, InventoryItem,
  Like,
  LikesCount,
  PlayerFriend,
  PlayerItem,
  PlayerListResponse,
  ReferralProgram,
  ReferralsDatas,
  User,
  UserNotification,
  UserXpResponse
} from "../models/User";
import {UpdateUserInfosRequest} from "../dto/update-user-request.dto";
import {emptySplitApi} from "./empty.api";
import {PaginationRequestDto} from "../dto/pagination-request.dto";
import {GenerateCityLinkRequestRequest} from "../dto/generate-city-link-request.dto";
import {CryptoCurrency} from "../models/Wallet";
import axios from "axios";
import { stopAnimation } from "../slices/gemSlice";

export const usersApi = emptySplitApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    createUser: build.mutation<User, CreateUserRequest>({
      query: (createUserRequest) => ({
        url: '/users',
        method: 'POST',
        body: createUserRequest,
      }),
    }),
    getUser: build.query<User, void>({
      query: () => ({url: "/users"}),
      providesTags: ['User'],
      transformResponse(response: User, meta, arg) {
        if (response && response._id) {
          gtag('set', {'signupDate': response.createdAt});
          try {
            const trk = localStorage.getItem('_seein_apps_uti');
            if (response._id) {
              if (trk && response._id !== trk) {
                console.log('trk');
                axios.post('/users/trk', {trk}).then(function () {
                }).catch(function (error) {
                });
              }
              localStorage.setItem('_seein_apps_uti', response._id);
              localStorage.setItem('_tanjiblocks_premium', String(!!response.isPremium));
            }
          } catch (e) {
            console.error('err while setting trk');
          }
        }

        return response;
      },
    }),
    getUserNotifications: build.query<{ notifications: UserNotification[] }, void>({
      query: () => ({url: "/users/notifications"}),
      providesTags: ['UserNotifications'],
    }),
    getUserSafe: build.query<User, void>({
      query: () => ({url: "/users/userSafe"}),
      providesTags: ['User'],
    }),
    getPlayersList: build.query<PlayerListResponse, PaginationRequestDto>({
      query: (paginationRequestDto) => {
        return {url: "/users/players/list", params: paginationRequestDto}
      },
      providesTags: ['UserList'],
    }),
    getPlayer: build.query<PlayerItem, { pseudo?: string }>({
      query: (playerReq) => {
        return {url: "/users/player", params: playerReq}
      },
      providesTags: (result, error, id) => [{type: 'getPlayer', id: id?.pseudo || ''}],
    }),
    getInfinitePlayersList: build.query<PlayerListResponse, PaginationRequestDto>({
      query: (paginationRequestDto) => {
        return {url: "/users/players/list", params: paginationRequestDto}
      },
      onQueryStarted(arg, api): Promise<void> | void {
        // console.log(`getInfinitePlayersList call : arg = ${JSON.stringify(arg)}`);
      },
      // Only have one cache entry because the arg always maps to one string
      serializeQueryArgs: ({endpointName, queryArgs}) => {
        return endpointName + queryArgs.week
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache: PlayerListResponse, newItems: PlayerListResponse) => {
        // if (currentCache.week !== newItems.week) {
        //   console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> BAD cache')
        // }
        // console.log(`getInfinitePlayersList result : ${newItems.players.length},week=${newItems.week}, NewPageIndex:${newItems.pageIndex} rank from : ${hasElements(newItems.players) ? `${newItems.players[0].rank} ${newItems.players[0].pseudo} ` : 'none'}`);
        currentCache.pageIndex = newItems.pageIndex;
        currentCache.players.push(...newItems.players)
      },
      // Refetch when the page arg changes
      forceRefetch({currentArg, previousArg}) {
        const res = currentArg !== previousArg;
        // console.log(`getInfinitePlayersList forceRefetch = ${res}, prev=${JSON.stringify(previousArg)} , new =${JSON.stringify(currentArg)}`);
        return res;
      },
      providesTags: ['InfinitePlayerList'],
    }),
    getXp: build.query<UserXpResponse, void>({
      query: () => ({url: "/game/xp"}),
      providesTags: ['UserXp'],
    }),
    login: build.mutation<User, LoginRequest>({
      query: (loginRequest) => ({
        url: "/auth/login",
        method: "POST",
        body: loginRequest,
      }),
      // transformResponse(response: User, meta, arg) {
      //   console.log(`login rtk transformResponse ${JSON.stringify(response)}`);
      //   return response;
      // },
      // async onQueryStarted(arg, {dispatch}) {
      //   console.log('onQueryStarted login')
      //   dispatch(emptySplitApi.util.resetApiState());
      // },
      invalidatesTags: ['User', 'IsAuth'],
    }),
    logoutUser: build.mutation<void, void>({
      query: (createUserRequest) => ({
        url: 'auth/logout',
        method: 'GET',
        body: createUserRequest,
      }),
      async onQueryStarted(arg, {dispatch}) {
        dispatch(emptySplitApi.util.resetApiState());
      },
      invalidatesTags: ['User'],
    }),
    updateUserInfos: build.mutation<User, UpdateUserInfosRequest>({
      query: (updateUserInfosRequest) => ({
        url: "/users/update",
        method: "POST",
        body: updateUserInfosRequest,
      }),
      invalidatesTags: ['User'],
    }),
    generateCityLink: build.mutation<CityLink, GenerateCityLinkRequestRequest>({
      query: (updateUserInfosRequest) => ({
        url: "/users/getCityLink",
        method: "POST",
        body: updateUserInfosRequest,
      }),
      transformResponse(response: CityLinkResponse, meta, arg) {
        return {url: document.location.origin + '/city?cityshareuid=' + response._id};
      },
      invalidatesTags: [],
    }),

    getUserCityLink: build.query<LikesCount, string>({
      query: (userId) => ({
        url: "/users/getUserCityLink",
        params: {userId},
        method: "GET",
      }),
    }),
    deleteCityLinks: build.mutation<any, void>({
      query: () => ({
        url: "/users/deleteCityLinks",
        method: "POST",
        body: {},
      }),
      invalidatesTags: [],
    }),
    like: build.mutation<any, string>({
      query: (cityuid) => ({
        url: "/users/like",
        method: "POST",
        body: {cityuid},
      }),
      async onQueryStarted(cityuid, {dispatch, queryFulfilled}) {
        const patchResult = dispatch(
          usersApi.util.updateQueryData('doILike', cityuid, (draft) => {
            if (draft) {
              Object.assign(draft, {liked: true});
            }
          })
        )

        const patchResult2 = dispatch(
          usersApi.util.updateQueryData('getLikes', cityuid, (draft: LikesCount) => {
            if (draft && typeof draft.value !== 'undefined') {
              draft.value++;
            }
          })
        )

        try {
          await queryFulfilled
        } catch {
          patchResult.undo();
          patchResult2.undo();

          /**
           * Alternatively, on failure you can invalidate the corresponding cache tags
           * to trigger a re-fetch:
           * dispatch(api.util.invalidateTags(['Like']))
           */
          dispatch(usersApi.util.invalidateTags(['Like']));
        }
      },
      invalidatesTags: ['Like', 'UserXp'],

    }),
    dislike: build.mutation<any, string>({
      query: (cityuid) => ({
        url: "/users/dislike",
        method: "POST",
        body: {cityuid},
      }),
      async onQueryStarted(cityuid, {dispatch, queryFulfilled}) {
        const patchResult = dispatch(
          usersApi.util.updateQueryData('doILike', cityuid, (draft) => {
            if (draft) {
              Object.assign(draft, {liked: false})
            }
          })
        );
        const patchResult2 = dispatch(
          usersApi.util.updateQueryData('getLikes', cityuid, (draft: LikesCount) => {
            if (draft && typeof draft.value !== 'undefined') {
              draft.value--;
            }
          })
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo();
          patchResult2.undo();

          /**
           * Alternatively, on failure you can invalidate the corresponding cache tags
           * to trigger a re-fetch:
           * dispatch(api.util.invalidateTags(['Like']))
           */
          dispatch(usersApi.util.invalidateTags(['Like']))
        }
      },
      invalidatesTags: ['Like', 'UserXp'],
    }),
    getLikes: build.query<LikesCount, string | undefined>({
      query: (cityuid) => ({
        url: "/users/likes",
        params: {cityuid},
        method: "GET",
      }),
      providesTags: ['Like'],
    }),
    doILike: build.query<Like, string | undefined>({
      query: (cityuid) => ({
        url: "/users/doilike",
        params: {cityuid},
        method: "GET",
      }),
      providesTags: ['Like'],
    }),
    getUserCryptos: build.query<CryptoCurrency[], { onlyVerifiedCrypto: boolean }>({
      query: (params) => ({
        url: "/wallets/getUserCryptos",
        params,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{type: 'UserCryptos', id: 'LIST'}],
    }),
    getUserPublicCryptos: build.query<string[], void>({
      query: () => ({
        url: "/wallets/getUserPublicCryptos",
        method: "GET",
      }),
      providesTags: (result, error, id) => [{type: 'UserPublicCryptos', id: 'LIST'}],
    }),
    updateOnBoardingUser: build.mutation<void, any>({
      query: (name) => ({
        url: `/users/update/onboarding/${name}`,
        method: "POST",
        body: {},
      }), invalidatesTags: ['User'],
    }),
    updatePseudo: build.mutation<void, string>({
      query: (name) => ({
        url: `/users/update/pseudo/${name}`,
        method: "POST",
        body: {},
      }), invalidatesTags: ['User'],
    }),
    getReferralLink: build.query<any, void>({
      query: () => ({
        url: "/users/getReferralLink",
        method: "GET",
      }),
      transformResponse(response: string, meta, arg) {
        return response['link'];
      },
    }),
    getReferralProgram: build.query<ReferralProgram | null, void>({
      query: () => ({url: "/users/getReferralProgram"}),
      providesTags: ['ReferralProgramExpirationDate'],
    }),

    acknowledgeNotifications: build.mutation<void, any>({
      query: (notificationIds) => ({
        url: `/users/clearNotifications`,
        method: "POST",
        body: {notificationIds},
      }),
      invalidatesTags: ['UserNotifications'],
    }),
    getValidReferrals: build.query<string[], void>({
      query: () => ({
        url: "/users/getValidReferrals",
        method: "GET",
      }),
      providesTags: (result, error, id) => [{type: 'UserReferredUsers', id: 'LIST'}],
    }),
    getReferredFriends: build.query<PlayerFriend[], void>({
      query: () => ({
        url: "/users/getReferredFriends",
        method: "GET",
      }),
      providesTags: (result, error, id) => [{type: 'UserReferredUsers', id: 'LIST'}],
    }),

    getReferralsDatas: build.query<ReferralsDatas, void>({
      query: () => ({
        url: "/users/getReferrals",
        method: "GET"
      }),
      providesTags: ['UserReferralsDatas'],
    }),

    claimReferralReward: build.mutation<void, any>({
      query: (body) => ({
        url: "/users/claimReferralReward",
        method: "POST",
        body,
      }),
      invalidatesTags: ['UserReferralsDatas', 'User', 'UserMoney'],
    }),

    claimReferralRewardWithOptimism: build.mutation<void, any>({
      query: (body) => ({
        url: "/users/claimReferralReward",
        method: 'POST',
        body,
      }),
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        const manualUserUpdate = dispatch(usersApi.util.updateQueryData('getUser', undefined, (user) => {
          user.gemsAmount = user.gemsAmount + body.amount;
        }))
        try {
          await queryFulfilled;
          dispatch(stopAnimation());
        } catch {
          manualUserUpdate.undo();
          dispatch(stopAnimation());
        }
      },
      invalidatesTags: ['UserReferralsDatas', 'User', 'UserMoney'],
    }),

    updatePushConfig: build.mutation<void, any>({
      query: (body) => ({
        url: "/users/updatePushConfig",
        method: "POST",
        body,
      }),
      invalidatesTags: ['User'],
    }),
    getUserLivePerformance: build.query<number, void>({
      query: () => ({
        url: "/users/getUserLivePerformance",
        method: "GET",
      }),
      providesTags: ['UserLiveOverallPerformance'],
    }),

    uploadProfilePicture: build.mutation<void, any>({
      query: (body) => ({
        url: "/users/uploadProfilePicture",
        method: "POST",
        body,
      }),
      invalidatesTags: ['User'],
    }),
    uploadEndOfWeekSnapPicture: build.mutation<void, any>({
      query: (body) => ({
        url: "/users/uploadEndOfWeekSnapPicture",
        method: "POST",
        body,
      }),
      invalidatesTags: ['User'],
    }),
    getDailyQuests: build.query<DailyQuest[], void>({
      query: () => ({
        url: "/users/getDailyQuests",
        method: "GET",
      }),
      providesTags: ['DailyQuests'],
    }),
    getDailyGift: build.query<DailyQuest, void>({
      query: () => ({
        url: "/users/getDailyGift",
        method: "GET",
      }),
      providesTags: ['DailyGift'],
    }),
    claimDailyQuest: build.mutation<void, any>({
      query: (body) => ({
        url: "/users/claimDailyQuests",
        method: "POST",
        body,
      }),
      invalidatesTags: ['DailyQuests', 'DailyGift', 'User', 'UserMoney'],
    }),
    claimDailyQuestWithOptimism: build.mutation<void, any>({
      query: (body) => ({
        url: "/users/claimDailyQuests",
        method: 'POST',
        body,
      }),
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        const manualUserUpdate = dispatch(usersApi.util.updateQueryData('getUser', undefined, (user) => {
          user.gemsAmount = user.gemsAmount + body.amount;
        }))
        try {
          await queryFulfilled;
          dispatch(stopAnimation());
        } catch {
          manualUserUpdate.undo();
          dispatch(stopAnimation());
        }
      },
      invalidatesTags: ['DailyQuests', 'DailyGift', 'User', 'UserMoney'],
    }),
    resendVerificationEmail: build.mutation<void, void>({
      query: (body) => ({
        url: "/users/resendVerificationEmail",
        method: "POST",
        body,
      }),
      // invalidatesTags: ['DailyQuests', 'User', 'UserMoney'],
    }),

    getInventory: build.query<Array<InventoryItem>, {family?:string,subfamily?:string,code?:string }>({
      query: (params) => ({
        url: "/users/inventory",
        method: "GET",
        params
      }),
      providesTags: ['Inventory'],
    }),
  }),
});

export const {
  useGetInventoryQuery,
  useResendVerificationEmailMutation,
  useCreateUserMutation,
  useGetUserQuery,
  useLogoutUserMutation,
  useLoginMutation,
  useUpdateUserInfosMutation,
  useGetXpQuery,
  useGetPlayersListQuery,
  useGetInfinitePlayersListQuery,
  useGetPlayerQuery,
  useGenerateCityLinkMutation,
  useGetUserCityLinkQuery,
  useDeleteCityLinksMutation,
  useLikeMutation,
  useDislikeMutation,
  useGetLikesQuery,
  useDoILikeQuery,
  useGetUserCryptosQuery,
  useGetUserPublicCryptosQuery,
  useUpdateOnBoardingUserMutation,
  useGetReferralLinkQuery,
  useGetReferralProgramQuery,
  useAcknowledgeNotificationsMutation,
  useGetValidReferralsQuery,
  useGetReferredFriendsQuery,
  useGetReferralsDatasQuery,
  useClaimReferralRewardMutation,
  useClaimReferralRewardWithOptimismMutation,
  useUpdatePushConfigMutation,
  useGetUserLivePerformanceQuery,
  useUpdatePseudoMutation,
  useUploadEndOfWeekSnapPictureMutation,
  useGetDailyQuestsQuery,
  useGetDailyGiftQuery,
  useClaimDailyQuestMutation,
  useClaimDailyQuestWithOptimismMutation,
  useGetUserNotificationsQuery,
} = usersApi;