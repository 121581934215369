import React, {useEffect, useMemo, useRef, useState} from "react";
import {useGetPlayersListQuery, useGetUserLivePerformanceQuery, useGetUserQuery} from "../../apis/users.api";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  PaginationState,
  Row,
  useReactTable
} from "@tanstack/react-table";
import {PlayerItem} from "../../models/User";
import VerifiedIcon from "../svg/VerifiedIcon";
import {classNames, getDaysLeftLabel, hasElements} from "../../utils/Helper";
import {ListLoaderComponent} from "../ui/list-loader.component";
import {QuantityCoinComponent} from "../ui/quantity-coin.component";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useCycle} from "../../hooks/useCycle";
import {useGameCycleRemainingDays} from "../../hooks/useGameCycleRemainingDays";
import {PodiumComponent} from "./podium.component";
import {GainComponent} from "../ui/gain.component";
import {useGetGeneralCollectibleRewardRankQuery} from "../../apis/game.api";
import {CollectibleRewardComponent} from "./collectible-reward.component";
import {PlayerActionDrawerComponent} from "../tournament/player-action-drawer.component";
import {useLocation} from "react-router";

const columnHelper = createColumnHelper<PlayerItem>();

export type Props = {
  week: 'current' | 'previous'
}

const PAGE_SIZE = 100;
const LeaderboardComponent: React.FC<Props> = ({week}) => {
  const nav = useNavigate();
  const [empty, setEmpty] = useState<boolean>(false);
  const {data: user} = useGetUserQuery();
  const ldRef = useRef<HTMLDivElement>(null);
  const {data: collectibleRewardRank} = useGetGeneralCollectibleRewardRankQuery();
  const cycle = useCycle(week);
  const remainingDays = useGameCycleRemainingDays();
  let [searchParams, setSearchParams] = useSearchParams();
  const [spiedPseudo, setSpiedPseudo] = useState<string | null>(null);
  const {state} = useLocation();

  useEffect(() => {
    if (state && state.openActionDrawer === true && user) {
      setSpiedPseudo(user.pseudo || '');
    }
  }, [state, user])

  //update url on pagination button click
  const [{pageIndex, pageSize}, setPagination] =
    React.useState<PaginationState>({
      pageIndex: Number(searchParams.get('pageIndex')) || 0,
      pageSize: Number(searchParams.get('pageSize')) || PAGE_SIZE,
    });

  useEffect(() => {
    // console.log(`setSearchParams ${JSON.stringify({pageIndex: String(pageIndex), pageSize: String(pageSize)})}`);
    setSearchParams({pageIndex: String(pageIndex), pageSize: String(pageSize)}, {preventScrollReset: true});
  }, [pageIndex, pageSize])

  const {
    data: overallPerf,
    isLoading: overallPerfLoading
  } = useGetUserLivePerformanceQuery(undefined, {refetchOnFocus: true});

  const {data: playersData, isLoading: arePlayersLoading} = useGetPlayersListQuery({
    week,
    pageIndex: Number(searchParams.get('pageIndex') || 0),
    pageSize: Number(searchParams.get('pageSize') || PAGE_SIZE)
  }, {refetchOnFocus: true});

  const {data: firstPlayersData, isLoading: areFirstPlayersLoading} = useGetPlayersListQuery({
    week,
    pageIndex: 0,
    pageSize: 3
  }, {refetchOnFocus: true});

  const defaultData = React.useMemo(() => [], [])

  const pagination = React.useMemo(
    () => ({
      pageIndex: Number(searchParams.get('pageIndex') || 0),
      pageSize: Number(searchParams.get('pageSize') || PAGE_SIZE),
    }),
    [searchParams]
  )

  const checkFirst = (pseudo: string) => {
    return firstPlayersData && firstPlayersData.players.find(f => f.pseudo === pseudo);
  }

  useEffect(() => {
    setEmpty(false);
    if (!areFirstPlayersLoading && (!firstPlayersData || !hasElements(firstPlayersData.players))) {
      setEmpty(true);
    }
  }, [firstPlayersData, areFirstPlayersLoading, week]);


  const columns = useMemo(() => {
    const columns = [
      columnHelper.accessor(row => row.rank, {
        id: 'rank',
        cell: info => {
          const rank = info.getValue();
          let className = "w-6 h-6 p-2 md:w-8 md:h-8 md:p-4 rounded-full flex-col justify-center items-center gap-4 inline-flex mr-2 md:mr-4";

          return (
            <span className={className}>
          <span className="text-inherit font-bold">{rank}</span>
        </span>
          );
        },
        header: () => <span className={"font-medium text-left w-full flex mr-4"}>#</span>,
        footer: info => info.column.id,
      }),

      // Pseudo column
      columnHelper.accessor(row => ({
        avatarUrl: row.avatarUrl,
        pseudo: row.pseudo,
        verified: row.verified,
        discordRole: row.discordRole,
        rank: row.rank,
      }), {
        id: 'pseudo',
        cell: info => (
          <div className={"flex flex-row w-full justify-start items-center gap-4"}>
        <span className={"relative flex items-center gap-x-0.5"}>
        <div
          className="w-8 h-8 p-0.5 bg-white rounded shadow border border-neutral-200 justify-start items-start gap-4 inline-flex">
          <img src={info.row.original.avatarUrl || `${process.env.REACT_APP_STATIC_ASSETS}/favicon.svg`}
               className="w-full h-full rounded-sm object-cover"/>
        </div>

          {collectibleRewardRank && Number(info.row.original.rank) <= collectibleRewardRank &&
            <CollectibleRewardComponent widthCss={"w-4"}/>
          }

        </span>

            <div className="flex flex-col gap-0">
          <span className={"text-xs font-bold items-center flex w-full flex-row gap-4"}>
            <span className="flex w-full max-w-[160px] break-all overflow-hidden">{info.row.original.pseudo}</span>
            <div className="w-4 h-4">{info.row.original.verified && <VerifiedIcon/>}</div>
          </span>
            </div>
          </div>
        ),
        header: () => <span className={"font-medium text-left w-full flex"}>User</span>,
        footer: info => info.column.id,
      }),

      // Discord Role column
      columnHelper.accessor('discordRole', {
        cell: info => (
          info.getValue() && (
            <div className="hidden justify-end md:flex"> {/* Hide on desktop */}
            </div>
          )
        ),
        header: () => (
          <span className="hidden md:flex w-full font-medium justify-end"> {/* Hide on mobile */}
            Discord Role
      </span>
        ),
        footer: info => info.column.id,
      }),


      columnHelper.accessor(row => row.score, {
        id: 'score',
        cell: info => <span className="flex w-full justify-end">
      <span className={"mx-1"}><QuantityCoinComponent size={12} quantity={info.getValue()}/></span>
          {/*<div className="glass-light rounded-full inline-flex px-1.5 py-0.5">*/}
          {/*<ExperienceBar owner={info.row.original}/>*/}
          {/*</div>*/}
    </span>,
        header: () => <span className={"font-medium w-full flex justify-end"}>Gain</span>,
        footer: info => info.column.id,
      }),
    ]
    return columns;
  }, [collectibleRewardRank]);


  const table = useReactTable({
    data: playersData ? playersData.players : defaultData,
    columns,
    state: {
      pagination,
    },
    pageCount: (playersData?.totalPlayers) ? Math.ceil((playersData?.totalPlayers) / pageSize) : -1,
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: (data) => {
      setPagination(data);
    },
    manualPagination: true,
  });

  return (
    <div ref={ldRef} className="">
      {arePlayersLoading &&
        <div className={"w-screen flex justify-center mt-7"}>
          <div className={"max-w-md"}>
            <ListLoaderComponent/>
          </div>
        </div>
      }

      <div
        className="sticky top-[148px] md:top-[180px] flex w-screen flex-col gap-3 p-3 pb-16 items-center">

        <PodiumComponent firstPlayersData={firstPlayersData} navigation={true}
                         collectibleRewardRank={collectibleRewardRank} onClick={(pseudo: string) => {
          setSpiedPseudo(pseudo);
        }}/>

        <div className="w-full max-w-md px-4 justify-between items-center inline-flex">
          <div className="grow shrink basis-0 text-white text-opacity-50 text-xs font-bold">
            {`${playersData ? playersData.totalPlayers : 0} Players`}

          </div>
          <div className="grow shrink basis-0 text-center text-white text-sm font-bold">{`Cycle ${cycle}`}</div>
          <div
            className="grow shrink basis-0 text-right text-white text-opacity-50 text-xs font-bold">
            {week === 'current' ? getDaysLeftLabel(remainingDays) : 'Finished'}
          </div>
        </div>
      </div>

      {(!empty && !arePlayersLoading) &&
        <>
          <div
            className="sticky w-screen min-h-screen bg-white p-2 rounded-tl-2xl rounded-tr-2xl z-20 pb-16 mt-8 flex flex-col items-center">

            <table className={"w-full max-w-md pb-32"}>
              <tbody>
              {table.getRowModel().rows.map((row: Row<PlayerItem>) => (
                <tr key={row.id}
                    onClick={() => {
                      setSpiedPseudo((row.getValue('pseudo') as any).pseudo);
                    }}
                    className={classNames(checkFirst(row.original.pseudo) ? 'hidden' : '', ((user && row.original.pseudo === user.pseudo) ? ' text-lg font-medium' : '') + ' cursor-pointer w-full border-b-[1px] border-grey-100')}>
                  {row.getVisibleCells().map((cell, index, array) => (
                    <td
                      className={`max-w-[135px] overflow-hidden overflow-ellipsis text-xxs xl:text-sm py-2 ${index === 0 ? ' md:pl-8 w-8' : ''} ${index === array.length - 1 ? 'pr-0 md:pr-8' : ''}`}
                      key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))}
              </tbody>
            </table>

            {/*Pagination Buttons*/}
            <div className="max-w-md flex items-center justify-center gap-8 my-4 w-full">
              <button
                className="orange-button text-sm"
                onClick={() => table.setPageIndex(0)}
                disabled={!table.getCanPreviousPage()}
              >
                {'<<'}
              </button>
              <button
                className="orange-button text-sm"
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
              >
                {'<'}
              </button>
              <button
                className="orange-button text-sm"
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
              >
                {'>'}
              </button>
              <button
                className="orange-button text-sm"
                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                disabled={!table.getCanNextPage()}
              >
                {'>>'}
              </button>
            </div>
          </div>
        </>
      }

      {empty &&
        <div className={"flat-card flex flex-col justify-center items-center gap-y-4"}>
          <h2 className={"text-3xl"}>Week ranking will be available soon</h2>
          <button className={"primary"} onClick={() => {
            nav('/leaderboard/previous');
          }}>Last week Final Ranking
          </button>
        </div>
      }


      {playersData && playersData.currentPlayer &&
        <div
          className="fixed bottom-16 w-screen px-2 pt-4 pb-3 bg-gradient-to-b from-white/0 to-white flex-col justify-start items-center gap-4 inline-flex z-30">
          <div
            className="w-full max-w-md pl-2 pr-4 py-3 bg-black rounded-lg justify-start items-center gap-3 inline-flex cursor-pointer"
            onClick={() => {
              setSpiedPseudo(playersData.currentPlayer.pseudo);
            }}>
            <div className="justify-start items-center flex">
              <div className="w-8 h-8 p-4 rounded-[288px] flex-col justify-center items-center gap-4 inline-flex">
                <div
                  className="text-white text-sm font-bold font-['Satoshi']">{playersData.currentPlayer.rank}</div>
              </div>
              <div
                className="p-0.5 bg-white rounded shadow border border-neutral-200 justify-start items-start gap-4 flex">
                <img className="w-7 h-7 rounded-sm"
                     src={playersData.currentPlayer.avatarUrl || `${process.env.REACT_APP_STATIC_ASSETS}/favicon.svg`}/>
              </div>
            </div>
            <div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
              <div
                className="self-stretch text-white text-sm font-bold font-['Satoshi']">{playersData.currentPlayer.pseudo}</div>
            </div>
            <div className="flex-col justify-start items-end gap-0.5 inline-flex">
              <div className="justify-start items-center gap-0.5 inline-flex">
                <div className="w-3 h-3 relative"/>
                <div className="text-white text-sm font-bold font-['Satoshi'] leading-[17px]">
                  <QuantityCoinComponent size={14}
                                         quantity={playersData.currentPlayer.score}/>
                </div>
              </div>

              <div
                className={classNames("text-green-500 text-[10px] font-bold font-['Satoshi'] leading-[10px]", overallPerfLoading || week === 'previous' ? 'hidden' : '')}>
                <GainComponent gain={(overallPerf || 0)}/>
              </div>
            </div>
          </div>
        </div>
      }

      <PlayerActionDrawerComponent open={!!spiedPseudo} pseudo={spiedPseudo || ''}
                                   onClose={() => setSpiedPseudo(null)}/>
    </div>
  )
}

export {LeaderboardComponent};