import * as React from 'react';
import TwitterIcon from "../svg/Twitter";
import FacebookIcon from "../svg/Facebook";
import InstagramIcon from "../svg/Instagram";
import LinkedInIcon from "../svg/LinkedIn";
import DiscordIcon from "../svg/Discord";
import {TanjiLogo} from "./seein-logo.component";
import {useEffect, useRef} from "react";
import {useLocation} from "react-router";

/**
 * Use this function to make the footer component be stuck to the bottom
 * of the screen in case the page content is not high enough.
 */
export function footerSnap(footer: HTMLElement | null) {
  if (footer) {
    let topStr = footer.style.top || '0px';
    let top = 0;
    if (topStr.endsWith('px')) {
      top = parseFloat(topStr.substring(0, topStr.length - 2));
      const windowSize = window.innerHeight;
      const rect = footer.getBoundingClientRect();
      if (rect.bottom < windowSize) {
        console.log(`Top to add : ${windowSize - rect.bottom}, current top:${top}`)
        footer.style.top = `${top + (windowSize - rect.bottom)}px`;
      }
    }
  }
}

export const DiscordFollow: React.FC = () => {
  return <a href="https://discord.gg/wyW78RCQau">
    <button className="px-4 py-1.5 primary-small justify-center items-center gap-1 flex">
      <div className="text-white text-xs font-bold">JOIN US ON DISCORD</div>
      <div className="w-4 h-4"><DiscordIcon/></div>
    </button>
  </a>
}

export default function AppFooter() {
  const ref = useRef(null);
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => footerSnap(ref.current), 100);
    setTimeout(() => footerSnap(ref.current), 1000);
  }, [location]);

  return (
    <footer ref={ref} id="app-footer" className="relative flex justify-center w-full mx-auto md:px-0 pt-8">
      <div
        className="max-w-5xl w-full h-min px-8 pt-6 pb-20 md:pb-3 bg-orange bg-opacity-70 rounded-tl-2xl rounded-tr-2xl border-l border-r border-t border-white border-opacity-20 flex-col justify-center items-center gap-3 inline-flex">
        <div className="self-stretch justify-between items-center gap-3 px-4 inline-flex flex-col md:flex-row">
          <div className="w-[80px] h-10 mb-2"><TanjiLogo/></div>
          <div className="justify-end items-center gap-4 flex flex-col-reverse md:flex-row">
            <DiscordFollow/>
            <div className="justify-start items-start gap-1.5 flex">
              <a href="https://twitter.com/tanjiblocks" target="_blank" rel="noreferrer">
                <div className="w-8 h-8 rounded-full glass-light flex-col center inline-flex"><TwitterIcon/></div>
              </a>
              <a href="https://www.facebook.com/tanjiblocks/" target="_blank" rel="noreferrer">
                <div className="w-8 h-8 rounded-full glass-light flex-col center inline-flex"><FacebookIcon/></div>
              </a>
              <a href="https://www.instagram.com/tanjiblocks/" target="_blank" rel="noreferrer">
                <div className="w-8 h-8 rounded-full glass-light flex-col center inline-flex"><InstagramIcon/></div>
              </a>
              <a href="https://www.linkedin.com/company/tanjiblocks/" target="_blank" rel="noreferrer">
                <div className="w-8 h-8 rounded-full glass-light flex-col center inline-flex"><LinkedInIcon/></div>
              </a>
            </div>
          </div>
        </div>
        <div
          className="flex-col md:flex-row self-stretch pt-3 px-4 border-t border-white border-opacity-20 justify-between items-center gap-4 inline-flex">
          <div className="text-white text-center text-xs font-bold capitalize">Made with ❤️ and Passion</div>
          <div className="text-white text-center text-xs font-bold capitalize">© Copyright, 2024, SAS Sumino Blockchain
            – All rights reserved.
          </div>
        </div>
      </div>
    </footer>
  );
}