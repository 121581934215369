import React from "react";


const XIcon: React.FC<{ h?: number }> = ({h = 24}) => <svg width={h} height={h} viewBox="0 0 20 20" fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.04389 1L7.99348 9.82495L1 17H2.57395L8.69674 10.7181L13.6438 17H19L11.6594 7.67867L18.1689 1H16.5949L10.9561 6.78548L6.40011 1H1.04389ZM3.3585 2.10107H5.81916L16.685 15.8988H14.2244L3.3585 2.10107Z"
      fill="white" stroke="white" strokeWidth="0.5"/>
  </svg>
;

export {XIcon};