import React from 'react'
import BoltIcon from "../svg/BoltIcon";

const UserXpComponent: React.FC<{ xp?: number }> = ({xp}) => {
  return <div className="w-min h-[21px] px-1.5 py-0.5 bg-black bg-opacity-30 rounded border border-black border-opacity-10 justify-start items-center gap-1 inline-flex">
    <div className="justify-center items-center gap-1 inline-flex">
      <div className="w-2.5 h-2.5 justify-center items-center flex">
        <BoltIcon/>
      </div>
      <div className="justify-center items-center gap-1 flex">
        <div className="text-white text-sm font-bold leading-[17px]">{xp ? Math.floor(xp) : 0}</div>
        <div className="opacity-50 text-white text-sm font-bold leading-[17px]">XP</div>
      </div>
    </div>
  </div>
}

export {UserXpComponent};