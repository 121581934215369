import React, {useEffect, useState} from 'react';
import {tournamentApi, useGetNextTournamentsQuery, useGetYesterdayTournamentsQuery,} from "../../apis/tournaments.api";
import {Tournament} from "../../models/Tournament";
import {useSseEvent} from "../../hooks/useSseEvent";
import {useAppDispatch} from "../../app/hooks";
import {hasElements} from "../../utils/Helper";
import {TournamentListComponent} from "./tournaments-list.component";


const TournamentViewComponent: React.FC<{
  range: 'current' | 'previous',
  type: string[]
}> = ({range, type}) => {
  const dispatch = useAppDispatch();
  const {
    data: _nextTournaments,
    isLoading: areNextLoading,
  } = useGetNextTournamentsQuery({type, count: 5}, {
    skip: range !== 'current', refetchOnFocus: true,
  });

  const {
    data: _yesterdayTournaments,
    isLoading: areYesterdayLoading,isFetching:isYFetching
  } = useGetYesterdayTournamentsQuery({type}, {skip: range !== 'previous'});

  //reload data when tournament end
  useSseEvent('/sse/tournament', (data: any) => {
    //reload tournaments
    dispatch(tournamentApi.util.invalidateTags([{type: 'NextTournaments'}]));
    dispatch(tournamentApi.util.invalidateTags([{type: 'User'}]));
    dispatch(tournamentApi.util.invalidateTags([{type: 'StreakBetBonus'}]));
  });

  const [nextTournaments, setNextTournaments] = useState<Tournament[]>([]);

  useEffect(() => {
    if (range === 'previous' && _yesterdayTournaments) {
      setNextTournaments(_yesterdayTournaments);
    } else if (_nextTournaments) {
      setNextTournaments(_nextTournaments);
    }
  }, [range, _nextTournaments, _yesterdayTournaments])


  return (
    <div className={"flex flex-col w-full gap-8 max-w-md -mt-8 pb-24"}>
      {(areNextLoading || areYesterdayLoading) &&
        <div className={"w-full flex justify-center text-white"}>Loading tournaments...</div>
      }

      <>
        {nextTournaments && nextTournaments.length > 0 && (
          <div className="">
            <TournamentListComponent tournaments={nextTournaments} displayTournamentsDate={false}
                                     displayBetBottomBar={range === "current"}/>
          </div>
        )}
        {!hasElements(nextTournaments) && !areNextLoading &&
          <>
            <div
              className={"w-full flex justify-center text-white"}> {range === 'current' ? 'Today Bets not open yet!' : 'You have no Bets here, play and come later!'}</div>
          </>
        }
      </>
    </div>
  );
};

export {
  TournamentViewComponent
}