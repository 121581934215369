export function getSwitchSx() {
  return {
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: '0px',
      margin: '2px',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        '& + .MuiSwitch-track': {
          opacity: 1,
        },
      }
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      color: 'white',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      opacity: 1,
      borderRadius: 26 / 2,
      backgroundColor: 'gray',
    }
  };
}

export function getBottomAlertSx() {
  return {
    zIndex: 999999,
    maxWidth:'29rem',
    boxShadow: '0px 4px 0px 0px #E6E6E6 !important',
    width: 'calc(100vw - 20px)',
    backgroundColor: 'white',
    '& .MuiAlert-action':{
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
      minWidth:'130px',
    },
    // border: 1,:

  }
}

// border-radius: 1rem;
// border-width: 1px;
// --tw-border-opacity: 1;
// border-color: rgb(229 229 229 / var(--tw-border-opacity));
// --tw-bg-opacity: 1;
// background-color: rgb(255 255 255 / 1);
// background-color: rgb(255 255 255 / var(--tw-bg-opacity));
// padding: 0.75rem;
// padding-left: 0.75rem;
// padding-right: 0.75rem;
// padding-top: 1rem;
// padding-bottom: 0.75rem;
// box-shadow: 0px 4px 0px 0px #E6E6E6;