import {DateTime, Duration} from "luxon";

export const getDuration = (range: '1H' | '2H' | '4H' | '8H' | '1D' | '1W' | '1M' | '1Y' | 'ALL', all?: DateTime): Duration => {
  const now = DateTime.now();
  let res = null;
  if (range.match(/(\d){1}H/)) {
    const m = range.match(/(\d){1}H/);
    const hours = m ? m[1] : 0;
    res = {hours: Number(hours)}
  } else if (range === '1D') {
    res = {days: 1};
  } else if (range === '1W') {
    res = {weeks: 1};
  } else if (range === '1M') {
    res = {months: 1};
  } else if (range === '1Y') {
    res = {years: 1};
  } else if (all && range === 'ALL') {
    return now.diff(all);
  } else {
    res = {milliseconds: 0}
  }
  return Duration.fromObject(res);
}

/**
 * Compare two dates.
 * Return -1 if date1 is before date2
 * Return 1 if date1 is after date2
 * Return 0 if date1 is equal date2
 *
 * @param date1
 * @param date2
 */
export const compareDates = (date1: Date, date2: Date): number => {
  let res = 0;
  if (date1.getTime() < date2.getTime()) {
    res = -1;
  } else if (date1.getTime() > date2.getTime()) {
    res = 1;
  }
  return res;
}

export const isBefore = (date1: Date, date2: Date): boolean => {
  return compareDates(date1, date2) === -1;
}

export const isBeforeOrEqual = (date1: Date, date2: Date): boolean => {
  const comp = compareDates(date1, date2);
  return comp <= 0;
}

export const isAfter = (date1: Date, date2: Date): boolean => {
  return !isBeforeOrEqual(date1, date2);
}

export const isAfterOrEqual = (date1: Date, date2: Date): boolean => {
  return !isBefore(date1, date2);
}

export const isSameDayAsToday = (date: Date): boolean => {
  return DateTime.now().hasSame(DateTime.fromISO(date.toString()), 'day');
}

export const isDateBeforeToday = (date: Date): boolean => {
  return DateTime.now().startOf('day') > DateTime.fromISO(date.toString()).endOf('day');
}

export const isDateAfterToday = (date: Date): boolean => {
  return DateTime.fromISO(date.toString()).startOf('day') > DateTime.now().endOf('day');
}