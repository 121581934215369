import React, { useEffect, useState } from "react";
import { Navigate, useParams, Link } from "react-router-dom";
import Layout from "../../Layout";
import "./style.css";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import CircularProgress from "@mui/material/CircularProgress";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LockIcon from "@mui/icons-material/Lock";
import { useGetUserQuery } from "../../apis/users.api";
import { useGetAchievementsByTypeQuery } from "../../apis/achievements.api";
import { achievementsTranslations }from "../../app/translations";
import { getAllAchievementByTypeDto } from "../../dto/achievement/get-all-achievement-by-type-response.dto";


const AchievementsListPage: React.FC = () => {
  const { name } = useParams<{ name: string }>();
  if (!name) {
    return <Navigate to="/achievements" />;
  }

  const { data: user } = useGetUserQuery();
  const { data: achievementsData, isError } =
    useGetAchievementsByTypeQuery(name);

  const [isValidSkill, setIsValidSkill] = useState<boolean>(false);
  const [achievements, setAchievements] = useState<getAllAchievementByTypeDto | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [width, setWidth] = useState<string>();
  const [flippedIndex, setFlippedIndex] = useState<number | null>(null);

  const [selectedFilter, setSelectedFilter] = useState<string>("ALLS");

  function handleFilterClick(level: string) {
    setSelectedFilter(level);
  }

  useEffect(() => {
    if (achievementsData) {
      setIsValidSkill(true);
      setAchievements(achievementsData);
      setLoading(false);

       // Calculate the completion ratio of the achievement
      const ratio = achievementsData.scoopUser / achievementsData.scoopMax;
      const widthResult = `${ratio * 100}%`;
      setWidth(widthResult);
    }
  }, [achievementsData]);


  if (isError) {
    return <Navigate to="/achievements" />;
  }


  if (!isValidSkill && !loading) {
    return <Navigate to="/achievements" />;
  }

  const handleFlip = (index: number) => {
    if (flippedIndex === index) {
      setFlippedIndex(null);
    } else {
      setFlippedIndex(index);
    }
  };

  return (
    <Layout>
      <div className={"flex flex-col w-full gap-x-4 gap-y-4 max-w-3xl mx-auto"}>
        {/* Header Profil user */}
        <div className="HeaderProfil flex flex-col self-center items-center ">
          {user && user.avatarUrl && <img src={user.avatarUrl} alt="" className={"lg:w-40 lg:h-40 w-20 h-20 rounded-full"}/>}
          {!(user && user.avatarUrl) && (
            <AccountCircleIcon
              style={{ width: "128px", height: "128px" }}
              fontSize={"inherit"}
            />
          )}

          {user && <p>@{user.pseudo}</p>}
        </div>
        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress color="primary" />
          </div>
        )}

        {!loading && achievements && (
          <>
            <div className="LevelNav">
              <Link to={`/achievements`}>
                <KeyboardArrowLeftIcon />
              </Link>
              {achievements && (
                <>
                  {["0", "1", "2", "3"].map((level) => {
                    const levelName =
                      level === "0"
                        ? "EASY"
                        : level === "1"
                        ? "PERFORMANCE"
                        : level === "2"
                        ? "ELITE"
                        : "META";
                    const hasAchievements = achievements.listAchievement.some(
                      (achievement) => achievement.level === parseInt(level)
                    );
                    return (
                      hasAchievements && (
                        <span
                          key={level}
                          onClick={() => handleFilterClick(level)}
                          className={`inline-flex items-center rounded-md bg-${
                            level === "0"
                              ? "green"
                              : level === "1"
                              ? "yellow"
                              : level === "2"
                              ? "blue"
                              : "red"
                          }-400 px-2 py-1 text-xs font-medium text-${
                            level === "0"
                              ? "green"
                              : level === "1"
                              ? "yellow"
                              : level === "2"
                              ? "blue"
                              : "red"
                          }-400  ring-inset ring-${
                            level === "0"
                              ? "green"
                              : level === "1"
                              ? "yellow"
                              : level === "2"
                              ? "blue"
                              : "red"
                          }-400/20 cursor-pointer`}
                          style={
                            selectedFilter === level
                              ? {
                                  backgroundColor:
                                    level === "0"
                                      ? "rgba(111, 220, 117, 0.3)"
                                      : level === "1"
                                      ? "rgba(252, 211, 77, 0.3)"
                                      : level === "2"
                                      ? "rgba(129, 212, 250, 0.3)"
                                      : "rgba(252, 165, 165, 0.3)",
                                  color: "white",
                                }
                              : {}
                          }
                        >
                          {levelName}
                        </span>
                      )
                    );
                  })}
                  <span
                    onClick={() => handleFilterClick("ALLS")}
                    className={`inline-flex items-center rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium text-white-500 ring-1 ring-inset ring-gray-400/20 cursor-pointer ${
                      selectedFilter === "ALLS"
                        ? "bg-gray-400/30"
                        : "hover:bg-gray-400/20"
                    }`}
                  >
                    ALLS
                  </span>
                </>
              )}
            </div>

            <div className="SectionAchievement">
              <header>
                {/* <span /> */}

                <div>
                  <div className="SectionAchievementHeaderLeft">
                  <p>
                    {/* Translate the achievement name using the translations object */}
                    {achievementsTranslations.EN.ACHIEVEMENTS.hasOwnProperty(achievements.name)
                      ? achievementsTranslations.EN.ACHIEVEMENTS[achievements.name as keyof typeof achievementsTranslations.EN.ACHIEVEMENTS]
                      : achievements.name}
                  </p>
                  </div>

                  <div className="SectionAchievementHeaderRight">
                    <p>
                      <span>
                        <span>{achievements.scoopUser}</span> /{" "}
                        {achievements.scoopMax}
                      </span>
                    </p>
                    <span>
                      <span style={{ width: width }} />
                    </span>
                  </div>
                </div>

                {/* <span /> */}
              </header>
            </div>

            <div className="SectionAchievementList">
              {achievements && (
                <>
                  {achievements.listAchievement
                    .filter((achievement) => {
                      if (selectedFilter === "ALLS") {
                        return true;
                      } else {
                        return achievement.level === parseInt(selectedFilter);
                      }
                    })
                    .map((list, index) => (
                      <div
                        key={index}
                        className={`achievement-item ${
                          flippedIndex === index ? "flipped" : ""
                        }`}
                        onClick={() => handleFlip(index)}
                      >
                        <div className="frontside">
                          {!list.lock ? (
                            <>
                              {list.image && (
                                <img
                                  src={list.image}
                                  style={{
                                    filter: "blur(5px)",
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                  }}
                                />
                              )}
                              <LockIcon className={"text-white"} />
                              <p
                                style={{
                                  position: "absolute",
                                  bottom: "5%",
                                  padding: "0.5rem",
                                  background: "white",
                                  width: "80%",
                                  borderRadius: "5px",
                                  textAlign: "center",
                                  fontSize: "x-small",
                                }}
                              >
                                {list.label}
                              </p>
                            </>
                          ) : (
                            <>
                              {list.image && (
                                <img
                                  src={list.image}
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                  }}
                                />
                              )}
                            </>
                          )}
                        </div>

                        <div className="backside">
                          <div className="achievement-description">
                            <p>{list.description}</p>
                            <div className="achievement-level">
                              {list.level === 0 && (
                                <span className="inline-flex items-center rounded-md bg-green-500/10 px-2 py-1 text-xs font-medium text-green-400 ring-1 ring-inset ring-green-500/20">
                                  EASY
                                </span>
                              )}
                              {list.level === 1 && (
                                <span className="inline-flex items-center rounded-md bg-yellow-400/10 px-2 py-1 text-xs font-medium text-yellow-400 ring-1 ring-inset ring-yellow-400/20">
                                  PERFORMANCE
                                </span>
                              )}
                              {list.level === 2 && (
                                <span className="inline-flex items-center rounded-md bg-blue-400/10 px-2 py-1 text-xs font-medium text-blue-400 ring-1 ring-inset ring-blue-400/20">
                                  ELITE
                                </span>
                              )}
                              {list.level === 3 && (
                                <span className="inline-flex items-center rounded-md bg-red-400/10 px-2 py-1 text-xs font-medium text-red-500 ring-1 ring-inset ring-red-400/20">
                                  META
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </>
              )}
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export { AchievementsListPage };
