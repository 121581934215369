import {emptySplitApi} from "./empty.api";

export const devApi = emptySplitApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({

    regenerateEndOfWeekNotification: build.mutation<any, void>({
      query: (req) => ({
        url: '/dev/regenerateEndOfWeekNotification',
        method: 'POST',
        body: {},
      }),
    }),
    regenerateFreeClaimNotification: build.mutation<any, void>({
      query: (req) => ({
        url: '/dev/regenerateFreeClaimNotification',
        method: 'POST',
        body: {},
      }),
    }),

    creditGems: build.mutation<any, void>({
      query: (req) => ({
        url: '/dev/gems/credit',
        method: 'POST',
        body: {},
      }), invalidatesTags: (a, b, o) =>
        ['User'],
    }),
    consumeGems: build.mutation<any, void>({
      query: (req) => ({
        url: '/dev/gems/consume',
        method: 'POST',
        body: {},
      }),
      invalidatesTags: (a, b, o) => ['User'],
    }),
    resetOnboarding: build.mutation<any, void>({
      query: (req) => ({
        url: '/dev/resetOnboarding',
        method: 'POST',
        body: {},
      }),
      invalidatesTags: (a, b, o) => ['User'],
    }),

    isAdminActionAuthorized: build.query<boolean, void>({
      query: () => ({url: "/dev/isAdminActionAuthorized"}),
      providesTags: ['isAdminAuth'],
    }),
    generateClaimRemainder: build.mutation<any, void>({
      query: (req) => ({
        url: '/dev/notifications/generateClaimRemainder',
        method: 'POST',
        body: {},
      }),
      invalidatesTags: (a, b, o) => ['User'],
    }),
    resetBTCCollectibleToZero: build.mutation<any, void>({
      query: (req) => ({
        url: '/dev/resetBtcCollectibleToStep1',
        method: 'POST',
        body: {},
      }),
      invalidatesTags: (a, b, o) => ['InfiniteCollectibleList', 'Collectibles', 'Collectible'],
    }),

    resetETHCollectibleToStep1: build.mutation<any, void>({
      query: (req) => ({
        url: '/dev/resetEthCollectibleToStep2',
        method: 'POST',
        body: {},
      }),
      invalidatesTags: (a, b, o) => ['InfiniteCollectibleList', 'Collectibles', 'Collectible'],
    }),
    randomizeMyCollectible: build.mutation<any, void>({
      query: (req) => ({
        url: '/dev/randomizeMyCollectible',
        method: 'POST',
        body: {},
      }),
      invalidatesTags: (a, b, o) => ['InfiniteCollectibleList', 'Collectibles', 'Collectible'],
    }),
    randomizeDailyCollectibles: build.mutation<any, void>({
      query: (req) => ({
        url: '/dev/randomizeDailyCollectibles',
        method: 'POST',
        body: {},
      }),
      invalidatesTags: (a, b, o) => ['InfiniteCollectibleList', 'Collectibles', 'Collectible'],
    }),
    rewardRankingWithCollectible: build.mutation<any, void>({
      query: (req) => ({
        url: '/dev/rewardRankingWithCollectible',
        method: 'POST',
        body: {},
      }),
      invalidatesTags: (a, b, o) => ['InfiniteCollectibleList', 'Collectibles', 'Collectible'],
    }),
    generateGoodTrades: build.mutation<any, void>({
      query: (req) => ({
        url: '/dev/generateGoodTrades',
        method: 'POST',
        body: {},
      }),
      invalidatesTags: (a, b, o) => ['User'],
    }),
    randomizeMyReferredFriends: build.mutation<any, void>({
      query: (req) => ({
        url: '/dev/randomizeMyReferredFriends',
        method: 'POST',
        body: {},
      }),
      invalidatesTags: (a, b, o) => ['UserReferredUsers', 'UserReferralsDatas'],
    }),

    // getClaimCohort
    getClaimCohort: build.query<string[], any>({
      query: () => ({url: "/dev/getClaimCohort"}),
      providesTags: [],
    }),
    // getPurchaseCohort
    getPurchaseCohort: build.query<string[], any>({
      query: () => ({url: "/dev/getPurchaseCohort"}),
      providesTags: [],
    }),
    sendNewFriendNotif: build.query<any, void>({
      query: () => ({url: "/dev/new-referred-friend"}),
      providesTags: [],
    }),
    randomizeQuest: build.mutation<any, void>({
      query: (req) => ({
        url: '/dev/randomizeQuest',
        method: 'POST',
        body: {},
      }),
      invalidatesTags: (a, b, o) => ['DailyQuests', 'User'],
    }),

    resetDailyGift: build.mutation<any, void >({
      query: (req) => ({
        url: '/dev/resetDailyGift',
        method: 'GET'
      }),
      invalidatesTags: (a, b, o) => ['DailyGift', 'User'],
    }),

    // getClaimCohort
    getQuantityOfGemsWorth: build.query<number, string | undefined>({
      query: (dollarAmount) => ({
        url: "/dev/getQuantityOfGemsWorth",
        params: {dollarAmount},
        method: "GET",
      }),
    }),
    generateFiatPromoNotification: build.mutation<any, { productCode: string }>({
      query: (req) => ({
        url: '/dev/generateFiatPromoNotification',
        method: 'POST',
        body: req,
      }),
    }),

    verifyMyEmail: build.mutation<any, void>({
      query: (req) => ({
        url: '/dev/verifyMyEmail',
        method: 'POST',
        body: req,
      }),
    }),

    unVerifyMyEmail: build.mutation<any, void>({
      query: (req) => ({
        url: '/dev/unVerifyMyEmail',
        method: 'POST',
        body: req,
      }),
    }),
  }),
});

export const {
  useVerifyMyEmailMutation,
  useUnVerifyMyEmailMutation,
  useGenerateFiatPromoNotificationMutation,
  useGetQuantityOfGemsWorthQuery,
  useGetPurchaseCohortQuery,
  useGetClaimCohortQuery,
  useGenerateGoodTradesMutation,
  useResetBTCCollectibleToZeroMutation,
  useResetETHCollectibleToStep1Mutation,
  useRegenerateEndOfWeekNotificationMutation,
  useResetOnboardingMutation,
  useRegenerateFreeClaimNotificationMutation,
  useIsAdminActionAuthorizedQuery,
  useCreditGemsMutation,
  useConsumeGemsMutation,
  useGenerateClaimRemainderMutation,
  useRandomizeMyCollectibleMutation,
  useRandomizeDailyCollectiblesMutation,
  useRandomizeMyReferredFriendsMutation,
  useSendNewFriendNotifQuery,
  useLazySendNewFriendNotifQuery,
  useRewardRankingWithCollectibleMutation,
  useRandomizeQuestMutation,
  useResetDailyGiftMutation,
} = devApi;