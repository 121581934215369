import React, {useRef} from "react";
import {useGetUserQuery} from "../../apis/users.api";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  PaginationState,
  Row,
  useReactTable
} from "@tanstack/react-table";
import {PlayerItem} from "../../models/User";
import ContentLoader from "react-content-loader";
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import {useGetWeekBestROIPlayersQuery} from "../../apis/squad.api";
import axios from "axios";
import toast from "react-hot-toast";
import VerifiedIcon from "../svg/VerifiedIcon";
import {RoiBar} from "../molecules/roi-bar";

const columnHelper = createColumnHelper<PlayerItem>();
const MAX_WINNER_RANK = 9;
const FIRSTS_WINNER = 4;

export type Props = { week: string | undefined }

const getColor = (value: number): string => {
  if (value < MAX_WINNER_RANK) return 'text-turquoise';
  else return '';
}

const getRankCellValue = (value: any): JSX.Element => {
  if (value < MAX_WINNER_RANK) {
    return <><span className={""}>{value}</span><span className={getColor(value)}><MilitaryTechIcon/></span></>;
  } else {
    return <>{value}</>
  }
}

const getQualifiedValue = (value: any): JSX.Element => {
  if (value < FIRSTS_WINNER) {
    return <><span>Crypto Trader's Challenge</span></>;
  } else if (value < MAX_WINNER_RANK) {
    return <><span>Race To Riches</span></>;
  } else {
    return <></>;
  }
}

const RoiRankingList: React.FC<Props> = ({week = 'current'}) => {
  const {data: user} = useGetUserQuery();
  const ldRef = useRef<HTMLDivElement>(null);

  const columns = [
    columnHelper.accessor(row => row.rank, {
      id: 'rank',
      cell: info => <div
        className={"w-6 md:w-10 gap-0 md:gap-1 flex justify-start items-center text-white text-sm font-bold mr-2"}>{getRankCellValue(info.getValue())}</div>,
      header: () => <span className={"font-medium text-left w-full flex text-white/50 mr-4"}>#</span>,
      footer: info => info.column.id,
    }),
    // Pseudo column
    columnHelper.accessor(row => ({
      avatarUrl: row.avatarUrl,
      pseudo: row.pseudo,
      verified: row.verified,
      rank: row.rank // Add rank to the accessor
    }), {
      id: 'pseudo',
      cell: info => (
        <div className={"flex flex-row w-full justify-start items-center gap-4"}>
          <img src={info.row.original.avatarUrl || `${process.env.REACT_APP_STATIC_ASSETS}/favicon.svg`}
               className="h-8 w-8 md:h-12 md:w-12 bg-white rounded-full"/>
          <div className="flex flex-col gap-0">
            <span className={"text-white text-xs font-bold items-center flex w-full flex-row gap-1 md:gap-4"}>
              <span className="flex w-min max-w-[160px] overflow-hidden">{info.row.original.pseudo}</span>
              <div className="w-4 h-4">{info.row.original.verified && <VerifiedIcon/>}</div>
            </span>
            <div className="flex justify-start md:hidden"> {/* Hide on desktop */}
              {getQualifiedValue(info.row.original.rank)} {/* Use getQualifiedValue function here */}
            </div>
          </div>
        </div>
      ),
      header: () => <span className={"font-medium text-left w-full flex text-white/50"}>User</span>,
      footer: info => info.column.id,
    }),
    columnHelper.accessor(row => row.rank, {
      id: 'qualified',
      cell: info => <div className={"hidden md:flex w-full justify-end"}>{getQualifiedValue(info.getValue())}</div>,
      header: () => <span
        className={"hidden md:flex w-full font-medium justify-end text-white/50"}>Qualification</span>,
      footer: info => info.column.id,
    }),
    columnHelper.accessor('score', {
      cell: info => {
        const v: number = info.getValue() || 0;
        return <div className="pl-4 flex w-full justify-end">
          <div className="glass-light rounded-full inline-flex px-1.5 py-0.5"><RoiBar score={v.toFixed(2)}/></div>
        </div>
      },
      header: () => <span className={"flex font-medium justify-end text-white/50"}>ROI</span>,
      footer: info => info.column.id,
    }),
  ]

  const [{pageIndex, pageSize}, setPagination] =
    React.useState<PaginationState>({
      pageIndex: 0,
      pageSize: 50,
    })

  const {data: playersData, isLoading: arePlayersLoading} = useGetWeekBestROIPlayersQuery({
    request: {pageIndex, pageSize}, week
  });

  const table = useReactTable({
    data: playersData ? playersData.players : [],
    columns,
    state: {
      pagination: {
        pageIndex,
        pageSize,
      },
    },
    pageCount: (playersData?.totalPlayers) ? Math.ceil((playersData?.totalPlayers) / pageSize) : -1,
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    manualPagination: true,
  })

  return (
    <div ref={ldRef} className={"text-xxs xl:text-sm w-full leaderboard-component overflow-hidden"}>
      {/*{arePlayersLoading &&*/}
      {/*  <ContentLoader animate={true} backgroundColor={'#595956'} height={1000}*/}
      {/*                 className={"mx-4 w-11/12 h-500"}>*/}
      {/*    {Array.from({ length: 50 }, (_, i) => (*/}
      {/*      <React.Fragment key={i}>*/}
      {/*        <circle cx="40" cy={30 + 40 + i * 90} r="40"/>*/}
      {/*        <rect x="100" y={30 + i * 90} rx="5" ry="5" className="w-[90%]" height="80"/>*/}
      {/*      </React.Fragment>*/}
      {/*    ))}*/}
      {/*  </ContentLoader>*/}
      {/*}*/}
      {!arePlayersLoading &&
        <table className={"w-full"}>
          <thead className="!bg-black !bg-opacity-80 !backdrop-blur-lg">
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header, index, array) => (
                <th key={header.id}
                    className={`${index === 0 ? 'pl-4 md:pl-8 w-8' : ''} ${index === array.length - 1 ? 'pr-4 md:pr-8' : ''} !border-b !border-white !border-opacity-20`}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                </th>
              ))}
            </tr>
          ))}
          </thead>
          <tbody>
          {table.getRowModel().rows.map(row => (
            <tr key={row.id}
                onClick={() => {
                  return axios.get(`/users/getUserCityLink?pseudo=${(row.getValue('pseudo') as any).pseudo}`)
                    .then(response => {
                      if (response.data && response.data._id) {
                        const event = new Event("visitingUserCity");
                        ldRef.current?.dispatchEvent(event);
                        document.location.href = document.location.origin + '/city?cityshareuid=' + response.data._id;
                      } else {
                        toast(`User ${(row.getValue('pseudo') as any).pseudo} does not share his city`);
                      }
                    })
                    .catch(error => toast.error(error));
                }}
                className={((user && row.getValue('pseudo') === user.pseudo) ? ' text-lg font-medium' : '') + ' cursor-pointer relative border-b-[1px] border-white border-opacity-20'}>
              {row.getVisibleCells().map((cell, index, array) => (
                <td
                  className={`overflow-hidden overflow-ellipsis text-xxs xl:text-sm py-5 ${index === 0 ? 'pl-4 md:pl-8 w-8' : ''} ${index === array.length - 1 ? 'pr-4 md:pr-8' : ''}`}
                  key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
          </tbody>
        </table>
      }
      <div className="flex items-center justify-center gap-2 my-4">
        <button
          className="pagination-button font-serif text-sm glass-dark"
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}
        >
          {'<<'}
        </button>
        <button
          className=" pagination-button font-serif text-sm glass-dark"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          {'<'}
        </button>
        <button
          className=" pagination-button font-serif text-sm glass-dark"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          {'>'}
        </button>
        <button
          className="pagination-button font-serif text-sm glass-dark"
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
        >
          {'>>'}
        </button>
        {arePlayersLoading ? 'Loading...' : null}
      </div>
    </div>
  )
}

export {RoiRankingList};

