import React, {useCallback, useEffect} from 'react'
import {CryptoTeammate} from "../../models/squad/CryptoTeammate";

export type Props = {
  squad: CryptoTeammate[],
  disabledCryptos?: CryptoTeammate[],
  placeholder?: JSX.Element,
  mandatoryPlaceholder?: JSX.Element,
  hideEmpty?: boolean,
  mandatorySlot?: number;
  maxSlot: number;
  onClick?: Function;
  displayValue?: boolean,
  logoSize?: number;
}

const SquadComponent: React.FC<Props> = ({
                                           squad = [],
                                           disabledCryptos = [],
                                           placeholder = <span>{"O"}</span>,
                                           mandatoryPlaceholder = <span>{"M"}</span>,
                                           hideEmpty = false,
                                           maxSlot,
                                           mandatorySlot = -1,
                                           onClick = () => {
                                           },
                                           displayValue = false,
                                           logoSize = 50
                                         }) => {

  const isEmpty = useCallback((i: number) => {
    return !(squad && squad.length >= i + 1);
  }, [squad]);

  const getSelected = useCallback((i: number) => {
    if (squad && squad.length >= i + 1) {
      const o = squad.filter(o => o.crypto.code === squad[i].crypto.code)[0];
      return <div className={"relative"}>
        <img src={o.crypto.logo} alt={o.crypto.code}
             width={logoSize} height={logoSize}
             title={o.crypto.name}/>{getDisabledLayerClass(o)}
        {displayValue && <div>{`${o.value} $`}</div>}
      </div>;
    } else if (mandatorySlot > 0 && i < mandatorySlot) return <span
      className={"w-[50px] h-[50px] flex justify-center flex-wrap items-center font-[50px] border-2 bg-gray-700 rounded-3xl"}>
    </span>
    else return <span
        className={"w-[50px] h-[50px] flex justify-center flex-wrap items-center font-[50px] border-2 bg-gray-700 rounded-3xl"}>
    </span>
  }, [squad, disabledCryptos]);

  const getDisabledLayerClass = useCallback((crypto: CryptoTeammate) => {
    if (disabledCryptos && disabledCryptos.indexOf(crypto) !== -1) {
      return <span
        className={"z-10 w-[50px] h-[50px] rounded-3xl absolute right-0 left-0 top-0 bottom-0 bg-white bg-opacity-70"}></span>
    } else return <></>;
  }, [squad, disabledCryptos])

  return (<>

      {!(isEmpty(0) && hideEmpty) &&
        <span onClick={() => onClick(squad[0])}>{getSelected(0)}</span>
      }
      {!(isEmpty(1) && hideEmpty) &&
        <span onClick={() => onClick(squad[1])}>{getSelected(1)}</span>
      }
      {!(isEmpty(2) && hideEmpty) && (maxSlot >= 3) &&
        <span onClick={() => onClick(squad[2])}>{getSelected(2)}</span>
      }
      {!(isEmpty(3) && hideEmpty) && (maxSlot >= 4) &&
        <span onClick={() => onClick(squad[3])}>{getSelected(3)}</span>
      }
      {!(isEmpty(4) && hideEmpty) && (maxSlot >= 5) &&
        <span onClick={() => onClick(squad[4])}>{getSelected(4)}</span>
      }
      {!(isEmpty(5) && hideEmpty) && (maxSlot >= 6) &&
        <span onClick={() => onClick(squad[5])}>{getSelected(5)}</span>
      }
      {!(isEmpty(6) && hideEmpty) && (maxSlot >= 7) &&
        <span onClick={() => onClick(squad[6])}>{getSelected(6)}</span>
      }
    </>
  );
}

export {SquadComponent};