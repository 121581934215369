import React, {useEffect, useState} from 'react';
import {classNames} from "../../utils/Helper";
import {CollectibleCard} from "../../dto/collectibles/collectibles-list-response.dto";
import {
  BorderLinearProgressAnimated,
  BronzeLinearProgressAnimated,
  DiamondLinearProgressAnimated,
  GoldLinearProgressAnimated,
  MasterLinearProgress,
  PlatinumLinearProgressAnimated,
  SilverLinearProgressAnimated
} from "../ui/linear-progress.component";
import {LockIcon} from "../ui/lock-icon";
import {UnlockIcon} from "../ui/unlock-icon";

const steps = ['', 'B', 'S', 'G', 'P', 'D', 'M'];
const stepsName = ['', 'bronze', 'silver', 'gold', 'platinum', 'diamond', 'master'];

export const getStepImage = (collectible: CollectibleCard) => {
  let res = null;
  if (collectible.step >= 1) {
    res = `${process.env.REACT_APP_STATIC_ASSETS}/images/${stepsName[collectible.step]}-step.png`;
  } else {
    res = `${process.env.REACT_APP_STATIC_ASSETS}/images/0-step.png`;
  }
  return res;
}

export const getStepName = (collectible: CollectibleCard) => {
  let res = '0';
  if (collectible.step >= 1) {
    res = stepsName[collectible.step]
  }
  return res;
}

const getBGColor = (collectible: CollectibleCard) => {
  let res = '';
  const colorGroup = collectible.colorGroup;
  if (colorGroup === 1 || !colorGroup) {
    res = 'from-[#FFCC88]/40  to-[#FBEB57]/40';
  } else if (colorGroup === 2) {
    res = 'from-[#ff00b5]/40  to-[#a9cc14]/40';
  } else if (colorGroup === 3) {
    res = 'from-[#a9cc14]/40  to-[#00c8ff]/40';
  } else if (colorGroup === 4) {
    res = 'from-[#00c8ff]/40  to-[#7e3de2]/40';
  } else if (colorGroup === 5) {
    res = 'from-[#7e3de2]/40 via-[#6646c7]/40 to-[#3d5599]/40';
  } else if (colorGroup === 6) {
    res = 'from-[#3d5599]/40  to-[#101e3a]/40';
  } else if (colorGroup === 7) {
    res = 'from-[#101e3a]/40  to-[#ff8240]/40';
  }
  return res
}

const CollectibleCardComponent: React.FC<{
  collectible: CollectibleCard,
  onUpgrade?: Function,
  onClick?: Function,
  //if the collectible reached max of the step, if this boolean is true will show the next step appearance.
  previewNextStep?: boolean,
  animateProgressBar?: boolean,
  shineEffect?: boolean,
  shadows?: boolean,
  styledBackgroundImage?: boolean,
}> = ({
        collectible,
        onUpgrade,
        onClick = () => {
        },
        previewNextStep = false,
        animateProgressBar = false,
        shineEffect = false,
        shadows = false,
        styledBackgroundImage = true
      }) => {

  const [_collectible, _setCollectible] = useState<CollectibleCard>(collectible);
  const [nextStepPreviewMode, setNextStepPreviewMode] = useState(false);

  useEffect(() => {
    if (previewNextStep && collectible && collectible.upgrade) {
      setNextStepPreviewMode(true);
      _setCollectible({...collectible, step: collectible.step + 1, level: collectible.level + 1, unlocked: true})
    }
    else if (collectible) {
      _setCollectible({...collectible});
    }
  }, [collectible, previewNextStep]);

  let level = _collectible.level || 0;

  const getBorderColor = (collectible: CollectibleCard) => {
    let res = '';
    if (collectible.step === 1) {
      res = 'metal-bronze'
    } else if (collectible.step === 2) {
      res = 'metal-silver'
    } else if (collectible.step === 3) {
      res = 'metal-gold'
    } else if (collectible.step === 4) {
      res = 'metal-platinum'
    } else if (collectible.step === 5) {
      res = 'metal-diamond'
    } else if (collectible.step === 6) {
      res = 'metal-master'
    }
    return collectible.unlocked !== false ? res : '';
  }


  const getLinearProgress = (collectible: CollectibleCard) => {
    let res = <BorderLinearProgressAnimated value={getProgressValue(collectible)} animated={animateProgressBar}/>;
    if (collectible.step === 1) {
      res = <BronzeLinearProgressAnimated
        value={getProgressValue(collectible)} animated={animateProgressBar}/>
    } else if (collectible.step === 2) {
      res = <SilverLinearProgressAnimated
        value={getProgressValue(collectible)} animated={animateProgressBar}/>
    } else if (collectible.step === 3) {
      res = <GoldLinearProgressAnimated
        value={getProgressValue(collectible)} animated={animateProgressBar}/>
    } else if (collectible.step === 4) {
      res = <PlatinumLinearProgressAnimated
        value={getProgressValue(collectible)} animated={animateProgressBar}/>
    } else if (collectible.step === 5) {
      res = <DiamondLinearProgressAnimated
        value={getProgressValue(collectible)} animated={animateProgressBar}/>
    } else if (collectible.step === 6) {
      res = <MasterLinearProgress variant="determinate"
                                  value={getProgressValue(collectible)}/>
    }
    return res;
  }

  const getProgressValue = (collectible: CollectibleCard) => {
    let val = 0;

    if (!nextStepPreviewMode) {
      val = Math.min(100, (Number(collectible.xp - collectible.currentLevelXp || 0) * 100) / Number((collectible.nextLevelXp - collectible.currentLevelXp) || 1));
    }

    if (val === 0 && collectible.xp > 0) {
      val = 5;//5% by default to avoid empty progress bar
    }
    return val;
  }
  return <div onClick={(e) => onClick(e)}
              className={classNames(`relative !rounded-md bg-white !w-[140px] overflow-hidden overflow-ellipsis text-xxs gap-x-4 p-2`,
                _collectible.unlocked === false && !_collectible.upgrade ? '!opacity-70' : 'cursor-pointer !text-white shadow-xl hover-up', getBorderColor(_collectible), shineEffect ? 'new-shiny' : '', shadows ? "" : "!shadow-none")}
              key={_collectible.name}>
    <div className={"bg-white !rounded-md w-full h-full p-[2px]"}>
      <div
        className={classNames(`!rounded-sm w-full h-full overflow-hidden bg-cover`, styledBackgroundImage ? 'bg-collectible-card-theme' : '')}>
        <div
          className={classNames(_collectible.unlocked === false ? "" : "!rounded-md bg-gradient-to-b from-0% via-70% to-100% " + getBGColor(_collectible), " w-full h-full !p-2 !pt-3 flex flex-col items-center gap-y-2 ")}>
          {_collectible.xp >= 0 && _collectible.upgrade && level === 0 &&
            <img className={"h-20 -left-1 -top-1 absolute z-10"}
                 src={`${process.env.REACT_APP_STATIC_ASSETS}/images/new-banner.png`}
            />
          }

          <div
            className={classNames("absolute left-3.5 top-2.5 font-['BIGSHOULDER'] font-light uppercase", _collectible.code.length > 5 ? 'text-sm' : 'text-base')}>{_collectible.code}</div>

          <div
            className={"absolute right-3.5 top-2.5 text-2xl text-white font-['BIGSHOULDER'] font-normal"}>{steps[_collectible.step]}</div>

          <div className={"flex flex-col justify-start items-center"}>
          <span className={"relative"}>
            <div
              className={classNames("w-12 h-12 p-0.5 bg-white rounded-full overflow-hidden shadow border border-neutral-200 justify-start items-start gap-4 inline-flex")}>
              <img src={_collectible.logo || `${process.env.REACT_APP_STATIC_ASSETS}/favicon.svg`}
                   className="w-full h-full rounded-full object-cover"/>
            </div>
          </span>

            <div className={classNames("my-1 !text-inherit")}>{_collectible.name}</div>
          </div>


          <div className={"relative w-full !text-inherit flex flex-col items-center"}>
            {!(_collectible.xp >= 0 && _collectible.upgrade && onUpgrade) &&
              <div
                className={classNames('h-6 w-6 z-20', getStepImage(_collectible) ? '' : 'hidden', !collectible.step || collectible.step === 0 ? 'grayscale opacity-70' : '')}>
                <img src={getStepImage(_collectible) || `${process.env.REACT_APP_STATIC_ASSETS}/favicon.svg`}/>
              </div>
            }
            <>
              <div className={classNames("bg-grey-150 rounded-full p-1 w-full", level >= 100 ? 'hidden' : '')}>
                {getLinearProgress(_collectible)}
              </div>
              <div
                className={classNames("w-full flex justify-between text-xxxxs mt-1 text-opacity-60 px-1 !text-inherit", level >= 100 ? 'hidden' : '')}>
                <span>{level === 0 ? <LockIcon h={12}/> : `Level ${level}`}</span>
                <span>{level === 0 ? <UnlockIcon h={12}/> : `Level ${level + 1}`}</span>
              </div>
              {level >= 100 &&
                <div className={"my-2 text-md"}>MASTER</div>
              }
            </>
          </div>

          {_collectible.xp >= 0 && _collectible.upgrade && onUpgrade &&
            <div className={"absolute bottom-4 w-full flex justify-center mt-2"}>
              <button className={"secondary-small !text-xs"}
                      onClick={(e) => {
                        e.stopPropagation();
                        onUpgrade(_collectible.cryptoCurrencyId)
                      }}>Upgrade
              </button>
            </div>
          }
        </div>
      </div>
    </div>
  </div>
};


export {
  CollectibleCardComponent
}