import React from "react";
import {Tournament} from "../../models/Tournament";

interface Prop {
  userAlreadyRegistered: boolean;
  onClick: Function,
  tournament: Tournament
}

export const RegisterButton: React.FC<Prop> = ({userAlreadyRegistered = false, onClick, tournament}) => {
  return <button
    className={(userAlreadyRegistered ? "secondary " : "primary ") + " gap-x-2 text-center w-full"}
    onClick={() => {
      onClick();
    }}> {userAlreadyRegistered ? "Update" : "Open my portfolio"}
  </button>

}