import React from "react";


const ProfileIcon: React.FC<{ h?: number,color?:string }> = ({h = 17,color='black'}) => <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 12.5C12.7614 12.5 15 10.2614 15 7.5C15 4.73858 12.7614 2.5 10 2.5C7.23858 2.5 5 4.73858 5 7.5C5 10.2614 7.23858 12.5 10 12.5Z" stroke={`${color}`} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M2.5 16.875C4.01328 14.2602 6.76172 12.5 10 12.5C13.2383 12.5 15.9867 14.2602 17.5 16.875" stroke={`${color}`} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

;

export {ProfileIcon};