import {useEffect, useState} from "react";
import {CollectibleCardComponent} from "../collectibles/collectible-card.component";
import {QuantityGemsComponent} from "../ui/quantity-gems.component";
import BuyDailyCollectibleDrawer from "./buy-daily-collectible-drawer.component";
import {useGetProductsQuery} from "../../apis/shop.api";
import {useGetAllUserCondensedCollectiblesQuery} from "../../apis/collectibles.api";
import {CollectibleCard} from "../../dto/collectibles/collectibles-list-response.dto";
import {find} from "lodash";
import {ShopProduct} from "../../models/shop/Product";
import {toastMessage} from "../../utils/ToastUtils";

interface DailyCollectiblesProps {
}

function canBuyCollectible(selectedCollectible: ShopProduct, userCollectibles: Partial<CollectibleCard>[]) {

  /* return false if :
    - owned collectible exist AND owned collectible level is >= collectible
  */

  if (!userCollectibles) {
    return true;
  }

  const ownedCollectible = find(userCollectibles, function (collectibleItem) {
    return collectibleItem.cryptoCurrencyId === selectedCollectible.collectibleCard?.cryptoCurrencyId;
  })

  if (!ownedCollectible) {
    return true;
  }

  if (typeof ownedCollectible?.level !== 'undefined' && ownedCollectible.level < (selectedCollectible.collectibleCard?.level || 0)) {
    return true;
  }

  return false;

}

const DailyCollectibles: React.FC<DailyCollectiblesProps> = () => {

  const {
    data: dailyCollectibles,
    isLoading: areDailyCollectiblesLoading
  } = useGetProductsQuery({family: 'limited_offers'});
  const {
    data: userCondensedCollectibles,
    isLoading: userCondensedCollectiblesLoading
  } = useGetAllUserCondensedCollectiblesQuery();

  const [selectedCollectible, setSelectedCollectible] = useState<any | null>(null);

  return (
    <>
      <div className="w-full flex flex-wrap justify-center gap-x-2 gap-y-4">

        {dailyCollectibles && !areDailyCollectiblesLoading && !userCondensedCollectiblesLoading && dailyCollectibles.map(((collectible: any, index: number) => {
          return <DailyCollectible
            key={index}
            collectible={collectible}
            onClick={() => {
              setSelectedCollectible(collectible)
            }
            }/>
        }))
        }

      </div>

      {selectedCollectible &&
        <BuyDailyCollectibleDrawer
          collectible={selectedCollectible}
          canBuy={canBuyCollectible(selectedCollectible, userCondensedCollectibles || [])}
          open={!!selectedCollectible}
          onClose={() => setSelectedCollectible(null)}
        />
      }
    </>
  );
}

interface DailyCollectibleProps {
  collectible: any,
  onClick: Function,
}

const DailyCollectible: React.FC<DailyCollectibleProps> = ({collectible, onClick}) => {

  const collectiblePrice = collectible.discountPrice ? collectible.discountPrice : collectible.displayedPrice;

  return (
    <div
      className="flat-card flex flex-col items-center gap-y-2 !pb-1 !min-w-[160px] !px-1.5 !md:px-2 !pt-2 !md:pt-4"
      onClick={() => onClick()}
    >
      <CollectibleCardComponent
        collectible={collectible.collectibleCard}
        styledBackgroundImage={false}
        shineEffect={false}
        shadows={false}
        onClick={() => {
        }}
        onUpgrade={undefined}
      />

      <QuantityGemsComponent quantity={collectiblePrice} size={18}/>

    </div>
  );
}

export default DailyCollectibles;