import {AlertTitle, Snackbar} from "@mui/material";
import Alert from "@mui/material/Alert";
import {getBottomAlertSx} from "../../ui/MuiStyle";
import { UserNotification } from "../../../models/User";
import { GemsIcon } from "../gems-icon";
import RaisingHandIcon from "../../svg/RaisingHand";

interface NewFriendNotificationProps {
  ackNotif: Function,
  notification: UserNotification
}

const NewFriendNotification: React.FC<NewFriendNotificationProps> = (props) => {
  return (

    <Snackbar
      sx={{
        bottom: '75px!important',
        right: '0',
        left: '0'
      }}
      anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
      open={true}
      autoHideDuration={6000}
      onClose={() => {
        props.ackNotif()
      }}
    >

      <div className={"h-full w-full mx-4"}>

        <div className="flat-card flex gap-2">

          <div className="flex-none content-center">
            <RaisingHandIcon/>
          </div>

          <div className="flew-auto flex flex-col">

            <p className="text-base">{props.notification.name}</p>
            <p className="text-grey-700">{props.notification.text}</p>

          </div>

        </div>

      </div>

    </Snackbar>

  );
}

export default NewFriendNotification;