import React, {useEffect, useState} from 'react'
import {
  canParticipate,
  canSubscribeToTournament,
  getSubScoreValue,
  hasTournamentEnded,
  isTournamentRunning,
  SubPayload,
  Tournament
} from "../../models/Tournament";
import Countdown from "react-countdown";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import * as _ from "lodash";
import {HeadToHeadItemComponent} from "../squad/head-to-head.item.component";
import {SquadItemComponent} from "../squad/squad.item.component";
import {DateTime} from "luxon";
import {PumpOrDumpItemComponent} from "../squad/pump-or-dump.item.component";
import {ChartRedIcon} from '../ui/chart-red-icon';
import {useNavigate} from 'react-router';
import {classNames, cssHidden} from "../../utils/Helper";
import {useGetUserQuery} from "../../apis/users.api";
import {useGetStreakBetBonusQuery} from "../../apis/tournaments.api";
import {GainCoinComponent} from "../ui/gain-coin.component";

export type Props = {
  tournaments: Tournament[];
  displayTournamentsDate?: boolean,
  displayBetBottomBar?: boolean,
}

const getTournamentCss = (o: Tournament) => {
  let res = '';
  if (o.type === 'head_to_head') {
    res += "";
  } else {
    res += "";
  }

  //add theme effect
  //
  //Cannot create dynamic css rule like bg-${url} that why its is hard coded for now...
  if (o.tournamentInputPayload && o.tournamentInputPayload.config && o.tournamentInputPayload.config.theme) {
    if (o.tournamentInputPayload.config.theme.name === 'doge-squad-theme') {
    }
    res += ` bg-doge-squad-theme bg-cover`;
    if (o.tournamentInputPayload.config.theme.name === 'eth-squad-theme') {
    }
    res += ` bg-eth-squad-theme bg-cover`;
    if (o.tournamentInputPayload.config.theme.name === 'btc-squad-theme') {
    }
    res += ` bg-btc-squad-theme bg-cover`;
    if (o.tournamentInputPayload.config.theme.name === 'sand-squad-theme') {
    }
    res += ` bg-sand-squad-theme bg-cover`;
  }

  return res;
}

const getRankLbl = (rank: number): string => {
  if (rank === 1) return '1st';
  else if (rank === 2) return '2nd';
  else if (rank === 3) return '3rd';
  else return '';
}

const getAwardElement = (t: Tournament) => {
  if (_.isArray(t.tournamentInputPayload?.ethAward)) {
    return <div className={"text-sm flex flex-col items-end"}>
      {t.tournamentInputPayload.ethAward.map((o: number, index: number) => <div
        key={`price_${index}`}>{`${getRankLbl(++index)} ${o}$`}</div>)}
    </div>
  } else if (t.tournamentInputPayload?.ethAward) {
    return <div>{t.tournamentInputPayload.ethAward}$</div>;
  } else return <></>;
}

const isLocked = (o: Tournament) => {
  return canSubscribeToTournament(o) && !canParticipate(o) && !hasTournamentEnded(o);
}

export function getSpecificIcon(o: Tournament) {
  if (o.type === 'squad') {
    return 'Weekly Portfolio'
  } else if (o.type === 'head_to_head') {
    return "Head to Head";
  } else if (o.type === 'pump_or_dump') {
    return "Pump or Dump";
  }
}

export function getSpecificDescription(o: Tournament) {

  let res = 'Enjoy'
  if (o.type === 'squad') {
    res = `Pick your Squad`;
  } else if (o.type === 'head_to_head') {
    res = 'Who will do better?';
  } else if (o.type === 'pump_or_dump') {
    const code = o.tournamentInputPayload.crypto.code;
    res = `Will ${code} go up or down?`;
  }
  return res;
}

export function getChoosenCollectibleInfo(tournament: Tournament, crypto: any) {

  if (crypto && tournament.cryptoBoosts && tournament.cryptoBoosts[crypto._id]) {
    return {
      ...crypto,
      bonus: tournament.cryptoBoosts[crypto._id]
    }
  }

}


const TournamentListComponent: React.FC<Props> = ({
                                                    displayTournamentsDate = true,
                                                    tournaments,
                                                    displayBetBottomBar = false
                                                  }) => {

  const navigate = useNavigate();
  const [grouped, setGrouped] = useState<_.Dictionary<Tournament[]>>({});
  useEffect(() => {
    setGrouped(_.groupBy(tournaments, t => t.shouldStartAt));
  }, [tournaments]);


  return (<>
      <div className={"flex flex-col my-2 gap-y-6 "}>
        {grouped && Object.keys(grouped).map((date, idx) => {
          return <div className={"flex flex-col gap-y-4"} key={`tournament${date}_${idx}_${Math.random()}`}>

            <div
              className={classNames("flex justify-center sticky top-[150px] z-20", cssHidden(!displayTournamentsDate))}>
              <div className={"rounded-full px-3 py-1 flat-item flex justify-center max-w-[200px] font-normal text-sm"}>
                {DateTime.fromISO(date).toFormat('ccc. dd. LLL')}
              </div>
            </div>

            {grouped[date] && grouped[date].map((o, idx) => {
              return <div key={`tournament${o.id}_${idx}_${Math.random()}`}>
                <div
                  className={"relative flex flex-col items-center flat-card " + getTournamentCss(o)}>

                  {isLocked(o) &&
                    <div className={"absolute right-0 top-0 left-0 bottom-0 flex items-center justify-center"}>
                      <LockOutlinedIcon fontSize={"large"} className={" opacity-75 text-gray-400 w-full h-full"}/>
                    </div>
                  }

                  {/* Tournament Head */}
                  <div className={"flex flex-col gap-2 items-center w-full pb-1"}>


                    {/* 1st Line */}
                    <div className='relative w-full flex justify-center center'>

                      {/* Title */}
                      <h2 className={"flex text-lg font-bold"}>
                        {getSpecificDescription(o)}
                      </h2>

                      {o.type === "pump_or_dump" &&
                        <div className={"cursor-pointer absolute right-0"}
                             onClick={() => navigate(`/portfolio/${o.tournamentInputPayload.crypto._id}?hideActionButtons=true`)}
                        >
                          <ChartRedIcon h={25}/>
                        </div>

                      }

                      {o.type === "head_to_head" &&
                        <div className={"cursor-pointer absolute right-0"}
                             onClick={() => navigate(`/chart/${o.tournamentInputPayload.cryptoA._id}/${o.tournamentInputPayload.cryptoB._id}`)}>
                          <ChartRedIcon h={25}/>
                        </div>
                      }

                    </div>


                    {o.currentUserSubscription && isTournamentRunning(o, o.currentUserSubscription) &&
                      <div className={"w-full text-center select-none text-grey-200 text-xs pb-3"}>
                        <Countdown
                          date={o.currentUserSubscription?.shouldStopAt ? o.currentUserSubscription?.shouldStopAt : o.shouldStopAt}/> left
                      </div>
                    }

                  </div>

                  <div className={"w-full justify-center items-center"}>
                    {o.type === 'squad' &&
                      <SquadItemComponent tournament={o}/>
                    }
                    {o.type === 'head_to_head' &&
                      <HeadToHeadItemComponent tournament={o} userScore={o.currentUserSubscription}/>
                    }
                    {o.type === 'pump_or_dump' &&
                      <PumpOrDumpItemComponent tournament={o} userScore={o.currentUserSubscription}/>
                    }
                  </div>
                </div>
              </div>
            })}
          </div>;
        })}
      </div>

      {displayBetBottomBar &&
        <BottomBetBar tournaments={tournaments}/>
      }

    </>
  );
}


const BottomBetBar: React.FC<{ tournaments: Tournament[] }> = ({tournaments}) => {
  const [displayStreak, setDisplayStreak] = useState<boolean>(false);
  const [scores, setScores] = useState<SubPayload[]>([]);
  const [total, setTotal] = useState<number>(0);
  const {data: user} = useGetUserQuery();
  const {data: streakBetBonus} = useGetStreakBetBonusQuery();
  useEffect(() => {
    if (tournaments && tournaments.find(o => o.type === 'pump_or_dump' || o.type === 'head_to_head')) {
      setDisplayStreak(true);
    }
    if (tournaments) {
      const arr = [];
      for (const t of tournaments) {
        if (t.currentUserSubscription) arr.push(t.currentUserSubscription);
      }
      setScores(arr);
    }
  }, [tournaments]);

  useEffect(() => {
    if (scores) {
      setTotal(scores.map(s => getSubScoreValue(s)).reduce((prev, curr) => prev + curr, 0));
    }
  }, [scores]);

  return <>
    {displayStreak &&
      <div
        className="fixed left-0 bottom-16 w-screen px-2 pt-4 pb-3 bg-gradient-to-b from-white/0 to-white flex-col justify-start items-center gap-4 inline-flex z-30">
        <div
          className="text-white text-sm w-full max-w-lg px-3 py-2 bg-black rounded-lg items-center gap-3 inline-flex justify-between">

          <div className={"text-inherit flex flex-col"}>
            <div className={"text-inherit text-xs"}>
              <span className={"text-xl"}>{scores?.length || 0}</span> Bets
            </div>
          </div>

          <div className={"flex flex-col text-inherit items-center"}>
            <span className={"text-xs text-inherit"}>Potential earnings</span>
            <GainCoinComponent size={20} gain={total || 0}/>
          </div>

          <div className={"flex text-inherit gap-x-0.5"}>
            <div className={"text-inherit flex flex-col justify-between"}>
              <div className={"text-inherit"}>
                <span className={"uppercase text-sm !font-normal"}>Bet Streak</span>
              </div>
              <div className={"text-inherit flex gap-x-1"}> {[1, 2, 3, 4, 5].map((v, idx) => <span
                className={classNames('h-3 w-3 rounded-full', idx < (user?.betStreakCount || 0) ? 'bg-orange animate-pulse' : 'bg-white')}></span>)}
              </div>
            </div>
            <span
              className={classNames("flex ml-1 font-normal !text-xl text-orange align-bottom", cssHidden(!streakBetBonus || streakBetBonus <= 1))}>
               <span className={"self-end"}> x{streakBetBonus || 0}</span>
             </span>
          </div>
        </div>
      </div>
    }
  </>
}
export {TournamentListComponent};