import React, {useEffect, useState} from 'react'
import TemporaryDrawer from "../../ui/temp-drawer.component";
import {classNames, hasElements, isInStandaloneMode} from "../../../utils/Helper";
import {usersApi, useUpdateOnBoardingUserMutation, useUpdatePseudoMutation} from "../../../apis/users.api";
import {FireIcon} from "../../ui/fire-icon";
import {SmilingGlassesIcon} from "../../ui/smiling-glasses-icon";
import {QuantityCoinComponent} from "../../ui/quantity-coin.component";
import {CoinIcon} from "../../ui/coin-icon";
import {useClaimFreeMoneyAmountMutation, useGetMoneyAmountQuery} from "../../../apis/game-income.api";
import {SwapComponent} from "../../squad/swap.component";
import {useGetNextTournamentsQuery} from "../../../apis/tournaments.api";
import {useGetCryptosQuery} from "../../../apis/cryptos.api";
import {PartyingFaceIcon} from "../../ui/partying-face-icon";
import RocketIcon from "../../svg/RocketIcon";
import {SquadAggrPositions} from "../../squad/squad-aggr-tx.component";
import {useAppDispatch} from "../../../app/hooks";
import {FileUploadComponent} from "../../ui/upload/file-upload.component";
import {MobilePhoneIcon} from "../../ui/mobile-phone-icon";
import {isIOS, isMobile, isTablet} from "react-device-detect";
import {ItemShadowIcon} from "../../ui/item-shadow-icon";
import {IosShare, MoreVert} from "@mui/icons-material";
import {useLocation, useNavigate} from "react-router-dom";
import {PumpOrDumpItemComponent} from "../../squad/pump-or-dump.item.component";

interface Props {
  open: boolean;
  onOpen: Function;
  onClose: Function;
}

const InitialOnboardingDrawer: React.FC<Props> = ({ open, onOpen, onClose }) => {

  const [step, setStep] = useState(1);
  const [maxStep, setMaxStep] = useState(3);
  const [_open, _setOpen] = useState(open);
  const [onboardingDone] = useUpdateOnBoardingUserMutation();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    _setOpen(open)
  }, [open]);

  useEffect(() => {
    // toastMessage(`isInStandaloneMode()=${isInStandaloneMode()}\n,isMobile=${isMobile},\nisTablet=${isTablet}`);
    if (!isInStandaloneMode() && (isMobile || isTablet)) {
      setMaxStep(4);
    }
  }, []);

  useEffect(() => {
    const localStorageStep = localStorage.getItem('tanji_initial_onboarding');
    if (localStorageStep) {
      setStep(Number(localStorageStep));
    } else {
      setStep(1);
    }
  }, []);

  useEffect(() => {
    if (step !== null && typeof step !== 'undefined') {
      let _step = step;
      if (_step > maxStep) {
        _setOpen(false);
        onboardingDone("initial");
        navigate(location.pathname, {state: {...location.state, openDailyReward: true}, replace: true})
      }
      localStorage.setItem('tanji_initial_onboarding', String(_step));
    }
  }, [step, maxStep]);


  return <TemporaryDrawer closeWhenClickingOutside={false} anchor={'bottom'} open={_open} lineIcon={false}
                          onOpen={() => onOpen()}
                          onClose={() => onClose()}>
    <div className={"w-full flex flex-col justify-center items-center py-2"}>

      {step < 5 &&
        <div className={"w-[60px] my-2"}>
          <StepsDot size={4} current={step}/>
        </div>
      }


      <div>
        {step === 1 &&
          <Step1 onNext={() => setStep((prev) => ++prev)}/>
        }

        {step === 2 &&
          <Step2 onNext={() => setStep((prev) => ++prev)}/>
        }

        {step === 3 &&
          <Step3 onNext={() => setStep((prev) => ++prev)}/>
        }

        {/*{step === 4 &&*/}
        {/*  <Step4Bet onNext={() => setStep((prev) => ++prev)}/>*/}
        {/*}*/}

        {/*{step === 5 &&*/}
        {/*  <Step5Bet*/}
        {/*    onNext={() => {*/}
        {/*      setStep((prev) => ++prev)*/}
        {/*    }}*/}
        {/*  />*/}
        {/*}*/}

        {step === 4 &&
          <StepPrompt onNext={() => {
            setStep((prev) => ++prev);
          }}/>
        }
      </div>

    </div>
  </TemporaryDrawer>;
}


const StepsDot: React.FC<{ size: number, current: number }> = ({size, current = 1}) => {
  return <div className={"flex justify-center items-center w-full justify-between"}>
    {Array.from({length: size}, (item, index) =>
      <span className={classNames("rounded-full  w-3 h-3", index + 1 <= current ? 'bg-orange' : 'bg-gray-400')}
            key={index}></span>
    )}
  </div>
};

interface StepProps {
  onNext: Function;
}

const Step1: React.FC<StepProps> = ({onNext}) => {
  return <div className={"flex flex-col justify-center items-center w-full"}>
    <h1 className={"text-2xl my-4"}>Welcome!</h1>

    <div>
      <p className={"text-grey-700 font-medium py-1 text-center"}>
        Tanjiblocks is an arcade trading game. Every day you win free in-app money to spend on cryptocurrencies.
      </p>
      <p className={"text-grey-700 font-medium py-1 text-center"}>
        Every week, the top coin earners win exclusive prizes: from cryptos to collectibles to many other prize.
      </p>
    </div>

    <div className={"w-full my-14 px-2"}>
      <div
        className={"relative flat-card flex w-full justify-between transform -rotate-1 shadow-2xl shadow-[0px_-10px_100px_-10px_rgba(245,103,51,0.3)]"}>
        <span className={"absolute -top-7 left-3/4"}><SmilingGlassesIcon h={36}/></span>
        <span className={"absolute -bottom-5 -left-2"}><FireIcon h={36}/></span>

        <div className={"flex items-center gap-x-4"}>
          <img className={"w-8 h-8 rounded fat-grey-shadow-2 border border-neutral-200"}
               src={`${process.env.REACT_APP_STATIC_ASSETS}/images/johnthedon.png`}/>
          <span>johnthedon</span>
        </div>
        <QuantityCoinComponent quantity={4315}/>
      </div>
    </div>

    <button className={classNames("primary")} onClick={async () => {
      onNext();
      gtag('event', 'onBoardingNext', {step: 1});
    }}>NEXT
    </button>

  </div>
};

const Step2: React.FC<StepProps> = ({onNext}) => {
  const [userName, setUserName] = useState('');
  const defaultPicture = `${process.env.REACT_APP_STATIC_ASSETS}/images/upload-image-icon.png`;
  const [picture, setPicture] = useState(defaultPicture);
  const [update] = useUpdatePseudoMutation();
  const dispatch = useAppDispatch();

  return <div className={"flex flex-col justify-center items-center w-full"}>
    <h1 className={"text-2xl my-4"}>Let's create your profile</h1>

    <div>
      <p className={"text-grey-700 font-medium py-1 text-center"}>Choose a cool username and upload an avatar to make
        your profile stand out.</p>
    </div>

    <div
      className={"bordered heavy-bordered p-4 flex items-center justify-center gap-x-6 w-full my-4"}>

      <div className={"cursor-pointer w-[60px] h-[60px]"}>
        <FileUploadComponent id={"onboarding_profile_img"}
                             api={"/users/uploadProfilePicture"}
                             onUploadSuccess={(res: any) => {
                               if (res.data) {
                                 setPicture(res.data + '?' + new Date().getTime());
                               }
                               dispatch(usersApi.util.invalidateTags([{type: 'User'}]));
                             }}>
          <div
            className={"w-full h-full overflow-hidden p-1 rounded fat-grey-shadow-2 border border-neutral-200"}>
            <img className={"object-cover w-full h-full"} src={picture}/>
          </div>
        </FileUploadComponent>
      </div>

      <div className={"grow"}><input className={'!m-0 !bg-white !bordered'} type={"text"} placeholder={"username"}
                                     onChange={(e) => setUserName(e.target.value)}></input></div>
    </div>

    <div className={"w-full flex flex-col justify-center gap-y-6 mt-4"}>
      <button className={classNames("primary")} onClick={async () => {
        if (userName)
          await update(userName);
        onNext({userName});
        gtag('event', 'onBoardingNext', {step: 2});
      }}>NEXT
      </button>

      <button className={"tertiary text-center"} onClick={() => {
        onNext();
        gtag('event', 'onBoardingNext', {step: 2});
      }}>skip for now
      </button>
    </div>

  </div>
};

const Step3: React.FC<StepProps> = ({onNext}) => {
  const [claim] = useClaimFreeMoneyAmountMutation();
  return <div className={"flex flex-col justify-center items-center w-full"}>
    <h1 className={"text-2xl my-4"}>Grab your first coin reward!</h1>

    <div>
      <p className={"text-grey-700 font-medium py-1 text-center"}>
        Tanjiblocks gives you free coins every day to invest in your favorite crypto.
      </p>
    </div>

    <div
      className={"flex flex-col justify-center items-center w-full gap-x-6 my-4 gap-y-2"}>
      <CoinIcon h={80}/>
      <ItemShadowIcon/>
      <h1 className={"text-2xl mt-2"}>X2000</h1>
    </div>

    <div className={"w-full flex flex-col justify-center gap-y-6 mt-4"}>
      <button className={classNames("primary")} onClick={async () => {
        await claim();
        onNext();
        gtag('event', 'onBoardingClaimFreeCoinsSuccess', {});
        gtag('event', 'onBoardingNext', {step: 3});
      }}>CLAIM
      </button>
    </div>

  </div>
};

const Step4: React.FC<StepProps> = ({onNext}) => {
  const {
    data: portfolio,
    isLoading: areNextLoading
  } = useGetNextTournamentsQuery({count: 1, type: 'squad'});

  const {data} = useGetMoneyAmountQuery();

  const {data: btc, isLoading: isBtcLoading} = useGetCryptosQuery({filter: 'BTC', pageSize: 1, pageIndex: 0});

  useEffect(() => {
    if (!hasElements(portfolio) && !areNextLoading) {
      onNext();
    }
  }, [portfolio, areNextLoading])
  return <div className={"flex flex-col justify-center items-center w-full"}>
    <h1 className={"text-2xl my-4"}>Buy your first Bitcoin!</h1>

    <div>
      <p className={"text-grey-700 font-medium py-1 text-center"}>
        Use the coins you receive to build a portfolio each week. Let's start by buying some Bitcoin:
      </p>
    </div>

    <div className={"flex justify-center items-center my-4 w-full"}>
      {portfolio && hasElements(portfolio) &&
        <SwapComponent forceAmount={data?.amount ? data.amount * 0.75 : 1000} mode={"buy"} forceCryptoDest={true}
                       cryptoDestId={btc ? btc[0]._id : undefined}
                       tournament={portfolio[0]} onError={() => {
        }} displayDefaultSuccessDialog={false} swapDone={() => {
          onNext();
          gtag('event', 'onBoardingNext', {step: 4});
        }}/>
      }
    </div>
  </div>;
};

const Step4Bet: React.FC<StepProps> = ({onNext}) => {
  const {
    data: bet,
    isLoading: areNextLoading
  } = useGetNextTournamentsQuery({count: 1, type: 'pump_or_dump'});

  useEffect(() => {
    if (!hasElements(bet) && !areNextLoading || (bet && bet[0].currentUserSubscription)) {
      onNext();
    }
  }, [bet, areNextLoading]);

  return <div className={"flex flex-col justify-center items-center w-full"}>
    <h1 className={"text-2xl my-4"}>Take your first position ! </h1>

    <div>
      <p className={"text-grey-700 font-medium py-1 text-center"}>
        Use the coins you receive to bet on crypto performances each day. Let's choose if the following crypto is going Up(bullish) or Down(bearish) :
      </p>
    </div>

    <div className={"flex justify-center items-center my-4 flat-card"}>
      {bet && hasElements(bet) &&
        <PumpOrDumpItemComponent displayBetBoost={false} displayOddDetail={false} tournament={bet[0]} betDone={() => {
          onNext();
          gtag('event', 'onBoardingNext', {step: 4});
        }}/>
      }
    </div>
  </div>;
};

const Step5: React.FC<StepProps> = ({onNext}) => {
  const {
    data: portfolio,
    isLoading: areNextLoading
  } = useGetNextTournamentsQuery({count: 1, type: 'squad'});

  return <div className={"flex flex-col justify-center items-center w-full p-4"}>
    <h1 className={"text-2xl my-4"}>Congratulations!</h1>

    <div>
      <p className={"text-grey-700 font-medium py-1 text-center"}>
        {portfolio && hasElements(portfolio) &&
          <>You’ve built your very first position in Tanjiblocks. You are now ready to start your crypto journey.</>
        }
        {!hasElements(portfolio) && !areNextLoading &&
          <>You’ve claimed your very first Tanjis ! You are now ready to start your crypto journey.</>
        }
      </p>
    </div>

    <div
      className={"my-10 relative flex w-full justify-between transform -rotate-1"}>
      <span className={"absolute -top-7 left-10"}><PartyingFaceIcon h={36}/></span>
      <span className={"absolute -bottom-5 right-2"}><RocketIcon h={36}/></span>

      {portfolio && hasElements(portfolio) &&
        <div
          className="flex justify-center items-center w-full gap-x-6">
          <SquadAggrPositions tournament={portfolio[0]} displayHeader={false}/>
        </div>
      }
    </div>

    <div className={"w-full flex flex-col justify-center gap-y-6 mt-4"}>
      <button className={classNames("primary")} onClick={async () => {
        onNext();
        gtag('event', 'onBoardingNext', {step: 5});
      }}>Explore
      </button>
    </div>
  </div>
};

// const Step5Bet: React.FC<StepProps> = ({onNext}) => {
//   const {
//     data: bet,
//     isLoading: areNextLoading
//   } = useGetNextTournamentsQuery({count: 1, type: 'pump_or_dump'});
//
//   useEffect(() => {
//     if (!hasElements(bet) && !areNextLoading) {
//       onNext();
//     }
//   }, [bet, areNextLoading]);
//
//   const nav = useNavigate();
//
//   return <div className={"flex flex-col justify-center items-center w-full"}>
//     <h1 className={"text-2xl my-4"}>Congratulations!</h1>
//
//     <div>
//       <p className={"text-grey-700 font-medium py-1 text-center"}>
//         {bet && hasElements(bet) &&
//           <>You’ve built your very first position in Tanjiblocks. You are now ready to start your crypto journey.</>
//         }
//         {!hasElements(bet) && !areNextLoading &&
//           <>You’ve claimed your very first Tanjis ! You are now ready to start your crypto journey.</>
//         }
//       </p>
//     </div>
//
//     <div className={"flex justify-center items-center my-4 flat-card"}>
//       {bet && hasElements(bet) &&
//         <PumpOrDumpItemComponent displayBetBoost={false} displayOddDetail={false} tournament={bet[0]} betDone={() => {
//           setTimeout(() => {
//             onNext();
//           }, 1000)
//           gtag('event', 'onBoardingNext', {step: 4});
//         }}/>
//       }
//     </div>
//
//     <div className={"w-full flex flex-col justify-center gap-y-6 mt-4"}>
//       <button className={classNames("primary")} onClick={async () => {
//         onNext();
//         gtag('event', 'onBoardingNext', {step: 5});
//       }}>Explore
//       </button>
//     </div>
//   </div>;
// };

const StepPrompt: React.FC<StepProps> = ({onNext}) => {

  const iosText = <>{"In the Safari browser menu tap the share button"}<span className={"text-xxs"}><IosShare
    fontSize={"small"}/></span> {"and then choose 'Add to Home Screen'"}</>;
  const androidText = <>{"In your browser menu tap the more button"}<MoreVert
    fontSize={"small"}/> {"and choose install app."}</>;

  const [btnCss, setBtnCss] = useState('disabled');
  useEffect(() => {
    setTimeout(() => {
      setBtnCss('');
    }, 3000)
  }, [])
  return <div className={"flex flex-col justify-center items-center w-full"}>
    <h1 className={"text-2xl mt-2 mb-4"}>Add to Home Screen</h1>

    <div>
      <p className={"text-grey-700 font-medium py-1 text-center"}>For a better user experience you can install the
        app.</p>
      <p className={"text-grey-700  font-medium py-1 text-center"}>
        {isIOS ? iosText : androidText}
      </p>
    </div>

    <div className={"w-full my-10 px-2"}>
      <div
        className={"relative flex justify-center items-center"}>
        <span className={"flex-col flex justify-center items-center gap-y-2"}>
          <span className={"animate-bounce"}><MobilePhoneIcon h={68}/></span>
         <ItemShadowIcon/>
        </span>
      </div>
    </div>

    <button className={classNames("secondary", btnCss)} onClick={async () => {
      gtag('event', 'onBoardingNext', {step: 4});
      onNext();
    }}>Got it
    </button>

  </div>
};
export {InitialOnboardingDrawer};