import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export type AppDialogProps = {
  openComponent?: JSX.Element,
  children: JSX.Element | JSX.Element[],
  title: string | JSX.Element,
  description?: string,
  onClose?: Function,
  beforeOpen?: Function,
  disabled?: boolean,
  initialState?: boolean
}
const AppDialog: React.FC<AppDialogProps> = ({
  openComponent,
  children,
  title,
  description,
  onClose,
  disabled = false,
  initialState = false,
  beforeOpen
}) => {
  const [open, setOpen] = useState(initialState)

  useEffect(() => {
    setOpen(initialState);
  }, [initialState])

  let childrenWithCallback = React.Children.map(children, element =>
    React.cloneElement(element, {
      onValidate: (data: any) => {
        // const newState = !open;
        // if (newState) {
        //   handleClickOpen(null);
        // } else {
        handleClose(data, 'onValidate');
        // }
      },
      onCancel: (data: any) => {
        handleClose(data, 'onCancel');
      }
    }));

  useEffect(() => {
    if (openComponent && !React.isValidElement(openComponent)) {
      setOpen(true);
    }
  }, [openComponent]);

  const handleClose = (data: any, reason: string) => {
    setOpen(false);
    if (onClose) {
      onClose(data, reason);
    }
  };

  const handleClickOpen = (e: any) => {
    if (e) {
      e.stopPropagation();
    }
    if (disabled) return;
    setOpen(true);
  };

  return (
    <div>
      <div onClick={(e) => {
        const doOpen = typeof beforeOpen === 'undefined' || beforeOpen() === true;
        if (doOpen) handleClickOpen(e);
      }} className={(!disabled ? 'cursor-pointer' : '')}>
        {React.isValidElement(openComponent) && openComponent}
      </div>

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        {title && (typeof title === 'string') &&
          <DialogTitle id="form-dialog-title" className={"overflow-hidden"}><span
            className={title.length > 20 ? "text-sm" : "text-md"}>{title}</span></DialogTitle>
        }
        {title && (typeof title !== 'string') &&
          title
        }
        <DialogContent className={"my-2"}>
          {
            description &&
            <DialogContentText>
              {description}
            </DialogContentText>
          }
          {childrenWithCallback}
        </DialogContent>

      </Dialog>
    </div>
  )
};

export { AppDialog };