import React, {useEffect, useRef, useState} from "react";
import MetaMaskOnboarding from "@metamask/onboarding";
import {ethers} from "ethers";
import {useWeb3LogginMutation} from "../../apis/auth.api";
import {useCreateUserMutation} from "../../apis/users.api";
import {useNavigate} from "react-router-dom";
import toast from "react-hot-toast";
import {isMobile} from "react-device-detect";
import {AppDialog} from "../ui/app-dialog.component";
import axios from "axios";

const MetamaskInstruction = ({
                               onCancel = () => {
                               }
                             }) => {
  return <>
    <div className={"my-2 mx-2"}>

      <div className={"list-decimal gap-y-2 flex flex-col"}>
        <div> 1) Use a desktop Chrome browser with the Metamask extension installed</div>
        <div> 2) Use the Metamask Mobile App's integrated browser</div>
      </div>
    </div>

    <div>
      <button className={"secondary mt-2"} onClick={() => onCancel()}>Ok got it</button>
    </div>
  </>
}
const MetamaskLoginComponent: React.FC = () => {
  const onboarding = useRef<MetaMaskOnboarding>();
  const [startLoggin, setStartLoggin] = useState(false);
  const [displayInstructions, setDisplayInstructions] = useState<boolean>(false);
  const [createUser, {data: createdUser, isLoading: isUserCreationLoading}] = useCreateUserMutation();
  const [web3loggin, {data: web3User, isLoading: isWeb3Logging, error: web3LogginError}] = useWeb3LogginMutation();
  const navigate = useNavigate();
  const [nonce, setNonce] = useState(null);
  useEffect(() => {
    if (!onboarding.current) {
      onboarding.current = new MetaMaskOnboarding();
    }
  }, []);

  useEffect(() => {
    if (startLoggin === true) {

      gtag('event', 'clickMetamaskLogin', {isMobile});

      if (MetaMaskOnboarding.isMetaMaskInstalled()) {
        (window as any).ethereum
          .request({method: 'eth_requestAccounts'})
          .then(async (newAccounts: any) => {
            const web3LoginPublicAddress = newAccounts[0];
            axios.get(`/auth/getWeb3Nonce?web3LoginPublicAddress=${web3LoginPublicAddress}`)
              .then(async (response) => {
                if (response.data && !isNaN(response.data)) {
                  setNonce(response.data);
                } else {
                  await createUser({web3LoginPublicAddress});
                }
              })
              .catch(error => toast.error(error));
          });
      } else {
        setStartLoggin(false);
        setDisplayInstructions(true);
      }
    }
  }, [startLoggin]);


  useEffect(() => {
    if (nonce || createdUser?.web3LoginNonce) {
      let _n: string = String((nonce || createdUser?.web3LoginNonce));
      (window as any).ethereum
        .request({method: 'eth_requestAccounts'})
        .then(async (newAccounts: any) => {
          if (_n) {
            const web3Provider = new ethers.providers.Web3Provider((window as any).ethereum);
            const web3LoginPublicAddress = newAccounts[0];
            let signer = web3Provider.getSigner();
            const message = `Sign this message to log into ${process.env.REACT_APP_NAME} with Metamask. ${_n}`
            signer.signMessage(message).then((signature) => {
              web3loggin({signature, publicAddress: web3LoginPublicAddress, message})
            });
          }
        });
    }
  }, [nonce, createdUser]);

  useEffect(() => {
    if (web3LogginError) {
      gtag('event', 'metamaskLoginError', {isMobile});
      toast.dismiss();
      // @ts-ignore
      toast.error(web3LogginError.data.message);
      navigate('/login');
      setStartLoggin(false);
    }
  }, [web3LogginError])

  useEffect(() => {
    if (web3User) {
      gtag('event', 'metamaskLoginSuccess', {isMobile});

      if (parent !== window) {
        parent.window.postMessage('redirectToAppHome', process.env.REACT_APP_DOMAIN as string);
      } else {
        navigate('/home');
      }
    }
  }, [web3User]);

  useEffect(() => {
    toast.dismiss();
    if (isWeb3Logging) {
      toast('Validating Metamask connection...', {
        icon: <img width={18} height={18} src={process.env.REACT_APP_STATIC_ASSETS + "/images/13-min.png"}
                   alt="Metamask Login"/>
      })
    }
  }, [isWeb3Logging])

  return <div
    className={"w-full h-16 bg-white bg-opacity-10 border border-gray-300 border-opacity-20 rounded-sm center"}>

    {isMobile && !MetaMaskOnboarding.isMetaMaskInstalled() &&
      <a
        href={`https://metamask.app.link/dapp/${window.location.host}`}
        // href={"https://metamask.app.link/dapp/seein-apps.com"}
        className={'flex w-full h-full center'}>
        <button className={"flex center w-full gap-x-2"}>
          <img width={25} height={25} className="float-left"
               src={process.env.REACT_APP_STATIC_ASSETS + "/images/13-min.png"} alt="Metamask Login"/>
          {/*<span className={"font-bold font-serif"}>Login with Metamask</span>*/}
        </button>
      </a>
    }

    {(!isMobile || MetaMaskOnboarding.isMetaMaskInstalled()) &&
      <button className={"flex center gap-x-2 leading-10 center"}
              onClick={() => setStartLoggin(true)}>
        <img width={25} height={25} className="float-left"
             src={process.env.REACT_APP_STATIC_ASSETS + "/images/13-min.png"} alt="Metamask Login"/>
        {/* <span className={"font-bold font-serif leading-6"}>Login with Metamask</span> */}
      </button>
    }

    {displayInstructions &&
      <AppDialog initialState={true}
                 disabled={false} title={<h2 className={"m-4 leading-10 bg-auto"}>
        <img width={35} height={35} className="mx-2 float-left"
             src={process.env.REACT_APP_STATIC_ASSETS + "/images/13-min.png"}
             alt="Metamask Login"/>
        To login with Metamask you can
      </h2>}
                 onClose={async () => {
                   setDisplayInstructions(false);
                 }}>
        <MetamaskInstruction/>
      </AppDialog>
    }
  </div>

}
export {MetamaskLoginComponent};