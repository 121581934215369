import React from 'react';

const LoudlyCryingFaceIcon = () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_728_20835)">
      <path d="M36 18C36 27.941 27.941 36 18 36C8.059 36 0 27.941 0 18C0 8.059 8.059 0 18 0C27.941 0 36 8.059 36 18Z" fill="#FFCC4D"/>
      <path d="M22 27C22 29.763 20.209 30 18 30C15.79 30 14 29.763 14 27C14 24.239 15.79 21 18 21C20.209 21 22 24.239 22 27ZM30 15C29.876 15 29.75 14.977 29.629 14.928C24.4 12.837 22.257 9.68702 22.168 9.55402C21.861 9.09402 21.9851 8.47302 22.445 8.16702C22.904 7.86102 23.522 7.98302 23.83 8.44102C23.849 8.46802 25.76 11.226 30.371 13.07C30.8841 13.276 31.1341 13.857 30.9291 14.37C30.7721 14.762 30.396 15 30 15ZM6.00005 15C5.60305 15 5.22805 14.762 5.07105 14.371C4.86605 13.858 5.11505 13.276 5.62805 13.071C10.24 11.227 12.1511 8.46902 12.1701 8.44202C12.4781 7.98602 13.099 7.86502 13.557 8.17202C14.014 8.48002 14.138 9.09702 13.832 9.55502C13.743 9.68802 11.6001 12.838 6.37205 14.929C6.25005 14.977 6.12405 15 6.00005 15Z" fill="#664500"/>
      <path d="M24 16H28V35L24 34.954V16ZM8 35L12 34.954V16H8V35Z" fill="#5DADEC"/>
      <path d="M14.999 18C14.849 18 14.696 17.966 14.553 17.895C11.041 16.139 7.48297 17.877 7.44797 17.895C6.95297 18.144 6.35297 17.941 6.10597 17.448C5.85897 16.954 6.05897 16.353 6.55297 16.106C6.73497 16.016 11.051 13.909 15.448 16.106C15.942 16.353 16.142 16.954 15.895 17.448C15.719 17.798 15.366 18 14.999 18ZM28.999 18C28.849 18 28.696 17.966 28.553 17.895C25.04 16.139 21.483 17.877 21.448 17.895C20.954 18.143 20.354 17.942 20.106 17.448C19.859 16.954 20.059 16.353 20.553 16.106C20.735 16.016 25.054 13.91 29.448 16.106C29.942 16.353 30.142 16.954 29.895 17.448C29.719 17.798 29.366 18 28.999 18Z" fill="#664500"/>
      <path d="M18 36C27.9411 36 36 35.1046 36 34C36 32.8954 27.9411 32 18 32C8.05887 32 0 32.8954 0 34C0 35.1046 8.05887 36 18 36Z" fill="#5DADEC"/>
      <path d="M18 29C19.6569 29 21 28.1046 21 27C21 25.8954 19.6569 25 18 25C16.3431 25 15 25.8954 15 27C15 28.1046 16.3431 29 18 29Z" fill="#E75A70"/>
    </g>
  <defs>
    <clipPath id="clip0_728_20835">
    <rect width="36" height="36" fill="white"/>
    </clipPath>
  </defs>
  </svg>
);

export default LoudlyCryingFaceIcon;

