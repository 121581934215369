import React from "react";


const SparkIcon: React.FC<{ h?: number }> = ({h = 24}) =>
  <svg width={h} height={h} viewBox="0 0 28 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M27.347 17.107L18.448 20.401L15.125 31.292C14.997 31.712 14.608 32 14.169 32C13.73 32 13.341 31.712 13.213 31.292L9.891 20.401L0.990998 17.107C0.597998 16.961 0.337997 16.588 0.337997 16.169C0.337997 15.751 0.597998 15.376 0.990998 15.231L9.886 11.938L13.21 0.715C13.336 0.291 13.726 0 14.169 0C14.611 0 15.002 0.291 15.128 0.716L18.452 11.939L27.348 15.232C27.739 15.376 28 15.75 28 16.169C28 16.587 27.739 16.961 27.347 17.107Z"
      fill="#FFAC33"/>
  </svg>


;
const DoubleLittleSparkIcon: React.FC<{ h?: number }> = ({h = 24}) =>
  <svg width={h} height={h} viewBox="0 0 14 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.347 7.10611L11.033 7.96211L10.133 11.2621C10.015 11.6981 9.62 12.0001 9.169 12.0001C8.718 12.0001 8.323 11.6981 8.204 11.2631L7.304 7.96311L4.991 7.10711C4.598 6.96211 4.338 6.58711 4.338 6.16911C4.338 5.75111 4.598 5.37611 4.991 5.23111L7.292 4.37811L8.199 0.756109C8.311 0.312111 8.71 0.000110626 9.169 0.000110626C9.628 0.000110626 10.027 0.312107 10.139 0.757109L11.046 4.37911L13.347 5.23211C13.74 5.37611 14 5.75111 14 6.16911C14 6.58711 13.74 6.96211 13.347 7.10611ZM9.009 28.7691L6.645 29.6441L5.769 32.0091C5.624 32.4021 5.25 32.6621 4.831 32.6621C4.413 32.6621 4.039 32.4021 3.893 32.0091L3.018 29.6441L0.653 28.7691C0.26 28.6231 0 28.2491 0 27.8311C0 27.4131 0.26 27.0381 0.653 26.8931L3.018 26.0181L3.893 23.6531C4.039 23.2601 4.413 23.0001 4.831 23.0001C5.249 23.0001 5.623 23.2601 5.769 23.6531L6.644 26.0181L9.009 26.8931C9.402 27.0391 9.662 27.4131 9.662 27.8311C9.662 28.2491 9.402 28.6231 9.009 28.7691Z"
      fill="#FFCC4D"/>
  </svg>
;


export {SparkIcon,DoubleLittleSparkIcon};