import React from 'react';

const RightPointingIcon = () => (
  <svg width="20" height="20" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_728_21274)">
      <path d="M15.856 31C15.856 31 18.25 30.792 18.924 29.208C19.621 27.569 18.302 26.899 18.302 26.899C18.302 26.899 20.216 26.958 20.924 24.958C21.592 23.073 19.966 22.208 19.966 22.208C19.966 22.208 21.837 21.901 22.383 19.916C22.842 18.245 21.216 17 21.216 17H33.424C34.383 17 35.999 16.458 36 14.457C36.002 12.457 34.341 12 33.424 12H12.924C11.924 12 11.924 11 12.924 11H15.59C19.382 11 21.733 8.96201 22.382 8.24901C23.032 7.53601 23.361 6.58201 23.116 5.42901C22.701 3.47301 21.196 3.90001 19.919 4.45401C16.841 5.79101 12.455 6.70801 10.381 6.98701C4.523 7.77801 0.00600226 12.796 2.2618e-06 18.871C-0.00399774 25.497 5.298 30.995 11.924 31H15.856Z" fill="#FFDC5D"/>
    </g>
    <defs>
      <clipPath id="clip0_728_21274">
      <rect width="36" height="36" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

export default RightPointingIcon;