import {useEffect, useState} from "react";
import {DateTime} from "luxon";

export function useCycle(week: 'current' | 'previous' = 'current'): number {
  const [weeks, setWeeks] = useState<number>(1);

  useEffect(() => {
    const dateStartStr = process.env.REACT_APP_CYCLE_START;
    const date = DateTime.fromJSDate(new Date(dateStartStr ? dateStartStr : new Date().getTime()));
    let now = DateTime.now();
    if (week === 'previous') {
      now = now.minus({week: 1});
    }
    const dur = now.diff(date, 'weeks');
    setWeeks(Math.floor(dur.weeks));
  }, [week]);

  return weeks;
}
