import React, {useEffect, useState} from 'react';
import Layout from "../../Layout";
import {useNavigate} from "react-router-dom";
import {useBuyCryptoMutation} from "../../apis/squad.api";
import {useLocation} from "react-router";
import {hasElements} from "../../utils/Helper";
import {InnerHeader} from "../../components/ui/app-inner-header.component";
import {CryptoFilterSelectionElement} from "../../components/crypto-filtered-selection-element.component";
import {CryptoCurrency} from "../../models/Wallet";
import {toastError, toastSuccess} from "../../utils/ToastUtils";
import {useGetMoneyAmountQuery} from "../../apis/game-income.api";
import {useGetCryptosFromIdsQuery} from "../../apis/cryptos.api";
import {QuantityCoinComponent} from "../../components/ui/quantity-coin.component";


const SquadBuyPage: React.FC = () => {
  const {state} = useLocation();
  const {tournamentId, cryptoId} = state;
  const navigate = useNavigate();
  const [newCrypto, setNewCrypto] = useState<CryptoCurrency | null>(null);
  const [amount, setAmount] = useState<number>(0);
  const [disabled, setDisabled] = useState<boolean>(false);
  const {data: money} = useGetMoneyAmountQuery();
  const [buy] = useBuyCryptoMutation();

  const {
    data: _crypto,
    isLoading: isCryptoLoading
  } = useGetCryptosFromIdsQuery({ids: cryptoId ? [cryptoId] : []}, {skip: !cryptoId});

  useEffect(() => {
    if (_crypto && hasElements(_crypto)) {
      setNewCrypto(_crypto[0])
    }
  }, [_crypto])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [newCrypto]);

  return (
    <Layout>
      <InnerHeader pageTitle={"Buy Crypto"} backCallback={() => {
        if (newCrypto) {
          setNewCrypto(null);
        } else {
          navigate(-1);
        }
      }}>
      </InnerHeader>
      <div className={"flex flex-col gap-y-4 max-w-3xl items-center flat-card w-full"}>

        {!newCrypto &&
          <div className={"flex flex-row flex-wrap justify-center w-full"}>
            <CryptoFilterSelectionElement
              selectedCryptos={[]}
              onSelect={(crypto: CryptoCurrency) => {
                setNewCrypto(crypto);
              }}
              onDeselect={(crypto: CryptoCurrency) => {
                setNewCrypto(null);
              }}/>
          </div>
        }

        {(newCrypto && money) &&
          <div className={"flex flex-col gap-x-2 w-full"}>
            <div
              className={"flex justify-start w-full items-center border-b-2 border-b-whitesmoke-300 py-2 mb-4 gap-x-2"}>
              <img src={newCrypto.logo} className={"w-14 h-14"}/>
              <div className={"flex-col"}>
                <h2 className={"text-lg text-start uppercase"}><span
                  className={" text-gray-500 "}>Buy</span> {newCrypto.code}</h2>
                <div className={"text-3xl font-bold"}><QuantityCoinComponent quantity={Number(newCrypto.lastQuote.price)}/></div>
              </div>
            </div>

            <input type={"number"} pattern="[0-9]*" placeholder={"0"} className={"seein-input input"} onChange={(e)=>{
              setAmount(Number(e.target.value));
            }}/>
          </div>
        }

        <div className={"flex gap-x-4 sticky bottom-20  w-full glass-light p-2 rounded-2xl justify-center"}>
          <button className={"primary " + (!disabled && newCrypto && amount > 0 ? '' : 'disabled')}
                  disabled={!newCrypto || disabled || amount === 0}
                  onClick={async () => {
                    if (newCrypto) {
                      gtag('event', 'squadBuyNewCrypto');
                      try {
                        setDisabled(true);
                        await buy({tournamentId, cryptoId: newCrypto?._id, amount})
                      } catch (e) {
                        toastError("Ooops cannot buy crypto, if you have enough coin retry later.");
                      } finally {
                        setDisabled(false);
                      }
                      toastSuccess("Order success !");
                      navigate(-1);
                    }
                  }}> Buy
          </button>
        </div>

      </div>
    </Layout>
  );
};

export {SquadBuyPage}
