import {useLocation} from "react-router-dom";
import {useLayoutEffect} from "react";

export function useScrollIntoView(): void {
  const location = useLocation();

  useLayoutEffect(() => {
    if (location?.state?.scrollToId && !location?.state?.scrollToOffset) {
      setTimeout(() => document.getElementById(location.state.scrollToId)?.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      }), 400);
    }
  }, [location])

}
