import {emptySplitApi} from "./empty.api";


export const pushApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    subscribePush: build.mutation<any, any>({
      query: (bdy) => ({
        url: "/api/push/subscribe",
        method: "POST",
        body: bdy,
      }),
    }),
    unsubscribePush: build.mutation<void, void>({
      query: (bdy) => ({
        url: "/api/push/unsubscribe",
        method: "POST",
        body: bdy,
      }),
    }),
  }),
});

export const {useSubscribePushMutation,useUnsubscribePushMutation} = pushApi;
