export function isProfile(pathname: string) {
  return pathname.startsWith('/collectibles')
}

export function isShop(pathname: string) {
  return pathname.startsWith('/store')
}

export function isLeaderboard(pathname: string) {
  return pathname.startsWith('/leaderboard')
}
