import React, {useEffect, useMemo, useState} from 'react';
import Layout from "../Layout";
import {useGetMyWalletsQuery} from "../apis/wallets.api";
import {isMobile} from 'react-device-detect';
import toast from "react-hot-toast";
import {SecondaryNavigation} from "../components/ui/secondary-navigation.component";
import {useUrlParameterErrorMessage} from "../utils/useUrlParameterError";

const ProfilePage: React.FC = () => {
  const {data: myWallets} = useGetMyWalletsQuery();
  const [singleAddressDrawerState, setSingleAddressDrawerState] = useState<boolean>(false)
  const [connectedWallets, setConnectedWallets] = useState<string[]>([]);
  const wltError = useMemo<string>(() => new URLSearchParams(window.location.search).get('sa_wallet_err') || '', []);
  const [editedWallet, setEditedWallet] = useState<string | undefined>(undefined);

  useUrlParameterErrorMessage('coinbase_connect_err','Impossible to connect to Coinbase. Please ask for assistance on Discord.');

  useEffect(() => {
    if (myWallets) {
      setConnectedWallets(myWallets);
    }
  }, [myWallets]);

  useEffect(() => {
    if (wltError && wltError.length) {
      toast.error(wltError);
    }
  }, [wltError]);

  // useWalletsShepherdTour();

  return (
    <Layout>
      <div className={"flex flex-col gap-4 md:gap-8 justify-center items-center w-full max-w-4xl pb-24"}>

        <SecondaryNavigation navItems={[
          {path: '/profileDetails', name: 'Account'},
          {path: '/profile', name: 'Wallets'},
          {path: '/achievements', name: 'Achievements'},
        ]}/>
        <div className="flex flex-col w-full center flat-card rounded-2xl py-8 px-4 md:px-8 gap-4">
          <div
            className="flex flex-col w-full gap-2 pb-4 border-b-white border-b-solid border-b-[1px] border-opacity-20">
            <h2
              className={"text-left text-2xl w-full"}>
              Connected Wallets
            </h2>
            <p className="text-md text-left ">Connect your wallet to unlock Tanjiblocks' NFTs features</p>
          </div>

          <div
            className={"flex flex-col lg:flex-row justify-center gap-4 w-full"}>
            <div className={"flex flex-col items-center md:w-1/2 gap-4 glass-light rounded-xl p-4"}>
              <h2 className={"step-1 text-center w-full"}>Wallets</h2>
              {/*<ExchangeDex connectedWallets={connectedWallets}/>*/}
              {isMobile &&
                <div className={"mt-1 text-xs"}>We recommend using a computer to connect your wallets because it is
                  easier & faster.</div>
              }
            </div>
            {/*<div className={"flex flex-col items-center md:w-1/2 gap-4 glass-light rounded-xl p-4"}>*/}
            {/*  <h2 className={"text-center w-full"}>Exchanges</h2>*/}
            {/*  <Exchanges connectedWallets={connectedWallets}/>*/}
            {/*</div>*/}
          </div>

          {/*<div className="flex w-full flex-col gap-1 center border-white border-t border-opacity-20 pt-4 -mb-4">*/}
          {/*  <div className="text-xs text-white">Disclaimer: We only read data (unable to do transactions without your*/}
          {/*    permission)*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>

        {/*<div className="flex w-full flex-col center glass-dark rounded-2xl py-8 px-4 md:px-8 gap-4">*/}
        {/*  <div*/}
        {/*    className="flex flex-col w-full gap-2 pb-4 border-b-white border-b-solid border-b-[1px] border-opacity-20">*/}
        {/*    <h2*/}
        {/*      className={"text-left text-2xl w-full"}>*/}
        {/*      Public Wallet Adresses*/}
        {/*    </h2>*/}
        {/*    <p className="text-md text-left max-w-2xl ">Use a public address to add holdings to your crypto*/}
        {/*      portfolio</p>*/}
        {/*  </div>*/}
        {/*  <ConnectedWalletList onClick={(wallet: Wallet) => {*/}
        {/*    setEditedWallet(wallet.name);*/}
        {/*    setSingleAddressDrawerState(true);*/}
        {/*  }}/>*/}

        {/*  <TemporaryDrawer open={singleAddressDrawerState}*/}
        {/*                   anchor={'right'}*/}
        {/*                   buttonClass={"primary"}*/}
        {/*                   title={<><ControlPointIcon/>{"New connection"}</>}*/}
        {/*                   onOpen={() => setSingleAddressDrawerState(true)}*/}
        {/*                   onClose={() => {*/}
        {/*                     setEditedWallet(undefined);*/}
        {/*                     setSingleAddressDrawerState(false)*/}
        {/*                   }}*/}
        {/*  >*/}

        {/*    <div className={"app-drawer app-drawer-right h-full"}>*/}
        {/*      <div className={"lg:w-96 w-56"}>*/}
        {/*        <div className={"app-title app-drawer-title"} onClick={() => setSingleAddressDrawerState(false)}>*/}
        {/*          <KeyboardArrowRightIcon/>  <h3*/}
        {/*          className={"px-4 py-2 w-full text-center"}>{editedWallet ? 'Update wallet' : 'Connect a wallet'}</h3>*/}
        {/*        </div>*/}
        {/*        <SingleAddressWalletConnect*/}
        {/*          onValidate={() => {*/}
        {/*            setSingleAddressDrawerState(false)*/}
        {/*          }}*/}
        {/*          onCancel={() => {*/}
        {/*            setSingleAddressDrawerState(false)*/}
        {/*          }}*/}
        {/*          name={editedWallet}*/}
        {/*        />*/}
        {/*      </div>*/}
        {/*    </div>*/}

        {/*  </TemporaryDrawer>*/}
        {/*</div>*/}

        {/*<div className="flex w-full flex-col center glass-dark rounded-2xl py-8 px-4 md:px-8 gap-4">*/}
        {/*  <div className={"w-full max-w-4xl"}>*/}
        {/*    <h2*/}
        {/*      className={"text-left text-2xl pb-4 border-b-white border-b-solid border-b-[1px] border-opacity-20 w-full"}>*/}
        {/*      Your Portfolio*/}
        {/*    </h2>*/}
        {/*    <div id="form-wallet-container" className="flex w-full items-center flex-col my-4 space-y-3">*/}
        {/*      <WalletsForm/>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    </Layout>
  );
};

export {ProfilePage};