import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {ProductItem} from "../components/shop/buy-product-drawer.component";

interface CartSlice {
  product?: ProductItem | null;
  walletPublicAddress?: string | null,
}


// Define the initial state using that type
const initialState: CartSlice = {product: null, walletPublicAddress: null};

export const cartSlice = createSlice({
  name: 'shopSlice',
  initialState,
  reducers: {
    addToCart: (state, {payload: {product}}: PayloadAction<CartSlice>) => {
      state.product = product;
    },
    registerWalletPublicAddress: (state, {payload: {walletPublicAddress}}: PayloadAction<CartSlice>) => {
      state.walletPublicAddress = walletPublicAddress;
    },
    clearCart: (state) => {
      state.product = null;
      state.walletPublicAddress = null;
    },
  }
})

// Action creators are generated for each case reducer function
export const {addToCart, clearCart, registerWalletPublicAddress} = cartSlice.actions

export default cartSlice.reducer