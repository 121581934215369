import React, {useEffect, useState} from 'react';
import Layout from "../Layout";
import {useGetUserQuery} from "../apis/users.api";
import 'react-confirm-alert/src/react-confirm-alert.css';
import {InnerHeader} from "../components/ui/app-inner-header.component";
import {loadStripe} from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import {useLocation} from "react-router-dom";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// This is your test public API key.
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);

const CheckoutForm: React.FC = () => {

  const location = useLocation();

  const [clientSecret, setClientSecret] = useState((location.state && location.state.clientSecret) ? location.state.clientSecret : '');

  // useEffect(() => {
  //   if(clientSecret){
  //     console.log("client secret : ", clientSecret);
  //   }
  // }, [clientSecret]);

  return (
    <div id="checkout" className={"w-full"}>
      {clientSecret && (
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={{clientSecret}}
        >
          <EmbeddedCheckout/>
        </EmbeddedCheckoutProvider>
      )}
    </div>
  )
}

const CheckoutPage: React.FC = () => {
  const {data: user} = useGetUserQuery();

  useEffect(() => {
    if (user) {
    }
  }, [user]);

  return (
    <div className='h-screen'>
      <div className="fixed w-screen h-64 from-5% to-60% bg-gradient-to-b from-orange-700 to-orange">
      </div>

      <div className="fixed z-[100] h-screen w-screen top-12 p-10 items-center flex flex-col gap-8 pb-[114px]">

        <InnerHeader pageTitle={`Checkout`}>
          <></>
        </InnerHeader>

        <div className='z-[100] overflow-y-scroll w-screen'>
          <CheckoutForm/>
        </div>
      </div>
  </div>
  );
};

export {CheckoutPage};