import React, { useState } from 'react'
import { AddIcon } from '../ui/add-icon';
import { PlayerItem } from '../../models/User';
import { XIcon } from '../ui/x-icon';
import { toastSuccess } from '../../utils/ToastUtils';

export interface SocialSharingProps {
  player: PlayerItem,
  sharingText: string,
}

export const SocialSharingComponent: React.FC<SocialSharingProps> = (options) => {

  return <>

    <div className={"flex gap-x-4 justify-center pb-1"}>
      <a
        href={`https://twitter.com/intent/tweet?timestamp=${new Date().getTime()}
        &url=${encodeURIComponent(`${process.env.REACT_APP_ROOT_URL}/pstats/${options.player.pseudo}?tbrefid=${options.player?.userId}&rdt=${new Date().getTime()}&share=x`)}
        &text=${encodeURIComponent(options.sharingText)}`}
        data-size="large">
        <div className={"orange-button"}>
          <XIcon h={16}/>
        </div>
      </a>


      <div className={"orange-button"} onClick={async () => {
        //for standard sharing (insta, fb, discord) we use portrait
        const response = await fetch(options.player?.scorePortraitSnapshotUrl || `${process.env.REACT_APP_STATIC_ASSETS}/favicon-orange.png`);
        const blob = await response.blob();
        const filesArray = [
          new File([blob], `score.webp`, {
            type: 'image/webp',
            lastModified: new Date().getTime(),
          }),
        ];

        const shareData = {
          title: 'Check my score! ',
          url: `${process.env.REACT_APP_ROOT_URL}/pstats/${options.player.pseudo}?tbrefid=${options.player?.userId}`,
          text: options.sharingText,
          files: filesArray,
        };

        if (navigator.clipboard) {
          try {
            await navigator.clipboard.writeText(options.sharingText + ` ${process.env.REACT_APP_ROOT_URL}/pstats/${options.player.pseudo}?tbrefid=${options.player?.userId}`);
            toastSuccess('Sharing text copied to clipboard!');
          } catch (e) {
            // toastError(`Error :${e}`);
          }
        }

        if (navigator.canShare && navigator.canShare(shareData)) {
          await navigator.share(shareData);
        }
      }}><AddIcon h={16}/></div>
    </div>
  
  
  </>

}