import React from "react";
import {PlayerItem, PlayerListResponse} from "../../models/User";
import {classNames, hasElements} from "../../utils/Helper";
import {Podium2ndIcon} from "../ui/podium-2nd-icon";
import {Podium1stIcon} from "../ui/podium-1st-icon";
import {Podium3rdIcon} from "../ui/podium-3rd-icon";
import {QuantityCoinComponent} from "../ui/quantity-coin.component";
import {useNavigate} from "react-router-dom";
import {CollectibleRewardComponent} from "./collectible-reward.component";

export const getFirstPlayerItemsComp = (player: PlayerItem, collectibleRewardRank: number = 0, imageSize: number = 12) => {
  if (player) {
    return <div className={"flex flex-col items-center gap-y-2 text-inherit"}>
      <div
        className={classNames(`h-${imageSize} w-${imageSize}`, "relative bg-white p-1 rounded fat-grey-shadow-2 border border-grey-100 justify-start items-start gap-4 inline-flex")}>
        <img src={player.avatarUrl || `${process.env.REACT_APP_STATIC_ASSETS}/favicon.svg`}
             className={classNames("object-cover w-full h-full", " bg-white")}/>

        {!!(collectibleRewardRank && collectibleRewardRank > 0 && Number(player.rank) <= collectibleRewardRank) &&
          <div className={"absolute -bottom-2 -right-4"}>
            <CollectibleRewardComponent widthCss={"w-6"}/>
          </div>
        }
      </div>
      <div
        className={"text-white !text-inherit text-sm font-bold font-['Satoshi'] leading-[17px] max-w-[103px] overflow-ellipsis overflow-hidden break-keep"}>{player.pseudo}</div>
      <div className="text-white !text-inherit text-sm">
        <QuantityCoinComponent size={12} quantity={player.score}/>
      </div>
    </div>;
  } else return <></>
}


const PodiumComponent: React.FC<{
  firstPlayersData?: PlayerListResponse,
  navigation: boolean,
  collectibleRewardRank?: number,
  onClick?: Function,
}> = ({
        firstPlayersData,
        navigation, collectibleRewardRank, onClick
      }) => {
  const nav = useNavigate();
  return <>
    {(firstPlayersData && hasElements(firstPlayersData.players)) &&
      <div className={`flex justify-center items-end gap-x-2 p-2 ${navigation ? 'cursor-pointer' : ''} text-center`}>
        {firstPlayersData.players[1] &&
          <div className={"text-white flex flex-col justify-center gap-y-2"}
               onClick={() => {
                 if (onClick) {
                   onClick(firstPlayersData.players[1].pseudo);
                 }
                 // navigation && nav(`/collectibles/${firstPlayersData.players[1].pseudo}`)
               }}>
            <div className={"text-white"}>
              {getFirstPlayerItemsComp(firstPlayersData.players[1], collectibleRewardRank)}
            </div>
            <Podium2ndIcon/>

          </div>
        }
        {firstPlayersData.players[0] &&
          <div className={` text-white  flex flex-col justify-center gap-y-2 ${navigation ? 'cursor-pointer' : ''}`}
               onClick={() => {
                 if (onClick) {
                   onClick(firstPlayersData.players[0].pseudo);
                 }
                 // navigation && nav(`/collectibles/${firstPlayersData.players[0].pseudo}`);
               }}>
            <div className={"text-white"}>
              {getFirstPlayerItemsComp(firstPlayersData.players[0], collectibleRewardRank, 16)}
            </div>
            <div><Podium1stIcon/></div>

          </div>
        }
        {firstPlayersData.players[2] &&
          <div className={`text-white  flex flex-col justify-center gap-y-2 ${navigation ? 'cursor-pointer' : ''}`}
               onClick={() => {
                 if (onClick) {
                   onClick(firstPlayersData.players[2].pseudo);
                 }
                 // navigation && nav(`/collectibles/${firstPlayersData.players[2].pseudo}`)
               }}>
            <div className={"text-white"}>
              {getFirstPlayerItemsComp(firstPlayersData.players[2], collectibleRewardRank)}
            </div>
            <div><Podium3rdIcon/></div>
          </div>
        }
      </div>
    }
  </>
}

export {PodiumComponent};