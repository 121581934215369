import {emptySplitApi} from "./empty.api";
import {CryptoCurrency} from "../models/Wallet";
import {PriceRange} from "../models/PriceRange";

export const cryptosApi = emptySplitApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getCryptos: build.query<CryptoCurrency[], undefined | {
      cryptoIds?: string[],
      excludedCryptoIds?:string[],
      filter: string,
      pageIndex: number,
      pageSize: number
    }>({
      query: (params) => ({
        url: "/cryptos/getCryptos",
        method: "GET",
        params,
      }),
    }),
    getCryptosFromIds: build.query<CryptoCurrency[], { ids: any[] }>({
      query: (params) => ({
        url: "/cryptos/getCryptosFromIds",
        method: "GET",
        params,
      }),
    }),
    getCryptosPrices: build.query<Array<{ price: number, date: string }>, {
      cryptoId: string,
      range: PriceRange
    }>({
      query: (params) => ({
        url: "/cryptos/prices",
        method: "GET",
        params,
      }),
    }),
  }),
});

export const {
  useGetCryptosQuery,
  useGetCryptosFromIdsQuery, useGetCryptosPricesQuery,
} = cryptosApi;