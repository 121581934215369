import React, {useEffect, useState} from 'react'
import TemporaryDrawer from "../ui/temp-drawer.component";
import {enableBodyScroll} from "../../utils/HtmlHelper";
import * as _ from "lodash";
import {BoostItem, ShieldItem} from "../shop/gems-buyable-items.component";
import {UserItem} from "../../models/User";

interface Props {
  inventory: UserItem[],
  open: boolean
  onOpen?: Function;
  onClose: Function;
  onItemChosen?: Function
}

const ItemsSelectionDrawer: React.FC<Props> = ({
                                                 inventory,
                                                 open,
                                                 onOpen = _.noop,
                                                 onClose = _.noop,
                                                 onItemChosen = _.noop
                                               }) => {
  const [_open, _setOpen] = useState(open);
  useEffect(() => {
    _setOpen(open)
  }, [open]);

  return <TemporaryDrawer
    title={'Power ups'}
    closeWhenClickingOutside={true}
    anchor={'bottom'}
    open={open}
    lineIcon={false}
    onOpen={() => {
    }}
    onClose={() => {
      enableBodyScroll();
      onClose();
    }}
    size="auto">
    <div className={'flex flex-wrap gap-y-2 gap-x-2 justify-center items-center'}>
      {inventory && _.uniqBy(_.sortBy(inventory, (i) => Number(i?.metadata?.value || 0)), (i) => i.code).map((i) =>
        <div className={"flex flex-col items-center cursor-pointer"}
             onClick={() => {
               gtag('event', 'onInventoryItemChosen', {inventory_item_code: i.code});
               onItemChosen(i);
               enableBodyScroll();
               onClose()
             }}>

          {i.subFamily === 'shield' &&
            <ShieldItem h={48} item={i} iconElement={<div
              className={"text-lg !text-opacity-70 font-['BIGSHOULDER']"}>x{inventory.filter(i2 => i2.code === i.code).length}</div>}/>
          }
          {i.subFamily === 'bet_boost' &&
            <BoostItem h={48} item={i} iconElement={<div
              className={"text-lg !text-opacity-70 font-['BIGSHOULDER']"}>x{inventory.filter(i2 => i2.code === i.code).length}</div>}/>
          }
        </div>)}
    </div>
  </TemporaryDrawer>
};
export {ItemsSelectionDrawer};