import {useEffect, useMemo} from "react";
import toast from "react-hot-toast";

export function useUrlParameterErrorMessage(name: string, errorMessageOverride?: string): void {
  const coinbaseConnectError = useMemo<string>(() => new URLSearchParams(window.location.search).get(`${name}`) || '', []);
  useEffect(() => {
    if (coinbaseConnectError && coinbaseConnectError.length) {
      errorMessageOverride ? toast.error(errorMessageOverride) : toast.error(coinbaseConnectError);
    }
  }, [coinbaseConnectError]);
}
