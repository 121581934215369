import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const canvasApi = createApi({
    reducerPath: "canvasApi",
    baseQuery: fetchBaseQuery({
        baseUrl: '/canvas'
    }),
    endpoints: (build) => ({
        getCanvasVideo: build.query<any, undefined>({
            query: () => ({
                url: "/"
            }),
        }),
    }),
});

export const {useGetCanvasVideoQuery } = canvasApi;