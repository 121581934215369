import React from "react";


const GearIcon: React.FC<{ h?: number, className?:string }> = ({h = 20, className="stroke-white"}) =>
  <svg width={h} height={h} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <g clipPath="url(#clip0_2537_5937)">
      <path
        d="M10 13.125C11.7259 13.125 13.125 11.7259 13.125 10C13.125 8.27411 11.7259 6.875 10 6.875C8.27411 6.875 6.875 8.27411 6.875 10C6.875 11.7259 8.27411 13.125 10 13.125Z"
        stroke="" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path
        d="M3.23594 13.9133C2.8906 13.3185 2.62604 12.6803 2.44922 12.0156L3.76016 10.375C3.74531 10.1242 3.74531 9.87271 3.76016 9.62189L2.45 7.98126C2.62652 7.31648 2.89054 6.67809 3.23516 6.08283L5.32187 5.84845C5.48851 5.66071 5.66617 5.48305 5.85391 5.31642L6.08828 3.23048C6.68269 2.88751 7.32004 2.62505 7.98359 2.45001L9.62422 3.76095C9.87504 3.74611 10.1265 3.74611 10.3773 3.76095L12.018 2.45079C12.6827 2.62731 13.3211 2.89134 13.9164 3.23595L14.1508 5.32267C14.3385 5.4893 14.5162 5.66696 14.6828 5.8547L16.7687 6.08908C17.1141 6.68389 17.3786 7.32206 17.5555 7.98673L16.2445 9.62736C16.2594 9.87818 16.2594 10.1297 16.2445 10.3805L17.5547 12.0211C17.3794 12.6857 17.1167 13.3241 16.7734 13.9195L14.6867 14.1539C14.5201 14.3417 14.3424 14.5193 14.1547 14.686L13.9203 16.7719C13.3255 17.1172 12.6873 17.3818 12.0227 17.5586L10.382 16.2477C10.1312 16.2625 9.87973 16.2625 9.6289 16.2477L7.98828 17.5578C7.32369 17.3825 6.68531 17.1198 6.08984 16.7766L5.85547 14.6899C5.66773 14.5232 5.49007 14.3456 5.32344 14.1578L3.23594 13.9133Z"
        stroke="" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_2537_5937">
        <rect width="20" height="20" fill="white"/>
      </clipPath>
    </defs>
  </svg>;

export {GearIcon};