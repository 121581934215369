import {useClaimReferralRewardWithOptimismMutation} from "../../apis/users.api";
import { GemsIcon } from "../ui/gems-icon";

import { RewardType } from "./profile-referral-rewards.component";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { useRef } from "react";
import useGemsCounterAnimation from "../../hooks/useGemsCounterAnimation";

import {animate} from "../../slices/gemSlice";

interface FanReferralRewardProps {
  step: number,
  type: RewardType,
  rewardAmount: number,
  goal: number,
  total: number,
  totalRewarded: number,
}

const FanReferralReward: React.FC<FanReferralRewardProps> = (props) => {

  const [claimReward] = useClaimReferralRewardWithOptimismMutation();
  const dispatch = useAppDispatch();
  const animationIsPlaying = useAppSelector((state: RootState) => state.gems.isPlaying);
  const spawnElementRef = useRef<HTMLDivElement>(null);
  const hasClaimed = useRef(false);
  const { incrementCounter } = useGemsCounterAnimation();


  async function onUserClaim(amount: number) {

    if(animationIsPlaying){
      return;
    }

    hasClaimed.current = true;

    const animParams = {
      spawnPoint: {
        x: 0,
        y: 0
      }
    };

    if(spawnElementRef.current){
      const domRect = spawnElementRef.current.getBoundingClientRect();
      animParams.spawnPoint.x = domRect.x + domRect.width / 2;
      animParams.spawnPoint.y = domRect.y - domRect.height;
    }

    dispatch(animate(animParams));
    incrementCounter(amount, ()=>{claimReward({amount: amount})});

  }

  return (
    <>

      {/* Breadcrumb */}
      <div
        className={`h-8 w-8 border-r  ${props.totalRewarded == props.total ? 'border-r-orange' : 'border-r-grey-100'}`}>
      </div>

      {/* Reward */}
      <div
        className={`min-h-[68px] flat-card flex gap-2 ${props.totalRewarded == props.total ? '' : ''}`}>
        <div className="flex-none content-center">

          <GemsIcon h={32}/>

        </div>
        <div className="flex-auto content-center">
          <p
            className={`font-bold text-xs ${props.totalRewarded == props.total ? "hidden" : ""}`}
          >
            {props.rewardAmount} Gems
          </p>
          <p
            className={`font-bold text-xs ${props.totalRewarded == props.total ? "" : "hidden"}`}
          >
              {/* All gems have been claimed */}
          </p>
          <p
            className={`font-normal text-xs text-opacity-60 ${props.totalRewarded == props.total ? "hidden" : ""}`}
          >
            You referred {props.goal} new { props.goal > 1 ? 'friends': 'friend' }
          </p>
          <p
            className={`font-normal text-xs ${props.totalRewarded == props.total ? "" : "hidden"}`}
          >
            Continue to invite friends
          </p>
        </div>

        <div className="flex-none content-center">

          {/* Achived but not claimed */}
          {props.total != props.totalRewarded &&

            <>
              {/* Next one to claim */}
              <div onClick={()=>{onUserClaim(props.rewardAmount)}} ref={spawnElementRef}
                className="orange-button text-white text-xs font-bold">
                { hasClaimed.current ? "Claiming" : "Claim" }
              </div>
            </>

          }

          {/* Achived and claimed */}
          {props.total == props.totalRewarded &&

            <div className="border border-orange text-orange p-2 rounded-md text-xs font-bold">
              Claimed
            </div>

          }

        </div>
      </div>
    </>
  );
}

export default FanReferralReward;