import React, {useState} from "react";
import {classNames} from "../../utils/Helper";
import TemporaryDrawer from "../ui/temp-drawer.component";
import {useNavigate} from "react-router-dom";
import {BetsIcon} from "../svg/bets-icon";

const BetInfoComponent: React.FC<{
  widthCss?: string,

}> = ({widthCss = 'w-8'}) => {

  const nav = useNavigate();
  const [showDrawer, setShowDrawer] = useState(false);

  return (
    <React.Fragment>

      <div className={classNames("relative", widthCss)} onClick={(e) => {
        setShowDrawer(true);
        e.stopPropagation();
      }}>

        <div className="nav-bar-button  w-8 h-[30px] center"> i</div>

      </div>

      <TemporaryDrawer title={`Bet`} closeWhenClickingOutside={true} anchor={'bottom'} size={'auto'}
                       open={showDrawer}
                       lineIcon={false}
                       onClose={() => {
                         setShowDrawer(false);
                       }}>
        <div className={"w-full h-full flex flex-col justify-between items-center py-2 overflow-y-auto"}>
          <BetsIcon/>

          <div className={"flex flex-col gap-y-4"}>
            <p className={"font-normal text-center"}>
              “Bet” page is dedicated to betting Tanjicoins in two main games.
            </p>

            <p className={"font-normal text-center"}>
              Either you bet on the rise (bullish) or fall (bearish) of a certain crypto price for a
              certain period of time
            </p>

            <p className={"font-normal text-center"}>
              Either you compare the performance of two crypto prices and bet on which one will perform
              the best for a given period of time.
            </p>

            <p className={"font-normal text-center"}>
              Bet Winning STREAK unlock odd boost that will be applied on the next winning bet. The longer is the
              winning streak the higher is the boost.
            </p>
          </div>

        </div>
      </TemporaryDrawer>

    </React.Fragment>
  )
}

export {BetInfoComponent};