import React from "react";


const UpGreenIcon: React.FC<{ h?: number }> = ({h = 10}) =><svg width={h} height={h} viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M4 6H1.20711C0.761654 6 0.53857 5.46143 0.853553 5.14645L3.64645 2.35355C3.84171 2.15829 4.15829 2.15829 4.35355 2.35355L7.14645 5.14645C7.46143 5.46143 7.23835 6 6.79289 6H4Z" fill="#39C16C"/>
</svg>


export {UpGreenIcon};

const UpWhiteIcon: React.FC<{ h?: number }> = ({h = 10}) =><svg width={h} height={h} viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M4 6H1.20711C0.761654 6 0.53857 5.46143 0.853553 5.14645L3.64645 2.35355C3.84171 2.15829 4.15829 2.15829 4.35355 2.35355L7.14645 5.14645C7.46143 5.46143 7.23835 6 6.79289 6H4Z" fill="white"/>
</svg>


export {UpWhiteIcon};