import {createSlice, PayloadAction} from '@reduxjs/toolkit'


// Define the initial state using that type
const initialState:{claimed:string[]} = {claimed: []};

export const incomeSlice = createSlice({
  name: 'incomeSlice',
  initialState,
  reducers: {
    claimReducer: (state, action: PayloadAction<string>) => {
      state.claimed.push(action.payload);
    },
  }
})

// Action creators are generated for each case reducer function
export const {claimReducer} = incomeSlice.actions

export default incomeSlice.reducer