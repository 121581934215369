import React, {useEffect, useState} from 'react';
import Layout from "../Layout";
import {useParams} from "react-router-dom";
import {useGetNextTournamentsQuery} from "../apis/tournaments.api";
import {classNames, cssHidden, formatBalanceNumber, formatGainPercent, hasElements} from "../utils/Helper";
import {useGetSquadAnalyticsQuery} from "../apis/squad.api";
import {getVariationRate} from "../utils/MathsUtils";
import {InnerHeader} from "../components/ui/app-inner-header.component";
import {useGetCryptosFromIdsQuery, useGetCryptosPricesQuery} from "../apis/cryptos.api";
import {CryptoCurrency} from "../models/Wallet";
import {SquadSimplifiedPositions} from "../components/squad/squad-simple-tx.component";
import {SquadAggrPositions} from "../components/squad/squad-aggr-tx.component";
import {ActionButtons} from "../components/ui/action-buttons";
import {PriceRange} from "../models/PriceRange";
import {AreaChart} from "../components/profile/area-chart.component";
import * as _ from "lodash";
import {QuantityCoinComponent} from "../components/ui/quantity-coin.component";

const PortfolioCryptoPage: React.FC = () => {

  const {cryptoId} = useParams<{ cryptoId: string }>();
  const [crypto, setCrypto] = useState<CryptoCurrency>();
  const [hideActionButton, setHideActionButton] = useState<boolean>(false);

  const {
    data: _crypto,
    isLoading: isCryptoLoading
  } = useGetCryptosFromIdsQuery({ids: cryptoId ? [cryptoId] : []}, {skip: !cryptoId});

  useEffect(() => {
    if (_crypto && _crypto.length) {
      setCrypto(_crypto[0]);
    }
  }, [_crypto]);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const hideActionButtonValue = urlParams.get('hideActionButtons') || false;
    if (hideActionButtonValue) {
      setHideActionButton(true);
    } else {
      setHideActionButton(false);
    }
  }, [hideActionButton]);

  const {
    data: portfolio,
    isLoading: areNextLoading
  } = useGetNextTournamentsQuery({count: 1, type: 'squad'});

  const {data: scorePayload, isLoading: areAnalyticsLoading} = useGetSquadAnalyticsQuery({
    tournamentId: portfolio ? portfolio[0].id : ''
  }, {skip: !portfolio || !portfolio[0], refetchOnFocus: true, refetchOnMountOrArgChange: 120});


  const [range, setRange] = useState<PriceRange>('1W');
  const {data: prices, isFetching: arePricesLoading} = useGetCryptosPricesQuery({
    cryptoId: cryptoId || '',
    range
  }, {skip: !cryptoId, refetchOnFocus: true, refetchOnMountOrArgChange: 120});

  const [seriesData, setSeriesData] = useState<Array<{ x: number, y: number }>>([]);

  useEffect(() => {
    if (prices && hasElements(prices)) {
      let sd = []
      for (const p of prices) {
        sd.push({x: new Date(p.date).getTime(), y: Number(p.price)});
      }
      if (sd.length === 1) {
        const d = _.cloneDeep(sd[0]);
        d.x += 1000 * 60 * 60;
        sd.push(d);
      }
      setSeriesData(sd);
    }

  }, [prices])

  const [pricePerf, setPricePerf] = useState<number>(0);
  const [priceDiff, setPriceDiff] = useState<number>(0);

  useEffect(() => {
    if (prices && hasElements(prices)) {
      const perf = getVariationRate(prices[0].price, prices[prices.length - 1].price) * 100;
      setPricePerf(perf);
      setPriceDiff(prices[prices.length - 1].price - prices[0].price);
    }
  }, [prices]);

  const [txPanelVisible, setTxPanelVisible] = useState(false);
  useEffect(() => {
    if (scorePayload && !cryptoId) {
      setTxPanelVisible(true);
    } else if (scorePayload && cryptoId) {
      if (scorePayload.txs.find(t => t.cryptoId === cryptoId)) {
        setTxPanelVisible(true);
      }
    }
  }, [scorePayload, cryptoId]);

  return (
    <Layout classes={"min-h-screen justify-end"}>
      <>
        {portfolio && portfolio[0] && portfolio[0].type === 'squad' && portfolio[0].status === 2 &&
          <>
            <div
              className="fixed w-screen h-screen top-12 pt-10 p-4 pb-16 items-center flex flex-col flex flex-col bg-gradient-to-b from-orange-700 to-orange gap-8">
              <div className="w-full max-w-md center flex flex-col">

                <div className="w-full max-w-md md:px-4">
                  <InnerHeader pageTitle={`${crypto && crypto.code}`}><></>
                  </InnerHeader>
                </div>

                <div className="flex w-full flex-col gap-2 center mt-5">
                  <h2 className="text-white text-opacity-80 text-xs font-bold text-center">{crypto?.code} Price</h2>

                  <div className="text-white text-[32px] font-bold  leading-none center gap-2">
                    <QuantityCoinComponent quantity={Number(crypto?.lastQuote?.price || 0)} textCssSize={"text-[32px]"}/>
                  </div>

                  <div className="justify-center items-center gap-2 inline-flex">

                    <div className="grow shrink basis-0 justify-end items-center gap-0.5 flex">
                      <div className="w-2 h-2 pt-0.5 flex-col justify-start items-center inline-flex"/>
                      <div className="text-white text-opacity-80 text-sm font-bold ">
                        {formatGainPercent(pricePerf)}
                      </div>
                    </div>

                    <div className="justify-center items-center gap-0.5 flex">
                      <div className="text-white text-opacity-30 text-sm font-bold ">|</div>
                    </div>

                    <div className="text-white text-opacity-80 text-sm font-bold ">
                      {(formatBalanceNumber(priceDiff))}
                    </div>
                  </div>
                </div>


                <div className={"w-full"}>
                  <AreaChart areSeriesLoading={arePricesLoading} series={[{
                    name: 'Prices',
                    data: arePricesLoading ? [] : seriesData,
                  }]}/>
                </div>

                <div className={"flex w-full gap-x-6 cursor-pointer justify-center mb-5"}>
                <span className={classNames("time-range-selector", range === '1H' ? 'selected' : '')}
                      onClick={() => setRange('1H')}>1H</span>
                  <span className={classNames("time-range-selector", range === '8H' ? 'selected' : '')}
                        onClick={() => setRange('8H')}>8H</span>
                  <span className={classNames("time-range-selector", range === '1D' ? 'selected' : '')}
                        onClick={() => setRange('1D')}>1D</span>
                  <span className={classNames("time-range-selector", range === '1W' ? 'selected' : '')}
                        onClick={() => setRange('1W')}>1W</span>
                  <span className={classNames("time-range-selector", range === '1M' ? 'selected' : '')}
                        onClick={() => setRange('1M')}>1M</span>
                </div>

                {!hideActionButton &&
                  <ActionButtons tournamentId={portfolio[0].id} hideButtons={['Skills']} cryptoId={cryptoId}/>
                }

              </div>
            </div>

            <div
              className={classNames("relative mt-[23rem] bg-white w-screen flex flex-col max-w-md gap-y-4 p-6 rounded-tl-2xl rounded-tr-2xl min-h-[30rem]")}>
              <div className={classNames("w-full", cssHidden(!txPanelVisible))}>
                <SquadAggrPositions tournament={portfolio[0]} cryptoId={cryptoId}/>
              </div>
              <div className={classNames(" w-full border-b-grey-100 border-b pb-3", cssHidden(!txPanelVisible))}>
                <SquadSimplifiedPositions tournament={portfolio[0]} cryptoId={cryptoId} title={"History"}/>
              </div>

              <div className={classNames("w-full", cssHidden(!crypto?.description))}>
                <h3 className={"text-sm w-full text-center"}>{`About ${crypto?.code}`}</h3>
                <p className={"h-auto font-normal"}>
                  {crypto?.description}
                  {/*Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.*/}
                </p>
              </div>
            </div>
          </>
        }

        {portfolio && portfolio[0] && portfolio[0].type === 'squad' && portfolio[0].status === 1 &&
          <div
            className={classNames(" max-w-3xl w-full items-center flex flex-col gap-6")}>
            Wallet detail is not available yet.
          </div>
        }
      </>
    </Layout>
  );
};

export {
  PortfolioCryptoPage
}