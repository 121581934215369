import React from 'react';

const RocketIcon: React.FC<{ h?: number }> = ({h}) => (
  <svg width={h} height={h} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1089_41822)">
      <path d="M0.666992 11.3333L6.00033 6.66666L16.667 7.33332L17.3337 18L12.667 23.3333C12.667 23.3333 12.6677 19.334 8.66699 15.3333C4.66633 11.3327 0.666992 11.3333 0.666992 11.3333Z" fill="#A0041E" />
      <path d="M0.648451 23.3333C0.648451 23.3333 0.624451 18.014 2.63845 16C4.65245 13.986 9.99978 14.1247 9.99978 14.1247C9.99978 14.1247 9.99912 19.3333 7.99912 21.3333C5.99912 23.3333 0.648451 23.3333 0.648451 23.3333V23.3333Z" fill="#FFAC33" />
      <path d="M5.99967 20.6667C7.47243 20.6667 8.66634 19.4728 8.66634 18C8.66634 16.5273 7.47243 15.3333 5.99967 15.3333C4.52691 15.3333 3.33301 16.5273 3.33301 18C3.33301 19.4728 4.52691 20.6667 5.99967 20.6667Z" fill="#FFCC4D" />
      <path d="M23.9989 0C23.9989 0 17.3323 0 9.33227 6.66667C5.33227 10 5.33227 16 6.6656 17.3333C7.99893 18.6667 13.9989 18.6667 17.3323 14.6667C23.9989 6.66667 23.9989 0 23.9989 0Z" fill="#55ACEE" />
      <path d="M17.9996 3.33334C16.9176 3.33334 15.9909 3.98068 15.5723 4.90668C15.9069 4.75534 16.2756 4.66668 16.6663 4.66668C18.1389 4.66668 19.3329 5.86068 19.3329 7.33334C19.3329 7.72401 19.2443 8.09268 19.0936 8.42668C20.0196 8.00868 20.6663 7.08201 20.6663 6.00001C20.6663 4.52734 19.4723 3.33334 17.9996 3.33334V3.33334Z" fill="black" />
      <path d="M5.33301 18.6667C5.33301 18.6667 5.33301 16 5.99967 15.3333C6.66634 14.6667 14.667 8.00067 15.333 8.66667C15.999 9.33267 9.33234 17.3333 8.66567 18C7.99901 18.6667 5.33301 18.6667 5.33301 18.6667Z" fill="#A0041E" />
    </g>
    <defs>
      <clipPath id="clip0_1089_41822">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default RocketIcon;
