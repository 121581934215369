import { Tournament, canParticipate, hasTournamentEnded, isTournamentRunning } from "../../../models/Tournament";
import { CryptoCurrency } from "../../../models/Wallet";
import { classNames, formatBalanceNumber } from "../../../utils/Helper";
import BearishIcon from "../../svg/BearishIcon";
import BullishIcon from "../../svg/BullishIcon";
import { DownRedIcon, DownWhiteIcon } from "../../ui/down-red-icon";
import { UpGreenIcon, UpWhiteIcon } from "../../ui/up-green-icon";

interface BearishBullishItemProps {
  crypto: CryptoCurrency,
  tournament: Tournament,
  isGoodChoice: Function,
  choseCallback?: Function,
  choiceDone: boolean,
  choice?: string;
  odd?: number,
  type: string
}

const BearishBullishItem: React.FC<BearishBullishItemProps> = ({
  crypto,
  tournament,
  isGoodChoice,
  choseCallback = () => {},
  choiceDone,
  choice,
  odd = 1.1,
  type
}) => {

  const isRunning = isTournamentRunning(tournament);
  const hasEnded = hasTournamentEnded(tournament);

  const imGood = isGoodChoice();
  const imBad = !isGoodChoice();

  const imSelectedOne = (choice == "up" && type == "bullish" ) || (choice == "down" && type == "bearish" );

  const getCssColor = () => {

    // no choice
    if(!choiceDone && !choice){
      return 'bg-white text-black';
    }

    // selected
    if(imSelectedOne && !choiceDone && choice ){
      return 'bg-orange !border-orange shadow-orange text-white';
    }

    // Winning
    if(imSelectedOne && imGood && (isRunning || hasEnded)){
      return 'bg-victory-green text-white';
    }

    // Losing
    if(imSelectedOne && imBad && (isRunning || hasEnded)){
      return 'bg-red-500 text-white';
    }

  }

  const perfCssColor = () => {

    // no choice or not selected
    if((!choiceDone && !choice) || !imSelectedOne){
      return 'bg-orange text-white';
    }

    // selected
    if(imSelectedOne && !choiceDone && choice ){
      return 'bg-white text-orange';
    }

    // Winning
    if(imSelectedOne && imGood && (isRunning || hasEnded)){
      return 'bg-white text-victory-green';
    }

    // Losing
    if(imSelectedOne && imBad && (isRunning || hasEnded)){
      return 'bg-white text-red-500 ';
    }

  }

  return <div className={classNames("mt-8 relative w-full flex flex-col gap-y-2 flat-card items-center justify-center", (!choiceDone && canParticipate(tournament)) ? 'cursor-pointer' : 'shadow-none', getCssColor())}
    onClick={() => {
      if (!choiceDone && canParticipate(tournament))
        choseCallback(crypto)
    }}>

    {/* ICON */}
    <div className="absolute -top-8 w-12 h-12 p-[3px] bg-white rounded-full fat-grey-shadow-2 border border-grey-100 justify-start items-start gap-4 inline-flex">
      <div className="w-full h-full justify-center items-center flex relative scale-110">
        { type === "bearish" && <BearishIcon/> }
        { type === "bullish" && <BullishIcon/> }
      </div>
    </div>

    <div className={"flex flex-col text-center text-inherit gap-y-1 py-2"}>

      <div className={classNames("font-medium text-inherit flex flex-col justify-center items-center")}>

        <div className={`flex gap-1 center ${type==="bearish" ? "": "hidden"}`}>

          <p className={`${ imSelectedOne ? "text-white" : "text-black"} `}>
            Bearish
          </p>

          {
            imSelectedOne ? <DownWhiteIcon h={16}/> : <DownRedIcon h={16}/>
          }

        </div>

        <div className={`flex gap-1 center ${type==="bullish" ? "": "hidden"}`}>

          <p className={`${ imSelectedOne ? "text-white" : "text-black"} `}>
            Bullish
          </p>

          {
            imSelectedOne ? <UpWhiteIcon h={16}/> : <UpGreenIcon h={16}/>
          }

        </div>

      </div>

      <div className={`text-center flex justify-center items-center p-1 px-4 rounded-full w-20 ${perfCssColor()} `}>
        <p className={`text-inherit`}>
          <span className={"text-xs mr-1"}>x</span>
          {formatBalanceNumber(odd)}
        </p>
      </div>

    </div>
  </div>
}

export default BearishBullishItem;