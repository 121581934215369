import React from "react";
import { PlayerItem } from "../../models/User";
import { QuantityCoinComponent } from "../ui/quantity-coin.component";
import { QuantityGemsComponent } from "../ui/quantity-gems.component";
import { useNavigate } from "react-router-dom";
import { GemsIcon } from "../ui/gems-icon";
import CheckIcon from "../svg/Check";


interface ReferredFriendCardProps {
  picture: string,
  pseudo: string,
  cumulatedGems: number
}

export const ReferredFriendCard: React.FC<ReferredFriendCardProps> = (friend: ReferredFriendCardProps) => {

  const nav = useNavigate();

  return (

    <div className={"flat-card w-full flex items-center gap-y-2 gap-x-1"}
        onClick={() => {
          nav(`/stats/${friend.pseudo}`);
    }}>

      <div className={"flex-none min-w-0"}>

        <span className={"w-12 h-12 p-0.5 bg-white rounded fat-grey-shadow-1 border border-neutral-200 justify-start items-start gap-4 inline-flex"}>
          <img src={friend.picture || `${process.env.REACT_APP_STATIC_ASSETS}/favicon.svg`}
              className="w-full h-full rounded-sm bg-white" />
        </span>

      </div>

      <div className={"flex-auto h-100 min-w-0 truncate"}>
        {/* <div className={"flex shrink-0"}> */}
          <span className="font-bold">{friend.pseudo}</span>
          {/* <span className="font-bold break-all truncate overflow-hidden">{friend.pseudo}</span> */}
        {/* </div> */}
      </div>

      <div className={"flex-none min-w-0"}>
        <div className={"w-full flex flex-col"}>

          {
            friend.cumulatedGems < 5 ?
            <div className={"w-full flex justify-end"}>
              <div className="flex gap-1">
                <div className="flex flex-wrap content-center h-full">
                  <GemsIcon h={12}/>
                </div>
                <div>
                  {friend.cumulatedGems}/5
                </div>
              </div>

            </div>
            :
            <CheckIcon/>
          }

        </div>
      </div>

    </div>

  );
}