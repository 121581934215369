export function formatPerformance2(perf:number) {
  perf = (perf - 1) * 100;
  const rounded = Math.round(perf * 100) / 100;
  let sign = '+';
  if (perf < 0) {
    sign = '';
  }
  const formatted = `${sign}${rounded}%`;

  return formatted;
}

export function formatPerformance(perf:number) {

  perf = perf * 100;
  const rounded = Math.round(perf * 100) / 100;
  let sign = '+';
  if (perf < 0) {
    sign = '';
  }
  const formatted = `${sign}${rounded}%`;

  return formatted;
}

export function formatGain(gain:number){
  const rounded = Math.round(gain * 100) / 100;
  let sign = '+';
  if (gain < 0) {
    sign = '';
  }
  const formatted = `${sign}${rounded}`;

  return formatted;
}


