import TripleGemsIcon from "../svg/TripleGemsIcon";
import { CoinIcon } from "../ui/coin-icon";
import { GemsIcon } from "../ui/gems-icon";

interface ReferralFanCardProps {

}

const ReferralFanCard: React.FC<ReferralFanCardProps> = () => {
  return (

    <div className="flex bg-orange p-2 gap-2 rounded-xl">

      <div className={"flex-none w-[48px] content-center"}>
        <TripleGemsIcon/>
      </div>

      <div className={"flex flex-col flex-auto"}>

        <p className={"text-white font-bold text-sm"}>Tanjiblocks Fan</p>
        <p className={"text-white font-light text-xs"}>0nce your referred 20 friends, your enter Tanjiblocks fan program.</p>

        {/* <p className={"text-white font-light text-xs"}>
          Once your referred 20 friends, your enter Tanjiblocks fan program. <br/>
          You win 10per referred friend.
        </p> */}

        <div className="flex flex-wrap mt-1 content-center">

          <p className={"text-white font-light text-xs pt-1"}>You win</p>

          <div className={"max-w-fit mx-1 bordered"}>
            <span className="text-xs opacity-80">10</span> <GemsIcon h={12}/>
          </div>

          <p className={"text-white font-light text-xs pt-1"}>per referred friend.</p>

        </div>

      </div>

    </div>
   );
}

export default ReferralFanCard;