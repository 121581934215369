import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface Coordinates {
  x: number,
  y: number
}

interface Gem {
  id: number
}

interface CounterState {
  isPlaying: boolean,
  gemPosition: Coordinates,
  value: number,
  gemList: Gem[]
}

const initialState = {
  isPlaying: false,
  gemPosition:{
    x: 0,
    y: 0
  },
  value: 0,
  gemList: [],
} satisfies CounterState as CounterState


export const gemSlice = createSlice({
  name: 'gemSlice',
  initialState,
  reducers: {
    setGemPosition: (state, action: PayloadAction<Coordinates>) => {
      state.gemPosition = action.payload;
    },
    animate(state, action: PayloadAction<{spawnPoint:Coordinates}>) {

      // Compute Spawing point
      const deltaX = action.payload.spawnPoint.x - state.gemPosition.x + "px";
      const deltaY = action.payload.spawnPoint.y - state.gemPosition.y + "px";

      // Reset CSS vars
      document.documentElement.style.setProperty("--animated-gem-delta-x", deltaX);
      document.documentElement.style.setProperty("--animated-gem-delta-y", deltaY);

      // Start animation
      state.isPlaying = true;

    },
    instanciateNewGem: (state) => {
      state.gemList = [...state.gemList, ({id: state.gemList.length++})];
    },
    updateValue: (state, action: PayloadAction<{value:number}>) => {
      state.value = action.payload.value;
    },
    stopAnimation: (state) => {
      state.isPlaying = false;
      state.gemList = [];
      state.value = 0;
    }
  }
})

export const {animate, stopAnimation, setGemPosition, instanciateNewGem, updateValue} = gemSlice.actions

export default gemSlice.reducer