import React from 'react';

const CheckIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.52 1.24127C15.7494 0.740598 14.7174 0.959932 14.2147 1.73193L6.95735 12.9179L3.60468 9.81793C2.92868 9.1926 1.87402 9.2346 1.24935 9.90993C0.624684 10.5859 0.666018 11.6406 1.34202 12.2653L6.14802 16.7093C6.46802 17.0059 6.87468 17.1526 7.27868 17.1526C7.72735 17.1526 8.30135 16.9646 8.67802 16.3933C8.89935 16.0559 17.0114 3.5466 17.0114 3.5466C17.512 2.77393 17.292 1.74193 16.52 1.24127Z" fill="#EA5C27"/>
  </svg>

);

export default CheckIcon;
