import React, {useEffect, useState} from 'react';
import TemporaryDrawer from "../ui/temp-drawer.component";
import {disableBodyScroll, enableBodyScroll} from "../../utils/HtmlHelper";
import {classNames} from "../../utils/Helper";
import { GemsCostButton } from '../ui/secondary-gems-button.component';
import { useBuyProductWithGemsMutation } from '../../apis/shop.api';
import {useNavigate} from "react-router-dom";
import { SmallTreasureComponent, TreasureInterface } from './treasure.component';


interface BuyTreasureDrawerProps {
  open: boolean,
  onClose: Function,
  treasure: TreasureInterface,
}

const BuyTreasureDrawer: React.FC<BuyTreasureDrawerProps> = ({
  open,
  onClose = () => {},
  treasure,
}) => {

  const navigate = useNavigate();

  const [buyProductWithGems] = useBuyProductWithGemsMutation();

  const [isBuying, setIsBuying] = useState(false);

  const treasurePrice = treasure.displayedPrice ? treasure.displayedPrice : treasure.displayedPrice;

  useEffect(() => {
    return () => enableBodyScroll();
  }, []);

  return (
    <>
      {/* { collectible && !userCollectibleLoading && */}
      { treasure &&
        <React.Fragment>
          <TemporaryDrawer
            title={"Treasure"}
            closeWhenClickingOutside={true}
            anchor={'bottom'}
            open={open}
            lineIcon={false}
            onOpen={() => { disableBodyScroll() }}
            onClose={() => {
              enableBodyScroll()
              onClose();
            }}
            size="auto"
          >
            <div className={"w-full h-full flex flex-col justify-center items-center py-2 overflow-y-auto pb-9 gap-8"}>

              <SmallTreasureComponent treasure={treasure} onClick={()=>{}} displayPrice={false}/>

              <p className='text-xs font-normal'>
                {treasure.description}
              </p>

              <div className='w-full max-w-80'>

                { isBuying &&
                  <GemsCostButton
                    label={"Buying..."}
                    quantity={treasurePrice}
                    css={classNames("disabled")}
                    onClick={() => {}}
                  />
                }

                { !isBuying &&

                  <GemsCostButton
                    label={"Buy"}
                    quantity={treasurePrice}
                    css={classNames()}
                    onClick={async (e) => {

                      e.stopPropagation();
                      setIsBuying(true);

                      await buyProductWithGems({productCode: treasure.code}).
                      then((data:any)=>{

                        if (data.error){
                          return;
                        }

                        gtag('event', 'buyDailyCollectible', {productCode: treasure.code, productPrice: treasurePrice});
                        // navigate(`/store?status=success&name=${collectible.collectibleCard.name}&price=${treasurePrice}`);

                      })
                      .finally(()=>{
                        setIsBuying(false);
                        onClose();
                      });

                    }}
                  />
                }

              </div>

            </div>
          </TemporaryDrawer>
        </React.Fragment>
      }
    </>
  );
}

export default BuyTreasureDrawer;