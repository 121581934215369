import React from "react";
import './index.css';


interface Props {
  children: React.ReactNode
}

function UnauthenticatedLayout(props: Props) {

  return (
      <div id={"layout"}
        className={"relative flex flex-col w-screen text-white items-center z-10 px-4 pb-16 pt-8"}>
        <>
          {props.children}
        </>
      </div>
  );
}

export default UnauthenticatedLayout;