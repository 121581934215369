import React, {useEffect, useState} from 'react';
import Layout from "../Layout";
import {useNavigate} from "react-router-dom";
import {SecondaryNavigation} from '../components/ui/secondary-navigation.component';
import {formatRank} from '../utils/Helper';
import {usePlayerFromPseudoParameter} from '../hooks/usePseudoParam';
import {BackButton} from '../components/ui/back-button';
import {SharingCardComponent} from '../components/social/sharing-card.component';
import ReferalRewardsList from '../components/profile/profile-referal-rewards-list.component';

export const ReferralRewardsPage: React.FC = () => {

  const {player} = usePlayerFromPseudoParameter();
  const [sharingText, setSharingText] = useState('');

  useEffect(() => {
    if (player){

      const previousRank = player.previousWeekRank ? player.previousWeekRank : 0;

      setSharingText(`I just finished ${formatRank(player.previousWeekRank)} rank on @tanjiblocks, a fictive crypto trading game to have fun with friends. 
      Try out now and earn 2000 free coins to start:`);

      if(previousRank == 0){
        setSharingText(`Try out @tanjiblocks now and earn 2000 free Coins to start:`);
      }

    }
  }, [player]);

  const navigate = useNavigate();

  return (

    <Layout classes='-mt-12 bg-black min-h-screen bg-gradient-to-b from-orange-700 to-orange w-screen'>

      {/* NavBar */}

      <div className={"w-screen mt-0 pt-6 max-w-4xl relative "}>

        <div className={"w-full max-w-4xl mx-auto flex flex-col gap-4 md:gap-8 p-4"}>

          <div className={"flex flex-col gap-4 md:gap-8 w-full"}>

            <span className={"absolute left-4 z-10"}><BackButton label={""} callback={() => navigate(-1)}/></span>

            <div className={"relative flex w-full justify-center"}>
              <SecondaryNavigation navItems={[
                {path: '/referral-rewards', name: 'Rewards'},
                {path: '/referral-friends', name: 'Referred friends'},
              ]}/>
            </div>

          </div>

        </div>

      </div>

      <div className='container max-w-4xl mb-6'>

        {/* Sticky Player Card*/}
        {player && <SharingCardComponent player={player} sharingText={sharingText} />}

        {/* TODO: Display Rewards list*/}
        <ReferalRewardsList/>

      </div>

    </Layout>
  );

}