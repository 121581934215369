import {emptySplitApi} from "./empty.api";
import {CollectibleCard, CollectiblesListResponse} from "../dto/collectibles/collectibles-list-response.dto";

export const collectiblesApi = emptySplitApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getCollectible: build.query<CollectibleCard, string>({
      query: (cryptoId) => ({
        url: "/collectibles/get",
        params: {cryptoId},
        method: "GET",
      }),
      providesTags: (result, error, id) => [{type: 'Collectible', id}],
    }),
    getCollectibles: build.query<Array<any>, any>({
      query: () => ({
        url: "/collectibles",
        method: "GET",
      }),
      providesTags: (result, error, id) => [{type: 'Collectibles', id: 'My'}],
    }),
    getAllUserCondensedCollectibles: build.query<Array<any>, void>({
      query: () => ({
        url: "collectibles/list-condensed",
        method: "GET",
      }),
      providesTags: ['CondensedCollectibleList'],
    }),
    getInfiniteCollectibleList: build.query<CollectiblesListResponse, any>({
      query: (requestArg: any) => {
        return {url: "/collectibles/list", params: requestArg}
      },
      onQueryStarted(arg, api): Promise<void> | void {
        // console.log(`InfiniteCollectibleList call : arg = ${JSON.stringify(arg)}`);
      },
      // Only have one cache entry because the arg always maps to one string
      serializeQueryArgs: ({endpointName, queryArgs}) => {
        // console.log(`serializeQueryArgs = ${ endpointName + queryArgs.mode}`)
        return endpointName + queryArgs.mode + queryArgs.userId;
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache: any, newItems: any) => {
        // console.log(`InfiniteCollectibleList result : ${newItems.collectibles.length}, NewPageIndex:${newItems.pageIndex}`);
        if (newItems.pageIndex > currentCache.pageIndex) {
          currentCache.pageIndex = newItems.pageIndex;
          currentCache.collectibles.push(...newItems.collectibles)
        }
      },
      // Refetch when the page arg changes
      forceRefetch({currentArg, previousArg}) {
        const res = JSON.stringify(currentArg) !== JSON.stringify(previousArg);
        // console.log(`InfiniteCollectibleList forceRefetch = ${res}, prev=${JSON.stringify(previousArg)} , new =${JSON.stringify(currentArg)}`);
        return res;
      },
      providesTags: ['InfiniteCollectibleList'],
    }),
    getUpgradeCost: build.query<number, string>({
      query: (cryptoId) => ({
        url: "/collectibles/getUpgradeCost",
        params: {cryptoId},
        method: "GET",
      }),
      providesTags: (result, error, id) => [{type: 'CollectibleUpgradeCost'}],
    }),
    getBetBoost: build.query<number, { cryptoId: string, previewNextLevel?: boolean }>({
      query: (params) => ({
        url: "/collectibles/getBetBoost",
        params,
        method: "GET",
      }),
      providesTags: (result, error, params) => [{type: 'CollectibleBetBoost', params}],
    }),
    upgradeCollectible: build.mutation <void, string>({
      query: (cryptoId) => ({
        url: "/collectibles/upgrade",
        method: "POST",
        body: {cryptoId},
      }),
      invalidatesTags: (a, b, o) => ['User', {type: 'Collectible', o}],
    }),

  }),
});

export const {
  useGetCollectiblesQuery,
  useGetCollectibleQuery,
  useGetAllUserCondensedCollectiblesQuery,
  useGetInfiniteCollectibleListQuery,
  useUpgradeCollectibleMutation,
  useGetBetBoostQuery,
  useGetUpgradeCostQuery,
} = collectiblesApi;