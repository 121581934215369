import {Tournament} from "../../models/Tournament";
import React, {useCallback, useEffect, useState} from "react";
import {useExecuteSwapTradeMutation, useGetSquadCryptosQuery} from "../../apis/squad.api";
import {CryptoCurrency, getTanjiCryptoCurrency} from "../../models/Wallet";
import toast from "react-hot-toast";
import {useNavigate} from "react-router-dom";
import {CryptoFilterSelectionElement} from "../crypto-filtered-selection-element.component";
import {toastMessage, toastSuccess} from "../../utils/ToastUtils";
import {Slider} from "@mui/material";
import {classNames, formatBalanceNumber, hasElements} from "../../utils/Helper";
import TemporaryDrawer from "../ui/temp-drawer.component";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {QuantityCoinComponent} from "../ui/quantity-coin.component";
import {fiatToCrypto} from "../../utils/MathsUtils";
import {FullScreenDialog} from "../ui/modal-confirmation";
import {PartyingFaceIcon} from "../ui/partying-face-icon";
import {useTanjiBalance} from "../../hooks/useTanjiBalance";
import {useGetCryptosFromIdsQuery} from "../../apis/cryptos.api";

interface SwapProps {
  tournament: Tournament,
  onCancel?: Function;
  cryptoSrcId?: string;
  cryptoDestId?: string;
  forceAmount?: number;
  mode?: 'swap' | 'sell' | 'buy',
  swapDone?: Function;
  onError?: Function;
  displayDefaultSuccessDialog?: boolean,
  forceCryptoDest?: boolean;
}

const marks = [
  {
    value: 10,
    // label: '10%',
  },
  {
    value: 20,
    // label: '20%',
  },
  {
    value: 30,
    // label: '30%',
  },
  {
    value: 40,
    // label: '40%',
  },
  {
    value: 50,
    // label: '50%',
  },
  {
    value: 60,
    // label: '60%',
  },
  {
    value: 70,
    // label: '70%',
  },
  {
    value: 80,
    // label: '80%',
  },
  {
    value: 90,
    // label: '90%',
  },
  {
    value: 100,
    // label: '100%',
  },
];

const SwapComponent: React.FC<SwapProps> = ({
                                              tournament,
                                              onCancel,
                                              cryptoSrcId,
                                              cryptoDestId,
                                              mode = 'manual',
                                              forceAmount,
                                              displayDefaultSuccessDialog = true,
                                              swapDone = () => {
                                              },
                                              onError,
                                              forceCryptoDest = false
                                            }) => {

  const {data: cryptos, isLoading: cryptoLoading} = useGetSquadCryptosQuery({
    tournamentId: tournament.id
  }, {refetchOnMountOrArgChange: 30});

  const [selectedSrc, setSelectedSrc] = useState<CryptoCurrency | null>(null);
  const [selectedDest, setSelectedDest] = useState<CryptoCurrency | null>(null);
  const [cryptosSrc, setCryptoSrc] = useState<CryptoCurrency[]>([]);
  const [swap] = useExecuteSwapTradeMutation();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [disabledSwapButton, setDisableSwapButton] = React.useState(false);
  const [successText, setSuccessText] = React.useState('');
  const {value: tanjiBalance, isLoading: isTanjiBalanceLoading} = useTanjiBalance();
  const [percentageAmount, setPercentageAmount] = React.useState(50);
  const [amountToSwap, setAmountToSwap] = React.useState(0);
  const {
    data: preselectedDestCrypto
  } = useGetCryptosFromIdsQuery({ids: [cryptoDestId]}, {skip: !cryptoDestId || cryptoDestId === 'tanji'});

  const onErrorCallBack = useCallback(() => {
    if (onError) {
      onError()
    } else navigate(-1);
  }, [onError]);

  const getSelectedAmount = useCallback((_id: string) => {
    let res = 0;
    if (_id === 'tanji') {
      return tanjiBalance;
    } else if (cryptos) {
      let value = cryptos.src.find(c => c.crypto._id === _id)?.value;
      if (value) {
        res = value;
      }
    }

    return res;
  }, [cryptos]);

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    if (selectedSrc) {
      setPercentageAmount(newValue as number);
      const srcAmount = getSelectedAmount(selectedSrc._id);
      setAmountToSwap((newValue as number / 100) * srcAmount);
    }
  };
  const [srcDrawerOpen, setSrcDrawerOpen] = useState<boolean>(false);
  const [dstDrawerOpen, setDstDrawerOpen] = useState<boolean>(false);

  const getCryptoSrcAmountToSwap = useCallback(() => {
    if (selectedSrc) {
      return formatBalanceNumber(fiatToCrypto(amountToSwap, selectedSrc));
    } else return 0;
  }, [amountToSwap, selectedSrc]);

  const getCryptoDstAmountToSwap = useCallback(() => {
    if (selectedDest) {
      return formatBalanceNumber(fiatToCrypto(amountToSwap, selectedDest));
    } else return 0;
  }, [amountToSwap, selectedDest]);

  useEffect(() => {
    if (mode === 'buy') {
      setSelectedSrc(getTanjiCryptoCurrency());
    }
  }, [mode]);

  useEffect(() => {
    if (forceAmount) {
      setAmountToSwap(forceAmount);
    }
  }, [forceAmount]);


  useEffect(() => {
    if (cryptoSrcId && cryptoSrcId !== 'tanji' && cryptos && hasElements(cryptos.src)) {
      const c = cryptos.src.map(s => s.crypto).find(c => c._id === cryptoSrcId);
      if (c) {
        setSelectedSrc(c);
      }
    } else if (cryptoSrcId && cryptoSrcId === 'tanji') {
      setSelectedSrc(getTanjiCryptoCurrency());
    }

  }, [cryptos, cryptoSrcId]);

  useEffect(() => {
    if (cryptoSrcId && cryptos && hasElements(cryptos.src)) {
      const c = cryptos.src.map(s => s.crypto).find(c => c._id === cryptoSrcId);
      if (c) {
        setSelectedSrc(c);
      }
    }
  }, [cryptos, cryptoSrcId]);

  useEffect(() => {
    if (preselectedDestCrypto && hasElements(preselectedDestCrypto) && !selectedDest) {
      setSelectedDest(preselectedDestCrypto[0]);
    } else if (!selectedDest && cryptoDestId === 'tanji') {
      setSelectedDest(getTanjiCryptoCurrency());
    }
  }, [preselectedDestCrypto])

  useEffect(() => {
    if (!cryptoLoading && cryptos) {
      setCryptoSrc(cryptos.src.filter(c => !!c.crypto).map(c => c.crypto));
    }
  }, [cryptos, cryptoLoading, selectedSrc]);

  useEffect(() => {
    if (selectedSrc && !forceAmount) {
      const srcAmount = getSelectedAmount(selectedSrc._id);
      const newPercentageAmount = 50;
      setPercentageAmount(newPercentageAmount);
      setAmountToSwap((newPercentageAmount as number / 100) * srcAmount);
    }
  }, [selectedSrc]);

  return <div
    className={"flex flex-col gap-y-4 items-center pt-4 px-4 pb-6 w-full"}>

    <div
      className={"w-full max-w-md p-2 bg-gray-200 rounded-xl border border-black border-opacity-5 flex-col justify-center items-center gap-1 inline-flex"}>
      {/*==========================================SOURCE================================================*/}
      {mode !== 'buy' &&
        <div
          className={"w-full p-4 bg-white rounded-lg border border-black border-opacity-5 flex-col justify-start items-start gap-8 inline-flex"}>
          <div className={"w-full flex flex-row gap-4 justify-between"}>
            <div className="flex flex-col gap-2">
              <div className="opacity-60 text-black text-xs font-bold leading-[17px]">You pay</div>
              <div
                className={"text-black text-[32px] font-bold leading-none truncate"}>{getCryptoSrcAmountToSwap()}</div>
            </div>
            <TemporaryDrawer anchor={'bottom'}
                             buttonClass={'h-10 pl-2 pr-1 py-2 bg-white rounded-lg fat-grey-shadow-2 border border-neutral-200 justify-start items-center gap-4 inline-flex'}
                             title={'Source'}
                             element={<div className={"flex items-center gap-x-2"}>
                               <img src={selectedSrc?.logo} width={20} height={20}
                                    className={!selectedSrc?.logo ? 'hidden' : ''}/>
                               <span className={"font-bold"}>{selectedSrc ? selectedSrc.code : "Source"}</span>
                               <span className={"text-gray-400"}><ExpandMoreIcon fontSize={"large"}/></span></div>}
                             open={srcDrawerOpen}
                             onOpen={() => setSrcDrawerOpen(true)}
                             onClose={() => setSrcDrawerOpen(false)}>
              <div className={"w-full h-full overflow-y-auto"}>
                <CryptoFilterSelectionElement
                  selectedCryptos={selectedSrc ? [selectedSrc._id] : []}
                  onSelect={(crypto: CryptoCurrency) => {
                    setSrcDrawerOpen(false);
                    setSelectedSrc(crypto);
                  }}
                  onDeselect={(crypto: CryptoCurrency) => {

                  }}
                  cryptoIds={cryptosSrc.map(c => c._id)}
                  excludedCryptoIds={selectedDest?._id}
                />
              </div>

            </TemporaryDrawer>
          </div>
          <div className={"w-full flex"}>
            <QuantityCoinComponent size={12} quantity={amountToSwap}/>
          </div>
        </div>
      }
      {/*==========================================SLIDER================================================*/}
      {!forceAmount &&
        <div className="w-full px-4 py-2 flex flex-col justify-center items-center">
          <Slider onChange={handleSliderChange} value={percentageAmount} step={10} marks={marks} min={0} max={100}
                  aria-label="Default"
                  valueLabelDisplay="auto"/>
        </div>
      }

      {/*==========================================DESTINATION================================================*/}
      <div
        className={"w-full p-4 bg-white rounded-lg border border-black border-opacity-5 flex-col justify-start items-start gap-8 inline-flex"}>
        <div className="w-full flex flex-col gap-4">
          <div className="w-full flex flex-row gap-4 justify-between">
            <div className="flex flex-col gap-2">
              <div className="opacity-60 text-black text-xs font-bold leading-[17px]">You receive</div>
              <div className={"text-black text-[32px] font-bold leading-none truncate"}>
                {selectedDest ? formatBalanceNumber(fiatToCrypto(amountToSwap, selectedDest)) : 0}
              </div>
            </div>

            <div className={"flex flex-col"}>
              <TemporaryDrawer disabled={forceCryptoDest && !!selectedDest && !!cryptoDestId} anchor={'bottom'}
                               buttonClass={'h-10 pl-2 pr-1 py-2 bg-white rounded-lg fat-grey-shadow-2 border border-neutral-200 justify-start items-center gap-4 inline-flex'}
                               title={'Destination'}
                               element={<div className={"flex items-center gap-x-2 px-2"}>
                                 <img src={selectedDest?.logo} width={20} height={20}
                                      className={!selectedDest?.logo ? 'hidden' : ''}/>
                                 <span className={"font-bold"}>{selectedDest ? selectedDest.code : "Destination"}</span>
                                 <span
                                   className={classNames("text-gray-400", forceCryptoDest && !!selectedDest ? 'hidden' : '')}>
                                   <ExpandMoreIcon fontSize={"large"}/>
                                 </span>
                               </div>}
                               open={dstDrawerOpen}
                               onOpen={() => setDstDrawerOpen(true)}
                               onClose={() => setDstDrawerOpen(false)}>
                <div className={"w-full"}>

                  <CryptoFilterSelectionElement
                    selectedCryptos={selectedDest ? [selectedDest._id] : []}
                    onSelect={(crypto: CryptoCurrency) => {
                      setDstDrawerOpen(false);
                      setSelectedDest(crypto);
                      setTimeout(() => window.scrollTo(0, document.body.scrollHeight), 400);
                    }}
                    onDeselect={(crypto: CryptoCurrency) => {

                    }}
                    excludedCryptoIds={selectedSrc?._id}
                  />
                </div>
              </TemporaryDrawer>
            </div>
          </div>

          <div
            className={classNames('text-black text-right text-opacity-60 text-sm font-bold leading-[14px]', mode === 'buy' ? 'flex justify-between ' : '')}>
            {mode === 'buy' &&
              <div className={"w-full flex"}>
                <QuantityCoinComponent size={12} quantity={amountToSwap}/>
              </div>
            }
            <span
              className={""}>Balance: </span>{selectedDest ? formatBalanceNumber(fiatToCrypto(getSelectedAmount(selectedDest._id), selectedDest)) : 0}
          </div>
        </div>
      </div>
    </div>


    <button
      className={classNames(`w-full max-w-md primary ${!(selectedSrc && selectedDest) ? 'opacity-50 cursor-not-allowed' : ''}`)}
      onClick={() => {
        if (!(selectedSrc && selectedDest)) return;
        gtag('event', 'swapping', {
          cryptoDestination: selectedDest?.code,
          cryptoSource: selectedSrc.code
        });
        window.scrollTo(0, 0);
        toast.loading('Swapping...');
        setDisableSwapButton(true);
        swap({
          tournamentId: tournament.id,
          // code: actionCode,
          payload: {
            cryptoDestination: selectedDest._id,
            cryptoSource: selectedSrc._id,
            amount: amountToSwap,
            percentage: percentageAmount
          }
        }).unwrap().then((data) => {
          toastSuccess('Swap done !');
          gtag('event', 'swapSuccess', {
            // trade: actionCode,
            cryptoDestination: selectedDest.code,
            cryptoSource: selectedSrc.code
          });

          if (cryptoDestId === 'tanji') {
            setSuccessText(`You sold ${getCryptoSrcAmountToSwap()} ${selectedSrc?.code} for ${formatBalanceNumber(amountToSwap)} Tanji !`);
          } else {
            setSuccessText(`You purchased ${getCryptoDstAmountToSwap()} ${selectedDest?.code} for ${formatBalanceNumber(amountToSwap)} Tanji. Check it out in your Wallet!`);
          }

          setOpen(true);
          swapDone();
        }).catch(() => {
          gtag('event', 'swapError', {});
          onErrorCallBack();
          toastMessage('Ooops, cannot swap. Check your Tanji balance or retry later.');
        }).finally(() => {
          setSelectedDest(null);
          setSelectedSrc(null);
          setAmountToSwap(0);
          setPercentageAmount(50);
          setDisableSwapButton(false);
        })
      }}
      disabled={!(selectedSrc && selectedDest) || disabledSwapButton}
    >
      {mode === 'buy' ? 'buy' : 'swap'}
    </button>

    {displayDefaultSuccessDialog &&
      <FullScreenDialog displayOpenButton={false} openProp={open} timer={10} onClose={() => {
        navigate('/portfolio')
      }}>
        <div className={"h-full flex flex-col gap-y-16 justify-center items-center"}>
          <PartyingFaceIcon h={50}/>

          <div className={"flex-col flex gap-3 justify-center items-center"}>
            <div className={"rounded-lg bg-black bg-opacity-20 text-white font-bold px-2 py-2 text-lg text-center"}>
              Success!
            </div>
            <div className={"text-white text-center"}>
              {successText}
            </div>
          </div>
        </div>
      </FullScreenDialog>
    }

  </div>;
};

export {SwapComponent};