import React from 'react';

const BoltIcon = () => (
  <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1029_30923)">
      <path d="M9.14907 4.8475C9.10852 4.73889 9.00463 4.66667 8.88852 4.66667H5.53435L7.46907 0.905C7.52991 0.786667 7.49852 0.641944 7.3938 0.559722C7.34324 0.519722 7.28241 0.5 7.22185 0.5C7.15713 0.5 7.09241 0.522778 7.04046 0.5675L4.99963 2.33L0.929351 5.84528C0.841574 5.92111 0.809907 6.04361 0.850462 6.15222C0.891018 6.26083 0.994629 6.33333 1.11074 6.33333H4.46491L2.53018 10.095C2.46935 10.2133 2.50074 10.3581 2.60546 10.4403C2.65602 10.4803 2.71685 10.5 2.77741 10.5C2.84213 10.5 2.90685 10.4772 2.9588 10.4325L4.99963 8.67L9.06991 5.15472C9.15796 5.07889 9.18935 4.95639 9.14907 4.8475V4.8475Z" fill="#FDC605" />
    </g>
    <defs>
      <clipPath id="clip0_1029_30923">
        <rect width="10" height="10" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default BoltIcon;
