import {CryptoCurrency} from "../models/Wallet";

function getVariationRate(val1: number|string, val2: number|string) {
  return (Number(val2) - Number(val1)) / Number(val1);
}


function fiatToCrypto(fiatAmount: number, crypto: CryptoCurrency) {
  return fiatAmount / Number(crypto.lastQuote.price);
}

function isStrictlyPositive(num?:number):boolean{
  return !!num && num > 0;
}
export {getVariationRate, fiatToCrypto,isStrictlyPositive}