import {useEffect, useState} from "react";
import axios from "axios";

export function useIsAuth() {
  const [isAuthLoading, setIsAuthLoading] = useState<boolean>(true);
  const [isAuth, setIsAuth] = useState<boolean>(false);

  useEffect(() => {
    axios.get('/auth/isAuth').then(function (data) {
      setIsAuth(true);
      setIsAuthLoading(false);
    }).catch(function (error) {
      setIsAuth(false);
      setIsAuthLoading(false);
    });
  }, []);

  return {isAuth, isAuthLoading};
}
