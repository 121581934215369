import React from "react";
import {SignupForm} from "./signup-form.component";
import HandShakeIcon from "../../svg/HandshakeIcon";
import {TanjiIcon} from "../../ui/tanji-icon";

export interface Props {
  onlyForm?: boolean;
  noRedirect?: boolean;
  referrerId?:string;
}

const SignupComponent: React.FC<Props> = ({onlyForm = false, noRedirect = false,referrerId}) => {
  return (
    <div className="flex flex-col w-full max-w-4xl center p-4 gap-4 flat-card">
      <img src="/static/tanjiblocks-logo-orange.svg" alt="Tanjiblocks logo" width="80px"/>
      <div className="self-stretch flex-col justify-start items-center gap-1 md:gap-2 flex">
        <h1 className="inline-block text-[28px] md:text-[40px] text-center leading-10">
          Welcome
          <span className="inline-block pl-2 align-middle"><HandShakeIcon/></span>
        </h1>
        <div className="self-stretch text-center text-base font-normal leading-snug">Start your investment adventure
        </div>
      </div>
      <div className="flex flex-col w-full">
        <SignupForm noRedirect={noRedirect} userReferrerId={referrerId}/>
      </div>
    </div>
  );
};

export {SignupComponent};