import React, {useEffect, useMemo, useState} from "react";
import GiftIcon from "../svg/GiftIcon";
import DailyQuestReward from "../profile/daily-quests-rewards.component";
import TemporaryDrawer from "../ui/temp-drawer.component";
import {disableBodyScroll, enableBodyScroll} from "../../utils/HtmlHelper";
import {useGetDailyQuestsQuery, useGetUserQuery} from "../../apis/users.api";
import {useLocation, useNavigate} from "react-router-dom";
import {DateTime} from "luxon";

import {isSameDayAsToday} from "../../utils/DateUtils";

function isClaimableDay(day: Date) {
  return isSameDayAsToday(day);
}

function isDailyQuestTimeOver(createdDate: Date | undefined) {
  if (!createdDate) {
    return true;
  }
  const questTimeOver = DateTime.fromISO(createdDate.toString()).plus({day: 2});
  return DateTime.now().startOf('day') > questTimeOver.endOf('day');
}

interface DailQuestProps {

}

const DailQuest: React.FC<DailQuestProps> = () => {

  const navigate = useNavigate();
  const {state} = useLocation();

  const [rewardsListElement, setRewardsListElement] = useState<any>(null);
  const {data: questsList} = useGetDailyQuestsQuery();
  const {data: user, isLoading: isUserLoading} = useGetUserQuery();

  const hasFinishedOnboarding = useMemo(() => {
    const initalTag = user?.onBoarding?.find(o => o.tag === 'initial');
    return initalTag ? initalTag.value : false;
  }, [state]);

  const [open, setOpen] = React.useState(false);
  const [animate, setAnimate] = React.useState(false);

  const openDrawer = () => setOpen(true);
  const closeDrawer = () => {
    setOpen(false);
    navigate(location.pathname, {state: {...state, openDailyReward: false}, replace: true});
  };

  useEffect(() => {
    if (!hasFinishedOnboarding && state && state.openDailyReward) {
      openDrawer();
    }
  }, [state]);

  useEffect(() => {
    return () => enableBodyScroll();
  }, []);

  useEffect(() => {
    if (questsList && questsList.length) {

      let animateClaim = false;

      setRewardsListElement(questsList.map((quest, index) => {

          if (isClaimableDay(quest.claimableDate) && !quest.isClaimed) {
            animateClaim = true;
          }

          return <DailyQuestReward
            key={index}
            dayNumber={++index}
            type={quest.rewardType}
            rewardAmount={quest.rewardAmount}
            isClaimed={quest.isClaimed}
            claimableDate={quest.claimableDate}
          />
        }
      ));

      setAnimate(animateClaim);

    }
  }, [questsList]);

  if (user && questsList && questsList.length) {
    return (
      <React.Fragment>
        <div
          className={`md:flex flex-col self-end center ${(!questsList?.length || isDailyQuestTimeOver(user?.createdAt)) ? '!hidden' : ''}`}
          onClick={openDrawer}>
          <div
            className={"h-[21px] px-1.5 bg-black bg-opacity-30 rounded border border-black border-opacity-10 justify-center center relative"}>
            <GiftIcon h={13} animate={animate}/>
          </div>
        </div>

        <TemporaryDrawer title={`Daily Rewards`} anchor={'bottom'} open={open}
                         closeWhenClickingOutside={true}
                         lineIcon={false}
                         onOpen={() => {
                           disableBodyScroll()
                         }}
                         onClose={() => {
                           enableBodyScroll()
                           closeDrawer();
                         }}
                         size="auto"
        >
          <div className="h-full content-center overflow-y-auto pb-10">
            <div className="flex flex-col">

              <div className="center pt-2 pb-4">
                <GiftIcon/>
              </div>

              <p className="center pb-10">
                Connect daily continuously to earn rewards
              </p>

              <div className="px-2">
                {rewardsListElement}
              </div>

            </div>
          </div>

        </TemporaryDrawer>
      </React.Fragment>
    );
  }

  return (<></>);

}

export default DailQuest;