import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {
  canParticipate,
  canSubscribeToTournament,
  hasTournamentEnded,
  isAfterTournamentEnd,
  isTournamentRunning,
  PumpOrDumpInputPayload,
  SubPayload,
  Tournament
} from "../../models/Tournament";
import {classNames, cssHidden, cssShow, hasElements} from "../../utils/Helper";
import {useGetBaseBetOddQuery, useRegisterUserToTournamentMutation} from "../../apis/tournaments.api";
import {useNavigate} from "react-router-dom";
import {QuantityCoinComponent} from "../ui/quantity-coin.component";
import {useGetMoneyAmountQuery} from "../../apis/game-income.api";
import {toastMessage} from "../../utils/ToastUtils";
import {useGetInventoryQuery, useGetUserQuery} from "../../apis/users.api";
import {DateTime} from "luxon";
import TokenSpot from './subcomponents/token-spot.component';
import BearishBullishItem from './subcomponents/bearish-bullish-item.component';
import BetSummary from './subcomponents/bet-summary.component';
import {getChoosenCollectibleInfo} from '../tournament/tournaments-list.component';
import {find} from 'lodash';
import PotentialEarningsAccordion from './subcomponents/potential-earnings-accordion.component';
import {useGetBetDurationOddBonusQuery, useGetMaxBetAmountQuery} from "../../apis/game.api";
import {RapidDecrementButtonV2, RapidIncrementButtonV2} from "../ui/numeric-button-v2";
import RocketIcon from "../svg/RocketIcon";
import {GemsAlertSnackbar, PowerUpAlertSnackbar} from "../game/gems-alert-snackbar.component";
import {MaxBetAmountReachedSnackbar} from "../game/max-bet-amount-snackbar.component";
import {BoostItemIcon, ShieldItemIcon} from "../shop/gems-buyable-items.component";
import {UserItem} from "../../models/User";
import {ItemsSelectionDrawer} from "./items-selection-drawer.component";
import {SparkIcon} from "../ui/spark-icon";


const getTournamentCryptoBoost = (tournament: Tournament, cryptoId?: string) => {
  return tournament.cryptoBoosts ? (cryptoId ? tournament.cryptoBoosts[cryptoId] : undefined) : undefined
}

const PumpOrDumpItemComponent: React.FC<{
  tournament: Tournament,
  userScore?: SubPayload
  betDone?: Function,
  displayOddDetail?: boolean,
  displayBetBoost?: boolean
}> = ({
        betDone = () => {
        },
        displayOddDetail = true,
        displayBetBoost = true,
        tournament, userScore,
      }) => {
  const [register] = useRegisterUserToTournamentMutation();
  const navigate = useNavigate();
  const {data: money} = useGetMoneyAmountQuery();
  const {data: duration_boosts} = useGetBetDurationOddBonusQuery();

  const [payload, setPayload] = useState<PumpOrDumpInputPayload | null>(tournament.tournamentInputPayload);
  const [choice, setChoice] = useState<'up' | 'down' | undefined>(userScore?.pumpOrDumpInputPayload.choice);
  const [amount, setAmount] = useState<number>(userScore?.pumpOrDumpInputPayload.amount || 0);
  const [winning, setWinning] = useState<boolean>(!!userScore?.pumpOrDumpScorePayload?.winning);
  const [choiceDone, setChoiceDone] = useState(!!userScore?.pumpOrDumpScorePayload);
  const [gemsBoostActivated, setGemsBoostActivated]
    = useState<boolean>((!!userScore?.pumpOrDumpInputPayload?.gemsBoost && userScore?.pumpOrDumpInputPayload?.gemsBoost > 0));
  const [showGemsAlert, setShowGemsAlert] = useState(false);
  const [showMaxBetAmountReached, setShowMaxBetAmountReached] = useState(false);
  const [maxBetThresholdCount, setMaxBetThresholdCount] = useState(0);
  const [a_rate, setA_rate] = useState(userScore?.pumpOrDumpScorePayload.variationRate || 0);
  const [a_price, setA_price] = useState(Number(payload?.crypto?.lastQuote?.price || 0));
  const [a_openPrice, setA_openPice] = useState<number>(userScore?.pumpOrDumpScorePayload.openPrice || 0);
  const [duration, setDuration] = useState<number>(userScore?.pumpOrDumpInputPayload.durationInHours || 1);
  const [subscribing, setSubscribing] = useState(false);
  const {data: user} = useGetUserQuery()
  const {data: maxBetAmount} = useGetMaxBetAmountQuery(payload?.crypto._id);
  const {data: inventory} = useGetInventoryQuery({});
  const [showShieldBoostDrawer, setShowShieldBoostDrawer] = useState(false);
  const [shield, setShield] = useState<UserItem | undefined>(undefined);
  const [boost, setBoost] = useState<UserItem | undefined>(undefined);
  useEffect(() => {
    if (userScore && userScore.pumpOrDumpInputPayload) {
      setChoice(userScore.pumpOrDumpInputPayload.choice);
      setAmount(userScore.pumpOrDumpInputPayload.amount);
      setGemsBoostActivated(userScore.pumpOrDumpInputPayload.gemsBoost > 0);
      setDuration(userScore.pumpOrDumpInputPayload.durationInHours || 1);
      setBoost(userScore.pumpOrDumpInputPayload.itemsApplied?.find((i) => i.subFamily === 'bet_boost'));
      let pumpOrDumpScore = userScore.pumpOrDumpScorePayload;
      setWinning(pumpOrDumpScore.winning);
      setA_rate(pumpOrDumpScore.variationRate);
      if (pumpOrDumpScore.openPrice) {
        setA_openPice(pumpOrDumpScore.openPrice);
        setA_price(pumpOrDumpScore.openPrice + pumpOrDumpScore.openPrice * pumpOrDumpScore.variationRate);
      }
    } else if (payload && payload.crypto.lastQuote) {
      setA_price(Number(payload.crypto.lastQuote.price));
    }
  }, [userScore, payload]);

  const userHasEnoughGems = useCallback(() => {
    return !!(user && user.gemsAmount >= 2);
  }, [user]);

  const choose = useCallback(() => {
    //optimistic
    setChoiceDone(true);
    setSubscribing(true);
    const _boostActivated = userHasEnoughGems() && gemsBoostActivated;

    const itemsApplied = [];
    if (shield) itemsApplied.push(shield);
    if (boost) itemsApplied.push(boost);
    register({
      inputPayload: {
        choice,
        amount,
        gemsBoost: _boostActivated ? 2 : 0,
        durationInHours: duration || 1,
        itemsApplied,
      },
      tournamentType: tournament.type,
      tournamentId: tournament.id,
      shouldStopAt: DateTime.now().plus({hour: duration || 1}).toJSDate(),
    }).then((data: any) => {
      setSubscribing(false);
      betDone();
      if (data.error) {
        gtag('event', 'postTournamentSubscriptionFailure', {
          tournament_type: tournament.type,
          code: tournament?.code,
          gemsBoost: _boostActivated
        });
        navigate('/tournament');
      } else {
        gtag('event', 'postTournamentSubscriptionSuccess', {
          code: tournament?.code,
          tournament_type: tournament.type,
          content_type: 'tournament',
          content_id: tournament.id,
          gemsBoost: _boostActivated
        });
      }
    });
  }, [payload, choice, amount, gemsBoostActivated, shield, boost]);

  const {data: marketOdds} = useGetBaseBetOddQuery();

  const getOdds = useCallback((cryptoBoost: number = 0, durationBoost: number = 0) => {
    return (marketOdds || 1) + Number(boost?.metadata?.value || 0) + cryptoBoost + durationBoost;
  }, [boost, marketOdds]);

  const getCryptoBoost = useCallback((cryptoId?: string) => {
    return getTournamentCryptoBoost(tournament, cryptoId);
  }, [tournament]);

  const getDurationBoost = useCallback((duration: number) => {
    const durationItem = find(duration_boosts, ['hours', duration]);
    return durationItem?.bonus || 0;
  }, [duration_boosts]);

  const isSame = useMemo(() => {
    let res = true;
    if (user && userScore && userScore.userId !== user._id) {
      res = false;
    }
    return res;
  }, [user, userScore])

  return (
    <div
      className={classNames("relative flex flex-col gap-3 justify-center items-center", hasTournamentEnded(tournament, userScore) ? 'mt-0' : 'mt-0')}>

      {payload && money &&
        <>

          {!subscribing &&
            <>
              {choiceDone && winning && hasTournamentEnded(tournament, userScore) &&
                <div className={"rounded-full bg-victory-green text-white uppercase text-xs p-2"}>
                  {isSame ? 'You won!' : 'Won'}
                </div>
              }
              {choiceDone && !winning && hasTournamentEnded(tournament, userScore) &&
                <div className={"rounded-full bg-red-500 text-white uppercase text-xs p-2"}>
                  {isSame ? 'You lost!' : 'Lost'}
                </div>
              }
              {!choiceDone && hasTournamentEnded(tournament, userScore) &&
                <div className={"rounded-full bg-gray-600 text-white uppercase text-xs p-2"}>Not Played</div>
              }

              {choiceDone && !winning && !hasTournamentEnded(tournament, userScore) &&
                <div className={"rounded-full bg-red-500 text-white uppercase text-xs p-2"}>
                  {isSame ? 'You are losing' : 'Losing'}
                </div>
              }

              {choiceDone && winning && !hasTournamentEnded(tournament, userScore) &&
                <div className={"rounded-full bg-victory-green text-white uppercase text-xs p-2"}>
                  {isSame ? 'You are winning' : 'Winning'}
                </div>
              }
            </>
          }


          <TokenSpot token={payload.crypto} collectible={getCryptoBoost(payload.crypto._id) ? true : false}
                     rate={a_rate} choiceDone={choiceDone}/>

          <div className={"relative w-full flex gap-4 justify-center pb-2"}>

            <div className={"flex grow"} onClick={() => {
              if (canParticipate(tournament)) {
                setChoice('down')
              }
            }}>
              <BearishBullishItem
                choice={choice}
                crypto={payload.crypto}
                tournament={tournament}
                isGoodChoice={() => choice === 'down' && a_rate <= 0}
                choiceDone={choiceDone}
                odd={getOdds(getCryptoBoost(payload.crypto._id), getDurationBoost(duration))}
                type="bearish"
              />
            </div>

            <div className={"flex grow"} onClick={() => {
              if (canParticipate(tournament)) {
                setChoice('up')
              }
            }}>
              <BearishBullishItem
                choice={choice}
                crypto={payload.crypto}
                tournament={tournament}
                isGoodChoice={() => choice === 'up' && a_rate >= 0}
                choiceDone={choiceDone}
                odd={getOdds(getCryptoBoost(payload.crypto._id), getDurationBoost(duration))}
                type="bullish"
              />
            </div>

          </div>

          {!subscribing && canSubscribeToTournament(tournament) &&
            (() => {
              if (!subscribing && !choiceDone && canSubscribeToTournament(tournament)) {
                const isDisplayed = !!choice;

                return <div
                  className={classNames("w-full flex flex-col items-center justify-center gap-5", isDisplayed ? 'py-2' : 'hidden')}>

                  {/* Tournament Duration */}
                  <div className={classNames("flex gap-x-4", cssHidden(!isDisplayed || !duration_boosts))}>
                    {duration_boosts?.map((durationChoice, index) => {
                      return <div key={index}
                                  className={classNames("flex flex-col items-center gap-y-2", cssHidden(isAfterTournamentEnd(durationChoice.hours, tournament)))}>
                        {/* <button className={classNames(duration === durationChoice ? 'primary' : 'secondary')} */}
                        <button
                          className={classNames('px-3.5 py-0.5 !rounded text-sm', duration === durationChoice.hours ? 'bg-orange text-white' : 'bg-grey-100 !text-grey-700')}
                          onClick={() => setDuration(durationChoice.hours)}>{durationChoice.hours}h
                        </button>
                        <span
                          className={"text-opacity-70 text-xs"}>{(getOdds(getCryptoBoost(payload.crypto._id), getDurationBoost(durationChoice.hours))).toFixed(2)}</span>
                      </div>
                    })}
                  </div>

                  {/* Stake */}
                  <div className="flex gap-2 w-full">

                    <div className={classNames("flex flex-col items-end w-full gap-2", isDisplayed ? '' : 'hidden')}>

                      <div className={classNames("w-full flex gap-4")}>

                        <div className='pb-[4px]'>
                          <RapidDecrementButtonV2 count={amount} callback={(a) => {
                            setAmount(a)
                          }}/>
                        </div>

                        <div className={"flex center flex-col grow h-full"}>
                          <QuantityCoinComponent quantity={amount} size={16} big={true}/>
                          <p className={"font-bold leading-3"}>Stake</p>
                        </div>

                        <div className='pb-[4px]'>
                          <RapidIncrementButtonV2 max={maxBetAmount} count={amount} callback={(a) => {
                            if (amount === maxBetAmount) {
                              if (maxBetThresholdCount >= 1) {
                                setShowMaxBetAmountReached(true);
                              } else {
                                setMaxBetThresholdCount((prev) => ++prev);
                              }
                            }

                            if (a > money.amount) {
                              setAmount(money.amount);
                            } else {
                              setAmount(a);
                            }
                          }}/>
                        </div>

                      </div>
                    </div>

                  </div>

                  {(isDisplayed && displayOddDetail) &&
                    <BetSummary
                      collectible={getChoosenCollectibleInfo(tournament, payload.crypto)}
                      duration={duration_boosts?.find((durationChoice) => {
                        return durationChoice.hours === duration
                      })}/>
                  }


                  {/*  Shield*/}
                  <div className={classNames(isDisplayed ? 'w-full flex justify-between' : 'hidden')}>

                    <div className={classNames('flex gap-x-2 items-center  bg-gray-200 rounded-xl p-1', cssShow(!!shield))} onClick={()=>setShield(undefined)}>
                      {shield && <><ShieldItemIcon h={24} item={shield}/><span
                        className={"text-xs"}>{shield.metadata?.value}%</span></>}
                    </div>

                    <div className={classNames('flex gap-x-2 items-center  bg-gray-200 rounded-xl p-1', cssShow(!!boost))} onClick={()=>setBoost(undefined)}>
                      {boost && <><BoostItemIcon h={24} item={boost}/><span
                        className={"text-xs"}>+ odd {Number(boost.metadata?.value).toFixed(1)}</span></>}
                    </div>

                    <button className={classNames("secondary !text-xs")} onClick={() => {
                      setShowShieldBoostDrawer(true);
                    }}><SparkIcon h={16}/>{" Power ups"}
                    </button>
                  </div>

                </div>;
              }
            })()
          }

          {subscribing && <div
            className={classNames("w-full flex items-center justify-center gap-5")}>
            <div>Registering your Bet...</div>
            <div className={"animate-bounce"}>
              <RocketIcon h={65}/>
            </div>
          </div>
          }
        </>
      }

      {!subscribing && (choiceDone && payload?.crypto) && (isTournamentRunning(tournament, tournament.currentUserSubscription) || hasTournamentEnded(tournament, tournament.currentUserSubscription)) &&
        <div className='w-full'>
          <hr className={`w-full border-t border-grey-100`}/>
          <PotentialEarningsAccordion
            items={userScore?.pumpOrDumpInputPayload.itemsApplied}
            collectibleXpReward={userScore?.collectibleXpReward}
            streakCount={userScore?.pumpOrDumpScorePayload.streakCount}
            streakMultiplier={userScore?.pumpOrDumpScorePayload.streakMultiplier}
            running={isTournamentRunning(tournament, tournament.currentUserSubscription)}
            stake={amount}
            xp={winning ? 100 : 0}
            gain={winning ? amount * getOdds(getCryptoBoost(payload.crypto._id), getDurationBoost(duration)) * (userScore?.pumpOrDumpScorePayload.streakMultiplier || 1) : 0}
            collectible={getChoosenCollectibleInfo(tournament, payload.crypto)}
            duration={duration_boosts?.find((durationChoice) => {
              return durationChoice.hours === duration
            })}
            gemBoost={gemsBoostActivated}
            crypoCode={payload.crypto.code}
            total={getOdds(getCryptoBoost(payload.crypto._id), getDurationBoost(duration))}
          />
        </div>
      }

      {(!choiceDone && choice) && canParticipate(tournament) &&
        <button className={"primary w-full mb-4"} onClick={() => {
          if (choice) {
            gtag('event', 'tournamentSubscribe', {
              update: !!tournament.currentUserSubscription,
              code: tournament.code,
              type: tournament.type
            });
            choose();
          } else {
            toastMessage('Select your bet')
          }

        }}>PLACE BET</button>
      }

      {!choiceDone && choice &&
        <div className='flex flex-col w-full gap-4'>

          <hr className={`w-full border-t border-grey-100`}/>

          <div className='flex w-full justify-between'>

            <p className='pl-2'>
              Potential earnings
            </p>

            <QuantityCoinComponent size={20} big={true}
                                   quantity={amount * getOdds(getCryptoBoost(payload?.crypto._id), getDurationBoost(duration))}/>

          </div>

        </div>
      }

      {showShieldBoostDrawer && inventory && hasElements(inventory) &&
        <ItemsSelectionDrawer inventory={inventory} open={showShieldBoostDrawer}
                              onClose={() => setShowShieldBoostDrawer(false)}
                              onItemChosen={(item: UserItem) => {
                                if (item.subFamily === 'shield') setShield(item);
                                if (item.subFamily === 'bet_boost') setBoost(item);
                              }}/>
      }

      {showShieldBoostDrawer && !hasElements(inventory) &&
        <PowerUpAlertSnackbar onClose={() => setShowShieldBoostDrawer(false)}/>
      }


      {showGemsAlert &&
        <GemsAlertSnackbar onClose={() => setShowGemsAlert(false)}/>
      }

      {showMaxBetAmountReached && payload && maxBetAmount &&
        <MaxBetAmountReachedSnackbar crypto={payload.crypto} maxAmount={maxBetAmount} onClose={() => {
          setShowMaxBetAmountReached(false);
          setMaxBetThresholdCount(0);
        }}/>
      }
    </div>
  );
}

export {PumpOrDumpItemComponent};