import React from 'react';
import Layout from "../Layout";

const NoMatch: React.FC = () => {

  return (
    <Layout>
      <div className={"flex justify-center items-center"}>
        <h2 className="font-sans">&#128169; The requested page does not exists &#128169;</h2>
      </div>
    </Layout>
  );
};

export {NoMatch}