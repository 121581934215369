import React, {useCallback} from 'react'
import {canParticipate, hasTournamentEnded, isTournamentRunning, Tournament} from "../../models/Tournament";
import {RegisterButton} from "../tournament/tournament-register-button.component";
import {useNavigate} from "react-router-dom";
import {useGetUserScoreQuery} from "../../apis/tournaments.api";
import {classNames} from "../../utils/Helper";
import {GainCoinComponent} from "../ui/gain-coin.component";
import {useIsDocumentVisible} from "../../hooks/useIsDocumentVisible";

export type Props = {
  tournament: Tournament;
}

const SquadItemComponent: React.FC<Props> = ({
                                               tournament,
                                             }) => {

  const navigate = useNavigate();

  const visibilityChange = useCallback((visibility: boolean) => {
    if (visibility) {
      refreshScore();
    }
  }, []);

  const {isVisible: isPageVisible} = useIsDocumentVisible(visibilityChange);

  const {
    data: subscription, refetch: refreshScore
  } = useGetUserScoreQuery({tournamentId: tournament?.id}, {
    skip: !tournament,
    pollingInterval: isPageVisible ? 5 * 60000 : undefined
  });

  return (<div
      className={classNames("flex flex-col items-center")}>

      <img className="w-1/2 md:w-1/4"
           src={process.env.REACT_APP_STATIC_ASSETS + "/images/squad_icon-min.png"}
           alt="Crypto Squad"/>

      <div className={"w-full flex flex-col items-center justify-center"}>

        {tournament.subscriptionsCount > 0 &&
          <div
            className={"text-xs text-gray-400 uppercase"}>{tournament.subscriptionsCount + ` player${(tournament.subscriptionsCount === 1 ? '' : 's')}`}</div>
        }

        {tournament.winner &&
          <div className={"text-end font-sans"}>{"#1 " + tournament.winner}</div>
        }

        <div className={"flex justify-between gap-x-2 py-2"}>
          {!(subscription && isTournamentRunning(tournament)) && canParticipate(tournament) &&
            <RegisterButton tournament={tournament} onClick={() => {
              gtag('event', 'tournamentSubscribe', {
                update: !!tournament.currentUserSubscription,
                code: tournament.code,
                type: tournament.type
              });
              navigate('/squad-registration', {state: {tournamentId: tournament.id}});
            }} userAlreadyRegistered={!!subscription}
            />
          }

          {subscription && tournament.type === 'squad' && isTournamentRunning(tournament) &&
            <button className={"primary self-end"} onClick={() => {
              gtag('event', 'checkSquadTx');
              navigate('/squad-tx?_sa_t_id=' + tournament.id, {
                state: {
                  tournamentId: tournament.id,
                }
              });
            }}>Trade</button>
          }

          {(isTournamentRunning(tournament) || hasTournamentEnded(tournament)) &&
            <button className={"secondary self-end"} onClick={() => {
              gtag('event', 'tournamentCheckRanking');
              navigate('/tournament-ranking?_sa_t_id=' + tournament.id);
            }}>Rank</button>
          }
        </div>

        {(subscription && isTournamentRunning(tournament) && subscription.squadScorePayload) &&
          <div className={"text-4xl py-4"}>
            <GainCoinComponent gain={subscription.squadScorePayload.totalGain}/>
          </div>
        }

      </div>

    </div>
  );
}

export {SquadItemComponent};