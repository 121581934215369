import React from 'react'
import {CryptoCurrency} from "../models/Wallet";
import {QuantityCoinComponent} from "./ui/quantity-coin.component";
import {classNames} from "../utils/Helper";
import {PerfComponent} from "./ui/perf.component";

export type Props = {
  cryptos: CryptoCurrency[] | undefined,
  selectedCryptos: string[],
  onDeselect: Function,
  onSelect: Function,
  classes?: string,
  imgClasses?: string,
  selectedArtifact?: JSX.Element;
  unSelectedArtifact?: JSX.Element;
  disabled?: boolean;
}

const CryptoSelectionElement: React.FC<Props> = ({
                                                   cryptos,
                                                   selectedCryptos,
                                                   onSelect,
                                                   onDeselect,
                                                   classes = "",
                                                   imgClasses = "",
                                                   unSelectedArtifact = <></>,
                                                   disabled = false
                                                 }) => {
  return (<>
      {cryptos && cryptos.map(o => {
        return <div key={o.code} onClick={() => {
          if (!disabled && selectedCryptos.indexOf(o._id) > -1) {
            onDeselect(o.code, o._id, o);
          } else {
            onSelect(o.code, o._id, o);
          }
        }}
                    className={classes + "relative flex gap-3 justify-start items-center cursor-pointer p-3 bg-white border border-grey-100 fat-grey-shadow-2 rounded-lg !flex-row " + (selectedCryptos.indexOf(o._id) !== -1 ? "opacity-100" : "opacity-100")}>
          <div
            className="w-8 h-8 p-0.5 bg-white rounded-[68px] fat-grey-shadow-1 border border-grey-100 justify-start items-start gap-4 inline-flex">
            <img className={"h-full w-full rounded-[40px] justify-center items-center flex"} src={o.logo} alt={o.code}
                 width={24} height={24}
                 title={o.name}/>
          </div>
          <div className={"flex flex-col items-start gap-y-1"}>
            <p className="text-center text-sm font-bold leading-none">{o.name}</p>
            <p className="text-center text-xxs font-bold leading-none uppercase text-gray-400">{o.code}</p>
          </div>


          <div className={classNames(o?.lastQuote?.price ? '' : 'hidden', 'grow flex justify-end text-xs gap-x-2')}>
            <QuantityCoinComponent size={15} quantity={Number(o.lastQuote.price)}/>

            <div className={"min-w-[75px] flex justify-end"}><PerfComponent
              perf={((Number(o.lastQuote.price) - Number(o?.quote24H?.price || o.lastQuote.price)) / Number(o.lastQuote.price)) * 100}/>
            </div>
          </div>

          {selectedCryptos.indexOf(o.code) === -1 &&
            <div className={"absolute right-0 bottom-0"}>
              <>{unSelectedArtifact}</>
            </div>
          }
        </div>;
      })}
    </>
  );
}

export {CryptoSelectionElement};