import {useEffect, useState} from "react";
import {isPushNotificationSupported} from "../utils/push/PushHelper";
import {useSubscribePushMutation, useUnsubscribePushMutation} from "../apis/push.api";
import {urlB64ToUint8Array} from '../utils/Helper';

const pushServerPublicKey = process.env.REACT_APP_PUBLIC_VAPID_KEY;

const pushNotificationSupported = isPushNotificationSupported();

export default function usePushNotifications() {
  //to manage the push server subscription
  const [error, setError] = useState<boolean | { name: string, message: string, code: number }>(false);
  //to manage errors
  const [workerRegistered, setWorkerRegistered] = useState(false);
  const [loading, setLoading] = useState(true);
  const [subscribe] = useSubscribePushMutation();
  const [unsubscribe] = useUnsubscribePushMutation();

  const [acceptNotif, setAcceptNotif] = useState<any>(false);
  useEffect(() => {
    // console.log('_app useEffect')

    const _doRegister = ()=>{
      // console.log('################################################_doRegister######################################################')
      if (window.Notification && pushServerPublicKey) {
        Notification.requestPermission(async function (status) {
          // console.log(status); // les notifications ne seront affichées que si "autorisées"
          setAcceptNotif(status);
          // new Notification("Nouvelle notif", {body:'corps de la notif', icon:'/image/logo.svg'});
          // console.log("request Permission");
          if (status == 'granted') {
            if ("serviceWorker" in navigator) {
              navigator.serviceWorker.register("/sw.js").then(
                async function (registration) {

                  const subscribeNotifs = async () => {
                    // console.log('subscribeNotifs');
                    // await registration.update();
                    // console.log("Service Worker registration successful with scope: ", registration.scope);
                    // window.safari.pushNotification
                    const subscription = await registration.pushManager
                      .subscribe({
                        applicationServerKey: urlB64ToUint8Array(pushServerPublicKey),
                        // applicationServerKey: pushServerPublicKey,
                        userVisibleOnly: true
                      });

                    await subscribe(subscription);
                    setWorkerRegistered(true);
                    // console.log('Service Worker notification subscription success');
                  }

                  if (registration.active && registration.pushManager) {
                    await subscribeNotifs();
                  }

                  registration.addEventListener("updatefound", () => {
                    // If updatefound is fired, it means that there's
                    // a new service worker being installed.
                    const installingWorker = registration.installing;
                    console.log(
                      "A new service worker is being installed:",
                      installingWorker,
                    );

                    // You can listen for changes to the installing service worker's
                    // state via installingWorker.onstatechange
                    if (installingWorker) {
                      installingWorker.addEventListener('statechange', async (e) => {
                        // console.log(`service worker statechange ${JSON.stringify(e)}`);
                        if (registration.active && registration.pushManager) {
                          await subscribeNotifs();
                        }
                      });
                    }
                  });


                },
                function (err) {
                  console.log("Service Worker registration failed: ", err);
                }
              );
            }
          } else if (status == 'denied') {
            await unsubscribe()
          }
        });
      }

      document.body.removeEventListener('click', _doRegister);
      document.body.removeEventListener('keydown', _doRegister);
      document.body.removeEventListener('scroll', _doRegister);
    }

    document.body.addEventListener('click', _doRegister);
    document.body.addEventListener('keydown', _doRegister);
    document.body.addEventListener('scroll', _doRegister);



  }, [acceptNotif])

  /**
   * returns all the stuff needed by a Component
   */
  return {
    pushNotificationSupported,
    error,
    loading,
    workerRegistered
  };
}