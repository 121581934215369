import React from 'react';

const TripleGemsIcon = () => (

  <svg width="48" height="38" viewBox="0 0 48 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6193_47099)">
      <path d="M34.2034 10.5412L31.3056 9.76474L27.1137 13.8179L31.4604 14.9826L34.2034 10.5412Z" fill="#BDDDF4" />
      <path d="M42.5686 17.9607L40.9649 12.3546L38.0671 11.5781L38.2219 16.796L42.5686 17.9607Z" fill="#5DADEC" />
      <path d="M38.2219 16.7926L42.5686 17.9573L32.5118 24.5801L38.2219 16.7926Z" fill="#4289C1" />
      <path
        d="M31.4604 14.9826L27.1137 13.8179L32.5118 24.5818L31.4604 14.9826ZM34.2034 10.5412L31.4604 14.9826L38.2219 16.7943L38.0671 11.5765L34.2034 10.5412Z"
        fill="#8CCAF7" />
      <path d="M32.5118 24.5836L31.4604 14.9844L38.2219 16.7961L32.5118 24.5836Z" fill="#5DADEC" />
    </g>
    <g clipPath="url(#clip1_6193_47099)">
      <path d="M9.38772 11.576L6.48994 12.3524L4.88626 17.9585L9.23292 16.7938L9.38772 11.576Z" fill="#BDDDF4" />
      <path d="M20.3411 13.8158L16.1492 9.76261L13.2515 10.5391L15.9944 14.9805L20.3411 13.8158Z" fill="#5DADEC" />
      <path d="M15.9944 14.9809L20.3411 13.8162L14.943 24.5801L15.9944 14.9809Z" fill="#4289C1" />
      <path
        d="M9.23292 16.7938L4.88626 17.9585L14.943 24.5813L9.23292 16.7938ZM9.38772 11.576L9.23292 16.7938L15.9944 14.9821L13.2514 10.5407L9.38772 11.576Z"
        fill="#8CCAF7" />
      <path d="M14.943 24.5804L9.23291 16.793L15.9944 14.9812L14.943 24.5804Z" fill="#5DADEC" />
    </g>
    <g clipPath="url(#clip2_6193_47099)">
      <path d="M20.9762 3.33203H15.9762L10.9762 11.6654H18.4762L20.9762 3.33203Z" fill="#BDDDF4" />
      <path d="M37.6428 11.6654L32.6428 3.33203H27.6428L30.1428 11.6654H37.6428Z" fill="#5DADEC" />
      <path d="M30.1429 11.6641H37.6429L24.3096 26.6641L30.1429 11.6641Z" fill="#4289C1" />
      <path
        d="M18.4762 11.6654H10.9762L24.3095 26.6654L18.4762 11.6654ZM20.9762 3.33203L18.4762 11.6654H30.1429L27.6429 3.33203H20.9762Z"
        fill="#8CCAF7" />
      <path d="M24.3095 26.6641L18.4762 11.6641H30.1429L24.3095 26.6641Z" fill="#5DADEC" />
    </g>
    <ellipse cx="24" cy="30.9987" rx="10.6667" ry="2.66667" fill="black" fillOpacity="0.3" />
    <defs>
      <clipPath id="clip0_6193_47099">
        <rect width="16" height="14" fill="white" transform="translate(28.4078 8.98828) rotate(15)" />
      </clipPath>
      <clipPath id="clip1_6193_47099">
        <rect width="16" height="14" fill="white" transform="translate(3.59216 13.1289) rotate(-15)" />
      </clipPath>
      <clipPath id="clip2_6193_47099">
        <rect width="26.6667" height="23.3333" fill="white" transform="translate(10.9762 3.33203)" />
      </clipPath>
    </defs>
  </svg>

);

export default TripleGemsIcon;
