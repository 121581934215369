import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import Layout from "../Layout";
import {SecondaryNavigation} from "../components/ui/secondary-navigation.component";
import {useNavigate, useParams} from "react-router-dom";
import {useGetUserQuery} from "../apis/users.api";
import {QuantityCoinComponent} from "../components/ui/quantity-coin.component";
import {classNames, formatGainPercent, formatPercentFloored, hasElements} from "../utils/Helper";
import {GameStats, useGetGamingStatsQuery, useGetUserBestRankQuery} from "../apis/game.api";
import * as _ from "lodash";
import {GreenCheckIcon} from "../components/ui/check-icon";
import {MoneyMoothIcon} from "../components/ui/money-mooth-icon";
import {MoneyBagIcon} from "../components/ui/money-bag-icon";
import {TrophyIcon} from "../components/ui/trophy-icon";
import {ChartIcon} from "../components/ui/chart-icon";
import {toastError, toastMessage, toastSuccess} from "../utils/ToastUtils";
import * as htmlToImage from "html-to-image";
import axios from "axios";
import {usePlayerFromPseudoParameter} from "../hooks/usePseudoParam";
import {UserInfosComponent} from "../components/users/user-infos.component";
import { BackButton } from '../components/ui/back-button';

const getHeaderColor = (type: string) => {

  if (type === 'squad') {
    return "bg-yellow";
  } else if (type === 'head_to_head') {
    return "bg-turquoise";
  } else {
    return "bg-turquoise";
  }
}

const snapHtml = (imageType: string, opts: { width: number, height: number }, htmlId: string) => {
  const node = document.getElementById(htmlId);
  const log = document.getElementById('snap-test');
  if (!log) toastError('no log element found');
  if (node && log) {
    log.innerHTML += ('<div>Node found</div>')
    log.innerHTML += ('<div>executing toBlob</div>')
    htmlToImage.toBlob(node, {...opts, pixelRatio: 1})
      .then(async function (blob) {
        toastMessage('Image blob generated')
        if (blob) {
          const f = new File([blob], `lol.png`, {
            type: 'image/webp',
            lastModified: new Date().getTime(),
          });

          const formData = new FormData();
          formData.append("client_file", f);
          try {
            toastMessage('starting snap upload')
            const res = await axios.post(`/users/uploadEndOfWeekSnapPicture?type=${imageType}`, formData, {
              headers: {'Content-Type': 'multipart/form-data'}
            });
          } catch (e) {
            log.innerHTML += ('<div class="text-red-500">File cannot be uploaded. Try another one.</div>')
            toastError('File cannot be uploaded. Try another one.')
          } finally {
            toastSuccess('Uploaded');
          }
        }
      })
      .catch(function (error) {
        log.innerHTML += (`<div class="text-red-500">${JSON.stringify(error)}</div>`)
        toastError(`Error while generatin snap : ${JSON.stringify(error)}`);
        console.error('oops, something went wrong!', error);
      });
  }
}

const StatsPage: React.FC = () => {
  const {data: user} = useGetUserQuery();
  const {pseudo: pseudoParam} = useParams<{ pseudo: string }>();
  const [pseudo, setPseudo] = useState<string | undefined>(pseudoParam);
  const [totalStat, setTotalStat] = useState<{
    investment: number,
    moneyGain: number,
    roi: number,
    totalPlayed: number,
    biggestGain: number,
    type: string,
  }>({
    investment: 0,
    moneyGain: 0,
    roi: 0,
    totalPlayed: 0,
    biggestGain: 0, type: ''
  });


  useEffect(() => {
    if (pseudoParam) {
      setPseudo(pseudoParam);
    } else if (!pseudoParam && user) {
      setPseudo(user.pseudo);
    }

  }, [user, pseudoParam]);

  const navigate = useNavigate();
  const {data: stats} = useGetGamingStatsQuery({pseudo: pseudo}, {skip: !pseudo});
  const {data: bestRank} = useGetUserBestRankQuery({pseudo}, {skip: !pseudo});
  const {player, isConnectedPlayer} = usePlayerFromPseudoParameter();

  const [orderedStats, setOrderedStates] = useState<Array<GameStats>>([]);
  useEffect(() => {
    if (stats && hasElements(stats)) {
      const s = _.cloneDeep(totalStat);
      s.investment = stats.map(s => s.investment).reduce((c, p) => c + p);
      s.moneyGain = stats.map(s => s.moneyGain).reduce((c, p) => c + p);
      s.roi = s.moneyGain / s.investment * 100;
      s.totalPlayed = stats.map(s => s.totalPlayed).reduce((c, p) => c + p);
      setTotalStat(s);
      const _ordered: Array<GameStats> = [...stats].sort((a, b) => a.type === 'squad' ? -1 : 1);
      setOrderedStates(_ordered);
    }
  }, [stats]);

  const isConnectedUser = useCallback(() => {
    let res = false;
    if (user && player) {
      res = user._id === player.userId;
    }
    return res;
  }, [player, user]);

  return (
    <Layout>

      <div
        className="fixed top-10 w-screen flex flex-col bg-gradient-to-b from-orange-700 to-orange pt-12 p-4 center pb-16">
        <div className={classNames("flex justify-between w-full max-w-xl pb-4 mt-1 center")}>

          <BackButton label={""} callback={() => navigate(-1)}/>

          <SecondaryNavigation navItems={[
            {path: `/collectibles/${player?.pseudo || ''}`, name: 'Collectibles'},
            {path: `/stats/${player?.pseudo || ''}`, name: 'Statistics'},
          ]}/>

          <div className="invisible" onClick={() => navigate('/profileDetails')}>
            <BackButton label={""} callback={() => navigate(-1)}/>
          </div>

        </div>
      </div>


      <div className={"relative mt-12 w-full max-w-xl"}>

        <UserInfosComponent player={player}/>

        {stats &&
          <div className={"flex flex-col gap-y-4 mx-auto max-w-5xl mt-8"}>

            <div className={"grid grid-cols-2 gap-x-2 gap-y-3"}>

              <div className={"flat-card-centered-col gap-y-1"}>
                <span className={"bg-whitesmoke-300 rounded-full p-2"}> <ChartIcon/></span>
                <span className={"gray-card-title"}>played</span>
                <span className={"font-bold"}>{totalStat.totalPlayed}</span>
              </div>

              <div className={"flat-card-centered-col gap-y-1"}>
                <span className={"bg-whitesmoke-300 rounded-full p-2"}><TrophyIcon/></span>
                <span className={"gray-card-title"}>Best Rank</span>{bestRank ? bestRank : '-'}
              </div>

              <div className={"flat-card-centered-col gap-y-1"}>
                <span className={"bg-whitesmoke-300 rounded-full p-2"}> <MoneyBagIcon/></span>
                <span className={"gray-card-title"}>Total Invest</span><QuantityCoinComponent
                quantity={totalStat.investment}/>
              </div>

              <div className={"flat-card-centered-col gap-y-1"}>
                <span className={"bg-whitesmoke-300 rounded-full p-2"}><MoneyMoothIcon/></span>
                <span
                  className={"gray-card-title"}>Total roi</span><span
                className={"font-bold"}>{formatGainPercent(totalStat.roi)}</span>
              </div>
            </div>

            {orderedStats.map((s, index) => {
              return <div key={`stats_${index}`} className={"flex flex-col"}>
                <div
                  className={classNames(`rounded-t-2xl rounded-b-none uppercase pb-4 px-4 pt-2 font-bold flex justify-between`, getHeaderColor(s.type))}>
                  <span className={`uppercase text-white`}>{`${s.type === 'squad' ? 'Portfolio' : 'Bets'}`}</span>
                </div>

                <div className={"flex flex-wrap gap-x-4 gap-y-2 flat-card mt-[-12px]"}>

                  <div className={"flat-card-centered-col w-full gap-y-1"}>
                    <span className={"bg-whitesmoke-300 rounded-full p-2"}><MoneyBagIcon/></span>
                    <span className={"gray-card-title"}>BIGGEST WIN</span>
                    <span><QuantityCoinComponent quantity={s.biggestGain}/></span>
                  </div>

                  <div className={"flat-card-centered-col grow gap-y-1"}>
                    <span className={"bg-whitesmoke-300 rounded-full p-2"}><ChartIcon/></span>
                    <span className={"gray-card-title"}>PLAYED</span>
                    <span className={"font-bold"}>{s.totalPlayed}</span>
                  </div>

                  {s.type === 'head_to_head' &&
                    <div className={"flat-card-centered-col grow gap-y-1"}>
                      <span className={"bg-whitesmoke-300 rounded-full p-2"}><GreenCheckIcon/></span>
                      <span
                        className={"gray-card-title"}>HIT RATE</span><span
                      className={"font-bold"}>{formatPercentFloored(s.totalPlayedWithGain / s.totalPlayed * 100)}</span>
                    </div>
                  }

                  {s.type !== 'head_to_head' &&
                    <div className={"flat-card-centered-col grow gap-y-1"}>
                      <span className={"bg-whitesmoke-300 rounded-full p-2"}><TrophyIcon/></span>
                      <div className={"flex gap-x-4 text-xs"}>
                        <div className={"flex flex-col justify-center items-center"}>
                          <span className={"gray-card-title text-xs"}>1ST</span>
                          <span className={"font-bold"}>{s.total1st}</span>
                        </div>
                        <div className={"flex flex-col justify-center items-center"}>
                          <span className={"gray-card-title text-xs"}>2ND</span>
                          <span className={"font-bold"}>{s.total2nd}</span>
                        </div>
                        <div className={"flex flex-col justify-center items-center"}>
                          <span className={"gray-card-title text-xs"}>3RD</span>
                          <span className={"font-bold"}>{s.total3rd}</span>
                        </div>
                      </div>

                    </div>
                  }

                  <div className={"flat-card-centered-col grow gap-y-1"}>
                    <span className={"bg-whitesmoke-300 rounded-full p-2"}><MoneyMoothIcon/></span>
                    <span
                      className={"gray-card-title"}>ROI</span><span
                    className={"font-bold"}>{formatGainPercent(s.investment > 0 ? (s.moneyGain / s.investment * 100) : 0)}</span>
                  </div>
                </div>

              </div>
            })}
            <div>
            </div>
          </div>
        }
      </div>
    </Layout>
  );
};

export {StatsPage}