import React from "react";
import {LinearProgress, linearProgressClasses, styled} from "@mui/material";

const BorderLinearProgress = styled(LinearProgress)(({theme}) => ({
  height: 10,
  borderRadius: 8,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#E9E9E9'//theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 8,
    backgroundColor: theme.palette.mode === 'light' ? '#EA5C28' : '#EA5C28',
  },
}));

const useAnimatedProgress = (value: number, animated: boolean = true) => {
  const [progress, setProgress] = React.useState(animated ? 0 : value);

  React.useEffect(() => {
    let timer: any = null;
    if (animated) {
      timer = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress === value) {
            if (timer) clearInterval(timer);
          }
          const diff = 10;
          return Math.min(oldProgress + diff, value);
        });
      }, 100);
    } else {
      setProgress(value)
      clearInterval(timer);
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [animated]);

  return progress;
}


const BorderLinearProgressAnimated: React.FC<{ value: number, animated?: boolean }> = ({value, animated = false}) => {
  const progress = useAnimatedProgress(value, animated);
  return (
    <BorderLinearProgress variant="determinate" value={progress}/>
  );
}


const BronzeLinearProgress = styled(LinearProgress)(({theme}) => ({
  height: 10,
  borderRadius: 8,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#E9E9E9'//theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 8,
    backgroundColor: '#a6692e',
  },
}));

const BronzeLinearProgressAnimated: React.FC<{ value: number, animated?: boolean }> = ({value, animated = false}) => {
  const progress = useAnimatedProgress(value, animated);
  return (
    <BronzeLinearProgress variant="determinate" value={progress}/>
  );
}

const SilverLinearProgress = styled(LinearProgress)(({theme}) => ({
  height: 10,
  borderRadius: 8,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#E9E9E9'//theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 8,
    backgroundColor: '#9599ad',
  },
}));

const SilverLinearProgressAnimated: React.FC<{ value: number, animated?: boolean }> = ({value, animated = false}) => {
  const progress = useAnimatedProgress(value, animated);
  return (
    <SilverLinearProgress variant="determinate" value={progress}/>
  );
}


const GoldLinearProgress = styled(LinearProgress)(({theme}) => ({
  height: 10,
  borderRadius: 8,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#E9E9E9'//theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 8,
    backgroundColor: '#f19900',
  },
}));


const GoldLinearProgressAnimated: React.FC<{ value: number, animated?: boolean }> = ({value, animated = false}) => {
  const progress = useAnimatedProgress(value, animated);
  return (
    <GoldLinearProgress variant="determinate" value={progress}/>
  );
}


const PlatinumLinearProgress = styled(LinearProgress)(({theme}) => ({
  height: 10,
  borderRadius: 8,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#E9E9E9'//theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 8,
    backgroundColor: '#60756c',
  },
}));

const PlatinumLinearProgressAnimated: React.FC<{ value: number, animated?: boolean }> = ({value, animated = false}) => {
  const progress = useAnimatedProgress(value, animated);
  return (
    <PlatinumLinearProgress variant="determinate" value={progress}/>
  );
}

const DiamondLinearProgress = styled(LinearProgress)(({theme}) => ({
  height: 10,
  borderRadius: 8,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#E9E9E9'//theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 8,
    backgroundColor: '#9de1ea',
  },
}));

const DiamondLinearProgressAnimated: React.FC<{ value: number, animated?: boolean }> = ({value, animated = false}) => {
  const progress = useAnimatedProgress(value, animated);
  return (
    <DiamondLinearProgress variant="determinate" value={progress}/>
  );
}

const MasterLinearProgress = styled(LinearProgress)(({theme}) => ({
  height: 10,
  borderRadius: 8,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#E9E9E9'//theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 8,
    backgroundColor: '#a11d25',
  },
}));

export {
  BorderLinearProgressAnimated,
  BronzeLinearProgressAnimated,
  SilverLinearProgressAnimated,
  GoldLinearProgressAnimated,
  PlatinumLinearProgressAnimated,
  DiamondLinearProgressAnimated,
  BorderLinearProgress,
  BronzeLinearProgress,
  SilverLinearProgress,
  GoldLinearProgress,
  PlatinumLinearProgress,
  DiamondLinearProgress,
  MasterLinearProgress
}
