import React, {useEffect, useRef, useState} from "react";
import {classNames, hasElements} from "../../utils/Helper";
import {CollectibleLoaderComponent} from "../ui/list-loader.component";
import {useWindowVirtualizer} from '@tanstack/react-virtual'
import {useGetInfiniteCollectibleListQuery} from "../../apis/collectibles.api";
import * as _ from "lodash";
import {CollectibleDrawer} from "./collectible-drawer.component";
import {CollectibleCardComponent} from "./collectible-card.component";
import {useGetUserQuery} from "../../apis/users.api";
import {CollectibleCard} from "../../dto/collectibles/collectibles-list-response.dto";

const InfiniteCollectibleComponent: React.FC<{
  shinyCollectibles?: string[],
  mode: 'all' | 'upgrades',
  drawerMode?: 'go_to_shop' | 'upgrade' | 'none',
  classes?: string[] | string,
  onEmpty?: Function,
  userId?: string,
  collectibleToDisplay?: CollectibleCard,
}> = ({
        userId,
        mode = 'all',
        shinyCollectibles = [],
        collectibleToDisplay,
        drawerMode,
        classes = [],
        onEmpty = _.noop()
      }) => {

  const {data: user} = useGetUserQuery();
  const [empty, setEmpty] = useState<boolean>(false);
  const ldRef = useRef<HTMLDivElement>(null);
  const [cardDetail, setCardDetail] = useState<CollectibleCard | undefined>(collectibleToDisplay);
  const tableContainerRef = React.useRef<HTMLDivElement>(null)

  const [pageIndex, setPageIndex] = useState<number | null>(null);
  const {
    data: collectiblesData,
    isFetching: areCollectibleFetching,
    isLoading: areCollectibleLoading
  } = useGetInfiniteCollectibleListQuery({
    userId: userId || user?._id,
    mode,
    pageIndex,
    pageSize: 100
  }, {refetchOnFocus: false});

  useEffect(() => {
    setPageIndex(0);
    if (tableContainerRef?.current) tableContainerRef.current.scrollTop = 0;
  }, []);

  const totalDBRowCount = collectiblesData?.totalCount ?? 0
  const totalFetched = collectiblesData?.collectibles.length ?? 0
  const allRows = collectiblesData ? _.chunk(collectiblesData.collectibles, 2) : [];
  const hasNextPage = totalFetched < totalDBRowCount;

  //called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
  const fetchMoreOnBottomReached = (containerRefElement?: HTMLElement | null) => {
    if (containerRefElement) {
      const {scrollHeight, scrollTop, clientHeight} = containerRefElement;
      //once the user has scrolled within 500px of the bottom of the table, fetch more data if we can
      if (
        scrollHeight - scrollTop - clientHeight < 250 &&
        !areCollectibleFetching &&
        hasNextPage &&
        totalFetched < totalDBRowCount
      ) {

        setPageIndex((prev) => {
          // console.log(`[setPageIndex]increment page = ${prev + 1}, totalFetched=${totalFetched}, totalDBRowCount=${totalDBRowCount}, playersData?.pageIndex = ${collectiblesData?.pageIndex}/ prev=${prev}___________________________________________________________________________`);
          if (collectiblesData?.pageIndex == prev) {
            // console.log(`[setPageIndex] incremented, fetching new Page ...`)
            return prev + 1;
          } else {
            return prev
          }
        });
      }
    }
  }

  useEffect(() => {
    if (collectibleToDisplay) {
      setCardDetail(collectibleToDisplay);
    }
  }, [collectibleToDisplay]);

  useEffect(() => {
    const func = () => {
      fetchMoreOnBottomReached(window.document.documentElement as HTMLElement)
    }

    const scroller = window.document.documentElement;
    scroller?.addEventListener('scroll', func);

    const it = setInterval(() => {
      fetchMoreOnBottomReached(scroller);
    }, 200);

    return () => {
      scroller?.removeEventListener('scroll', func);
      clearInterval(it);
    }
  }, [fetchMoreOnBottomReached])

  const rowVirtualizer = useWindowVirtualizer({
    count: hasNextPage ? allRows.length + 1 : allRows.length,
    estimateSize: () => 200, //estimate row height for accurate scrollbar dragging
    //measure dynamic row height, except in firefox because it measures table border height incorrectly
    measureElement:
      typeof window !== 'undefined' &&
      navigator.userAgent.indexOf('Firefox') === -1
        ? element => element?.getBoundingClientRect().height
        : undefined,
    overscan: 10,
  });

  useEffect(() => {
    setEmpty(false);
    if (!areCollectibleFetching && !hasElements(allRows)) {
      setEmpty(true);
      if (onEmpty) onEmpty();
    }
  }, [areCollectibleFetching, allRows]);

  return (
    <div id="infinite_collectibles_list" ref={ldRef} className={classNames(classes)}>
      {areCollectibleLoading &&
        <div className={"flex justify-center"}>
          <div className={"max-w-md"}>
            <CollectibleLoaderComponent/>
          </div>
        </div>
      }

      {empty &&
        <div className={"w-full text-gray-400 italic text-center"}>No cards</div>
      }

      <div id="list_scroller" ref={tableContainerRef}
           className={classNames("sticky w-full z-20 flex flex-col items-center rounded-tl-2xl rounded-tr-2xl")}>

        <div className={`relative w-full max-w-md flex flex-wrap`}
             style={{height: `${rowVirtualizer.getTotalSize()}px`}}>
          {rowVirtualizer.getVirtualItems().map((virtualRow, index) => {
            const row = allRows[virtualRow.index];
            const isLoaderRow = virtualRow.index > allRows.length - 1;
            const item1 = !isLoaderRow ? row[0] : null;
            const item2 = !isLoaderRow && row.length === 2 ? row[1] : null;

            return <div
              data-index={virtualRow.index} //needed for dynamic row height measurement
              key={isLoaderRow ? `loader_${virtualRow.index}` : item1?.name || '' + index}
              onClick={() => {
                // if (!isLoaderRow) nav(`/stats/${row.pseudo}`);
              }}
              style={{
                display: 'flex',
                position: 'absolute',
                transform: `translateY(${virtualRow.start}px)`, //this should always be a `style` as it changes on scroll
                width: '100%',
              }}
              className={classNames('h-[200px] flex w-full px-4 py-2')}>

              {isLoaderRow ?
                <div
                  className={`w-full flex justify-center h-[48px] py-2 text-opacity-40 text-sm`}>Loading more...</div>
                :
                <div className={"w-full justify-center flex gap-x-2 "}>
                  {item1 &&
                    <>
                      {/*styledBackgroundImage passed as false for performance issue on low end devices*/}
                      <CollectibleCardComponent onClick={() => {
                        setCardDetail(item1);
                        // if (item1?.unlocked) setCardDetail(item1);
                      }} collectible={item1}
                                                onUpgrade={!userId || userId === user?._id ? () => setCardDetail(item1) : undefined}
                                                shineEffect={!!shinyCollectibles.find(c => c === item1.cryptoCurrencyId)}
                                                styledBackgroundImage={false}/>
                      {item2 &&
                        <CollectibleCardComponent onClick={() => {
                          setCardDetail(item2);
                          // if (item2?.unlocked) setCardDetail(item2);
                        }} collectible={item2}
                                                  onUpgrade={!userId || userId === user?._id ? () => setCardDetail(item2) : undefined}
                                                  shineEffect={!!shinyCollectibles.find(c => c === item2.cryptoCurrencyId)}
                                                  styledBackgroundImage={false}/>
                      }
                    </>
                  }

                </div>
              }

            </div>;
          })}
        </div>
      </div>

      {cardDetail &&
        <CollectibleDrawer collectible={cardDetail}
                           open={!!cardDetail}
                           onClose={() => setCardDetail(undefined)}
                           mode={drawerMode}/>
      }
    </div>
  )
}

export {InfiniteCollectibleComponent};