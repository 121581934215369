import React from 'react';
import Layout from "../Layout";
import {useNavigate} from "react-router-dom";
import {SecondaryNavigation} from "../components/ui/secondary-navigation.component";
import {useGetPlayerQuery} from "../apis/users.api";
import {BackButton} from "../components/ui/back-button";
import {useLocation} from "react-router";
import {TournamentListComponent} from "../components/tournament/tournaments-list.component";
import {useGetCycleTournamentsQuery} from "../apis/tournaments.api";
import {hasElements} from "../utils/Helper";
import {MagnifyingGlassIcon} from "../components/ui/magnifying-glass-icon";
import {getFirstPlayerItemsComp} from "../components/users/podium.component";


const SpyBetPage: React.FC = () => {

  const {state} = useLocation();
  const {
    data: spiedGames,
    isLoading: areNextLoading,
  } = useGetCycleTournamentsQuery({type: ['head_to_head', 'pump_or_dump'], pseudo: state.pseudo}, {});
  const {data: player} = useGetPlayerQuery({pseudo: state.pseudo}, {skip: !state.pseudo || state.pseudo == ''});
  const navigate = useNavigate();

  return (
    <Layout>
      <div
        className="w-screen fixed top-12 pt-12 p-4 center pb-16 flex flex-col bg-gradient-to-b from-orange-700 to-orange ">
        <div className="pt-2 pb-2 w-full px-4 flex justify-center">

          <div className='flex justify-between center w-full max-w-md'>
            <span className={""}>
              <BackButton label={""} callback={() => navigate(-1)}/></span>
            <SecondaryNavigation navItems={[
              {path: '/spy/bets', name: 'Bets', state: {pseudo: state.pseudo}},
              {path: '/portfolio', name: 'Invest', state: {pseudo: state.pseudo}},
            ]}/>
            <span className={"invisible"}>
            </span>
          </div>
        </div>

      </div>

      <div className={"mt-20 w-full max-w-md z-20"}>

        {!areNextLoading && !hasElements(spiedGames) &&
          <div className={"h-full flex center flex-col h-full mt-20 gap-y-4"}>
            <div className={"text-2xl"}>Nothing to spy here yet</div>
            <MagnifyingGlassIcon h={200}/>
          </div>
        }

        {hasElements(spiedGames) && player &&
          <div className="">
            <div className={"text-black p-4 flat"}>
              {getFirstPlayerItemsComp(player, 0, 20)}
            </div>

            <TournamentListComponent tournaments={spiedGames || []} displayTournamentsDate={false}
                                     displayBetBottomBar={false}/>
          </div>
        }


      </div>


    </Layout>
  );
};

export {
  SpyBetPage
}