import {Action, configureStore, ThunkAction} from '@reduxjs/toolkit';
import {authApi} from '../apis/auth.api';
import {coinbaseApi} from "../apis/coinbase.api";
import video from '../slices/video.slice';
import survey from '../slices/survey.slice';
import {logoutApi} from "../apis/logout.api";
import {canvasApi} from "../apis/canvas.api";
import {emptySplitApi} from "../apis/empty.api";
import {rtkQueryErrorLogger} from "../apis/rtk-error-middleware";
import {squadSlice} from "../pages/squad/squadSlice";
import {tournamentSlice} from "../slices/tournamentSlice";
import {incomeSlice} from "../slices/incomeSlice";
import {setupListeners} from "@reduxjs/toolkit/query";
import {cartSlice} from "../slices/shopSlice";
import { gemSlice } from '../slices/gemSlice';

export const store = configureStore({
  reducer: {
    [emptySplitApi.reducerPath]: emptySplitApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [coinbaseApi.reducerPath]: coinbaseApi.reducer,
    [canvasApi.reducerPath]: canvasApi.reducer,
    [logoutApi.reducerPath]: logoutApi.reducer,
    video,
    survey,
    squad: squadSlice.reducer,
    subscribingTournament: tournamentSlice.reducer,
    cart: cartSlice.reducer,
    income: incomeSlice.reducer,
    gems: gemSlice.reducer

  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(rtkQueryErrorLogger)
      .concat(emptySplitApi.middleware)
      .concat(coinbaseApi.middleware)
      .concat(canvasApi.middleware)
});


export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
  RootState,
  unknown,
  Action<string>>;

// enable listener behavior for the store
setupListeners(store.dispatch)