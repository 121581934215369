import * as React from 'react';
import {useEffect, useMemo, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useGetPlayerQuery} from "../apis/users.api";
import {SnapshotWeekResult} from "../components/users/snapshot-week-result.component";
import {BkTanjiIcon} from "../components/ui/bk-tanji-icon";
import UnauthenticatedLayout from "../UnauthenticatedLayout";
import {useIsAuth} from "../hooks/useIsAuth";

/**
 * this url is just used to display Twitter card image containing the stat image when tweeting.
 *
 * That is why there is an immediate redirect to signup. The goal is to win a signup
 * @constructor
 */
const TwitterCardLinkPage: React.FC = () => {
  const {pseudo: pseudoParam} = useParams<{ pseudo: string }>();
  const [pseudo, setPseudo] = useState<string | undefined>(pseudoParam);
  const {data: player} = useGetPlayerQuery({pseudo}, {skip: !pseudo});
  const navigate = useNavigate();
  const ref = useMemo<string>(() => new URLSearchParams(window.location.search).get('tbrefid') || '', []);
  const {isAuth, isAuthLoading} = useIsAuth();

  useEffect(() => {
    if (!isAuth && !isAuthLoading) navigate(`/signup?tbrefid=${ref}`);
  }, [isAuth, isAuthLoading]);

  useEffect(() => {
    if (pseudoParam)
      setPseudo(pseudoParam);
  }, [pseudoParam]);

  return (<>
      <div className={"fixed w-screen h-screen bg-orange z-0 flex justify-end items-center"}>
        <BkTanjiIcon factor={1.3}/>
      </div>
      <UnauthenticatedLayout>

        <div className={"my-10 w-full max-w-lg rounded overflow-hidden"}>
          {player && <SnapshotWeekResult player={player}/>}
        </div>

        <button className={"secondary"} onClick={() => navigate('/home')}>Home</button>

      </UnauthenticatedLayout>
    </>
  );
};

export {TwitterCardLinkPage};