import React from 'react';

const BoostIcon: React.FC<{ h?: number, bgColor?: string, sideColor?: string, sideColorDark?: string }> = ({
                                                                                                             h = 16,
                                                                                                             bgColor = '#04ADB2',
                                                                                                             sideColor = '#EA5C28',
                                                                                                             sideColorDark = '#BA481E'
                                                                                                           }) => (
  <svg width={h} height={h} viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.7148 1.33334L5.44012 1.33334L5.44012 15.0476L15.7148 15.0476L15.7148 1.33334Z" fill={bgColor}/>
    <path d="M19.2859 16V0L21.4287 2V14L19.2859 16Z" fill={sideColorDark}/>

    <path d="M2.14286 -1.77116e-07L2.14286 16L-1.84933e-07 14L-1.29453e-06 2L2.14286 -1.77116e-07Z"
          fill={sideColorDark}/>

    <path
      d="M21.8574 5.33334H21.4289V10.6667H21.8574C22.4097 10.6667 22.8574 10.219 22.8574 9.66668V6.33334C22.8574 5.78106 22.4097 5.33334 21.8574 5.33334Z"
      fill="#FDC605"/>

    <path
      d="M22.8574 9.95239V6.04763C22.8574 5.65314 22.5376 5.33334 22.1431 5.33334V10.6667C22.5376 10.6667 22.8574 10.3469 22.8574 9.95239Z"
      fill="black" fillOpacity="0.2"/>

    <path d="M5.0007 16L2.14356 16L2.14356 0L5.11043 2.45225e-07L5.71483 1.33333L5.71483 14.6667L5.0007 16Z"
          fill={sideColor}/>
    <path d="M16.429 4.60239e-07L19.2861 0L19.2861 16L16.3193 16L15.7149 14.6667L15.7149 1.33333L16.429 4.60239e-07Z"
          fill={sideColor}/>
    <path
      d="M11.137 10.2762C11.2027 10.2539 11.2463 10.197 11.2463 10.1333V8.29327L13.5199 9.35459C13.5914 9.38797 13.6789 9.37075 13.7286 9.3133C13.7527 9.28557 13.7646 9.2522 13.7646 9.21898C13.7646 9.18347 13.7509 9.14797 13.7239 9.11947L12.6586 7.99994L10.534 5.76711C10.4882 5.71896 10.4141 5.70159 10.3485 5.72384C10.2828 5.74609 10.239 5.80292 10.239 5.86662V7.70661L7.96547 6.64528C7.89395 6.61191 7.80648 6.62913 7.75679 6.68658C7.73261 6.71431 7.72069 6.74768 7.72069 6.7809C7.72069 6.8164 7.73446 6.85191 7.76149 6.8804L8.82674 7.99994L10.9514 10.2328C10.9972 10.2811 11.0712 10.2983 11.137 10.2762V10.2762Z"
      fill="#EA5C28"/>
    <path
      d="M11.6585 10.2762C11.7242 10.2539 11.7678 10.197 11.7678 10.1333V8.29327L14.0414 9.35459C14.1129 9.38797 14.2003 9.37075 14.25 9.3133C14.2742 9.28557 14.2861 9.2522 14.2861 9.21898C14.2861 9.18347 14.2724 9.14797 14.2453 9.11947L13.1801 7.99994L11.0555 5.76711C11.0096 5.71896 10.9356 5.70159 10.87 5.72384C10.8043 5.74609 10.7605 5.80292 10.7605 5.86662V7.70661L8.48696 6.64528C8.41544 6.61191 8.32797 6.62913 8.27827 6.68658C8.2541 6.71431 8.24218 6.74768 8.24218 6.7809C8.24218 6.8164 8.25594 6.85191 8.28297 6.8804L9.34822 7.99994L11.4728 10.2328C11.5187 10.2811 11.5927 10.2983 11.6585 10.2762V10.2762Z"
      fill="#FDC605"/>

    <path
      d="M11.137 10.2762C11.2027 10.2539 11.2463 10.197 11.2463 10.1333V8.29327L13.5199 9.35459C13.5914 9.38797 13.6789 9.37075 13.7286 9.3133C13.7527 9.28557 13.7646 9.2522 13.7646 9.21898C13.7646 9.18347 13.7509 9.14797 13.7239 9.11947L12.6586 7.99994L10.534 5.76711C10.4882 5.71896 10.4141 5.70159 10.3485 5.72384C10.2828 5.74609 10.239 5.80292 10.239 5.86662V7.70661L7.96547 6.64528C7.89395 6.61191 7.80648 6.62913 7.75679 6.68658C7.73261 6.71431 7.72069 6.74768 7.72069 6.7809C7.72069 6.8164 7.73446 6.85191 7.76149 6.8804L8.82674 7.99994L10.9514 10.2328C10.9972 10.2811 11.0712 10.2983 11.137 10.2762V10.2762Z"
      fill="#EA5C28"/>
    <path
      d="M11.6585 10.2762C11.7242 10.2539 11.7678 10.197 11.7678 10.1333V8.29327L14.0414 9.35459C14.1129 9.38797 14.2003 9.37075 14.25 9.3133C14.2742 9.28557 14.2861 9.2522 14.2861 9.21898C14.2861 9.18347 14.2724 9.14797 14.2453 9.11947L13.1801 7.99994L11.0555 5.76711C11.0096 5.71896 10.9356 5.70159 10.87 5.72384C10.8043 5.74609 10.7605 5.80292 10.7605 5.86662V7.70661L8.48696 6.64528C8.41544 6.61191 8.32797 6.62913 8.27827 6.68658C8.2541 6.71431 8.24218 6.74768 8.24218 6.7809C8.24218 6.8164 8.25594 6.85191 8.28297 6.8804L9.34822 7.99994L11.4728 10.2328C11.5187 10.2811 11.5927 10.2983 11.6585 10.2762V10.2762Z"
      fill="#FDC605"/>
  </svg>

);

const DoubleBoostIcon: React.FC<{ h?: number; }> = ({h = 16}) => (<div>
  <BoostIcon h={h} sideColor={"#cccfd7"} sideColorDark={"#9599ad"}/>
</div>);

const TripleBoostIcon: React.FC<{ h?: number; }> = ({h = 16}) => (<div>
  <BoostIcon h={h} sideColor={"#f19900"} sideColorDark={"#E3800A"}/>
</div>);

export {BoostIcon, DoubleBoostIcon, TripleBoostIcon};
