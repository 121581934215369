import {useEffect, useState} from "react";
import {DateTime} from "luxon";
import {Tournament} from "../models/Tournament";

export function useTournamentRemainingDays(tournament: Tournament, defaultValue = 7): number {
  const [remaningDays, setRemainingDays] = useState<number>(defaultValue);
  useEffect(() => {
    if (tournament) {
      const now = DateTime.now();
      const remaining = DateTime.fromISO(tournament.shouldStopAt.toString()).diff(now, 'days');
      setRemainingDays(Math.floor(remaining.days));
    }
  }, [tournament]);
  return remaningDays;
}
