import React, {useEffect} from 'react';
import TemporaryDrawer from "../ui/temp-drawer.component";
import {disableBodyScroll, enableBodyScroll} from "../../utils/HtmlHelper";
import {classNames, formatBalanceNumber} from "../../utils/Helper";
import {useGetUserQuery} from "../../apis/users.api";
import {useNavigate} from "react-router-dom";
import axios from "axios";

export interface ProductItem {
  discountPrice?: number,
  image1: string,
  code: string,
  displayedPrice: number
  displayedDiscountPrice?: number
  externalId: string
  stripeId: string
}

const BuyProductDrawer: React.FC<{
  open: boolean,
  onClose: Function,
  product: ProductItem | null,
}> = ({
        open, onClose = () => {
  }, product
      }) => {

  const {data: user} = useGetUserQuery();
  const navigate = useNavigate();

  useEffect(() => {
    return () => enableBodyScroll();
  }, []);

  async function payByStripe() {
    if (product) {
      gtag('event', 'shop_onChoosePaymentType', {shop_payment_type: 'fiat'});
      await axios.post('/shop/stripe/create-checkout-session', {
        lineItems: [{price: product.stripeId, quantity: 1}],
        productCode: product.code,
        customer_email: user?.email || ''
      })
        .then((response) => {
          gtag('event', 'shop_onFiatPaymentStripeCheckoutSessionCreateSuccess', {shop_stripe_price_id: product.stripeId});
          navigate('/checkout', {state: {clientSecret: response.data.clientSecret}})
        })
        .catch((error) => console.log(error));
    }
  }

  return (
    <React.Fragment>
      <TemporaryDrawer
        title={"Gems"}
        closeWhenClickingOutside={true}
        anchor={'bottom'}
        open={open}
        lineIcon={false}
        onOpen={() => {
          disableBodyScroll()
        }}
        onClose={() => {
          enableBodyScroll()
          onClose();
        }}
        size="auto"
      >
        <div className={"w-full h-full flex flex-col justify-center items-center py-2 overflow-y-auto pb-9"}>

          {product && <div className='relative flat-card !p-0'>

            {product.displayedDiscountPrice ?
              <img className={"h-20 -left-0.5 -top-0.5 absolute mou"}
                   src={`${process.env.REACT_APP_STATIC_ASSETS}/images/save-big-banner.png`}
              /> : <></>
            }

            <img className={"w-[130px] h-auto"}
                 src={`${process.env.REACT_APP_STATIC_ASSETS}/images/${product.image1}`}
                 alt={`${product.code} product image`}
            />

            <div className={"w-full flex gap-x-3 justify-center"}>
              <div
                className={classNames("description text-center text-lg", product.displayedDiscountPrice ? 'line-through text-orange-700' : '')}>
                ${formatBalanceNumber(product.displayedPrice || 0)}
              </div>

              {product.displayedDiscountPrice ?
                <div className={"description text-center text-lg"}>
                  ${formatBalanceNumber(product.displayedDiscountPrice || 0)}
                </div> : <></>
              }
            </div>

          </div>}

          <div className='py-8'>
            <p className='text-gray-900'>
              Choose your payment method
            </p>
          </div>

          {user && <div className='flex flex-col w-full px-4 gap-4'>

            <button className="primary !grid !grid-cols-6 content-center" onClick={() => {
              gtag('event', 'shop_onChoosePaymentType', {shop_payment_type: 'crypto'});
              navigate('/crypto-pay/initial');
            }}>

              <span className="col-start-2 col-span-4">Crypto</span>

              <span className='col-start-6 col-span-1 justify-self-end'>
              <img src='https://s2.coinmarketcap.com/static/img/coins/64x64/1.png' width={30} height={30}/>
            </span>

            </button>

            <button className="primary !grid !grid-cols-6 content-center" onClick={payByStripe}>

              <span className="col-start-2 col-span-4">Credit Card</span>

              <span className='col-start-6 col-span-1 justify-self-end'>

              <svg width="30" height="30" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_6434_47515)">
                <path
                  d="M4.5 5C2.291 5 0.5 6.791 0.5 9V27C0.5 29.209 2.291 31 4.5 31H32.5C34.709 31 36.5 29.209 36.5 27V9C36.5 9 36.5 5 32.5 5H4.5Z"
                  fill="#FFAC33"/>
                <path d="M0.5 10H36.5V15H0.5V10Z" fill="#292F33"/>
                <path d="M4.5 19H32.5V25H4.5V19Z" fill="#F4F7F9"/>
                <path
                  d="M19.5 24C17.797 24 17.159 22.79 17.031 22.199C16.484 22.24 15.951 22.502 15.226 22.963C14.461 23.449 13.594 24 12.5 24C11.303 24 10.576 23.325 10.5 22C10.497 21.944 10.538 21.812 10.521 21.812C8.66299 21.812 7.31899 23.573 7.30599 23.591C7.11099 23.858 6.80699 24 6.49999 24C6.29399 24 6.08699 23.937 5.90899 23.806C5.46399 23.479 5.36699 22.853 5.69399 22.408C5.77099 22.303 7.60999 20 10.5 20C12.437 20 12.548 21.375 12.578 21.888L12.585 21.997C13.071 21.963 13.576 21.643 14.155 21.274C15.116 20.664 16.308 19.903 17.905 20.312C18.776 20.535 18.912 21.343 18.964 21.648C18.977 21.724 18.996 21.838 19.013 21.874C19.02 21.874 19.159 21.965 19.59 22.004C20.41 22.079 21.311 21.725 22.265 21.351C23.253 20.963 24.275 20.563 25.376 20.563C28.765 20.563 30.143 22.198 30.289 22.384C30.629 22.819 30.553 23.447 30.118 23.787C29.943 23.924 29.737 23.994 29.531 24C29.224 24.009 28.918 23.877 28.714 23.616C28.69 23.589 27.784 22.562 25.377 22.562C24.654 22.562 23.849 22.877 22.996 23.211C21.987 23.607 20.562 24 19.5 24Z"
                  fill="#8899A6"/>
                </g>
                <defs>
                <clipPath id="clip0_6434_47515">
                <rect width="36" height="36" fill="white" transform="translate(0.5)"/>
                </clipPath>
                </defs>
              </svg>
            </span>
            </button>
          </div>}

        </div>
      </TemporaryDrawer>
    </React.Fragment>
  );
}

export {
  BuyProductDrawer
}