import React from 'react';
import RocketIcon from "../svg/RocketIcon";

type RoiBarProps = {
    score: number | string;
};

const RoiBar: React.FC<RoiBarProps> = ({ score }) => {
    return (
    <div className="justify-center items-center gap-1 inline-flex">
        <div className="hidden w-2.5 h-2.5 justify-center items-center md:flex">
            <RocketIcon />
        </div>
        <div className="justify-center items-center gap-1 flex">
            <div className="text-white text-xs font-bold leading-[17px]">{score}</div>
            <div className="hidden md:flex opacity-50 text-white text-xs font-bold leading-[17px]">ROI</div>
            <div className="md:hidden w-2.5 h-2.5 justify-center items-center flex">
            <RocketIcon />
        </div>
        </div>
    </div>
    )
};


export { RoiBar};