import React from 'react';

const HourglassIcon: React.FC<{ h?: number }> = ({h}) => (
  <svg width={h} height={h} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21 18C21 15.999 24.246 14.631 26 12C28 9 28 2 28 2H8C8 2 8 9 10 12C11.754 14.631 15 15.999 15 18C15 20.001 11.754 21.369 10 24C8 27 8 34 8 34H28C28 34 28 27 26 24C24.246 21.369 21 20.001 21 18Z" fill="#FFE8B6"/>
    <path d="M20.999 24C20 24 18.942 23 18.942 22C19 20.287 19 19.154 19 18C19 14.78 22.034 13.439 23.9 11H12.1C13.965 13.439 17 14.78 17 18C17 19.155 17 20.289 17.058 22C17.058 23 16 24 15 24C13 24 11.405 25.784 11 27C10 30 10 34 10 34H26C26 34 26 30 25 27C24.595 25.784 22.999 24 20.999 24Z" fill="#FFAC33"/>
    <path d="M30 34C30 35.104 29.104 36 28 36H8C6.896 36 6 35.104 6 34C6 32.896 6.896 32 8 32H28C29.104 32 30 32.896 30 34ZM30 2C30 3.104 29.104 4 28 4H8C6.896 4 6 3.104 6 2C6 0.896 6.896 0 8 0H28C29.104 0 30 0.896 30 2Z" fill="#3B88C3"/>
  </svg>
);

export default HourglassIcon;
