import React from "react";


const ChainsIcon: React.FC<{ h?: number }> = ({h = 24}) =>
  <svg width="10" height={h} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5678_25833)">
      <path
        d="M9.16668 9.44401V9.99957H8.32751V9.44401C8.32751 8.98373 7.95168 8.61068 7.48834 8.61068V7.77734C8.41529 7.77734 9.16668 8.52373 9.16668 9.44401Z"
        fill="black"/>
      <path
        d="M7.65137 8.3329C7.60359 8.3329 7.55776 8.34012 7.51165 8.34707V8.6129C7.96387 8.6254 8.32748 8.99151 8.32748 9.44401V9.99957H8.63053V9.30512C8.63053 8.76818 8.1922 8.3329 7.65137 8.3329ZM5.83331 9.44401V9.99957H6.67248V9.44401C6.67248 8.98373 7.04831 8.61068 7.51165 8.61068V7.77734C6.5847 7.77734 5.83331 8.52373 5.83331 9.44401Z"
        fill="#BCBCBC"/>
      <path
        d="M7.37168 8.33333C7.41946 8.33333 7.46529 8.34056 7.51141 8.3475V8.61333C7.05918 8.62583 6.69557 8.99195 6.69557 9.44444V10H6.39252V9.30556C6.39279 8.76861 6.83113 8.33333 7.37168 8.33333ZM9.16668 0.555556V0H8.32752V0.555556C8.32752 1.01583 7.95168 1.38889 7.48835 1.38889V2.22222C8.41502 2.22222 9.16668 1.47583 9.16668 0.555556Z"
        fill="black"/>
      <path
        d="M7.65137 1.66667C7.60359 1.66667 7.55776 1.65944 7.51165 1.6525V1.38667C7.96387 1.37389 8.32748 1.00806 8.32748 0.555556V0H8.63053V0.694444C8.63053 1.23139 8.19192 1.66667 7.65137 1.66667ZM5.83331 0.555556V0H6.67248V0.555556C6.67248 1.01583 7.04831 1.38889 7.51165 1.38889V2.22222C6.5847 2.22222 5.83331 1.47583 5.83331 0.555556Z"
        fill="#BCBCBC"/>
      <path
        d="M7.37165 1.66667C7.41943 1.66667 7.46526 1.65944 7.51137 1.6525V1.38667C7.05943 1.37389 6.69582 1.00806 6.69582 0.555556V0H6.39276V0.694444C6.39276 1.23139 6.83109 1.66667 7.37165 1.66667ZM7.48832 2.77778V3.61111C7.95165 3.61111 8.32748 3.98417 8.32748 4.44444V5.55556C8.32748 6.01583 7.95165 6.38889 7.48832 6.38889V7.22222C8.41526 7.22222 9.16665 6.47583 9.16665 5.55556V4.44444C9.16665 3.52417 8.41526 2.77778 7.48832 2.77778Z"
        fill="black"/>
      <path
        d="M7.65141 3.33398C7.60364 3.33398 7.5578 3.34121 7.51141 3.34815V3.61398C7.96364 3.62648 8.32725 3.9926 8.32725 4.4451V5.55621C8.32725 6.00871 7.96364 6.37454 7.51141 6.38732V6.65315C7.5578 6.6601 7.60364 6.66732 7.65141 6.66732C8.19197 6.66732 8.6303 6.23204 8.6303 5.6951V4.30621C8.63058 3.76926 8.19225 3.33398 7.65141 3.33398Z"
        fill="#BCBCBC"/>
      <path
        d="M6.67248 4.44401C6.67248 3.98373 7.04831 3.61068 7.51165 3.61068V2.77734C6.5847 2.77734 5.83331 3.52373 5.83331 4.44401V5.55512C5.83331 6.4754 6.5847 7.22179 7.51165 7.22179V6.38845C7.04831 6.38845 6.67248 6.0154 6.67248 5.55512V4.44401Z"
        fill="#BCBCBC"/>
      <path
        d="M7.37168 3.33398C6.83112 3.33398 6.39279 3.76926 6.39279 4.30621V5.6951C6.39279 6.23204 6.83112 6.66732 7.37168 6.66732C7.41945 6.66732 7.46529 6.6601 7.5114 6.65315V6.38732C7.05918 6.37482 6.69556 6.00871 6.69556 5.55621V4.4451C6.69556 3.9926 7.05918 3.62676 7.5114 3.61398V3.34815C7.46556 3.34121 7.41973 3.33398 7.37168 3.33398ZM2.48834 5.27843V6.11176C2.95168 6.11176 3.32751 6.48482 3.32751 6.9451V8.05621C3.32751 8.51648 2.95168 8.88954 2.48834 8.88954V9.72287C3.41529 9.72287 4.16668 8.97648 4.16668 8.05621V6.9451C4.16668 6.02482 3.41529 5.27843 2.48834 5.27843Z"
        fill="black"/>
      <path
        d="M2.65141 5.83398C2.60364 5.83398 2.5578 5.84121 2.51141 5.84815V6.11398C2.96364 6.12648 3.32725 6.4926 3.32725 6.9451V8.05621C3.32725 8.50871 2.96364 8.87454 2.51141 8.88732V9.15315C2.5578 9.1601 2.60364 9.16732 2.65141 9.16732C3.19197 9.16732 3.6303 8.73204 3.6303 8.1951V6.80621C3.63058 6.26926 3.19225 5.83398 2.65141 5.83398Z"
        fill="#BCBCBC"/>
      <path
        d="M1.67248 6.94401C1.67248 6.48373 2.04831 6.11068 2.51165 6.11068V5.27734C1.5847 5.27734 0.833313 6.02373 0.833313 6.94401V8.05512C0.833313 8.9754 1.5847 9.72179 2.51165 9.72179V8.88845C2.04831 8.88845 1.67248 8.5154 1.67248 8.05512V6.94401Z"
        fill="#BCBCBC"/>
      <path
        d="M2.37165 5.8329C1.83109 5.8329 1.39276 6.26818 1.39276 6.80512V8.19401C1.39276 8.73096 1.83109 9.16623 2.37165 9.16623C2.41943 9.16623 2.46526 9.15901 2.51137 9.15207V8.88623C2.05943 8.87346 1.69582 8.50762 1.69582 8.05512V6.94401C1.69582 6.49151 2.05943 6.12568 2.51165 6.1129V5.84707C2.46554 5.84012 2.41971 5.8329 2.37165 5.8329ZM2.48832 0.277344V1.11068C2.95165 1.11068 3.32748 1.48373 3.32748 1.94401V3.05512C3.32748 3.5154 2.95165 3.88845 2.48832 3.88845V4.72179C3.41526 4.72179 4.16665 3.9754 4.16665 3.05512V1.94401C4.16665 1.02373 3.41526 0.277344 2.48832 0.277344Z"
        fill="black"/>
      <path
        d="M2.65137 0.832899C2.60359 0.832899 2.55776 0.840122 2.51165 0.847066V1.1129C2.96387 1.12568 3.32748 1.49151 3.32748 1.94401V3.05512C3.32748 3.50762 2.96387 3.87346 2.51165 3.88623V4.15207C2.55776 4.15901 2.60359 4.16623 2.65137 4.16623C3.19192 4.16623 3.63026 3.73096 3.63026 3.19401V1.80512C3.63054 1.26818 3.1922 0.832899 2.65137 0.832899ZM1.67248 1.94401C1.67248 1.48373 2.04831 1.11068 2.51165 1.11068V0.277344C1.5847 0.277344 0.833313 1.02373 0.833313 1.94401V3.05512C0.833313 3.9754 1.5847 4.72179 2.51165 4.72179V3.88845C2.04831 3.88845 1.67248 3.5154 1.67248 3.05512V1.94401Z"
        fill="#BCBCBC"/>
      <path
        d="M2.37165 0.833984C1.83109 0.833984 1.39276 1.26926 1.39276 1.80621V3.1951C1.39276 3.73204 1.83109 4.16732 2.37165 4.16732C2.41943 4.16732 2.46526 4.1601 2.51137 4.15315V3.88732C2.05943 3.87454 1.69582 3.50871 1.69582 3.05621V1.9451C1.69582 1.4926 2.05943 1.12676 2.51165 1.11398V0.848151C2.46554 0.841207 2.41971 0.833984 2.37165 0.833984ZM8.05554 9.16732C8.05554 9.47398 7.80665 9.72287 7.49998 9.72287C7.19332 9.72287 6.94443 9.47398 6.94443 9.16732V5.83398C6.94443 5.52732 7.19332 5.27843 7.49998 5.27843C7.80665 5.27843 8.05554 5.52732 8.05554 5.83398V9.16732Z"
        fill="black"/>
      <path
        d="M6.94446 5.8329V9.16623C6.94446 9.4729 7.19335 9.72179 7.50001 9.72179V5.27734C7.19335 5.27734 6.94446 5.52623 6.94446 5.8329Z"
        fill="#E6E6E6"/>
      <path
        d="M8.05557 4.16623C8.05557 4.4729 7.80668 4.72179 7.50001 4.72179C7.19335 4.72179 6.94446 4.4729 6.94446 4.16623V0.832899C6.94446 0.525955 7.19335 0.277344 7.50001 0.277344C7.80668 0.277344 8.05557 0.525955 8.05557 0.832899V4.16623Z"
        fill="black"/>
      <path
        d="M6.94446 0.832899V4.16623C6.94446 4.4729 7.19335 4.72179 7.50001 4.72179V0.277344C7.19335 0.277344 6.94446 0.526233 6.94446 0.832899Z"
        fill="#E6E6E6"/>
      <path
        d="M3.05557 9.99957H1.94446V8.3329C1.94446 8.02623 2.19335 7.77734 2.50001 7.77734C2.80668 7.77734 3.05557 8.02623 3.05557 8.3329V9.99957Z"
        fill="black"/>
      <path d="M2.50001 7.77734C2.19335 7.77734 1.94446 8.02623 1.94446 8.3329V9.99957H2.50001V7.77734Z"
            fill="#E6E6E6"/>
      <path
        d="M3.05557 6.66623C3.05557 6.9729 2.80668 7.22179 2.50001 7.22179C2.19335 7.22179 1.94446 6.9729 1.94446 6.66623V3.3329C1.94446 3.02623 2.19335 2.77734 2.50001 2.77734C2.80668 2.77734 3.05557 3.02623 3.05557 3.3329V6.66623Z"
        fill="black"/>
      <path
        d="M1.94446 3.3329V6.66623C1.94446 6.9729 2.19335 7.22179 2.50001 7.22179V2.77734C2.19335 2.77734 1.94446 3.02623 1.94446 3.3329Z"
        fill="#E6E6E6"/>
      <path
        d="M3.05557 0H1.94446V1.66667C1.94446 1.97333 2.19335 2.22222 2.50001 2.22222C2.80668 2.22222 3.05557 1.97333 3.05557 1.66667V0Z"
        fill="black"/>
      <path d="M2.50001 2.22222C2.19335 2.22222 1.94446 1.97333 1.94446 1.66667V0H2.50001V2.22222Z" fill="#E6E6E6"/>
    </g>
    <defs>
      <clipPath id="clip0_5678_25833">
        <rect width="10" height="10" fill="white"/>
      </clipPath>
    </defs>
  </svg>;

export {ChainsIcon};