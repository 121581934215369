import React from "react";


const HomeIcon: React.FC<{ h?: number,color?:string }> = ({h = 17,color='black'}) => <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2409_3683)">
      <path d="M16.875 16.875V9.02657C16.875 8.93947 16.8568 8.85333 16.8215 8.77369C16.7863 8.69404 16.7348 8.62264 16.6703 8.56407L10.4203 2.6625C10.3052 2.55776 10.1552 2.49971 9.99962 2.49971C9.84401 2.49971 9.69399 2.55776 9.57891 2.6625L3.32891 8.56407C3.2646 8.62271 3.21323 8.69414 3.17812 8.77378C3.14301 8.85343 3.12492 8.93953 3.12501 9.02657V16.875" stroke={`${color}`} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M1.25001 16.875H18.75" stroke={`${color}`} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M11.875 16.875V12.5C11.875 12.3342 11.8092 12.1753 11.6919 12.0581C11.5747 11.9408 11.4158 11.875 11.25 11.875H8.75001C8.58425 11.875 8.42528 11.9408 8.30807 12.0581C8.19086 12.1753 8.12501 12.3342 8.12501 12.5V16.875" stroke={`${color}`} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_2409_3683">
        <rect width="20" height="20" fill="white"/>
      </clipPath>
    </defs>
  </svg>;

export {HomeIcon};