import {SmilingGlassesIcon} from "./smiling-glasses-icon";
import {TrophyIcon} from "./trophy-icon";
import {FireIcon} from "./fire-icon";
import {BicepIcon} from "./bicep-icon";
import {PartyingFaceIcon} from "./partying-face-icon";
import {getRandomInt} from "../../utils/Helper";

export const getRandomSuccessIcon = (h: number) => {
  const icons = [<SmilingGlassesIcon h={h}/>, <TrophyIcon h={h}/>, <FireIcon h={h}/>, <BicepIcon h={h}/>,
    <PartyingFaceIcon h={h}/>];
  const r = getRandomInt(icons.length);
  return icons[r];
}
