import React, {useState, useRef, useEffect, useCallback} from 'react';

const RapidIncrementButtonV2: React.FC<{ max?: number, count: number, callback: (count: number) => void }> = ({
                                                                                                                callback,
                                                                                                                count,
                                                                                                                max = Number.MAX_SAFE_INTEGER
                                                                                                              }) => {
  return <RapidButtonV2 type={"inc"} min={0} max={max} count={count} callback={callback}/>
};

const RapidDecrementButtonV2: React.FC<{ count: number, callback: (count: number) => void }> = ({callback, count}) => {
  return <RapidButtonV2 type={"dec"} min={0} max={Number.MAX_SAFE_INTEGER} count={count} callback={callback}/>
};

// const steps = [10, 50, 100, 500, 1000];
// const stepValueInitial = [0, 0, 0, 0];
const RapidButtonV2: React.FC<{
  count: number,
  type: 'inc' | 'dec',
  min: number,
  max: number,
  callback: (count: number) => void
}> = ({
        type,
        min,
        max,
        count,
        callback
      }) => {
  const [_count, _setCount] = useState(count);
  const [start, setStart] = useState(false);
  // const [stepValues, setStepValues] = useState(stepValueInitial);
  const intRef: React.MutableRefObject<any> = useRef(null);
  const timeORef: React.MutableRefObject<any> = useRef(null);

  const [tick, setTick] = useState(0);

  const handleClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    increment();
    handleMouseUp(e);
  }, [callback]);

  useEffect(() => {
    window.onmousedown = handleMouseUp;
    window.onmousedown = handleMouseUp;
  }, [])

  const handleMouseUp = useCallback(
    (e: React.MouseEvent<HTMLButtonElement> | MouseEvent | React.TouchEvent) => {
      e.stopPropagation();
      // console.log('mouseup reset');
      if (timeORef.current) clearTimeout(timeORef.current);
      if (intRef.current) clearInterval(intRef.current);
      // setStepValues(stepValueInitial);
      setStart(false);
    }, []);


  const handleMouseDown = useCallback(
    (e: React.MouseEvent<HTMLButtonElement> | MouseEvent | React.TouchEvent) => {
      e.stopPropagation();
      // console.log('Mousedown start');
      setStart(true);
    }, []);

  useEffect(() => {
    if (start) {
      //one second of press we start interval
      timeORef.current = setTimeout(() => {
        intRef.current = setInterval(() => {
          setTick((prev: number) => prev + 1);
        }, 100);
      }, 500);
    }
    return () => {
      if (timeORef.current) clearTimeout(timeORef.current);
      if (intRef.current) clearInterval(intRef.current);
    }
  }, [start]);

  useEffect(() => {
    if (tick > 0) {
      increment();
      // for (let i = 0; i < stepValues.length; i++) {
      // if (i < stepValues.length - 1 && stepValues[i] > (3 + i * 2)) continue;
      // incrementStepValue(i);
      // break;
      // }
    }
  }, [tick]);
  //
  // const incrementStepValue = (idx: number) => {
  //   setStepValues((state) => {
  //     // console.log(`increment step values, current:  ${JSON.stringify(state)}`);
  //     const copy = [...state];
  //     copy[idx]++;
  //     // console.log(`increment step values, new Value :  ${JSON.stringify(copy)}`);
  //     return copy;
  //   });
  // }

  const getIncrementValue = (currentVal: number) => {
    let val = 1;
    if (currentVal >= 5000) val = 1000;
    if (currentVal >= 1000) val = 500;
    else if (currentVal >= 500) val = 100;
    else if (currentVal >= 100) val = 50;
    else if (currentVal >= 50) val = 25;
    else if (currentVal >= 10) val = 10;
    return val;
  }
  const increment = () => {
    if (type === 'inc') {
      _setCount((prevCount) => {
        const val = getIncrementValue(prevCount);
        // console.log(`increment count of ${val}`);
        const res = Math.min(prevCount + val, max);
        setTimeout(() => callback(res), 0);
        return res;
      });
    } else {
      _setCount((prevCount) => {
        const val = getIncrementValue(prevCount);
        // console.log(`decrement count of ${val}`);
        let res = Math.max(min, prevCount - val);
        if (res > 100) res -= res % 100;//round to 100
        setTimeout(() => callback(res), 0);
        return res;
      });
    }
  }

  useEffect(() => {
    _setCount(count);
  }, [count])

  return (
    <div>
      <button className={'primary !w-[46px] !h-[44px] !text-xl'} onMouseLeave={handleMouseUp}
              onMouseDown={handleMouseDown}
              onTouchStart={handleMouseDown} onTouchEnd={handleMouseUp}
              onMouseUp={handleMouseUp}
              onClick={handleClick}>{type === 'inc' ? '+' : '-'}</button>
    </div>
  );
};
export {RapidIncrementButtonV2, RapidDecrementButtonV2};
