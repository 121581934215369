import React, {useEffect, useState} from 'react';
import Layout from "../Layout";
import {useGetUserQuery, usersApi} from "../apis/users.api";
import 'react-confirm-alert/src/react-confirm-alert.css';
import {classNames, cssHidden} from "../utils/Helper";
import {useAppDispatch} from "../app/hooks";
import {FullScreenDialog} from "../components/ui/modal-confirmation";
import {useUrlParameter} from "../utils/useUrlParameter";
import {useGetProductsQuery} from "../apis/shop.api";
import {isStrictlyPositive} from "../utils/MathsUtils";
import {useLocation} from "react-router";
import {CollectibleDrawer} from "../components/collectibles/collectible-drawer.component";
import {useGetCollectibleQuery} from "../apis/collectibles.api";
import {useNavigate} from "react-router-dom";
import {InfiniteCollectibleComponent} from "../components/collectibles/infinite_collectibles.component";
import {SecondaryNavigation} from "../components/ui/secondary-navigation.component";
import {AnchorProvider, AnchorSection,} from "react-anchor-navigation";
import {ProductItems} from '../components/shop/product-items.component';
import {BuyProductDrawer, ProductItem} from '../components/shop/buy-product-drawer.component';
import {addToCart} from "../slices/shopSlice";
import StripeSuccessCheckout from '../components/ui/notification/stripe-success-checkout-content.component';
import DefaultSuccessCheckout from '../components/ui/notification/default-success-checkout-content.component';
import DailyCollectibles from '../components/shop/daily-collectibles.component';
import DailyGift from '../components/shop/daily-gift.component';
import Treasures from '../components/shop/treasures.component';
import {BigRoundedTitle} from '../components/ui/big-rounded-title';
import {SmilingGlassesIcon} from "../components/ui/smiling-glasses-icon";
import {ItemShadowIcon} from "../components/ui/item-shadow-icon";
import {CollectibleCard} from "../dto/collectibles/collectibles-list-response.dto";
import GemsBuyableItems from "../components/shop/gems-buyable-items.component";
import {useScrollIntoView} from "../utils/useScrollIntoView";
import {SparkIcon} from "../components/ui/spark-icon";

const ShopPage: React.FC = () => {
  useScrollIntoView();
  const {data: user} = useGetUserQuery();
  const nav = useNavigate();
  const dispatch = useAppDispatch();
  const {data: products, isLoading: areProductsLoading} = useGetProductsQuery({family: 'simple'});
  const {
    data: dailyCollectibles,
    isLoading: areDailyCollectiblesLoading
  } = useGetProductsQuery({family: 'limited_offers'});
  const {data: treasures, isLoading: areTreasuresLoading} = useGetProductsQuery({family: 'treasures'});

  const {data: gemsBuyableItems, isLoading: areGemsBuyableItemsLoading} = useGetProductsQuery({family: 'gems_buyable_items'});

  const paymentStatus = useUrlParameter('status');
  const productCode = useUrlParameter('product');
  const productName = useUrlParameter('name');
  const productPrice = Number(useUrlParameter('price'));
  const {state} = useLocation();
  const {data: collectible} = useGetCollectibleQuery(state?.upgradeCollectibleId, {skip: !state?.upgradeCollectibleId});
  const [noCard, setNoCards] = useState<boolean>(false);
  const [product, setProduct] = useState<ProductItem | null>(null);
  const [collectibleSuccess, setCollectibleSuccess] = useState<CollectibleCard | null>(null);
  useEffect(() => {
    dispatch(usersApi.util.invalidateTags([{type: 'InfiniteCollectibleList'}]));

    window.addEventListener("upgradeCollectibleSuccess", (e: any) => {
      setCollectibleSuccess(e.detail.data());
    });
  }, []);


  useEffect(() => {
    if (paymentStatus) {
      gtag('event', 'shop_onPaymentSuccessBackToStorePage');
    }
  }, [paymentStatus])


  useEffect(() => {
    //if a new product is set to buy we reset the payment status
    if (paymentStatus === 'success') {
      dispatch(usersApi.util.invalidateTags(['User']));
    }
  }, [paymentStatus]);

  return (
    <Layout classes={"!py-0 gap-y-4 mb-[80px]"}>

      <AnchorProvider>
        <div
          className={"fixed w-screen pt-24 items-center justify-center gap-x-6 z-50  bg-gradient-to-b from-orange-700 to-orange"}>

          <div className={classNames("mx-auto flex gap-4 md:gap-8 center max-w-5xl pb-2")}>
            <SecondaryNavigation navItems={[
              {path: '/store', hash: '#prods', name: 'Gems'},
              {path: '/store', hash: '#dailyCollectibles', name: 'Daily Offers', hidden: !dailyCollectibles?.length},
              {path: '/store', hash: '#treasures', name: 'Treasures', hidden: !treasures?.length},
              {path: '/store', hash: '#collectibles', name: 'Collectibles', hidden: noCard},
            ]}
                                 css={classNames("bg-orange")}
            />
          </div>

          <div className='w-full flex center relative h-12'>

            <div className='absolute bottom-0 translate-y-1/2 w-full px-8 max-w-md'>
              <DailyGift/>
            </div>

          </div>

        </div>

        <div className='w-full pt-[224px] max-w-2xl flex flex-col gap-6'>

          <AnchorSection className="relative -top-[224px]" id="prods">
            <div className={""}>

              <BigRoundedTitle title={"Gems"} bgColor='bg-blue' textColor='text-white'/>

              <div className={"w-full flex flex-wrap justify-center gap-y-4 gap-x-3 pt-3"}>
                {!areProductsLoading && products && products.map((product, idx) => <div
                  key={`prod_${product.externalId}`} className={"relative flat-card !p-0"}>
                  <div
                    onClick={() => {
                      dispatch(addToCart({product}));
                      setProduct(product);
                    }}
                  >
                    <ProductItems
                      externalId={product.externalId}
                      image={product.image1}
                      code={product.code}
                      price={product.displayedPrice}
                      discountPrice={isStrictlyPositive(product.displayedDiscountPrice) ? product.displayedDiscountPrice : undefined}/>
                  </div>
                </div>)}
              </div>
            </div>
          </AnchorSection>

          {dailyCollectibles?.length &&
            <AnchorSection className="relative -top-[224px]" id="dailyCollectibles">
              <div className={classNames("w-full flex flex-col gap-4")}>

                <BigRoundedTitle title={"Daily offers"} bgColor='bg-orange' textColor='text-white' timer={true}/>

                <DailyCollectibles/>

              </div>
            </AnchorSection>
          }

          {!areTreasuresLoading && treasures?.length &&
            <AnchorSection className="relative -top-[224px]" id="treasures">
              <div className={classNames("w-full flex flex-col gap-4")}>
                <BigRoundedTitle title={"Treasures"} bgColor='bg-yellow' textColor='text-orange'/>
                <Treasures/>
              </div>
            </AnchorSection>
          }

          {!areGemsBuyableItemsLoading && gemsBuyableItems?.length &&
            <div className="relative" id="power_ups">

              {!areGemsBuyableItemsLoading && gemsBuyableItems?.length &&
                <div className={classNames("w-full flex flex-col gap-4")}>
                  <BigRoundedTitle title={<div className={"flex text-white gap-x-2"}>
                    {/* <SparkIcon/> */}
                    Power-ups</div>} bgColor='bg-blue' textColor='text-white'/>
                  <GemsBuyableItems gemsBuyableItems={gemsBuyableItems}/>
                </div>
              }

            </div>
          }

          <AnchorSection className="relative -top-[224px]" id="collectibles">
            <div className={classNames("w-full flex flex-col gap-4", cssHidden(noCard))}>

              <BigRoundedTitle title={"Collectible upgrades"} bgColor='bg-turquoise' textColor='text-white'/>

              <InfiniteCollectibleComponent
                userId={user?._id}
                classes={"w-full"}
                onEmpty={() => {
                  setNoCards(true);
                }}
                mode={'upgrades'}
                drawerMode={'upgrade'}
                shinyCollectibles={state?.upgradeCollectibleId ? [state.upgradeCollectibleId as string] : []}
              />
            </div>
          </AnchorSection>

        </div>

      </AnchorProvider>


      <FullScreenDialog openProp={paymentStatus === 'success'} timer={10} onClose={() => {
        nav('/store', {replace: true});
      }}>
        <div className={"h-full flex flex-col gap-y-4 justify-center items-center px-2"}>

          {products && productCode &&
            <StripeSuccessCheckout product={products.find((item) => item.code === productCode)}/>
          }

          {!productCode && !productName &&
            <DefaultSuccessCheckout/>
          }
        </div>
      </FullScreenDialog>

      {collectible &&
        <CollectibleDrawer collectible={collectible} open={!!collectible}
                           onClose={() => {
                             nav('/store', {replace: true, state: undefined})
                           }} mode={collectible.upgrade ? "upgrade" : "none"}/>
      }


      {/*Real money product*/}
      <BuyProductDrawer
        open={product ? true : false}
        product={product}
        onClose={() => {
          setProduct(null);
        }}
      />


      {collectibleSuccess &&
        <FullScreenDialog openProp={true} timer={10} onClose={() => {
          setCollectibleSuccess(null);
          nav('/collectibles', {
            replace: true,
            state: {upgradeCollectibleId: collectibleSuccess.cryptoCurrencyId}
          });
        }}>
          <div className={"h-full flex flex-col gap-y-16 justify-center items-center"}>
            <div className={"flex flex-col gap-y-2"}>
              <SmilingGlassesIcon h={50}/>
              <ItemShadowIcon/>
            </div>


            <div className={"flex-col flex gap-3 justify-center items-center"}>
              <div
                className={"rounded-lg bg-black bg-opacity-20 text-white font-bold px-2 py-2 text-lg text-center"}>
                Congratulation !
              </div>
              <div className={"text-white text-center"}>
                {`You ${collectibleSuccess.step === 1 ? 'purchased' : 'upgraded'} the ${collectibleSuccess.name} Card! Check it out in your profile!`}
              </div>
            </div>
          </div>
        </FullScreenDialog>
      }

    </Layout>
  );
};

export {ShopPage};