import { useRef } from "react";
import { delay } from "lodash";
import { sleepNow } from "../utils/Helper";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";
import { useGetUserQuery } from "../apis/users.api";
import { instanciateNewGem, updateValue } from "../slices/gemSlice";

function useGemsCounterAnimation() {

  const {data: user} = useGetUserQuery();

  const dispatch = useAppDispatch();
  const counterValue = useAppSelector((state: RootState) => state.gems.value);
  const gemsCount = useRef(counterValue);

  const presetValue = () => {
    const presetValue = user ? user.gemsAmount : 0;
    gemsCount.current = presetValue;
    dispatch(updateValue({value: presetValue}));
  }

  const incrementCounter = async (increment:number, callback: Function) => {

    presetValue();

    const iterations = Math.min(increment, 20);
    const iteration = iterations >= 20 ? increment / 20 : 1;

    delay(
      function () {
        callback();
      },
      1500 + 50 * (iterations + 1),
    );

    for (let index = 0; index < iterations; index++) {

      dispatch(instanciateNewGem());
      await sleepNow(50);

      gemsCount.current = gemsCount.current + iteration;

      delay(
        function (count) {
          dispatch(updateValue({value: count}));
        },
        1500,
        gemsCount.current,
      );
    }

  }

  return { incrementCounter, presetValue };
}

export default useGemsCounterAnimation;