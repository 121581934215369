import {NftMetadataInterface, PlaceableResponseDto} from "../dto/placeable/placeable-response.dto";
import {Tournament} from "../models/Tournament";
import axios from "axios";
import {DateTime} from 'luxon';
import * as _ from "lodash";

export function ipfsToHttp(ipfsUrl: string = "") {
  return ipfsUrl.replace("ipfs://", "https://ipfs.io/ipfs/");
}


export function getLocalNFTSnapshot(nft: NftMetadataInterface): string {
  let res = `${process.env.REACT_APP_STATIC_ASSETS}/favicon.svg`;
  if (nft.attributes && nft.attributes.length) {
    const buildingType = nft.attributes.find(o => o.trait_type === 'building_type');
    if (buildingType) {
      if (buildingType.value === 'cyberpunk') {
        const color = nft.attributes.find(n => n.trait_type === 'color');
        if (buildingType && buildingType.value && color && color.value) {
          res = `${process.env.REACT_APP_STATIC_ASSETS}/3d/models2/specials/nft/${buildingType.value}/${color.value.toLowerCase()}.png`;
        }
      } else {
        res = nft.image || '';

      }
    }
  }

  if (res.startsWith('ipfs')) {
    res = ipfsToHttp(res);
  }
  return res;
}

export function getSquadTournamentLabel(o: Tournament | undefined): string {
  let res = "unknown";
  if (o && o.type === 'squad') {
    if (o.code.startsWith('SQUAD2')) {
      res = "Portfolio Punchout"
    } else if (o.code.startsWith('SQUAD3')) {
      res = "Crypto Craze"
    } else if (o.code.startsWith('SQUAD_RTC_')) {
      res = "Race To Riches"
    } else if (o.code.startsWith('SQUAD_CTC_')) {
      res = "Crypto Trader's Challenge"
    } else {
      res = "Crypto Clash"
    }
  }
  return res;
}

export async function get3DAssetUri(placeable: PlaceableResponseDto): Promise<string> {
  const buildingType =
    placeable.nftMetadata.attributes?.find(a => a.trait_type === 'building_type');
  let res = '';
  if (buildingType) {
    if (buildingType.value === 'cyberpunk') {
      const colorAttr =
        placeable.nftMetadata.attributes?.find(a => a.trait_type === 'color')
      res = `/3d/models2/specials/nft/cyberpunk/${colorAttr?.value.toLowerCase()}.glb`
    } else {
      res = await getStandardizedAnimationUrl(placeable);
    }
  }
  if (res.startsWith('ipfs')) {
    res = ipfsToHttp(res);
  }
  return res;
}

export async function getStandardizedAnimationUrl(placeable: PlaceableResponseDto): Promise<string> {
  let res = placeable.nftMetadata.animation_url || '';
  if (placeable.nftMetadata.secondary_medias && placeable.nftMetadata.secondary_medias.length) {
    //ask server which media to take
    res = await axios.get(`/assets/get3dUrl?placeableId=${placeable.id}`)
      .then(response => {
        if (response.data) {
          console.log(JSON.stringify(response));
          return response.data;
        } else {
          return '';
        }
      });
    // .catch(error => toast.error(error));
  }
  return res;
}

export function getSafeModel(modelUri: string) {
  if (!modelUri.startsWith('http') && !modelUri.startsWith('ipfs')) {
    if (modelUri.startsWith('/')) {
      modelUri = `${process.env.REACT_APP_STATIC_ASSETS}${modelUri}`;
    } else {
      modelUri = `${process.env.REACT_APP_STATIC_ASSETS}/${modelUri}`;
    }
  }
  if (modelUri.startsWith('ipfs')) {
    modelUri = ipfsToHttp(modelUri);
  }
  return modelUri;
}

export function getSafeStaticAssetUri(assetUri: string) {
  if (!assetUri.startsWith('http') && !assetUri.startsWith('ipfs')) {
    if (assetUri.startsWith('/')) {
      assetUri = `${process.env.REACT_APP_STATIC_ASSETS}${assetUri}`;
    } else {
      assetUri = `${process.env.REACT_APP_STATIC_ASSETS}/${assetUri}`;
    }
  }
  return assetUri;
}

export function getPlaceableType(placeable: PlaceableResponseDto): string {
  let res = '';
  const buildingType =
    placeable.nftMetadata.attributes?.find(a => a.trait_type === 'building_type');
  if (buildingType) {
    res = buildingType.value;
  }
  return res;
}

function getPlaceableName() {

}

export function getScaleFactor(placeable: PlaceableResponseDto) {
  let res = 1;
  const placeableType = getPlaceableType(placeable);
  if (placeableType === 'cyberpunk') {
    res = 0.3;
  } else if (placeableType === 'golden_doge') {
    res = 0.8;
  } else if (placeableType === 'subscription_rocket') {
    res = 0.5;
  } else if (placeableType === 'eth_gas_station') {
    res = 0.5;
  } else if (placeableType === 'orange_tanji_ambassador') {
    res = 0.6;
  } else if (placeableType === 'btc_gold_mine') {
    res = 0.1;
  } else if (placeableType === 'sand_pixel_world') {
    res = 0.15;
  }
  return res;
}

export function dateToString(date: string | Date): string {
  let res = "";
  if (typeof date === 'string') {
    res = new Date(date).toLocaleDateString('en-US', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
    });
  } else {
    res = date.toLocaleDateString('en-US', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
    });
  }
  return res;
}

/**
 * Compare two dates.
 * Return -1 if date1 is before date2
 * Return 1 if date1 is after date2
 * Return 0 if date1 is equal date2
 *
 * @param date1
 * @param date2
 */
export const compareDates = (date1: Date, date2: Date): number => {
  let res = 0;
  if (date1.getTime() < date2.getTime()) {
    res = -1;
  } else if (date1.getTime() > date2.getTime()) {
    res = 1;
  }
  return res;
}

export const isBefore = (date1: Date, date2: Date): boolean => {
  return compareDates(date1, date2) === -1;
}

export const isTomorrow = (date: Date) => {
  const now = DateTime.now().startOf('day');
  const dt = DateTime.fromJSDate(date).startOf('day');
  return dt.diff(now, 'days').days >= 1;
}

export const getDaysDiff = (date1: Date | string, date2: Date | string): number => {
  const start = typeof date1 === 'string' ? DateTime.fromISO(date1) : DateTime.fromJSDate(date1);
  const end = typeof date2 === 'string' ? DateTime.fromISO(date2) : DateTime.fromJSDate(date2);

  var diffInMonths = end.diff(start, 'days');
  return diffInMonths.toObject().days as number;
}

export const chainIdToName = (chainId: string): string => {
  let res = chainId;
  if (chainId === '0x5') {
    res = 'goerli';
  } else if (chainId === '0x89') {
    res = 'matic';
  }
  return res;
}
export const getOpenseaDomainFromChainId = (chainId: string): string => {
  let res = 'opensea.io';
  if (chainId === '0x5') {
    res = 'testnets.opensea.io';
  }
  return res;
}

export const getCookie = (cname: string) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
export const isAuth = async (): Promise<boolean> => {
  let promise: Promise<boolean> = new Promise((resolve, reject) => {
    axios.get(`/auth/isAuth`)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        resolve(false)
      });
  });
  return promise;
}

export function classNames(...classes: any[]) {
  classes = _.flatten(classes);
  return classes.filter(Boolean).join(' ')
}

export function hexToRgbA(hex: string, opacity: number) {
  let c: any;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},${opacity})`;
  } else {
    return 'rgba(255,255,255,1)';
  }
  // throw new Error('Bad Hex');
}

export async function wait(sec: number): Promise<void> {
  await new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(null);
    }, sec * 1000)
  });
}

export function urlB64ToUint8Array(base64String: string): Uint8Array {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
  const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/')
  const rawData = atob(base64)
  const outputArray: Uint8Array = new Uint8Array(rawData.length)
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

export function getAxiosErrorMessage(error: any) {
  return error?.response?.data?.message || error.message;
}

export function hasElements(arr?: any[]): boolean {
  return !!arr && arr.length > 0;
}

export function formatAmount(amount: number, symbol: string = '$', factionDigits: number | undefined = 2): string {
  if (amount < 1 && amount > -1) {
    if (factionDigits < 1) factionDigits = 1
    return amount.toPrecision(factionDigits) + symbol;
  } else {
    return amount.toFixed(factionDigits) + symbol;
  }
}

export function formatRank(rank = 0): string {
  let sfx = 'th';
  if (rank === 1) {
    sfx = 'st';
  } else if (rank === 2) {
    sfx = 'nd';
  } else if (rank === 3) {
    sfx = 'rd';
  }
  return `${rank}${sfx}`;
}

export function formatPercent(percent: number | string) {
  return Number(percent).toFixed(2) + '%';
}

export function formatPercentFloored(percent: number | string) {
  return Number(percent).toFixed(0) + '%';
}

export function formatGainPercent(percent: number | string) {
  return (Number(percent) > 0 ? '+' : '') + Number(percent).toFixed(1) + '%';
}

const numberRanges = [
  {divider: 1e18, suffix: 'E'},
  {divider: 1e15, suffix: 'P'},
  {divider: 1e12, suffix: 'T'},
  {divider: 1e9, suffix: 'G'},
  {divider: 1e6, suffix: 'M'},
  {divider: 1e3, suffix: 'k'}
];

export function formatBigNumber(n: number): string {

  if (n < 0) {
    return '-' + formatBigNumber(-n);
  } else {
    for (let i = 0; i < numberRanges.length; i++) {
      if (n >= numberRanges[i].divider) {
        return (n / numberRanges[i].divider).toFixed(2) + numberRanges[i].suffix;
      }
    }
    return n.toString();
  }
}


const numberFormat = new Intl.NumberFormat('de-DE', {
  style: 'decimal',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2
});
const littleNumberFormat = new Intl.NumberFormat('de-DE', {
  style: 'decimal',
  maximumFractionDigits: 10, maximumSignificantDigits: 2
});

export function formatBalanceNumber(gain: number): string {
  if (Math.abs(gain) >= 10000) {
    return formatBigNumber(gain)
  } else if (gain === 0) {
    return String(gain);
  } else {
    if (Math.abs(gain) < 1) {
      return littleNumberFormat.format(gain);
    } else {
      return numberFormat.format(gain);
    }
  }
}

// check if the device is in standalone mode
export const isInStandaloneMode = () => {
  return (
    "standalone" in (window as any).navigator &&
    (window as any).navigator.standalone
  );
};

export const cssZeroOpacity = (zeroOpacity: boolean, display = '') => {
  return zeroOpacity ? 'opacity-0' : display;
}


export const cssHidden = (hidden: boolean, display = '') => {
  return hidden ? 'hidden' : display;
}

export const cssShow = (show: boolean, display = '') => {
  return show ? display : '!hidden';
}


export const getDaysLeftLabel = (nbDays: number) => {
  return `${nbDays} Day${nbDays > 1 ? 's' : ''} left`
}

export const isPostInError = (postResult: any) => {
  return !!postResult.error;
}

/**
 * return integer between 0 and max excluded
 * @param max
 */
export function getRandomInt(max: number) {
  return Math.floor(Math.random() * max);
}

export const sleepNow = (delay: number) =>
  new Promise((resolve) => setTimeout(resolve, delay));

export function responseOk(responseObject:any){
  return responseObject && !responseObject.error;
}