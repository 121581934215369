import React from "react"
import './index.css'

interface Props {
  children: React.ReactNode
}

function HeightLayout(props: Props) {
  return (
      <div
        className={"text-white select-none w-screen h-full"}>
        {props.children}
      </div>
  )
}

export default HeightLayout
