import React, {useEffect, useState} from 'react'
import {FullScreenDialog} from "../ui/modal-confirmation";
import {ExplodingHeadIcon} from "../ui/exploding-head-icon";
import {useGetPlayerQuery, useGetPlayersListQuery, useGetUserQuery, usersApi} from "../../apis/users.api";
import {classNames, formatRank, hasElements} from "../../utils/Helper";
import {QuantityCoinComponent} from "../ui/quantity-coin.component";
import {PodiumComponent} from "./podium.component";
import {GainComponent} from "../ui/gain.component";
import {CoinIcon} from "../ui/coin-icon";
import {ItemShadowIcon} from "../ui/item-shadow-icon";
import {GemsIcon} from "../ui/gems-icon";
import {motion} from "framer-motion";
import {User} from "../../models/User";
import {AddIcon} from "../ui/add-icon";
import {XIcon} from "../ui/x-icon";
import {WeekResultComponent} from "./week-result.component";
import {toastSuccess} from "../../utils/ToastUtils";
import {SnapshotWeekResult} from "./snapshot-week-result.component";
import {SnapshotLargeWeekResult} from "./snapshot-large-week-result.component";
import {snapHtml} from "../../utils/HtmlHelper";
import {useAppDispatch} from "../../app/hooks";
import toast from "react-hot-toast";
import {useGetLastWeekParticipationCountQuery} from "../../apis/game.api";
import { SocialSharingComponent } from '../social/sharing.component';

export interface WeekResumeProps {
  score: number
  gain: number,
  rank: string,
  xpGain?: number,
  gemsGain?: number,
  investment: number
  roi: number,
  ackNotif: Function,
  user: User;
}

const WeekResumeNotifComponent: React.FC<WeekResumeProps> = (payload) => {
  const [step, setStep] = useState(1);
  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (step > 5) {
      setOpen(false);
      payload.ackNotif();
    } else {
      setOpen(true);
    }
  }, [step]);

  // useEffect(() => {
  //   console.log(`step ${step}`);
  // }, [step])

  return <div id={"WeekResumeNotifComponent"}
              className={`relative flex justify-center flex-col items-center gap-y-6 w-full z-10`}>
    <FullScreenDialog name={"main"} displayOpenButton={false} openProp={open} timer={step < 5 ? 10 : undefined}
                      autoClosing={false}
                      restartTimer={true}
                      showUpperCloseButton={step === 5}
                      timerFinished={() => {
                        setStep((prev) => ++prev);
                      }} onClose={() => {
      toast.dismiss();
    }}>
      <>
        {step === 1 &&
          <StepWow payload={payload}/>
        }
        {step === 2 &&
          <StepRank payload={payload}/>
        }
        {step === 3 &&
          <StepHugeProfit payload={payload}/>
        }
        {step === 4 &&
          <StepGems payload={payload}/>
        }
        {step === 5 &&
          <StepShare payload={payload}/>
        }
      </>
    </FullScreenDialog>
  </div>
}

interface StepProps {
  payload: WeekResumeProps;
}

const StepWow: React.FC<StepProps> = ({}) => {
  return <div className={"h-full flex flex-col gap-y-16 justify-center items-center"}>
    <ExplodingHeadIcon h={50}/>

    <div className={"flex-col flex gap-3 justify-center items-center"}>
      <div className={"rounded-lg bg-black bg-opacity-20 text-white font-bold px-2 py-2 text-lg text-center"}>
        You had an amazing week!
      </div>
      <div className={"text-white text-center font-normal"}>
        Ready for a summary ?
      </div>
    </div>
  </div>
};

const StepRank: React.FC<StepProps> = ({payload}) => {
  const [rank, setRank] = useState(0);
  const {data: user} = useGetUserQuery();
  useEffect(() => {
    const r = Number(payload.rank);
    setRank(r > 1 ? r - 2 : 1);
    // console.log(`setting rank to ${r > 2 ? r - 2 : 1}`)
  }, [payload]);

  const {data: playersData, isLoading: arePlayersLoading} = useGetPlayersListQuery({
    week: 'previous',
    offset: rank,
    pageSize: 3,
    pageIndex: 0
  });

  const getCurrentCss = (pseudo: string) => {
    if (user && pseudo === user.pseudo) {
      return 'bg-black rounded-lg text-white z-10';
    } else return '';
  }

  return <div className={"h-full flex flex-col gap-y-4 justify-center items-center px-2"}>

    <div className={"flex-col flex gap-3 justify-center items-center"}>
      <div className={"rounded-lg bg-black bg-opacity-20 text-white font-bold px-2 py-2 text-lg text-center"}>
        {Number(payload.rank) <= 3 ? 'Wow!' : 'Not bad!'}
      </div>
      <div className={"text-white text-center font-normal text-sm opacity-80"}>
        {Number(payload.rank) <= 3 ? 'You ranked among the best 3 players this week' : 'You missed the leaderboard, but there is new chance ahead'}
      </div>
    </div>

    {Number(payload.rank) > 3 &&
      <div
        className={"relative overflow-hidden flex-col flex gap-3 justify-center items-center mx-2 bg-white py-1 px-2 rounded-lg flat-card w-full"}>
        <>
          <div
            className={"z-0 backdrop-blur-[1px] shadow-[inset_0px_0px_100px_30px_white] absolute left-0 right-0 top-0 bottom-0"}></div>
          {playersData && hasElements(playersData.players) && playersData.players.map(p => <div key={`${p.pseudo}`}
                                                                                                className={classNames("flex w-full mx-1 gap-x-3 items-center py-3 px-3 text-sm", getCurrentCss(p.pseudo))}>
            <span className={"w-6"}>{p.rank}</span>
            <div className={"flex items-center text-inherit gap-x-2"}>
              <div className={"p-0.5 bg-white shadow border border-neutral-200 w-8 h-8 rounded"}>
                <img src={p.avatarUrl || `${process.env.REACT_APP_STATIC_ASSETS}/favicon.svg`}
                     className="rounded-sm object-cover w-full h-full"/>
              </div>
              <span className={"text-inherit max-w-[103px] overflow-hidden overflow-ellipsis"}>{p.pseudo}</span>
            </div>
            <span className={"grow flex justify-end flex flex-col items-end"}>
                <QuantityCoinComponent quantity={p.score}/>
                <div
                  className={classNames("text-green-500 text-[12px] font-bold font-['Satoshi'] leading-[10px]")}>
                  <GainComponent gain={(p.gain || 0)}/>
                </div>
              </span>
          </div>)}
        </>
      </div>
    }

    {Number(payload.rank) <= 3 &&
      <PodiumComponent firstPlayersData={playersData} navigation={true}/>
    }
  </div>
};


const StepHugeProfit: React.FC<StepProps> = ({payload}) => {
  const [currentScore, setCurrentScore] = useState(0);

  function animateValue(start: number, end: number, duration: number) {
    let startTimestamp: number | null = null;
    const step = (timestamp: number) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      setCurrentScore(Math.floor(progress * (end - start) + start));
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  }

  // function animateValueReverse(start: number, end: number, duration: number) {
  //   let startTimestamp: number | null = null;
  //   const step = (timestamp: number) => {
  //     if (!startTimestamp) startTimestamp = timestamp;
  //     const progress = Math.min((timestamp - startTimestamp) / duration, 1);
  //     setCurrentScore(start - Math.floor(progress * (start - end)));
  //     if (progress < 1) {
  //       window.requestAnimationFrame(step);
  //     }
  //   };
  //   window.requestAnimationFrame(step);
  // }

  useEffect(() => {
    animateValue(0, payload.score, 4000);
  }, []);

  return <div className={"h-full flex flex-col gap-y-4 justify-center items-center px-5"}>

    <div className={"flex flex-col justify-center items-center gap-y-2"}>
      <h1 className={"text-xl rounded-lg bg-black bg-opacity-10 text-white font-bold px-2 py-2 text-center"}>
        A huge profit
      </h1>
      <div className={"font-normal opacity-80 text-white"}>for this week</div>
    </div>


    <div className={"flex-col flex gap-3 justify-center items-center"}>
      <motion.div
        className=""
        animate={{
          y: ["10%", "-10%", "10%"],
        }}
        transition={{
          duration: 3,
          ease: "easeInOut",
          repeat: Infinity,
        }}
      >
        <CoinIcon h={48}/>
      </motion.div>
      <motion.div
        animate={{
          opacity: [0.7, 1, 0.7]
        }}
        transition={{
          duration: 3,
          ease: "easeInOut",
          repeat: Infinity,
        }}
      >
        <ItemShadowIcon/>
      </motion.div>
      <div className={"rounded-lg bg-black bg-opacity-50 text-white font-bold px-4 py-2 text-center text-3xl"}>
        <motion.div
          className="text-white flex flex-row gap-2 justify-between items-center"
          animate={{opacity: 1}}
          exit={{opacity: 0, transition: {duration: 2}}}
          transition={{duration: 2, delay: 2}}
        >
          <CoinIcon h={24}/>{currentScore}
        </motion.div>
      </div>
    </div>

    <div className={"rounded-full flat-card text-center font-normal flex items-center py-2 px-4 pr-8 mt-8"}>
      <span className={"mr-2"}><CoinIcon/></span><span className={"text-left text-sm"}>Win at least 3000 coins to turn them into gems</span>
    </div>
  </div>
};

const StepGems: React.FC<StepProps> = ({payload}) => {


  const [currentScore, setCurrentScore] = useState(0);

  function animateValue(start: number, end: number, duration: number) {
    let startTimestamp: number | null = null;
    const step = (timestamp: number) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      setCurrentScore(Math.floor(progress * (end - start) + start));
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  }

  useEffect(() => {
    animateValue(0, payload.gemsGain || 0, 2000);
  }, []);


  return <div className={"h-full flex flex-col gap-y-4 justify-center items-center px-5"}>

    <div className={"flex flex-col justify-center items-center gap-y-2"}>
      <h1 className={"text-2xl rounded-lg bg-black bg-opacity-10 text-white font-bold px-2 py-2 text-center"}>
        {payload.gemsGain && payload.gemsGain > 0 ? "A lot of gems" : "No gem"}
      </h1>
      <div className={"font-normal opacity-80 text-white"}>
        {payload.gemsGain && payload.gemsGain > 0 ? "as a reward" : "you'll do better this week!"}
      </div>
    </div>


    <div className={"flex-col flex gap-3 justify-center items-center"}>
      <motion.div
        className=""
        animate={{
          y: ["10%", "-10%", "10%"],
        }}
        transition={{
          duration: 3,
          ease: "easeInOut",
          repeat: Infinity,
        }}
      >
        <GemsIcon h={48}/>
      </motion.div>
      <motion.div
        animate={{
          opacity: [0.7, 1, 0.7]
        }}
        transition={{
          duration: 3,
          ease: "easeInOut",
          repeat: Infinity,
        }}
      >
        <ItemShadowIcon/>
      </motion.div>
      <div className={"rounded-lg bg-black bg-opacity-50 text-white font-bold px-4 py-2 text-center text-xl"}>
        <motion.div
          className="text-white flex flex-row gap-2 justify-between items-center"
          animate={{opacity: 1}}
          exit={{opacity: 0, transition: {duration: 2}}}
          transition={{duration: 2, delay: 2}}
        >
          <GemsIcon h={24}/>{currentScore}
        </motion.div>
      </div>

      <div className={"rounded-full flat-card text-center font-normal flex items-center py-2 px-4 pr-8 mt-4"}>
        <span className={"mr-2"}><GemsIcon/></span>
        <span className={"text-left text-xs"}>Use gems to earn even more coins in the next cycle</span>
      </div>
    </div>
  </div>
};


const StepShare: React.FC<StepProps> = ({payload}) => {
  const {data: player} = useGetPlayerQuery({pseudo: payload.user.pseudo}, {skip: !payload});
  const [imageGenerated, setImageGenerated] = useState(false);
  const [sharingText, setSharingText] = useState('');
  const dispatch = useAppDispatch();
  const imageURLToPreload = [
    `${process.env.REACT_APP_STATIC_ASSETS}/images/floor-of-tanji.png`,
    `${process.env.REACT_APP_STATIC_ASSETS}/images/safe-full-of-tanji.png`
  ];

  const {data: playersCount} = useGetLastWeekParticipationCountQuery();

  //generate Score Card
  useEffect(() => {
    if (player && !imageGenerated) {
      const ps = [];

      ps.push(snapHtml('landscape', {width: 1200, height: 600}, 'week-snap-landscape', imageURLToPreload));

      ps[0].then(() => {
        ps.push(snapHtml('portrait', {width: 450, height: 800}, 'week-snap'));
      });

      const a = new Date().getTime();

      let done = false;
      const tud = toast.promise(Promise.all(ps).then((urls) => {
        done = true;
        setImageGenerated(done);
      }).finally(() => {
        //reload player with new score image
        dispatch(usersApi.util.invalidateTags([{type: 'getPlayer', id: payload.user.pseudo}]));
        done = true;
        setImageGenerated(done);
      }), {success: 'Sharing card generated ! ', error: '', loading: 'Generating your sharing card...'}).then(() => {
        console.log('done');
      });

      //in the case where the image generation does not succeed and hang forever we force the 'ack' of the notification
      //to close the notification layer. Otherwise the user is blocked forever.
      const i = setInterval(() => {
        if (new Date().getTime() - a > 20000 && !done) {
          toast.dismiss();
          setImageGenerated(true);
          payload.ackNotif();
          clearInterval(i);
        }
      }, 1000)
    }
  }, [player, imageGenerated]);

  useEffect(() => {
    if (player)
      setSharingText(`Just finished ${formatRank(player.previousWeekRank)} rank on @tanjiblocks, the app where fun and financial revolution meet each other. Now you can try to beat me.`);
  }, [player]);

  return <>{player && <div className={"h-full flex flex-col pt-4 gap-y-3 items-center"}>

    {/*hidden elements used to generate image*/}
    <div className={"my-10 w-[450px] h-[800px] overflow-hidden hidden"}>
      {player && <SnapshotWeekResult player={player} playersCount={playersCount}/>}
    </div>

    <div className={"my-10 w-[1200px] h-[600px] overflow-hidden hidden"}>
      {player && <SnapshotLargeWeekResult player={player}/>}
    </div>

    <div className={"w-full md:w-1/2"}>{player &&
      <WeekResultComponent player={player} playersCount={playersCount}/>}</div>

    <div className={"flat-card !rounded-full text-center font-normal flex items-center py-2 px-4 pr-8"}>
      <span className={"mr-2"}><CoinIcon/></span><span className={"text-left text-xs"}>Make three friends sign up and earn 2000 extra TANJI</span>
    </div>

    <div className={"flex flex-col gap-y-1"} onClick={(e) => {
      e.stopPropagation();
    }}>

      <div className={"w-full text-center text-white opacity-70"}>Share on</div>

      <SocialSharingComponent
        player={player}
        sharingText={sharingText} 
      /> 
      
    </div>
  </div>}</>
};

export {WeekResumeNotifComponent};