import React from "react";
import {formatGain} from "../../utils/usePerfFormatter";

type Props = {
  gain: number;
};


const GainComponent: React.FC<Props> = ({gain}) => {
  return (
    <div className={gain >= 0 ? "text-green-600" : "text-red-500"}>{formatGain(gain)}</div>
  )
}

export {GainComponent};

