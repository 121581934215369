import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {FullScreenDialog} from "../modal-confirmation";
import toast from "react-hot-toast";
import {CollectibleCardComponent} from "../../collectibles/collectible-card.component";

export interface CollectibleFeaturingProps {
//   isBlurred?: boolean,
//   forcedAchievement?: boolean,
//   achievement: NotificationAchievement,
  ackNotif: Function,
}

export const CollectibleFeaturing: React.FC<CollectibleFeaturingProps> = (payload) => {

  const [step, setStep] = useState(1);
  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (step > 2) {
      setOpen(false);
      payload.ackNotif();
    } else {
      setOpen(true);
    }
  }, [step]);

  return <div id={"CollectibleFeaturingNotifComponent"}
              className={`relative flex justify-center flex-col items-center gap-y-6 w-full z-10`}>
    <FullScreenDialog
      name={"main"}
      displayOpenButton={false}
      openProp={open}
      timer={step === 1 ? 10 : undefined}
      autoClosing={step === 1 ? false : true}
      restartTimer={step === 1 ? true : false}
      showUpperCloseButton={step === 2}
      timerFinished={() => {
        setStep((prev) => ++prev);
      }}
      onClose={() => {
        toast.dismiss();
      }}
    >
      <>
        {step === 1 &&
          <StepImg/>
        }
        {step === 2 &&
          <StepText isBlurred={false} setStep={setStep}/>
        }
      </>
    </FullScreenDialog>
  </div>

}

interface StepProps {
  isBlurred: boolean;
  setStep: Function;
}

const StepImg: React.FC<{}> = () => {

  return <div className={"h-full flex flex-col gap-y-6 justify-center items-center"}>

    <div className={"flex flex-col gap-y-2 items-center"}>
      <img className="w-16" src={`${process.env.REACT_APP_STATIC_ASSETS}/images/collectible-card.png`}/>
    </div>

    <div className={"flex-col flex gap-3 justify-center items-center"}>
      <div
        className={"rounded-lg bg-black bg-opacity-20 text-white font-bold px-2 py-2 text-lg text-center"}>
        Brand new feature unveiled!
      </div>

      <div className={"text-white text-center text-sm"}>
        Crypto Cards Collectibles have just been added in the app.
      </div>

      <div className={"mt-4"}>
        <div className={"text-white text-center"}>
          <CollectibleCardComponent
            collectible={{
              logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/1.png",
              xp: 5,
              name: "Bitcoin",
              code: "BTC",
              level: 1,
              nextLevelXp: 1,
              currentLevelXp: 7,
              unlocked: true,
              upgrade: true,
              cryptoCurrencyId: "string",
              step: 1,
            }}
            animateProgressBar={false}
            shineEffect={true}/>
        </div>
      </div>

    </div>

  </div>
};

const StepText: React.FC<StepProps> = ({isBlurred = false, setStep: setStep}) => {
  return <div className={"h-full flex flex-col gap-y-6 justify-center items-center"}>

    <div className={"flex flex-col gap-y-2 items-center"}>
      <img className="w-16" src={`${process.env.REACT_APP_STATIC_ASSETS}/images/collectible-card.png`}/>
    </div>

    <div className={"flex-col flex gap-3 justify-center items-center"}>
      <div
        className={"rounded-lg bg-black bg-opacity-20 text-white font-bold px-2 py-2 text-lg text-center"}>
        Crypto Card Collectibles
      </div>

      <div className={"text-white text-center text-sm grow overflow-y-auto"}>
        They give you lifetime advantages and can become Non Fungible Tokens.
        <br/><br/>
        They gain experience related to the investment actions your take in-app using a crypto.
        <br/><br/>
        You can unlock a collectible once it gains enough experience. Once unlocked, buy it using Gems.
        <br/><br/>
        Check profile page to track your progress
        <br/><br/>
        Crypto Cards Collectibles have just been added in the app.
      </div>

      <Link to={`/collectibles`}>
        <button
          type="button"
          // className={"w-full flex justify-center items-center border-2 rounded-md border-orange text-orange text-xs lg:text-base py-2 px-2 gap-x-2 fat-orange-shadow-4 orange-button"}
          className={"w-full flex justify-center items-center text-xs lg:text-base orange-button py-4 px-12 gap-x-2"}
          style={{
            filter: isBlurred ? "blur(10px)" : "none",
            letterSpacing: "1px",
            cursor: "pointer",
            transition: "filter 0.5s ease-in-out",
          }}
          onClick={() => {
            setStep(3);
          }}
        >
          CHECK
        </button>
      </Link>

    </div>

  </div>
};