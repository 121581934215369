import {canParticipate, hasTournamentEnded, isTournamentRunning, Tournament} from "../../../models/Tournament";
import {CryptoCurrency} from "../../../models/Wallet";
import {classNames, cssHidden, formatBalanceNumber} from "../../../utils/Helper";
import {PerfComponent} from "../../ui/perf.component";
import {QuantityCoinComponent} from "../../ui/quantity-coin.component";

interface BetTokenItemProps {
  crypto: CryptoCurrency,
  tournament: Tournament,
  isGoodChoice: Function,
  choseCallback: Function,
  choiceDone: boolean,
  variationRate: number,
  openPrice: number,
  price: number,
  chosen?: CryptoCurrency;
  odd?: number,
  collectibleBoosted?: number,
}

const BetTokenItem: React.FC<BetTokenItemProps> = ({
                                                     crypto,
                                                     tournament,
                                                     isGoodChoice,
                                                     choseCallback,
                                                     choiceDone,
                                                     variationRate,
                                                     openPrice = 0,
                                                     price = 0,
                                                     chosen, odd = 1.1, collectibleBoosted = 0,
                                                   }) => {

  const isRunning = isTournamentRunning(tournament);
  const hasEnded = hasTournamentEnded(tournament);
  const imGood = isGoodChoice() && chosen && crypto._id === chosen._id;
  const imBad = !isGoodChoice() && chosen && crypto._id === chosen._id;

  const theGood = isGoodChoice() && chosen && crypto._id !== chosen._id;
  const theBad = !isGoodChoice() && chosen && crypto._id !== chosen._id;

  const getCssColor = () => {
    let res = '';
    if (choiceDone && (isRunning || hasEnded)) {
      if (imGood) {
        res = 'bg-victory-green text-white';
      } else if (imBad) {
        res = 'bg-red-500 text-white';
      }
    } else {
      if (crypto._id === chosen?._id) {
        res = 'bg-orange text-white !border-orange shadow-orange';
      }
    }
    return res;
  }

  const getPerfCssColor = () => {
    let res = '';
    if (imGood) {
      res = `bg-white rounded-full ${variationRate > 0 ? 'text-victory-green' : 'text-red-500'}`;
    } else if (imBad) {
      res = `bg-white rounded-full ${variationRate > 0 ? 'text-victory-green' : 'text-red-500'}`;
    } else if (theGood) {
      res = `text-white rounded-full ${variationRate > 0 ? 'bg-victory-green' : 'bg-red-500'}`;
    } else if (theBad) {
      res = ` text-white rounded-full ${variationRate > 0 ? 'bg-victory-green' : 'bg-red-500'}`;
    }
    return res;
  }

  const getBetQuoteCssColor = () => {
    let res = 'rounded-full';
    if (crypto._id !== chosen?._id) {
      res += ' bg-orange text-white ';
    } else {
      res += ' bg-white text-orange font-bold';
    }
    return res;
  }

  return <div
    className={classNames("mt-8 relative w-full flex flex-col gap-y-2 flat-card items-center justify-center ", (!choiceDone && canParticipate(tournament)) ? 'cursor-pointer' : 'shadow-none', getCssColor())}
    onClick={() => {
      if (!choiceDone && canParticipate(tournament))
        choseCallback(crypto)
    }}>

    {/* Crypto icon & collectible */}
    <div
      className="absolute -top-9 w-12 h-12 p-[3px] bg-white rounded-full fat-grey-shadow-2 border border-grey-100 justify-start items-start gap-4 inline-flex">
      <div className="w-full h-full justify-center items-center flex">
        <img className="w-full h-full rounded-full" src={crypto.logo}/>
      </div>
      {!!collectibleBoosted &&
        <img className="-bottom-0.5 -right-3 w-5 absolute"
             src={`${process.env.REACT_APP_STATIC_ASSETS}/images/collectible-card.png`}/>
      }
    </div>

    <div className={"flex flex-col text-center text-inherit gap-y-4"}>

      <p className={classNames("flex flex-col justify-center items-center text-inherit ")}>
        <span>{crypto.name}</span>
        <span className={classNames("text-xs")}>
          <QuantityCoinComponent size={12} quantity={price}/>
        </span>
      </p>

      <div
        className={classNames("text-center flex justify-center items-center py-1 px-4", getBetQuoteCssColor(), cssHidden(choiceDone))}>
        <span className={"text-xs mr-1"}>x</span>
        <span>{formatBalanceNumber(odd)}</span>
      </div>

      <p
        className={classNames('py-1 px-4 text-center w-full', choiceDone && (isTournamentRunning(tournament) || hasTournamentEnded(tournament)) ? '' : 'hidden', getPerfCssColor())}>
        <PerfComponent mathSymbolMode={true} noColor={true} perf={variationRate * 100}/>
      </p>

    </div>
  </div>
}

export default BetTokenItem;