import React from "react";
import { BackIcon } from "./back-icon";
import { useNavigate } from "react-router-dom";
import { classNames } from "../../utils/Helper";


const BackButton: React.FC<{ label?: string, path?: string, callback?: Function }> = ({ path, label, callback }) => {
  const nv = useNavigate();
  return <div className={"flex items-center justify-start"} onClick={() => {
    if (callback) {
      callback();
    }
    else if (path) nv(path);
    else nv(-1);
  }}>
    <div className="cursor-pointer w-8 h-[30px] bg-orange rounded-lg fat-orange-shadow-2 border-1 border-orange-700 center gap-2 inline-flex">
      <BackIcon />
      <div className={classNames(label ? '' : 'hidden', 'text-base font-bold')}>
        {label}
      </div>
    </div>
  </div>
};

export { BackButton };