import type {Middleware, MiddlewareAPI} from '@reduxjs/toolkit'
import {isRejectedWithValue} from '@reduxjs/toolkit'
import {toastError, toastMessage} from "../utils/ToastUtils";
import * as _ from 'lodash';
import {hasElements} from "../utils/Helper";

const getMessageLevel = (action: any): string => {
  let res = "error";
  if (action.payload.data.applicationMessageType === 'info') {
    res = "info";
  }
  return res;
}
const displayMessage = (message: string, level = 'error') => {
  if (level === 'info') {
    toastMessage(message);
  } else {
    toastError(message)
  }
}
/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action) && action?.payload?.status === 401) {
      let redirTarget = 'login';
      const isSignupPage = window.location.href.match(/.*(\/signup).*/g);
      const isSigninPage = window.location.href.match(/.*(\/login).*/g);
      if ((!isSigninPage && !isSignupPage)) {
        localStorage.setItem('sa_last_unauthorized_page', document.location.href);
        window.location.href = "/" + redirTarget;
      }
    }
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    else if (isRejectedWithValue(action) && (action?.payload?.data?.message || action?.payload?.data?.error)) {
      console.log('We got a rejected action!');
      const level = getMessageLevel(action);
      let mess = (action?.payload?.data?.message || action?.payload?.data?.error);
      if (_.isArray(mess) && hasElements(mess)) mess = mess[0];
      if (typeof mess==='string' && mess.toLowerCase() === "unauthorized") return;
      // alert(action.payload.data.message ? action.payload.data.message : action.payload.data.error);
      if ((typeof action.payload.data.message === 'string')) {
        displayMessage(action.payload.data.message, level);
      } else if ((typeof action.payload.data.error === 'string')) {
        displayMessage(mess ? mess : action.payload.data.error, level);
      } else {
        displayMessage("An error has occurred.");
      }
      // toast.warn({ title: 'Async error!', message: action.error.data.message })
    }

    return next(action)
  }