import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const coinbaseApi = createApi({
    reducerPath: "coinbaseApi",
    baseQuery: fetchBaseQuery({
        baseUrl: '/coinbase'
    }),
    endpoints: (build) => ({
        getPrimaryAccountTransactions: build.query<any, undefined>({
            query: () => ({
                url: "/"
            }),
        }),
        useGetPrimaryAccountWallets: build.query<any, undefined>({
            query: () => ({
                url: "/"
            }),
        }),
        getExchangeRates: build.query<any, undefined>({
            query: () => ({
                url: "/"
            }),
        }),
    }),
});

export const exchangeApi = createApi({
    reducerPath: "coinbaseApi",
    baseQuery: fetchBaseQuery({
        baseUrl: '/coinbase/exchange'
    }),
    endpoints: (build) => ({
        getExchangeRates: build.query<any, void>({
            query: () => ({
                url: "/"
            }),
        }),
    }),
});

export const { useGetPrimaryAccountTransactionsQuery } = coinbaseApi;
export const { useGetExchangeRatesQuery } = exchangeApi;

