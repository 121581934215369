import {useEffect, useState} from "react";
import {DateTime} from "luxon";

export function useGameCycleRemainingDays(): number {
  const [remainingDays, setRemainingDays] = useState<number>(0);
  useEffect(() => {
    const now = DateTime.now();
    const remaining = now.set({weekday: 7}).diff(now, 'days');
    setRemainingDays(Math.floor(remaining.days));
  }, []);
  return remainingDays;
}
