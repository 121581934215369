import React from "react";
import {classNames} from "../../utils/Helper";
import {QuantityGemsComponent} from "./quantity-gems.component";

type Props = {
  quantity: number;
  size?: number,
  css?: string | string[],
  label:string,
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined
};

const GemsCostButton: React.FC<Props> = ({label,quantity, size = 12, css = '', onClick}) => {
  return (
    <button
      className={classNames("w-full secondary flex justify-center cursor-pointer", css)}
      onClick={onClick}>

      <span className="uppercase">{label}</span>

      <div
        className={classNames("absolute right-0 mr-2 h-8 max-w-[56px] p-0.5 bg-black bg-opacity-20 rounded-md border border-black border-opacity-5 justify-start items-start gap-1 inline-flex", quantity > 0 ? '' : 'hidden')}>
        <div
          className="self-stretch w-full px-2 py-0.5 bg-transparent rounded border border-black border-opacity-20 justify-center items-center gap-1 flex">
          <div className="text-white text-sm font-bold font-['Satoshi'] leading-[17px]">
            <QuantityGemsComponent quantity={quantity} size={12}/>
          </div>
        </div>
      </div>
    </button>
  )
}

export {GemsCostButton};

