import React from 'react'
import {Link, useLocation} from "react-router-dom";
import {classNames} from "../../utils/Helper";

export type Props = {
}

const AuthNav: React.FC<Props> = () => {
  const location = useLocation();
  return <div className={"flex w-full mb-4  gap-x-4 max-w-4xl"}>
    <div className={classNames("grow w-0 rounded-xl border-white border-[1px] p-2", location.pathname.startsWith('/signup') ? '!text-orange bg-white':' bg-transparent text-white')}>
      <Link to={"/signup"} className="flex flex-row center gap-2">
        <p className="text-lg text-inherit font-bold">Create account</p>
      </Link>
    </div>
    <div className={classNames("grow w-0 rounded-xl border-white border-[1px] p-2", location.pathname.startsWith('/login') ? '!text-orange bg-white':' bg-transparent text-white')}>
      <Link to={"/login"} className="flex flex-row center gap-2">
        <p className="text-lg text-inherit font-bold">Sign in</p>
      </Link>
    </div>
  </div>
}

export { AuthNav };