import React, {useEffect, useRef, useState} from 'react';
import Layout from "../Layout";
import {useGetUserQuery} from "../apis/users.api";
import 'react-confirm-alert/src/react-confirm-alert.css';
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {useGetWalletAddressQuery} from "../apis/shop.api";
import {isStrictlyPositive} from "../utils/MathsUtils";
import {useNavigate, useParams} from "react-router-dom";
import toast from "react-hot-toast";
import {ethers} from "ethers";
import {useConnectEthereumWalletMutation} from "../apis/wallets.api";
import {toastError, toastSuccess} from "../utils/ToastUtils";
import {ProductItems} from '../components/shop/product-items.component';
import {RootState} from "../app/store";
import {classNames} from "../utils/Helper";
import {InnerHeader} from "../components/ui/app-inner-header.component";
import {registerWalletPublicAddress} from "../slices/shopSlice";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";


const CryptoPaymentPage: React.FC = () => {
  const {data: user} = useGetUserQuery();
  const nav = useNavigate();
  const dispatch = useAppDispatch();
  const {step} = useParams<{ step: string }>();
  const [registerEthereumAddress] = useConnectEthereumWalletMutation();
  const [ethAddress, setEthAddress] = useState<string | null>(null);
  const ref = useRef<HTMLInputElement>(null);
  const product = useAppSelector((state: RootState) => state.cart.product);
  const walletPublicAddress = useAppSelector((state: RootState) => state.cart.walletPublicAddress);
  const {data: appWalletAddress} = useGetWalletAddressQuery({});

  useEffect(() => {
    console.log(`appWalletAddress ${appWalletAddress}`);
  }, [appWalletAddress])

  function useDepay() {
    if (user) {
      const depayLink = `https://link.depay.com/${product?.externalId}?payload[user_id]=${user._id}&payload[product_id]=${product?.code}&status=success`;
      // console.log("Go TO : ", link);
      document.location.href = depayLink;
    }
  }

  useEffect(() => {
    if (!product) nav('/store');
  }, [product]);

  return (
    <Layout classes={"min-h-screen justify-end"}>
      <div
        className="fixed top-10 w-screen min-h-screen flex flex-col bg-gradient-to-b from-orange-700 to-orange pt-12 p-4 pb-16 items-center">
        <div className={classNames("flex justify-between w-full max-w-xl pb-4 mt-1 center")}>
          <InnerHeader pageTitle={'Crypto payment'}>
          </InnerHeader>
        </div>
      </div>

      <div
        className={classNames("mt-10 relative bg-white w-screen flex flex-col justify-between max-w-md gap-y-4 p-6 rounded-tl-2xl rounded-tr-2xl")}>
        <div className={"flex justify-center"}>
          {product &&
            <div className={"flat-card !p-0"}>
              <ProductItems
                externalId={product.externalId}
                image={product.image1}
                code={product.code}
                price={product.displayedPrice}
                discountPrice={isStrictlyPositive(product.displayedDiscountPrice) ? product.displayedDiscountPrice : undefined}/>
            </div>
          }
        </div>

        {(() => {
          if (step === 'initial') {
            return <div className={"flex w-full flex-col gap-y-4"}>
              <button className={"primary"} onClick={() => {
                gtag('event', 'shop_onChooseCryptoPaymentMode', {shop_crypto_payment_mode: 'walletConnection'});
                useDepay();
              }}>Connect my wallet & pay
              </button>
              <button className={"primary"} onClick={() => {
                gtag('event', 'shop_onChooseCryptoPaymentMode', {shop_crypto_payment_mode: 'connectionless'});
                nav('/crypto-pay/connection_less');
              }}>Send crypto directly*
              </button>
              <p className={"text-xxs font-normal italic text-opacity-50"}>*If you choose
                connectionless
                payment be careful to use
                supported networks and tokens.</p>
            </div>
          } else if (step === 'connection_less') {
            return <div className={"flex flex-col p-4 gap-y-6"}>
              <div>
                <p>1. Enter your ERC-20 wallet public address from which you will send coins from.</p>
                <input ref={ref} type={"text"} onChange={(e) => {
                  setEthAddress(e.target.value);
                  dispatch(registerWalletPublicAddress({walletPublicAddress: e.target.value}));
                }} value={walletPublicAddress || ''}/>
              </div>

              <button className={"primary"} onClick={async () => {
                if (ethAddress) {
                  const addr = ethers.utils.getAddress(ethAddress);
                  toast.loading('Checking address...');
                  const res = await registerEthereumAddress({address: addr});
                  // @ts-ignore
                  if (res.error) {
                    toastError("Bad ethereum address or address not available.");
                    gtag('event', 'shop_onConnectionLessCryptoAddressNotValid');
                    if (ref.current) ref.current.value = '';
                  } else {
                    toastSuccess('Address Ok! You can now send tokens to get you gems');
                    gtag('event', 'shop_onConnectionLessCryptoAddressValid');
                    nav('/crypto-pay/connection_less_2');
                  }
                }
              }}>Next
              </button>
            </div>
          } else if (step === 'connection_less_2') {
            return <div className={"flex flex-col gap-y-4"}>
              <p className={"flex"}>
                <span>2. Send any amount of crypto to the following address to receive gems.</span>
              </p>
              <div className={"flex flex-col items-center justify-between"}>
                <button className={"secondary"} onClick={(e) => {
                  navigator.clipboard.writeText(appWalletAddress?.address || '').then(() => {
                    toast.success('Address copied !');
                    gtag('event', 'shop_onConnectionLessCryptoAddressCopied');
                  })
                  e.stopPropagation();
                }}><span
                  className={"text-xxs overflow-x-hidden text-ellipsis w-full"}>{appWalletAddress?.address?.replace(/(.{10})(.{25})(.*)/, '$1...$3') || '**********************'}</span>
                  <ContentCopyIcon/></button>
              </div>
              <p className={"uppercase text-opacity-50 text-sm"}>
                Supported networks: Ethereum, Polygon and Binance Smart chain.
              </p>
              <p className={"uppercase text-opacity-50 text-sm"}>
                Supported cryptos : ETH, MATIC, USDC & USDT.
              </p>
              <p className={"text-opacity-50 text-sm"}>
                Send at
                least {product?.displayedDiscountPrice ? product?.displayedDiscountPrice : product?.displayedPrice}$
                (excluding gas fees) to
                get access to the displayed price.
              </p>
            </div>
          } else return <></>
        })()}
      </div>
    </Layout>
  )
}


export {
  CryptoPaymentPage
};