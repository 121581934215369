import React from 'react'
import {AlertTitle, Snackbar} from "@mui/material";
import Alert from "@mui/material/Alert";
import {getBottomAlertSx} from "../ui/MuiStyle";
import {useNavigate} from "react-router-dom";
import {CryptoCurrency} from "../../models/Wallet";
import {CollectibleIcon} from "../ui/collectible-icon";

export type Props = {
  onClose: Function,
  maxAmount:number,
  crypto:CryptoCurrency
}

const MaxBetAmountReachedSnackbar: React.FC<Props> = ({
                                              onClose,crypto,maxAmount
                                            }) => {
  const navigate = useNavigate();
  return <Snackbar sx={{bottom: '75px!important'}} anchorOrigin={{vertical: 'bottom', horizontal: 'center'}} open={true}
                   autoHideDuration={6000} onClose={() => {
    onClose()
  }}>
    <Alert className={"flat-card"} sx={getBottomAlertSx()} severity="error" action={
      <button className={"primary !text-sm"} onClick={() => navigate('/collectibles',{state:{cryptoId:crypto._id}})}>
       VIEW
      </button>
    }>
      <AlertTitle>Oops</AlertTitle>
      <span className={"text-normal"}>With your current {crypto.code} <CollectibleIcon/> collectible's level you can bet a maximum of <span className={"font-bold"}>{maxAmount}</span> Tanjis </span>
    </Alert>
  </Snackbar>
}

export {MaxBetAmountReachedSnackbar};