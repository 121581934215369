import React from "react";


const LockIcon: React.FC<{ h?: number }> = ({h = 24}) =>
  <svg width={h} height={h} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 3C12.477 3 8 7.477 8 13V23H12V13C12 9.687 14.686 7 18 7C21.314 7 24 9.687 24 13V23H28V13C28 7.477 23.523 3 18 3Z" fill="#AAB8C2"/>
    <path d="M31 32C31 34.209 29.209 36 27 36H9C6.791 36 5 34.209 5 32V20C5 17.791 6.791 16 9 16H27C29.209 16 31 17.791 31 20V32Z" fill="#FFAC33"/>
  </svg>
;

export {LockIcon};