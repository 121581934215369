import React from 'react';

const CrownIcon: React.FC<{ size?: number }> = ({size = 36}) => (
  <svg width={size} height={size} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.1741 17.075L6.75008 7.59399L3.02808 17.075H14.1741Z" fill="#F4900C"/>
    <path d="M17.938 5.53406L11.375 17.9231H24.5L17.938 5.53406Z" fill="#F4900C"/>
    <path d="M21.8259 17.075L29.2499 7.59399L32.9719 17.075H21.8259Z" fill="#F4900C"/>
    <path
      d="M28.669 15.19L23.887 3.52297L18.007 15.191L18 15.194L17.993 15.19L12.113 3.52197L7.33103 15.19C4.19703 10.833 1.28003 8.04197 1.28003 8.04197C1.28003 8.04197 3.00003 20.75 3.00003 33H33C33 20.75 34.72 8.04197 34.72 8.04197C34.72 8.04197 31.803 10.833 28.669 15.19Z"
      fill="#FFCC4D"/>
    <path
      d="M17.957 25.688C19.9939 25.688 21.645 24.0368 21.645 22C21.645 19.9632 19.9939 18.312 17.957 18.312C15.9202 18.312 14.269 19.9632 14.269 22C14.269 24.0368 15.9202 25.688 17.957 25.688Z"
      fill="#5C913B"/>
    <path
      d="M26.463 24.412C27.7951 24.412 28.875 23.3321 28.875 22C28.875 20.6679 27.7951 19.588 26.463 19.588C25.1309 19.588 24.051 20.6679 24.051 22C24.051 23.3321 25.1309 24.412 26.463 24.412Z"
      fill="#981CEB"/>
    <path
      d="M32.852 23.986C33.9488 23.986 34.838 23.0969 34.838 22C34.838 20.9032 33.9488 20.014 32.852 20.014C31.7551 20.014 30.866 20.9032 30.866 22C30.866 23.0969 31.7551 23.986 32.852 23.986Z"
      fill="#DD2E44"/>
    <path
      d="M9.45009 24.412C10.7822 24.412 11.8621 23.3321 11.8621 22C11.8621 20.6679 10.7822 19.588 9.45009 19.588C8.11797 19.588 7.03809 20.6679 7.03809 22C7.03809 23.3321 8.11797 24.412 9.45009 24.412Z"
      fill="#981CEB"/>
    <path
      d="M3.06095 23.986C4.15779 23.986 5.04695 23.0969 5.04695 22C5.04695 20.9032 4.15779 20.014 3.06095 20.014C1.96411 20.014 1.07495 20.9032 1.07495 22C1.07495 23.0969 1.96411 23.986 3.06095 23.986Z"
      fill="#DD2E44"/>
    <path
      d="M33 34H3C2.448 34 2 33.553 2 33C2 32.447 2.448 32 3 32H33C33.553 32 34 32.447 34 33C34 33.553 33.553 34 33 34ZM33 30.514H3C2.448 30.514 2 30.067 2 29.514C2 28.961 2.448 28.514 3 28.514H33C33.553 28.514 34 28.961 34 29.514C34 30.067 33.553 30.514 33 30.514Z"
      fill="#FFAC33"/>
    <path
      d="M1.44704 9.44901C2.2241 9.44901 2.85404 8.81907 2.85404 8.04201C2.85404 7.26494 2.2241 6.63501 1.44704 6.63501C0.669975 6.63501 0.0400391 7.26494 0.0400391 8.04201C0.0400391 8.81907 0.669975 9.44901 1.44704 9.44901Z"
      fill="#FFCC4D"/>
    <path
      d="M6.75011 8.78598C7.40843 8.78598 7.94211 8.2523 7.94211 7.59398C7.94211 6.93565 7.40843 6.40198 6.75011 6.40198C6.09178 6.40198 5.55811 6.93565 5.55811 7.59398C5.55811 8.2523 6.09178 8.78598 6.75011 8.78598Z"
      fill="#F4900C"/>
    <path
      d="M12.1131 5.30701C13.0984 5.30701 13.8971 4.50829 13.8971 3.52301C13.8971 2.53774 13.0984 1.73901 12.1131 1.73901C11.1278 1.73901 10.3291 2.53774 10.3291 3.52301C10.3291 4.50829 11.1278 5.30701 12.1131 5.30701Z"
      fill="#FFCC4D"/>
    <path
      d="M34.553 9.44901C35.3301 9.44901 35.96 8.81907 35.96 8.04201C35.96 7.26494 35.3301 6.63501 34.553 6.63501C33.7759 6.63501 33.146 7.26494 33.146 8.04201C33.146 8.81907 33.7759 9.44901 34.553 9.44901Z"
      fill="#FFCC4D"/>
    <path
      d="M29.2501 8.78598C29.9084 8.78598 30.4421 8.2523 30.4421 7.59398C30.4421 6.93565 29.9084 6.40198 29.2501 6.40198C28.5918 6.40198 28.0581 6.93565 28.0581 7.59398C28.0581 8.2523 28.5918 8.78598 29.2501 8.78598Z"
      fill="#F4900C"/>
    <path
      d="M23.887 5.30701C24.8723 5.30701 25.671 4.50829 25.671 3.52301C25.671 2.53774 24.8723 1.73901 23.887 1.73901C22.9017 1.73901 22.103 2.53774 22.103 3.52301C22.103 4.50829 22.9017 5.30701 23.887 5.30701Z"
      fill="#FFCC4D"/>
    <path
      d="M17.9381 7.318C18.9233 7.318 19.7221 6.51928 19.7221 5.534C19.7221 4.54872 18.9233 3.75 17.9381 3.75C16.9528 3.75 16.1541 4.54872 16.1541 5.534C16.1541 6.51928 16.9528 7.318 17.9381 7.318Z"
      fill="#F4900C"/>
  </svg>
);

const GrayedCrownIcon: React.FC<{ size?: number }> = ({size = 36}) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.5">
      <g clipPath="url(#clip0_1598_6516)">
        <path d="M6.2996 7.5889L3.00005 3.37512L1.34583 7.5889H6.2996Z" fill="#666666"/>
        <path d="M7.97243 2.45956L5.05554 7.96579H10.8889L7.97243 2.45956Z" fill="#666666"/>
        <path d="M9.70044 7.5889L13 3.37512L14.6542 7.5889H9.70044Z" fill="#666666"/>
        <path
          d="M12.7417 6.75112L10.6164 1.56578L8.00307 6.75156L7.99996 6.75289L7.99685 6.75112L5.38351 1.56534L3.25818 6.75112C1.86529 4.81467 0.568848 3.57423 0.568848 3.57423C0.568848 3.57423 1.33329 9.22223 1.33329 14.6667H14.6666C14.6666 9.22223 15.4311 3.57423 15.4311 3.57423C15.4311 3.57423 14.1346 4.81467 12.7417 6.75112Z"
          fill="#BCBCBC"/>
        <path
          d="M7.98091 11.4169C8.88616 11.4169 9.62002 10.683 9.62002 9.77778C9.62002 8.87253 8.88616 8.13867 7.98091 8.13867C7.07565 8.13867 6.3418 8.87253 6.3418 9.77778C6.3418 10.683 7.07565 11.4169 7.98091 11.4169Z"
          fill="#535353"/>
        <path
          d="M11.7613 10.8498C12.3534 10.8498 12.8333 10.3698 12.8333 9.77778C12.8333 9.18573 12.3534 8.70578 11.7613 8.70578C11.1693 8.70578 10.6893 9.18573 10.6893 9.77778C10.6893 10.3698 11.1693 10.8498 11.7613 10.8498Z"
          fill="#666666"/>
        <path
          d="M14.6009 10.6604C15.0884 10.6604 15.4836 10.2653 15.4836 9.77778C15.4836 9.29029 15.0884 8.89511 14.6009 8.89511C14.1134 8.89511 13.7183 9.29029 13.7183 9.77778C13.7183 10.2653 14.1134 10.6604 14.6009 10.6604Z"
          fill="#666666"/>
        <path
          d="M4.20005 10.8498C4.7921 10.8498 5.27205 10.3698 5.27205 9.77778C5.27205 9.18573 4.7921 8.70578 4.20005 8.70578C3.608 8.70578 3.12805 9.18573 3.12805 9.77778C3.12805 10.3698 3.608 10.8498 4.20005 10.8498Z"
          fill="#666666"/>
        <path
          d="M1.36045 10.6604C1.84793 10.6604 2.24312 10.2653 2.24312 9.77778C2.24312 9.29029 1.84793 8.89511 1.36045 8.89511C0.872967 8.89511 0.477783 9.29029 0.477783 9.77778C0.477783 10.2653 0.872967 10.6604 1.36045 10.6604Z"
          fill="#666666"/>
        <path
          d="M14.6667 15.1111H1.33336C1.08803 15.1111 0.888916 14.9124 0.888916 14.6667C0.888916 14.4209 1.08803 14.2222 1.33336 14.2222H14.6667C14.9125 14.2222 15.1111 14.4209 15.1111 14.6667C15.1111 14.9124 14.9125 15.1111 14.6667 15.1111ZM14.6667 13.5618H1.33336C1.08803 13.5618 0.888916 13.3631 0.888916 13.1173C0.888916 12.8715 1.08803 12.6729 1.33336 12.6729H14.6667C14.9125 12.6729 15.1111 12.8715 15.1111 13.1173C15.1111 13.3631 14.9125 13.5618 14.6667 13.5618Z"
          fill="#BCBCBC"/>
        <path
          d="M0.643156 4.19955C0.988518 4.19955 1.26849 3.91958 1.26849 3.57422C1.26849 3.22885 0.988518 2.94888 0.643156 2.94888C0.297794 2.94888 0.0178223 3.22885 0.0178223 3.57422C0.0178223 3.91958 0.297794 4.19955 0.643156 4.19955Z"
          fill="#BCBCBC"/>
        <path
          d="M2.99999 3.90489C3.29258 3.90489 3.52977 3.6677 3.52977 3.37511C3.52977 3.08253 3.29258 2.84534 2.99999 2.84534C2.7074 2.84534 2.47021 3.08253 2.47021 3.37511C2.47021 3.6677 2.7074 3.90489 2.99999 3.90489Z"
          fill="#666666"/>
        <path
          d="M5.38359 2.35867C5.82149 2.35867 6.17648 2.00368 6.17648 1.56578C6.17648 1.12788 5.82149 0.772888 5.38359 0.772888C4.94569 0.772888 4.5907 1.12788 4.5907 1.56578C4.5907 2.00368 4.94569 2.35867 5.38359 2.35867Z"
          fill="#BCBCBC"/>
        <path
          d="M15.3569 4.19955C15.7023 4.19955 15.9822 3.91958 15.9822 3.57422C15.9822 3.22885 15.7023 2.94888 15.3569 2.94888C15.0115 2.94888 14.7316 3.22885 14.7316 3.57422C14.7316 3.91958 15.0115 4.19955 15.3569 4.19955Z"
          fill="#BCBCBC"/>
        <path
          d="M13 3.90489C13.2926 3.90489 13.5298 3.6677 13.5298 3.37511C13.5298 3.08253 13.2926 2.84534 13 2.84534C12.7074 2.84534 12.4702 3.08253 12.4702 3.37511C12.4702 3.6677 12.7074 3.90489 13 3.90489Z"
          fill="#666666"/>
        <path
          d="M10.6165 2.35867C11.0544 2.35867 11.4094 2.00368 11.4094 1.56578C11.4094 1.12788 11.0544 0.772888 10.6165 0.772888C10.1786 0.772888 9.82361 1.12788 9.82361 1.56578C9.82361 2.00368 10.1786 2.35867 10.6165 2.35867Z"
          fill="#BCBCBC"/>
        <path
          d="M7.97245 3.25243C8.41035 3.25243 8.76534 2.89745 8.76534 2.45955C8.76534 2.02164 8.41035 1.66666 7.97245 1.66666C7.53455 1.66666 7.17957 2.02164 7.17957 2.45955C7.17957 2.89745 7.53455 3.25243 7.97245 3.25243Z"
          fill="#666666"/>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1598_6516">
        <rect width="16" height="16" fill="white"/>
      </clipPath>
    </defs>
  </svg>

);

export {CrownIcon, GrayedCrownIcon};
