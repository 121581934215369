import React, {useCallback, useEffect, useState} from 'react'
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {useGetUserQuery} from "../../apis/users.api";
import {ProfileDetail} from "../profile/profile-detail.component";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import axios from "axios";
import Twemoji from 'react-twemoji';
import {SmilingIcon} from "./smiling-icon";
import {TanjiIcon} from "./tanji-icon";
import {HomeIcon} from "../svg/home-icon";
import DailQuest from '../users/daily-quest.component';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export interface NavBarProps {
  active?: boolean
}

const NavBar: React.FC<NavBarProps> = ({active = true}) => {
  const location = useLocation();
  const {data: user} = useGetUserQuery();
  const [avatarUrlOk, setAvatarUrlOk] = useState(false);
  const navigate = useNavigate();

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    if (active) {
      navigate('/collectibles')
    }
  }, [active]);

  useEffect(() => {
    if (user && user.avatarUrl) {
      axios.get(user.avatarUrl).then(function () {
        setAvatarUrlOk(true);
      });
    }
  }, [user]);

  return (
    <>
      <div className={"!fixed top-0 w-screen h-[50px] bg-orange-700 flex center"} onClick={(e)=>{
        e.stopPropagation();
        e.preventDefault()
      }}></div>
      <div className="!fixed top-2 left-0 flex w-full center z-[1500]"  onClick={(e)=>{
        e.stopPropagation()
        e.preventDefault()
      }}>
        <div id="snav"
             className="w-full max-w-md mx-2 bg-orange shadow-[0px_-4px_8px_rgba(0,_0,_0,_0.3)] box-border border-b-[2px] border-solid border-orange-700 rounded-lg">

          <div
            className="flex row h-full w-full items-center align-middle pl-4 pr-2 py-2">

            <div className="hidden flex-1 grow">
              <div className={"hidden h-12 md:flex items-center flex-row gap-2 p-1 rounded-full hover:cursor"}>


                <div
                  className={classNames(
                    "nav-play-button desktop-mode h-full min-w-50 flex items-center font-bold px-3 py-2 rounded-full overflow-hidden uppercase border border-transparent",
                    (location.pathname.startsWith('/portfolio')) ? 'glass-light' : '')}>
                  <Link to={"/portfolio"} className={classNames("flex flex-row center gap-2", active ? '' : 'pointer-events-none')}>
                    <div className="h-4 w-4"><Twemoji><HomeIcon/></Twemoji></div>
                    <p className="font-bold text-sm text-white">Wallet</p>
                  </Link>
                </div>

                <div
                  className={classNames(
                    "nav-play-button desktop-mode h-full min-w-50 flex items-center font-bold px-3 py-2 rounded-full overflow-hidden uppercase border border-transparent",
                    (location.pathname.startsWith('/tournament') || location.pathname === '/home') ? 'glass-light' : '')}>
                  <Link to={"/tournament/current"} className={classNames("flex flex-row center gap-2", active ? '' : 'pointer-events-none')}>
                    <div className="h-4 w-4"><Twemoji>🎲</Twemoji></div>
                    <p className="font-bold text-sm text-white">Bet</p>
                  </Link>
                </div>

                <div
                  className={classNames(
                    "nav-leaderboard-button desktop-mode h-full min-w-50 flex items-center font-bold px-3 py-2 rounded-full overflow-hidden uppercase border border-transparent",
                    location.pathname.startsWith('/leaderboard') ? 'glass-light' : '')}>
                  <Link to={"/leaderboard/current"} className={classNames("flex flex-row center gap-2", active ? '' : 'pointer-events-none')}>
                    <div className="h-4 w-4"><Twemoji>👑</Twemoji></div>
                    <p className="font-bold text-sm text-white">Rankings</p>
                  </Link>
                </div>

                <div
                  className={classNames(
                    "nav-city-button desktop-mode h-full min-w-50 flex items-center px-3 py-2 rounded-full overflow-hidden uppercase border border-transparent",
                    location.pathname === '/profileDetails' ? 'glass-light' : '')}>
                  <Link to={"/profileDetails"}
                        className={classNames("flex flex-row center gap-2", active ? '' : 'pointer-events-none')}>
                    <div className="h-4 w-4"><SmilingIcon/></div>
                    <p className="font-bold text-sm text-white">Profile</p>
                  </Link>
                </div>

              </div>
            </div>

            {/* Top Bar */}
            <div className="md:flex-shrink-0 flex md:grow-0 md:center">
              <TanjiIcon/>
            </div>
            <div className="flex flex-1 grow justify-end">
              <div id="main-menu-component-container" className="flex flex-row gap-1 justify-end items-center">

                <DailQuest/>

                <div className="md:flex flex-col">
                  <ProfileDetail/>
                </div>

                {/*Profile component*/}
                <div
                  className={"flex w-full gap-x-2 center mb-[2px]"}
                  onClick={handleClick}>
                  <div
                    className={"w-[45px] h-[45px] p-[3px] bg-white rounded fat-grey-shadow-2 border border-neutral-200 justify-start items-start gap-4 inline-flex overflow-hidden"}>
                    {user && avatarUrlOk &&
                      <img className={"object-cover w-full h-full"} src={user.avatarUrl + '?' + new Date().getTime()}
                        width={48} height={48}
                      />
                    }
                    {!avatarUrlOk &&
                      <div className="w-full h-full flex center">
                        <AccountCircleIcon fontSize={"large"}/>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>)
}

export {NavBar};
