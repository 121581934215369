import React from "react";


const CloudArrowUpIcon: React.FC<{ h?: number }> = ({h = 17}) =><svg width={h} height={h} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2373_36947)">
      <path d="M8.00046 16.75H6.12546C5.50512 16.7492 4.89203 16.6165 4.32688 16.3608C3.76172 16.105 3.25743 15.7319 2.84746 15.2663C2.43748 14.8008 2.13121 14.2534 1.94895 13.6604C1.76669 13.0674 1.71261 12.4425 1.79031 11.827C1.86801 11.2116 2.07571 10.6197 2.39962 10.0906C2.72354 9.56153 3.15626 9.1074 3.66907 8.75834C4.18189 8.40928 4.76308 8.17326 5.37407 8.06595C5.98506 7.95864 6.61189 7.9825 7.21296 8.13593" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M6.75 10.5C6.75 9.50968 6.98533 8.53353 7.4366 7.652C7.88788 6.77047 8.54217 6.00879 9.34556 5.42974C10.149 4.85068 11.0784 4.47083 12.0574 4.32148C13.0364 4.17213 14.0369 4.25756 14.9764 4.57073C15.9159 4.8839 16.7676 5.41584 17.4612 6.12272C18.1547 6.8296 18.6704 7.69118 18.9657 8.63645C19.261 9.58173 19.3274 10.5836 19.1595 11.5596C18.9916 12.5356 18.5942 13.4577 18 14.25" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.875 13L12.375 10.5L14.875 13" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.375 16.75V10.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_2373_36947">
        <rect width="20" height="20" fill="white" transform="translate(0.5 0.5)"/>
      </clipPath>
    </defs>
  </svg>
;

export {CloudArrowUpIcon};