import {DiscordRoleInterface} from "./User";

export function getDiscordRoleColor(discordRole?: DiscordRoleInterface) {
  let res = 'black';
  // console.log(`Discord role : ${JSON.stringify(discordRole)}`);
  if (discordRole) {
    if (discordRole.name.startsWith('Crypto Addict')) {
      res = '#53599A';
    } else if (discordRole.name.startsWith('Crypto Curious')) {
      res = '#FDC605';
    } else if (discordRole.name.startsWith('Crypto Beginner')) {
      res = '#000000';
    } else if (discordRole.name.startsWith('Cryptopath')) {
      res = '#04ADB2';
    } else if (discordRole.name.startsWith('Admin') || discordRole.name.startsWith("Community Manager")) {
      res = '#EA5C28';
    }
  }
  return res;
}
