import React from 'react';

const VerifiedIcon = () => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.5" width="16" height="16" rx="8" fill="white" fillOpacity="0.1"/>
    <path d="M11.631 4.63281C11.2568 4.38526 10.7556 4.49371 10.5115 4.87542L6.98703 10.4062L5.35884 8.87345C5.03055 8.56426 4.51836 8.58503 4.215 8.91894C3.91163 9.25318 3.93171 9.77465 4.26 10.0835L6.59398 12.2808C6.74939 12.4275 6.94688 12.5 7.14308 12.5C7.36097 12.5 7.63973 12.407 7.82265 12.1246C7.93014 11.9578 11.8696 5.77266 11.8696 5.77266C12.1128 5.39062 12.006 4.88036 11.631 4.63281Z" fill="white"/>
    <rect x="0.5" y="1" width="15" height="15" rx="7.5" stroke="white" strokeOpacity="0.2"/>
  </svg>
);

export default VerifiedIcon;