import React from 'react';

const BullishIcon = () => (
<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_6582_56856)">
<path d="M4 7.99996C4 7.99996 0 9.99996 0 19C0 19 6 18 7 16C7 16 9 3.74996 4 7.99996ZM31.995 8.04296C31.995 8.04296 35.995 10.043 35.995 19.043C35.995 19.043 29.995 18.044 28.995 16.044C28.995 16.044 26.995 3.79296 31.995 8.04296Z" fill="#99AAB5"/>
<path d="M1 0.99996C-0.00999868 1.98996 2 8.99996 6 9.99996C10 11 10 4.99996 9 4.99996C5 4.99996 3.042 -1.00004 1 0.99996ZM35.297 0.99996C36.308 1.98996 34.297 8.99996 30.297 9.99996C26.297 11 26.297 4.99996 27.297 4.99996C31.297 4.99996 33.256 -1.00004 35.297 0.99996Z" fill="#FDC605"/>
<path d="M21.976 31H14.025C8.488 31 4 26.512 4 20.976V12.025C4 6.488 8.488 2 14.025 2H21.976C27.512 2 32 6.488 32 12.025V20.976C32 26.512 27.512 31 21.976 31Z" fill="#CCD6DD"/>
<path d="M35 28C35 33.522 30.522 36 25 36H11C5.477 36 1 33.522 1 28C1 22.478 5.477 18 11 18H25C30.522 18 35 22.478 35 28Z" fill="#E6AAAA"/>
<path d="M9.5 29C10.3284 29 11 27.6569 11 26C11 24.3431 10.3284 23 9.5 23C8.67157 23 8 24.3431 8 26C8 27.6569 8.67157 29 9.5 29Z" fill="#C1694F"/>
<path d="M26.5 29C27.3284 29 28 27.6569 28 26C28 24.3431 27.3284 23 26.5 23C25.6716 23 25 24.3431 25 26C25 27.6569 25.6716 29 26.5 29Z" fill="#C1694F"/>
<path d="M23.0001 13L25.0001 12V14C25.0001 14 25.0001 16 23.0001 16C21.0001 16 21.0001 14 21.0001 14L23.0001 13Z" fill="#272B2B"/>
<path d="M11 12L13 13L15 14C15 14 15 16 13 16C11 16 11 14 11 14L11 12Z" fill="#272B2B"/>
</g>
<path d="M26.5 28.3392C26.5 29.5203 25.7812 30.7738 24.2395 31.8007C22.7085 32.8205 20.5069 33.5 18 33.5C15.4931 33.5 13.2915 32.8205 11.7605 31.8007C10.2188 30.7738 9.5 29.5203 9.5 28.3392C9.5 27.755 9.66199 27.5048 9.82339 27.3502C10.0373 27.1453 10.4521 26.9243 11.2316 26.7637C12.5793 26.4861 14.3827 26.4944 16.6213 26.5047C17.0639 26.5067 17.5235 26.5088 18 26.5088C18.4765 26.5088 18.9361 26.5067 19.3787 26.5047C21.6173 26.4944 23.4207 26.4861 24.7684 26.7637C25.5479 26.9243 25.9627 27.1453 26.1766 27.3502C26.338 27.5048 26.5 27.755 26.5 28.3392Z" stroke="#FDC605" strokeWidth="3"/>
<path d="M25 26C25 29 25.5 29.5 22 30H13C10.7258 30 11 29.1813 11 25.5C11 21.8187 12.8434 18 15.1176 18H20.8823C23.1561 18 25.0001 23 25 26Z" fill="#E6AAAA"/>
<defs>
<clipPath id="clip0_6582_56856">
<rect width="36" height="36" fill="white"/>
</clipPath>
</defs>
</svg>

);

export default BullishIcon;
