import React from 'react';
import {Field, Form, Formik} from "formik";
import axios from "axios";
import Layout from "../Layout";
import {PublicNavBar} from "../components/ui/public-nav-bar.component";
import {useNavigate} from "react-router-dom";
import {TanjiLogo} from "../components/ui/seein-logo.component";

interface MyFormValues {
  email: string;
}

const AskresetpwdPage: React.FC = () => {
  const nv = useNavigate();
  const initialValues: MyFormValues = {email: ''};
  return (
    <Layout>
      <PublicNavBar/>
      <div className={"w-full rounded-2xl bg-orange p-4 max-w-xl justify-center flex"}><TanjiLogo/></div>
      <h2 className="text-gray-900 font-sans">Password forgotten</h2>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          axios.post('/users/resetpwd', {email: values.email}).then(function () {
            alert('An email was sent to ' + values.email)
          }).catch(function (error) {
            alert(error.response.data.message);
          });
        }}
      >
        <Form className={"flat-card w-full max-w-xl"}>
          <div id="form-content" className="">
            <div className={"block"}>
              <label className={""} htmlFor="email">Email</label>
              <Field type="email" id="email" name="email" placeholder="Email" className={"input"}/>
            </div>
            <div className={"flex w-full flex-wrap w-full justify-around gap-y-4 p-4"}>
              <button className={"secondary"} onClick={(e) => {
                e.preventDefault();
                nv(-1);
              }}>Cancel
              </button>
              <button className={"primary"} type="submit">Submit</button>
            </div>
          </div>
        </Form>
      </Formik>
    </Layout>
  );
};

export {AskresetpwdPage}