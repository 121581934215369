import React, {useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom"
import {useCreateUserMutation, useLoginMutation} from "../../../apis/users.api";
import toast from "react-hot-toast";
import {MetamaskLoginComponent} from "../metamask-login.component";
import {DiscordLoginComponent} from "../discord-login.component";
import {TwitterLoginComponent} from "../twitter-login.component";
import {GoogleLoginComponent} from "../google-login.component";
import {useIsAuthQuery} from "../../../apis/auth.api";
import {EyeClosedIcon} from "../../ui/eye-closed-icon";
import {EyeOpenIcon} from "../../ui/eye-open-icon";

export interface Props {
  onlyForm?: boolean;
  noRedirect?: boolean;
  hideGoogle?: boolean;
  hideTwitter?: boolean;
  hideCoinbase?: boolean;
  hideTikTok?: boolean;
  hideDiscord?: boolean;
  hideSignin?: boolean;
  signupCtaLabel?: string;
  userReferrerId?: string;
}

const SignupForm: React.FC<Props> = ({
                                       userReferrerId,
                                       onlyForm = false, noRedirect = false, hideGoogle = false
                                       , hideTwitter = false
                                       , hideCoinbase = false
                                       , hideDiscord = false
                                       , hideTikTok = false, hideSignin = false, signupCtaLabel = "Sign Up"
                                     }) => {
  const [email, setEmail] = useState("");
  const [emailErrored, setEmailErrored] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordErrored, setPasswordErrored] = useState(false);
  const [createUser, {data: createdUser, isLoading: isUserCreationLoading}] = useCreateUserMutation();
  const [login, {data: loggedUser, error: logginError}] = useLoginMutation();
  const navigate = useNavigate();

  const {data: isAuth, isLoading} = useIsAuthQuery();
  const [showPwd, setShowPwd] = useState<boolean>(false);

  useEffect(() => {
    if (!isLoading && isAuth) {
      navigate('/tournament');
    }
  }, [isLoading, isAuth]);

  useEffect(() => {
    if (!isUserCreationLoading && createdUser && createdUser.emailVerified !== true) {
      gtag('event', 'signup', {
        'email': email, emailVerified: false,
      });
      // toast("Please check your inbox to validate your email", {
      //   duration: 10000,
      //   icon: <span className={""}>&#128232;</span>
      // });
    }

    if (createdUser) {
      login({email, password});
    }
  }, [createdUser]);

  useEffect(() => {
    if (parent !== window) {
      parent.window.postMessage('redirectToAppHome', process.env.REACT_APP_DOMAIN as string);
    } else if (loggedUser && !logginError && noRedirect !== true) {
      gtag('event', 'loggin', {
        'email': email
      });

      navigate("/home", {replace: true});
    }
  }, [loggedUser, logginError])

  useEffect(() => {
    if (logginError) {
      setPasswordErrored(true);
      // @ts-ignore
      toast.error(logginError.data.message);
    }
  }, [logginError])

  const handleSignup = async () => {

    if (emailErrored) return;

    setSubmitDisabled(true);
    if (!email) {
      setEmailErrored(true);
    } else {
      setEmailErrored(false);
    }
    if (!password) {
      setPasswordErrored(true);
    } else {
      setPasswordErrored(false);
    }

    try {
      const referrerSource = localStorage.getItem("sa_s");
      const referrerId = localStorage.getItem("sa_i");
      await createUser({email, password, referrerSource, referrerId, userReferrerId});
    } catch (err) {
      setEmailErrored(true);
      setPasswordErrored(true);
    } finally {
      setSubmitDisabled(false);
    }
  }
  return (
    <>
      <div className="flex flex-col gap-4 w-full max-w-md mx-auto">
        <input type="hidden" name="remember" defaultValue="true"/>
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-1">
            <label htmlFor="email-address" className="text-sm font-medium0">
              Email
            </label>
            <input
              id="email-address"
              pattern={"^\S+@\S+\.\S+$"}
              name="email"
              type="email"
              autoComplete="email"
              required
              onChange={(event) => {
                setEmail(event.target.value);
                if (!event.target.value.match(/^\S+@\S+\.\S+$/)) {
                  setEmailErrored(true);
                } else setEmailErrored(false);
              }}
              className="sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 flex w-full p-2 dark:placeholder-gray-400 text-black"
              placeholder=""/>
            <span className="font-medium tracking-wide text-red-500 text-xs">
              {(emailErrored && "Please enter a valid Email.") || ""}
            </span>
          </div>
          <div className="flex flex-col gap-1">
            <label htmlFor="password" className="text-sm font-medium block">
              Password
            </label>

            <div className={"flex items-center relative"}>
              <input
                id="password"
                name="password"
                type={showPwd ? "email" : "password"}
                autoComplete="current-password"
                required
                onChange={(event) => setPassword(event.target.value)}
                className="sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 flex w-full p-2 dark:placeholder-gray-400 text-black"
                placeholder=""/>
              <span className={"absolute right-2"}>
                    <span className={showPwd ? 'hidden' : 'cursor-pointer'} onClick={(e) => {
                      setShowPwd(true);
                    }}>
                      <EyeClosedIcon/>
                  </span>

                  <span className={!showPwd ? 'hidden' : 'cursor-pointer'} onClick={(e) => {
                    setShowPwd(false);
                  }}>
                     <EyeOpenIcon/>
                  </span>
                  </span>
            </div>
            <span className="font-medium tracking-wide text-red-500 text-xs">
              {(passwordErrored && "Please enter a valid password.") || ""}
            </span>
          </div>
        </div>
        <div className={"flex flex-col gap-y-2 items-center mt-4"}>
          <button
            disabled={submitDisabled}
            type="submit"
            className="primary w-full"
            onClick={handleSignup}
          >
            {signupCtaLabel}
          </button>
        </div>
      </div>

      <div className="flex flex-col w-full center gap-2 mt-4">
        <div className="self-stretch text-center text-base font-normal leading-snug">Or register with</div>
        <div
          className="p-2 bg-white bg-opacity-10 rounded-lg border border-white border-opacity-20 grid w-full grid-cols-3 gap-2">
          <MetamaskLoginComponent/>
          {/*{!hideCoinbase && <CoinbaseLoginComponent/>}*/}
          {!hideDiscord && <DiscordLoginComponent/>}
          {!hideGoogle && <GoogleLoginComponent/>}
          {!hideTwitter && <TwitterLoginComponent/>}
          {/*<TiktokLoginComponent />*/}
        </div>
      </div>
    </>
  );
};

export {SignupForm};
