import React from 'react'
import { UserNotification } from '../../../models/User';
import { useGetCollectibleQuery } from '../../../apis/collectibles.api';
import { FullScreenDialog } from '../modal-confirmation';
import { CollectibleCardComponent } from '../../collectibles/collectible-card.component';
import { getRandomSuccessIcon } from '../IconUtils';

interface CollectibleLevelUpNotifProps {
  notification: UserNotification,
  timer: number,
  visible: boolean,
  onClose: Function
}

const CollectibleLevelUpNotif: React.FC<CollectibleLevelUpNotifProps> = ({timer, visible, notification, onClose}) => {

  const {data: collectible} = useGetCollectibleQuery(notification.payload.cryptoId);

  return <FullScreenDialog onClose={onClose} timer={timer} openProp={visible}>
    <div className={"h-full flex flex-col gap-y-16 justify-center items-center"}>
      <div className={"flex flex-col gap-3 justify-center items-center"}>

        {getRandomSuccessIcon(42)}

        <div className={"rounded-lg bg-black bg-opacity-20 text-white font-bold px-2 py-2 text-lg text-center"}>
          Congratulations!
        </div>

        <div className={"text-white text-center"}>
          {notification.text}
        </div>

        {collectible && <CollectibleCardComponent shineEffect={true} collectible={collectible} animateProgressBar={true}/>}

      </div>
    </div>
  </FullScreenDialog>;
}

export default CollectibleLevelUpNotif;