const GiftIcon: React.FC<{ h?: number, animate?: boolean }>  = ({h=36, animate=false}) => (

  <div className="relative">

    <span className={`absolute h-full w-full rounded-full bg-[#FCAB40] ${ animate ? "animate-ping opacity-75" : "opacity-0" }`}></span>

    <svg width={h} height={h} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M33 31C33 33.2 31.2 35 29 35H7C4.8 35 3 33.2 3 31V14C3 11.8 4.8 10 7 10H29C31.2 10 33 11.8 33 14V31Z" fill="#FDD888"/>
      <path d="M36 11C36 13.2 34.2 15 32 15H4C1.8 15 0 13.2 0 11C0 8.8 1.8 7 4 7H32C34.2 7 36 8.8 36 11Z" fill="#FDD888"/>
      <path d="M3 15H33V17H3V15Z" fill="#FCAB40"/>
      <path d="M19 3H17C15.343 3 14 4.343 14 6V35H22V6C22 4.344 20.657 3 19 3Z" fill="#DA2F47"/>
      <path d="M16 7.00001C17.1 7.00001 17.263 6.48401 16.361 5.85301L9.63899 1.14701C8.73699 0.516009 7.55399 0.781009 7.00799 1.73601L4.99199 5.26401C4.44599 6.21901 4.89999 7.00001 5.99999 7.00001H16ZM20 7.00001C18.9 7.00001 18.737 6.48401 19.639 5.85301L26.362 1.14701C27.263 0.516009 28.447 0.781009 28.993 1.73601L31.009 5.26301C31.554 6.21901 31.1 7.00001 30 7.00001H20Z" fill="#DA2F47"/>
    </svg>

 </div>

);

export default GiftIcon;


