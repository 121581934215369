export const achievementsTranslations = {
  EN: {
    ACHIEVEMENTS: {
      WALLET_PLUG_IN_TYPE: "Wallet connection",
      CRYPTOS_COLLECTION: "Crypto collector",
      SQUAD_SIZE_WIN: "Squad Size",
      SQUAD_CRYPTO_MAJORITY_WIN: "Squad winner With Crypto",
      SQUAD_CRYPTO_MAJORITY_2ND: "Squad 2nd With Crypto",
      SQUAD_CRYPTO_MAJORITY_3RD: "Squad 3rd With Crypto",
      SQUAD_WINNER: "Squad Tournament Winner",
      SQUAD_2ND: "Squad Tournament 2nd",
      SQUAD_3RD: "Squad Tournament 3rd",
      SQUAD: "Squad",
      SQUAD_PARTICIPANT: "Squad participation",
      SQUAD_ROI: "Squad Best Scores",
      PERF: "Portfolio performance",
      CITY_LIKES : "City likes",
      CITY_VIEWS : "City views"
    },
  },
};

