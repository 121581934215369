import React, {useEffect, useState} from "react";
import Layout from "../../Layout";
import "./style.css";
import CircularProgress from "@mui/material/CircularProgress";
import {Link} from "react-router-dom";
import {useGetUserQuery} from "../../apis/users.api";
import {useGetAchievementsQuery} from "../../apis/achievements.api";
import {achievementsTranslations} from "../../app/translations";
import {getAllAchievementTypeDto} from "../../dto/achievement/get-all-achievement-type.dto";
import {SecondaryNavigation} from "../../components/ui/secondary-navigation.component";
import AchievementIcon from "../../components/svg/AchievementIcon";

const AchievementsPage: React.FC = () => {
  // Get the user and achievements data using React Query hooks
  const {data: user} = useGetUserQuery();
  const {data: achievementsData} = useGetAchievementsQuery();

  // Define state variables for the achievement types and loading state
  const [typeAchievements, setTypeAchievements] = useState<getAllAchievementTypeDto[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  // When the achievements data changes, update the typeAchievements state variable
  useEffect(() => {
    if (achievementsData) {
      setTypeAchievements(achievementsData);
      setLoading(false);
    }
  }, [achievementsData]);

  // Render the achievements page UI
  return (
    <Layout>
      <div className={"flex w-full flex-col gap-4 md:gap-8 max-w-4xl mx-auto pb-24"}>
        <div className="w-full max-w-4xl self-center"><SecondaryNavigation navItems={[
          {path: '/stats', name: 'Statistics'},
          {path: '/achievements', name: 'Achievements'},
        ]}/></div>
        {/* Show loading spinner while the data is being fetched */}
        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress color="primary"/>
          </div>
        )}

        {/* Render the achievements once the data has loaded */}
        {!loading && (
          <>
            <div className="w-full grid grid-cols-2 md:grid-cols-4 gap-4">
              {typeAchievements.map((achievement) => {
                // Calculate the completion ratio of the achievement
                const ratio = achievement.scoopUser / achievement.scoopMax;
                const width = `${ratio * 100}%`;

                // Translate the achievement name using the translations object
                const translatedName = achievementsTranslations.EN.ACHIEVEMENTS.hasOwnProperty(achievement.name)
                  ? achievementsTranslations.EN.ACHIEVEMENTS[achievement.name as keyof typeof achievementsTranslations.EN.ACHIEVEMENTS]
                  : achievement.name;

                // Render the achievement UI using a Link component
                return (
                  <Link key={`${achievement.name}`} to={`/achievements/${achievement.name}`}>
                    <div
                      className="flex flex-col flat-card h-min md:h-48 rounded-2xl SectionAchievement md:justify-between items-center overflow-hidden">
                      <div>
                        <div className="flex flex-row gap-1 text-xs font-bold items-center mt-4">
                          <div className=""><AchievementIcon/></div>
                          <div>{achievement.scoopUser}</div>
                          /{" "}
                          {achievement.scoopMax}
                        </div>
                      </div>
                      <div className="flex flex-col h-[88px] mt-4"><p
                        className="text-center text-sm md:text-base font-medium px-3">{translatedName}</p></div>

                      <div className="SectionAchievementHeaderRight flex w-full h-2">
                        <span>
                          <div className="bg-turquoise" style={{width: width}}/>
                        </span>
                      </div>
                      <div
                        className="absolute -top-4 -left-4 h-24 w-24 bg-white opacity-30 blur-[50px] rounded-full overflow-hidden"></div>
                    </div>

                    {/* <span /> */}
                  </Link>
                );
              })}
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export {AchievementsPage};
