import React from "react";

type CollectibleAmountComponentProps = {
  quantity: number; size?: number,
};

const CollectibleAmountComponent: React.FC<CollectibleAmountComponentProps> = ({quantity, size="w-4"}) => {
  return (
    <div className={"flex text-inherit items-center bg-white px-2 py-1 gap-2 rounded"}>
      <span className={"select-none text-inherit font-bold"}>{quantity}</span>
      <img className={`${size}`} src="/static/images/collectible-icon.png" alt="Collectible card icon"/>
    </div>
  )
}

export {CollectibleAmountComponent};

