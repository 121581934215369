import {emptySplitApi} from "./empty.api";
import {ShopProduct} from "../models/shop/Product";


export const shopApi = emptySplitApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getProducts: build.query<ShopProduct[], { family: string, subFamily?: string }>({
      query: ({family, subFamily}) => ({
        url: `/shop/products/${family}/${subFamily}`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{type: 'getProducts'}],
    }),
    getWalletAddress: build.query<{ address:string }, { productId?: string }>({
      query: ({productId}) => ({
        url: `/shop/walletAddress/`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{type: 'getShopWalletAddress'}],
    }),
    buyProductWithGems: build.mutation< void, { productCode?: string } >({
      query: (body) => ({
        url: '/shop/buyProductWithGems',
        method: 'POST',
        body
      }),
      invalidatesTags: ['User', 'UserMoney', {type: 'Collectibles', id: 'My'}, 'InfiniteCollectibleList', 'CondensedCollectibleList', 'Collectible', 'UserNotifications','Inventory'],
    }),
  }),

});

export const {
  useGetProductsQuery,
  useGetWalletAddressQuery,
  useBuyProductWithGemsMutation,
} = shopApi;