import {createSlice, PayloadAction} from '@reduxjs/toolkit'

interface Tournament {
  id: string;
}

// Define the initial state using that type
const initialState: Tournament = {id: ''};

export const tournamentSlice = createSlice({
  name: 'subscribingTournament',
  initialState,
  reducers: {
    startRegistration: (state, action: PayloadAction<string>) => {
      state.id = action.payload;
    },
    stopRegistration: (state, action: PayloadAction<void>) => {
      state.id = '';
    }
  }
})

// Action creators are generated for each case reducer function
export const {startRegistration, stopRegistration} = tournamentSlice.actions

export default tournamentSlice.reducer