import React from "react";


const MoneyBagIcon: React.FC<{ h?: number }> = ({h = 17}) => <svg width={h} height={h} viewBox="0 0 17 16" fill="none"
                                                                  xmlns="http://www.w3.org/2000/svg">
  <path
    d="M14.6767 10.6391C14.4109 7.69778 12.9442 6.22222 12.9442 6.22222L10.2776 2.66667H6.722L4.05534 6.22222C4.05534 6.22222 3.42467 6.85911 2.91445 8.12222C2.03045 8.41556 1.38867 9.23956 1.38867 10.2222C1.38867 10.8658 1.66645 11.4409 2.10378 11.8467C1.93667 12.1587 1.83312 12.5098 1.83312 12.8889C1.83312 13.7591 2.33801 14.5049 3.06645 14.8693C3.63667 15.7236 4.39845 16 4.94423 16H12.0553C12.6682 16 13.5544 15.6538 14.1344 14.5289C14.9927 14.2218 15.6109 13.4089 15.6109 12.4444C15.6109 11.6987 15.2402 11.0427 14.6767 10.6391ZM8.49978 2.66667C8.74423 2.66667 8.97 2.59644 9.16645 2.48178C9.36334 2.59644 9.58912 2.66667 9.83312 2.66667C10.5696 2.66667 11.6109 1.62489 11.6109 0.888889C11.6109 0.888889 11.6109 0 10.722 0C10.3718 0 10.2776 0.444444 9.83312 0.444444C9.38867 0.444444 9.38867 0 8.49978 0C7.61089 0 7.61089 0.444444 7.16645 0.444444C6.722 0.444444 6.62823 0 6.27756 0C5.38867 0 5.38867 0.888889 5.38867 0.888889C5.38867 1.62489 6.43045 2.66667 7.16645 2.66667C7.41045 2.66667 7.63623 2.59644 7.83312 2.48178C8.03 2.59644 8.25578 2.66667 8.49978 2.66667Z"
    fill="#FDD888"/>
  <path
    d="M11.1673 2.66667C11.1673 2.912 10.9687 3.11111 10.7229 3.11111H6.27843C6.0331 3.11111 5.83398 2.912 5.83398 2.66667C5.83398 2.42133 6.0331 2.22222 6.27843 2.22222H10.7229C10.9687 2.22222 11.1673 2.42133 11.1673 2.66667Z"
    fill="#BF6952"/>
  <path
    d="M11.1218 10.9076C11.1218 8.91778 7.30803 9.04756 7.30803 7.84711C7.30803 7.26578 7.88625 6.98178 8.55736 6.98178C9.68536 6.98178 9.88625 7.67911 10.3969 7.67911C10.7583 7.67911 10.9325 7.46 10.9325 7.21422C10.9325 6.64356 10.0329 6.21156 9.17025 6.06222V5.51111C9.17025 5.16756 8.88136 4.88889 8.52403 4.88889C8.16625 4.88889 7.87692 5.16756 7.87692 5.51111V6.08133C6.93648 6.28711 6.12714 6.91467 6.12714 7.93733C6.12714 9.848 9.94003 9.77067 9.94003 11.1129C9.94003 11.5782 9.41648 12.0431 8.55736 12.0431C7.26803 12.0431 6.8387 11.2036 6.31514 11.2036C6.06003 11.2036 5.83203 11.4098 5.83203 11.7209C5.83203 12.2156 6.69336 12.8102 7.87781 12.976L7.87736 12.9804V13.6018C7.87736 13.9449 8.16714 14.224 8.52448 14.224C8.88181 14.224 9.17114 13.9449 9.17114 13.6018V12.9804C9.17114 12.9729 9.16759 12.9671 9.16714 12.9604C10.2329 12.7693 11.1218 12.1018 11.1218 10.9076Z"
    fill="#67757F"/>
</svg>;

export {MoneyBagIcon};