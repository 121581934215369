import { toNumber } from "lodash";
import { CryptoCurrency } from "../../../models/Wallet";
import { QuantityCoinComponent } from "../../ui/quantity-coin.component";
import { PerfComponent } from "../../ui/perf.component";

interface TokenSpotProps {
  token: CryptoCurrency;
  collectible: boolean;
  rate: number;
  choiceDone: boolean;
}

const TokenSpot: React.FC<TokenSpotProps> = ({token, collectible, rate, choiceDone}) => {

  return (
    <div className="flex w-full center justify-evenly pb-2">

      {/* Crypto icon & collectible */}
      <div className="relative w-12 h-12 p-[3px] bg-white rounded-full fat-grey-shadow-2 border border-grey-100 justify-start items-start gap-4 inline-flex">
        <div className="w-full h-full justify-center items-center flex">
          <img className="w-full h-full rounded-full" src={token.logo}/>
        </div>
        { collectible &&
          <img className="absolute -bottom-0.5 -right-3 w-5" src={`${process.env.REACT_APP_STATIC_ASSETS}/images/collectible-card.png`}/>
        }
      </div>

      <div className="flex flex-col center">
        <p>{token.name}</p>
        <QuantityCoinComponent size={10} quantity={toNumber(token.lastQuote.price)} small={true}/>
      </div>

      {/* Not played */}
      { !choiceDone &&
        <div className="flex flex-col center">
          <p className="text-xs font-medium text-grey-700">% last 24h</p>
          <PerfComponent perf={((Number(token.lastQuote.price) - Number(token?.quote24H?.price || token.lastQuote.price)) / Number(token.lastQuote.price)) * 100}/>
        </div>
      }

      {/* Played */}
      { choiceDone &&
        <div className="flex flex-col center">
          <p className="text-xs font-medium text-grey-700">% since bet</p>
          <PerfComponent perf={rate}/>
        </div>
      }

    </div>
);
}

export default TokenSpot;