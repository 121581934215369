import React from 'react'
import QRCode from "react-qr-code";
import {PlayerItem} from "../../models/User";
import {WeekResultComponent} from "./week-result.component";

export interface WeekResultProps {
  player: PlayerItem,
  playersCount?: number,
}

const SnapshotWeekResult: React.FC<WeekResultProps> = ({player, playersCount = 0}) => {
  return <div id={`week-snap`}
              className={`relative items-center gap-y-5 bg-gradient-to-b from-orange-300 to-orange-700 w-full h-full pt-2 flex flex-col justify-between`}>

    <div className={"px-6"}>
      <div className={"w-full flex justify-center my-8"}>
        <img crossOrigin={"anonymous"} className={"z-10 w-28 relative"}
             src={`${process.env.REACT_APP_STATIC_ASSETS}/tanjiblocks-logo.png`}/>
      </div>

      <WeekResultComponent player={player} playersCount={playersCount}/>

      <div
        className={"z-20 relative top-10 bg-orange-700 text-white rounded-2xl flex justify-between items-center text-xs gap-x-4 p-4"}>
        <div className={"text-inherit"}>
          <span className={"text-inherit"}>Scan QR code to Register and get a 2000 coins new-user bonus!</span>
        </div>
        <div className={"text-inherit"}>
          <div className={"bg-white"}>
            <QRCode value={`https://app.tanjiblocks.com?tbrefid=${player?.userId}`} size={56} fgColor={'#BA481E'}/>
          </div>
        </div>
      </div>
    </div>


    <img crossOrigin={"anonymous"} className={"absolute z-10 object-cover relative flex-grow"}
         src={`${process.env.REACT_APP_STATIC_ASSETS}/images/floor-of-tanji.png`}/>
  </div>
};
export {SnapshotWeekResult};