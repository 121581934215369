import React, {useEffect, useState} from 'react';
import Layout from "../Layout";
import {useGetUserQuery} from "../apis/users.api";
import 'react-confirm-alert/src/react-confirm-alert.css';
import {InnerHeader} from "../components/ui/app-inner-header.component";
import {Navigate} from "react-router-dom";

const CheckoutReturn = () => {
  const [status, setStatus] = useState(null);
  const [customerEmail, setCustomerEmail] = useState('');
  const [productCode, setProductCode] = useState('');

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id') || "";
    const productCode = urlParams.get('product') || "";

    fetch(`/shop/stripe/session-status?session_id=${encodeURIComponent(sessionId)}`)
      .then((res) => res.json())
      .then((data) => {
        setStatus(data.status);
        setCustomerEmail(data.customer_email);
        setProductCode(productCode);
      });

  }, []);

  if (status === 'open') {
    return (
      <Navigate to="/checkout"/>
    )
  }

  if (status === 'complete') {
    gtag('event', 'shop_onFiatPaymentCheckoutCompleted');

    let successRedirectUrl = "/store?status=success&paymentType=fiat";
    if (productCode){
      successRedirectUrl += "&product=" + productCode;
    }

    return (
      <Navigate to={successRedirectUrl}/>
    )
  }

  return null;
}

const CheckoutReturnPage: React.FC = () => {
  const {data: user} = useGetUserQuery();


  useEffect(() => {
    if (user) {
    }
  }, [user]);

  return (
    <Layout>

      {/*<div className={"-mt-12 md:-mt-16 bg-gradient-to-b from-orange-700 to-orange h-40 w-screen"}></div>*/}

      <div
        className="fixed w-screen h-screen top-12 pt-10 p-4 pb-16 items-center flex flex-col bg-gradient-to-b from-orange-700 to-orange gap-8">

        <div className={"relative -top-32 w-full max-w-4xl mx-auto flex flex-col gap-4 md:gap-8 pb-24"}>
          <InnerHeader pageTitle={`Settings`}><></>
          </InnerHeader>

          <div><CheckoutReturn/></div>
        </div>

      </div>

    </Layout>
  );
};

export {CheckoutReturnPage};