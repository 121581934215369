import React, {useCallback} from 'react';
import {classNames, isPostInError} from "../../utils/Helper";
import {CollectibleCard} from "../../dto/collectibles/collectibles-list-response.dto";
import {useNavigate} from "react-router-dom";
import RocketIcon from "../svg/RocketIcon";
import {ChainsIcon} from "../ui/chains-icon";
import {PlayIcon} from "../svg/PlayIcon";
import {GemsCostButton} from "../ui/secondary-gems-button.component";
import {useGetBetBoostQuery, useGetUpgradeCostQuery, useUpgradeCollectibleMutation} from "../../apis/collectibles.api";
import {CollectibleCardComponent, getStepImage, getStepName} from "./collectible-card.component";
import {LockedButton} from "../ui/secondary-locked-button.component";
import {LockIcon} from '../ui/lock-icon';
import {CoinIcon} from "../ui/coin-icon";
import {BoostIcon} from "../svg/BoostIcon";

interface BigCardItemProps {
  collectible: CollectibleCard,
  mode?: 'go_to_shop' | 'upgrade' | 'none' | 'pure'
}

export const triggerUpgradeCollectibleSuccess = (collectible: CollectibleCard, gemsCost: number = 0) => {
  gtag('event', 'upgradeCollectibleSuccess', {gemsCost, crypto: collectible.cryptoCurrencyId});
  const evt = new CustomEvent("upgradeCollectibleSuccess", {
    bubbles: true,
    detail: {data: () => collectible},
  });

  window?.dispatchEvent(evt);
}
const BigCardItem: React.FC<BigCardItemProps> = ({
                                                   collectible,
                                                   mode = 'go_to_shop'
                                                 }) => {

  const nav = useNavigate();
  const [upgradeCollectible] = useUpgradeCollectibleMutation();
  const {data: gemsCost} = useGetUpgradeCostQuery(collectible.cryptoCurrencyId, {skip: mode != 'upgrade'})
  const {data: betBoost} = useGetBetBoostQuery({
    cryptoId: collectible.cryptoCurrencyId,
    previewNextLevel: (collectible.unlocked === false || collectible.upgrade === true)
  });

  // useEffect(() => {
  //   if (collectible)
  //     toastMessage(`mode=${mode}, unlocked=${collectible.unlocked}, upgrade=${collectible.upgrade}, previewnext = ${(mode === 'go_to_shop' || mode === 'upgrade' || mode === 'pure') && (collectible.unlocked === false || collectible.upgrade === true)}`);
  // }, [mode, collectible])
  const onUpgrade = useCallback(async () => {
    const res = await upgradeCollectible(collectible.cryptoCurrencyId);
    if (!isPostInError(res)) {
      triggerUpgradeCollectibleSuccess(collectible, gemsCost);
    }
  }, [collectible]);

  return <div className={'grow flex flex-col justify-between pb-4'}>
    <div className={"flex-col flex gap-y-4 mb-4"}>
      <div key={collectible.name}
           className={classNames(` w-[200px] relative flat-card justify-between !p-2 !pt-3 flex flex-col gap-y-2 items-center overflow-hidden overflow-ellipsis text-sm gap-x-4`, collectible.unlocked === false && !collectible.upgrade ? '!opacity-70' : '')}>

        <CollectibleCardComponent collectible={collectible} previewNextStep={true} shineEffect={collectible.unlocked}/>

        <div
          className={"flex flex-col gap-1 text-xs border-t border-t-grey-100 w-full justify-center text-black py-2 text-xxxs text-opacity-20 striped"}>
          <div className={"flex w-full justify-between items-center px-2 !text-opacity-100"}>
            <span className={"flex items-center gap-x-1"}>
              <RocketIcon h={10}/>
              <span className={` ${collectible.unlocked ? "" : "text-opacity-20 italic"} `}>Bet boost</span>
            </span>
            <span>+{(betBoost || 0) * 100}%</span>
          </div>

          <div className={"flex w-full justify-between items-center px-2 !text-opacity-100"}>
            <span className={"flex items-center gap-x-1"}>
              <CoinIcon h={12}/>
              <span className={` ${collectible.unlocked ? "" : "text-opacity-5 italic"} `}>Bet limit</span>
            </span>
            {(collectible.unlocked && !collectible.upgrade) &&
              <span>{collectible.maxBetAmount}</span>
            }
            {(!collectible.unlocked || collectible.upgrade) &&
              <span>{collectible.nextLevelMaxBetAmount}</span>
            }
          </div>


          <div
            className={"flex w-full justify-between items-center text-inherit rounded px-2 text-grey-400 italic text-opacity-20"}>
            <span className={"flex items-center gap-x-1"}><ChainsIcon h={10}/><span className={"text-inherit"}>On Chain inscription</span></span><span>Level 10</span>
          </div>
          <div
            className={"flex w-full justify-between items-center text-inherit px-2 text-grey-400 italic text-opacity-50"}>
            <span className={"flex items-center gap-x-1"}><BoostIcon h={10}/><span>Invest boost</span></span><span>Level 30</span>
          </div>
          <div
            className={"flex w-full justify-between items-center text-inherit rounded px-2 text-grey-400 italic text-opacity-50"}>
          <span className={"flex items-center gap-x-1"}><PlayIcon
            h={10}/> <span>1 vs 1 Game</span></span><span>Level 50</span>
          </div>
        </div>
      </div>

      <div
        className={classNames("flex flex-col gap-y-2 w-full text-xs text-opacity-40", getStepImage(collectible) ? '' : 'hidden')}>

        <div className={"flex flex-row w-full justify-between items-center text-inherit"}>
          <div className={classNames('h-4 w-4 z-20 text-inherit')}>
            {!collectible.unlocked &&
              <LockIcon h={12}/>
            }
            {collectible.unlocked &&
              <img src={getStepImage(collectible) || `${process.env.REACT_APP_STATIC_ASSETS}/favicon.svg`}/>
            }
          </div>
          {!collectible.unlocked &&
            <div className={"text-inherit"}>Locked
              {/* <span className={"capitalize text-inherit ml-1"}>{getStepName(collectible)}</span> */}
            </div>
          }
          {collectible.unlocked &&
            <div className={"text-inherit"}>Rank
              <span className={"capitalize text-inherit ml-1"}>{getStepName(collectible)}</span>
            </div>
          }
        </div>

        <div
          className={"flex flex-row w-full justify-between items-center border-t border-t-grey-100 pt-1 text-inherit"}>
          <span>Trading XP</span>
          <span>{collectible.xp}</span>
        </div>
        <div
          className={"flex flex-row w-full justify-between items-center border-t border-t-grey-100 pt-1 text-inherit"}>
          <span>Next Level in</span>
          <span>{collectible.nextLevelXp - collectible.xp}</span>
        </div>
      </div>
    </div>


    {mode === 'go_to_shop' &&
      <button className={"primary"}
              onClick={() => {
                gtag('event', 'collectibleGoToShopButtonClicked', {crypto: collectible.cryptoCurrencyId});
                nav('/store', {
                  replace: true,
                  state: {upgradeCollectibleId: collectible.cryptoCurrencyId}
                })
              }}>
        Go to the shop
      </button>
    }

    {mode === 'upgrade' &&
      <GemsCostButton quantity={gemsCost || 100} label={'Buy'} onClick={onUpgrade}/>
    }

    {mode === 'none' &&
      <LockedButton
        lockMessage={`You need to unlock the collectible to buy it. Make some actions using ${collectible.code} and a surprise might arise soon.`}
        label={'Buy'}/>
    }
  </div>;
};

export {
  BigCardItem
}