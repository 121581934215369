import {CreateUserRequest} from "../dto/create-user-request.dto";
import {User} from "../models/User";
import {emptySplitApi} from "./empty.api";
import {ClaimIncomeDto, IncomeStateDto} from "../dto/game/game-income-request.dto";

export const gameIncomeApi = emptySplitApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    claimIncome: build.mutation<User, ClaimIncomeDto>({
      query: (claimIncomeDto) => ({
        url: '/income/claimIncome',
        method: 'POST',
        body: claimIncomeDto,
      }),
      invalidatesTags: ['UserMoney', 'UserAvailableIncome', 'UserLiveOverallPerformance'],
    }),
    getAvailableIncomes: build.query<IncomeStateDto, void>({
      query: () => ({
        url: "/income/getAvailable",
        method: "GET",
      }),
      providesTags: ['UserAvailableIncome'],
    }),
    getMoneyAmount: build.query<{ amount: number, inGameMoneyAmount: number }, void>({
      query: () => ({
        url: "/income/getMoneyAmount",
        method: "GET",
      }),
      providesTags: ['UserMoney'],
    }),
    getFreeMoneyToClaimAmount: build.query<{ amount: number }, void>({
      query: () => ({
        url: "/income/getFreeMoneyToClaimAmount",
        method: "GET",
      }),
      providesTags: ['UserFreeMoneyToClaim'],
    }),
    claimFreeMoneyAmount: build.mutation<number, { gemsBoost?:number } | void>({
      query: (body) => ({
        url: '/income/claimFreeMoneyAmount',
        method: 'POST',
        body
      }),
      invalidatesTags: ['User', 'UserMoney', 'UserFreeMoneyToClaim', 'UserLiveOverallPerformance'],
    }),
    spend: build.mutation<User, CreateUserRequest>({
      query: (createUserRequest) => ({
        url: '/income/spend',
        method: 'POST',
        body: createUserRequest,
      }),
      invalidatesTags: ['UserMoney'],
    }),
  }),
});

export const {
  useClaimIncomeMutation,
  useSpendMutation,
  useGetAvailableIncomesQuery,
  useGetMoneyAmountQuery,
  useGetFreeMoneyToClaimAmountQuery,
  useClaimFreeMoneyAmountMutation,
} = gameIncomeApi;