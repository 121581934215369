import React, {useState} from 'react'
import {classNames, cssHidden, responseOk} from "../../../utils/Helper";
import {useGenerateFiatPromoNotificationMutation} from "../../../apis/dev.api";
import {toastError, toastSuccess} from "../../../utils/ToastUtils";

const FiatProductPromoComponent: React.FC = () => {
  const [hide, setHide] = useState(false);

  const [post] = useGenerateFiatPromoNotificationMutation();
  const dbRef = React.useRef<HTMLInputElement>(null);
  const stripeRef = React.useRef<HTMLInputElement>(null);
  const depayRef = React.useRef<HTMLInputElement>(null);
  const codeRef = React.useRef<HTMLInputElement>(null);


  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (codeRef.current) {
      await post({productCode: codeRef.current.value}).then((data) => {
        if (responseOk(data)) {
          //hide button to avoid to click again because that would generate notifications twice for each players
          setHide(true);
          toastSuccess('Notifications are being generated ! ');
        }
      });
    }
  }

  return <div>
    <h2>Generate Fiat product promo story notification</h2>
    <div className={`bordered p-4`}>
      <div className={"flex flex-col gap-y-8"}>
        <div className={"flex justify-between"}>
          <label>DB configured w/ promo ? </label><input id="db_promo" type={"checkbox"} ref={dbRef}/>
        </div>
        <div className={"flex justify-between"}>
          <label>STRIPE configured w/ promo ? </label><input id="stripe_promo" type={"checkbox"} ref={stripeRef}/>
        </div>
        <div className={"flex justify-between"}>
          <label>DePay configured w/ promo ? </label><input id="depay_promo" type={"checkbox"} ref={depayRef}/>
        </div>

        <div className={"flex justify-between"}>
          <label>Product Code </label><input id="code_product" type={"text"} ref={codeRef}/>
        </div>

        <button className={classNames("primary", '!' + cssHidden(hide))} onClick={async (e) => {
          if (dbRef.current?.checked && stripeRef.current?.checked && depayRef.current?.checked && codeRef.current?.value) {
            await handleSubmit(e);
          } else toastError('Please read, check the boxes and enter a product code');
        }}>Validate & Generate Notifications
        </button>
      </div>
    </div>
  </div>
}

export {FiatProductPromoComponent};