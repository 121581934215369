import React from "react";


const ChartIcon: React.FC<{ h?: number }> = ({h = 17}) => <svg width={h} height={h} viewBox="0 0 16 16" fill="none"
                                                               xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_2068_8266)">
    <path
      d="M13.7776 0.888885H2.22201C1.48556 0.888885 0.888672 1.48577 0.888672 2.22222V13.7778C0.888672 14.5142 1.48556 15.1111 2.22201 15.1111H13.7776C14.514 15.1111 15.1109 14.5142 15.1109 13.7778V2.22222C15.1109 1.48577 14.514 0.888885 13.7776 0.888885Z"
      fill="#CCD6DD"/>
    <path
      d="M13.7786 0.444443H2.22309C1.24131 0.444443 0.445312 1.24044 0.445312 2.22222V13.7778C0.445312 14.7596 1.24131 15.5556 2.22309 15.5556H13.7786C14.7604 15.5556 15.5564 14.7596 15.5564 13.7778V2.22222C15.5564 1.24044 14.7604 0.444443 13.7786 0.444443ZM13.7786 1.33333C14.2689 1.33333 14.6675 1.732 14.6675 2.22222V4H12.0009V1.33333H13.7786ZM12.0009 8.44444H14.6675V11.1111H12.0009V8.44444ZM12.0009 7.55555V4.88889H14.6675V7.55555H12.0009ZM11.112 1.33333V4H8.44531V1.33333H11.112ZM8.44531 4.88889H11.112V7.55555H8.44531V4.88889ZM8.44531 8.44444H11.112V11.1111H8.44531V8.44444ZM7.55642 1.33333V4H4.88976V1.33333H7.55642ZM4.88976 4.88889H7.55642V7.55555H4.88976V4.88889ZM4.88976 8.44444H7.55642V11.1111H4.88976V8.44444ZM1.3342 2.22222C1.3342 1.732 1.73287 1.33333 2.22309 1.33333H4.00087V4H1.3342V2.22222ZM1.3342 4.88889H4.00087V7.55555H1.3342V4.88889ZM1.3342 8.44444H4.00087V11.1111H1.3342V8.44444ZM2.22309 14.6667C1.73287 14.6667 1.3342 14.268 1.3342 13.7778V12H4.00087V14.6667H2.22309ZM4.88976 14.6667V12H7.55642V14.6667H4.88976ZM8.44531 14.6667V12H11.112V14.6667H8.44531ZM13.7786 14.6667H12.0009V12H14.6675V13.7778C14.6675 14.268 14.2689 14.6667 13.7786 14.6667Z"
      fill="#E1E8ED"/>
    <path
      d="M2.22206 14.6667C2.07984 14.6667 1.9354 14.6329 1.80162 14.5604C1.36917 14.328 1.20784 13.7889 1.44029 13.3564L4.5514 7.57867C4.68429 7.33111 4.92695 7.16134 5.20473 7.12045C5.48295 7.07956 5.76384 7.17289 5.96251 7.37156L8.66295 10.072L12.991 1.80978C13.2185 1.37511 13.7558 1.20667 14.1905 1.43511C14.6256 1.66267 14.7932 2.2 14.5656 2.63511L9.67673 11.9684C9.54562 12.2191 9.30295 12.392 9.0234 12.4351C8.74473 12.4756 8.46073 12.3849 8.26073 12.1849L5.54962 9.47423L3.00562 14.1991C2.84517 14.4973 2.53851 14.6667 2.22206 14.6667Z"
      fill="#EA5C28"/>
  </g>
  <defs>
    <clipPath id="clip0_2068_8266">
      <rect width="16" height="16" fill="white"/>
    </clipPath>
  </defs>
</svg>;

export {ChartIcon};