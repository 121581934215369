import React, {useRef} from "react";
import {classNames} from "../../../utils/Helper";
import axios from "axios";
import toast from "react-hot-toast";
import {toastError, toastSuccess} from "../../../utils/ToastUtils";

export interface Prop {
  onUploadSuccess: Function,
  id: string,
  children?: JSX.Element,
  api: string,
  maxSize?: number, //MB
}

export const FileUploadComponent: React.FC<Prop> = ({
                                                      onUploadSuccess = () => {
                                                      },
                                                      id, children, api, maxSize = 2
                                                    }) => {

  const imgInputRef = useRef<HTMLInputElement>(null);
  const chooseFile = () => {
    imgInputRef.current?.click();
  }
  return <>
    <input ref={imgInputRef} className={classNames(children ? "hidden" : '')} id={id} type={"file"}
           accept="image/gif, image/jpeg, image/png, image/webp" onChange={async (e) => {
      const formData = new FormData();
      const imagefile = document.querySelector('#onboarding_profile_img');
      if (imagefile) {
        // @ts-ignore
        const file = imagefile.files[0];
        let fileSize = file.size; //size in b
        fileSize = fileSize / 1048576;//Mo
        if (fileSize > maxSize) {
          toastError(`File too large (Max ${maxSize}MB)`);
          // @ts-ignore
          imagefile.files = null;
          return;
        }
        formData.append("client_file", file);
        const l = setTimeout(() => toast.loading('Uploading...'), 1000);
        try {
          const res = await axios.post(api, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
          clearTimeout(l);

          onUploadSuccess(res);

        } catch (e) {
          clearTimeout(l);
          toastError('File cannot be uploaded. Try another one.')
        } finally {
          toastSuccess('Uploaded');
        }
      }
    }}/>

    <div className={"cursor-pointer relative w-full h-full"} onClick={() => chooseFile()}>
      {children}
    </div>
  </>
};