import React from "react";


const Podium3rdIcon: React.FC<{ h?: number }> = ({h = 24}) => 

<svg width="106" height="68" viewBox="0 0 106 68" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 0V8H106L94.322 0H0Z" fill="white" fillOpacity="0.8"/>
<path d="M106 60H0V68H106V60Z" fill="white"/>
<path d="M106 64H0V8H106V64Z" fill="white"/>
<path d="M52.0741 41.08H47.7541V36.92C47.8341 36.84 47.9141 36.8 47.9941 36.72L54.4341 31.08H43.2741V25.4H62.5541V30.56L56.3141 36.04C60.3141 37.24 63.5541 40.44 63.5541 45.48C63.5541 51.72 58.5141 55.52 52.5141 55.52C46.7541 55.52 41.9141 52 41.9141 45.32H48.3141C48.3141 48.24 50.0741 49.8 52.6741 49.8C55.1941 49.8 56.9141 48.08 56.9141 45.44C56.9141 43.08 55.5541 41.08 52.0741 41.08Z" fill="#EA5C28"/>
</svg>

;

export {Podium3rdIcon};