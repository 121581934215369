import React from "react";
import ContentLoader from "react-content-loader";

export interface Prop {

}

export const ListLoaderComponent: React.FC<Prop> = ({
                                                }) => {

  const rects: Array<JSX.Element> = [];
  for (let i = 0; i < 50; i++) {
    rects.push(<circle key={'c_' + i} cx="40" cy={30 + 40 + i * 90} r="40" opacity={10}/>);
    rects.push(<rect key={'r_' + i} x="100" y={30 + i * 90} rx="5" ry="5" className="w-[60%]" height="80"/>);
  }

  return <ContentLoader animate={true} backgroundColor={'#e8e8e4'} height={1000} opacity={10} backgroundOpacity={0.5} foregroundOpacity={0.5}
                        className={"mx-4 w-11/12 h-500 relative"}>
    {rects.map((o: any, idx: number) => {
      return o;
    })}
  </ContentLoader>
};

export const CollectibleLoaderComponent: React.FC<Prop> = ({
                                                    }) => {

  const rects: Array<JSX.Element> = [];
  for (let i = 0; i < 50; i++) {
    rects.push(<rect key={'r_' + i} x="0" y={50 + i * 220} rx="5" ry="5" className="w-[48%]" height="200"/>);
    rects.push(<rect key={'rr_' + i} x="150" y={50 + i * 220} rx="5" ry="5" className="w-[48%]" height="200"/>);
  }

  return <ContentLoader animate={true} backgroundColor={'#e8e8e4'} height={1000}
                        className={"mx-4 w-11/12 h-500 relative"}>
    {rects.map((o: any, idx: number) => {
      return o;
    })}
  </ContentLoader>
};