import React, {useEffect, useMemo} from 'react'
import {useLocation, useNavigate} from 'react-router-dom';
import {TanjiLogo} from "./seein-logo.component";
import LocationCityIcon from "@mui/icons-material/LocationCity";

const PublicNavBar: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const noHeader = useMemo<string>(() => new URLSearchParams(window.location.search).get('no_header') || '', []);

  return noHeader ? <></> : (
    <>
      {/*<div id="snav"*/}
      {/*     className={"fixed w-full h-20 top-0 z-20 !bg-black !bg-opacity-30 border-b-[1px] border-white border-opacity-20" + (noHeader === 'true' ? 'hidden' : '')}>*/}
      {/*  <div*/}
      {/*    className="flex h-full items-center align-middle justify-between">*/}

      {/*    <div className="flex w-full items-center justify-center">*/}
      {/*      <TanjiLogo/>*/}
      {/*    </div>*/}
      {/*    /!*{location.pathname === '/city' &&*!/*/}
      {/*    /!*  <div className={"flex items-center gap-x-4 justify-center"}>*!/*/}
      {/*    /!*    <button className={"primary"} onClick={() => {*!/*/}
      {/*    /!*      //next line allow automatic redirection to /home after sign up*!/*/}
      {/*    /!*      localStorage.setItem('sa_last_unauthorized_page', document.location.origin + '/home');*!/*/}
      {/*    /!*      navigate("/home")*!/*/}
      {/*    /!*    }}>Build my City<LocationCityIcon className={"ml-2"}/>*!/*/}
      {/*    /!*    </button>*!/*/}
      {/*    /!*  </div>*!/*/}
      {/*    /!*}*!/*/}
      {/*  </div>*/}


      {/*</div>*/}
    </>

  )
}

export {PublicNavBar};