import { useState } from "react";
import { useGetProductsQuery } from "../../apis/shop.api";
import Treasure from "./treasure.component";
import BuyTreasureDrawer from "./buy-treasure-drawer.component";

interface TreasuresProps { }

const Treasures: React.FC<TreasuresProps> = () => {

  const {data: treasures, isLoading: areTreasuresLoading} = useGetProductsQuery({family: 'treasures'});

  const [selectedTreasure, setSelectedTreasure] = useState<any | null>(null);

  return (

    <>
    <div className="w-full flex flex-wrap justify-center gap-x-2 gap-y-4">

      { treasures && !areTreasuresLoading && treasures.map(((treasure:any, index:number) => {
          return <Treasure
            key={index}
            treasure={treasure}
            onClick={()=>{
              setSelectedTreasure(treasure)
            }
          }/>
        }))
      }

    </div>

    { selectedTreasure &&
      <BuyTreasureDrawer
        treasure={selectedTreasure}
        open={!!selectedTreasure}
        onClose={() => setSelectedTreasure(null)}
      />
    }
  </>

   );
}

export default Treasures;