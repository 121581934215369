import React from 'react'
import {hexToRgbA} from "../../utils/Helper";
import {getDiscordRoleColor} from "../../models/DiscordHelper";
import {DiscordRoleInterface} from "../../models/User";

const DiscordRoleComponent: React.FC<{ discordRole?: DiscordRoleInterface }> = ({discordRole}) => {
  return <> {discordRole && <span className={"inline-flex gap-2 items-center rounded-lg px-4 py-1 border-[2px]"}
                                  style={{
                                    background: hexToRgbA(getDiscordRoleColor(discordRole), 0.1),
                                    borderColor: getDiscordRoleColor(discordRole)
                                  }}>
                  <span className="w-[6px] h-[6px] rounded-3xl shrink-0"
                        style={{background: getDiscordRoleColor(discordRole)}}></span>
          <p style={{color: getDiscordRoleColor(discordRole)}}
             className={"whitespace-nowrap font-bold"}>{discordRole?.name}</p>
        </span>}</>
}

export {DiscordRoleComponent};