import React from "react";
import {classNames} from "../../utils/Helper";
import {GemsIcon} from "./gems-icon";

type Props = {
  quantity: number; size?: number,
};

const QuantityGemsComponent: React.FC<Props> = ({quantity, size}) => {
  return (
    <div className={classNames("flex text-inherit items-center")}>
      <GemsIcon h={size}/>
      <span className={"select-none mx-1 text-inherit font-bold"}>{quantity}</span>
    </div>
  )
}

export {QuantityGemsComponent};

