import React, {useEffect, useState} from 'react';
import Chart from "react-apexcharts";
import {ApexOptions} from "apexcharts";

type ChartProp = {
  heightPx?: number;
  series: ApexAxisChartSeries,
  options?: ApexOptions,
  areSeriesLoading?: boolean,
};

const defaultChartOptions: ApexOptions = {
  stroke: {curve: 'smooth', width: 2},
  colors: ['#FFFFFF'],
  chart: {
    type: 'area',
    stacked: false,
    toolbar: {
      show: false,
    }
  },
  dataLabels: {
    enabled: false
  },
  markers: {
    size: 0,
  },
  fill: {
    type: 'gradient',
    gradient: {
      shadeIntensity: 1,
      inverseColors: false,
      opacityFrom: 0.5,
      opacityTo: 0.1,
      stops: [0, 90, 100]
    },
  },
  grid: {
    yaxis: {
      lines: {
        show: false,
      },
    },
    xaxis: {
      lines: {
        show: false,
      },
    }
  },
  yaxis: {
    axisBorder: {show: false},
    axisTicks: {show: false},
    labels: {
      show: false,
      formatter: function (val: number) {
        return (val / 1000000).toFixed(0);
      },
    },
  },
  xaxis: {
    labels: {show: false},
    axisBorder: {show: false},
    axisTicks: {show: false},
    type: 'datetime',
  },
  tooltip: {
    enabled: false,
  },
};

const noData = {

  text: 'Loading...',
  align: 'center',
  verticalAlign: 'middle',
  offsetX: 0,
  offsetY: 0,
  style: {
    color: "#ffffff",
    fontSize: '14px',
  }

}

const AreaChart: React.FC<ChartProp> = ({
                                          heightPx = 80,
                                          series,
                                          options = defaultChartOptions,
                                          areSeriesLoading = false
                                        }) => {
  const [opt, setOpt] = useState(options);
  useEffect(() => {
    noData.text = areSeriesLoading ? 'Loading...' : 'No data';
    // @ts-ignorex
    setOpt({...options, noData: {...noData}});
    console.log(`areSeriesLoading:${areSeriesLoading}`);
  }, [options, areSeriesLoading])
  return (
    <div className="app">
      <div className="row">
        <div className="mixed-chart">
          <Chart
            options={opt}
            series={series}
            type="area"
            width="100%"
            height={`${heightPx}px`}
          />
        </div>
      </div>
    </div>

  );
};

export {AreaChart};
