import React, {useEffect, useState} from 'react';
import TemporaryDrawer from "../ui/temp-drawer.component";
import {disableBodyScroll, enableBodyScroll} from "../../utils/HtmlHelper";
import {classNames} from "../../utils/Helper";
import {BigCardItem, triggerUpgradeCollectibleSuccess} from '../collectibles/big-card.component';
import {CollectibleCard} from '../../dto/collectibles/collectibles-list-response.dto';
import {GemsCostButton} from '../ui/secondary-gems-button.component';
import {useBuyProductWithGemsMutation} from '../../apis/shop.api';
import {useGetCollectibleQuery} from "../../apis/collectibles.api";

export interface DailyCollectibleItem {
  family: string,
  subFamily: string,
  label: string,
  code: string,
  displayedPrice: number,
  displayedDiscountPrice: number,
  quantity: number,
  collectibleCard: CollectibleCard
}

interface BuyDailyCollectibleDrawerProps {
  open: boolean,
  onClose: Function,
  collectible: DailyCollectibleItem,
  canBuy: boolean
}

const BuyDailyCollectibleDrawer: React.FC<BuyDailyCollectibleDrawerProps> = ({
                                                                               open,
                                                                               onClose = () => {
                                                                               },
                                                                               collectible,
                                                                               canBuy,
                                                                             }) => {

  const [buyProductWithGems] = useBuyProductWithGemsMutation();

  const [isBuying, setIsBuying] = useState(false);

  const collectiblePrice = collectible.displayedDiscountPrice ? collectible.displayedDiscountPrice : collectible.displayedPrice;

  useEffect(() => {
    return () => enableBodyScroll();
  }, []);

  const {data: card} = useGetCollectibleQuery(collectible.collectibleCard.cryptoCurrencyId);


  return (
    <>
      {/* { collectible && !userCollectibleLoading && */}
      {collectible &&
        <React.Fragment>
          <TemporaryDrawer
            title={collectible.label}
            closeWhenClickingOutside={true}
            anchor={'bottom'}
            open={open}
            lineIcon={false}
            onOpen={() => {
              disableBodyScroll()
            }}
            onClose={() => {
              enableBodyScroll()
              onClose();
            }}
            size="auto"
          >
            <div className={"w-full h-full flex flex-col justify-center items-center py-2 overflow-y-auto pb-9"}>

              {/*force upgrade to true to display card colors.*/}
              {card && <BigCardItem collectible={{...card, upgrade: canBuy}} mode={'pure'}/>}

              <div className='w-full max-w-80'>

                {canBuy && isBuying &&
                  <GemsCostButton
                    label={"Buying..."}
                    quantity={collectiblePrice}
                    css={classNames("disabled")}
                    onClick={() => {
                    }}
                  />
                }

                {canBuy && !isBuying &&

                  <GemsCostButton
                    label={"Buy"}
                    quantity={collectiblePrice}
                    css={classNames()}
                    onClick={async (e) => {

                      e.stopPropagation();
                      setIsBuying(true);

                      await buyProductWithGems({productCode: collectible.code}).then((data: any) => {

                        if (data.error) {
                          return;
                        }

                        gtag('event', 'buyDailyCollectible', {
                          productCode: collectible.code,
                          productPrice: collectiblePrice
                        });

                        triggerUpgradeCollectibleSuccess(collectible.collectibleCard, collectiblePrice);
                      })
                        .finally(() => {
                          setIsBuying(false);
                          onClose();
                        });

                    }}
                  />
                }

                {!canBuy &&
                  <div className='w-full center'>
                    <p className='infoMessage'>You already own this collectible</p>
                  </div>
                }

              </div>

            </div>
          </TemporaryDrawer>
        </React.Fragment>
      }
    </>
  );
}

export default BuyDailyCollectibleDrawer;