import React, {useEffect, useRef} from 'react'
import toast from "react-hot-toast";

export type Props = { triggerImmediatly?: boolean; }

const DiscordLoginComponent: React.FC<Props> = ({triggerImmediatly = false}) => {

  const btnRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (triggerImmediatly === true && btnRef.current) {
      btnRef.current.click();
    }
  }, []);

  return <button ref={btnRef} className={"w-full h-16 border-gray-300 bg-opacity-10 border border-white border-opacity-20 rounded-sm center"} onClick={() => {
    gtag('event', 'discordLoginStarted');

    if (parent !== window) {
      parent.window.postMessage('triggerDiscordLogin', process.env.REACT_APP_DOMAIN as string);
      return;
    }

    toast("Redirecting to Discord...", {
      icon: <img width={20} height={20} src={process.env.REACT_APP_STATIC_ASSETS + "/images/discord.webp"}/>
    })
    // @ts-ignore
    window.location.href = process.env.REACT_APP_DISCORD_LOGIN_URL;
  }}>
    <img width={25} height={25} src={process.env.REACT_APP_STATIC_ASSETS + "/images/discord.webp"}/>
    {/* <span className={"font-bold font-serif"}>Login with Discord</span> */}
  </button>;
}

export {DiscordLoginComponent};