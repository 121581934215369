import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

interface Survey {
    isSurvey: boolean;
}

const slice = createSlice({
    name: 'survey',
    initialState: { isSurvey: true } as Survey, 
    reducers: {
        setSurveyState: (state, { payload: { isSurvey } }: PayloadAction<Survey>) => {
            state.isSurvey = isSurvey; 
        }
    }
})

export const { setSurveyState } = slice.actions; 
export default slice.reducer;
export const selectCurrentSurvey = (state: RootState) => state.survey.isSurvey;  