import {useGetCyclePlayerQuery} from "../apis/game.api";

export function useCanSpy(targetPlayerId?: string): boolean {
  const {data: connectedPlayer} = useGetCyclePlayerQuery();
  const {data: targetPlayer} = useGetCyclePlayerQuery(targetPlayerId)
  return !targetPlayerId
    || (!!connectedPlayer && !!targetPlayer && connectedPlayer.userId === targetPlayer.userId)
    || (!!targetPlayer && !targetPlayer.forbidSpy && !!connectedPlayer?.spiedPlayers?.includes(targetPlayerId));
}

export function useIsSpyable(targetPlayerId?: string): boolean {
  const {data: targetPlayer} = useGetCyclePlayerQuery(targetPlayerId);
  const {data: connectedPlayer} = useGetCyclePlayerQuery();
  return !targetPlayer || !targetPlayer.forbidSpy || (!!connectedPlayer && connectedPlayer.userId === targetPlayer.userId);
}
