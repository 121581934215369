import React from "react";
import {classNames, formatPercent} from "../../utils/Helper";
import {UpGreenIcon} from "./up-green-icon";
import {DownRedIcon} from "./down-red-icon";

type Props = {
  perf: number | string;
  noColor?: boolean;
  formatFunction?: Function,
  mathSymbolMode?: boolean,
}


const PerfComponent: React.FC<Props> = ({perf, noColor = false, formatFunction, mathSymbolMode = false}) => {

  return (
    <div
      className={classNames("flex items-center justify-center", (noColor ? 'text-inherit' : (perf >= 0 ? "text-green-600" : "text-red-500")))}>
      {(!mathSymbolMode && perf >= 0) &&
        <UpGreenIcon h={10}/>
      }
      {(!mathSymbolMode && perf) < 0 &&
        <DownRedIcon/>
      }
      {(mathSymbolMode && perf > 0) &&
        <span>+</span>
      }
      <span
        className={"ml-0.5 font-bold"}>{(formatFunction ? formatFunction(perf) : formatPercent(mathSymbolMode ? perf : Math.abs(Number(perf))))}</span>
    </div>
  )
}

export {PerfComponent};

