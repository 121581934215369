import {useEffect} from 'react';

import {usersApi, useGetReferredFriendsQuery} from "../../apis/users.api";
import { gameApi, useGetFriendsCumulatedGemsAmountQuery } from "../../apis/game.api";
import { ReferredFriendCard } from "./profile-referred-friend-card.component";
import ReferralCountInfo from "../ui/referred-count-info.component";
import LoudlyCryingFaceIcon from "../svg/LoudlyCryingFaceIcon";


export const ReferredFriendsList: React.FC = () => {


  // @ts-ignore
  const {data: friendsData, isLoading} = useGetReferredFriendsQuery();
  const {data: friendsCumulatedGems} = useGetFriendsCumulatedGemsAmountQuery();

  const refetchCollectedGems = gameApi.endpoints.getFriendsCumulatedGemsAmount.useQuerySubscription();
  const refetchFriends = usersApi.endpoints.getReferredFriends.useQuerySubscription();

  useEffect(() => {
    refetchCollectedGems.refetch();
    refetchFriends.refetch();
  }, []);

  return (
    <>

      {!isLoading && friendsData && friendsCumulatedGems && friendsData.length > 0 &&

        <>

          <div className="mt-12 mb-2 pr-2 float-right">
            <p className="text-white text-xs">{Object.values(friendsCumulatedGems).filter((gems)=>{return gems>=5}).length}/{friendsData.length} Confirmed</p>
          </div>

          <div className={"w-full flex gap-y-3 flex-col justify-between mt-3"}>
            { friendsData.map((friend, index) => {

              return <ReferredFriendCard key={index}
                  pseudo = {friend.pseudo}
                  cumulatedGems = { friendsCumulatedGems[friend._id] || 0 }
                  picture = {friend.picture}
                />
            })
            }
          </div>

        </>
      }

      {
        !isLoading && friendsData && friendsData.length == 0 &&
        <div className="w-full h-[50vh] flex justify-center">

          <div className={"flex-col flex gap-3 justify-center items-center"}>

            <div>
              <LoudlyCryingFaceIcon/>
            </div>

            <div
              className={"rounded-lg bg-black bg-opacity-20 text-white font-bold px-4 py-2 mt-2 text-lg text-center"}>
              {'Where are they?'}
            </div>
            <div className={"text-white text-center font-medium opacity-80"}>
              {'No friend invited yet...'}
            </div>
          </div>

        </div>
      }

    </>
  );

}