import React, {useEffect, useState} from 'react'
import {useGetSquadAnalyticsQuery, useGetSquadPerfPercentageQuery} from "../../apis/squad.api";
import {Tournament} from "../../models/Tournament";
import {classNames, formatBalanceNumber, formatGainPercent, getDaysLeftLabel, hasElements} from "../../utils/Helper";
import {useGetCryptosFromIdsQuery} from "../../apis/cryptos.api";
import {CryptoTrade} from "../../dto/squad/squad.dto";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {useLocation, useNavigate} from "react-router-dom";
import {CryptoCurrency} from "../../models/Wallet";
import {QuantityCoinComponent} from "../ui/quantity-coin.component";
import {useCycle} from "../../hooks/useCycle";
import {useTournamentRemainingDays} from "../../hooks/useTournamentRemainingDays";
import * as _ from "lodash";
import TemporaryDrawer from "../ui/temp-drawer.component";
import {CryptoFilterSelectionElement} from "../crypto-filtered-selection-element.component";
import {SecondarySearchButton} from "../ui/secondary-search-button.component";
import {useUrlParameter} from "../../utils/useUrlParameter";
import {disableBodyScroll, enableBodyScroll} from "../../utils/HtmlHelper";
import {PerfComponent} from "../ui/perf.component";
import {useGetPlayerQuery} from "../../apis/users.api";
import {useCanSpy} from "../../hooks/useCanSpy";

interface AggrTrades {
  cryptoSourceId: string;
  logo: string;
  label: string;
  amount: number;
  cryptoAmount: number;
  closed: boolean;
}

function aggregateTrades(trades: CryptoTrade[], cryptos: CryptoCurrency[]): Array<AggrTrades> {
  const aggregates: Array<AggrTrades> = [];
  for (let i = 0; i < trades.length; i++) {
    const c = cryptos.find(c => c._id === trades[i].cryptoSourceId);
    let aggr = aggregates.find(t => t.cryptoSourceId === trades[i].cryptoSourceId);
    if (!aggr && c) {
      aggr = {
        cryptoSourceId: trades[i].cryptoSourceId,
        logo: c.logo,
        label: c.name,
        amount: 0,
        cryptoAmount: 0,
        closed: true
      };
      aggregates.push(aggr);
    }

    if (aggr && trades[i].open) {
      aggr.amount += trades[i].amountCrypto * trades[i].price;
      aggr.cryptoAmount += trades[i].amountCrypto;
      aggr.closed = false;
    }
  }
  return aggregates;
}


const SquadAggrPositions: React.FC<{
  tournament: Tournament,
  pseudo?: string,
  cryptoId?: string,
  displayHeader?: boolean,
  showMarketPriceButton?: boolean
}> = ({
        tournament,
        pseudo,
        cryptoId, displayHeader = true, showMarketPriceButton = false
      }) => {


  const navigate = useNavigate();
  const location = useLocation();

  const searchMarket = useUrlParameter('searchMarket');
  const [trades, setTrades] = useState<CryptoTrade[]>([]);
  const {
    data: cryptos,
    isLoading: areCryptosLoading
  } = useGetCryptosFromIdsQuery({ids: trades.map(t => t.cryptoSourceId)});

  const {data: scorePayload, isLoading: areAnalyticsLoading} = useGetSquadAnalyticsQuery({
    tournamentId: tournament.id,
    pseudo
  });

  useEffect(() => {
    if (scorePayload && scorePayload.positions) {
      setTrades(scorePayload.positions.filter(p => cryptoId ? p.cryptoSourceId === cryptoId : true));
    }
  }, [tournament, scorePayload]);

  useEffect(() => {
    if (location.state) {
      setOpenMarket(location.state.openMarketState ? true : false);
    }
  }, []);

  const [openMarket, setOpenMarket] = useState(searchMarket === 'true');
  const cycle = useCycle();
  const remainingDays = useTournamentRemainingDays(tournament, 7);
  const nav = useNavigate();
  const {
    data: targetPlayer,
    isLoading: targetPlayerLoading
  } = useGetPlayerQuery({pseudo: pseudo}, {skip: !pseudo || pseudo == ''});
  const canSpy = useCanSpy(targetPlayer?.userId);

  return (
    <div className={classNames("flex w-full flex-col text-sm md:text-base gap-4")}>
      <div className={classNames("w-full px-4 justify-center items-center inline-flex", displayHeader ? '' : 'hidden')}>
        <div
          className="grow shrink basis-0 text-black text-opacity-30 text-xs font-bold font-['Satoshi']">{`Cycle ${cycle}`}</div>
        <div
          className="p-0.5 bg-gray-200 rounded-md border border-black border-opacity-5 justify-start items-start gap-1 flex">
          <div
            className="px-2 py-1 bg-white rounded border border-black border-opacity-10 justify-center items-center gap-1 flex">
            <div
              className="text-black text-sm font-bold font-['Satoshi'] leading-[17px]">{cryptoId ? 'Holdings' : 'Wallet'}</div>
          </div>
        </div>
        <div className="grow shrink basis-0 justify-end items-center gap-1 flex">
          <div
            className="text-black text-opacity-30 text-xs font-bold font-['Satoshi']">{`${getDaysLeftLabel(remainingDays)}`}</div>
        </div>
      </div>

      {!areCryptosLoading && !targetPlayerLoading && !canSpy &&
        <div className={"m-2 flex flex-col items-center"}>
          <div>{`${pseudo} does not share his Squad or you did not choose the right trading action to see it.`}</div>
          <VisibilityOffIcon fontSize={"large"}></VisibilityOffIcon>
        </div>
      }

      {!areCryptosLoading && cryptos && canSpy &&
        <div className="flex flex-col gap-2 w-full">

          {(!trades || !hasElements(trades)) && !areAnalyticsLoading && !pseudo &&
            <div className={"font-bold text-center w-full"}>{`Make a trade to participate`}</div>
          }

          {aggregateTrades(trades, cryptos).map((t: AggrTrades, idx) => {
            const currentCrypto: CryptoCurrency | undefined = cryptos.find(c => c._id === t.cryptoSourceId);

            return currentCrypto ?
              <SquadAggrPositionsItem key={t.cryptoSourceId} t={t} currentCrypto={currentCrypto} pseudo={pseudo}
                                      tournament={tournament}/> : <></>;
          })}

          {showMarketPriceButton &&
            <TemporaryDrawer anchor={'bottom'}
                             className={'grow shrink basis-0 w-full flex my-2'}
                             buttonClass={"w-full "}
                             title={'Market prices'}
                             element={<SecondarySearchButton label={"Market prices"} size={16}/>}
                             open={openMarket}
                             onOpen={() => {
                               disableBodyScroll();
                               navigate(location.pathname, {
                                 state: {...location.state, openMarketState: true},
                                 replace: true
                               });
                               setOpenMarket(true);
                             }}
                             onClose={() => {
                               enableBodyScroll();
                               navigate(location.pathname, {
                                 state: {
                                   ...location.state,
                                   openMarketState: false,
                                   filterValue: ''
                                 }, replace: true
                               });
                               setOpenMarket(false)
                             }}>
              <div className={"w-full h-full overflow-y-auto"}>
                <CryptoFilterSelectionElement
                  selectedCryptos={[]}
                  onSelect={(crypto: CryptoCurrency) => {
                    enableBodyScroll();
                    nav(`/portfolio/${crypto._id}`);
                  }}
                  onDeselect={(crypto: CryptoCurrency) => {

                  }}
                  excludedCryptoIds={['tanji']}
                />
              </div>

            </TemporaryDrawer>
          }
        </div>
      }
    </div>
  );
}


const SquadAggrPositionsItem: React.FC<{
  currentCrypto: CryptoCurrency,
  pseudo?: string,
  tournament: Tournament,
  t: AggrTrades
}> = ({
        currentCrypto,
        pseudo,
        t,
        tournament
      }) => {

  const navigate = useNavigate();
  const {data: portfolioPerf, isLoading: isPerformanceLoading} =
    useGetSquadPerfPercentageQuery({
      tournamentId: tournament ? tournament.id : '', cryptoId: currentCrypto?._id, range: 'ALL', pseudo: pseudo
    }, {skip: !currentCrypto, refetchOnFocus: true});

  return <div onClick={() => {
    if (tournament.status === 2) navigate(`/portfolio/${t.cryptoSourceId}`)
  }}
              className={classNames("cursor-pointer p-3 bg-white rounded-lg fat-grey-shadow-2 border border-neutral-200 items-center gap-4 inline-flex")}>
    {/*========================================CRYPTO========================================*/}
    <div
      className={"flex md:text-base justify-start items-center gap-x-2 text-xs"}>
      <div
        className="w-8 h-8 p-0.5 bg-white rounded-full fat-grey-shadow-1 border border-grey-100 center flex">
        <img className="w-7 h-7 rounded-full" src={currentCrypto?.logo}/>
      </div>

      <div className="text-black text-sm font-bold font-['Satoshi']">
        <span>{currentCrypto?.code}</span>
      </div>
    </div>

    {/*========================================PERF & ALLOCATION========================================*/}
    <div className="grow flex flex-row justify-end gap-2">
      <div
        className={"grow flex justify-end items-end flex-col font-bold "}>
        <div className="w-20 items-center justify-end gap-1 inline-flex">
          <QuantityCoinComponent quantity={t.amount}/>
        </div>
        <div className={"text-black"}>{formatBalanceNumber(t.cryptoAmount)}<span
          className={"text-gray-400 ml-1"}>{currentCrypto?.code}</span></div>
      </div>

      <div
        className={classNames("flex flex-col min-w-[60px] items-end font-bold justify-center")}>
        <div
          className={classNames("flex justify-end font-bold items-center", !portfolioPerf || !_.isNumber(portfolioPerf?.performance) || portfolioPerf?.performance >= 0 ? 'text-green-500' : 'text-red-500')}>
          <PerfComponent
            perf={(portfolioPerf?.performance && _.isNumber(portfolioPerf?.performance)) ? portfolioPerf?.performance : 0}/>
        </div>
      </div>
    </div>
  </div>
};


export {SquadAggrPositions};