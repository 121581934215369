import {CoinIcon} from "../ui/coin-icon";
import {GemsIcon} from "../ui/gems-icon";

import {useClaimReferralRewardMutation} from "../../apis/users.api";
import {useClaimReferralRewardWithOptimismMutation} from "../../apis/users.api";
import {forwardRef, useRef} from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

import {animate} from "../../slices/gemSlice";
import useGemsCounterAnimation from "../../hooks/useGemsCounterAnimation";
import { RootState } from "../../app/store";

export enum RewardType {
  "coins",
  "gems"
}

interface ReferralRewardProps {
  step: number,
  type: RewardType,
  rewardAmount: number,
  goal: number,
  total: number,
  totalRewarded: number,
  claimableStep: number
}

const ReferralReward = forwardRef<any, ReferralRewardProps>(
  ({step, type, rewardAmount, goal, total, totalRewarded, claimableStep}, ref) => {

    const [claimCoinReward] = useClaimReferralRewardMutation();
  const [claimReward] = useClaimReferralRewardWithOptimismMutation();
  const dispatch = useAppDispatch();
  const spawnElementRef = useRef<HTMLDivElement>(null);
  const hasClaimed = useRef(false);
  const { incrementCounter } = useGemsCounterAnimation();


  const animationIsPlaying = useAppSelector((state: RootState) => state.gems.isPlaying);

  async function onUserClaim(type: RewardType, amount: number) {

    console.log("type: ", type, "amount: ", amount);

    if(animationIsPlaying || hasClaimed.current){
      return;
    }

    hasClaimed.current = true;

    if( type === RewardType.coins){
      return claimCoinReward({amount: amount});
    }

    const animParams = {
      spawnPoint: {
        x: 0,
        y: 0
      }
    };

    if(spawnElementRef.current){
      const domRect = spawnElementRef.current.getBoundingClientRect();
      animParams.spawnPoint.x = domRect.x + domRect.width / 2 ;
      animParams.spawnPoint.y = domRect.y - domRect.height;
    }

    dispatch(animate(animParams));
    incrementCounter(amount, ()=>{claimReward({amount: amount})});

  }

  return (
    <>

      {/* Breadcrumb */}
      <div className={`${step == 0 ? 'hidden' : 'show'}`}>
        <div className={`h-8 w-8 border-r  ${totalRewarded >= goal ? 'border-r-orange' : 'border-r-grey-100'}`}>
        </div>
      </div>

      {/* Reward */}
      <div ref={ref}
        className={`min-h-[68px] flat-card flex gap-2 ${totalRewarded >= goal ? '!border-orange border-2 shadow-none' : ''}`}>
        <div className="flex-none content-center">
          {
            type == RewardType.coins ? <CoinIcon h={32}/> : <GemsIcon h={32}/>
          }
        </div>
        <div className="flex-auto content-center">
          <p
            className="font-bold text-xs">{rewardAmount} {type == RewardType.coins ? 'Tanjicoins' : 'Gems'}</p>
          <p className="font-normal text-xs text-opacity-60"> {step == 5 ? `You referred ${goal} new ${ goal > 1 ? 'friends': 'friend' }` : `Invite ${goal} friends to unlock` } </p>
        </div>
        <div className="flex-none content-center">

          {/* Not achived */}
          {total < goal &&

            <div
              className={"bg-orange p-2 rounded-md text-white text-sm font-bold border border-opacity-10 border-black"}>
              {total} / {goal}
            </div>
          }

          {/* Achived but not claimed */}
          {total >= goal && totalRewarded < goal &&

            <>
              {/* Next one to claim */}
              <div onClick={()=>{onUserClaim(type, rewardAmount)}} ref={spawnElementRef}
                className={`orange-button text-white text-xs font-bold ${claimableStep == step ? "" : "hidden"}`}>
                { hasClaimed.current ? "Claiming" : "Claim" }
              </div>

              {/* Not next one to claim */}
              <div
                className={`bg-orange p-2 rounded-md text-white text-sm font-bold border border-opacity-10 border-black ${claimableStep == step ? "hidden" : ""}`}>
                {Math.min(total, goal)} / {goal}
              </div>
            </>

          }

          {/* Achived and claimed */}
          {totalRewarded >= goal &&
            <div className="border border-orange text-orange p-2 rounded-md text-xs font-bold">
              Claimed
            </div>
          }

        </div>
      </div>
    </>
  );
});

export default ReferralReward;