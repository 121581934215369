import React, {useEffect, useState, useMemo} from "react";
import Layout from "../../../Layout";
import "../style.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CircularProgress from "@mui/material/CircularProgress";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import {Navigate, useParams, Link} from "react-router-dom";
import {useGetAchievementsOtherQuery} from "../../../apis/achievements.api";
import {achievementsTranslations} from "../../../app/translations";
import {getAllAchievementTypeOtherDto} from "../../../dto/achievement/get-all-achievement-other-type.dto";
import AchievementIcon from "../../../components/svg/AchievementIcon";


const AchievementsOtherPage: React.FC = () => {
  const {username} = useParams<{ username: string }>();
  const cityUid = useMemo<string>(() => new URLSearchParams(window.location.search).get('cityshareuid') || '', []);

  if (!username) {
    window.history.back();
    return <Navigate to="/achievements"/>;
  }


  const {data: achievementsData, isError} = useGetAchievementsOtherQuery(username);

  const [isValidSkill, setIsValidSkill] = useState<boolean>(false);
  const [typeAchievements, setTypeAchievements] = useState<getAllAchievementTypeOtherDto | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (achievementsData) {
      setIsValidSkill(true);
      setTypeAchievements(achievementsData);
      setLoading(false);
    }
  }, [achievementsData]);


  if (isError) {
    window.history.back();
    return <Navigate to="/"/>;
  }

  if (!isValidSkill && !loading) {
    window.history.back();
    return <Navigate to="/"/>;
  }

  const goBack = async () => {
    window.location.href = `/city?cityshareuid=${cityUid}`;
  }


  return (
    <Layout>
      <div className={"flex flex-col gap-x-4 gap-y-2 lg:gap-y-4 w-full max-w-3xl mx-auto"}>
        {/* Header Profil user */}
        <div className="HeaderProfil self-center">
          {typeAchievements && typeAchievements.imageAvatar &&
            <img src={typeAchievements.imageAvatar} className={"lg:w-40 lg:h-40 w-20 h-20 rounded-full"} alt=""/>}
          {!(typeAchievements && typeAchievements.imageAvatar) && (
            <AccountCircleIcon
              style={{width: "100px", height: "100px"}}
              fontSize={"inherit"}
            />
          )}

          {typeAchievements &&
            <p> {cityUid && <KeyboardArrowLeftIcon onClick={() => goBack()}/>} @{typeAchievements.username}</p>}
        </div>
        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress color="primary"/>
          </div>
        )}

        {(!loading && typeAchievements) && (
          <div className={"w-full grid grid-cols-2 md:grid-cols-4 gap-4"}>
            {typeAchievements.listAchievementType.map((achievement) => {
              const ratio = achievement.scoopUser / achievement.scoopMax;
              const width = `${ratio * 100}%`;

              const translatedName = achievementsTranslations.EN.ACHIEVEMENTS.hasOwnProperty(achievement.name)
                ? achievementsTranslations.EN.ACHIEVEMENTS[achievement.name as keyof typeof achievementsTranslations.EN.ACHIEVEMENTS]
                : achievement.name;


              return (
                <Link
                  key={achievement.id}
                  to={
                    cityUid
                      ? `/${typeAchievements.username}/achievements/${achievement.name}?cityshareuid=${cityUid}`
                      : `/${typeAchievements.username}/achievements/${achievement.name}`
                  } replace>
                  <div
                    className="flex flex-col glass-dark h-min md:h-48 rounded-2xl SectionAchievement md:justify-between items-center overflow-hidden">
                    {/* <span /> */}
                    <div>
                      <div className="flex flex-row gap-1 text-white text-xs font-bold items-center mt-4">
                        <div className=""><AchievementIcon/></div>
                        <div>{achievement.scoopUser}</div>
                        /{" "}
                        {achievement.scoopMax}
                      </div>
                    </div>

                    <div className="flex flex-col h-[88px] mt-4">
                      <p
                        className="text-white text-center text-sm md:text-base font-medium font-serif px-3">{translatedName}</p>
                    </div>


                    {/* <span /> */}
                  </div>
                </Link>
              );
            })}
          </div>
        )}
      </div>
    </Layout>
  );
};

export {AchievementsOtherPage};
