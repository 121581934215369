import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Layout from "../../Layout";
import { RoiRankingList } from "../../components/squad/week-roi-ranking.component";
import { Link } from "react-router-dom";
import { SecondaryNavigation } from "../../components/ui/secondary-navigation.component";

const SquadWeeklyROIPage: React.FC = () => {
  const { week } = useParams<{ week?: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    if (!week) {
      navigate('/squad-roi-weekly/current');
    }
  }, [week, navigate]);

  return (
    <Layout>
      <div className={"flex flex-col gap-8 w-full center mx-auto max-w-5xl md:px-8 pb-20"}>
        <div className="flex flex-col w-full justify-start gap-4">
          <SecondaryNavigation navItems={[
            { path: '/leaderboard', name: 'XP Leaderboard' },
            { path: '/squad-roi-weekly', name: 'ROI Leaderboard' },
          ]} />
  
          <div className="flex w-full max-w-xl flex-col flex-start items-start gap-4 px-2 md:px-4">
            <p>In our trading competitions, you can distribute 10.000$ fictive value in different cryptocurrencies. You can choose from 2 to 5 cryptos to participate in this tournament. The person with the highest ROI wins.</p>
    
            <div className="flex flex-row gap-4">
              <Link to={"/tournament"}><button className="primary">Participate</button></Link>
              <Link to={week === 'current' ? "/squad-roi-weekly/previous" : "/squad-roi-weekly/current"}>
                <button className={week === 'current' ? "secondary" : "secondary"}>
                  {week === 'current' ? 'Last Week' : 'Current Week'}
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className={"w-full"}>
          <div className="flex w-full rounded-xl overflow-hidden glass-dark"><RoiRankingList week={week}></RoiRankingList></div>
        </div>
      </div>
    </Layout>
  )
    ;
};

export { SquadWeeklyROIPage }