import React, {useEffect, useState} from 'react'
import {Link, useLocation,} from 'react-router-dom'
import {useIsAuth} from "../../hooks/useIsAuth"
import {BetsIcon} from "../svg/bets-icon";
import {RankIcon} from "../svg/rank-icon";
import {InvestIcon} from "../svg/invest-icon";
import {ShopIcon} from "./shop-icon";
import {useGetUserQuery} from "../../apis/users.api";
import {hasElements} from "../../utils/Helper";
import {RedDotIcon} from "./red-dot-icon";
import {ProfileIcon} from "../svg/profile-icon";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const FootBar: React.FC = () => {
  const {
    isAuth,
    isAuthLoading,
  } = useIsAuth()

  const location = useLocation()

  const [isInvest, setInvest] = useState<boolean>(false);
  const [isBet, setBet] = useState<boolean>(false);
  const [isRank, setRank] = useState<boolean>(false);
  const [isProfile, setProfile] = useState<boolean>(false);
  const [isStore, setStore] = useState<boolean>(false);
  const [isCollectibles, setCollectibles] = useState<boolean>(false);
  const {data: user, refetch} = useGetUserQuery();
  const [shopDot, setShopDot] = useState(false);
  const [profileDot, setProfileDot] = useState(false);

  useEffect(() => {

    if (user && user.notifications && hasElements(user.notifications)) {
      setShopDot(!!user.notifications.find(n => n.tag === 'collectible_upgrade_available' || n.tag === 'gems_simple_discount'))
    }

    if (user && user.notifications && hasElements(user.notifications)) {
      setProfileDot(!!user.notifications.find(n => n.tag === 'referral_success_available'))
    }

  }, [user]);

  useEffect(() => {
    setInvest(location.pathname.startsWith('/portfolio') || location.pathname === '/home' || location.pathname === '/tournament-ranking');
    setBet(location.pathname.startsWith('/tournament/'));
    setRank(location.pathname.startsWith('/leaderboard'));
    setStore(location.pathname.startsWith('/store'));
    setCollectibles(location.pathname.startsWith('/collectibles'));
    setProfile(location.pathname.startsWith('/profile') || location.pathname.startsWith('/achievements') || location.pathname.startsWith('/stats'));
  }, [location]);

  return (
    <>
      {(!isAuthLoading && isAuth) &&
        <div
          className={"bg-white fixed w-full py-1 bottom-0 z-50 border-t-[1px] border-solid border-grey-300 center"}>
          <div
            className={"h-full w-full max-w-md flex justify-between items-center flex-row mx-4 p-1 rounded-full hover:cursor"}>
            <div
              className={classNames(
                "h-full w-full min-w-50 grow center flex items-center font-bold px-4 py-1 rounded-full overflow-hidden uppercase border border-transparent")}>
              <Link to={"/portfolio"} className="flex flex-col center gap-1">
                <div
                  className={classNames("h-5 w-5", isInvest ? '' : 'hidden')}>
                  <InvestIcon/></div>
                <div
                  className={classNames("h-5 w-5", isInvest ? 'hidden' : '')}>
                  <InvestIcon color={"gray"}/></div>
                <p
                  className={classNames("font-bold text-xs text-inherit", isInvest ? '' : '!text-gray-400')}>Invest</p>
              </Link>
            </div>
            <div
              className={classNames(
                "h-full w-full min-w-50 grow center flex items-center font-bold px-4 py-1 rounded-full overflow-hidden uppercase border border-transparent")}>
              <Link to={"/tournament/current"} className="flex flex-col center gap-1">
                <div
                  className={classNames("h-5 w-5", isBet ? '' : 'hidden')}>
                  <BetsIcon/></div>
                <div
                  className={classNames("h-5 w-5", isBet ? 'hidden' : '')}>
                  <BetsIcon color={"gray"}/></div>
                <p
                  className={classNames("font-bold text-xs text-inherit", isBet ? '' : '!text-gray-400')}>Bet</p>
              </Link>
            </div>

            <div
              className={classNames(
                "h-full w-full min-w-50 flex center font-bold px-4 py-1 rounded-full overflow-hidden uppercase border border-transparent")}>
              <Link to={"/leaderboard/current"} className="flex flex-col center gap-1">
                <div className={classNames("h-5 w-5", isRank ? '' : 'hidden')}>
                  <RankIcon/></div>
                <div className={classNames("h-5 w-5", isRank ? 'hidden' : '')}>
                  <RankIcon color={"gray"}/></div>
                <p
                  className={classNames("font-bold text-xs text-inherit", isRank ? '' : '!text-gray-400')}>Rank</p>
              </Link>
            </div>

            <div onClick={() => setProfileDot(false)}
              className={classNames(
              "h-full w-full min-w-50 grow center flex items-center px-4 py-1 rounded-full overflow-hidden uppercase border border-transparent")}>
              <Link to={"/collectibles"} className="relative flex flex-col center gap-1">
                <div
                  className={classNames("h-5 w-5", isCollectibles || isProfile ? '' : 'hidden')}>
                  <ProfileIcon/>
                </div>

                <div
                  className={classNames("h-5 w-5 relative", isCollectibles || isProfile ? 'hidden' : '')}>
                  <ProfileIcon color={"gray"}/>
                  <div className={classNames("absolute right-0 -top-1", profileDot ? '' : 'hidden')}><RedDotIcon/></div>
                  </div>
                <p
                  className={classNames("font-bold text-xs text-inherit", isCollectibles || isProfile ? '' : '!text-gray-400')}>Profile</p>
              </Link>
            </div>

            <div onClick={() => setShopDot(false)}
                 className={classNames(
                   "h-full w-full min-w-50 grow center flex items-center px-4 py-1 rounded-full overflow-hidden uppercase border border-transparent")}>
              <Link to={"/store"} className="relative flex flex-col center gap-1">
                <div
                  className={classNames("h-5 w-5", isStore ? '' : 'hidden')}>
                  <ShopIcon/></div>
                <div
                  className={classNames("h-5 w-5", isStore ? 'hidden' : '')}>
                  <ShopIcon color={"gray"}/></div>
                <p
                  className={classNames("font-bold text-xs text-inherit", isStore ? '' : '!text-gray-400')}>Shop</p>

                <div className={classNames("absolute right-1 -top-1", shopDot ? '' : 'hidden')}><RedDotIcon/></div>
              </Link>
            </div>


          </div>
        </div>
      }
    </>

  )
}

export {FootBar}
