import React, {useEffect, useState} from 'react';
import Layout from "../Layout";
import {useNavigate} from "react-router-dom";
import {SecondaryNavigation} from '../components/ui/secondary-navigation.component';
import {formatRank} from '../utils/Helper';
import {usePlayerFromPseudoParameter} from '../hooks/usePseudoParam';
import {BackButton} from '../components/ui/back-button';
import {ReferredFriendsList} from '../components/profile/profile-referred-friends-list.component';
import {SharingCardComponent} from '../components/social/sharing-card.component';

import {usersApi} from "../apis/users.api";
import ReferralCountInfo from '../components/ui/referred-count-info.component';

export const ReferralFriendsPage: React.FC = () => {

  const {player} = usePlayerFromPseudoParameter();
  const [sharingText, setSharingText] = useState('');

  const refetchFriends = usersApi.endpoints.getReferredFriends.useQuerySubscription();

  useEffect(() => {
    if (player){

      const previousRank = player.previousWeekRank ? player.previousWeekRank : 0;

      setSharingText(`I just finished ${formatRank(player.previousWeekRank)} rank on @tanjiblocks. You think you can beat me? Try out now and earn 2000 Coins for free to start:`);

      if(previousRank == 0){
        setSharingText(`Try out @tanjiblocks now and earn 2000 for free Coins to start:`);
      }

    }
  }, [player]);

  useEffect(() => {
    refetchFriends.refetch();
  }, []);


  const navigate = useNavigate();

  return (

    <Layout classes='-mt-12 bg-black min-h-screen bg-gradient-to-b from-orange-700 to-orange w-screen'>

      {/* NavBar */}

      <div className={"w-screen mt-0 pt-6 max-w-4xl relative"}>

        <div className={"w-full mx-auto flex flex-col gap-4 md:gap-8 p-4"}>

          <div className={"flex justify-between w-full center"}>

            <span className={""}><BackButton label={""} callback={() => navigate(-1)}/></span>

            <div className={"relative flex w-full justify-center"}>
              <SecondaryNavigation navItems={[
                {path: '/referral-rewards', name: 'Rewards'},
                {path: '/referral-friends', name: 'Referred friends'},
              ]}/>
            </div>

            <span className={""}>
              <ReferralCountInfo/>
            </span>

          </div>

        </div>

      </div>

      <div className='container max-w-4xl mb-6'>

        {/* Sticky Player Card*/}
        {player && <SharingCardComponent player={player} sharingText={sharingText} />}

        {/* Sticky Player Card*/}
        <ReferredFriendsList/>

      </div>

    </Layout>
  );

}