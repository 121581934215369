import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useGetPlayerQuery, useGetUserQuery} from "../apis/users.api";
import {PlayerItem} from "../models/User";

export function usePlayerFromPseudoParameter(): { player?: PlayerItem, isConnectedPlayer: boolean } {
  const {data: user} = useGetUserQuery();
  const {pseudo: pseudoParam} = useParams<{ pseudo: string }>();
  const [pseudo, setPseudo] = useState<string | undefined>(pseudoParam);

  useEffect(() => {
    if (pseudoParam) {
      setPseudo(pseudoParam);
    } else if (!pseudoParam && user) {
      setPseudo(user.pseudo);
    }
  }, [user, pseudoParam]);

  const {data: player} = useGetPlayerQuery({pseudo}, {skip: !pseudo});

  return {player: player, isConnectedPlayer: user?._id === player?.userId};
}
