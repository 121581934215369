import React from "react";


const PartyingFaceIcon: React.FC<{ h?: number }> = ({h = 24}) => <svg width={h} height={h} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2329_54842)">
      <path d="M30.2222 64C46.9135 64 60.4444 50.469 60.4444 33.7778C60.4444 17.0865 46.9135 3.55556 30.2222 3.55556C13.5309 3.55556 0 17.0865 0 33.7778C0 50.469 13.5309 64 30.2222 64Z" fill="#FFCC4D"/>
      <path d="M31.9984 50.6667C33.9621 50.6667 35.554 48.6768 35.554 46.2222C35.554 43.7676 33.9621 41.7778 31.9984 41.7778C30.0347 41.7778 28.4429 43.7676 28.4429 46.2222C28.4429 48.6768 30.0347 50.6667 31.9984 50.6667Z" fill="#664500"/>
      <path d="M14.4197 38.0142C14.0962 38.0142 13.7673 37.9253 13.4739 37.7404C12.6437 37.2178 12.3948 36.1209 12.9175 35.2889C14.5459 32.7058 19.497 28.288 26.5886 30.0231C27.5415 30.256 28.1246 31.2196 27.8917 32.1724C27.657 33.1253 26.6899 33.7049 25.7424 33.4756C19.3353 31.9076 15.961 37.1307 15.9273 37.184C15.5877 37.7209 15.0099 38.0142 14.4197 38.0142ZM35.705 31.3618C35.1895 31.3618 34.681 31.1396 34.329 30.7111C33.7068 29.952 33.817 28.8302 34.5779 28.208C40.2242 23.5787 46.5993 25.4311 49.1824 27.0596C50.0126 27.5822 50.2615 28.6809 49.737 29.5111C49.2162 30.3378 48.1228 30.5902 47.2908 30.0711C47.0455 29.9218 41.865 26.8302 36.8304 30.9582C36.4997 31.2302 36.1015 31.3618 35.705 31.3618Z" fill="#664500"/>
      <path d="M23.7263 55.5965C23.6054 55.5965 23.4827 55.5805 23.3618 55.5467C22.6543 55.3458 22.2418 54.6098 22.4427 53.9005C23.2196 51.1627 22.9014 48.5156 21.5716 46.6365C20.688 45.3885 19.4294 44.6258 18.1174 44.5422C17.3814 44.4978 16.8232 43.8631 16.8694 43.1289C16.9156 42.3947 17.5467 41.7938 18.2827 41.8827C20.4036 42.0142 22.3947 43.1858 23.7458 45.0951C25.5432 47.6356 26.0036 51.1093 25.0063 54.6258C24.8409 55.2142 24.3058 55.5965 23.7263 55.5965ZM43.3476 50.1547C43.0952 50.1547 42.8409 50.0836 42.6152 49.9342C40.5405 48.5653 39.3796 46.1707 39.4294 43.3653C39.4809 40.4587 40.8676 37.76 42.9565 36.4871C43.584 36.1031 44.4036 36.3022 44.7876 36.9333C45.1716 37.5627 44.9725 38.3805 44.3414 38.7645C43.0347 39.5609 42.1298 41.4293 42.0943 43.4116C42.0587 45.2871 42.7858 46.8533 44.0818 47.7085C44.6969 48.1138 44.8658 48.9405 44.4605 49.5556C44.2045 49.9449 43.7814 50.1547 43.3476 50.1547Z" fill="#E2A62D"/>
      <path d="M30.5403 4.83556C30.4639 4.74845 30.3448 4.70045 30.2043 4.67378C30.2043 4.67378 1.895 -0.700438 0.670114 0.380451C-0.552997 1.46312 1.31545 30.2187 1.31545 30.2187C1.32434 30.3627 1.35634 30.4853 1.43456 30.5725C2.50834 31.7885 9.89323 27.0098 17.9306 19.904C25.9697 12.7965 31.6141 6.05156 30.5403 4.83556Z" fill="#DD2E44"/>
      <path d="M0.62029 0.481779C0.593623 0.535112 0.570512 0.608001 0.552734 0.700445C0.835401 3.13778 3.56607 23.1929 5.2674 29.4613C7.82029 28.0338 10.4141 26.0942 13.4612 23.6782C10.8727 18.9404 2.05851 0.259556 0.62029 0.481779Z" fill="#EA596E"/>
      <path d="M53.1593 51.9627L34.3628 49.6462C32.3539 49.4649 28.8215 49.4471 29.0028 46.2649C29.1681 43.3796 32.6295 43.824 35.1877 44.2027L54.0997 47.3564L53.1593 51.9627Z" fill="#3B88C3"/>
      <path d="M54.098 47.3582L46.5922 46.0711C45.714 45.9129 44.9318 47.2213 44.8927 48.6062C44.8482 50.1458 45.2962 50.9244 46.1833 51.1058L53.5504 52.016L54.098 47.3582Z" fill="#88C9F9"/>
      <path d="M62.0763 46.8284L57.415 51.1147L49.0825 42.0533L53.7439 37.7671C56.1634 35.5413 59.9643 35.7013 62.1901 38.1209L62.4301 38.3822C64.6541 40.8036 64.4959 44.6027 62.0763 46.8284Z" fill="#3B88C3"/>
      <path d="M57.412 51.1171C59.4141 49.2763 59.172 45.7556 56.8714 43.2534C54.5707 40.7512 51.0827 40.215 49.0806 42.0558C47.0786 43.8966 47.3206 47.4173 49.6213 49.9195C51.9219 52.4217 55.41 52.9579 57.412 51.1171Z" fill="#88C9F9"/>
      <path d="M55.7459 49.3046C56.747 48.3842 56.4395 46.4211 55.0593 44.9199C53.679 43.4187 51.7486 42.9479 50.7476 43.8683C49.7466 44.7887 50.054 46.7518 51.4342 48.253C52.8145 49.7542 54.7449 50.225 55.7459 49.3046Z" fill="#226699"/>
      <path d="M4.4445 62.2222C5.91726 62.2222 7.11117 61.0283 7.11117 59.5556C7.11117 58.0828 5.91726 56.8889 4.4445 56.8889C2.97174 56.8889 1.77783 58.0828 1.77783 59.5556C1.77783 61.0283 2.97174 62.2222 4.4445 62.2222Z" fill="#55ACEE"/>
      <path d="M51.5556 7.11111C53.5192 7.11111 55.1111 5.51923 55.1111 3.55556C55.1111 1.59188 53.5192 0 51.5556 0C49.5919 0 48 1.59188 48 3.55556C48 5.51923 49.5919 7.11111 51.5556 7.11111Z" fill="#55ACEE"/>
      <path d="M8.64709 51.9929L4.49064 42.0089L0.732422 52.7556L8.64709 51.9929ZM46.2222 8.88889L39.1111 10.6667L40.8889 3.55556L46.2222 8.88889Z" fill="#EA596E"/>
      <path d="M56.8872 23.1111L64.0001 14.2204L58.6668 10.6667L56.8872 23.1111Z" fill="#77B255"/>
    </g>
    <defs>
      <clipPath id="clip0_2329_54842">
        <rect width="64" height="64" fill="white"/>
      </clipPath>
    </defs>
  </svg>
;

export {PartyingFaceIcon};