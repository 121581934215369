import { useEffect, useRef, useState } from "react";
import GiftIcon from "../svg/GiftIcon";
import { DailyCountdown } from "../ui/timer.component";
import { RootState } from "../../app/store";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useClaimDailyQuestWithOptimismMutation } from "../../apis/users.api";
import {animate} from "../../slices/gemSlice";
import useGemsCounterAnimation from "../../hooks/useGemsCounterAnimation";
import { useGetDailyGiftQuery } from "../../apis/users.api";

const DailyGift: React.FC = () => {

  const [claimReward] = useClaimDailyQuestWithOptimismMutation();
  const dispatch = useAppDispatch();
  const animationIsPlaying = useAppSelector((state: RootState) => state.gems.isPlaying);
  const spawnElementRef = useRef<HTMLDivElement>(null);
  const { incrementCounter } = useGemsCounterAnimation();

  const {data: dailyGift, isLoading: isDailyGiftLoading} = useGetDailyGiftQuery();

  const hasClaimed = useRef(false);

  async function onUserClaim(amount: number) {

    hasClaimed.current = true;

    if(animationIsPlaying || !dailyGift){
      return;
    }

    const animParams = {
      spawnPoint: {
        x: 0,
        y: 0
      }
    };

    if(spawnElementRef.current){
      const domRect = spawnElementRef.current.getBoundingClientRect();
      animParams.spawnPoint.x = domRect.x + domRect.width / 2;
      animParams.spawnPoint.y = domRect.y - domRect.height;
    }

    dispatch(animate(animParams));
    incrementCounter(amount, ()=>{claimReward({amount:amount, questListName:'dailyShopGifts'})});

  }

  return (

    <div className="w-full flat-card !p-2">

      <div className="bg-gray-100 border flex w-full justify-between items-center p-2 rounded-lg sm:px-4">

        <div className="flex items-center gap-2 sm:gap-4">

          <GiftIcon h={36}/>

          <div className="flex flex-col justify-center">

            <p className="font-[BioRhyme] text-base">Gift of the day</p>
            <p className="font-medium text-xs"> Refresh in <DailyCountdown/> </p>

          </div>

        </div>

        {/* Claimed */}
        { !isDailyGiftLoading && dailyGift && dailyGift.isClaimed &&
          <div className="border border-orange text-orange p-2 rounded-md text-xs font-bold">
            Claimed
          </div>
        }

        {/* To Claim */}
        { !isDailyGiftLoading && dailyGift && !dailyGift.isClaimed &&
          <div onClick={()=>{onUserClaim(dailyGift.rewardAmount)}} ref={spawnElementRef}
            className="orange-button text-white text-xs font-bold">
            { hasClaimed.current ? "Claiming": "Claim" }
          </div>
        }

      </div>

    </div>


   );
}

export default DailyGift;