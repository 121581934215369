import toast from "react-hot-toast";
import {isDateAfterToday, isDateBeforeToday, isSameDayAsToday} from "../../utils/DateUtils";

// import {useClaimDailyQuestMutation} from "../../apis/users.api";
import {useClaimDailyQuestWithOptimismMutation} from "../../apis/users.api";
import {GemsIcon} from "../ui/gems-icon";

import {RewardType} from "./profile-referral-rewards.component";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";

import {animate} from "../../slices/gemSlice";
import { useRef } from "react";

import useGemsCounterAnimation from "../../hooks/useGemsCounterAnimation";


interface DailyQuestRewardProps {
  dayNumber: number,
  type: RewardType,
  rewardAmount: number,
  isClaimed: boolean,
  claimableDate: Date
}

function isClaimableDay(day:Date){
  return isSameDayAsToday(day);
}

function isClaimDayBeforeToday(day:Date){
  return isDateBeforeToday(day);

}

function isClaimDayAfterToday(day:Date){
  return isDateAfterToday(day);
}


const DailyQuestReward: React.FC<DailyQuestRewardProps> = (props) => {

  const [claimReward] = useClaimDailyQuestWithOptimismMutation();
  const dispatch = useAppDispatch();
  const animationIsPlaying = useAppSelector((state: RootState) => state.gems.isPlaying);
  const spawnElementRef = useRef<HTMLDivElement>(null);
  const { incrementCounter } = useGemsCounterAnimation();

  const hasClaimed = useRef(false);

  async function onUserClaim(amount: number) {

    hasClaimed.current = true;

    if(animationIsPlaying){
      return;
    }

    const animParams = {
      spawnPoint: {
        x: 0,
        y: 0
      }
    };

    if(spawnElementRef.current){
      const domRect = spawnElementRef.current.getBoundingClientRect();
      animParams.spawnPoint.x = domRect.x + domRect.width / 2;
      animParams.spawnPoint.y = domRect.y - domRect.height;
    }

    dispatch(animate(animParams));
    incrementCounter(amount, ()=>{claimReward({amount:amount})});

  }

  return (
    <>

      {/* Breadcrumb */}
      <div className={`${props.dayNumber == 1 ? 'hidden' : 'show'}`}>
        <div className={`h-8 w-8 border-r  border-r-grey-100`}>
        </div>
      </div>

      {/* Reward */}
      <div
        className={`min-h-[68px] flat-card flex gap-2 ${props.isClaimed ? '!border-orange border-2 shadow-none' : ''}`}>

        <div className="flex-none content-center">
          <GemsIcon h={32}/>
        </div>

        <div className="flex-auto content-center">
          <p className={`font-bold text-xs`}>
            {props.rewardAmount} Gems
          </p>
        </div>

        <div className="flex-none content-center">

          {/* Claimed */}
          {props.isClaimed &&
            <div className="border border-orange text-orange p-2 rounded-md text-xs font-bold">
              Claimed
            </div>
          }

          {/* Claim day missed*/}
          {!props.isClaimed && isClaimDayBeforeToday(props.claimableDate) &&
            <div className="border border-gray-400 text-gray-400 p-2 rounded-md text-xs font-bold">
              Missed
            </div>
          }

          {/* Claim day */}
          {!props.isClaimed && isClaimableDay(props.claimableDate) &&
            <div onClick={()=>{onUserClaim(props.rewardAmount)}} ref={spawnElementRef}
              className="orange-button text-white text-xs font-bold">
              { hasClaimed.current ? "Claiming": "Claim" }
            </div>
          }

          {/* Claim day is in future*/}
          {!props.isClaimed && isClaimDayAfterToday(props.claimableDate) &&
            <div className="orange-button text-white text-xs font-bold cursor-auto">
              Day {props.dayNumber}
            </div>
          }

        </div>
      </div>
    </>
  );
}

export default DailyQuestReward;