import { GemsIcon } from "../gems-icon";
import { PartyingFaceIcon } from "../partying-face-icon";

interface DefaultSuccessCheckoutProps {

}

const DefaultSuccessCheckout: React.FC<DefaultSuccessCheckoutProps> = () => {
  return (
    <>
      <PartyingFaceIcon h={50}/>
      <h1 className={"text-white text-4xl text-center"}>Purchase success!</h1>
      <GemsIcon h={64}></GemsIcon>
    </>
  );
}

export default DefaultSuccessCheckout;