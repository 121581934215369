import {emptySplitApi} from "./empty.api";
import {SubPayload, Tournament} from "../models/Tournament";
import {PlayerListResponse} from "../models/User";
import {TournamentRankingRequestDto} from "../dto/tournament-ranking-request.dto";
import {TournamentRegisterRequestDto} from "../dto/tournament-register-request.dto";


export const tournamentApi = emptySplitApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getTournament: build.query<Tournament, string | undefined>({
      query: (tournamentId) => ({
        url: "/tournaments",
        params: {tournamentId},
        method: "GET",
      }),
      providesTags: (result, error, id) => [{type: 'Tournament', id}],
    }),
    getYesterdayTournaments: build.query<Tournament[], { count?: number, type?: string | string[] } | undefined>({
      query: (params) => ({
        url: "/tournaments/yesterday",
        params,
        method: "GET",
      }),
      providesTags: (result, error, tournamentType) => [{type: 'YesterdayTournaments', tournamentType}],
    }),
    getNextTournaments: build.query<Tournament[], {
      count?: number,
      type?: string | string[],
    } | undefined>({
      query: (params) => ({
        url: "/tournaments/next",
        params,
        method: "GET",
      }),
      providesTags: (result, error, tournamentType) => [{
        type: 'NextTournaments',
        tournamentType: tournamentType?.type
      }],
    }),

    getCycleTournaments: build.query<Tournament[], {
      count?: number,
      type?: string | string[],
      pseudo: string
    } | undefined>({
      query: (params) => ({
        url: "/tournaments/current_cycle",
        params,
        method: "GET",
      }),
      providesTags: (result, error, tournamentType) => [{type: 'CurrentCycleTournaments', tournamentType}],
    }),

    getWeeklyTournaments: build.query<Tournament[], { type?: string } | undefined>({
      query: (params) => ({
        url: "/tournaments/weekly",
        params,
        method: "GET",
      }),
      providesTags: (result, error, tournamentType) => [{type: 'WeeklyTournaments', tournamentType}],
    }),

    getLastTournaments: build.query<Tournament[], {
      startIndex?: number,
      count?: number,
      type?: string,
      name?: string,
      me?: boolean
    } | undefined>({
      query: (params) => ({
        url: "/tournaments/last",
        params,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{type: 'LastTournaments'}],
    }),
    getRunningTournaments: build.query<Tournament[], { count?: number, type?: string } | undefined>({
      query: (params) => ({
        url: "/tournaments/running",
        params,
        method: "GET",
      }),
      providesTags: (result, error, tournamentType) => [{type: 'RunningTournaments', tournamentType}],
    }),
    registerUserToTournament: build.mutation<Tournament, TournamentRegisterRequestDto>({
      query: (TournamentRegisterRequestDto) => ({
        url: "/tournaments/postSubscription",
        method: "POST",
        body: TournamentRegisterRequestDto,
      }),
      invalidatesTags: (result, error, TournamentRegisterRequestDto) =>
        ['User', 'UserMoney', 'UserLiveOverallPerformance', 'NextTournaments', 'Inventory', {
          type: 'Tournament',
          id: TournamentRegisterRequestDto.tournamentId
        }, {
          type: 'TournamentPlayers',
          id: TournamentRegisterRequestDto.tournamentId
        }, {
          type: 'UserTournamentScore',
          id: TournamentRegisterRequestDto.tournamentId
        }],

      async onQueryStarted({shouldStopAt, tournamentType, tournamentId, ...patch}, {dispatch, queryFulfilled}) {
        try {
          const {data: tournament} = await queryFulfilled;

          //
          //I keep this commented as tutorial of how to update a single element in a cached list in RTK.$
          //It is working very well but, finally I did not use it because it makes the page scrolling back
          // to 0 when the state change.

          // const patchResult = dispatch(
          //   tournamentApi.util.updateQueryData('getNextTournaments', {
          //     type: ['head_to_head', 'pump_or_dump'],
          //     count: 5
          //   }, (draft) => {
          //     const t = draft.find(t => t.id === tournamentId);
          //     if (t) {
          //       Object.assign(t, tournament);
          //     }
          //   })
          // )
        } catch (e) {
          console.error(e);
        }
      },
    }),
    getTournamentRanking: build.query<PlayerListResponse, TournamentRankingRequestDto>({
      query: (params) => ({
        url: "/tournaments/ranking",
        params,
        method: "GET",
      }),
      providesTags: (result, error, params) => [{type: 'TournamentPlayers', id: params.tournamentId}],
    }),
    getUserScore: build.query<SubPayload, { tournamentId: string }>({
      query: (params) => ({
        url: "/tournaments/score",
        params,
        method: "GET",
      }),
      providesTags: (result, error, params) => [{type: 'UserTournamentScore', id: params.tournamentId}],
    }),
    getUserScores: build.query<SubPayload[], { tournamentIds: string[] }>({
      query: (params) => ({
        url: "/tournaments/scores",
        params,
        method: "GET",
      }),
      providesTags: (result, error, params) => [{type: 'UserTournamentScores', tournamentIds: params.tournamentIds}],
    }),
    getStreakBetBonus: build.query<number, void>({
      query: () => ({
        url: "/tournaments/getStreakBetBonus",
        method: "GET",
      }),
      providesTags: (result, error, params) => [{type: 'StreakBetBonus'}],
    }),

    getBaseBetOdd: build.query<number, void>({
      query: () => ({
        url: "/tournaments/getBaseBetOdd",
        method: "GET",
      }),
      providesTags: (result, error, params) => [{type: 'BaseBetOdd'}],
    }),
  }),
});

export const {
  useGetBaseBetOddQuery,
  useGetCycleTournamentsQuery,
  useGetStreakBetBonusQuery,
  useGetUserScoresQuery,
  useGetTournamentQuery,
  useGetTournamentRankingQuery,
  useRegisterUserToTournamentMutation,
  useGetLastTournamentsQuery,
  useGetYesterdayTournamentsQuery,
  useGetNextTournamentsQuery,
  useGetWeeklyTournamentsQuery,
  useGetRunningTournamentsQuery,
  useGetUserScoreQuery,
} = tournamentApi;