import {emptySplitApi} from "./empty.api";
import {
  BuyCryptoDto,
  ExecuteSwapSquadTradeDto,
  SquadRoiSwapList,
  SquadScorePayload,
  SquadTradeDto
} from "../dto/squad/squad.dto";
import {CryptoCurrency} from "../models/Wallet";
import {Tournament} from "../models/Tournament";
import {PlayerListResponse} from "../models/User";
import {TournamentRankingRequestDto} from "../dto/tournament-ranking-request.dto";
import {PriceRange} from "../models/PriceRange";


export const squadApi = emptySplitApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getSquadAnalytics: build.query<SquadScorePayload, { tournamentId: string, pseudo?: string }>({
      query: (params) => ({
        url: "/squad/analytics",
        params: params,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{type: 'SquadAnalytics', id: JSON.stringify(id)}],
    }),
    getSquadGain: build.query<number, { tournamentId: string, cryptoId?: string, range: PriceRange, pseudo?: string }>({
      query: (params) => ({
        url: "/squad/gain",
        params: params,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{type: 'SquadGain', id: JSON.stringify(id)}],
    }),
    getSquadPerfPercentage: build.query<{ gain: number, performance: number }, {
      tournamentId: string,
      cryptoId?: string,
      range: PriceRange,
      pseudo?: string
    }>({
      query: (params) => ({
        url: "/squad/performance",
        params: params,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{type: 'SquadPerformance', id: JSON.stringify(id)}],
    }),
    getSquadSkills: build.query<SquadTradeDto[], { codes: any[] }>({
      query: (params) => ({
        url: "/squad/skills",
        params: params,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{type: 'SquadTrades'}],
    }),
    buySkill: build.mutation<void, { tournamentId: string, skillCodes: any[] }>({
      query: (buySkillsDto) => ({
        url: "/squad/skills/buy",
        method: "POST",
        body: buySkillsDto,
      }),
      invalidatesTags: (a, b, o) =>
        ['UserTournamentScore', 'User'],
    }),
    getAllCryptos: build.query<CryptoCurrency[], any>({
      query: (params) => ({
        url: "/squad/allCryptos",
        params: params,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{type: 'AllSquadCryptos', id}],
    }),
    areSquadTradesAvailable: build.query<boolean, string>({
      query: (tournamentId) => ({
        url: "/squad/trades/availables",
        params: {tournamentId},
        method: "GET",
      }),
    }),
    getSquadCryptos: build.query<SquadRoiSwapList, { tournamentId: string, pseudo?: string }>({
      query: (params) => ({
        url: "/squad/trades/cryptos/",
        params,
        method: "GET",
      }),
      providesTags: ['SquadCryptoToTrade']
    }),
    executeSwapTrade: build.mutation<void, ExecuteSwapSquadTradeDto>({
      query: (executeSquadTradeDto) => ({
        url: "/squad/trades/executeSwapTrade",
        method: "POST",
        body: executeSquadTradeDto,
      }),
      invalidatesTags: (a, b, o) =>
        [{type: 'Tournament', id: o.tournamentId}, {
          type: 'TournamentPlayers',
          id: o.tournamentId
        }, 'SquadCryptoToTrade', 'SquadAnalytics', 'UserMoney', 'UserLiveOverallPerformance'],
    }),
    buyCrypto: build.mutation<void, BuyCryptoDto>({
      query: (buyDto) => ({
        url: "/squad/trades/buy",
        method: "POST",
        body: buyDto,
      }),
      invalidatesTags: (a, b, o) =>
        [{
          type: 'Tournament',
          id: o.tournamentId
        }, 'SquadCryptoToTrade', 'SquadAnalytics', 'UserMoney', 'UserLiveOverallPerformance'],
    }),
    getWeekBestROIPlayers: build.query<PlayerListResponse, { week: string, request: TournamentRankingRequestDto }>({
      query: (params) => ({
        url: "/squad/best_roi/week/" + params.week,
        params: params.request,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetSquadAnalyticsQuery,
  useGetSquadGainQuery,
  useGetSquadPerfPercentageQuery,
  useGetAllCryptosQuery,
  // useGetSquadTradesQuery,
  useGetSquadSkillsQuery,
  useAreSquadTradesAvailableQuery,
  useGetSquadCryptosQuery,
  useExecuteSwapTradeMutation,
  useBuyCryptoMutation,
  useGetWeekBestROIPlayersQuery,
  useBuySkillMutation
} = squadApi;