import React from "react";
import {classNames} from "../../utils/Helper";
import {DailyCountdown} from "./timer.component";


const BigRoundedTitle: React.FC<{
    title: string | JSX.Element,
    bgColor: string,
    textColor: string,
    timer?: boolean
  }> = ({title, bgColor, textColor, timer}) =>

    <div className={`w-full h-[50px] pl-5 pr-2 rounded-tr-2xl justify-between items-center inline-flex ${bgColor}`}>
      <p className={` text-xl font-['BioRhyme'] ${textColor}`}>{title}</p>
      <p className={`${textColor} ${timer ? "" : "hidden"}`}>end in <DailyCountdown/></p>
    </div>

;

export {BigRoundedTitle};