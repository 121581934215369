import React from 'react';

const BearishIcon = () => (
<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M7 12C10.3137 12 13 9.31371 13 6C13 2.68629 10.3137 0 7 0C3.68629 0 1 2.68629 1 6C1 9.31371 3.68629 12 7 12Z" fill="#C1694F"/>
  <path d="M29 12C32.3137 12 35 9.31371 35 6C35 2.68629 32.3137 0 29 0C25.6863 0 23 2.68629 23 6C23 9.31371 25.6863 12 29 12Z" fill="#C1694F"/>
  <path d="M7 10C9.20914 10 11 8.20914 11 6C11 3.79086 9.20914 2 7 2C4.79086 2 3 3.79086 3 6C3 8.20914 4.79086 10 7 10Z" fill="#E6AAAA"/>
  <path d="M29 10C31.2091 10 33 8.20914 33 6C33 3.79086 31.2091 2 29 2C26.7909 2 25 3.79086 25 6C25 8.20914 26.7909 10 29 10Z" fill="#E6AAAA"/>
  <path d="M35 22C35 22 33.692 0 18 0C2.308 0 1 22 1 22C1 27.872 5.499 32.323 13.216 33.61C14.311 35.06 16.044 36 18 36C19.956 36 21.688 35.06 22.784 33.61C30.501 32.323 35 27.872 35 22Z" fill="#C1694F"/>
  <path d="M18 34C20.2091 34 22 32.2091 22 30C22 27.7909 20.2091 26 18 26C15.7909 26 14 27.7909 14 30C14 32.2091 15.7909 34 18 34Z" fill="#DD2E44"/>
  <path d="M18 20C18 20 7 23.687 7 27C7 30.313 9.687 33 13 33C15.088 33 16.925 31.933 18 30.315C19.074 31.933 20.912 33 23 33C26.313 33 29 30.313 29 27C29 23.687 18 20 18 20Z" fill="#D99E82"/>
  <path d="M13.125 25C11.501 26 16.375 29 18 29C19.625 29 24.499 26 22.874 25C21.249 24 14.75 24 13.125 25Z" fill="#272B2B"/>
  <path d="M23 18L25 17V19C25 19 25 21 23 21C21 21 21 19 21 19L23 18Z" fill="#272B2B"/>
  <path d="M11 17L13 18L15 19C15 19 15 21 13 21C11 21 11 19 11 19L11 17Z" fill="#272B2B"/>
</svg>

);

export default BearishIcon;
