import * as React from 'react';
import {forwardRef, useEffect, useRef} from 'react';
import Drawer from '@mui/material/Drawer';
import CloseIcon from "@mui/icons-material/Close";
import {LineIcon} from "./line-icon";
import {BorderedTitle} from "./bordered-title";
import {disableBodyScroll, enableBodyScroll} from "../../utils/HtmlHelper";

type Anchor = 'top' | 'left' | 'bottom' | 'right';

export type DrawerProps = {
  children: JSX.Element | JSX.Element[],
  anchor: Anchor,
  element?: string | JSX.Element,
  title?: string,
  open?: boolean,
  onOpen?: Function,
  onClose?: Function,
  buttonClass?: string,
  lineIcon?: boolean,
  closeWhenClickingOutside?: boolean;
  disabled?: boolean;
  className?: string;
  size?: string;
}

export default function TemporaryDrawer({
                                          children,
                                          anchor,
                                          element,
                                          title = '',
                                          open = false,
                                          buttonClass = "primary",
                                          lineIcon = true,
                                          closeWhenClickingOutside = true,
                                          className = '',
                                          onOpen = () => {
                                          },
                                          onClose = () => {
                                          },
                                          disabled = false,
                                          size = ''
                                        }: DrawerProps) {

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  //We this mutable var to avoid calling toggleDrawer(false) when the component render the first time
  //and is already closed
  const isOpen = useRef(false);

  useEffect(() => {
    if (!open) enableBodyScroll();
  }, [open]);

  useEffect(() => {
    if (isOpen.current !== open)
      toggleDrawer(anchor, open)(undefined);
  }, [open, anchor]);

  const toggleDrawer = (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent | undefined) => {
      if (event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      if (open) {
        onOpen();
        disableBodyScroll();
      } else {
        onClose();
        enableBodyScroll();
      }
      setState({...state, [anchor]: open});
      isOpen.current = open;
    };

  const listRef = useRef<HTMLDivElement>(null);

  const MyList = forwardRef((props: any, ref: React.ForwardedRef<HTMLDivElement>) => {

    return <div ref={ref} className={"h-full w-full !rounded-2xl"} role="presentation">
      <div className={"flex flex-col px-4 py-2 h-full"}>

        {title &&
          <div className={"relative flex justify-center py-2 w-full"}>
            {/*<BorderedTitle title={title}/>*/}
            <div>{title}</div>
            <div className={"absolute right-0"} onClick={toggleDrawer(props.anchor, false)}><CloseIcon/></div>
          </div>
        }

        {!title && lineIcon &&
          <div onClick={toggleDrawer(props.anchor, false)}
               className={"cursor-pointer w-full flex justify-center pb-2 my-2"}>
            <LineIcon/>
          </div>
        }
        <div className={"grow overflow-y-auto"}>
          {children}
        </div>
      </div>
    </div>
  });

  return (
    <div className={className} onClick={(e) => e.stopPropagation()}>
      <React.Fragment key={anchor}>


        {element &&
          <button className={`${buttonClass}`} onClick={(e) => {
            if (!disabled) toggleDrawer(anchor, true)(e);
          }}>
            {element}
          </button>
        }

        <Drawer
          anchor={anchor}
          open={state[anchor]}
          onClose={(e, b) => {
            if (b === 'backdropClick' && !closeWhenClickingOutside) {

            } else {
              toggleDrawer(anchor, false)(undefined);
            }

          }}

          PaperProps={
            anchor === 'right' || anchor === 'left'
              ? {
                sx: {
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  width: {xs: 150, md: 250},
                },
              }
              : {
                sx: {
                  margin: 'auto',
                  maxWidth: '750px',
                  height: size === 'auto' ? 'auto' : 'calc(100% - 120px)',
                  top: size === 'auto' ? '' : 120,
                  borderTopLeftRadius: '20px',
                  borderTopRightRadius: '20px',
                  backgroundColor: "white"
                }
              }
          }
        >
          <MyList anchor={anchor} ref={listRef}/>
          {/*{list(anchor)}*/}
        </Drawer>
      </React.Fragment>
    </div>
  );
};


