import React from "react";


const MagnifyingGlassIcon: React.FC<{ h?: number }> = ({h = 24}) => <svg width={h} height={h} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2498_223)">
      <path d="M8.25156 12.0346L9.97978 13.7628L6.95478 16.7878L5.22656 15.059L8.25156 12.0346Z" fill="#9AAAB4"/>
      <path d="M0.768498 17.7894L4.36183 14.1967C4.83911 13.7194 5.61278 13.7194 6.09005 14.1967L7.81828 15.9249C8.29556 16.4022 8.29556 17.1759 7.81828 17.6532L4.22555 21.2459C3.271 22.2011 1.72244 22.2011 0.767887 21.2459C-0.186057 20.2919 -0.185446 18.744 0.768498 17.7894Z" fill="#66757F"/>
      <path d="M13.6621 16.6033C18.2185 16.6033 21.9121 12.9096 21.9121 8.35328C21.9121 3.79693 18.2185 0.103279 13.6621 0.103279C9.10576 0.103279 5.41211 3.79693 5.41211 8.35328C5.41211 12.9096 9.10576 16.6033 13.6621 16.6033Z" fill="#8899A6"/>
      <path d="M13.661 14.1588C16.8673 14.1588 19.4666 11.5596 19.4666 8.35328C19.4666 5.14696 16.8673 2.54772 13.661 2.54772C10.4547 2.54772 7.85547 5.14696 7.85547 8.35328C7.85547 11.5596 10.4547 14.1588 13.661 14.1588Z" fill="#BBDDF5"/>
    </g>
    <defs>
      <clipPath id="clip0_2498_223">
        <rect width="22" height="22" fill="white"/>
      </clipPath>
    </defs>
  </svg>
;

export {MagnifyingGlassIcon};