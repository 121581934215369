import React from 'react';
import {GemsIcon} from '../ui/gems-icon';
import {classNames} from "../../utils/Helper";

const UserGemsComponent: React.FC<{ iconSize?: number, textCss?: string, gemsAmount: number }> = ({
                                                                                                  gemsAmount,
                                                                                                  iconSize = 12,
                                                                                                  textCss = 'text-sm'
                                                                                                }) => {

  return <div
    className={`bordered`}>
    <GemsIcon h={iconSize}/>
    <span className={classNames("font-bold", textCss)}>{gemsAmount || 0}</span>
  </div>
}

export {UserGemsComponent};