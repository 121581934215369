import { memo, useEffect, useRef, useState } from "react";
import { random, set } from "lodash";
import { GemsIcon } from "./gems-icon";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setGemPosition } from "../../slices/gemSlice";
import { RootState } from "../../app/store";
import 'animate.css';

const AnimatedGem: React.FC = memo(() => {
  const randX = (random() ? "pr-" : "pl-") + random(3, 10);
  const randY = (random() ? "pt-" : "pb-") + random(3, 10);

  return (
    <div className={`absolute left-0 opacity-0 animatedGem remove ${randX} ${randY}`}>
      <p className="animate__animated animate__heartBeat"><GemsIcon h={12}/></p>
    </div>
  );
});

const Counter: React.FC<{count:number}> = memo(({count}) => {
  return (
    <p className="float-left text-white text-sm font-bold">{Math.ceil(count)}</p>
  );
});

const AnimatedGemCounter: React.FC<{amount: number}> = ({amount}) => {

  const isGemAnimationPlaying = useAppSelector((state: RootState) => state.gems.isPlaying);
  const animatedCounterAmount = useAppSelector((state: RootState) => state.gems.value);
  const gemList = useAppSelector((state: RootState) => state.gems.gemList);

  const dispatch = useAppDispatch();

  const [now, setNow] = useState(isGemAnimationPlaying ? animatedCounterAmount : amount);

  const elementRef = useRef<HTMLDivElement>(null); // Animation EndPoint

  // Set the spawning coord points based on "spawnElement" relatively to the end of animation element
  useEffect(() => {
    if (elementRef.current) {
      const elementBounding = elementRef.current.getBoundingClientRect();
      dispatch(setGemPosition({x: elementBounding.x, y: elementBounding.y}));
    }
  }, [elementRef]);

  useEffect(() => {
    setNow(amount);
  }, [amount]);

  return (
    <div className="relative pl-[16px] center">
      <div className="absolute left-0" ref={elementRef}>
        <GemsIcon h={12}/>
      </div>
      <Counter count={isGemAnimationPlaying ? animatedCounterAmount : now}/>
      {gemList.map((gem, index) => (<AnimatedGem key={index}/>))}
    </div>
   );
}

export default AnimatedGemCounter;