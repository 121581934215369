import React, {useEffect, useState} from "react";
import {useGetUserNotificationsQuery} from "../apis/users.api";
import {UserNotification} from "../models/User";
import {NotificationDialog} from "./ui/notification-dialog.component";
import {useLocation} from "react-router";
import {hasElements} from "../utils/Helper";
import {isLeaderboard, isProfile, isShop} from "../routes/RouteUtils";
import {useGetMoneyAmountQuery} from "../apis/game-income.api";

export type Props = {
  openElement?: JSX.Element
  onOpenButtonClicked?: Function;
}


const ServerSentEventNotif: React.FC<Props> = ({
                                                 openElement = <>Notifications</>,
                                                 onOpenButtonClicked
                                               }) => {
  const {data: notificationsPayload, refetch} = useGetUserNotificationsQuery();
  const {data: money, refetch: refetchMoney} = useGetMoneyAmountQuery();
  const [notificationsToPop, setNotificationsToPop] = useState<UserNotification[]>([]);
  const {pathname} = useLocation();

  /**
   * Function to force refetch of user notifications from server
   */
  const refresh = async () => {
    await refetch();
    await refetchMoney();
  };

  /**
   * Subscribe to server sent events. Server will notify client when
   * new user notifications are availables.
   */
  useEffect(() => {
    const eventSource = new EventSource('/sse/user');
    eventSource.onmessage = async (event) => {
      console.log('refetch /sse/user');
      await refresh();
    }
    return () => {
      // console.log('>>>>>>>>>><>>>>>>>>>><>>>>>>>>>><>>>>>>>>>><>>>>>>>>>><>>>>>>>>>>< UNMOUNTED');
      eventSource.close();
    }
  }, []);

  /**
   * When user notification state change, update the notifications UI components.
   */
  useEffect(() => {
    if (notificationsPayload && hasElements(notificationsPayload.notifications)) {
      let filtered = notificationsPayload.notifications.filter(n => n.forcedview === true && !n.received && n.tag !== 'achievement');

      //collectible_upgrade_available notification should pop only on store
      const firstTimeCollectible = !localStorage.getItem('_collectible_upgrade_available');

      if (!firstTimeCollectible && !pathname.startsWith('/store')) {
        filtered = filtered.filter(n => n.tag !== 'collectible_upgrade_available');
      } else if (hasElements(filtered.filter(n => n.tag === 'collectible_upgrade_available'))) {
        localStorage.setItem('_collectible_upgrade_available', 'true');
      }

      if (!isShop(pathname)) {
        filtered = filtered.filter(n => n.tag !== 'gems_simple_discount');
      }

      // Prevent to show referral_success_available unless click on Profile Menu Icon (/collectibles)
      if (!isProfile(pathname)) {
        filtered = filtered.filter(n => n.tag !== 'referral_success_available');
      }

      if (!isLeaderboard(pathname)) {
        filtered = filtered.filter(n => !n.tag.startsWith('leaderboard_page-'));
      }

      // Set notification.display based on tag
      filtered = filtered.map((notification) => {
        switch (notification.tag) {
          case "new_friend_signup":
            return {...notification, display: "snackBar"}
            break;
          case "email_verification_remainder":
            return {...notification, display: "snackBar"}
            break;
          case "daily_free_coin":
            return {...notification, display: "snackBar"}
            break;
          case "someone_spying_you":
            return {...notification, display: "snackBar"}
            break;
          default:
            return {...notification, display: "fullScreen"}
            break;
        }
      });

      setNotificationsToPop(filtered);
    }
  }, [notificationsPayload, pathname])

  return <>
    {/*Notification that open in the main dialog notif popup*/}
    <NotificationDialog notifications={notificationsToPop} onClose={() => refresh()}/>
  </>
}

export {ServerSentEventNotif};

