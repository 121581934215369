import React from "react";
import './index.css';
import {Toaster} from "react-hot-toast";
import {createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider} from "react-router-dom";
import {SeeinPage} from "./pages/page";
import {NoMatch} from "./pages/nomatch.page";
import {ProtectedRoute} from "./routes/protected-route.component";
import {ProfilePage} from "./pages/wallets.page";
import {LeaderboardPage} from "./pages/leaderboard.page";
import {Account} from "./pages/user-infos.page";
import {AchievementsPage} from "./pages/achievements/achievements.page";
import {StatsPage} from "./pages/stats.page";
import {TwitterCardLinkPage} from "./pages/twitter-card-link.page";
import {ProfileCollectiblesPage} from "./pages/profile-collectibles-page";
import {ReferralFriendsPage} from "./pages/referral-friends.page";
import {ReferralRewardsPage} from "./pages/referral-rewards.page";
import {AchievementsListPage} from "./pages/achievements/achievements.list.page";
import {AchievementsOtherPage} from "./pages/achievements/other/achievements.other.page";
import {AchievementsOtherListPage} from "./pages/achievements/other/achievements.other.list.page";
import {PublicRoute} from "./routes/public-route.component";
import {SignupPage} from "./pages/signup.page";
import {LoginPage} from "./pages/login.page";
import {AskresetpwdPage} from "./pages/askresetpwd.page";
import {DoresetpwdPage} from "./pages/doresetpwd.page";
import {LinkExpiredPage} from "./pages/link_expired.page";
import {TournamentPage} from "./pages/tournament.page";
import {OldTournamentPage} from "./pages/old-tournament";
import {TournamentRankingPage} from "./pages/tournament-ranking.page";
import {PortfolioPage} from "./pages/portfolio.page";
import {PortfolioCryptoPage} from "./pages/portfolio-crypto.page";
import {CryptoComparisonPage} from "./pages/crypto-comparison.page";
import {SquadTxPage} from "./pages/squad/squad-tx.page";
import {SquadRegisterPage} from "./pages/squad/squad-register.page";
import {SquadAllocationPage} from "./pages/squad/squad-allocation.page";
import {ShopPage} from "./pages/shop-page";
import {CheckoutPage} from "./pages/checkout.page";
import {CryptoPaymentPage} from "./pages/crypto-payment-page";
import {CheckoutReturnPage} from "./pages/checkout-return-page";
import {SwapPage} from "./pages/squad/swap.page";
import {SquadBuyPage} from "./pages/squad/squad-buy.page";
import {SquadWeeklyROIPage} from "./pages/squad/week-roi-rank.page";
import {ClaimSignupPage} from "./pages/claim-signup.page";
import {SpyBetPage} from "./pages/spy-bet.page";

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <React.Fragment>
        <Route
          path="*"
          element={
            <SeeinPage title={"Page not found – Tanjiblocks"}>
              <NoMatch/>
            </SeeinPage>
          }
        />


        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <SeeinPage title={"Wallets – Tanjiblocks"}>
                <ProfilePage/>
              </SeeinPage>
            </ProtectedRoute>
          }
        />

        <Route
          path="/leaderboard/:week"
          element={
            <ProtectedRoute>
              <SeeinPage title={"Leaderboard – Tanjiblocks"}>
                <LeaderboardPage/>
              </SeeinPage>
            </ProtectedRoute>
          }
        />

        <Route
          path="/profileDetails"
          element={
            <ProtectedRoute>
              <SeeinPage title={"Account – Tanjiblocks"}>
                <Account/>
              </SeeinPage>
            </ProtectedRoute>
          }
        />

        <Route
          path="/achievements"
          element={
            <ProtectedRoute>
              <SeeinPage title={"Archievements – Tanjiblocks"}>
                <AchievementsPage/>
              </SeeinPage>
            </ProtectedRoute>
          }
        />

        <Route
          path="/stats/:pseudo"
          element={
            <ProtectedRoute>
              <SeeinPage title={"Statistics – Tanjiblocks"}>
                <StatsPage/>
              </SeeinPage>
            </ProtectedRoute>
          }
        />

        <Route
          path="/pstats/:pseudo"
          element={
            <SeeinPage title={"Player – Tanjiblocks"}>
              <TwitterCardLinkPage/>
            </SeeinPage>
          }
        />

        <Route
          path="/stats"
          element={
            <ProtectedRoute>
              <SeeinPage title={"Statistics – Tanjiblocks"}>
                <StatsPage/>
              </SeeinPage>
            </ProtectedRoute>
          }
        />

        <Route
          path="/collectibles"
          element={
            <ProtectedRoute>
              <SeeinPage title={"Collectibles – Tanjiblocks"}>
                <ProfileCollectiblesPage/>
              </SeeinPage>
            </ProtectedRoute>
          }
        />

        <Route
          path="/collectibles/:pseudo"
          element={
            <ProtectedRoute>
              <SeeinPage title={"Collectibles – Tanjiblocks"}>
                <ProfileCollectiblesPage/>
              </SeeinPage>
            </ProtectedRoute>
          }
        />

        <Route
          path="/referral-friends"
          element={
            <ProtectedRoute>
              <SeeinPage title={"Invite friends – Tanjiblocks"}>
                <ReferralFriendsPage/>
              </SeeinPage>
            </ProtectedRoute>
          }
        />

        <Route
          path="/referral-rewards"
          element={
            <ProtectedRoute>
              <SeeinPage title={"Friend rewards – Tanjiblocks"}>
                <ReferralRewardsPage/>
              </SeeinPage>
            </ProtectedRoute>
          }
        />

        <Route
          path="/achievements/:name"
          element={
            <ProtectedRoute>
              <SeeinPage title={"Your Archievements – Tanjiblocks"}>
                <AchievementsListPage/>
              </SeeinPage>
            </ProtectedRoute>
          }
        />

        <Route
          path="/:username/achievements"
          element={
            <ProtectedRoute>
              <SeeinPage title={"Archievements – Tanjiblocks"}>
                <AchievementsOtherPage/>
              </SeeinPage>
            </ProtectedRoute>
          }
        />

        <Route
          path="/:username/achievements/:name"
          element={
            <ProtectedRoute>
              <SeeinPage title={"Archievements – Tanjiblocks"}>
                <AchievementsOtherListPage/>
              </SeeinPage>
            </ProtectedRoute>
          }
        />

        <Route
          path="/signup"
          element={
            <PublicRoute>
              <SeeinPage title={"Signup – Tanjiblocks"}>
                <SignupPage/>
              </SeeinPage>
            </PublicRoute>
          }
        />

        <Route
          path="/login"
          element={
            <PublicRoute>
              <SeeinPage title={"Sign in – Tanjiblocks"}>
                <LoginPage/>
              </SeeinPage>
            </PublicRoute>
          }
        />

        <Route
          path="/askresetpwd"
          element={
            <SeeinPage title={"Reset password request – Tanjiblocks"}>
              <AskresetpwdPage/>
            </SeeinPage>
          }
        />

        <Route
          path="/resetpwd"
          element={
            <SeeinPage title={"Reset password form – Tanjiblocks"}>
              <DoresetpwdPage/>
            </SeeinPage>
          }
        />

        <Route
          path="/link_expired"
          element={
            <SeeinPage title={"Expired Link – Tanjiblocks"}>
              <LinkExpiredPage/>
            </SeeinPage>
          }
        />

        <Route
          path="/tournament/:range"
          element={
            <ProtectedRoute>
              <SeeinPage title={"Squad Tournament – Tanjiblocks"}>
                <TournamentPage/>
              </SeeinPage>
            </ProtectedRoute>
          }
        />

        <Route
          path="/tournament"
          element={<Navigate to="/tournament/current"/>}
        />

        <Route
          path="/home"
          element={<Navigate to="/tournament/current"/>}
        />
        <Route
          path="/"
          element={<Navigate to="/portfolio"/>}
        />

        <Route path="/old/tournament" element={
          <ProtectedRoute>
            <SeeinPage title={"Old Tournament – Tanjiblocks"}>
              <OldTournamentPage/>
            </SeeinPage>
          </ProtectedRoute>
        }/>

        <Route path="/tournament-ranking" element={
          <ProtectedRoute>
            <SeeinPage title={"Tournament Ranking – Tanjiblocks"}>
              <TournamentRankingPage/>
            </SeeinPage>
          </ProtectedRoute>
        }/>

        <Route
          path="/portfolio"
          element={
            <ProtectedRoute hideAppFooter={true}>
              <SeeinPage title={"Wallet – Tanjiblocks"}>
                <PortfolioPage/>
              </SeeinPage>
            </ProtectedRoute>
          }
        />

        <Route
          path="/portfolio/:cryptoId"
          element={
            <ProtectedRoute>
              <SeeinPage title={"Crypto – Tanjiblocks"}>
                <PortfolioCryptoPage/>
              </SeeinPage>
            </ProtectedRoute>
          }
        />

        <Route
          path="/chart/:cryptoAId/:cryptoBId"
          element={
            <ProtectedRoute>
              <SeeinPage title={"Crypto Chart Comparison – Tanjiblocks"}>
                <CryptoComparisonPage/>
              </SeeinPage>
            </ProtectedRoute>
          }
        />

        <Route
          path="/squad-tx"
          element={
            <ProtectedRoute>
              <SeeinPage title={"Choose your squad – Tanjiblocks"}>
                <SquadTxPage/>
              </SeeinPage>
            </ProtectedRoute>
          }
        />

        <Route
          path="/squad-registration"
          element={
            <ProtectedRoute>
              <SeeinPage title={"Choose your squad – Tanjiblocks"}>
                <SquadRegisterPage/>
              </SeeinPage>
            </ProtectedRoute>
          }
        />

        <Route
          path="/squad-distribution"
          element={
            <ProtectedRoute>
              <SeeinPage title={"Affect an amount for each – Tanjiblocks"}>
                <SquadAllocationPage/>
              </SeeinPage>
            </ProtectedRoute>
          }
        />
        <Route
          path="/store"
          element={
            <ProtectedRoute>
              <SeeinPage title={"Affect an amount for each – Tanjiblocks"}>
                <ShopPage/>
              </SeeinPage>
            </ProtectedRoute>
          }
        />
        <Route
          path="/checkout"
          element={
            <ProtectedRoute>
              <SeeinPage title={"Affect an amount for each – Tanjiblocks"}>
                <CheckoutPage/>
              </SeeinPage>
            </ProtectedRoute>
          }
        />
        <Route
          path="/crypto-pay/:step"
          element={
            <ProtectedRoute>
              <SeeinPage title={"Pay with crypto – Tanjiblocks"}>
                <CryptoPaymentPage/>
              </SeeinPage>
            </ProtectedRoute>
          }
        />

        <Route
          path="/return"
          element={
            <ProtectedRoute>
              <SeeinPage title={"Affect an amount for each – Tanjiblocks"}>
                <CheckoutReturnPage/>
              </SeeinPage>
            </ProtectedRoute>
          }
        />

        <Route path="/squad-roi-swapping" element={
          <ProtectedRoute>
            <SeeinPage title={"Swap ROI – Tanjiblocks"}>
              <SwapPage/>
            </SeeinPage>
          </ProtectedRoute>
        }/>

        <Route path="/squad-buy" element={
          <ProtectedRoute>
            <SeeinPage title={"Buy new Crypto"}>
              <SquadBuyPage/>
            </SeeinPage>
          </ProtectedRoute>
        }/>

        <Route path="/squad-roi-weekly/:week" element={
          <ProtectedRoute>
            <SeeinPage title={"ROI Weekly Ranking – Tanjiblocks"}>
              <SquadWeeklyROIPage/>
            </SeeinPage>
          </ProtectedRoute>
        }/>

        <Route path="/squad-roi-weekly/" element={
          <ProtectedRoute>
            <SeeinPage title={"ROI Weekly Ranking – Tanjiblocks"}>
              <SquadWeeklyROIPage/>
            </SeeinPage>
          </ProtectedRoute>
        }/>

        <Route
          path="/signup-claim"
          element={
            <SeeinPage title={"City – Tanjiblocks"}>
              <ClaimSignupPage/>
            </SeeinPage>
          }
        />

        <Route path="/spy/bets" element={
          <ProtectedRoute>
            <SeeinPage title={"Bet Spy"}>
              <SpyBetPage/>
            </SeeinPage>
          </ProtectedRoute>
        }/>

      </React.Fragment>
    )
  );

  return (
    <React.Fragment>
      <RouterProvider router={router} />
      <Toaster toastOptions={{
        className: '',
        style: {
          padding: '16px',
          zIndex:9999,
          color: '#666',
          background:'#ffffff'
        },
      }}/>
    </React.Fragment>
  );
}

export default App;