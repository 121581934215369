import * as React from 'react';
import Layout from "../Layout";
import {SecondaryNavigation} from "../components/ui/secondary-navigation.component";
import {useNavigate, useParams} from "react-router-dom";
import {BackButton} from '../components/ui/back-button';
import {CollectibleRewardComponent} from '../components/users/collectible-reward.component';
import {LeaderboardComponent} from "../components/users/leaderboard.component";

const LeaderboardPage: React.FC = () => {
  const {week} = useParams<{ week: 'current' | 'previous' }>();
  const navigate = useNavigate();
  return (
    <Layout>
      <div
        className="fixed top-10 w-screen min-h-screen flex flex-col bg-gradient-to-b from-orange-700 to-orange pt-12 p-4 pb-16">
        <div className="pt-2 pb-2 w-full px-4 flex justify-center">

          <div className='flex justify-between center w-full max-w-md'>

            <span className={"invisible"}><BackButton label={""} callback={() => navigate(-1)}/></span>

            <SecondaryNavigation navItems={[
              {path: '/leaderboard/previous', name: 'Last'},
              {path: '/leaderboard/current', name: 'Current'},
            ]}/>

            <span className={""}>
              <CollectibleRewardComponent widthCss={""} displayInfoButton={true}/>
            </span>

          </div>

        </div>
      </div>

      {/*I was forced de reload entirely the component when changing week otherwise I had a problem with pagination reset when
      going from current to previous*/}
      {week === 'current' &&
        <LeaderboardComponent week={'current'}/>
      }
      {week !== 'current' &&
        <LeaderboardComponent week={'previous'}/>
      }
    </Layout>
  );
};

export {LeaderboardPage}