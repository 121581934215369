import { classNames, formatBalanceNumber } from "../../utils/Helper";
import { useGetUserQuery } from "../../apis/users.api";

const ProductDisplay: React.FC<{
  code: string,
  externalId: string,
  price: number,
  discountPrice?: number,
  image: string
}> = ({code, externalId, price, image, discountPrice}) => {
  const {data: user} = useGetUserQuery();
  return user ? <div className={"relative flex flex-col items-center p-1"} onClick={(e) => {
    e.stopPropagation();
    document.location.href = `https://link.depay.com/${externalId}?payload[user_id]=${user._id}&payload[product_id]=${code}&status=success`;
  }}>

    {discountPrice &&
      <img className={"h-20 -left-0.5 -top-0.5 absolute mou"}
           src={`${process.env.REACT_APP_STATIC_ASSETS}/images/save-big-banner.png`}
      />
    }

    <img className={"w-[130px] h-auto"}
         src={`${process.env.REACT_APP_STATIC_ASSETS}/images/${image}`}
         alt={`${code} product image`}
    />
    <div className={"w-full flex gap-x-3 justify-center"}>
      <div
        className={classNames("description text-center text-lg", discountPrice ? 'line-through text-orange-700' : '')}>
        {`$${formatBalanceNumber(price)}`}
      </div>
      {discountPrice &&
        <div className={"description text-center text-lg"}>
          {`$${formatBalanceNumber(discountPrice)}`}
        </div>
      }
    </div>

  </div> : <></>
};

export default ProductDisplay;