import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {CryptoTeammate} from "../../models/squad/CryptoTeammate";
import {SquadTradeDto} from "../../dto/squad/squad.dto";

interface SquadState {
  value: CryptoTeammate[];
  trades: SquadTradeDto[];
}

// Define the initial state using that type
const initialState: SquadState = {value: [], trades: []};
export const squadSlice = createSlice({
  name: 'squad',
  initialState,
  reducers: {
    addToSquad: (state, action: PayloadAction<CryptoTeammate>) => {
      if (state.value.find(t => t.crypto.code === action.payload.crypto.code) === undefined)
        state.value.push(action.payload);
    },
    updateOrAddToSquad: (state, action: PayloadAction<CryptoTeammate>) => {
      const index = state.value.findIndex(t => t.crypto.code === action.payload.crypto.code);
      if (index !== -1) {
        state.value.splice(index, 1);
      }
      state.value.push(action.payload);
    },
    setAllocation: (state, action: PayloadAction<{ value: number, crypto: string }>) => {
      const teammate = state.value.find(t => t.crypto.code === action.payload.crypto);
      if (teammate)
        teammate.value = action.payload.value
    },
    removeFromSquad: (state, action: PayloadAction<CryptoTeammate>) => {
      state.value = state.value.filter(o => o.crypto.code != action.payload.crypto.code);
    },
    removeFromSquadWithCode: (state, action: PayloadAction<string>) => {
      state.value = state.value.filter(o => o.crypto.code != action.payload);
    },
    clearSquad: (state) => {
      state.value = [];
      state.trades = [];
    },
    addTradingAction: (state, action: PayloadAction<SquadTradeDto>) => {
      if (state.trades.find(t => t.code === action.payload.code) === undefined)
        state.trades.push(action.payload);
    },
    removeTradingAction: (state, action: PayloadAction<string>) => {
      state.trades = state.trades.filter(o => o.code != action.payload);
    },
    clearTradingActions: (state) => {
      state.trades = [];
    },
  }
});

// Action creators are generated for each case reducer function
export const {
  addToSquad,
  removeFromSquad,
  clearSquad,
  removeFromSquadWithCode,
  addTradingAction,
  updateOrAddToSquad,
  removeTradingAction,
} = squadSlice.actions