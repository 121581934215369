import React, {useCallback, useEffect, useState} from 'react';
import Layout from "../Layout";
import {useGetLastTournamentsQuery,} from "../apis/tournaments.api";
import {TournamentListComponent} from "../components/tournament/tournaments-list.component";
import {CircularProgress} from '@material-ui/core';
import MenuItem from '@mui/material/MenuItem';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import {Tournament} from '../models/Tournament';
import {Checkbox} from '@mui/material';

const OldTournamentPage: React.FC = () => {
  const [start, setStart] = useState(0);
  const [count, setCount] = useState(5);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMoreTournaments, setHasMoreTournaments] = useState(true);
  const [tournaments, setTournaments] = useState<Tournament[]>([]);
  const [tournamentType, setTournamentType] = useState('all');
  const [tournamentMe, setTournamentMe] = useState(false);

  const {data: newTournaments, isLoading: areLoading} = useGetLastTournamentsQuery({
    startIndex: start,
    count,
    name: tournamentType === 'all' ? undefined : tournamentType,
    me: tournamentMe ? true : undefined
  });

  const handleScroll = useCallback(() => {
      const windowHeight = window.innerHeight;
      const fullHeight = document.documentElement.offsetHeight;
      const scrollHeight = window.scrollY;
      if (scrollHeight + windowHeight + 100 >= fullHeight && !isLoadingMore && hasMoreTournaments && tournaments && tournaments.length) {
        setIsLoadingMore(true);
        setStart((start) => start + count);
        // console.log('loaodin more...')
      }
    }
  , [isLoadingMore, hasMoreTournaments, start, tournaments]);


  useEffect(() => {
    if (hasMoreTournaments) {
      window.addEventListener('scroll', handleScroll);
    }
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [hasMoreTournaments, isLoadingMore, start, handleScroll]);


  useEffect(() => {
    if (!areLoading) {
      if (newTournaments && newTournaments.length) {
        setIsLoadingMore(false);
        setTournaments((prevTournaments) => [...prevTournaments, ...newTournaments]);
      } else {
        setIsLoadingMore(false);
        setHasMoreTournaments(false);
      }
    }
  }, [newTournaments, count, areLoading]);


  const handleTournamentTypeChange = (event: SelectChangeEvent) => {
    setTournamentType(event.target.value as string);
    setStart(0);
    setTournaments([]);
    setHasMoreTournaments(true);
  };

  const handleTournamentMeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.checked;
    setTournamentMe(value);
    setStart(0);
    setTournaments([]);
    setHasMoreTournaments(true);
  };

  return (
    <Layout>
      <div className={"flex flex-col gap-x-4 gap-y-4 max-w-3xl mx-auto"}>
        {(areLoading && start === 0) && 'Loading tournaments ...'}
        <>
          <div className={"flex flex-col justify-center items-center justify-start gap-x-4  mt-2"}>
            <h1 className={"text-2xl"}>Past Tournaments</h1>
          </div>
          <p className={"text-sm text-center"}>
            Welcome to our Past Tournaments page! We are excited to present to you all the tournaments that we have
            organized so far, along with the rewards that we have offered to our winners.
          </p>

          <div className={"flex row justify-around items-center flex-wrap gap-x-2 text-xs md:text-base"}>
            {/*<div className={"flex justify-center items-center"}>*/}
            {/*  <Select*/}
            {/*    style={{width: '150px', maxWidth: '150px'}}*/}
            {/*    value={tournamentType}*/}
            {/*    onChange={handleTournamentTypeChange}*/}
            {/*    displayEmpty*/}
            {/*    inputProps={{'aria-label': 'Without label'}}*/}
            {/*  >*/}
            {/*    <MenuItem value="all">All</MenuItem>*/}
            {/*    <MenuItem value="SQUAD1">Crypto Clash</MenuItem>*/}
            {/*    <MenuItem value="SQUAD2">Portfolio Punchout</MenuItem>*/}
            {/*    <MenuItem value="SQUAD3">Crypto Craze</MenuItem>*/}
            {/*  </Select>*/}
            {/*</div>*/}

            <div className={"flex justify-center items-center"}>
              <div style={{marginRight: '5px'}}>My tournaments</div>
              <Checkbox checked={tournamentMe} onChange={handleTournamentMeChange}/>
            </div>
          </div>


          <TournamentListComponent tournaments={tournaments}/>

          {isLoadingMore && <CircularProgress className="mx-auto mt-4 mb-4"/>}

          {!hasMoreTournaments && <p className={"text-sm text-center mt-4"}>No more tournaments available.</p>}
        </>
      </div>
    </Layout>
  );
};

export {OldTournamentPage};
