import React from "react";


const SmilingGlassesIcon: React.FC<{ h?: number }> = ({h = 24}) => <svg width={h} height={h} viewBox="0 0 36 36"
                                                                         fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_2341_15436)">
    <path d="M36 18C36 27.941 27.941 36 18 36C8.059 36 0 27.941 0 18C0 8.059 8.059 0 18 0C27.941 0 36 8.059 36 18Z"
          fill="#FFCC4D"/>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M1.23998 11.0179C1.47998 11.2569 2.67798 11.9749 2.91698 12.6929C3.15698 13.4099 3.63698 17.4769 5.07498 18.6739C6.55798 19.9059 12.152 19.4479 13.223 18.9139C15.62 17.7189 15.914 14.3829 16.338 12.6929C16.577 11.7359 18.015 11.7359 18.015 11.7359C18.015 11.7359 19.453 11.7359 19.693 12.6919C20.117 14.3829 20.413 17.7189 22.808 18.9129C23.88 19.4479 29.474 19.9069 30.959 18.6749C32.395 17.4779 32.874 13.4109 33.114 12.6929C33.352 11.9759 34.552 11.2579 34.791 11.0189C35.032 10.7799 35.032 9.82294 34.791 9.58294C34.312 9.10494 28.657 8.67894 22.568 9.34394C21.353 9.47694 20.891 9.82194 18.014 9.82194C15.139 9.82194 14.675 9.47594 13.461 9.34394C7.37598 8.67794 1.71998 9.10394 1.23998 9.58194C1.00098 9.82094 1.00098 10.7789 1.23998 11.0179Z"
          fill="#292F33"/>
    <path
      d="M27.335 23.6291C27.157 23.4681 26.891 23.4581 26.7 23.6001C26.661 23.6291 22.778 26.5001 18 26.5001C13.234 26.5001 9.33801 23.6291 9.30001 23.6001C9.10901 23.4581 8.84301 23.4701 8.66501 23.6291C8.48801 23.7891 8.44801 24.0531 8.57101 24.2571C8.70001 24.4721 11.788 29.5001 18 29.5001C24.212 29.5001 27.301 24.4721 27.429 24.2571C27.552 24.0521 27.513 23.7891 27.335 23.6291Z"
      fill="#664500"/>
  </g>
  <defs>
    <clipPath id="clip0_2341_15436">
      <rect width="36" height="36" fill="white"/>
    </clipPath>
  </defs>
</svg>;

export {SmilingGlassesIcon};