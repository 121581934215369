import React, {useEffect} from 'react';
import {createTheme, ThemeProvider} from "@mui/material";
import {useLocation} from "react-router";
import {ScrollRestoration} from "react-router-dom";

type Props = {
  children: JSX.Element | JSX.Element[];
  title: string;
}
const SeeinPage: React.FC<Props> = ({children, title}) => {
  const location = useLocation();

  useEffect(() => {
    // console.log('updating title ' + title);
    document.title = title || process.env.REACT_APP_NAME || 'Tanjiblocks';
  }, [title]);

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#EA5C28',
      },
    },

    typography: {
      fontFamily: [
        'SATOSHI'
      ].join(','),
    },
  });

  return (
    <>
      <ScrollRestoration/>
      <div className={'hidden'}>{title}</div>
      <ThemeProvider theme={darkTheme}>
        {children}
      </ThemeProvider>
    </>
  );
};

export {SeeinPage}