import {CreateWalletRequest} from "../dto/create-wallet-request.dto";
import {Wallet} from "../models/Wallet";
import {CreateWeb3WalletRequest} from "../dto/create-web3wallet-request.dto";
import {AggrWallet} from "../models/AggrWallet";
import {DisconnectWeb3WalletRequest} from "../dto/disconnect-web3wallet-request.dto";
import {emptySplitApi} from "./empty.api";
import {CityLinkResponse} from "../models/User";

export const walletsApi = emptySplitApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    createWallet: build.mutation<Wallet, CreateWalletRequest>({
      query: (CreateWalletRequest) => ({
        url: "/wallets",
        method: "POST",
        body: CreateWalletRequest,
      }),
      invalidatesTags: ['Wallets', 'AggrWallets', 'UserCryptos', 'User', 'UserPublicCryptos'],
    }),
    connectMetamaskWallet: build.mutation<Wallet, CreateWeb3WalletRequest>({
      query: (CreateWeb3WalletRequest) => ({
        url: "/wallets/metamaskConnect",
        method: "POST",
        body: CreateWeb3WalletRequest,
      }),
      invalidatesTags: ['Wallets', 'MetamaskWalletConnect', 'AggrWallets', 'MetamaskAddress', 'UserXp', 'UserList', 'User', 'UserCryptos', 'EvmSideChain', 'MyWallets'],
    }),
    connectEthereumWallet: build.mutation<Wallet, CreateWeb3WalletRequest>({
      query: (CreateWeb3WalletRequest) => ({
        url: "/wallets/ethereumWalletConnect",
        method: "POST",
        body: CreateWeb3WalletRequest,
      }),
      invalidatesTags: ['Wallets', 'WalletConnect', 'AggrWallets', 'UserXp', 'UserList', 'User', 'UserCryptos', 'EthereumPublicAddress', 'EvmSideChain', 'MyWallets'],
    }),
    connectPhantomWallet: build.mutation<Wallet, CreateWeb3WalletRequest>({
      query: (CreateWeb3WalletRequest) => ({
        url: "/wallets/phantomConnect",
        method: "POST",
        body: CreateWeb3WalletRequest,
      }),
      invalidatesTags: ['Wallets', 'PhantomWalletConnect', 'AggrWallets', 'PhantomAddress', 'UserXp', 'UserList', 'User', 'UserCryptos', 'MyWallets'],
    }),
    getPrimaryAccountTransactions: build.mutation<void, void>({
      query: () => ({
        url: "/wallets/exchanges",
        method: "POST",
        body: {},
      }),
      invalidatesTags: ['Wallets'],
    }),
    getWallets: build.query<Wallet, void>({
      query: () => ({
        url: "/wallets",
        method: "GET",
      }),
      providesTags: (result, error, id) => [{type: 'Wallets', id: 'LIST'}],
    }),

    getWalletFromSource: build.query<Wallet, { source: string }>({
      query: (params) => ({
        url: "/wallets/fromSource",
        params,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{type: 'WalletFromSource', id: id.source}],
    }),

    getMyWallets: build.query<string[], void>({
      query: () => ({
        url: "/wallets/my",
        method: "GET",
      }),
      providesTags: (result, error, id) => [{type: 'MyWallets', id: 'LIST'}],
    }),
    getAggrWallets: build.query<AggrWallet, any>({
      query: (params) => ({
        url: "/wallets/aggrWallet",
        params,
        method: "GET",
      }),
      async onQueryStarted(cryptoToAdd, {dispatch, queryFulfilled}) {
        try {
          console.log('Calling /wallets/aggrWallet');
          await queryFulfilled
        } catch (e) {
          console.log('Error while calling /wallets/aggrWallet', e)
        }
      },
      providesTags: (result, error, id) => [{type: 'AggrWallets', id: 'AGGR'}],
    }),
    getPublicAggrWallets: build.query<AggrWallet, any>({
      query: (params) => ({
        url: "/wallets/publicAggrWallet",
        params,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{type: 'publicAggrWallet', id: id['cityUid']}],
    }),
    isMetamaskConnected: build.query<boolean, void>({
      query: () => ({
        url: "/wallets/isMetamaskConnected",
        method: "GET",
      }),
      providesTags: (result, error, id) => [{type: 'MetamaskWalletConnect', id: 'METAMASK_CONNECT'}],
    }),
    isPhantomConnected: build.query<boolean, void>({
      query: () => ({
        url: "/wallets/isPhantomConnected",
        method: "GET",
      }),
      providesTags: (result, error, id) => [{type: 'PhantomWalletConnect', id: 'PHANTOM_CONNECT'}],
    }),
    getEthereumPublicAddress: build.query<any, { source: string }>({
      query: (params) => ({
        url: "/wallets/getEthereumPublicAddress",
        params,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{type: 'EthereumPublicAddress', id: id.source}],
    }),
    ethereumWalletDisconnect: build.mutation<void, DisconnectWeb3WalletRequest>({
      query: (DisconnectWeb3WalletRequest) => ({
        url: "/wallets/ethereumWalletDisconnect",
        method: "POST",
        body: DisconnectWeb3WalletRequest,
      }),
      invalidatesTags: ['Wallets', 'EthereumPublicAddress', 'AggrWallets', 'UserXp', 'UserList', 'UserCryptos', 'User', 'MyWallets'],
    }),
    getMetamaskPublicAddress: build.query<any, void>({
      query: () => ({
        url: "/wallets/getMetamaskPublicAddress",
        method: "GET",
      }),
      providesTags: (result, error, id) => [{type: 'MetamaskAddress', id: 'METAMASK_ADDRESS'}],
    }),
    getPhantomPublicAddress: build.query<any, void>({
      query: () => ({
        url: "/wallets/getPhantomPublicAddress",
        method: "GET",
      }),
      providesTags: (result, error, id) => [{type: 'PhantomAddress', id: 'PHANTOM_ADDRESS'}],
    }),
    disconnectMetamask: build.mutation<void, DisconnectWeb3WalletRequest>({
      query: (DisconnectWeb3WalletRequest) => ({
        url: "/wallets/metamaskDisconnect",
        method: "POST",
        body: DisconnectWeb3WalletRequest,
      }),
      invalidatesTags: ['Wallets', 'MetamaskWalletConnect', 'AggrWallets', 'MetamaskAddress', 'UserXp', 'UserList', 'UserCryptos', 'User', 'MyWallets'],
    }),
    disconnectPhantom: build.mutation<void, DisconnectWeb3WalletRequest>({
      query: (DisconnectWeb3WalletRequest) => ({
        url: "/wallets/phantomDisconnect",
        method: "POST",
        body: DisconnectWeb3WalletRequest,
      }),
      invalidatesTags: ['Wallets', 'PhantomWalletConnect', 'AggrWallets', 'PhantomAddress', 'UserXp', 'UserList', 'UserCryptos', 'User', 'MyWallets'],
    }),
    generateTemporaryCity: build.mutation<CityLinkResponse, string>({
      query: (bdy) => ({
        url: "/wallets/generateTemporaryCity",
        method: "POST",
        body: {publicAddress: bdy},
      }),
      // transformResponse(response: CityLinkResponse, meta, arg) {
      //   return response;
      // },
      invalidatesTags: ['publicAggrWallet'],
    }),
  }),

});

export const {
  useCreateWalletMutation,
  useConnectMetamaskWalletMutation,
  useConnectPhantomWalletMutation,
  useGetWalletsQuery,
  useGetAggrWalletsQuery,
  useGetPublicAggrWalletsQuery,
  useGetPrimaryAccountTransactionsMutation,
  useIsMetamaskConnectedQuery,
  useIsPhantomConnectedQuery,
  useGetMetamaskPublicAddressQuery,
  useGetPhantomPublicAddressQuery,
  useDisconnectMetamaskMutation,
  useDisconnectPhantomMutation,
  useConnectEthereumWalletMutation,
  useGetEthereumPublicAddressQuery,
  useEthereumWalletDisconnectMutation,
  useGetMyWalletsQuery,
  useGenerateTemporaryCityMutation,useGetWalletFromSourceQuery
} = walletsApi;