import {LoginRequest, Web3LoginRequest} from "../dto/login-request.dto";
import {User} from "../models/User"
import {emptySplitApi} from "./empty.api";

export const authApi = emptySplitApi.injectEndpoints({
// export const authApi = createApi({
//   reducerPath: 'authApi',
//   baseQuery: fetchBaseQuery({
//     baseUrl: '/auth'
//   }),
  overrideExisting: false,
  endpoints: (build) => ({
    // login: build.mutation<User, LoginRequest>({
    //   query: (loginRequest) => ({
    //     url: "/auth/login",
    //     method: "POST",
    //     body: loginRequest,
    //   }),
    //   invalidatesTags: ['User','IsAuth']
    // }),
    isAuth: build.query<boolean, void>({
      query: () => ({
        url: "/auth/isAuth",
        method: "GET",
      }),
      providesTags: ['IsAuth']
    }),
    getWeb3Nonce: build.query<string, string>({
      query: (web3LoginPublicAddress) => ({
        url: "/auth/getWeb3Nonce",
        params: {web3LoginPublicAddress},
        method: "GET",
      }),
    }),
    web3Loggin: build.mutation<User, Web3LoginRequest>({
      query: (loginRequest) => ({
        url: "/auth/verifyNonceAndLoggin",
        method: "POST",
        body: loginRequest,
      }),
      invalidatesTags: ['User','IsAuth']
    }),
  }),
})


export const {useIsAuthQuery, useGetWeb3NonceQuery, useWeb3LogginMutation} = authApi;