import React, {useState} from 'react';
import Layout from "../Layout";
import {useParams} from "react-router-dom";
import {classNames, hasElements} from "../utils/Helper";
import {PriceRange} from "../models/PriceRange";
import {CryptoChartComponent} from "../components/chart/crypto-chart.component";
import {InnerHeader} from "../components/ui/app-inner-header.component";
import {useGetCryptosFromIdsQuery} from "../apis/cryptos.api";

const CryptoComparisonPage: React.FC = () => {
  const {cryptoAId, cryptoBId} = useParams<{ cryptoAId: string, cryptoBId: string }>();
  const [range, setRange] = useState<PriceRange>('1D');

  const {
    data: cryptos,
  } = useGetCryptosFromIdsQuery({ids: [cryptoBId, cryptoAId]}, {skip: !cryptoBId || !cryptoAId});

  return (
    <Layout classes={"min-h-screen bg-gradient-to-b from-orange-700 to-orange "}>
        <div className="w-full max-w-md md:px-4 pb-4">
          <InnerHeader
            pageTitle={cryptos && hasElements(cryptos) ? `${cryptos[0]?.code}/${cryptos[1]?.code}` : `Chart comparison`}><></>
          </InnerHeader>
        </div>

      <div className={"flex w-full gap-x-6 cursor-pointer justify-center mb-2"}>
                <span className={classNames("time-range-selector", range === '1H' ? 'selected' : '')}
                      onClick={() => setRange('1H')}>1H</span>
        <span className={classNames("time-range-selector", range === '8H' ? 'selected' : '')}
              onClick={() => setRange('8H')}>8H</span>
        <span className={classNames("time-range-selector", range === '1D' ? 'selected' : '')}
              onClick={() => setRange('1D')}>1D</span>
        <span className={classNames("time-range-selector", range === '1W' ? 'selected' : '')}
              onClick={() => setRange('1W')}>1W</span>
      </div>

      <div className={"w-full max-w-xl"}>
        {cryptoAId &&
          <CryptoChartComponent cryptoId={cryptoAId} range={range}/>
        }
        {cryptoBId &&
          <CryptoChartComponent cryptoId={cryptoBId} range={range}/>
        }
      </div>


    </Layout>
  );
};

export {
  CryptoComparisonPage
}