import React, {useEffect, useState} from 'react';
import {formatBalanceNumber, formatGainPercent, hasElements} from "../../utils/Helper";
import {getVariationRate} from "../../utils/MathsUtils";
import {useGetCryptosFromIdsQuery, useGetCryptosPricesQuery} from "../../apis/cryptos.api";
import {CryptoCurrency} from "../../models/Wallet";
import {AreaChart} from "../../components/profile/area-chart.component";
import * as _ from "lodash";
import {QuantityCoinComponent} from "../ui/quantity-coin.component";
import {PriceRange} from "../../models/PriceRange";

const CryptoChartComponent: React.FC<{ cryptoId: string, range: PriceRange }> = ({cryptoId, range = '1D'}) => {

  useEffect(() => {
    if (cryptoId) console.log(`CryptoChartComponent#cryptoId=${cryptoId}`);
  }, [cryptoId])

  const [crypto, setCrypto] = useState<CryptoCurrency>();
  const {
    data: _crypto,
    isLoading: isCryptoLoading
  } = useGetCryptosFromIdsQuery({ids: cryptoId ? [cryptoId] : []}, {skip: !cryptoId});

  useEffect(() => {
    if (_crypto && _crypto.length) {
      setCrypto(_crypto[0]);
    }
  }, [_crypto]);

  const {data: prices, isFetching: arePricesLoading} = useGetCryptosPricesQuery({
    cryptoId: cryptoId || '',
    range
  }, {skip: !cryptoId, refetchOnFocus: true, refetchOnMountOrArgChange: 120});

  const [seriesData, setSeriesData] = useState<Array<{ x: number, y: number }>>([]);

  useEffect(() => {
    if (prices && hasElements(prices)) {
      // console.log(`CryptoChartComponent#cryptoId=${JSON.stringify(prices)}`);
      let sd = []
      for (const p of prices) {
        sd.push({x: new Date(p.date).getTime(), y: Number(p.price)});
      }
      if (sd.length === 1) {
        const d = _.cloneDeep(sd[0]);
        d.x += 1000 * 60 * 60;
        sd.push(d);
      }
      setSeriesData(sd);
    }

  }, [prices])

  const [pricePerf, setPricePerf] = useState<number>(0);
  const [priceDiff, setPriceDiff] = useState<number>(0);

  useEffect(() => {
    if (prices && hasElements(prices)) {
      const perf = getVariationRate(prices[0].price, prices[prices.length - 1].price) * 100;
      setPricePerf(perf);
      setPriceDiff(prices[prices.length - 1].price - prices[0].price);
    }
  }, [prices]);


  return (
    <>
      {seriesData &&
        <>

          <div className="flex w-full flex-col gap-2 center">
            <h2 className="text-white text-opacity-80 text-xs font-bold text-center">{crypto?.code} Price</h2>

            <div className="text-white text-[32px] font-bold  leading-none center gap-2">
              <QuantityCoinComponent quantity={Number(crypto?.lastQuote?.price || 0)}/>
            </div>

            <div className="justify-center items-center gap-2 inline-flex">

              <div className="grow shrink basis-0 justify-end items-center gap-0.5 flex">
                <div className="w-2 h-2 pt-0.5 flex-col justify-start items-center inline-flex"/>
                <div className="text-white text-opacity-80 text-sm font-bold ">
                  {formatGainPercent(pricePerf)}
                </div>
              </div>

              <div className="justify-center items-center gap-0.5 flex">
                <div className="text-white text-opacity-30 text-sm font-bold ">|</div>
              </div>

              <div className="text-white text-opacity-80 text-sm font-bold ">
                {(formatBalanceNumber(priceDiff))}
              </div>
            </div>
          </div>

          <div className={"w-full"}>
            <AreaChart areSeriesLoading={arePricesLoading} heightPx={150} series={[{
              name: 'Prices',
              data: arePricesLoading ? [] : seriesData,
            }]}/>
          </div>
        </>
      }
    </>
  );
};

export {
  CryptoChartComponent
}