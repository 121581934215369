import React, {useEffect, useMemo} from 'react';
import {SignupComponent} from "../components/auth/signup-form/signup.component";
import {AuthNav} from "../components/auth/auth-navigation.component";
import {BkTanjiIcon} from "../components/ui/bk-tanji-icon";
import UnauthenticatedLayout from "../UnauthenticatedLayout";

const SignupPage: React.FC = () => {

  const ref = useMemo<string>(() => new URLSearchParams(window.location.search).get('tbrefid') || '', []);
  useEffect(() => {
    if (ref && ref.length) {
      document.cookie = `tbrefid=${ref}`;
    }
  }, [ref]);

  return (<>
      <div className={"fixed w-screen h-screen bg-orange z-0 flex justify-end items-center"}><BkTanjiIcon factor={1.3}/>
      </div>
      <UnauthenticatedLayout>
        <AuthNav/>
        <div className="flex w-full justify-center items-center">
          <SignupComponent referrerId={ref}/>
        </div>
      </UnauthenticatedLayout></>
  );
};

export {SignupPage};