import toast from "react-hot-toast";

export function toastMessage(message:string){
  toast.dismiss();
  toast(message);
}

export function toastSuccess(message:string){
  toast.dismiss();
  toast.success(message);
}

export function toastError(message:string){
  toast.dismiss();
  toast.error(message);
}
