import React, {useEffect, useRef, useState} from "react";
import {useGetUserQuery, useGetXpQuery} from "../../apis/users.api";
import {CoinIcon} from "../ui/coin-icon";
import {useGetMoneyAmountQuery} from "../../apis/game-income.api";
import {formatBalanceNumber} from "../../utils/Helper";
import {GemsIcon} from "../ui/gems-icon";
import AnimatedGemCounter from "../ui/animated-gem-counter.component";

const ProfileDetail: React.FC = () => {
  const {data: user} = useGetUserQuery();
  const {data: userXp} = useGetXpQuery();
  const {data: money} = useGetMoneyAmountQuery();
  const [animMoney, setAnimMoney] = useState(false);
  const prevCountRef = useRef<{ amount: number } | undefined>(money);
  const [gems, setGems] = useState(user?.gemsAmount);

  useEffect(() => {
    if (money && prevCountRef.current && prevCountRef.current?.amount !== money.amount) {
      setAnimMoney(true);
      setTimeout(() => {
        setAnimMoney(false);
      }, 500)
    }
    prevCountRef.current = money;
  }, [money]);

  useEffect(() => {
    if(user){
      setGems(user.gemsAmount);
    }
  }, [user]);

  return (
    <div className={"flex flex-col items-strech text-xs xl:text-sm gap-y-1 min-w-12"}>

      {money &&
        <div
          className={`${animMoney ? 'animate-ping' : ''} h-[21px] px-1.5 py-0.5 bg-black bg-opacity-30 rounded border border-black border-opacity-10 justify-start items-center gap-1 inline-flex`}>
          <CoinIcon h={12}/>
          <span className={"text-white text-sm font-bold"}>{money ? formatBalanceNumber(money.amount) : 0}</span>
        </div>
      }

      <div className={"h-[21px] px-1.5 py-0.5 bg-black bg-opacity-30 rounded border border-black border-opacity-10 justify-start items-center gap-1 inline-flex"}>
        <AnimatedGemCounter amount={gems ? gems : 0}/>
      </div>

    </div>
  )
}

export {ProfileDetail};

