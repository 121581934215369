import React from 'react';

const AchievementIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.9014 3.90091C13.9614 3.74774 13.9996 3.58468 13.9996 3.41174C13.9996 2.63246 13.3276 2 12.4997 2H7.49958C6.67085 2 5.99963 2.63246 5.99963 3.41174C5.99963 3.58468 6.03712 3.74774 6.09787 3.90091C3.65445 5.18983 2 7.64698 2 10.4705C2 14.6288 5.58114 18 10 18C14.4181 18 18 14.6288 18 10.4705C17.9993 7.64698 16.3456 5.18983 13.9014 3.90091Z" fill="#FFAC33"/>
<path d="M9.99981 16.1174C13.3134 16.1174 15.9996 13.5892 15.9996 10.4704C15.9996 7.35169 13.3134 4.82346 9.99981 4.82346C6.68621 4.82346 4 7.35169 4 10.4704C4 13.5892 6.68621 16.1174 9.99981 16.1174Z" fill="#FFD983"/>
<path d="M12.4152 14.2123C12.3087 14.2123 12.2022 14.1812 12.1107 14.1198L9.95673 12.6664L7.80355 14.1198C7.6198 14.2433 7.37381 14.2433 7.19157 14.1177C7.00932 13.9934 6.93282 13.7725 7.00032 13.5713L7.80355 11.1318L5.66986 9.71657C5.48837 9.59092 5.41337 9.36928 5.48237 9.1674C5.55212 8.96623 5.75086 8.82929 5.9766 8.82788L8.61952 8.82435L9.461 6.44556C9.53224 6.24438 9.73174 6.10886 9.95748 6.10886C10.1825 6.10886 10.3827 6.24438 10.454 6.44556L11.2812 8.82435L13.9376 8.82788C14.1633 8.82929 14.3628 8.96623 14.4318 9.1674C14.5016 9.36928 14.4258 9.59022 14.2443 9.71657L12.1099 11.1318L12.9131 13.5713C12.9821 13.7732 12.9041 13.9934 12.7226 14.1177C12.6304 14.1812 12.5224 14.2123 12.4152 14.2123Z" fill="#FFAC33"/>
</svg>


);

export default AchievementIcon;
