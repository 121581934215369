import React from "react";
import {Link} from "react-router-dom";

const TanjiLogo: React.FC = () => {

  return (
    <>
      <Link to="/tournament">
        <img
          className="block lg:hidden h-10 w-auto"
          src={process.env.REACT_APP_STATIC_ASSETS+"/tanjiblocks-logo.svg"}
          alt="tanjiblocks logo"
        />
        <img
          className="hidden lg:block h-12 w-auto"
          src={process.env.REACT_APP_STATIC_ASSETS+"/tanjiblocks-logo.svg"}
          alt="tanjiblocks logo"
        />
      </Link>
    </>
  )
}

export {TanjiLogo};

