import React from "react";


const Podium1stIcon: React.FC<{ h?: number }> = ({h = 24}) => 

<svg width="106" height="108" viewBox="0 0 106 108" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.678 0L0 8H106L94.322 0H11.678Z" fill="white" fillOpacity="0.8"/>
<path d="M106 100H0V108H106V100Z" fill="white"/>
<path d="M106 104H0V8H106V104Z" fill="white"/>
<path d="M51.0169 71.2H44.2969V65.4H57.2169V95H51.0169V71.2Z" fill="#EA5C28"/>
</svg>;

export {Podium1stIcon};