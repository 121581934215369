import React from "react";


const ChartRedIcon: React.FC<{ h?: number }> = ({h = 17}) => <svg width={h} height={h} viewBox="0 0 20 21" fill="none"
                                                                  xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_6569_60127)">
      <rect x="1" y="1" width="18" height="18" rx="3" fill="#EA5C27" shapeRendering="crispEdges"/>
      <rect x="0.5" y="0.5" width="19" height="19" rx="3.5" stroke="#BA481E" shapeRendering="crispEdges"/>
      <path
        d="M3.49897 17.5C3.33897 17.5 3.17647 17.462 3.02597 17.3805C2.53947 17.119 2.35797 16.5125 2.61947 16.026L6.11947 9.52598C6.26897 9.24748 6.54197 9.05648 6.85447 9.01048C7.16747 8.96448 7.48347 9.06948 7.70697 9.29298L10.745 12.331L15.614 3.03598C15.87 2.54698 16.4745 2.35748 16.9635 2.61448C17.453 2.87048 17.6415 3.47498 17.3855 3.96448L11.8855 14.4645C11.738 14.7465 11.465 14.941 11.1505 14.9895C10.837 15.035 10.5175 14.933 10.2925 14.708L7.24247 11.6585L4.38047 16.974C4.19997 17.3095 3.85497 17.5 3.49897 17.5Z"
        fill="white"/>
    </g>
    <defs>
      <filter id="filter0_d_6569_60127" x="0" y="0" width="20" height="20.5" filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                       result="hardAlpha"/>
        <feOffset dy="0.5"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.729412 0 0 0 0 0.282353 0 0 0 0 0.117647 0 0 0 1 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6569_60127"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6569_60127" result="shape"/>
      </filter>
    </defs>
  </svg>

;

export {ChartRedIcon};