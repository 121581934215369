import React from 'react';
import {useGetUserQuery, usersApi} from '../../apis/users.api';
import {FileUploadComponent} from "../ui/upload/file-upload.component";
import {CloudArrowUpIcon} from "../ui/upload-icon";
import {DiscordRoleComponent} from "./discord-role.component";
import {UserXpComponent} from "./user-xp.component";
import {PlayerItem} from "../../models/User";
import {UserGemsComponent} from './user-gems.component';
import {useAppDispatch} from "../../app/hooks";
import {useNavigate} from "react-router-dom";
import {GearIcon} from '../ui/gear-icon';
import {DiscordFollow} from "../ui/app-footer.component";

const UserInfosComponent: React.FC<{ player?: PlayerItem }> = ({player}) => {
  const dispatch = useAppDispatch();
  const {data: user} = useGetUserQuery();
  const navigate = useNavigate();
  return <>        {player &&
    <div className={"flat-card gap-y-2 w-full"}>

      <div className='flex gap-2 sm:gap-4'>

        <div className='flex-none center'>

          <div
            className={"flat-card rounded-lg !p-0 overflow-hidden h-[90px] w-[90px] sm:h-[120px] sm:w-[120px] self-center flex items-center"}>

            {user && player.pseudo === user.pseudo && player.userId === user._id &&
              <FileUploadComponent id={"onboarding_profile_img"}
                                   api={"/users/uploadProfilePicture"}
                                   onUploadSuccess={(res: any) => {
                                     dispatch(usersApi.util.invalidateTags([{type: 'User'}]));
                                   }}>
                <>
                  <img
                    src={user.avatarUrl ? (user.avatarUrl + '?' + new Date().getTime()) : `${process.env.REACT_APP_STATIC_ASSETS}/favicon.svg`}
                    className="object-cover w-full h-full"/>
                  <div
                    className={"absolute rounded-lg left-0 top-0 w-full h-full bg-black bg-opacity-30 hover:bg-opacity-20"}>
                          <span className={"absolute left-1/2 top-1/2 -mt-[10px] -ml-[10px]"}>
                            <CloudArrowUpIcon h={20}/>
                          </span>
                  </div>
                </>

              </FileUploadComponent>
            }
            {user && player.pseudo !== user.pseudo &&
              <img src={player.avatarUrl || `${process.env.REACT_APP_STATIC_ASSETS}/favicon.svg`}
                   className="rounded-lg object-cover h-full w-full bg-white"/>
            }

          </div>

        </div>

        <div className='flex grow flex-col overflow-hidden text-ellipsis gap-2 justify-between'>

          <div className=''>

            <div className='flex justify-between gap-1'>

              <p className='text-ellipsis overflow-hidden text-lg font-bold self-center'>
                {player.pseudo}
              </p>

              <div className={`inline-flex center ${player.userId === user?._id ? '' : '!hidden'}`}
                   onClick={() => navigate('/profileDetails')}>
                <GearIcon className="stroke-orange" h={25}/>
              </div>

            </div>

          </div>

          {player.discordRole &&
            <div className="flex items-center">
              <DiscordRoleComponent discordRole={player.discordRole}/>
            </div>
          }

          {!player.discordRole &&
            <DiscordFollow/>
          }

          <div className={`flex items-center gap-x-4 ${player.userId === user?._id ? '' : 'hidden'}`}>
            <UserXpComponent xp={player.xp}/>
            <UserGemsComponent gemsAmount={user?.gemsAmount || 0}/>
          </div>

          <div className={`flex items-center gap-x-4 ${player.userId === user?._id ? 'hidden' : ''}`}>
            <UserXpComponent xp={player.xp}/>
            <UserGemsComponent gemsAmount={player.gemsAmount}/>
          </div>

        </div>

      </div>

      <div
        className={`orange-button text-white text-xs uppercase font-bold w-full col-span-2 h-12 mt-4 ${player.userId === user?._id ? '' : '!hidden'}`}
        onClick={() => navigate('/referral-rewards')}
      > Invite Friends
      </div>

    </div>
  }</>
}

export {UserInfosComponent};