import { ShopProduct } from "../../../models/shop/Product";
import DefaultSuccessCheckout from "./default-success-checkout-content.component";

interface StripeSuccessCheckoutProps {
  product: ShopProduct | undefined
}

const StripeSuccessCheckout: React.FC<StripeSuccessCheckoutProps> = ({product}) => {

  if(product){
    return (
      <>

        <img className={"w-[130px] h-auto"}
          src={`${process.env.REACT_APP_STATIC_ASSETS}/images/${product.image1}`}
          alt={`${product.code} product image`} />

        <p className={"rounded-lg bg-black bg-opacity-20 text-white font-bold px-2 py-2 text-lg text-center"}>
          Success!
        </p>

        <p className={"text-white text-center text-sm"}>
          You just bought {product.quantity} gems <br />
          to use in the game
        </p>
      </>
    );
  } else {
    return (
    <DefaultSuccessCheckout/>
  )
  }

}

export default StripeSuccessCheckout;