import React from 'react';
import { BackButton } from "./back-button";

export type AppDialogProps = {
  children: JSX.Element | JSX.Element[],
  backPath?: string,
  pageTitle: string;
  backCallback?: Function,
}
const InnerHeader: React.FC<AppDialogProps> = ({
  backPath, pageTitle,
  children, backCallback,
}) => {

  return (
    <div className={"w-full md:max-w-3xl"}>
      
      <div className="absolute">
        <BackButton label={""} path={backPath} callback={backCallback} />
      </div>
      <div className={"flex w-full gap-x-2 center"}>
        
        <div className="h-8 justify-between items-center inline-flex ">
          <div className="h-full flex items-center px-8 py-1.5 rounded-md justify-center text-white">
            <h2 className={"w-full font-bold text-sm text-white"}>{pageTitle}</h2>
          </div>
        </div>
      </div>
      {children}
    </div>);
};

export { InnerHeader };