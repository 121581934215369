import React from 'react';
import Layout from "../../Layout";
import { useGetTournamentQuery } from "../../apis/tournaments.api";
import { SwapComponent } from "../../components/squad/swap.component";
import { useLocation } from "react-router";
import { InnerHeader } from "../../components/ui/app-inner-header.component";


const SwapPage: React.FC = () => {
  const { state } = useLocation();
  const { tournamentId, actionCode, actionLabel, actionType, cryptoSrcId, cryptoDestId } = state;
  const { data: tournament } = useGetTournamentQuery(tournamentId, { skip: !tournamentId });


  return (
    <Layout>
      <div className="w-screen min-h-screen absolute top-0 flex flex-col bg-gradient-to-b from-orange-700 to-orange items-center">
        <div className="w-full h-screen max-w-md flex flex-col justify-between pt-20 px-4">
          <InnerHeader pageTitle={actionLabel}>
          </InnerHeader>

          <div className={"w-full flex flex-col max-w-md pb-16 bg-white rounded-tl-3xl rounded-tr-3xl mt-2"}>
            {tournament && actionCode &&
              <SwapComponent tournament={tournament} cryptoSrcId={cryptoSrcId} cryptoDestId={cryptoDestId} />
            }
          </div>
        </div>
      </div>
    </Layout>
  );
};

export { SwapPage }
