import React from "react";


const BetsIcon: React.FC<{ h?: number,color?:string }> = ({h = 17,color='black'}) => <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" stroke={`${color}`} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M10 14C12.2091 14 14 12.2091 14 10C14 7.79086 12.2091 6 10 6C7.79086 6 6 7.79086 6 10C6 12.2091 7.79086 14 10 14Z" stroke={`${color}`} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M10 6V3" stroke={`${color}`} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M6.90627 6.90624L4.6969 4.69687" stroke={`${color}`} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M5.625 10H2.5" stroke={`${color}`} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M6.90627 13.0938L4.6969 15.3031" stroke={`${color}`} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M10 14.375V17.5" stroke={`${color}`} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M13.0938 13.0938L15.3031 15.3031" stroke={`${color}`} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M14.375 10H17.5" stroke={`${color}`} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M13 7L15 5" stroke={`${color}`} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
;

export {BetsIcon};