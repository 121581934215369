import { useEffect, useState } from "react";
import { DateTime } from "luxon";
import TimerIcon from "../svg/TimerIcon";

interface DailyTimerProps {

}

export function getRemainingTime(){
  const now = DateTime.now();
  const endOfToday = now.endOf("day");
  const remainingTime = endOfToday.minus({hours: now.hour, minutes:now.minute, seconds:now.second});
  return remainingTime;
}

const DailyTimer: React.FC<DailyTimerProps> = () => {

  const [remainingTime, setRemainingTime] = useState<DateTime>(getRemainingTime());

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime(getRemainingTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [remainingTime]);

  return (
    <Timer time={remainingTime.toFormat("hh:mm:ss")}/>
   );
}

export const DailyCountdown: React.FC = () => {

  const [remainingTime, setRemainingTime] = useState<DateTime>(getRemainingTime());

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime(getRemainingTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [remainingTime]);

  return (
    <span>
      {remainingTime.toFormat("hh:mm:ss")}
    </span>
  );

}

interface TimerProps {
  time: string
}

const Timer: React.FC<TimerProps> = ({time}) => {
  return (

    <div className="relative bg-teal-500 rounded-lg border-2 border-white justify-center items-center gap-3 inline-flex pl-8 pr-2 w-56">

      <div className="absolute -left-8 -top-4">
        <TimerIcon/>
      </div>

      <p className="text-white text-3xl font-normal">
        {time}
      </p>

    </div>

   );
}



export default DailyTimer;