import React from "react";
import {useNavigate} from "react-router-dom";
import {getTanjiCryptoCurrency} from "../../models/Wallet";

interface ActionButtonsProps {
  tournamentId: string;
  cryptoId?: string,
  hideButtons?: string[]; // new prop
  onClick?: Function;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({
                                                       tournamentId, cryptoId, hideButtons = [], onClick = () => {
  }
                                                     }) => {
  const navigate = useNavigate();

  return (
    <div className="w-full px-6 justify-start items-start gap-1 inline-flex">
      {!hideButtons.includes('Buy') && (
        <div className="grow shrink basis-0 flex-col justify-center items-center gap-3 inline-flex"
             onClick={() => {
               onClick('Buy');
               navigate('/squad-roi-swapping', {
                 state: {
                   tournamentId,
                   cryptoSrcId: getTanjiCryptoCurrency()._id,
                   cryptoDestId: cryptoId,
                   actionCode: 'Buy',
                   actionLabel: 'Buy',
                   actionType: '',
                 }
               })
             }}>
          <div
            className="w-12 h-12 px-4 py-2 bg-orange rounded-lg fat-orange-shadow-4 border-l-2 border-r-2 border-t-2 border-orange-700 justify-center items-center gap-2 inline-flex">
            <div className="w-4 h-4 flex center">
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_2424_7328)">
                  <path d="M1 7H13" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M7 1V13" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <defs>
                  <clipPath id="clip0_2424_7328">
                    <rect width="14" height="14" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          <div className="self-stretch text-center text-white text-sm font-bold font-['Satoshi']">Buy</div>
        </div>
      )}
      {!hideButtons.includes('Sell') && (
        <div className="grow shrink basis-0 flex-col justify-center items-center gap-3 inline-flex"
             onClick={() => {
               onClick('Sell');
               navigate('/squad-roi-swapping', {
                 state: {
                   cryptoSrcId: cryptoId,
                   cryptoDestId: getTanjiCryptoCurrency()._id,
                   tournamentId,
                   actionCode: 'SIMPLE_SWAP',
                   actionLabel: 'Sell',
                   actionType: '',
                 }
               })
             }}>
          <div
            className="w-12 h-12 px-4 py-2 bg-orange rounded-lg fat-orange-shadow-4 border-l-2 border-r-2 border-t-2 border-orange-700 justify-center items-center gap-2 inline-flex">
            <div className="w-4 h-4 flex center">
              <svg width="14" height="2" viewBox="0 0 14 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_2424_7333)">
                  <path d="M1 1H13" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <defs>
                  <clipPath id="clip0_2424_7333">
                    <rect width="14" height="2" fill="white"/>
                  </clipPath>
                </defs>
              </svg>

            </div>
          </div>
          <div className="self-stretch text-center text-white text-sm font-bold font-['Satoshi']">Sell</div>
        </div>
      )}
      {!hideButtons.includes('Swap') && (
        <div className="grow shrink basis-0 flex-col justify-center items-center gap-3 inline-flex"
             onClick={() => {
               onClick('Swap');
               navigate('/squad-roi-swapping', {
                 state: {
                   cryptoSrcId: cryptoId,
                   tournamentId,
                   actionCode: 'SIMPLE_SWAP',
                   actionLabel: 'Swap',
                   actionType: '',
                 }
               })
             }}>
          <div
            className="w-12 h-12 px-4 py-2 bg-orange rounded-lg fat-orange-shadow-4 border-l-2 border-r-2 border-t-2 border-orange-700 justify-center items-center gap-2 inline-flex">
            <div className="w-4 h-4 flex center">
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_2424_7341)">
                  <path d="M2.63635 2.63636C2.63635 2.63636 4.27272 1 6.99999 1C10.8182 1 13 4.81818 13 4.81818"
                        stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M11.3636 11.3636C11.3636 11.3636 9.72727 13 7 13C3.18182 13 1 9.1818 1 9.1818" stroke="white"
                        strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M9.72723 4.81824H13V1.54551" stroke="white" strokeWidth="2" strokeLinecap="round"
                        strokeLinejoin="round"/>
                  <path d="M4.27273 9.1818H1V12.4545" stroke="white" strokeWidth="2" strokeLinecap="round"
                        strokeLinejoin="round"/>
                </g>
                <defs>
                  <clipPath id="clip0_2424_7341">
                    <rect width="14" height="14" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          <div className="self-stretch text-center text-white text-sm font-bold font-['Satoshi']">Swap</div>
        </div>
      )}
      {/*{!hideButtons.includes('Skills') && (*/}
      {/*  <div className="grow shrink basis-0 flex-col justify-center items-center gap-3 inline-flex" onClick={()=>{*/}
      {/*    onClick('Skills');*/}
      {/*  }}>*/}
      {/*    <div*/}
      {/*      className="w-12 h-12 px-4 py-2 bg-orange rounded-lg fat-orange-shadow-4 border-l-2 border-r-2 border-t-2 border-orange-700 justify-center items-center gap-2 inline-flex">*/}
      {/*      <div className="w-4 h-4 flex center">*/}
      {/*        <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
      {/*          <g clipPath="url(#clip0_2424_7371)">*/}
      {/*            <path d="M12.5 7V10" stroke="white" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>*/}
      {/*            <path d="M11 8.5H14" stroke="white" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>*/}
      {/*            <path d="M4 1.5V4.5" stroke="white" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>*/}
      {/*            <path d="M2.5 3H5.5" stroke="white" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>*/}
      {/*            <path d="M9.5 10.5V12.5" stroke="white" strokeWidth="1.3" strokeLinecap="round"*/}
      {/*                  strokeLinejoin="round"/>*/}
      {/*            <path d="M8.5 11.5H10.5" stroke="white" strokeWidth="1.3" strokeLinecap="round"*/}
      {/*                  strokeLinejoin="round"/>*/}
      {/*            <path d="M8 4L10 6" stroke="white" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>*/}
      {/*            <path*/}
      {/*              d="M10.3534 1.64632L1.64629 10.3535C1.45103 10.5487 1.45103 10.8653 1.64629 11.0606L2.93897 12.3532C3.13423 12.5485 3.45081 12.5485 3.64607 12.3532L12.3532 3.6461C12.5485 3.45084 12.5485 3.13426 12.3532 2.93899L11.0605 1.64632C10.8653 1.45105 10.5487 1.45105 10.3534 1.64632Z"*/}
      {/*              stroke="white" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>*/}
      {/*          </g>*/}
      {/*          <defs>*/}
      {/*            <clipPath id="clip0_2424_7371">*/}
      {/*              <rect width="15" height="14" fill="white"/>*/}
      {/*            </clipPath>*/}
      {/*          </defs>*/}
      {/*        </svg>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className="self-stretch text-center text-white text-sm font-bold font-['Satoshi']">Skills</div>*/}
      {/*  </div>*/}
      {/*)}*/}
    </div>
  )
}

export {ActionButtons};

