import React from 'react'
import {AlertTitle, Snackbar} from "@mui/material";
import Alert from "@mui/material/Alert";
import {getBottomAlertSx} from "../ui/MuiStyle";
import {useNavigate} from "react-router-dom";

export type Props = {
  onClose: Function
}

const GemsAlertSnackbar: React.FC<Props> = ({
                                              onClose
                                            }) => {
  const navigate = useNavigate();
  return <Snackbar sx={{bottom: '75px!important'}} anchorOrigin={{vertical: 'bottom', horizontal: 'center'}} open={true}
                   autoHideDuration={6000} onClose={() => {
    onClose()
  }}>
    <Alert className={"flat-card"} sx={getBottomAlertSx()} severity="error" action={
      <button className={"primary"} onClick={() => navigate('/store')}>
        SHOP
      </button>
    }>
      <AlertTitle>Oops</AlertTitle>
      <span className={""}>You don’t have enough gems to boost your bet.</span>
    </Alert>
  </Snackbar>
}

const PowerUpAlertSnackbar: React.FC<Props> = ({
                                                onClose
                                              }) => {
  const navigate = useNavigate();
  return <Snackbar sx={{bottom: '75px!important'}} anchorOrigin={{vertical: 'bottom', horizontal: 'center'}} open={true}
                   autoHideDuration={6000} onClose={() => {
    onClose()
  }}>
    <Alert className={"flat-card"} sx={getBottomAlertSx()} severity="error" action={
      <button className={"primary"} onClick={() => navigate('/store#treasures',{state:{scrollToId:'power_ups'}})}>
        SHOP
      </button>
    }>
      <AlertTitle>Oops</AlertTitle>
      <span className={""}>You don’t have any shields in your inventory yet.</span>
    </Alert>
  </Snackbar>
}

export {GemsAlertSnackbar, PowerUpAlertSnackbar};