import React from "react";
import {CoinIcon} from "./coin-icon";
import {classNames, formatBalanceNumber} from "../../utils/Helper";

type Props = {
  quantity: number,
  size?: number,
  small?: boolean,
  big?: boolean,
  textCssSize?: string,
};

const QuantityCoinComponent: React.FC<Props> = ({quantity, size, small, big, textCssSize}) => {
  return (
    <p className={classNames("flex !text-inherit items-center")}>
      <CoinIcon h={size}/>
      <span
        className={`select-none mx-1 !text-inherit font-bold ${small ? 'text-xs text-grey-700' : ''} ${big ? 'text-xl' : ''} ${textCssSize ? textCssSize : ''}`}>
        {formatBalanceNumber(quantity)}
      </span>
    </p>
  )
}

export {QuantityCoinComponent};

