import React from 'react'
import {WeekResultComponent} from "./week-result.component";
import QRCode from "react-qr-code";
import {PlayerItem} from "../../models/User";

export interface WeekResultProps {
  player: PlayerItem
}

const SnapshotLargeWeekResult: React.FC<WeekResultProps> = ({player}) => {
  return <div id={"week-snap-landscape"}
              className={`w-full h-full relative items-center gap-y-5 bg-gradient-to-b from-orange-300 to-orange-700 pt-2`}>

    <div className={"absolute left-8 top-8"}>
      <img crossOrigin={"anonymous"} className={"z-10 w-24 relative"} src={`${process.env.REACT_APP_STATIC_ASSETS}/tanjiblocks-logo.png`}/>
    </div>

    <div className={"py-6 px-6 w-full flex flex-col items-center justify-center"}>

      <div className={"w-[40%]"}><WeekResultComponent player={player}/></div>

      <div
        className={"mt-6 z-20 relative bg-orange-700 text-white rounded-2xl flex justify-between items-center text-xs gap-x-4 p-4"}>
        <div className={"text-inherit"}>
          <span className={"text-inherit"}>Scan QR code to Register and get a 2000 coins new-user bonus!</span>
        </div>
        <div className={"text-inherit"}>
          <div className={"bg-white"}>
            <QRCode value={`https://app.tanjiblocks.com?tbrefid=${player?.userId}`} size={56} fgColor={'#BA481E'}/>
          </div>
        </div>
      </div>
    </div>

    <div className={"absolute bottom-0 flex w-full"}>
      <div className={"grow"}>
        <img crossOrigin={"anonymous"} className={"object-cover"}
             src={`${process.env.REACT_APP_STATIC_ASSETS}/images/floor-of-tanji.png`}/>
      </div>
      <div className={"grow"}>
        <img crossOrigin={"anonymous"} className={"object-cover"}
             src={`${process.env.REACT_APP_STATIC_ASSETS}/images/floor-of-tanji.png`}/>
      </div>

    </div>

  </div>
};
export {SnapshotLargeWeekResult};