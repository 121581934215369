import React from "react";
import {classNames} from "../../utils/Helper";
import {ChartIcon} from "./chart-icon";

type Props = {
  size?: number,
  css?: string | string[],
  label: string,
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
};

const SecondarySearchButton: React.FC<Props> = ({label, size = 12, css = '', onClick}) => {
  return (
    <button
      className={classNames("w-full white flex justify-center !text-inherit", css)}
      onClick={onClick}>

      <span className="uppercase mr-1 !text-inherit">{label}</span>
      <ChartIcon h={size}/>

    </button>
  )
}

export {SecondarySearchButton};

