import React, {useEffect} from 'react';
import Layout from "../Layout";
import {usersApi} from "../apis/users.api";
import 'react-confirm-alert/src/react-confirm-alert.css';
import {classNames} from "../utils/Helper";
import {SecondaryNavigation} from "../components/ui/secondary-navigation.component";
import {InfiniteCollectibleComponent} from "../components/collectibles/infinite_collectibles.component";
import {useNavigate} from "react-router-dom";
import {useLocation} from "react-router";
import {useAppDispatch} from "../app/hooks";
import {usePlayerFromPseudoParameter} from "../hooks/usePseudoParam";
import {UserInfosComponent} from "../components/users/user-infos.component";
import CollectibleInfo from '../components/ui/collectible-info.component';
import {BackButton} from "../components/ui/back-button";
import {useGetCollectibleQuery} from "../apis/collectibles.api";


const ProfileCollectiblesPage: React.FC = () => {
  const navigate = useNavigate();
  const {state} = useLocation();
  const dispatch = useAppDispatch();
  const {data: collectibleToDisplay} = useGetCollectibleQuery(state?.cryptoId, {skip: !state?.cryptoId});
  const {player, isConnectedPlayer} = usePlayerFromPseudoParameter();
  useEffect(() => {
    // console.log(`FORCE REFETCH <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< player = ${player?.pseudo} / id = ${player?.userId}`)
    dispatch(usersApi.util.invalidateTags([{type: 'InfiniteCollectibleList'}]));
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>

      <div
        className="fixed top-10 w-screen flex flex-col bg-gradient-to-b from-orange-700 to-orange pt-12 p-4 center pb-16">

        <div className={classNames("flex justify-between w-full max-w-xl pb-4 mt-1 center")}>

          <BackButton label={""} callback={() => navigate(-1)}/>

          <SecondaryNavigation navItems={[
            {matchPath: '/collectibles', path: `/collectibles/${player?.pseudo || ''}`, name: 'Collectibles'},
            {matchPath: '/stats', path: `/stats/${player?.pseudo || ''}`, name: 'Statistics'},
          ]}/>

          <div>
            <CollectibleInfo/>
          </div>

        </div>

      </div>

      <div className='container mt-12 z-20 max-w-xl'>

        <div className={"w-full"}>
          <UserInfosComponent player={player}/>
        </div>

        <InfiniteCollectibleComponent userId={player?.userId} classes={"w-full mt-2"}
                                      shinyCollectibles={state?.upgradeCollectibleId ? [state.upgradeCollectibleId as string] : []}
                                      mode={"all"} collectibleToDisplay={collectibleToDisplay}/>
      </div>

    </Layout>
  );
};

export {ProfileCollectiblesPage};