import React from "react";
import { GemsIcon } from "./gems-icon";

type GemsAmountComponentProps = {
  quantity: number; size?: number,
};

const GemsAmountComponent: React.FC<GemsAmountComponentProps> = ({quantity, size="w-4"}) => {
  return (
    <div className={"flex text-inherit items-center bg-white px-2 py-1 gap-2 rounded"}>
      <span className={"select-none text-inherit font-bold"}>{quantity}</span>
      <GemsIcon h={16}/>
    </div>
  )
}

export {GemsAmountComponent};

