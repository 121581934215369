import React, {useState} from "react";
import {useGetUserQuery} from "../../apis/users.api";
import {createColumnHelper, flexRender, getCoreRowModel, PaginationState, useReactTable} from "@tanstack/react-table";
import {PlayerItem} from "../../models/User";
import {useGetTournamentQuery, useGetTournamentRankingQuery} from "../../apis/tournaments.api";
import {classNames, getSafeStaticAssetUri} from "../../utils/Helper";
import {ListLoaderComponent} from "../ui/list-loader.component";
import {GainCoinComponent} from "../ui/gain-coin.component";
import {QuantityCoinComponent} from "../ui/quantity-coin.component";
import {useNavigate} from "react-router-dom";
import {hasTournamentEnded, isTournamentRunning} from "../../models/Tournament";
import {useGetPortfolioCollectibleRewardRankQuery} from "../../apis/game.api";
import {CollectibleRewardComponent} from "../users/collectible-reward.component";

const columnHelper = createColumnHelper<PlayerItem>();

export type Props = {
  tournamentId?: string,
  tournamentType?: string,
}

const getUserPlayingThemeIcon = (playerItem: PlayerItem): string | null => {
  let res = null;
  if (playerItem.metadata && playerItem.metadata.logo) {
    res = getSafeStaticAssetUri(playerItem.metadata.logo);
  }
  return res;
}

const TournamentRankingList: React.FC<Props> = ({ tournamentType, tournamentId }) => {
  const { data: user } = useGetUserQuery();
  const { data: tournament } = useGetTournamentQuery(tournamentId);
  const {data: collectibleRewardRank} = useGetPortfolioCollectibleRewardRankQuery();
  const columns = [
    columnHelper.accessor(row => row.rank, {
      id: 'rank',
      cell: info => {
        const rank = info.getValue();
        let className = "w-6 h-6 p-2 md:w-8 md:h-8 rounded-full flex-col justify-center items-center inline-flex";
        return (
          <span className={className}>
            <span className=" text-sm font-bold">{rank}</span>
          </span>
        );
      },
      header: () => <span className={"font-medium text-left w-full flex  mr-4"}>#</span>,
      footer: info => info.column.id,
    }),
    columnHelper.accessor(row => ({
      avatarUrl: row.avatarUrl,
      pseudo: row.pseudo,
      verified: row.verified,
      discordRole: row.discordRole
    }), {
      id: 'pseudo',
      cell: info => (
        <div className={"flex flex-row w-full h-full justify-start items-center gap-4"}>
          <span className={"w-8 h-8 p-0.5 bg-white rounded fat-grey-shadow-1 border border-neutral-200 justify-start items-start gap-4 inline-flex"}>
            <img src={info.row.original.avatarUrl || `${process.env.REACT_APP_STATIC_ASSETS}/favicon.svg`}
              className="w-full h-full rounded-sm bg-white" />
          </span>

          {collectibleRewardRank && Number(info.row.original.rank) <= collectibleRewardRank &&
            <CollectibleRewardComponent widthCss={"w-5"}/>
          }

          <div className="flex flex-col gap-0">
            <span className={"text-xs font-bold items-center flex w-full flex-row gap-4"}>
              <span className="flex w-full max-w-[160px] break-all overflow-hidden">{info.row.original.pseudo}</span>
            </span>
          </div>
        </div>
      ),
      header: () => <span className={"font-medium text-left w-full flex "}>User</span>,
      footer: info => info.column.id,
    }),

    columnHelper.accessor(row => ({ score: row.score, initialInvestment: row.initialInvestment }), {
      id: 'trades',
      cell: info => {
        const gain: number = info.row.original.score || 0;
        const invest: number = info.row.original.initialInvestment || 0;

        return <div className={"flex flex-col justify-end pr-2"}>
          <div className={"text-sm w-full flex justify-end"}>
            <QuantityCoinComponent size={12} quantity={parseFloat(invest.toFixed(1))} />
          </div>
          <div className={"w-full flex justify-end"}><GainCoinComponent gain={gain} size={0} /></div>
        </div>
      },
      header: () => <span className={"font-bold"}>Score</span>,
      footer: info => info.column.id,

    }),
  ]

  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: 0,
      pageSize: 50,
    })

  const { data: playersData, isLoading: arePlayersLoading } = useGetTournamentRankingQuery({
    tournamentType,
    tournamentId,
    pageIndex, pageSize
  });

  const [showAnalytics, setShowAnalytics] = useState<any>(null);

  const defaultData = React.useMemo(() => [], [])

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  )


  const table = useReactTable({
    data: playersData ? playersData.players : defaultData,
    columns,
    state: {
      pagination,
    },
    pageCount: (playersData?.totalPlayers) ? Math.ceil((playersData?.totalPlayers) / pageSize) : -1,
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: (data) => {
      window.scroll(0, 0);
      setPagination(data);
    },
    manualPagination: true,
  })

  const navigate = useNavigate();

  return (
    <div className={"w-full max-w-md text-xxs md:text-sm"}>

      {arePlayersLoading &&
        <ListLoaderComponent />
      }
      {!arePlayersLoading &&
        <table className={"w-full"}>
          <thead className={"hidden"}>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header, index, array) => (
                  <th key={header.id}
                    className={`${index === 0 ? 'pl-4 w-8' : ''} ${index === array.length - 1 ? 'pr-4 md:pr-8' : ''} border-b-[1px] border-gray-300`}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>


          <tbody>
            {table.getRowModel().rows.map(row => (
              <tr key={row.id}
                className={classNames(((user && (row.getValue('pseudo') as PlayerItem).pseudo === user.pseudo) ? '' : ''),
                  ' cursor-pointer relative',
                  getUserPlayingThemeIcon(row.original) ? '' : '')}
                onClick={() => {
                  if (tournament && (isTournamentRunning(tournament) || hasTournamentEnded(tournament))) {
                    setShowAnalytics({ pseudo: (row.getValue('pseudo') as PlayerItem).pseudo, tournamentId })
                    navigate('/portfolio', {
                      state: {
                        tournamentId,
                        pseudo: (row.getValue('pseudo') as PlayerItem).pseudo
                      }
                    });
                  }
                }}>
                {row.getVisibleCells().map((cell, index) => (
                  <td
                    className={`border-b-[1px] border-[#E6E6E6] max-w-[135px] overflow-hidden overflow-ellipsis text-xxs md:text-sm py-3 ${index === 0 ? 'pl-2 w-8' : ''}`}
                    key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>

        </table>
      }

      <div className="flex items-center justify-center gap-8 my-4">
        <button
          className="orange-button text-sm"
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}
        >
          {'<<'}
        </button>
        <button
          className="orange-button text-sm"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          {'<'}
        </button>
        <button
          className="orange-button text-sm"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          {'>'}
        </button>
        <button
          className="orange-button text-sm"
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
        >
          {'>>'}
        </button>
        {arePlayersLoading ? 'Loading...' : null}
      </div>
    </div>
  );
}

export { TournamentRankingList };

