import React from "react";


const GemsIcon: React.FC<{ h?: number }> = ({h = 24}) => <svg width={h} height={h} viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3095_17822)">
      <path d="M4.5 0.25H2.25L0 4H3.375L4.5 0.25Z" fill="#BDDDF4"/>
      <path d="M12 4L9.75 0.25H7.5L8.625 4H12Z" fill="#5DADEC"/>
      <path d="M8.625 4H12L6 10.75L8.625 4Z" fill="#4289C1"/>
      <path d="M3.375 4H0L6 10.75L3.375 4ZM4.5 0.25L3.375 4H8.625L7.5 0.25H4.5Z" fill="#8CCAF7"/>
      <path d="M6 10.75L3.375 4H8.625L6 10.75Z" fill="#5DADEC"/>
    </g>
    <defs>
      <clipPath id="clip0_3095_17822">
        <rect width="12" height="10.5" fill="white" transform="translate(0 0.25)"/>
      </clipPath>
    </defs>
  </svg>

;

export {GemsIcon};