import React from "react";


const EmailIcon: React.FC<{ h?: number }> = ({h = 24}) => <svg width={h} height={h} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M36 27C36 29.209 34.209 31 32 31H4C1.791 31 0 29.209 0 27V9C0 6.791 1.791 5 4 5H32C34.209 5 36 6.791 36 9V27Z" fill="#CCD6DD"/>
    <path d="M11.95 17.636L0.637026 28.949C0.610026 28.977 0.600026 29.012 0.577026 29.04C0.917026 29.61 1.39103 30.083 1.96103 30.424C1.99003 30.401 2.02403 30.391 2.05103 30.364L13.365 19.05C13.755 18.659 13.755 18.027 13.365 17.636C12.973 17.245 12.341 17.245 11.95 17.636ZM35.423 29.04C35.402 29.012 35.39 28.977 35.363 28.95L24.051 17.636C23.659 17.245 23.027 17.245 22.636 17.636C22.245 18.027 22.245 18.659 22.636 19.05L33.949 30.364C33.975 30.39 34.011 30.401 34.039 30.424C34.61 30.084 35.083 29.61 35.423 29.04Z" fill="#99AAB5"/>
    <path d="M32 5H4C1.791 5 0 6.791 0 9V10.03L14.528 24.526C16.422 26.419 19.516 26.419 21.412 24.526L36 10.009V9C36 6.791 34.209 5 32 5Z" fill="#99AAB5"/>
    <path d="M32 5H4.00003C2.41203 5 1.05103 5.934 0.405029 7.275L15.171 22.042C16.733 23.604 19.267 23.604 20.828 22.042L35.595 7.275C34.949 5.934 33.589 5 32 5Z" fill="#E1E8ED"/>
    <path d="M15 9.27C15 8.54 15.365 8 16 8H19.62C20.459 8 20.794 8.49 20.794 9C20.794 9.496 20.445 10 19.759 10H17.051V12H19.584C20.3 12 20.649 12.489 20.649 13C20.649 13.496 20.283 14 19.584 14H17.051V16H19.891C20.59 16 20.928 16.489 20.928 17C20.928 17.496 20.575 18 19.891 18H16.125C15.482 18 15 17.469 15 16.812V9.27Z" fill="#66757F"/>
  </svg>
;

export {EmailIcon};