import {createRef, useEffect, useRef, useState} from "react";
import ReferralFanCard from "./profile-referral-fan-card.component";
import ReferralReward, {RewardType} from "./profile-referral-rewards.component";
import {useGetReferralsDatasQuery} from "../../apis/users.api";
import FanReferralReward from "./profile-referral-fan-rewards.component";

interface RewardItem {
  type: RewardType,
  rewardAmount: number,
  goal: number
}

interface ReferalRewardsListProps {

}

const ReferalRewardsList: React.FC<ReferalRewardsListProps> = () => {

  const [rewardsListElement, setRewardsListElement] = useState<any>(null);

  const {data: referralDatas} = useGetReferralsDatasQuery();

  const scrollRef = useRef<HTMLDivElement | null>(null);
  const stepRef = createRef<HTMLDivElement | null>();

  const rewardsList: RewardItem[] = [{
    type: RewardType.coins,
    rewardAmount: 2000,
    goal: 3
  }, {
    type: RewardType.gems,
    rewardAmount: 10,
    goal: 5
  }, {
    type: RewardType.gems,
    rewardAmount: 50,
    goal: 10
  }, {
    type: RewardType.gems,
    rewardAmount: 200,
    goal: 20
  }];

  useEffect(() => {

    if (referralDatas) {


      const lastRewardedStepAmount = referralDatas.lastRewardedStep ? referralDatas.lastRewardedStep : 0;
      const totalReferredFriends = referralDatas.totalReferredFriends ? referralDatas.totalReferredFriends : 0;

      const lastRewardedStep = rewardsList.findIndex((reward: RewardItem) => {
        return lastRewardedStepAmount == reward.goal
      });

      const total = totalReferredFriends;
      const claimableStep = Math.min(lastRewardedStep + 1, rewardsList.length);
      const totalRewarded = lastRewardedStepAmount;

      setRewardsListElement(rewardsList.map((reward, index) =>
        <ReferralReward
          ref={index == claimableStep ? stepRef : null}
          key={index}
          step={index}
          type={reward.type}
          rewardAmount={reward.rewardAmount}
          goal={reward.goal}
          total={total}
          totalRewarded={totalRewarded}
          claimableStep={claimableStep}
        />
      ));

      setTimeout(() => {
        if (stepRef.current && claimableStep <= 4 && claimableStep > 0) {
          stepRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        } else if (scrollRef.current){
          scrollRef.current.scrollIntoView({ behavior: 'smooth',  block: 'start'  });
        }
      }, 10);

    }

  }, [referralDatas]);

  return (

    < div className={"flat-card mt-4 !p-4"}>

      <div className="flex flex-col">
        {rewardsListElement}
      </div>

      <div className={``}>
        { referralDatas &&
          <div
            className={`h-8 w-8 border-r  ${referralDatas.lastRewardedStep >= 20 ? 'border-r-orange' : 'border-r-grey-100'}`}>
          </div>
          }
      </div>

      <ReferralFanCard/>

      { referralDatas && referralDatas.lastRewardedStep >= 20 &&
        <div className="flex flex-col" ref={referralDatas.lastRewardedStep >= 20 ? scrollRef : null}>
          <FanReferralReward
            step={5}
            type={RewardType.gems}
            rewardAmount={(referralDatas.totalReferredFriends - referralDatas.lastRewardedStep) * 10}
            goal={(referralDatas.totalReferredFriends - referralDatas.lastRewardedStep)}
            total={referralDatas.totalReferredFriends}
            totalRewarded={referralDatas.lastRewardedStep}
          />
        </div>
      }

    </div>

  );
}

export default ReferalRewardsList;