import React, {useEffect} from 'react';
import {CollectibleCard} from "../../dto/collectibles/collectibles-list-response.dto";
import TemporaryDrawer from "../ui/temp-drawer.component";
import {BigCardItem} from "./big-card.component";
import {disableBodyScroll, enableBodyScroll} from "../../utils/HtmlHelper";

interface CollectibleDrawerProps {
  collectible: CollectibleCard,
  mode?: 'go_to_shop' | 'upgrade' | 'none',
  open: boolean,
  onClose: Function,
}

const CollectibleDrawer: React.FC<CollectibleDrawerProps> = ({
                                                               collectible,
                                                               mode = 'go_to_shop',
                                                               open,
                                                               onClose = () => {
                                                               },
                                                             }) => {

  useEffect(() => {
    return () => enableBodyScroll();
  }, []);

  return <TemporaryDrawer
    title={`${collectible.name} Card`}
    closeWhenClickingOutside={true}
    anchor={'bottom'}
    open={open}
    lineIcon={false}
    onOpen={() => {
      disableBodyScroll()
    }}
    onClose={() => {
      enableBodyScroll();
      onClose();
    }}
  >
    <div className={"w-full flex flex-col justify-center items-center py-2 overflow-y-auto"}>
      <BigCardItem collectible={collectible} mode={mode}/>
    </div>
  </TemporaryDrawer>;
}

export {CollectibleDrawer}