import React, {useEffect, useState} from 'react';
import Layout from "../Layout";
import {useNavigate, useParams} from "react-router-dom";
import {SecondaryNavigation} from "../components/ui/secondary-navigation.component";
import {TournamentViewComponent} from "../components/tournament/tournament-view.component";
import {useGetUserQuery} from "../apis/users.api";
import {InitialOnboardingDrawer} from "../components/tutorial/onboarding/initial-onboarding-drawer.component";
import {BackButton} from "../components/ui/back-button";
import {BetInfoComponent} from "../components/tournament/bet-info.component";


const TournamentPage: React.FC = () => {
  const [openOnboarding, setOpenOnboarding] = useState(false);
  const {data: user, isLoading: isUserLoading} = useGetUserQuery();
  const {range, type, limit} = useParams<{
    range: 'current' | 'previous',
    type: 'squad' | 'head_to_head',
    limit: string | undefined
  }>();

  useEffect(() => {
    const open = !isUserLoading && (!user?.onBoarding?.find(o => o.tag === 'initial') || !user?.onBoarding?.find(o => o.tag === 'initial')?.value);
    setOpenOnboarding(open);
  }, [user]);

  const navigate = useNavigate();

  return (
    <Layout>
      <div
        className="w-screen fixed top-12 pt-12 p-4 center pb-16 flex flex-col bg-gradient-to-b from-orange-700 to-orange ">
        <div className="pt-2 pb-2 w-full px-4 flex justify-center">

          <div className='flex justify-between center w-full max-w-md'>
            <span className={"invisible"}><BackButton label={""} callback={() => navigate(-1)}/></span>
            <SecondaryNavigation navItems={[
              {path: '/tournament/previous', name: 'Closed'},
              {path: '/tournament/current', name: 'Open'},
            ]}/>

            <span className={""}>
              <BetInfoComponent widthCss={""}/>
        </span>
          </div>
        </div>

      </div>


      <div className="mt-20 w-full max-w-md z-20">
        <TournamentViewComponent range={range || 'current'} type={["head_to_head", 'pump_or_dump']}/>
      </div>

      <InitialOnboardingDrawer open={openOnboarding} onOpen={() => {
      }} onClose={() => {
      }}/>
    </Layout>
  );
};

export {
  TournamentPage
}