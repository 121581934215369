import React from 'react';
import {useGetTournamentQuery} from "../../apis/tournaments.api";
import {useLocation} from "react-router";
import {useGetUserQuery} from "../../apis/users.api";
import {useNavigate} from "react-router-dom";


const SquadTxPage: React.FC = () => {
  const {data: user} = useGetUserQuery();
  const {state} = useLocation();
  const {tournamentId, pseudo} = state;
  const {data: tournament} = useGetTournamentQuery(tournamentId, {skip: !tournamentId});
  const navigate = useNavigate();
  return (<></>
    // <Layout>
    //   <InnerHeader pageTitle={"Portfolio"}>
    //     <>
    //       {tournament &&
    //         <div className={"text-gray-500 text-xs"}>
    //           <Countdown date={tournament.shouldStopAt}/>
    //         </div>
    //       }
    //     </>
    //   </InnerHeader>
    //
    //   <div className={"flex flex-col gap-y-4 max-w-3xl items-center w-full mt-4"}>
    //
    //     {tournament && tournament.type === 'squad' &&
    //       <div className={" w-full"}>
    //         <SquadPositions tournament={tournament} pseudo={pseudo}/>
    //       </div>
    //     }
    //   </div>
    //
    //   <div className={"flex gap-x-4 sticky bottom-20  w-full glass-light p-2 rounded-2xl justify-center"}>
    //     {tournament && isTournamentRunning(tournament) &&
    //       <div className={"flex justify-center items-center text-xs lg:text-sm"}>
    //         {tournament && tournament.currentUserSubscription && (!pseudo || pseudo === user?.pseudo) &&
    //           <>
    //             <TradingsActionDrawer tournament={tournament}></TradingsActionDrawer>
    //             <button className={"secondary"}
    //                     onClick={() => navigate('/squad-buy', {state: {tournamentId}})}>Buy
    //             </button>
    //           </>
    //         }
    //       </div>
    //     }
    //   </div>
    //
    //
    // </Layout>
  );
};

export {SquadTxPage}
