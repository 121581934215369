import React from "react";


const ShopIcon: React.FC<{ h?: number ,color?:string}> = ({h = 17,color='black'}) => <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2919_23775)">
      <path d="M4.41675 10.9053V16.2498C4.41675 16.4156 4.4826 16.5745 4.59981 16.6917C4.71702 16.809 4.87599 16.8748 5.04175 16.8748H16.2917C16.4575 16.8748 16.6165 16.809 16.7337 16.6917C16.8509 16.5745 16.9167 16.4156 16.9167 16.2498V10.9053" stroke={color} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M4.8855 3.125H16.448C16.5838 3.12503 16.7158 3.16926 16.8242 3.25101C16.9326 3.33277 17.0114 3.44759 17.0488 3.57812L18.1667 7.5H3.16675L4.28706 3.57812C4.32429 3.44798 4.40276 3.33343 4.51068 3.25172C4.6186 3.17 4.75013 3.12553 4.8855 3.125Z" stroke={color} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.16675 7.5V8.75C8.16675 9.41304 7.90336 10.0489 7.43452 10.5178C6.96567 10.9866 6.32979 11.25 5.66675 11.25C5.00371 11.25 4.36782 10.9866 3.89898 10.5178C3.43014 10.0489 3.16675 9.41304 3.16675 8.75V7.5" stroke={color} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M13.1667 7.5V8.75C13.1667 9.41304 12.9034 10.0489 12.4345 10.5178C11.9657 10.9866 11.3298 11.25 10.6667 11.25C10.0037 11.25 9.36782 10.9866 8.89898 10.5178C8.43014 10.0489 8.16675 9.41304 8.16675 8.75V7.5" stroke={color} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M18.1667 7.5V8.75C18.1667 9.41304 17.9034 10.0489 17.4345 10.5178C16.9657 10.9866 16.3298 11.25 15.6667 11.25C15.0037 11.25 14.3678 10.9866 13.899 10.5178C13.4301 10.0489 13.1667 9.41304 13.1667 8.75V7.5" stroke={color} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_2919_23775">
        <rect width="20" height="20" fill="white" transform="translate(0.666748)"/>
      </clipPath>
    </defs>
  </svg>

;

export {ShopIcon};