import React from 'react'
import {CoinIcon} from "../ui/coin-icon";
import {useClaimFreeMoneyAmountMutation} from "../../apis/game-income.api";
import toast from "react-hot-toast";
import {toastError, toastSuccess} from "../../utils/ToastUtils";
import {claimReducer} from "../../slices/incomeSlice";
import {UserNotification} from "../../models/User";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {RootState} from "../../app/store";
import {classNames} from "../../utils/Helper";
import {GreenCheckIcon} from "../ui/check-icon";
import {GemsCostButton} from "../ui/secondary-gems-button.component";
import {useGetUserQuery} from "../../apis/users.api";
import TemporaryDrawer from "../ui/temp-drawer.component";

const FreeIncomeNotifComponent: React.FC<{ ackNotif: Function, amount: number, notification: UserNotification }> = ({
                                                                                                                      amount,
                                                                                                                      notification,
                                                                                                                      ackNotif
                                                                                                                    }) => {
  const [claim] = useClaimFreeMoneyAmountMutation();
  const claimed = useAppSelector((state: RootState) => state.income.claimed);
  const dispatch = useAppDispatch();
  const {data: user} = useGetUserQuery();
  return <TemporaryDrawer size={"auto"} open={true} anchor={'bottom'} lineIcon={false} onClose={async () => {
    //should not arrive, but if the user click not the button we should claim anyway.
    await claim()
  }}>
    <div
      className={`flex justify-center flex-col items-center gap-y-4 pb-4 px-4 pt-1`}>
      <div className={"flex flex-col gap-y-4"}>
        <div className={`relative flex justify-center items-center`}>
          <div>
            <CoinIcon h={48}></CoinIcon>
          </div>
        </div>

        <div className={"flex flex-col justify-center items-center w-full"}>
       <span
         className={`text-2xl font-bold`}> x{amount}</span>
        </div>
      </div>

      <div
        className={classNames("flex flex-col justify-center items-center w-full", claimed.find(id => id === notification.id) ? 'hidden' : '')}>
        <span className={`opacity-50 font-normal text-center`}>Claim you daily free coins so you don't lose them ! </span>
      </div>
      <div
        className={classNames("flex flex-col justify-center items-center w-full gap-y-4", claimed.find(id => id === notification.id) ? 'hidden' : '')}>
        <button className={"primary w-full"} onClick={async (e) => {
          e.stopPropagation();
          toast.loading('Getting your free Coins...');
          const amount: { data: number } = await claim() as { data: number };
          gtag('event', 'claimFreeCoinsSuccess', {});
          if (amount.data > 0) {
            toastSuccess(`Claimed!`);
          } else {
            toastError('Nothing to claim, free Tanji offer has expired.');
          }
          dispatch(claimReducer(notification.id));
          ackNotif();
        }}>
          Claim
        </button>

        <div className={"w-full"} onClick={(e) => e.stopPropagation()}>
          <GemsCostButton label={"Double Claim"} quantity={3} onClick={async (e) => {
            e.stopPropagation();
            toast.loading('Getting your free Coins...');
            await claim({gemsBoost: 3});
            gtag('event', 'claimFreeCoinsSuccess', {gemsBoost: 2});
            toastSuccess('Claimed!');
            dispatch(claimReducer(notification.id));
            ackNotif();
          }} css={classNames((user?.gemsAmount || 0) >= 3 ? '' : 'disabled')}/>
        </div>

      </div>

      <div
        className={classNames("flex flex-col justify-center items-center w-full", claimed.find(id => id === notification.id) ? '' : 'hidden')}>
        Claimed <GreenCheckIcon/>
      </div>

    </div>
  </TemporaryDrawer>;
}

export {FreeIncomeNotifComponent};