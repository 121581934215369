import React, {useCallback, useEffect, useRef, useState} from 'react'
import {CryptoSelectionElement} from "./crypto-selection-element.component";
import {useGetCryptosQuery} from "../apis/cryptos.api";
import * as _ from "lodash";
import {CryptoCurrency, getTanjiCryptoCurrency} from "../models/Wallet";
import {classNames, hasElements} from "../utils/Helper";

import {useLocation, useNavigate} from "react-router-dom";

export type Props = {
  onDeselect: Function,
  onSelect: Function,
  selectedCryptos: string[],
  classes?: string,
  imgClasses?: string,
  disabled?: boolean;
  cryptoIds?: string[]
  excludedCryptoIds?: string[] | string
}

const pageSize = 25;
const CryptoFilterSelectionElement: React.FC<Props> = ({
                                                         onSelect,
                                                         onDeselect,
                                                         selectedCryptos,
                                                         cryptoIds,
                                                         excludedCryptoIds
                                                       }) => {

  const location = useLocation();
  const navigate = useNavigate();

  const [filter, setFilter] = useState( (location.state && location.state.filterValue) ? location.state.filterValue : '' );

  const [pageIndex, setPageIndex] = useState(0);
  const [allLoaded, setAllLoaded] = useState(false);
  const [loadedCryptos, setLoadedCryptos] = useState<CryptoCurrency[]>([]);
  const {data: cryptoInfos, isLoading: isCryptosLoading} = useGetCryptosQuery({
    cryptoIds: cryptoIds ? cryptoIds.filter(id => id != 'tanji') : [],
    excludedCryptoIds: excludedCryptoIds ? _.concat(excludedCryptoIds).filter(id => id !== 'tanji') : [],
    filter,
    pageIndex: pageIndex,
    pageSize: pageSize
  });

  const _setFilter = useCallback(_.debounce((val) => {
    setFilter(val);
    navigate( location.pathname, {state: {...location.state, filterValue: val}, replace: true } );
  }, 250), []);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
  }, [location]);

  useEffect(() => {
    setLoadedCryptos([]);
    setPageIndex(0);
    setAllLoaded(false);
  }, [filter]);

  useEffect(() => {
    if (!isCryptosLoading && cryptoInfos && hasElements(cryptoInfos)) {
      if (cryptoInfos.length < pageSize) {
        setAllLoaded(true);
      }
      setLoadedCryptos((prev) => {
        if (!excludedCryptoIds || (excludedCryptoIds !== 'tanji' && excludedCryptoIds.indexOf('tanji')) === -1) {
          return _.concat(getTanjiCryptoCurrency(), prev, cryptoInfos);
        } else {
          return _.concat(prev, cryptoInfos)
        }
      });
    } else if (cryptoInfos && cryptoInfos.length === 0) {
      setAllLoaded(true);
    }
  }, [cryptoInfos, isCryptosLoading]);

  const loadRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let intersectionObserver: IntersectionObserver | null = null;
    if (loadRef.current) {

      intersectionObserver = new IntersectionObserver(function (entries) {
        // If intersectionRatio is 0, the target is out of view
        // and we do not need to do anything.
        // console.log(entries);
        if (entries[0].isIntersecting && !allLoaded && loadedCryptos.length >= pageSize) {
          setPageIndex((prevState) => {
            // console.log(`Loaded new items, prevState:${prevState}, entries : ${entries.length}`);
            return prevState + 1
          })
        }
      });

      // start observing
      // @ts-ignore
      intersectionObserver.observe(loadRef.current);
    }
    return () => {
      if (loadRef.current) intersectionObserver?.unobserve(loadRef.current);
    }
  }, [loadRef, allLoaded, loadedCryptos])

  return (<div className={"w-full h-full flex flex-col gap-y-2 items-center"}>
      <input ref={inputRef} placeholder={"Search for coins"} type={"text"} id={"cryptoFilter"}
             className={"search w-full max-w-md"}
             onChange={(e) => {
               _setFilter(e.target.value)
             }} defaultValue={filter}/>
      <div className={"w-full text-end text-opacity-40 text-xs font-normal mr-1"}>% last 24h</div>
      <div className={"relative flex w-full h-full flex-col gap-2 justify-start overflow-y-auto"}>
        <CryptoSelectionElement classes={"w-full"} cryptos={loadedCryptos} selectedCryptos={selectedCryptos}
                                onDeselect={(code: string, id: string, crypto: CryptoCurrency) => {
                                  onDeselect(crypto);
                                }} onSelect={(code: string, id: string, crypto: CryptoCurrency) => {
          onSelect(crypto);
        }}></CryptoSelectionElement>
        <div ref={loadRef} id="load"
                className={classNames("w-full text-center p-4 text-opacity-40 text-sm font-normal", allLoaded ? 'hidden' : '')}>
          Loading...
        </div>
      </div>
    </div>
  );
}

export {CryptoFilterSelectionElement};