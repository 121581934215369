import React from "react";


const GrayedPlayIcon: React.FC<{ h?: number }> = ({h = 24}) => <svg width={h} height={h} viewBox="0 0 17 16" fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.5">
      <path
        d="M1.86121 4.88889V4.94977C1.86121 4.93111 1.86609 4.91244 1.86787 4.89377L1.86121 4.88889ZM7.63898 9.83866C7.57409 9.80888 7.51143 9.77422 7.45587 9.72933L2.11232 5.44222C1.94476 5.30666 1.86121 5.12844 1.86121 4.94977V10.3889C1.86121 11.2964 2.34698 11.4929 2.34698 11.4929L7.49098 15.5369C7.58209 15.6084 7.65854 15.652 7.72565 15.6782C7.67498 15.6009 7.63898 15.5116 7.63898 15.4098V9.83866ZM3.73987 10C3.2981 10 2.83632 9.55244 2.70921 9C2.58165 8.448 2.83676 8.00044 3.2781 8.00044C3.71987 8.00044 4.18165 8.448 4.30876 9C4.43632 9.55244 4.18165 10 3.73987 10ZM6.22121 13.3333C5.77943 13.3333 5.31765 12.8853 5.19054 12.3333C5.06298 11.7809 5.3181 11.3333 5.75987 11.3333C6.20165 11.3333 6.66343 11.7809 6.79054 12.3333C6.91765 12.8853 6.66298 13.3333 6.22121 13.3333Z"
        fill="#666666"/>
      <path
        d="M14.0545 5.40489L8.67496 9.72933C8.62963 9.76622 8.57985 9.79689 8.52785 9.82355V15.4098C8.52785 15.5133 8.49096 15.604 8.43896 15.6827C8.50785 15.6569 8.58785 15.6116 8.68252 15.5369C9.49185 14.9004 13.8327 11.4929 13.8327 11.4929C13.8327 11.4929 14.3056 11.2964 14.3056 10.3889V4.91244C14.3056 5.09111 14.2221 5.26933 14.0545 5.40489ZM10.7403 12.8409C10.6327 13.3462 10.243 13.7556 9.87008 13.7556C9.49763 13.7556 9.28252 13.3462 9.39008 12.8409C9.49763 12.3356 9.88696 11.9262 10.2599 11.9262C10.6327 11.9262 10.8474 12.3356 10.7403 12.8409ZM12.3127 10.4982C12.1999 11.0191 11.7892 11.4409 11.3963 11.4409C11.0039 11.4409 10.7776 11.0191 10.8905 10.4982C11.0039 9.97733 11.4145 9.55555 11.8065 9.55555C12.1999 9.55555 12.4261 9.97733 12.3127 10.4982ZM13.6741 8.22933C13.5625 8.72489 13.1572 9.12577 12.7696 9.12577C12.3821 9.12577 12.1585 8.72489 12.2705 8.22933C12.3821 7.73466 12.787 7.33333 13.1745 7.33333C13.5625 7.33333 13.7861 7.73466 13.6741 8.22933ZM14.303 4.89111C14.3034 4.89822 14.3056 4.90533 14.3056 4.91244V4.88889L14.303 4.89111Z"
        fill="#535353"/>
      <path
        d="M14.0545 4.42L8.65632 0.222224C8.32165 -0.0488874 7.77276 -0.0488874 7.43765 0.222224L2.11232 4.45733C1.96209 4.57867 1.88343 4.73467 1.86743 4.89422C1.86609 4.91289 1.86121 4.93111 1.86121 4.94978C1.86121 5.12845 1.94476 5.30667 2.11232 5.44222L7.45587 9.72934C7.51143 9.77422 7.57365 9.80845 7.63898 9.83867V15.4098C7.63898 15.5116 7.67498 15.6009 7.72521 15.6782C7.80565 15.8027 7.93232 15.8889 8.08343 15.8889C8.23276 15.8889 8.35854 15.8044 8.43898 15.6827C8.49098 15.6044 8.52787 15.5138 8.52787 15.4098V9.82356C8.57987 9.79689 8.62965 9.76622 8.67498 9.72934L14.0545 5.40489C14.2221 5.26934 14.3057 5.09111 14.3057 4.91245C14.3057 4.90534 14.3034 4.89822 14.303 4.89067C14.2968 4.71956 14.215 4.54978 14.0545 4.42ZM8.30565 3.852C9.02121 3.852 9.60165 4.316 9.60165 4.88889C9.60165 5.46178 9.02121 5.92578 8.30565 5.92578C7.58965 5.92578 7.00965 5.46178 7.00965 4.88889C7.00965 4.316 7.58965 3.852 8.30565 3.852Z"
        fill="#BCBCBC"/>
      <path
        d="M8.30564 5.92577C9.02141 5.92577 9.60164 5.46154 9.60164 4.88888C9.60164 4.31622 9.02141 3.85199 8.30564 3.85199C7.58988 3.85199 7.00964 4.31622 7.00964 4.88888C7.00964 5.46154 7.58988 5.92577 8.30564 5.92577Z"
        fill="white"/>
      <path
        d="M13.1745 7.33334C12.787 7.33334 12.3821 7.73468 12.2705 8.22934C12.1585 8.7249 12.3821 9.12579 12.7696 9.12579C13.1572 9.12579 13.5625 8.7249 13.6741 8.22934C13.7861 7.73468 13.5625 7.33334 13.1745 7.33334ZM11.807 9.55557C11.4145 9.55557 11.0039 9.97734 10.891 10.4982C10.7781 11.0191 11.0043 11.4409 11.3968 11.4409C11.7896 11.4409 12.2003 11.0191 12.3132 10.4982C12.4261 9.97734 12.1999 9.55557 11.807 9.55557ZM10.2594 11.9262C9.88654 11.9262 9.4972 12.3356 9.38965 12.8409C9.28209 13.3462 9.4972 13.7556 9.86965 13.7556C10.2425 13.7556 10.6323 13.3462 10.7399 12.8409C10.8474 12.3356 10.6323 11.9262 10.2594 11.9262Z"
        fill="white"/>
      <path
        d="M3.27805 8.00046C2.83628 8.00046 2.58161 8.44801 2.70917 9.00001C2.83672 9.55246 3.29805 10 3.73983 10C4.18161 10 4.43628 9.55246 4.30872 9.00001C4.18161 8.44801 3.71983 8.00046 3.27805 8.00046ZM5.75939 11.3333C5.31761 11.3333 5.06294 11.7809 5.19005 12.3333C5.31761 12.8853 5.77939 13.3333 6.22117 13.3333C6.66294 13.3333 6.91761 12.8853 6.7905 12.3333C6.66294 11.7809 6.20117 11.3333 5.75939 11.3333Z"
        fill="#E1E8ED"/>
    </g>
  </svg>
;

const PlayIcon: React.FC<{ h: number }> = ({h = 36}) =>
  <svg width={h} height={h} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M4 11V11.137C4 11.095 4.011 11.053 4.015 11.011L4 11ZM17 22.137C16.854 22.07 16.713 21.992 16.588 21.891L4.565 12.245C4.188 11.94 4 11.539 4 11.137V23.375C4 25.417 5.093 25.859 5.093 25.859L16.667 34.958C16.872 35.119 17.044 35.217 17.195 35.276C17.081 35.102 17 34.901 17 34.672V22.137ZM8.227 22.5C7.233 22.5 6.194 21.493 5.908 20.25C5.621 19.008 6.195 18.001 7.188 18.001C8.182 18.001 9.221 19.008 9.507 20.25C9.794 21.493 9.221 22.5 8.227 22.5ZM13.81 30C12.816 30 11.777 28.992 11.491 27.75C11.204 26.507 11.778 25.5 12.772 25.5C13.766 25.5 14.805 26.507 15.091 27.75C15.377 28.992 14.804 30 13.81 30Z" fill="#A0041E"/>
  <path d="M31.435 12.161L19.331 21.891C19.229 21.974 19.117 22.043 19 22.103V34.672C19 34.905 18.917 35.109 18.8 35.286C18.955 35.228 19.135 35.126 19.348 34.958C21.169 33.526 30.936 25.859 30.936 25.859C30.936 25.859 32 25.417 32 23.375V11.053C32 11.455 31.812 11.856 31.435 12.161ZM23.978 28.892C23.736 30.029 22.859 30.95 22.02 30.95C21.182 30.95 20.698 30.029 20.94 28.892C21.182 27.755 22.058 26.834 22.897 26.834C23.736 26.834 24.219 27.755 23.978 28.892ZM27.516 23.621C27.262 24.793 26.338 25.742 25.454 25.742C24.571 25.742 24.062 24.793 24.316 23.621C24.571 22.449 25.495 21.5 26.377 21.5C27.262 21.5 27.771 22.449 27.516 23.621ZM30.579 18.516C30.328 19.631 29.416 20.533 28.544 20.533C27.672 20.533 27.169 19.631 27.421 18.516C27.672 17.403 28.583 16.5 29.455 16.5C30.328 16.5 30.831 17.403 30.579 18.516ZM31.994 11.005C31.995 11.021 32 11.037 32 11.053V11L31.994 11.005Z" fill="#DD2E44"/>
  <path d="M31.435 9.94498L19.289 0.49998C18.536 -0.11002 17.301 -0.11002 16.547 0.49998L4.565 10.029C4.227 10.302 4.05 10.653 4.014 11.012C4.011 11.054 4 11.095 4 11.137C4 11.539 4.188 11.94 4.565 12.245L16.588 21.891C16.713 21.992 16.853 22.069 17 22.137V34.672C17 34.901 17.081 35.102 17.194 35.276C17.375 35.556 17.66 35.75 18 35.75C18.336 35.75 18.619 35.56 18.8 35.286C18.917 35.11 19 34.906 19 34.672V22.103C19.117 22.043 19.229 21.974 19.331 21.891L31.435 12.161C31.812 11.856 32 11.455 32 11.053C32 11.037 31.995 11.021 31.994 11.004C31.98 10.619 31.796 10.237 31.435 9.94498ZM18.5 8.66698C20.11 8.66698 21.416 9.71098 21.416 11C21.416 12.289 20.11 13.333 18.5 13.333C16.889 13.333 15.584 12.289 15.584 11C15.584 9.71098 16.889 8.66698 18.5 8.66698Z" fill="#EA596E"/>
  <path d="M18.5 13.333C20.1105 13.333 21.416 12.2885 21.416 11C21.416 9.71151 20.1105 8.66699 18.5 8.66699C16.8895 8.66699 15.584 9.71151 15.584 11C15.584 12.2885 16.8895 13.333 18.5 13.333Z" fill="white"/>
  <path d="M29.455 16.5C28.583 16.5 27.672 17.403 27.421 18.516C27.169 19.631 27.672 20.533 28.544 20.533C29.416 20.533 30.328 19.631 30.579 18.516C30.831 17.403 30.328 16.5 29.455 16.5ZM26.378 21.5C25.495 21.5 24.571 22.449 24.317 23.621C24.063 24.793 24.572 25.742 25.455 25.742C26.339 25.742 27.263 24.793 27.517 23.621C27.771 22.449 27.262 21.5 26.378 21.5ZM22.896 26.834C22.057 26.834 21.181 27.755 20.939 28.892C20.697 30.029 21.181 30.95 22.019 30.95C22.858 30.95 23.735 30.029 23.977 28.892C24.219 27.755 23.735 26.834 22.896 26.834Z" fill="white"/>
  <path d="M7.188 18.001C6.194 18.001 5.621 19.008 5.908 20.25C6.195 21.493 7.233 22.5 8.227 22.5C9.221 22.5 9.794 21.493 9.507 20.25C9.221 19.008 8.182 18.001 7.188 18.001ZM12.771 25.5C11.777 25.5 11.204 26.507 11.49 27.75C11.777 28.992 12.816 30 13.81 30C14.804 30 15.377 28.992 15.091 27.75C14.804 26.507 13.765 25.5 12.771 25.5Z" fill="#E1E8ED"/>
</svg>



export {GrayedPlayIcon, PlayIcon};