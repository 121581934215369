import React from 'react';

const ScalesIcon: React.FC<{ h?: number }> = ({h}) => (
  <svg width={h} height={h} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.92298 22.923C3.71098 23.434 3.12498 23.674 2.61498 23.462C2.10498 23.249 1.86398 22.664 2.07598 22.154L6.69298 9.61602C6.90498 9.10602 7.49098 8.86502 7.99998 9.07702C8.50998 9.29002 8.75098 9.87502 8.53898 10.385L3.92298 22.923Z" fill="#66757F"/>
    <path d="M13.923 22.154C14.135 22.664 13.894 23.249 13.384 23.462C12.874 23.674 12.289 23.434 12.076 22.923L7.46099 10.385C7.24899 9.87497 7.48999 9.28997 7.99999 9.07697C8.50999 8.86497 9.09499 9.10597 9.30799 9.61597L13.923 22.154ZM23.924 22.923C23.711 23.434 23.125 23.674 22.615 23.462C22.105 23.249 21.865 22.664 22.077 22.154L26.693 9.61497C26.905 9.10597 27.49 8.86497 28 9.07697C28.51 9.28997 28.752 9.87497 28.539 10.385L23.924 22.923Z" fill="#66757F"/>
    <path d="M33.923 22.154C34.135 22.664 33.895 23.249 33.385 23.462C32.875 23.674 32.289 23.434 32.076 22.923L27.461 10.385C27.248 9.87502 27.49 9.29002 28 9.07702C28.51 8.86502 29.095 9.10602 29.307 9.61602L33.923 22.154Z" fill="#66757F"/>
    <path d="M14.857 22H1.143C0.512 22 0 22.511 0 23.143C0 25.667 3.582 27.714 8 27.714C12.418 27.714 16 25.667 16 23.143C16 22.511 15.488 22 14.857 22ZM24 34H12C11.448 34 11 33.553 11 33C11 30.791 14.134 29 18 29C21.866 29 25 30.791 25 33C25 33.553 24.553 34 24 34ZM34.857 22H21.143C20.511 22 20 22.511 20 23.143C20 25.667 23.581 27.714 28 27.714C32.419 27.714 36 25.667 36 23.143C36 22.511 35.489 22 34.857 22Z" fill="#FFAC33"/>
    <path d="M19 3C19 2.448 18.553 2 18 2C17.448 2 17 2.448 17 3V30C17 30.553 17.448 31 18 31C18.553 31 19 30.553 19 30V3Z" fill="#FFAC33"/>
    <path d="M18 6C19.1046 6 20 5.10457 20 4C20 2.89543 19.1046 2 18 2C16.8954 2 16 2.89543 16 4C16 5.10457 16.8954 6 18 6Z" fill="#FFAC33"/>
    <path d="M8 12C9.10457 12 10 11.1046 10 10C10 8.89543 9.10457 8 8 8C6.89543 8 6 8.89543 6 10C6 11.1046 6.89543 12 8 12Z" fill="#FFAC33"/>
    <path d="M28 12C29.1046 12 30 11.1046 30 10C30 8.89543 29.1046 8 28 8C26.8954 8 26 8.89543 26 10C26 11.1046 26.8954 12 28 12Z" fill="#FFAC33"/>
    <path d="M28 10C28 11.104 28 10 18 10C8 10 8 11.104 8 10C8 8.896 11 6 18 6C25 6 28 8.896 28 10Z" fill="#FFAC33"/>
  </svg>
);

export default ScalesIcon;
