import BoostUpIcon from "../../svg/BoostUpIcon";
import HourglassIcon from "../../svg/HourglassIcon";
import ScalesIcon from "../../svg/ScalesIcon";
import {useGetBaseBetOddQuery} from "../../../apis/tournaments.api";
import {useEffect, useState} from "react";
import {UserItem} from "../../../models/User";
import {hasElements} from "../../../utils/Helper";
import {BoostItemIcon, ShieldItemIcon} from "../../shop/gems-buyable-items.component";

interface BetSummaryProps {
  duration: any,
  collectible: any,
  gemBoost?: boolean,
  total?: number,
  streakMultiplier?: number,
  streakCount?: number,
  items?: UserItem[],
}

const getItemComponent = (userItem: UserItem): JSX.Element => {
  const text = userItem.label;
  return <div className={"flex w-full justify-between"}>
    <div className={"flex gap-x-2"}>
      {userItem.subFamily === 'shield' &&
        <span><ShieldItemIcon h={20} item={userItem}/></span>
      }
      {userItem.subFamily === 'bet_boost' &&
        <span><BoostItemIcon h={16} item={userItem}/></span>
      }
      <span className={"text-sm"}>{text}</span>
    </div>
    {userItem.subFamily === 'shield' &&
      <span className={"text-sm"}>{userItem.metadata?.value}%</span>
    }
    {userItem.subFamily === 'bet_boost' &&
      <span className={"text-sm"}>+ {Number(userItem.metadata?.value).toFixed(1)}</span>
    }
  </div>;
}

const BetSummary: React.FC<BetSummaryProps> = ({
                                                 duration,
                                                 collectible,
                                                 gemBoost,
                                                 total,
                                                 streakMultiplier = 1,
                                                 streakCount = 0,
                                                 items = []
                                               }) => {


  const displayTotal = !!total;

  const [_tot, _setTot] = useState(0);
  useEffect(() => {
    if (total) {
      _setTot(total * streakMultiplier);
    }
  }, [total, streakMultiplier])

  return (

    <div className='w-full flex flex-col'>
      <div className='striped'>
        <MarketLine/>
        <CollectibleLine collectible={collectible}/>
        <DurationLine duration={duration}/>
        {items && hasElements(items) && items.map((i) => getItemComponent(i))}

        {streakCount > 0 &&
          <StreakLine streakMultiplier={streakMultiplier} streakCount={streakCount}/>
        }

      </div>


      {displayTotal &&
        <div className='w-full flex flex-col gap-y-2'>

          <hr className="w-full border-t border-grey-100"/>

          <p className='w-full text-right'>
            Total odds: {_tot.toFixed(2)}
          </p>

        </div>
      }

    </div>

  );
};

export default BetSummary;

const CollectibleLine: React.FC<{ collectible: any }> = ({collectible}) => {

  return (<>{collectible &&
    <div className='flex justify-between items-center'>

      <div className='flex gap-2 center'>
        <img className="w-4" src="/static/images/collectible-icon.png" alt="Collectible card logo"></img>
        <p className='text-sm'> {collectible.code} Collectible</p>
      </div>

      <div>
        <p className='text-sm'>+ {collectible.bonus}</p>
      </div>

    </div>}</>)
}

const DurationLine: React.FC<{ duration: any }> = ({duration}) => {
  return duration ? (
    <div className='flex justify-between items-center'>

      <div className='flex gap-2 center'>
        <HourglassIcon h={18}/>
        <p className='text-sm'> {duration.label} </p>
      </div>

      <p className='text-sm'>+ {duration.bonus}</p>

    </div>) : (<></>);
}

const GemBoostLine: React.FC = () => {

  const gemBoostSpecs = {
    label: "Gems Boost",
    bonus: 1.9
  }

  return (
    <div className='flex justify-between items-center'>

      <div className='flex gap-2 center'>
        <BoostUpIcon h={18}/>
        <p className='text-sm'> {gemBoostSpecs.label} </p>
      </div>

      <p className='text-sm'>+ {gemBoostSpecs.bonus}</p>

    </div>)
}

const MarketLine: React.FC = () => {

  const {data: marketOdds} = useGetBaseBetOddQuery();


  return (
    <div className='flex justify-between items-center'>

      <div className='flex gap-2 center'>
        <ScalesIcon h={18}/>
        <p className='text-sm'>Market Odds</p>
      </div>

      <p className='text-sm'>{marketOdds}</p>

    </div>
  )
}
const StreakLine: React.FC<{ streakCount: number, streakMultiplier: number }> = ({
                                                                                   streakCount = 0,
                                                                                   streakMultiplier = 1
                                                                                 }) => {

  return (
    <div className='flex justify-between items-center'>

      <div className='flex gap-2 center'>
        <ScalesIcon h={18}/>
        <p className='text-sm'>Bet Streak #{streakCount}</p>
      </div>

      <p className='text-sm text-orange'>x{streakMultiplier}</p>

    </div>
  )
}