import React, {useCallback, useEffect, useState} from "react";
import {classNames, hasElements} from "../../utils/Helper";
import {NotificationAchievement, UserNotification} from "../../models/User";
import {useAcknowledgeNotificationsMutation, useGetUserQuery} from "../../apis/users.api";
import {FreeIncomeNotifComponent} from "../users/free-income-notif.component";
import {WeekResumeNotifComponent} from "../users/week-resume-notif.component";
import {FullScreenDialog} from "./modal-confirmation";
import {SmilingIcon} from "./smiling-icon";
import {ItemShadowIcon} from "./item-shadow-icon";
import {getRandomSuccessIcon} from "./IconUtils";
import {CollectibleCardComponent} from "../collectibles/collectible-card.component";
import {useNavigate} from "react-router-dom";
import {CollectibleFeaturing} from "./notification/collectible-featuring-notification-content.component";
import {BigTradeNotifComponent} from "../users/big-trade-notif.component";
import {CollectibleXpRewardNotifComponent} from "../users/collectible-xp-reward-notif.component";
import NewFriendNotification from "./notification/new-friend-notification-content.component";
import {CrownIcon} from "../svg/CrownIcon";
import {GemsDiscountNotifComponent} from "../users/gems-discount-notif.component";
import {UserGemsComponent} from "../users/user-gems.component";
import CollectibleLevelUpNotif from "./notification/collectible-level-up-notification-content.component";
import {
  EmailVerificationRemainderNotifComponent
} from "../users/notifications/email-verification-remainder-notif.component";
import {enableBodyScroll} from "../../utils/HtmlHelper";
import {MagnifyingGlassIcon} from "./magnifying-glass-icon";
import {SomeOnSpyingYouSnackbar} from "../game/someone-spying-you-snackbar.component";

export interface Prop {
  children?: JSX.Element | JSX.Element[],
  hidden?: boolean,
  notifications: UserNotification[],
  achievementsNotification?: NotificationAchievement[],
  customCss?: string,
  Openachievement?: boolean;
  isBlurred?: boolean,
  forcedAchievement?: boolean,
  onClose?: Function;
}

function getNotificationBgColor(n: UserNotification): string {
  let res = 'bg-gradient-to-b from-orange-700 to-orange';
  if (!n?.name) {
    res = '!bg-orange !bg-opacity-75';
  }

  return res;
}

function getNotificationCloseButtonLabel(n: UserNotification): string {
  let res = 'Close';
  if (n) {
    if (n.tag === 'daily_free_coin') {
      res = 'No, I hate free money';
    }
  }
  return res;
}

function canBeSkipped(n: UserNotification): boolean {
  let res = true;
  if (n) {
    if (n.tag === 'daily_free_coin') {
      res = false
    }
  }
  return res;
}


export const NotificationDialog: React.FC<Prop> = ({
                                                     notifications = [],
                                                     onClose,
                                                   }) => {
  const nav = useNavigate();
  const [ackNotifs] = useAcknowledgeNotificationsMutation();
  const [notification, setNotification] = useState<UserNotification>();
  const [count, setCount] = useState(0);
  const [visible, setVisible] = useState(false);
  const [bgLayerColor, setBgLayerColor] = useState('bg-orange');
  const [title, setTitle] = useState<string | undefined>(undefined);
  const {data: user} = useGetUserQuery();
  const notificationTimeout = 10;

  const _close = useCallback(async () => {
    // console.log(`Notification dialog _close called notif count : ${notifications.length}`);
    setVisible(false);
    setNotification(undefined);
    setTitle('');
    await ackAllNotifs();
    if (onClose)
      onClose();
    // console.log(`Notification dialog _close  = ackAllNotifs done`);
  }, [onClose]);

  const skipAll = useCallback(async () => {
    await ackNotifs(notifications.map(n => n.id));
  }, [notifications]);

  const ackAllNotifs = useCallback(async () => {
    await ackNotifs(notifications.map(n => n.id));
  }, [notifications]);

  useEffect(() => {
    setCount(0);
    // console.log(`notitications changed quantity=${notifications?.length || 0}`);
    if (hasElements(notifications)) console.log(JSON.stringify(notifications))
  }, [notifications]);

  useEffect(() => {
    setVisible(false);
    const newVal = notifications && notifications.length > 0 && (count < notifications.length);
    if (!newVal && hasElements(notifications)) {
      // console.log(`Calling _close from useEffect count=${count}`);
      _close();
    } else {
      setVisible(newVal);
      console.log(`setting notification ${count}, notification length : ${notifications.length}`);
      setBgLayerColor(getNotificationBgColor(notifications[count]));
      if (notifications[count] && notifications[count].name) {
        setTitle(notifications[count].name);
        console.log(`setting notif title ${notifications[count].name}`)
      }

      setNotification(notifications[count]);
    }
  }, [count, notifications]);


  const next = useCallback((notification: UserNotification) => {
    // console.log('calling next');
    setVisible(false);
    setTitle('');
    setCount(count + 1);
    enableBodyScroll();
    setNotification(undefined);
  }, [count]);

  return <div id={"notification-dialog"} className={classNames(`fixed z-50`, !!visible ? '' : 'hidden')}>
    {(notification && notification.display === "snackBar") ?

      <>
        {notification && notification.tag === "new_friend_signup" &&
          <NewFriendNotification notification={notification} ackNotif={() => next(notification)}/>
        }
        {user && notification.tag === 'email_verification_remainder' &&
          <EmailVerificationRemainderNotifComponent notification={notification} onClose={() => {
            console.log('On Close email_verification_remainder notif')
            next(notification)
          }}/>
        }

          {user && notification.tag === 'daily_free_coin' &&
            <FreeIncomeNotifComponent ackNotif={() => next(notification)}
                                      notification={notification}
                                      amount={notification.payload.amount as number}/>

          }

          {user && notification.tag === 'someone_spying_you' &&
            <SomeOnSpyingYouSnackbar onClose={() => next(notification)}
                                     spy={notification.payload.spy}/>
          }
      </>

      :

      <div id="app-notif-popup" className="fixed inset-0 w-full z-50 overflow-y-auto">
        <div
          className={classNames("flex flex-col min-h-full items-center justify-end w-full text-center sm:p-4", bgLayerColor)}>

          <div
            className={'bg-transparent w-full sm:max-w-3xl flex flex-col overflow-hidden rounded-t-2xl sm:rounded-2xl px-8 pb-4 pt-4 text-center shadow-xl transform transition-all sm:px-40 sm:py-8'}>
            {notification &&
              <>
                <div className={"w-full h-full overflow-y-auto"}>
                  {(() => {
                    if (user && notification.tag === 'game_week_resume') {
                      return <WeekResumeNotifComponent
                        ackNotif={() => next(notification)}
                        investment={notification.payload.investment as number}
                        gain={notification.payload.gain}
                        score={notification.payload.score}
                        rank={notification.payload.rank}
                        xpGain={notification.payload.xpGain}
                        gemsGain={notification.payload.gemsGain}
                        roi={notification.payload.roi} user={user}/>;
                    } else if (user && notification.tag === 'gems_simple_discount') {
                      return <GemsDiscountNotifComponent notification={notification} timer={10}
                                                         visible={visible} onClose={() => next(notification)}/>
                    } else if (user && notification.tag === 'referral_reward_unlocked') {
                      return <FullScreenDialog openProp={visible} timer={notificationTimeout}
                                               onClose={() => next(notification)}>
                        <div className={"h-full flex flex-col gap-y-16 justify-center items-center"}>
                          <div className={"flex flex-col gap-y-2"}>
                            <SmilingIcon h={50}/>
                            <ItemShadowIcon/>
                          </div>

                          <div className={"flex-col flex gap-3 justify-center items-center"}>
                            <div
                              className={"rounded-lg bg-black bg-opacity-20 text-white font-bold px-2 py-2 text-lg text-center"}>
                              Referral Reward unlocked!
                            </div>
                            <div className={"text-white text-center"}>
                              {`You just won ${notification?.payload?.amount || ''} Tanjis as a reward for referring new friends !`}
                            </div>
                          </div>
                        </div>
                      </FullScreenDialog>
                    } else if (user && notification.tag === 'referral_success_available') {
                      return <FullScreenDialog
                        openProp={visible}
                        timer={undefined}
                        autoClosing={false}
                        showUpperCloseButton={true}
                        onClose={() => next(notification)}>

                        <div className={"h-full flex flex-col items-center justify-between"}>

                          <div className="mt-8">

                            <div className={"flex mb-5 justify-center"}>
                              <CrownIcon size={50}/>
                            </div>


                            <div className={"flex justify-center items-center mb-4"}>
                              <div
                                className={"rounded-lg bg-orange-700 text-white font-bold px-4 py-2 text-2xl text-center"}>
                                You won a Special Reward
                              </div>
                            </div>

                            <div className={"flex flex-col gap-6 px-10"}>
                              <p className="text-white text-center">
                                You managed to refer {notification?.payload?.amount || ''} friends to play
                                Tanjiblocks.
                              </p>
                              <p className="text-white text-center">
                                You just unlocked a reward.
                              </p>
                              <p className="text-white text-center">
                                Click in the button below to discover and claim it!
                              </p>
                            </div>

                          </div>

                          <div className="mb-8">

                            <button className={"primary"}
                                    onClick={() => {
                                      skipAll();
                                      nav('/referral-rewards')
                                    }}>
                              Show Prize
                            </button>

                          </div>

                        </div>

                      </FullScreenDialog>
                    } else if (user && notification.tag === 'collectible_good_trade') {
                      return <BigTradeNotifComponent visible={visible} timer={notificationTimeout}
                                                     onClose={() => next(notification)}
                                                     notification={notification}/>

                    } else if (user && (notification.tag === 'free_collectible_xp_consolation_prize' || notification.tag === 'ranking_unlock_collectible_reward')) {
                      return <CollectibleXpRewardNotifComponent visible={visible} timer={notificationTimeout}
                                                                onClose={() => next(notification)}
                                                                notification={notification}/>
                    } else if (user && (notification.tag === 'collectible_level_up')) {
                      return <CollectibleLevelUpNotif
                        visible={visible}
                        timer={notificationTimeout}
                        onClose={() => next(notification)}
                        notification={notification}
                      />
                    } else if (user && notification.tag === 'collectible_upgrade_available') {
                      const code = notification.payload.collectible.code;
                      const level = Number(notification.payload.collectible.level);
                      return <FullScreenDialog openProp={visible} timer={notificationTimeout}
                                               onClose={() => {
                                                 // console.log('On Close collectible_upgrade_available notif')
                                                 next(notification);
                                               }}>
                        <div className={"h-full flex flex-col gap-y-6 justify-center items-center"}>
                          <div className={"flex flex-col gap-y-2 items-center"}>
                            {getRandomSuccessIcon(42)}
                          </div>

                          <div className={"flex-col flex gap-3 justify-center items-center"}>
                            <div
                              className={"rounded-lg bg-black bg-opacity-20 text-white font-bold px-2 py-2 text-lg text-center"}>
                              What a boss !
                            </div>

                            <div className={"text-white text-center text-sm"}>
                              {level === 0 && `You can now unlock the ${code} collectible ! `}
                              {level === 0 && `Buy it and keep performing with ${code} to increase its level.`}
                              {level > 0 && `You reached ${code} Collectible next rank ! `}
                              {level > 0 && `Upgrade it to unlock new features.`}
                            </div>
                          </div>

                          <div className={"mt-4"}>
                            <div className={"text-white text-center"}>
                              <CollectibleCardComponent collectible={notification.payload.collectible}
                                                        animateProgressBar={true} shineEffect={true}/>
                            </div>
                          </div>

                          <button className={"primary mt-2"}
                                  onClick={() => {
                                    skipAll();
                                    nav('/store', {
                                      replace: true,
                                      state: {upgradeCollectibleId: notification.payload.collectible.cryptoCurrencyId}
                                    })
                                  }}>
                            Show Offer
                          </button>
                        </div>
                      </FullScreenDialog>
                    } else if (user && notification.tag === 'collectible_onboarding') {
                      return <CollectibleFeaturing ackNotif={() => next(notification)}/>
                    } else if (user && notification.tag === 'connection_less_crypto_gems_purchase') {
                      gtag('event', 'shop_onConnectionLessCryptoPaymentSuccessNotificationReceived', {shop_purchased_gems_amount: notification.payload.gemsAmount});
                      return <FullScreenDialog openProp={visible} timer={10} onClose={() => {
                        // console.log('On Close collectible_upgrade_available notif')
                        next(notification)
                      }}>
                        <div className={"h-full flex flex-col gap-y-4 justify-center items-center px-2"}>
                          <UserGemsComponent iconSize={40} textCss={"text-xl"}
                                             gemsAmount={notification.payload.gemsAmount}/>
                          <div className={"flex-col flex gap-3 justify-center items-center"}>
                            <div
                              className={"rounded-lg bg-black bg-opacity-20 text-white font-bold px-2 py-2 text-lg text-center"}>
                              Gems Received !
                            </div>

                            <div className={"text-white text-center text-sm"}>
                              We received your crypto payment. You have been credited
                              with {notification.payload.gemsAmount} gems.
                            </div>
                          </div>
                        </div>
                      </FullScreenDialog>
                    } else if (user && notification.tag === 'leaderboard_page-spy_global') {
                      return <FullScreenDialog openProp={visible} timer={10} onClose={() => {
                        next(notification)
                      }}>
                        <div className={"h-full flex flex-col gap-y-6 justify-center items-center"}>
                          <div className={"flex flex-col gap-y-2 items-center"}>
                            <MagnifyingGlassIcon h={48}/>
                          </div>

                          <div className={"flex-col flex gap-3 justify-center items-center"}>
                            <div
                              className={"rounded-lg bg-black bg-opacity-20 text-white font-bold px-2 py-2 text-lg text-center"}>
                              You can now spy Bets positions !
                            </div>
                          </div>

                          <div className={"text-white text-center"}>
                            Go to the leaderboard and select which players you want to spy.
                          </div>
                        </div>
                      </FullScreenDialog>
                    } else {
                      next(notification)
                    }
                  })()
                  }
                </div>
                {/*I put hidden for now because we do not use pagination button for now. But I keep the code to keep the logic*/}
                <div className={"w-full flex justify-center gap-x-5 pt-8 items-center text-xxs hidden"}>
                  <button
                    className={classNames("tertiary !text-xxs", count > 0 && notifications.length > 0 ? '' : '!hidden')}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (count > 0) setCount(count - 1)
                    }}>Previous
                  </button>

                  <button
                    className={classNames("tertiary !text-xxs", canBeSkipped(notification) ? '' : 'hidden')}
                    onClick={(e) => {
                      e.stopPropagation();
                      next(notification);
                    }}>{(notifications.length > 1 && notification !== notifications[notifications.length - 1]) ? 'Next' : getNotificationCloseButtonLabel(notification)}
                  </button>

                  <button
                    className={classNames("tertiary !text-xxs", (canBeSkipped(notification) && notifications.length > 1 && notification !== notifications[notifications.length - 1]) ? '' : '!hidden')}
                    onClick={() => {
                      skipAll();
                      setVisible(false);
                    }}>Skip all
                  </button>

                </div>
              </>
            }
          </div>
        </div>
      </div>
    }
  </div>;
};