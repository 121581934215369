import React, {useState} from 'react'
import TemporaryDrawer from "../../ui/temp-drawer.component";
import {EmailIcon} from "../../ui/email-icon";
import {useGetUserQuery, useResendVerificationEmailMutation} from "../../../apis/users.api";
import {SmilingGlassesIcon} from "../../ui/smiling-glasses-icon";
import CheckIcon from "../../svg/Check";
import {UserNotification} from "../../../models/User";
import {GemsIcon} from "../../ui/gems-icon";


const EmailVerificationRemainderNotifComponent: React.FC<{
  notification: UserNotification,
  onClose?: Function
}> = ({onClose, notification}) => {
  const {data: user} = useGetUserQuery();
  const [resend] = useResendVerificationEmailMutation();
  const [emailSent, setEmailSent] = useState<boolean>(false);

  return <TemporaryDrawer size={'auto'} open={true} anchor={'bottom'} title={"Information"} onClose={() => {
    if (onClose) onClose();
  }}>
    <div className={"h-full flex flex-col gap-y-4 justify-center items-center pb-8"}>

      {!notification.payload?.gemsMode &&
        <h1 className={"text-2xl mt-2 mb-4"}>Verify your email</h1>
      }
      {notification.payload?.gemsMode &&
        <div className={"mt-2 mb-4 relative"}>
          You must verify your email to be able to spend Gems
          <span className={"relative !inline-block top-1 mx-1 bordered"}>
            <GemsIcon h={12}/>
          </span>
        </div>
      }

      <div className={"flex-col flex gap-y-2 justify-center items-center"}>
        <div><EmailIcon h={56}/></div>
        <div></div>
      </div>

      <div className={"text-start"}>
        <p className={"flex items-center justify-center gap-x-1 text-grey-700 font-medium py-1 text-center"}><span>Hi mysterious Trader</span><span><SmilingGlassesIcon
          h={16}/></span>,
        </p>
        <p className={"text-grey-700 font-medium py-1 text-center"}> To <strong className={"font-bold"}> fully unlock
          all game features, </strong> verify your account by clicking the link in the email we sent at <strong
          className={"font-bold"}>{user?.email}</strong>.
        </p>

        <div className={"flex-col flex justify-center mt-4"}>
          {!emailSent && <div className={"flex justify-center items-center gap-x-4"}>

            <button className={"tertiary !text-xs"} onClick={async () => {
              await resend();
              setEmailSent(true);
            }}>Send email again
            </button>

          </div>
          }

          {emailSent &&
            <div className={"flex justify-center gap-x-2"}>
              <p>Email sent </p>
              <CheckIcon/>
            </div>
          }
        </div>
      </div>


    </div>
  </TemporaryDrawer>;
}

export {EmailVerificationRemainderNotifComponent};