import * as React from 'react';
import {useCallback, useEffect} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import {TransitionProps} from '@mui/material/transitions';
import {NavBar} from "./nav-bar.component";
import {AddIcon} from "./add-icon";
import {classNames, cssShow} from "../../utils/Helper";
import {Toaster} from "react-hot-toast";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FullScreenDialog: React.FC<{
  name?: string,
  openProp: boolean,
  timer?: number,
  children: JSX.Element,
  timerFinished?: Function,
  displayOpenButton?: boolean,
  autoClosing?: boolean,
  onClose?: Function;
  restartTimer?: boolean,
  showUpperCloseButton?: boolean
}> = ({
        name = '',
        timerFinished = () => {
        },
        onClose = () => {
        },
        displayOpenButton = false,
        openProp,
        timer,
        children, autoClosing = true, restartTimer = false, showUpperCloseButton = false,
      }) => {

  const [open, setOpen] = React.useState(false);
  const [time, setTime] = React.useState(timer || 0);
  const [intervalId, setIntervalId] = React.useState<NodeJS.Timeout | null>(null);
  const [triggerRestart, setTriggerRestart] = React.useState<number>(0);

  useEffect(() => {
    // console.log('Timer param change timer');
    if (intervalId) {
      // console.log('Timer param change reset interval id');
      clearInterval(intervalId);
      setIntervalId(null);
    }
  }, [timer]);

  useEffect(() => {
    setOpen(openProp);
    if (openProp) {
      // console.log(`${name} opened`);
    }
  }, [openProp]);

  useEffect(() => {
    // console.log(`UseEffect timer=${timer}, open=${open}`);
    if (timer && open && !intervalId) {
      // console.log('Restarting timer');
      const id = setInterval(() => {
        setTime((prev) => {
          if (prev <= 0) {
            // console.log(`${name} interval finished calling handleClose`)
            handleClose();
            setTimeout(() => timerFinished());

            if (!restartTimer) {
              return 0
            } else {
              return timer || 0;
            }
          } else {
            return --prev;
          }
        });
      }, 1000);

      setIntervalId(id);
      return () => {
        clearInterval(id);
        setIntervalId(null);
      }
    }
  }, [open, timer, triggerRestart]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = useCallback(() => {

    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
    // else
    if (restartTimer) {
      setTriggerRestart((prev) => ++prev);
      setIntervalId(null);
      setTime(timer || 0);
    }

    // console.log(`${name} calling close`);
    if (autoClosing) {
      setOpen(false);
    }
    //calling onClose in a setTimeout to avoid REACT warning :
    //"Cannot update a component from inside the function body of a different component"
    setTimeout(() => onClose(), 0);
  }, [intervalId, restartTimer, autoClosing]);

  return (
    <React.Fragment>
      {displayOpenButton && <Button variant="outlined" onClick={handleClickOpen}>
        Open full-screen dialog
      </Button>}
      <Dialog
        sx={{
          position: 'fixed',
          left: 0, top: 0,
          zIndex: 99999,
        }}
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        {/*Add toast here to access it from the full screen dialog*/}
        <Toaster toastOptions={{
          className: '',
          style: {
            padding: '16px',
            color: '#ffffff',
            background: '#007679'
          },
        }}/>
        <NavBar active={false}/>
        <div
          className={"mt-[50px] bg-gradient-to-b from-orange-700 to-orange w-full h-full flex flex-col justify-between items-center text-white grow px-8 pb-2 pt-8 z-10 overflow-y-auto"}
          onClick={(e) => {
            // console.log(`${name} click calling handleClose`);
            handleClose();
            setTimeout(() => timerFinished());
            e.stopPropagation();
          }}>
          <div className={classNames("w-full flex justify-end", cssShow(showUpperCloseButton))}>
            <div className={"orange-button text-white gap-0"}><span className={"rotate-45"}><AddIcon h={14}/></span>
            </div>
          </div>
          <div className={"grow"}>{children}</div>
          {timer && <div className={"flex flex-col gap-y-2 w-full items-center self-end"}>
            <button className={"orange-button px-2 w-10"} onClick={(e) => {
              // console.log(`${name} click on button calling handleClose`);
              handleClose();
              setTimeout(() => timerFinished());
              e.stopPropagation();
            }}>{time}</button>
            <span className={"text-white opacity-70 font-normal"}>Tap to continue</span>
          </div>
          }
        </div>
      </Dialog>
    </React.Fragment>
  );
};

export {FullScreenDialog};