import React from "react";


const SmilingIcon: React.FC<{ h?: number }> = ({h = 17}) => <svg width={h} height={h} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Frame" clipPath="url(#clip0_1603_4957)">
      <path id="Vector" d="M16.9166 8C16.9166 12.4182 13.3348 16 8.91663 16C4.4984 16 0.916626 12.4182 0.916626 8C0.916626 3.58178 4.4984 0 8.91663 0C13.3348 0 16.9166 3.58178 16.9166 8Z" fill="#FDC605"/>
      <path id="Vector_2" fillRule="evenodd" clipRule="evenodd" d="M1.46773 4.89689C1.57439 5.00311 2.10684 5.32222 2.21306 5.64134C2.31973 5.96 2.53306 7.76756 3.17217 8.29956C3.83128 8.84711 6.31751 8.64356 6.79351 8.40622C7.85884 7.87511 7.98951 6.39245 8.17795 5.64134C8.28417 5.216 8.92328 5.216 8.92328 5.216C8.92328 5.216 9.5624 5.216 9.66906 5.64089C9.85751 6.39245 9.98906 7.87511 11.0535 8.40578C11.53 8.64356 14.0162 8.84756 14.6762 8.3C15.3144 7.768 15.5273 5.96045 15.634 5.64134C15.7397 5.32267 16.2731 5.00356 16.3793 4.89734C16.4864 4.79111 16.4864 4.36578 16.3793 4.25911C16.1664 4.04667 13.6531 3.85734 10.9468 4.15289C10.4068 4.212 10.2015 4.36534 8.92284 4.36534C7.64506 4.36534 7.43884 4.21156 6.89928 4.15289C4.19484 3.85689 1.68106 4.04622 1.46773 4.25867C1.36151 4.36489 1.36151 4.79067 1.46773 4.89689Z" fill="black"/>
      <path id="Vector_3" d="M13.0655 10.5018C12.9864 10.4302 12.8682 10.4258 12.7833 10.4889C12.766 10.5018 11.0402 11.7778 8.91665 11.7778C6.79843 11.7778 5.06688 10.5018 5.04999 10.4889C4.9651 10.4258 4.84687 10.4311 4.76776 10.5018C4.6891 10.5729 4.67132 10.6902 4.72599 10.7809C4.78332 10.8764 6.15576 13.1111 8.91665 13.1111C11.6775 13.1111 13.0504 10.8764 13.1073 10.7809C13.162 10.6898 13.1447 10.5729 13.0655 10.5018Z" fill="black"/>
    </g>
    <defs>
      <clipPath id="clip0_1603_4957">
        <rect width="16" height="16" fill="white" transform="translate(0.916626)"/>
      </clipPath>
    </defs>
  </svg>
;

export {SmilingIcon};