import {Account} from "./Account";

export interface Wallet {
  _id: string;
  isWallet: boolean;
  source: string;
  name: string;
  accounts: Array<Account>;
  accountsTracking?: WalletAccountTracking[],
  isMetamask: boolean;
  disabled: boolean;
  syncError: string;
}

export function getPublicAddress(wlt: Wallet): string | null {
  let publicAddress = null
  if (wlt.accounts) {
    publicAddress = wlt.accounts[0]?.publicAddress;
  }
  if (!publicAddress && wlt.accountsTracking) {
    publicAddress = wlt.accountsTracking[0]?.publicAddress;
  }
  return publicAddress;
}

export interface WalletAccountTracking {
  protocol: string,
  publicAddress: string,
}

export interface CryptoCurrency {
  _id: string;
  name: string;

  code: string;

  slug: string;

  logo: string;

  category: string;

  description:string;
  coinMarketCapId: string;
  quote24H?: { price: string, date: string },
  lastQuote: { price: string, date: string }
}

export const getTanjiCryptoCurrency = (): CryptoCurrency => {
  return {
    _id: 'tanji',
    name: 'Tanji',
    code: 'TANJI',
    slug: 'tanji',
    category: 'tanji',
    description: 'tanji is the native Tanjiblock Token',
    logo: process.env.REACT_APP_STATIC_ASSETS + "/images/tanji-min.png",
    coinMarketCapId: 'tanji',
    lastQuote: {price: '1', date: new Date().toString()}
  };
}


