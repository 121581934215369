import React from "react";


const CoinIcon: React.FC<{ h?: number }> = ({h = 24}) => <svg xmlns="http://www.w3.org/2000/svg" width={h} height={h} viewBox="0 0 80 80" fill="none" className={"inline"}>
      <path fillRule="evenodd" clipRule="evenodd" d="M40 72.2414C56.4257 72.2414 69.7414 58.9257 69.7414 42.5C69.7414 26.0743 56.4257 12.7586 40 12.7586C23.5743 12.7586 10.2586 26.0743 10.2586 42.5C10.2586 58.9257 23.5743 72.2414 40 72.2414ZM40 80C60.7107 80 77.5 63.2107 77.5 42.5C77.5 21.7893 60.7107 5 40 5C19.2893 5 2.5 21.7893 2.5 42.5C2.5 63.2107 19.2893 80 40 80Z" fill="#F4900C"/>
      <path d="M77.5 37.5C77.5 58.2107 60.7107 75 40 75C19.2893 75 2.5 58.2107 2.5 37.5C2.5 16.7893 19.2893 0 40 0C60.7107 0 77.5 16.7893 77.5 37.5Z" fill="#FFAC33"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M40 67.2414C56.4257 67.2414 69.7414 53.9257 69.7414 37.5C69.7414 21.0743 56.4257 7.75862 40 7.75862C23.5743 7.75862 10.2586 21.0743 10.2586 37.5C10.2586 53.9257 23.5743 67.2414 40 67.2414ZM40 75C60.7107 75 77.5 58.2107 77.5 37.5C77.5 16.7893 60.7107 0 40 0C19.2893 0 2.5 16.7893 2.5 37.5C2.5 58.2107 19.2893 75 40 75Z" fill="#FFCC4D"/>
      <g filter="url(#filter0_d_1369_6263)">
          <path d="M52.4992 44.4263L46.9995 47.6938L41.4998 50.9614V37.8868L46.9995 41.1544L52.4992 44.422V37.8859L47.1289 34.6932L41.4998 31.3465V18.2676L35.9993 15V28.0772L30.4997 24.8096L25 28.0772L30.5723 31.3903L36.0002 34.6158V41.1561V54.2324L41.5007 57.5L47.0003 54.2324L52.5 50.9648L52.4992 44.4263Z" fill="#FFCC4D"/>
      </g>
      <defs>
          <filter id="filter0_d_1369_6263" x="25" y="15" width="27.5" height="43.5" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dy="1"/>
              <feComposite in2="hardAlpha" operator="out"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0.956863 0 0 0 0 0.564706 0 0 0 0 0.0470588 0 0 0 1 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1369_6263"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1369_6263" result="shape"/>
          </filter>
      </defs>
  </svg>
;

export {CoinIcon};