import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

interface Video {
    link?: string
}

const slice = createSlice({
    name: 'video',
    initialState: {} as Video, 
    reducers: {
        setVideoState: (state, { payload: { link } }: PayloadAction<Video>) => {
            state.link = link; 
        }
    }
})

export const { setVideoState } = slice.actions; 
export default slice.reducer;
export const selectCurrentVideo = (state: RootState) => state.video.link;  