import * as htmlToImage from "html-to-image";
import {toastError, toastMessage} from "./ToastUtils";
import axios from "axios";

export function isVisible(element: HTMLElement | null) {
  let res = false;
  if (element) {
    if (window.getComputedStyle(element).display === "none") {
      return false;
    } else res = true;
  }
  return res;
}

const loadImage = async (imageURL: string) => {
  return new Promise((resolve) => {
    const downloadedImg = new Image();
    downloadedImg.crossOrigin = "anonymous";
    downloadedImg.addEventListener("load", (d) => {
      // toastMessage(`Image ${imageURL} loaded`);
      resolve(downloadedImg)
    }, false);
    downloadedImg.src = imageURL;
  })
}

export const loadImages = async (imagesURL: string[]) => {
  const ps: Promise<any>[] = [];
  for (let i = 0; i < imagesURL.length; i++) {
    ps.push(loadImage(imagesURL[i]));
  }
  return Promise.all(ps);
}
/**
 *
 * @param imageType
 * @param opts
 * @param htmlId
 */
export const snapHtml = async (imageType: string, opts: {
  width: number,
  height: number
}, htmlId: string, preloadImagesURL: string[] = []) => {
  await loadImages(preloadImagesURL);
  return new Promise(async (resolve, reject) => {
    const node = document.getElementById(htmlId);
    if (node) {
      // htmlToImage.toPng(node, {...opts})
      //   .then(function (dataUrl) {
      //     const img = new Image();
      //     img.src = dataUrl;
      //     document.getElementById('snap-test')?.appendChild(img);
      //   })
      //   .catch(function (error) {
      //     console.error('oops, something went wrong!', error);
      //   });
      //Workaround : calling mutiple time toBlob assure that images render well in the snapshot
      await htmlToImage.toBlob(node, {...opts, pixelRatio: 1});
      await htmlToImage.toBlob(node, {...opts, pixelRatio: 1});
      await htmlToImage.toBlob(node, {...opts, pixelRatio: 1});
      await htmlToImage.toBlob(node, {...opts, pixelRatio: 1});
      htmlToImage.toBlob(node, {...opts, pixelRatio: 1})
        .then(async function (blob) {
          // toastMessage('Image blob generated')
          if (blob) {
            const f = new File([blob], `lol.png`, {
              type: 'image/webp',
              lastModified: new Date().getTime(),
            });

            const formData = new FormData();
            formData.append("client_file", f);
            try {
              // toastMessage('starting snap upload')
              let res = await axios.post(`/users/uploadEndOfWeekSnapPicture?type=${imageType}`, formData, {
                headers: {'Content-Type': 'multipart/form-data'}
              });
              resolve(res);
            } catch (e) {
              reject(false);
              console.error('File cannot be uploaded. Try another one.');
            } finally {
              console.log('Snap uploaded');
            }
          }
        })
        .catch(function (error) {
          toastError(`Error while generating snap, ${error}`);
          console.error('oops, something went wrong!', error);
          reject(false);
        });
    }
  })
}

export function disableBodyScroll() {
  document.getElementsByTagName('html')[0].style.overflow='hidden'
}

export function enableBodyScroll() {
  document.getElementsByTagName('html')[0].style.overflow=''
}