import React, {useEffect, useRef} from "react";
import './index.css';
import {classNames} from "./utils/Helper";


interface Props {
  children: React.ReactNode
  classes?: string,
  onInitialRendering?: (elem: HTMLDivElement) => void
}

function Layout(props: Props) {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (props.onInitialRendering && ref.current) props.onInitialRendering(ref.current);
  }, [])
  return (
    <div id={"layout"} ref={ref}
         className={classNames("relative flex flex-col w-screen text-white items-center z-10 px-4 pb-16 md:px-2 pt-24", props.classes ? props.classes : '')}>
      <>
        {props.children}
      </>
    </div>
  );
}

export default Layout;