import React from "react";


const ExplodingHeadIcon: React.FC<{ h?: number }> = ({h = 24}) => <svg width={h} height={h} viewBox="0 0 64 65" fill="none"
                                                              xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_2280_21380)">
    <path
      d="M62.2223 33.6002C62.2223 26.8553 56.7556 21.3887 50.0107 21.3887C48.2703 21.3887 45.1183 22.4162 45.1183 22.4162L21.7832 24.8767C21.7832 24.8767 16.9529 22.066 13.9912 22.066C7.2445 22.066 1.77783 27.5327 1.77783 34.2776C1.77783 34.6989 1.79917 35.1149 1.84005 35.5256C2.49961 51.634 15.7316 64.4998 32.0001 64.4998C48.6916 64.4998 62.2223 50.9691 62.2223 34.2776C62.2223 34.1851 62.2063 34.098 62.2009 34.0056C62.2063 33.8705 62.2223 33.7371 62.2223 33.6002Z"
      fill="#FFCB4C"/>
    <path
      d="M53.5574 24.9462C51.8845 24.5124 51.7636 25.6324 49.6427 25.3835C47.5663 25.1399 50.4054 24.0697 49.2961 23.4422C48.9227 23.2306 48.3876 23.1862 47.7281 23.2679C45.8579 23.4973 17.5876 24.235 16.5334 24.7435C15.5965 25.195 15.0419 25.7177 14.1903 24.923C13.1183 23.9239 11.5841 24.4999 11.1396 24.8324C10.5672 25.2626 10.4481 26.1159 11.5556 27.1026C12.6596 27.8493 14.5299 28.6297 17.7156 29.163C18.0659 29.2217 18.3645 29.2448 18.6436 29.2555C20.825 29.3284 21.1272 26.2866 24.2081 26.2048C26.2276 26.1515 44.0356 28.107 45.5023 27.9079C45.6819 27.883 45.8703 27.8653 46.0872 27.8653C48.8659 27.8653 49.5112 30.443 52.4676 28.427C53.721 27.4084 55.6872 25.4973 53.5574 24.9462Z"
      fill="#F19020"/>
    <path
      d="M10.5564 16.7046C13.264 19.4193 15.1857 22.2957 16.5333 24.7455C17.5395 26.5748 18.2328 28.1748 18.6435 29.2575C20.8248 29.3304 21.1271 28.0664 24.208 27.9846C26.2275 27.9313 27.3777 29.3517 28.8711 30.1695C31.5413 31.6308 33.4382 28.8717 35.4826 28.8717C36.9937 28.8717 39.4275 31.0175 41.1288 30.2299C43.5946 29.0868 44.0355 28.1126 45.5022 27.9117C46.0746 26.5055 46.8195 24.8966 47.728 23.2735C49.0435 20.9197 50.7111 18.5357 52.7786 16.7081H10.5564V16.7046Z"
      fill="#F36C24"/>
    <path
      d="M52.0995 17.2733C54.3768 15.028 11.1413 17.3017 12.3822 18.6742C14.1386 20.6191 16.2933 24.2795 16.2933 24.2795C16.2933 24.2795 24.7502 28.5551 31.1395 23.1666C35.584 25.8333 41.8062 24.8893 44.5831 20.3062C44.5831 20.3062 46.7466 21.1115 48.9404 21.2502C48.9404 21.2502 50.6346 18.7168 52.0995 17.2733Z"
      fill="#CE5C1F"/>
    <path
      d="M32 60.2118C36.2534 60.2118 39.7014 56.2719 39.7014 51.4118C39.7014 46.5517 36.2534 42.6118 32 42.6118C27.7467 42.6118 24.2987 46.5517 24.2987 51.4118C24.2987 56.2719 27.7467 60.2118 32 60.2118Z"
      fill="#65471B"/>
    <path
      d="M24.8889 37.1328C24.8889 39.7514 23.2961 41.8741 21.3334 41.8741C19.3689 41.8741 17.7778 39.7514 17.7778 37.1328C17.7778 34.5141 19.3689 32.3914 21.3334 32.3914C23.2961 32.3914 24.8889 34.5141 24.8889 37.1328ZM46.2223 37.131C46.2223 39.7496 44.6312 41.8723 42.6667 41.8723C40.7023 41.8723 39.1112 39.7496 39.1112 37.131C39.1112 34.5141 40.7041 32.3896 42.6667 32.3896C44.6312 32.3914 46.2223 34.5141 46.2223 37.131Z"
      fill="#65471B"/>
    <path
      d="M55.3334 7.12212C54.9068 7.12212 54.5032 7.20212 54.1139 7.3159C52.8908 5.81367 51.0508 4.83412 48.9619 4.83412C48.8072 4.83412 48.6614 4.86967 48.5085 4.88034C47.2037 3.41012 45.3192 2.46434 43.1983 2.46434C40.5228 2.46434 38.2205 3.9559 37.001 6.13723C36.3859 4.71856 34.9761 3.72479 33.3317 3.72479C32.0552 3.72479 30.9334 4.33279 30.201 5.26256C29.2979 2.64923 26.8445 0.76123 23.9254 0.76123C21.5734 0.76123 19.5165 1.98612 18.329 3.82434C18.0019 3.77279 17.6748 3.72123 17.3334 3.72123C14.7877 3.72123 12.6028 5.16479 11.4792 7.26256C11.1183 7.19501 10.7503 7.15056 10.3699 7.15056C7.0561 7.15056 4.36987 9.83679 4.36987 13.1506C4.36987 16.4643 7.0561 19.1506 10.3699 19.1506C11.4152 19.1506 12.3877 18.8679 13.2392 18.395C13.9077 18.8963 14.729 19.2057 15.6303 19.2057C15.8881 19.2057 16.137 19.1772 16.3805 19.1292C17.9005 21.5808 20.5885 23.2235 23.6854 23.2235C26.7236 23.2235 29.385 21.6501 30.9263 19.2821C32.2063 20.8501 34.1299 21.8723 36.3112 21.8723C39.5379 21.8723 42.2259 19.6715 43.0241 16.6972C43.0845 16.699 43.1396 16.715 43.2001 16.715C43.7032 16.715 44.1939 16.6599 44.6685 16.5603C45.8312 17.5488 47.3192 18.1675 48.9654 18.1675C51.0525 18.1675 52.8943 17.1879 54.1157 15.6857C54.505 15.7995 54.9085 15.8795 55.3352 15.8795C57.753 15.8795 59.7139 13.9186 59.7139 11.5008C59.7139 9.08301 57.7512 7.12212 55.3334 7.12212Z"
      fill="#CCD6DD"/>
    <path
      d="M49.4134 14.5233C51.4959 14.5233 53.184 12.8351 53.184 10.7526C53.184 8.67012 51.4959 6.98193 49.4134 6.98193C47.3309 6.98193 45.6427 8.67012 45.6427 10.7526C45.6427 12.8351 47.3309 14.5233 49.4134 14.5233Z"
      fill="#E1E8ED"/>
    <path
      d="M35.3351 10.994C35.3351 8.87312 33.616 7.15224 31.4951 7.15224C30.8409 7.15224 30.2329 7.33179 29.6942 7.61979C29.248 6.55135 28.1938 5.79935 26.9618 5.79935C25.9538 5.79935 25.0649 6.30601 24.5298 7.07579C23.3387 5.96824 21.7547 5.2749 20 5.2749C16.88 5.2749 14.2827 7.42601 13.5556 10.3202C12.9067 9.66779 12.0089 9.26246 11.0151 9.26246C9.04001 9.26246 7.43823 10.8642 7.43823 12.8393C7.43823 14.8145 9.04001 16.4162 11.0151 16.4162C12.2649 16.4162 13.36 15.7745 14 14.8038C14.9191 16.7256 16.7182 18.1336 18.8871 18.4998C19.5805 19.6909 20.8551 20.4998 22.3325 20.4998C24.2418 20.4998 25.8329 19.1593 26.2311 17.3709C26.8071 17.6909 27.4596 17.89 28.1636 17.89C30.0658 17.89 31.6516 16.5585 32.0569 14.7789C33.9093 14.5033 35.3351 12.9229 35.3351 10.994Z"
      fill="#E1E8ED"/>
  </g>
  <defs>
    <clipPath id="clip0_2280_21380">
      <rect width="64" height="64" fill="white" transform="translate(0 0.5)"/>
    </clipPath>
  </defs>
</svg>;

export {ExplodingHeadIcon};