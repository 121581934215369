import React, {useEffect, useState} from 'react'
import {useGetUserQuery, useUpdatePushConfigMutation} from "../../apis/users.api";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import * as _ from "lodash";
import {PushNotificationConfigItem} from "../../models/User";
import Switch from "@mui/material/Switch";
import {getSwitchSx} from "../ui/MuiStyle";

export type Props = {}

const UserPushConfig: React.FC<Props> = ({}) => {
  const {data: user} = useGetUserQuery();
  const [items, setItems] = useState<PushNotificationConfigItem[][]>();
  const [updateConfig] = useUpdatePushConfigMutation();
  useEffect(() => {
    if (user && user.pushNotificationCodes) {
      setItems(_.partition(user.pushNotificationCodes, (o) => o.type));
    }
  }, [user]);

  useEffect(() => {
    // console.log('items refreshed');
  }, [items]);

  return (<>
      {items &&
        <div className="flat-card flex flex-col gap-2 w-full">
          <h1>Push notifications</h1>
          {
            <>
              {items.map((groupedItemsByType, idx) => {
                return <div key={`conf_${idx}`}>
                  {groupedItemsByType[0] &&
                    <div className={"flex w-full flex-col justify-start gap-y-2"}>
                      {groupedItemsByType.map((n, idx2) => {
                        return <span key={n.code} className={"flex flat-card justify-between"}>
                                          <span>{n.label}</span>
                          <Switch sx={getSwitchSx()} checked={n.active} onChange={async (e) => {
                            //optimistic UI update using cloneDeep.
                            //The ui will finally be in sync with redux when the mutation
                            //will invalidate cache. But for the interaction to be fluid
                            //we peform a optimistic ui update by updating react state.
                            const _items = _.cloneDeep(items);
                            const _n = _items[idx][idx2];
                            _n.active = e.currentTarget.checked;
                            setItems(_items);
                            await updateConfig({
                              code: n.code,
                              active: e.currentTarget.checked
                            });
                          }}/>
                            </span>
                      })
                      }
                    </div>
                  }
                </div>
              })}
            </>
          }
        </div>
      }

    </>
  );
}

export {UserPushConfig};