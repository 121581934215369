import React from "react";


const ItemShadowIcon: React.FC<{ w?: number, h?: number }> = ({h = 17, w = 52}) => <svg width={w} height={h}
                                                                                        viewBox="0 0 52 17"
                                                                                        fill="none"
                                                                                        xmlns="http://www.w3.org/2000/svg">
  <g filter="url(#filter0_f_2341_16142)">
    <ellipse cx="26" cy="8.5" rx="24" ry="6.5" fill="black" fillOpacity="0.5"/>
  </g>
  <defs>
    <filter id="filter0_f_2341_16142" x="0" y="0" width="52" height="17" filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
      <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur_2341_16142"/>
    </filter>
  </defs>
</svg>;

export {ItemShadowIcon};