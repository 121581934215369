import React, {useEffect, useMemo, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {useLoginMutation} from "../../../apis/users.api";
import {User} from "../../../models/User";
import {useIsAuthQuery} from "../../../apis/auth.api";
import toast from "react-hot-toast";
import {MetamaskLoginComponent} from "../metamask-login.component";
import {DiscordLoginComponent} from "../discord-login.component";
import {TwitterLoginComponent} from "../twitter-login.component";
import {GoogleLoginComponent} from "../google-login.component";
import HandIcon from "../../svg/HandIcon";
import {EyeClosedIcon} from "../../ui/eye-closed-icon";
import {EyeOpenIcon} from "../../ui/eye-open-icon";

export interface Props {
  triggerTwitterLogin?: boolean;
  triggerDiscordLogin?: boolean;
  triggerGoogleLogin?: boolean;
}

const LoginForm: React.FC<Props> = ({
                                      triggerTwitterLogin = false,
                                      triggerDiscordLogin = false,
                                      triggerGoogleLogin = false,
                                    }) => {
  const [email, setEmail] = useState("");
  const [emailErrored, setEmailErrored] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordErrored, setPasswordErrored] = useState(false);
  const [login] = useLoginMutation();
  let navigate = useNavigate();
  const {data: isAuth, isLoading} = useIsAuthQuery();

  const _sa_verifSuccess = useMemo<string>(() => new URLSearchParams(window.location.search).get('_sa_verification_success') || '', []);
  const _prefilledEmail = useMemo<string>(() => new URLSearchParams(window.location.search).get('email') || '', []);

  useEffect(() => {
    if (!isLoading && isAuth) {
      navigate('/portfolio');
    }
  }, [isLoading, isAuth]);

  useEffect(() => {
    if (_prefilledEmail) {
      setEmail(_prefilledEmail);
      setPassword('');
    }
  }, [_prefilledEmail]);

  useEffect(() => {
    if (_sa_verifSuccess === 'true') {

      setTimeout(() => {
        toast.success(`Your email was successfully verified, you can now log into ${process.env.REACT_APP_NAME}`, {duration: 5000});
      }, 500);

      gtag('event', 'accountVerified', {
        'email': email,
      });
    }

  }, []);


  const handleLogin = async () => {
    setSubmitDisabled(true);
    if (!email) {
      setEmailErrored(true);
    } else {
      setEmailErrored(false);
    }
    if (!password) {
      setPasswordErrored(true);
    } else {
      setPasswordErrored(false);
    }
    try {
      const response = (await login({email, password})) as { data: User, error: any };
      if (response.error) {
        setEmailErrored(response.error?.status === 404);
        setPasswordErrored(response.error?.status === 401);
      } else if (response.data._id !== undefined) {
        gtag('event', 'loggin', {
          'email': email
        });

        navigate("/portfolio", {replace: true});
      }
    } catch (err) {
      setEmailErrored(true);
      setPasswordErrored(true);
    } finally {
      setSubmitDisabled(false)
    }
  };

  const [showPwd, setShowPwd] = useState<boolean>(false);

  return (
    <div className="flex flex-col w-full max-w-4xl center p-4 gap-4 flat-card">
      <img src="/static/tanjiblocks-logo-orange.svg" alt="Tanjiblocks logo" width="80px"/>
      <div className="self-stretch flex-col justify-start items-start gap-1 md:gap-2 flex">
        <div className="self-stretch flex-col justify-start items-center gap-1 md:gap-2 flex">
          <h1 className="inline-block text-[28px] md:text-[40px] text-center leading-10">
            Welcome back
            <span className="inline-block pl-2 align-middle"><HandIcon/></span>
          </h1>
          <div className="self-stretch text-center text-base font-normal leading-snug">Continue your investment
            adventure
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full">
        <div className="flex flex-col gap-8 items-center">
          <div className="flex flex-col gap-4 max-w-md w-full">
            <input type="hidden" name="remember" defaultValue="true"/>
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-1">
                <label htmlFor="email-address" className="text-sm font-medium0">
                  Email
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  onChange={(event) => setEmail(event.target.value)}
                  className="sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 flex w-full p-2 dark:placeholder-gray-400 text-black"
                  placeholder=""/>
                <span className="font-medium tracking-wide text-red-500 text-xs">
                  {(emailErrored && "Please enter a valid Email.") || ""}
                </span>
              </div>
              <div className="flex flex-col gap-1">
                <label htmlFor="password" className="text-sm font-medium block">
                  Password
                </label>
                <div className={"flex items-center relative"}>
                  <input
                    id="password"
                    name="password"
                    type={showPwd ? "email" : "password"}
                    autoComplete="current-password"
                    required
                    onChange={(event) => setPassword(event.target.value)}
                    className="sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 flex w-full p-2 dark:placeholder-gray-400 text-black"
                    placeholder=""/>

                  <span className={"absolute right-2"}>
                    <span className={showPwd ? 'hidden' : 'cursor-pointer'} onClick={(e) => {
                      setShowPwd(true);
                    }}>
                      <EyeClosedIcon/>
                  </span>

                  <span className={!showPwd ? 'hidden' : 'cursor-pointer'} onClick={(e) => {
                    setShowPwd(false);
                  }}>
                     <EyeOpenIcon/>
                  </span>
                  </span>
                </div>


                <span className="font-medium tracking-wide text-red-500 text-xs">
                  {(passwordErrored && "Password is incorrect.") || ""}
                </span>
              </div>
            </div>
            <div className="flex flex-col items-center justify-between">
              <div className="text-sm">
                <Link to="/askresetpwd" className="underline hover:text-seeinhover">
                  Forgot your password?
                </Link>
              </div>
            </div>
            <div className={"flex flex-col gap-y-2 items-center"}>
              <button disabled={submitDisabled}
                      type="submit"
                      className="primary w-full"
                      onClick={handleLogin}
              >
                LOGIN
              </button>
            </div>
          </div>

          <div className="flex flex-col w-full center gap-2">
            <div className="self-stretch text-center text-base font-normal leading-snug">Or signin with</div>
            <div
              className="p-2 bg-white bg-opacity-10 rounded-lg border border-white border-opacity-20 grid w-full grid-cols-3 gap-2">
              <MetamaskLoginComponent/>
              {/*<CoinbaseLoginComponent/>*/}
              <DiscordLoginComponent triggerImmediatly={triggerDiscordLogin}/>
              <GoogleLoginComponent triggerImmediatly={triggerGoogleLogin}/>
              <TwitterLoginComponent triggerImmediatly={triggerTwitterLogin}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};


export {LoginForm};
