import React, {useEffect, useMemo, useState} from 'react';
import Layout from "../Layout";
import {TournamentRankingList} from "../components/tournament/tournament-ranking.component";
import {tournamentApi, useGetTournamentQuery} from "../apis/tournaments.api";
import {canSubscribeToTournament, hasTournamentEnded, Tournament} from "../models/Tournament";
import {useLocation, useNavigate} from "react-router-dom";
import {useAppDispatch} from "../app/hooks";
import {Notification} from "../models/Notification";
import {SecondaryNavigation} from "../components/ui/secondary-navigation.component";
import { BackButton } from '../components/ui/back-button';
import { CollectibleRewardComponent } from '../components/users/collectible-reward.component';


const TournamentRankingPage: React.FC = () => {
  const tournamentId = useMemo<string>(() => new URLSearchParams(window.location.search).get('_sa_t_id') || '', []);
  const tournamentType = useMemo<string>(() => new URLSearchParams(window.location.search).get('_sa_t_type') || '', []);
  const { data: tournament } = useGetTournamentQuery(tournamentId);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [title, setTitle] = useState("Tournament rank")

  useEffect(() => {
    if (tournament) {
      if (hasTournamentEnded(tournament)) {
        setTitle(`${tournament.title} rank(Finished)`);
      } else if (!canSubscribeToTournament(tournament)) {
        setTitle(`${tournament.title} rank`);
      } else if (canSubscribeToTournament(tournament)) {
        setTitle(`${tournament.title} Registered Players`);
      }
    }
  }, [tournament]);

  useEffect(() => {
    const eventSource = new EventSource('/sse/tournament');
    eventSource.onmessage = ({ data }) => {
      const json = JSON.parse(data);
      if (json.notification) {
        const notification: Notification = json.notification;
        if (notification.payload.id === tournamentId) {
          dispatch(tournamentApi.util.invalidateTags([{ type: 'Tournament', id: tournamentId }]));
        }
      }
      // console.log('New message', JSON.parse(data));
    };

    eventSource.onerror = (e) => {
      // error log here
      console.log('error sse', e);
      eventSource.close();
    }
    return () => {
      eventSource.close();
    };
  }, []);

  return (
    <Layout>

      {/* <div className="sticky top-10 md:top-12 w-screen flex flex-col bg-gradient-to-b from-orange-700 to-orange -mt-16 pt-14 md:pt-12 p-4 center pb-10"> */}
      <div className="sticky top-10 md:top-12 w-screen flex flex-col bg-gradient-to-b from-orange-700 to-orange -mt-16 pt-14 md:pt-12 p-4 center pb-10">

        <div className='w-full flex justify-between center max-w-md'>

          <div className='justify-self-start invisible'>
            <BackButton label={""} callback={() => navigate(-1)}/>
          </div>

          <SecondaryNavigation navItems={[
            { path: '/portfolio', name: 'Portfolio' },
            { path: '/tournament-ranking', name: 'Players' },
          ]} />

          <span className={""}>
            <CollectibleRewardComponent widthCss={""} displayInfoButton={true}/>
          </span>

        </div>

      </div>

      <div className="w-full max-w-md px-4 justify-between items-center inline-flex">
        {/*<div className="grow shrink basis-0 text-white text-opacity-50 text-xs font-bold font-['Satoshi']">127 Players</div>*/}
        {/*<div className="grow shrink basis-0 text-center text-white text-sm font-bold font-['Satoshi']">Cycle 5</div>*/}
        {/*<div className="grow shrink basis-0 text-right text-white text-opacity-50 text-xs font-bold font-['Satoshi']">4D left</div>*/}
      </div>

      <div className={"w-screen bg-white p-6 rounded-tl-2xl rounded-tr-2xl z-10 -mt-4 pb-24 center"}>
          <TournamentRankingList tournamentId={tournamentId} tournamentType={tournamentType} />
      </div>


    </Layout>
  );
};

export { TournamentRankingPage }